<template>
    <div class="flex">
        <div class="align-middle main">
            <slot  />
        </div>
        <div class="bottom">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
export default {
};
</script>

<style lang="scss">
   .flex {
       display:flex;
       flex-direction: column;
       justify-content: center;
       height:80vh;
   }

   .bottom {
       position:absolute;
       bottom:1rem;
       left:0.5rem;
       width:99.5%;
       margin:auto;
   }

   .main {
        @media only screen and (min-width: 991px) {
            width:400px;
        }
    }

</style>
