import { storeFactory } from '@/app/shared/services/store-helper';

function initialState() {
  return {
    items: [
      {
        key: 'WiredKeypadsAndReaders',
        count: undefined,
        max: undefined,
        status: undefined,
        hasState: true,
        isLoading() { return this.count === undefined || this.max === undefined || (this.status === undefined && this.hasState === true); },
      },
      {
        key: 'WirelessKeypads',
        count: undefined,
        max: undefined,
        status: undefined,
        hasState: true,
        isLoading() { return this.count === undefined || this.max === undefined || (this.status === undefined && this.hasState === true); },
      },
      {
        key: 'WirelessZoneExpanders',
        count: undefined,
        max: undefined,
        status: undefined,
        hasState: true,
        isLoading() { return this.count === undefined || this.max === undefined || (this.status === undefined && this.hasState === true); },
      },
      {
        key: 'WiredZoneExpanders',
        count: undefined,
        max: undefined,
        status: undefined,
        hasState: true,
        isLoading() { return this.count === undefined || this.max === undefined || (this.status === undefined && this.hasState === true); },
      },
      {
        key: 'Zones',
        count: undefined,
        max: undefined,
        status: undefined,
        hasState: true,
        isLoading() { return this.count === undefined || this.max === undefined || (this.status === undefined && this.hasState === true); },
      },
      {
        key: 'OutputExpanders',
        count: undefined,
        max: undefined,
        status: undefined,
        hasState: true,
        isLoading() { return this.count === undefined || this.max === undefined || (this.status === undefined && this.hasState === true); },
      },
      {
        key: 'Outputs',
        count: undefined,
        max: undefined,
        status: undefined,
        hasState: true,
        isLoading() { return this.count === undefined || this.max === undefined || (this.status === undefined && this.hasState === true); },
      },
      {
        key: 'UserDefinedOutputs',
        count: undefined,
        max: undefined,
        hasState: false,
        isLoading() { return this.count === undefined || this.max === undefined || (this.status === undefined && this.hasState === true); },
      },
      {
        key: 'Users',
        count: undefined,
        max: undefined,
        hasState: false,
        isLoading() { return this.count === undefined || this.max === undefined || (this.status === undefined && this.hasState === true); },
      },
    ],
  };
}

const { store } = storeFactory(initialState, {
  getters: {},
  actions: {
    requiredEndpoints: () => ['infoKeypadInfo', 'infoBusDevices', 'infoZoneInfo', 'infoOutputInfo', 'infoUserInfo', 'liveSystem'],
    async populate(context, { endpoints }) {
      context.commit('setItemByKey', {
        key: 'WiredKeypadsAndReaders',
        item: {
          max: endpoints.infoKeypadInfo.data.Info.keypadInfo.WiredKeypadsAndReadersMaxCount,
          count: endpoints.infoKeypadInfo.data.Info.keypadInfo.WiredReaderInstalledCount + endpoints.infoKeypadInfo.data.Info.keypadInfo.WiredKeypadInstalledCount,
        },
      });

      context.commit('setItemByKey', {
        key: 'WirelessKeypads',
        item: {
          max: endpoints.infoKeypadInfo.data.Info.keypadInfo.WirelessKeypadMaxCount,
          count: endpoints.infoKeypadInfo.data.Info.keypadInfo.WirelessKeypadInstalledCount,
        },
      });

      context.commit('setItemByKey', {
        key: 'WirelessZoneExpanders',
        item: {
          max: endpoints.infoBusDevices.data.Info.BusDevices.WirelessHubMaxCount,
          count: endpoints.infoBusDevices.data.Info.BusDevices.WirelessHubInstalledCount,
        },
      });

      context.commit('setItemByKey', {
        key: 'WiredZoneExpanders',
        item: {
          max: endpoints.infoBusDevices.data.Info.BusDevices.WiredHubMaxCount,
          count: endpoints.infoBusDevices.data.Info.BusDevices.WiredHubInstalledCount,
        },
      });

      context.commit('setItemByKey', {
        key: 'Zones',
        item: {
          max: endpoints.infoZoneInfo.data.Info.zoneInfo.ZoneMaxCount,
          count: endpoints.infoZoneInfo.data.Info.zoneInfo.ZoneInstalledCount,
        },
      });

      context.commit('setItemByKey', {
        key: 'OutputExpanders',
        item: {
          max: endpoints.infoOutputInfo.data.Info.outputInfo.OutputExpanderMaxCount,
          count: endpoints.infoOutputInfo.data.Info.outputInfo.OutputExpanderInstalledCount,
        },
      });

      context.commit('setItemByKey', {
        key: 'Outputs',
        item: {
          max: endpoints.infoOutputInfo.data.Info.outputInfo.OutputsMaxCount,
          count: endpoints.infoOutputInfo.data.Info.outputInfo.OutputsInstalledCount,
        },
      });

      context.commit('setItemByKey', {
        key: 'UserDefinedOutputs',
        item: {
          max: endpoints.infoOutputInfo.data.Info.outputInfo.UserDefinedOutputsMaxCount,
          count: endpoints.infoOutputInfo.data.Info.outputInfo.UserDefinedOutputsInstalledCount,
        },
      });

      context.commit('setItemByKey', {
        key: 'Users',
        item: {
          max: endpoints.infoUserInfo.data.Info.userInfo.Users.MaximumUsersAllowed,
          count: endpoints.infoUserInfo.data.Info.userInfo.Users.RegisteredUsersCount,
        },
      });

      await context.dispatch('onPoll', { key: 'liveSystem', endpoint: endpoints.liveSystem });
    },
    async onPoll(context, { dataStatus, key, endpoint }) {
      if (key === 'liveSystem') {
        context.commit('trySetItemByKey', { dataStatus, key: 'WiredKeypadsAndReaders', item: { status: endpoint.data.Live.System.Device.WiredKeypadsAndReaders.Status } });
        context.commit('trySetItemByKey', { dataStatus, key: 'WirelessKeypads', item: { status: endpoint.data.Live.System.Device.WirelessKeypads.Status } });
        context.commit('trySetItemByKey', { dataStatus, key: 'WirelessZoneExpanders', item: { status: endpoint.data.Live.System.Device.WirelessZoneExpanders.Status } });
        context.commit('trySetItemByKey', { dataStatus, key: 'WiredZoneExpanders', item: { status: endpoint.data.Live.System.Device.WiredZoneExpanders.Status } });
        context.commit('trySetItemByKey', { dataStatus, key: 'Zones', item: { status: endpoint.data.Live.System.Device.Zones.Status } });
        context.commit('trySetItemByKey', { dataStatus, key: 'OutputExpanders', item: { status: endpoint.data.Live.System.Device.OutputExpanders.Status } });
        context.commit('trySetItemByKey', { dataStatus, key: 'Outputs', item: { status: endpoint.data.Live.System.Device.Outputs.Status } });
      }
    },
  },
});

export default store;
