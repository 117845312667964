import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';


function initialState() {
  return {
    form: {
      topDisplayMessage: undefined,
      displayWhenSet: undefined,
      displayAlarm: undefined,
      displayPAs: undefined,
      displayZones: undefined,
      disarmLED: undefined,
      displaySilentPAs: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configSysInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        topDisplayMessage: endpoints.configSysInfo.data.Config.sysInfo.Displays.SignOn.replace(/\s+$/, ''),
        displayWhenSet: endpoints.configSysInfo.data.Config.sysInfo.MiscOptions.DisplayWhenSet,
        displayAlarm: endpoints.configSysInfo.data.Config.sysInfo.MiscOptions.DisplayAlarms,
        displayPAs: endpoints.configSysInfo.data.Config.sysInfo.MiscOptions.DisplayPAs,
        displayZones: endpoints.configSysInfo.data.Config.sysInfo.Displays.DisplayZones,
        disarmLED: endpoints.configSysInfo.data.Config.sysInfo.MiscOptions.UnsetLEDOnWhenDisarmed,
        displaySilentPAs: endpoints.configSysInfo.data.Config.sysInfo.MiscOptions.DisplaySilentPAs,
      });
    },
    async save(context) {
      const data = {
        Displays: {
          SignOn: context.state.form.topDisplayMessage,
          DisplayZones: context.state.form.displayZones,
        },
        MiscOptions: {
          DisplayWhenSet: context.state.form.displayWhenSet,
          DisplayAlarms: context.state.form.displayAlarm,
          DisplayPAs: context.state.form.displayPAs,
          UnsetLEDOnWhenDisarmed: context.state.form.disarmLED,
          DisplaySilentPAs: context.state.form.displaySilentPAs,
        },
      };

      await api.put('/Config/sysInfo', data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('settingsState/globalDisplay/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['settingsState/globalDisplay'] });
    },
  },
});


export default store;
