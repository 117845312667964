// Convert volts,amps value to a diagnostic string
// e.g. 13.76 volts, 0.15 amps returns '13.76V 0.15A'
// Small amp values are shown as '<0.1A'.
export function getPowerStr(volts, amps, isNegativeCurrent) {
  let ampStr = '';
  // If the amp reading is 0, use the string '<0.1' instead.
  if (amps === 0) {
    if (isNegativeCurrent) {
      // TODO This may need replacing
      ampStr = '<0.1'; // '<0.1' is less confusing than '>-0.1' (as '<-0.1' is incorrect)
    } else {
      ampStr = '<0.1';
    }
  } else {
    // There is a non-zero amp value, use it.
    ampStr = `${amps}`;
  }
  const powerStr = `${volts}V ${ampStr}A`;
  return powerStr;
}
