<template>
    <card>
        <template slot:status>
            <status state="blank" v-if="isLoading" />
            <status :state="info.status == 'OK' ? 'active' : 'error'" v-else-if="info.hasState" />
            <status state="blank" v-else />
        </template>
        <div class="card__stat mt-0">
            <a-skeleton :loading="isLoading" :paragraph="{ rows:2, width:'100%' }">
                <div>
                    <p class="stat">{{ info.count }}/{{ info.max }}</p>
                    <p>{{ $t(`panel.SideBoxCounts.${info.key}.title`) }}</p>
                    <p class="subtitle">{{ $tc(`panel.SideBoxCounts.${info.key}.available`, info.max - info.count, { count: info.max - info.count }) }}</p>
                </div>
            </a-skeleton>
        </div>
    </card>
</template>
<script>
import card from '@/app/shared/components/Card/card.vue';
import status from '@/app/shared/components/Status/status.vue';

export default {
  components: {
    card,
    status,
  },
  name: 'CountCard',
  props: {
    isLoading: { required: true },
    info: {
      required: true,
    },
    hasState: {
      default: true,
    },
  },
};
</script>

<style lang="scss">

</style>
