/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-return-assign */
import { API } from '@/app/shared/services/api'; // eslint-disable-line import/no-cycle
import logger from '@/app/shared/services/logger';

const api = new API();

const limits = {
  MAX_WIRED_KP: undefined,
  MAX_WE_KP: undefined,
  MAX_KEYPADS: undefined,
  MAX_WIRED_ZEM: undefined,
  MAX_WE_ZEM: undefined,
  MAX_WE_ZEM_ZONES: undefined,
  MAX_WE_ZONES: undefined,
  MAX_ZONES: undefined,
  MAX_ES_ZONES: undefined,
  MAX_ROX: undefined,
  MAX_ROX_OUTPUTS: undefined,
  MAX_OUTPUTS: undefined,
  MAX_ES_OUTPUTS: undefined,
  MAX_USERS: undefined,
  MAX_AREAS: undefined,
  WE_ZONE_OFFSET: undefined,
  MAX_SUBAREAS: undefined,
  MAX_LEVELS: undefined,
};

export async function getLimits() {
  if (!(Object.isFrozen(limits))) {
    await api.get('/Info/keypadInfo/WiredKeypadsAndReadersMaxCount').then(r => limits.MAX_WIRED_KP = r.data.Info.keypadInfo.WiredKeypadsAndReadersMaxCount);
    await api.get('/Info/keypadInfo/WirelessKeypadMaxCount').then(r => limits.MAX_WE_KP = r.data.Info.keypadInfo.WirelessKeypadMaxCount);
    await api.get('/Info/BusDevices/WiredHubMaxCount').then(r => limits.MAX_WIRED_ZEM = r.data.Info.BusDevices.WiredHubMaxCount);
    await api.get('/Info/BusDevices/WirelessHubMaxCount').then(r => limits.MAX_WE_ZEM = r.data.Info.BusDevices.WirelessHubMaxCount);
    await api.get('/Info/BusDevices/wirelessHubs/0/AssociatedZones').then(r => limits.MAX_WE_ZEM_ZONES = r.data.Info.BusDevices.wirelessHubs[0].AssociatedZones.length);
    await api.get('/Info/zoneInfo/ZoneMaxCount').then(r => limits.MAX_ZONES = r.data.Info.zoneInfo.ZoneMaxCount);
    await api.get('/Info/zoneInfo/EndstationZoneMaxCount').then(r => limits.MAX_ES_ZONES = r.data.Info.zoneInfo.EndstationZoneMaxCount);
    await api.get('/Info/outputInfo/OutputExpanderMaxCount').then(r => limits.MAX_ROX = r.data.Info.outputInfo.OutputExpanderMaxCount);
    await api.get('/Info/outputInfo/OutputExpanderOutputsMaxCount').then(r => limits.MAX_ROX_OUTPUTS = r.data.Info.outputInfo.OutputExpanderOutputsMaxCount);
    await api.get('/Info/outputInfo/OutputsMaxCount').then(r => limits.MAX_OUTPUTS = r.data.Info.outputInfo.OutputsMaxCount);
    await api.get('/Info/outputInfo/EndstationOutputsMaxCount').then(r => limits.MAX_ES_OUTPUTS = r.data.Info.outputInfo.EndstationOutputsMaxCount);
    await api.get('/Info/userInfo/Users/MaximumUsersAllowed').then(r => limits.MAX_USERS = r.data.Info.userInfo.Users.MaximumUsersAllowed - 1);

    await api.get('/Info/areaInfo/Areas').then(r => limits.MAX_AREAS = r.data.Info.areaInfo.Areas.length);
    await api.get('/Info/areaInfo/LevelsMaxCount').then(r => limits.MAX_LEVELS = r.data.Info.areaInfo.LevelsMaxCount);
    limits.MAX_KEYPADS = limits.MAX_WIRED_KP + limits.MAX_WE_KP;
    limits.MAX_WE_ZONES = limits.MAX_WE_ZEM * limits.MAX_WE_ZEM_ZONES;
    limits.WE_ZONE_OFFSET = limits.MAX_ES_ZONES;
    limits.MAX_SUBAREAS = limits.MAX_WIRED_KP; // for subareas
    Object.freeze(limits);
  }
}

export async function setLimitsMocks() {
  logger.log('setLimitsMocks');
  limits.MAX_WIRED_KP = 30;
  limits.MAX_WE_KP = 4;
  limits.MAX_KEYPADS = 34;
  limits.MAX_WIRED_ZEM = 30;
  limits.MAX_WE_ZEM = 3;
  limits.MAX_WE_ZEM_ZONES = 280;
  limits.MAX_WE_ZONES = 96;
  limits.MAX_ZONES = 280;
  limits.MAX_ES_ZONES = 8;
  limits.MAX_ROX = 16;
  limits.MAX_ROX_OUTPUTS = 16;
  limits.MAX_ES_OUTPUTS = 17;
  limits.MAX_USERS = 521;
  limits.MAX_AREAS = 99;
  limits.MAX_LEVELS = 4;
  limits.WE_ZONE_OFFSET = 8;
}

// Constants used for mode setting on the Panel page.
const panelPageConsts = {
  panelPage: 'panelPage',
  userMode: 'USER',
  engineerMode: 'ENGINEER',
  userModeSet: 'userModeSet',
  engineerModeSet: 'engineerModeSet',
  userModeRequested: 'userModeRequested',
  engineerModeRequested: 'engineerModeRequested',
  panelArmed: 'panelArmed',
  initVarToNull: null,
  keypadLockOwner: 'Engineer',
  panelPageKey: '/',
};

// Constants for wireless devices diagnostics.
const wrlsDevicesDiagnosticsConsts = {
  gettingDiagnostics: 'updating...',
  retryingDiagnostics: 'retrying...',
};

// Constants for live zone info polling
const liveZoneInfoConsts = {
  key: 'liveZoneInfo',
  interval: 1000, // polling interval in ms
  baseUrl: '/Live/zoneInfo',
};

// Constants for live output expander polling
const liveOutputExpanderConsts = {
  key: 'liveOutputExpanders',
  interval: 5000, // polling interval in ms
  baseUrl: '/Live/Devices/OutputExpander',
};

// Constants for live panel status outputs polling e.g. in outputs-list page
const livePanelStatusOutputsConsts = {
  key: 'livePanelStatusOutputs',
  activeInterval: 5000, // polling interval in ms. Note: slower than default
  defaultInterval: 1000, // polling interval in ms
  baseUrl: '/Live/PanelStatus',
  defaultUrl: '/Live/PanelStatus/OutputTypes/0',
};

// Constants for live wired zone expander polling
const liveWiredZoneExpanderConsts = {
  key: 'liveWiredHubs',
  activeInterval: 5000, // polling interval in ms
  defaultInterval: 20000, // polling interval in ms
  baseUrl: '/Live/BusDevices/wiredHubs',
  defaultUrl: '/Live/BusDevices/wiredHubs/0',
};

// Constants for live wireless zone expander polling
const liveWirelessZoneExpanderConsts = {
  key: 'liveWirelessHubs',
  activeInterval: 5000, // polling interval in ms
  defaultInterval: 20000, // polling interval in ms
  baseUrl: '/Live/BusDevices/wirelessHubs',
  defaultUrl: '/Live/BusDevices/wirelessHubs/0',
};

// Constants for live zone list page polling
const liveZonePageConsts = {
  key: 'liveZonePage',
  activeInterval: 1000, // polling interval in ms when endpoint in use (1000ms)
  defaultInterval: 50000, // polling interval in ms when endpoint idle
  baseUrl: '/Live/zoneInfo',
  defaultUrl: '/Live/zoneInfo/Zones/0',
};

// Constants for live comms info polling used in communications/arc-options page
const liveCommsInfoConsts = {
  key: 'liveCommsInfo',
  activeInterval: 5000, // polling interval in ms when endpoint in use. Note that this is slower than the default.
  defaultInterval: 500, // polling interval in ms when endpoint initialised by requiredEndpoints() - note that initial polling is quite fast.
  baseUrl: '/Live/commsInfo',
  defaultUrl: '/Live/commsInfo', // same as baseUrl
};

export {
  limits, panelPageConsts, wrlsDevicesDiagnosticsConsts, liveZoneInfoConsts, liveOutputExpanderConsts, livePanelStatusOutputsConsts, liveWiredZoneExpanderConsts, liveWirelessZoneExpanderConsts, liveZonePageConsts, liveCommsInfoConsts,
};
export const ENGINEER_KEYPAD_INDEX = 29;
export const MAX_KEYFOBS = 32;
export const MAX_SIRENS = 2;
export const MAX_GUARD_USERS = 20;
export const READERS_MN = 16; // seems to be related to readers addresses
export const MAX_KF_BUTTONS = 8; // for keyfob buttons
export const MAX_ZEM_ADDRESS_ZONES = 8; // for wireless zones per ZEM address
export const MAX_ZONES_PER_HUB = 32; // max zones supported by a hub/ZEM
export const MAX_GATE_TRIGGERS = 4; // for Logic Gates: must be identical to PanelMain's NUM_GATE_INPUTS
// it would be ideal if we could save all the max values read from panel endpoints in here
// then they can be imported from here anywhere across the code

// The number of items shown per page on a 'paged list' screen.
export const MAX_ITEMS_PER_PAGE = 9;

// Fixed short (user visible) identifier for each level in an area.
export const LEVEL_IDS = ['A', 'B', 'C', 'D'];

// Text string indicating a value is to be loaded later.
// TODO Ideally a value that an installer cannot create.
export const LOADING = 'LOADING';
