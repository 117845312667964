<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('communications.smsNotifications.navigationTitle') }}</h2>
      </a-row>
      <a-form>
        <p-input :label="$t('communications.smsNotifications.SMS-common-message')"
                 :labelTooltip="$t('communications.smsNotifications.SMS-common-message-tooltip')"
                 :model="$v.form.smsCommonMessage"  display-style="small"
                 :disabled="!configWriteLock.canWriteConfig" />

        <a-tabs defaultActiveKey="item-0">
          <a-tab-pane v-for="(v, index) in $v.form.items.$each.$iter" :key="`item-${index}`">
            <template slot="tab">
              <span :class="{ 'has-error': v.$anyError }">
                {{ parseInt(index, 10) + 1 }} <span class="ant-form-explain" v-if="v.$anyError">*</span>
              </span>
            </template>
            <p-area-selector :label="$t('common.Areas')" :labelTooltip="$t('communications.smsNotifications.Areas-tooltip')" :model="v.areas" :areasInfo="$v.form.areasInfo.$model" :areasConfig="$v.form.areasConfig.$model" display-style="small-alternative"
                            :disabled="!configWriteLock.canWriteConfig" />
            <p-input :label="$t('communications.smsNotifications.Redials')" :labelTooltip="$t('communications.smsNotifications.Redials-tooltip')" :model="v.redials" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
            <p-input :label="$t('communications.smsNotifications.Time-out')" :labelTooltip="$t('communications.smsNotifications.Time-out-tooltip')" :model="v.timeout" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />

            <p-switch :label="$t('communications.smsNotifications.Test-calls')" :labelTooltip="$t('communications.smsNotifications.Test-calls-tooltip')" :model="v.testCalls" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
            <div v-if="v.testCalls.$model" class="indent">
              <p-input :label="$t('communications.smsNotifications.Test-hours')" :model="v.testHours" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-input :label="$t('communications.smsNotifications.Test-minutes')" :model="v.testMinutes" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-input :label="$t('communications.smsNotifications.Interval-days')" :model="v.intervalDays" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-input :label="$t('communications.smsNotifications.Interval-hours')" :model="v.intervalHours" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-input :label="$t('communications.smsNotifications.Interval-minutes')" :model="v.intervalMinutes" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <div class="ant-row ant-form-item"  v-if="v.interval.$invalid">
                <div class="ant-col-sm-12 ant-col-md-8 ant-col-lg-6 ant-col-xl-5 ant-form-item-label"></div>
                <div class="ant-col-sm-24 ant-col-md-18 ant-col-lg-18 ant-col-xl-18 ant-form-item-control-wrapper">
                  <div class="ant-form-item-control has-error">
                    <div class="ant-form-explain">{{ $t('communications.voice.Valid-minutes') }}</div>
                  </div>
                </div>
              </div>
            </div>

            <p-select :label="$t('communications.smsNotifications.Event-types')" :labelTooltip="$t('communications.smsNotifications.Event-types-tooltip')" :model="v.eventTypes"
            :items="Object.keys(EVENT_TYPES)" :valueExpr="(i) => EVENT_TYPES[i]" :textExpr="(i) => $t(`enums.EVENT_TYPES.${i}`)" display-style="small-alternative"
            :disabled="!configWriteLock.canWriteConfig" />

            <div class="indent" v-if="v.eventTypes.$model === EVENT_TYPES.CUSTOM">
              <p-switch :label="$t('communications.smsNotifications.Arm')" :model="v.arm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Disarm')" :model="v.disarm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Special-Arm-Disarm')" :model="v.specialArmDisarm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Sub-area-SH-arm')" :model="v.subAreaSHArm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Sub-area-SH-disarm')" :model="v.subAreaSHDisarm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />

              <p-select :label="$t('communications.smsNotifications.Burglary-alarm')" :model="v.burglaryAlarm" display-style="small-alternative"
                :items="BUGLARY_ALARM_OPTIONS.values" :valueExpr="(i) => i.value" :textExpr="(i) => $t(`enums.BUGLARY_ALARM_OPTIONS.${i.key}`)"
                :disabled="!configWriteLock.canWriteConfig" />

              <p-switch :label="$t('communications.smsNotifications.Burglary-restore')" :model="v.burglaryRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Fire')" :model="v.fire" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Fire-restore')" :model="v.fireRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.PA-alarm')" :model="v.paAlarm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.PA-restore')" :model="v.paRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Medical')" :model="v.medical" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Medical-restore')" :model="v.medicalRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />

              <p-select :label="$t('communications.smsNotifications.Tamper')" :model="v.tamper" display-style="small-alternative"
                :items="TAMPER_OPTIONS.values" :valueExpr="(i) => i.value" :textExpr="(i) => $t(`enums.TAMPER_OPTIONS.${i.key}`)"
                :disabled="!configWriteLock.canWriteConfig" />

              <p-switch :label="$t('communications.smsNotifications.Tamper-restore')" :model="v.tamperRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Bypass')" :model="v.bypass" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Bypass-restore')" :model="v.bypassRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Technical')" :model="v.technical" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Technical-restore')" :model="v.technicalRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.AC-fault-restore')" :model="v.acFaultRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Wireless-faults')" :model="v.wirelessFaults" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Telecom-status')" :model="v.telecomStatus" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Access-control')" :model="v.accessControl" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Mask-restore')" :model="v.maskRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Special-log')" :model="v.specialLog" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Alarm-silenced')" :model="v.alarmSilenced" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Tech-alarm-silence')" :model="v.techAlarmSilence" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.smsNotifications.Information')" :model="v.information" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
            </div>

          </a-tab-pane>
        </a-tabs>
      </a-form>
      <div class="align-right">
         <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
      </div>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />

  </div>
</template>

<script>
import { required, between } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import { validMinutesValidator } from '@/app/shared/utils/date-helper';

import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import { BUGLARY_ALARM_OPTIONS, TAMPER_OPTIONS } from '@/app/shared/enums';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'communicationsState/smsNotificiations';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

const TEST_CALLS = {
  YES: 1440,
  NO: 0,
};

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
      TEST_CALLS,
      EVENT_TYPES: {
        DEFAULT_1: 0,
        CUSTOM: 1,
      },
      BUGLARY_ALARM_OPTIONS,
      TAMPER_OPTIONS,
    };
  },
  computed: {
    ...mapState(['form']),
  },
  validations: {
    form: {
      smsCommonMessage: { },
      areasInfo: {},
      areasConfig: {},
      items: {
        $each: {
          areas: {},
          redials: { required, between: between(0, 15) },
          timeout: { },
          testCalls: { },
          interval: { validMinutes: validMinutesValidator(46079, 'intervalMinutes', 'intervalDays', 'intervalHours') },
          intervalDays: { between: between(0, 31) },
          intervalHours: { between: between(0, 23) },
          intervalMinutes: { between: between(0, 59) },
          testHours: { between: between(0, 23) },
          testMinutes: { between: between(0, 59) },

          eventTypes: { required, between: between(0, 3) },
          arm: {},
          disarm: {},
          specialArmDisarm: {},
          subAreaSHArm: {},
          subAreaSHDisarm: {},
          burglaryAlarm: { },
          burglaryRestore: {},
          fire: {},
          fireRestore: {},
          paAlarm: {},
          paRestore: {},
          medical: {},
          medicalRestore: {},
          tamper: {},
          tamperRestore: {},
          bypass: {},
          bypassRestore: {},
          technical: {},
          technicalRestore: {},
          acFaultRestore: {},
          wirelessFaults: {},
          telecomStatus: {},
          accessControl: {},
          maskRestore: {},
          specialLog: {},
          alarmSilenced: {},
          techAlarmSilence: {},
          information: {},
        },
      },
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
};
</script>
