<template>
  <diagnostics :globalState="globalState" :items="items" :isLoading="isLoading" :options="options" />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapGetters } = createNamespacedHelpers('panelState/panelDiagnostics');

export default {
  data() {
    return {
      options: {
        statusType(diagnostic) {
          if (diagnostic.key === 'panel.Diagnostics.ConnectionStatus') {
            return diagnostic.value !== 'CONNECTED' ? 'error' : 'active';
          }

          return diagnostic.value === 'FAULT' ? 'error' : 'active';
        },
      },
    };
  },
  computed: {
    ...mapState(['items', 'globalState']),
    ...mapGetters(['isLoading']),
  },
};
</script>
