/* eslint-disable no-param-reassign */
import Q from 'q';
import _ from 'lodash';
import { API } from '@/app/shared/services/api';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import logger from '@/app/shared/services/logger';

const instances = [];
let requiresEventRegistration = true;

function idsOfInstances() {
  // For debug, return string of list of instance identities e.g. (2) [PL-login,GL-sl]
  const idList = [];
  instances.forEach((instance) => {
    idList.push(instance.id);
  });
  return '('.concat(instances.length.toString()).concat(') [').concat(idList).concat(']');
}

function configWriteHandler(payload) {
  instances.forEach(instance => instance.onConfigWrite.call(instance, payload));
}
function partialConfigWriteHandler(payload) {
  instances.forEach(instance => instance.onPartialConfigWrite.call(instance, payload));
}

export default class BaseLoader {
  constructor(id, $store, endpoints) {
    this.api = new API();
    this.$store = $store;
    this.stores = {};
    this.endpoints = endpoints;
    this.gettingUrls = [];
    this.id = id;

    if (requiresEventRegistration) {
      requiresEventRegistration = false;
      eventHub.$on(EVENTS.CONFIG_WRITE, configWriteHandler);
      eventHub.$on(EVENTS.PARTIAL_CONFIG_WRITE, partialConfigWriteHandler);
    }

    instances.push(this);
    logger.log('Instances:', idsOfInstances());
  }

  // eslint-disable-next-line class-methods-use-this
  resetGLEndpoints() {
    // For any generic-loader (loader used by Engineer page):
    instances.forEach((instance) => {
      const isGL = (instance.id.substring(0, 2) === 'GL');
      if (isGL) {
        // Reset all GL endpoints data to null, forcing them to be reloaded on next use.
        Object.keys(instance.endpoints).forEach((key) => {
          instance.endpoints[key].data = null;
        });
      }
    });
  }

  dispose() {
    this.api.cancelPolling();
    const index = instances.indexOf(this);
    if (index >= 0) {
      instances.splice(index, 1);
    }
    logger.log('Instances:', idsOfInstances());
  }

  async onPartialConfigWrite(payload) {
    // set commitConfigRequired to true here, as we're sure at this point the /config on the panel has been modified (LAN sent the PUTs prior to this)
    const commitConfigRequired = JSON.parse(window.localStorage.getItem('commitConfigRequired'));
    // if it hasn't been set before, do it now
    if (!commitConfigRequired) {
      window.localStorage.setItem('commitConfigRequired', 'true');
    }
    // Get the endpoints specified with the PARTIAL_CONFIG_WRITE event call and update them
    const endpoints = payload.endpointsToUpdate;
    endpoints.forEach((key) => {
      const endpoint = this.endpoints[key];
      this.loadEndpoint(endpoint, true);
    });

    // Get the store names specified with the PARTIAL_CONFIG_WRITE event call and refresh them
    const stores = payload.storesToRefresh;
    // this main promise will run in the background, after the function returns,
    // until all endpoints and stores have been populated with data and marked as fulfilled
    const promise = new Promise(async (resolve, reject) => {
      const populateDispatchPromises = [];
      const errors = [];
      // Go through all the stores and collect all endpoint promises and populate promises
      stores.forEach((key) => {
        const store = this.stores[key];
        if (store) {
          const requiredEndpointPromises = store.requiredEndpoints.map(k => this.endpoints[k].promise);
          const populatePromise = Q.allSettled(requiredEndpointPromises)
            .then(() => { this.$store.dispatch(`${key}/populateWrapper`, { endpoints: this.endpoints, payload: { ...store.payload, event: payload != null ? payload.event : undefined } }); })
            .catch((error) => {
              errors.push(error);
            });
          populateDispatchPromises.push(populatePromise);
        }
      });

      await Q.allSettled(populateDispatchPromises).then(() => { // this is a set of promises containing a set of promises!!!
        if (errors.length > 0) {
          reject(errors);
        } else {
          resolve();
        }
      });
    });
    return promise;
  }

  async onConfigWrite(payload) {
    // go through all endpoints listed in loader & generic-loader (both loaders are using this base-loader)
    Object.keys(this.endpoints).forEach((key) => {
      const endpoint = this.endpoints[key];
      // if the endpoint has updateOnConfigWrite set to true or if there is a payload, load the endpoint
      if (endpoint.updateOnConfigWrite || (payload != null && payload.additionalEndpoints != null && payload.additionalEndpoints.includes(key))) {
        this.loadEndpoint(endpoint, true);
      }
    });

    // get all stores that require one of the endpoints above
    // this main promise will run in the background, after the function returns,
    // until all endpoints and stores have been populated with data and marked as fulfilled
    const promise = new Promise(async (resolve, reject) => {
      const populateDispatchPromises = [];
      const errors = [];
      // go through all the cached stores and update the ones containing the updated endpoints above
      Object.keys(this.stores).forEach((key) => {
        const store = this.stores[key];
        // get all the requiredEndpoints needed for this store path
        const requiredEndpoints = Object.keys(this.endpoints).filter(e => store.requiredEndpoints.includes(e));
        // establish if any of the required endpoints include ones that have updateOnConfigWrite set to true in loaders
        const refresh = requiredEndpoints.find(e => this.endpoints[e].updateOnConfigWrite);
        // if refresh is true, run populate for this store with all the loader endpoints available
        if (refresh) {
          const requiredEndpointPromises = store.requiredEndpoints.map(k => this.endpoints[k].promise);
          // create an array of promises for each required endpoint of this store;
          const populatePromise = Q.allSettled(requiredEndpointPromises)
            .then(() => this.$store.dispatch(`${key}/populateWrapper`, { endpoints: this.endpoints, payload: { ...store.payload, event: payload != null ? payload.event : undefined } }))
            .catch((error) => {
              errors.push(error);
            });
          // push this into another array of promises; at the end this will contain all the promises needed for all stores
          populateDispatchPromises.push(populatePromise);
        }
      });
      // when all the endpoint promises are fulfilled, resolve the main promise
      await Q.allSettled(populateDispatchPromises).then(() => { // this is a set of promises containing a set of promises!!!
        if (errors.length > 0) {
          reject(errors);
        } else {
          if (_.has(payload, 'callback')) {
            if (payload.callback) {
              payload.callback();
            }
          }
          resolve();
        }
      });
    });

    return promise;
  }

  async populate(storeKey, payload, callback) {
    const endpointKeys = await this.$store.dispatch(`${storeKey}/requiredEndpoints`);

    endpointKeys.forEach((endpointKey) => {
      const endpoint = this.endpoints[endpointKey];
      if (!endpoint) throw new Error(`Endpoint not found: ${endpointKey}`);
      const uri = this.endpoints[endpointKey].url;
      if (uri.includes('/Live/')) {
        window.globalThis.currentRequiredEndpoints.push(uri);
      }
      endpoint.usedBy.push(storeKey);
      this.loadEndpoint(endpoint);
    });

    this.stores[storeKey] = {
      key: storeKey, endpoints: endpointKeys, payload, requiredEndpoints: endpointKeys,
    };

    const result = await Q.allSettled(endpointKeys.map(k => this.endpoints[k].promise));

    if (result.every(r => r.state === 'fulfilled' && r.value != null)) {
      await this.$store.dispatch(`${storeKey}/populateWrapper`, { endpoints: this.endpoints, payload });
    }

    await this.api.asyncDone();

    if (callback) callback();
  }

  async resetVuex() {
    // Sending a reset command for each store instance; actioned in the store-helper.
    this.$store.dispatch('panelState/panel/reset');
    this.$store.dispatch('panelState/panelAreas/reset');
    this.$store.dispatch('panelState/panelConnections/reset');
    this.$store.dispatch('panelState/panelCounts/reset');
    this.$store.dispatch('panelState/panelDiagnostics/reset');
    this.$store.dispatch('panelState/panelOutputs/reset');
    this.$store.dispatch('panelState/panelTitle/reset');
    this.$store.dispatch('panelState/panelZones/reset');
    this.$store.dispatch('panelState/silenceAlarm/reset');
    this.$store.dispatch('communicationsState/advancedOptions/reset');
    this.$store.dispatch('communicationsState/appOptions/reset');
    this.$store.dispatch('communicationsState/arcOptions/reset');
    this.$store.dispatch('communicationsState/cellular/reset');
    this.$store.dispatch('communicationsState/cloudOptions/reset');
    this.$store.dispatch('communicationsState/lan/reset');
    this.$store.dispatch('communicationsState/smsControls/reset');
    this.$store.dispatch('communicationsState/smsNotificiations/reset');
    this.$store.dispatch('communicationsState/voice/reset');
    this.$store.dispatch('communicationsState/wifi/reset');
    this.$store.dispatch('historyState/accessList/reset');
    this.$store.dispatch('historyState/panelList/reset');
    this.$store.dispatch('historyState/voiceList/reset');
    this.$store.dispatch('historyState/cloudList/reset');
    this.$store.dispatch('keypadsAndReadersState/list/reset');
    this.$store.dispatch('keypadsAndReadersState/wiredKeypad/reset');
    this.$store.dispatch('keypadsAndReadersState/wiredReader/reset');
    this.$store.dispatch('keypadsAndReadersState/wirelessKeypad/reset');
    this.$store.dispatch('keypadsAndReadersState/addKeypadOrReader/reset');
    this.$store.dispatch('maintenanceState/arcCommsTest/reset');
    this.$store.dispatch('maintenanceState/pgmTest/reset');
    this.$store.dispatch('maintenanceState/soakTest/reset');
    this.$store.dispatch('maintenanceState/walkTest/reset');
    this.$store.dispatch('outputExpandersState/outputExpander/reset');
    this.$store.dispatch('outputExpandersState/list/reset');
    this.$store.dispatch('outputExpandersState/addOutputExpander/reset');
    this.$store.dispatch('outputsState/output/reset');
    this.$store.dispatch('outputsState/outputsList/reset');
    this.$store.dispatch('outputsState/userDefinedOutput/reset');
    this.$store.dispatch('outputsState/userDefinedOutputsList/reset');
    this.$store.dispatch('outputsState/logicGate/reset');
    this.$store.dispatch('outputsState/logicGatesList/reset');
    this.$store.dispatch('outputsState/addOutput/reset');
    this.$store.dispatch('settingsState/alarmResponses/reset');
    this.$store.dispatch('settingsState/areasOptions/reset');
    this.$store.dispatch('settingsState/armingOptions/reset');
    this.$store.dispatch('settingsState/dateAndTime/reset');
    this.$store.dispatch('settingsState/engineerRestores/reset');
    this.$store.dispatch('settingsState/globalDisplay/reset');
    this.$store.dispatch('settingsState/generalResponses/reset');
    this.$store.dispatch('settingsState/generalTimers/reset');
    this.$store.dispatch('settingsState/globalVolume/reset');
    this.$store.dispatch('settingsState/siteOptions/reset');
    this.$store.dispatch('settingsState/userSettings/reset');
    this.$store.dispatch('settingsState/zoneSettings/reset');
    this.$store.dispatch('sirensState/list/reset');
    this.$store.dispatch('sirensState/wiredSiren/reset');
    this.$store.dispatch('sirensState/wirelessSiren/reset');
    this.$store.dispatch('sirensState/addSiren/reset');
    this.$store.dispatch('usersState/user/reset');
    this.$store.dispatch('usersState/list/reset');
    this.$store.dispatch('usersState/addKeyFob/reset');
    this.$store.dispatch('usersState/addUser/reset');
    this.$store.dispatch('zoneExpandersState/wiredZoneExpander/reset');
    this.$store.dispatch('zoneExpandersState/wirelessZoneExpander/reset');
    this.$store.dispatch('zoneExpandersState/list/reset');
    this.$store.dispatch('zoneExpandersState/addZoneExpander/reset');
    this.$store.dispatch('zonesState/zone/reset');
    this.$store.dispatch('zonesState/list/reset');
    this.$store.dispatch('zonesState/addZone/reset');
  }

  async unload(storeKey) {
    if (this.stores[storeKey] == null) return;

    this.$store.dispatch(`${storeKey}/unload`, { payload: this.stores[storeKey].payload });

    Object.keys(this.endpoints).forEach((endpointKey) => {
      if (this.endpoints[endpointKey].polling != null && this.endpoints[endpointKey].usedBy.filter(e => e !== storeKey).length === 0) {
        this.endpoints[endpointKey].polling.cancel();
        this.endpoints[endpointKey].polling = null;
      }
      this.endpoints[endpointKey].usedBy = this.endpoints[endpointKey].usedBy.filter(i => i !== storeKey);
    });
    delete this.stores[storeKey];
  }

  async reloadEndpoint(endpointKey) {
    const endpoint = this.endpoints[endpointKey];
    await this.loadEndpoint(endpoint, true);
  }

  // Request that the polling endpoint use a new url
  async changeUrlOnTheFly(endpointKey, newUrl, newInterval) {
    const endpoint = this.endpoints[endpointKey];
    endpoint.interval = newInterval;
    // New URL to be picked up on the reload of this endpoint.
    endpoint.newUrl = newUrl;
  }

  // Set the polling interval and url for an existing endpoint.
  setEndpointIntervalAndUrl(endpointKey, newInterval, newUrl) {
    const endpoint = this.endpoints[endpointKey];
    if (!endpoint) throw new Error(`Endpoint not found: ${endpointKey}`);
    endpoint.interval = newInterval;
    endpoint.url = newUrl;
  }

  async loadEndpoint(endpoint, force) {
    if (endpoint.type === 'once' && (force || endpoint.data == null)) {
      if (!this.gettingUrls[endpoint.url]) {
        this.gettingUrls[endpoint.url] = true;
        endpoint.promise = this.api.get(endpoint.url).then((res) => {
          this.gettingUrls[endpoint.url] = false;
          if (res != null) {
            endpoint.data = res.data;
            endpoint.dataStatus = res.status === 200 ? 'FULLY_LOADED' : 'PARTIALLY_LOADED';
            return endpoint.data;
          }
          return null;
        });
      }
    }

    // Handle poll-type endpoints.
    if (endpoint.type === 'poll' && (force || endpoint.polling == null)) {
      if (endpoint.polling != null) {
        endpoint.polling.cancel();
      }
      if (endpoint.newUrl) {
        if (endpoint.newUrl.length > 0) {
          endpoint.url = endpoint.newUrl;
          endpoint.newUrl = '';
        }
      }
      const { call, polling } = this.api.poll(endpoint.interval, endpoint.url, (res) => {
        if (res != null) {
          const dataStatus = res.status === 200 ? 'FULLY_LOADED' : 'PARTIALLY_LOADED';

          endpoint.data = res.data;
          endpoint.dataStatus = dataStatus;

          Object.keys(this.stores).forEach((m) => {
            this.$store.dispatch(`${this.stores[m].key}/onPoll`, {
              key: endpoint.key, endpoint, payload: this.stores[m].payload, dataStatus,
            });
          });
        }
      });
      // VD - endpoint doesn't have call and polling parameters. Fix me!!! BUGRD-398
      endpoint.promise = call;
      endpoint.polling = polling;
    }
  }
}
