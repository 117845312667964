import _ from 'lodash';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { getDevices, canBeOmitted } from '@/app/zones/shared/services/helpers';
import i18n from '@/app/shared/services/i18n';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import {
  limits, wrlsDevicesDiagnosticsConsts, liveZoneInfoConsts, liveWirelessZoneExpanderConsts,
} from '@/app/shared/constants';
import zoneAreasHelper from '@/app/shared/services/zone-areas-helper';

export function roundToNumber(number, places) {
  return +(`${Math.round(`${number}e+${places}`)}e-${places}`);
}

const getDiagnostic = wrlsDevicesDiagnosticsConsts.gettingDiagnostics; // while loading the page the first time
const validState = 'valid';
const errorState = wrlsDevicesDiagnosticsConsts.retryingDiagnostics; // error condition
const wrlsZoneMaxDiagnosticRetryLimit = 3; // based on poll duration of 20 seconds, mask null value and retry for a minute before reporting error
const wrlsZoneMaxDiagnosticUpdateLimit = 1; // max number to attempts to load diagnostics the first time, before reporting an error

let wrlsZoneDiagnosticState = getDiagnostic;
let wrlsZoneDiagnosticRetryCount = 0;
let wrlsZonePreviousBatteryStatus = null;
let wrlsZonePreviousSignalStrength = null;
let cachedEol = null;

function initialState() {
  return {
    managerOmitted: undefined,
    setOmitted: undefined,
    forceOmitted: undefined,
    name: undefined,
    omittable: undefined,
    associatedDevices: [],

    form: {
      index: undefined,
      name: undefined,
      zoneType: undefined,
      location: undefined,
      anyAllArea: undefined,
      areas: undefined,
      areasInfo: undefined,
      areasConfig: undefined,
      associatedWithDevice: undefined,
      associatedWithZoneIndex: undefined,
      connectionType: undefined,
      chime: undefined,
      allowByPass: undefined,
      crossZone: undefined,
      normallyOpen: undefined,
      specialLogged: undefined,
      nonActivityInput: undefined,
      swingerLimit: undefined,
      doubleKnock: undefined,
      maskTest: undefined,
      inertiaInput: undefined,
      inertiaDebounceCount: undefined,
      inertiaDebounceTime: undefined,
      // soakTest: undefined,
      hasPerZoneOptions: false,
      eolResistorRange: undefined,
      eolResistorMode: undefined,
      occupancy: undefined,
      supervision: undefined,
      confirmGroup: undefined,
    },

    globalState: undefined,
    isWirelessFault: undefined,
    globalState_state: undefined,
    globalState_signalLevel: undefined,

    wiredDiagnostics: [
      {
        key: 'wiredZone.Diagnostics.Status',
        isLoading: true,
        value: undefined,
      },
      {
        key: 'wiredZone.Diagnostics.ZoneResistance', isLoading: true, value: undefined, valueIsHtml: true, format: undefined,
      },
      { key: 'wiredZone.Diagnostics.ZoneStatus', isLoading: true, value: undefined },
      {
        key: 'wiredZone.Diagnostics.State',
        isLoading: true,
        value: undefined,
      },
    ],
    wirelessDiagnostics: [
      { key: 'wirelessZone.Diagnostics.Supervision', isLoading: true, value: undefined },
      { key: 'wirelessZone.Diagnostics.BatteryStatus', isLoading: true, value: undefined },
      {
        key: 'wirelessZone.Diagnostics.SignalStrength', isLoading: true, value: undefined, format: undefined,
      },
      {
        key: 'wirelessZone.Diagnostics.State',
        isLoading: true,
        value: undefined,
      },
    ],
  };
}

function getState(state) {
  if (state === 'FAULT' || state === 'DET_FAULT' || state === 'UNKNOWN' || state === 'MASKED') return 'FAULT';
  if (state === 'TAMPER_2ND_IP' || state === 'TAMPER' || state === 'DOOR_FORCED') return 'TAMPER';
  return 'OK';
}

const { api, store } = storeFactory(initialState, {
  getters: {
    diagnostics: (state) => {
      if (state.form.connectionType !== undefined) {
        return state.form.connectionType === 'TYPE_WIRELESS' ? state.wirelessDiagnostics : state.wiredDiagnostics;
      }
      return [];
    },
  },
  actions: {
    requiredEndpoints() {
      // Note that getDevices() requires endpoints: configBusDevices configKeypadInfo configZoneInfoParts infoBusDevices infoKeypadInfo
      return ['configAreaInfo', 'configBusDevices', 'configKeypadInfo', 'configZoneInfoParts', 'infoAreaInfo', 'infoBusDevices', 'infoKeypadInfo', 'liveActiveFaultsWirelessInputs', 'liveAreaInfo', liveWirelessZoneExpanderConsts.key, 'liveZoneInfo'];
    },
    async populate(context, { endpoints, payload }) {
      const cachedInfoZoneInfo = (await api.get('/Info/zoneInfo/Zones/'.concat(payload.index.toString())));
      const associatedWithDeviceType = cachedInfoZoneInfo.data.Info.zoneInfo.Zones[payload.index].AssociatedWith.DeviceType;
      const associatedWithDeviceNumber = cachedInfoZoneInfo.data.Info.zoneInfo.Zones[payload.index].AssociatedWith.DeviceNumber;
      const associatedWithZoneIndex = cachedInfoZoneInfo.data.Info.zoneInfo.Zones[payload.index].AssociatedWith.ZoneIndex;

      const cachedConfigZoneInfo = (await api.get('/Config/zoneInfo/Zones/'.concat(payload.index.toString())));

      context.commit('setForm', {
        index: payload.index,
        name: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Name.replace(/\s+$/, ''),
        zoneType: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].ZoneType,
        location: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Location.replace(/\s+$/, ''),
        anyAllArea: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].AnyAllArea === 1,
        areas: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Areas,
        areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
        areasConfig: endpoints.configAreaInfo.data.Config.areaInfo,
        connectionType: associatedWithDeviceType === 'WIRELESS_HUB' ? 'TYPE_WIRELESS' : 'TYPE_WIRED',
        associatedWithDevice: associatedWithDeviceType !== 'NONE' ? `${associatedWithDeviceType}_${associatedWithDeviceNumber}` : null,
        chime: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Chime,
        allowByPass: canBeOmitted(cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].ZoneType) && ((0x01 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x01),
        crossZone: (0x08 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x08,
        normallyOpen: (0x20 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x20,
        specialLogged: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].SpecialLogged,
        nonActivityInput: (0x10 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x10,
        swingerLimit: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].SwingerLimit,
        doubleKnock: (0x04 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x04,
        maskTest: (0x40 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x40,
        inertiaInput: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].InertiaZoneDebounceTime_ms !== 0 && cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].InertiaZoneDebounceCount !== 0,
        inertiaDebounceCount: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].InertiaZoneDebounceCount,
        inertiaDebounceTime: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].InertiaZoneDebounceTime_ms,
        // soakTest: (0x02 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x02,
        hasPerZoneOptions: endpoints.infoZoneInfo.data.Info.zoneInfo.Zones[payload.index].PerZoneOptions,
        eolResistorRange: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].ZoneEol,
        eolResistorMode: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].EolMode,
        occupancy: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Occupancy,
        supervision: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Supervision,
        confirmGroup: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].ConfirmGroup,
      });

      const associatedDevices = getDevices(associatedWithDeviceType, associatedWithDeviceNumber, payload.index, endpoints);
      context.commit('set', {
        index: payload.index,
        name: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Name,
        omittable: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[payload.index].Omittable,
        areas: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Areas,
        associatedDevices,
        associatedWithDeviceNumber,
        associatedWithZoneIndex,
      });

      // Ensure wireless zones are awake.
      const AllDetectorsAwake = (await api.get('/Live/Devices/Wireless/ActivateAllDetectors')).data.Live.Devices.Wireless.ActivateAllDetectors;
      if (AllDetectorsAwake === false) {
        await api.put('/Live/Devices/Wireless/ActivateAllDetectors', true);
      }

      // Reset global variables
      wrlsZoneDiagnosticState = getDiagnostic;
      wrlsZoneDiagnosticRetryCount = 0;
      wrlsZonePreviousBatteryStatus = null;
      wrlsZonePreviousSignalStrength = null;
      // Reset the cached EOL resistance value when the page is loaded.
      cachedEol = null;

      // call onPoll as state now set (could do with a refactor with a nicer way for polling to get other endpoint data)
      await context.dispatch('onPoll', {
        key: 'liveAreaInfo', endpoint: endpoints.liveAreaInfo, payload, dataStatus: endpoints.liveAreaInfo.dataStatus,
      });
      context.commit('trySetDiagnosticByKey', {
        dataStatus: 'PARTIALLY_LOADED', key: 'wirelessZone.Diagnostics.BatteryStatus', collection: 'wirelessDiagnostics', item: { value: getDiagnostic },
      });
      context.commit('trySetDiagnosticByKey', {
        dataStatus: 'PARTIALLY_LOADED', key: 'wirelessZone.Diagnostics.SignalStrength', collection: 'wirelessDiagnostics', item: { value: getDiagnostic, format: '{0}' },
      });
    },
    async onPoll(context, {
      dataStatus, key, endpoint, payload,
    }) {
      if (key === liveZoneInfoConsts.key) {
        const state = getState(endpoint.data.Live.zoneInfo.Zones[payload.index].state);
        context.commit('trySet', { dataStatus, data: { globalState_state: state } });
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wiredZone.Diagnostics.Status',
          collection: 'wiredDiagnostics',
          item: {
            value: state,
            tooltip() {
              const value = this.value || 'FAULT';
              return i18n.t(`${this.key}.tooltips.${value}`);
            },
          },
        });
        // Zone end-of-line resistance in ohms.
        let eol = cachedEol;
        // 8486 is Unicode ohm sign Ω
        let formatString = '{0}k&#8486;';
        if ((endpoint.data.Live.zoneInfo.Zones[payload.index].EndOfLine_ohm !== null) && (endpoint.data.Live.zoneInfo.Zones[payload.index].EndOfLine_ohm !== 99999)) {
          if ((context.state.form.hasPerZoneOptions === true) && (endpoint.data.Live.zoneInfo.Zones[payload.index].EndOfLine_ohm > 50000)) {
            eol = 50.0;
            formatString = '&gt{0}k&#8486;';
          } else if ((context.state.form.hasPerZoneOptions === false) && (endpoint.data.Live.zoneInfo.Zones[payload.index].EndOfLine_ohm > 25000)) {
            eol = 25.0;
            formatString = '&gt{0}k&#8486;';
          } else {
            eol = roundToNumber(endpoint.data.Live.zoneInfo.Zones[payload.index].EndOfLine_ohm / 1000, 1);
          }
          cachedEol = eol;
        }
        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'wiredZone.Diagnostics.ZoneResistance', collection: 'wiredDiagnostics', item: { value: eol, format: formatString },
        });
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wiredZone.Diagnostics.State',
          collection: 'wiredDiagnostics',
          item: {
            value: endpoint.data.Live.zoneInfo.Zones[payload.index].state,
            tooltip() {
              return i18n.t(`${this.key}.tooltips.${this.value}`);
            },
          },
        });
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wirelessZone.Diagnostics.State',
          collection: 'wirelessDiagnostics',
          item: {
            value: endpoint.data.Live.zoneInfo.Zones[payload.index].state,
            tooltip() {
              return i18n.t(`${this.key}.tooltips.${this.value}`);
            },
          },
        });

        const omittable = endpoint.data.Live.zoneInfo.Zones[payload.index].Omittable;
        const managerOmitted = endpoint.data.Live.zoneInfo.Zones[payload.index].ManagerOmitted;
        const setOmitted = endpoint.data.Live.zoneInfo.Zones[payload.index].SetOmitted;
        const forceOmitted = endpoint.data.Live.zoneInfo.Zones[payload.index].ForceOmitted;

        context.commit('trySet', {
          dataStatus,
          data: {
            omittable,
            forceOmitted,
            setOmitted,
            managerOmitted,
          },
        });
      }

      if (key === 'liveAreaInfo' && context.state.areas != null) {
        let set = false;
        context.state.areas.forEach((area, i) => {
          if (area && endpoint.data.Live.areaInfo.Areas[i].AreaSet) {
            set = true;
          }
        });

        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'wiredZone.Diagnostics.ZoneStatus', collection: 'wiredDiagnostics', item: { value: set ? 'ARMED' : 'DISARMED' },
        });
      }

      if (key === liveWirelessZoneExpanderConsts.key && context.state.form.connectionType === 'TYPE_WIRELESS') {
        const detector = endpoint.data.Live.BusDevices.wirelessHubs[context.state.associatedWithDeviceNumber].Detectors[context.state.associatedWithZoneIndex];
        let batteryStatusValue = detector.BatteryStatus;
        let signalStrengthValue = detector.SignalLevel;
        const gotNullValue = batteryStatusValue === signalStrengthValue;

        // Initial state updating - Fetching for the first time
        if (gotNullValue && wrlsZoneDiagnosticState === getDiagnostic) {
          // Got null value in updating state;
          batteryStatusValue = wrlsZoneDiagnosticState;
          signalStrengthValue = wrlsZoneDiagnosticState;
          wrlsZoneDiagnosticRetryCount += 1;
          if (wrlsZoneDiagnosticRetryCount === wrlsZoneMaxDiagnosticUpdateLimit) {
            wrlsZoneDiagnosticRetryCount = wrlsZoneMaxDiagnosticRetryLimit; // force error state
            wrlsZoneDiagnosticState = errorState;
          }
        } else if (!gotNullValue) {
          // Got a valid value - transition from 'updating' or 'retrying' to 'valid' or remain in 'valid' state
          wrlsZoneDiagnosticState = validState;
          wrlsZonePreviousBatteryStatus = batteryStatusValue; // backup previous good state
          wrlsZonePreviousSignalStrength = signalStrengthValue; // backup previous good state
          wrlsZoneDiagnosticRetryCount = 0;
        } else if (gotNullValue && wrlsZoneDiagnosticState === validState && wrlsZoneDiagnosticRetryCount < wrlsZoneMaxDiagnosticRetryLimit) {
          // Got a null value in a valid state - silently retry for retry counts by masking the null value
          wrlsZoneDiagnosticRetryCount += 1;
          batteryStatusValue = wrlsZonePreviousBatteryStatus;
          signalStrengthValue = wrlsZonePreviousSignalStrength;
        } else if (wrlsZoneDiagnosticRetryCount >= wrlsZoneMaxDiagnosticRetryLimit) {
          // Exceeded retry attempts - don't mask null value
          wrlsZoneDiagnosticState = errorState;
          batteryStatusValue = wrlsZoneDiagnosticState;
          wrlsZonePreviousBatteryStatus = wrlsZoneDiagnosticState;
          signalStrengthValue = wrlsZoneDiagnosticState;
          wrlsZonePreviousSignalStrength = wrlsZoneDiagnosticState;
          wrlsZoneDiagnosticRetryCount += 1;
        }
        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'wirelessZone.Diagnostics.BatteryStatus', collection: 'wirelessDiagnostics', item: { value: batteryStatusValue },
        });
        if (detector.SignalLevel != null) {
          context.commit('trySet', { dataStatus, data: { globalState_signalLevel: detector.SignalLevel } });
        }
        let formatString = '{0}%';
        if ((context.state.globalState_state === 'FAULT') && (!context.state.globalState_signalLevel)) {
          signalStrengthValue = i18n.t('wirelessZone.Diagnostics.SignalStrength.supervision');
          formatString = '{0}';
          if (!context.state.globalState_BatteryStatus) {
            context.commit('trySetDiagnosticByKey', {
              dataStatus, key: 'wirelessZone.Diagnostics.BatteryStatus', collection: 'wirelessDiagnostics', item: { value: i18n.t('wirelessZone.Diagnostics.BatteryStatus.enum.MISSING'), format: formatString },
            });
          }
        } else if (wrlsZoneDiagnosticState !== validState) {
          formatString = '{0}';
        }
        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'wirelessZone.Diagnostics.SignalStrength', collection: 'wirelessDiagnostics', item: { value: signalStrengthValue, format: formatString },
        });
      }

      if (key === 'liveActiveFaultsWirelessInputs' && context.state.form.connectionType === 'TYPE_WIRELESS') {
        const {
          CaseTamper, Supervision, Battery, Polling,
        } = endpoint.data.Live.ActiveFaults.WirelessInputs[payload.index - limits.WE_ZONE_OFFSET];

        // Set the displayed supervision fault state (OK/fault).
        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'wirelessZone.Diagnostics.Supervision', collection: 'wirelessDiagnostics', item: { value: !Supervision },
        });

        // Set isWirelessFault to true if any radio detector fault is active; makes the status banner go red.
        if (CaseTamper === true || Supervision === true || Battery === true || Polling === true) {
          context.commit('trySet', { dataStatus, data: { isWirelessFault: true } });
        } else if (CaseTamper === false && Supervision === false && Battery === false && Polling === false) {
          context.commit('trySet', { dataStatus, data: { isWirelessFault: false } });
        }
      }

      // Set composite global state (base on state & signal level)
      if (context.state.form.connectionType === 'TYPE_WIRELESS' && (context.state.globalState_signalLevel === 0 || context.state.globalState_state === 'FAULT' || context.state.isWirelessFault === true)) {
        context.commit('trySet', { dataStatus, data: { globalState: 'FAULT' } });
      } else if (context.state.form.connectionType !== 'TYPE_WIRELESS' || context.state.globalState_signalLevel != null) {
        context.commit('trySet', { dataStatus, data: { globalState: context.state.globalState_state } });
      }
    },
    async save(context) {
      // When editing/saving the config of a zone, clear this variable to force recalculating
      // if any zones are in areas on the panel page.
      window.globalThis.zoneLevelsRequireReread = true;
      // unassociate existing device (if associated with has changed)
      if (context.state.index !== context.state.form.index) {
        await api.put(`/Config/zoneInfo/Zones/${context.state.index}/ZoneType`, 'ISOLATED');
      }

      const zoneIndex = context.state.form.index;
      const response = await api.get(`/Config/zoneInfo/Zones/${zoneIndex}`);

      const data = response.data.Config.zoneInfo.Zones[zoneIndex];

      data.Name = context.state.form.name;
      data.ZoneType = context.state.form.zoneType;
      data.Location = context.state.form.location;
      data.AnyAllArea = context.state.form.anyAllArea ? 1 : 0;
      data.Areas = context.state.form.areas;
      data.Attributes = 0;
      data.Chime = parseInt(context.state.form.chime, 10);
      data.Occupancy = context.state.form.occupancy;
      data.Supervision = context.state.form.supervision;
      data.ConfirmGroup = parseInt(context.state.form.confirmGroup, 10);
      if (context.state.form.allowByPass && canBeOmitted(context.state.form.zoneType)) data.Attributes += 0x01;
      if (context.state.form.crossZone) data.Attributes += 0x08;
      if (context.state.form.normallyOpen) data.Attributes += 0x20;
      if (context.state.form.nonActivityInput) data.Attributes += 0x10;
      if (context.state.form.doubleKnock) data.Attributes += 0x04;
      if (context.state.form.maskTest) data.Attributes += 0x40;
      // if (context.state.form.soakTest) data.Attributes += 0x02;
      data.SpecialLogged = context.state.form.specialLogged;
      data.SwingerLimit = parseInt(context.state.form.swingerLimit, 10);
      if (context.state.form.hasPerZoneOptions) {
        data.ZoneEol = context.state.form.eolResistorRange;
        data.EolMode = context.state.form.eolResistorMode;
      }
      if (context.state.form.inertiaInput) {
        data.InertiaZoneDebounceCount = parseInt(context.state.form.inertiaDebounceCount, 10);
        data.InertiaZoneDebounceTime_ms = parseInt(context.state.form.inertiaDebounceTime, 10);
      } else {
        data.InertiaZoneDebounceCount = 0;
        data.InertiaZoneDebounceTime_ms = 0;
      }

      await api.put(`/Config/zoneInfo/Zones/${zoneIndex}`, data);

      // Find the zone areas endpoints that this zone used before any potential changes (using the values cached on populate)
      const zoneAreasCached = JSON.parse(localStorage.getItem('vuex')).zonesState.zone.areas;
      const zoneAreasCachedEndpts = zoneAreasHelper.getZoneAreaEndpointsFromArea(context, zoneAreasCached);

      // Find the zone areas endpoints that this zone uses, after any potential changes (using latest values from context).
      const zoneAreas = context.state.form.areas;
      const zoneAreasChangedEndpts = zoneAreasHelper.getZoneAreaEndpointsFromArea(context, zoneAreas);

      // Merge the two endpoint lists
      const zoneAreaEndpointsToRefresh = zoneAreasCachedEndpts.concat(zoneAreasChangedEndpts);
      // Zone area endpoints may contain duplicates; de-dupe using Set constructor and spread syntax:
      const uniqZoneAreaEndpointsToRefresh = [...new Set(zoneAreaEndpointsToRefresh)];

      // Get the requiredEndpoints for this page
      let endpointsToUpdate = await this.dispatch('zonesState/zone/requiredEndpoints');
      // Handle the zone area endpoints: take them all away, then add only the ones that will change:
      endpointsToUpdate = endpointsToUpdate.filter(endp => !zoneAreasHelper.zoneAreasEndpointsList.includes(endp));
      uniqZoneAreaEndpointsToRefresh.forEach((endp) => {
        endpointsToUpdate = endpointsToUpdate.concat(endp);
      });

      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['zonesState/zone'] });
    },
    async deleteItem(context) {
      // When deleting an individual zone, clear this variable to force recalculating
      // if 'any zones are in areas' on the panel page.
      window.globalThis.zoneLevelsRequireReread = true;
      const objDefaults = await generateDefaultObject(`/Config/zoneInfo/Zones/${context.state.index}`);
      objDefaults.Name = `Zone ${_.padStart(context.state.index + 1, 3, '0')}`;
      objDefaults.Location = '                ';
      await api.put(`/Config/zoneInfo/Zones/${context.state.index}`, objDefaults);
      if (context.state.form.connectionType === 'TYPE_WIRELESS') {
        await api.put(`/Live/Devices/WirelessZones/${context.state.index - limits.WE_ZONE_OFFSET}/DeleteLearnedDevice`, true);
      }

      // Find the zone areas endpoints that this zone used, because they need to be refreshed.
      const zoneAreas = context.state.form.areas;
      const zoneAreaEndpointsToRefresh = zoneAreasHelper.getZoneAreaEndpointsFromArea(context, zoneAreas);
      // Zone area endpoints may contain duplicates; de-dupe using Set constructor and spread syntax:
      const uniqZoneAreaEndpointsToRefresh = [...new Set(zoneAreaEndpointsToRefresh)];
      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('zonesState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('zonesState/addZone/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      let endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);

      // Handle the zone area endpoints: take them all away, then add only the ones that will change:
      endpointsToUpdate = endpointsToUpdate.filter(endp => !zoneAreasHelper.zoneAreasEndpointsList.includes(endp));
      uniqZoneAreaEndpointsToRefresh.forEach((endp) => {
        endpointsToUpdate = endpointsToUpdate.concat(endp);
      });

      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['zonesState/list'] });
    },
  },
});

export default store;
