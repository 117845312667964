<template>

  <wizard :config="wizard" @save="finish" @done="cancel">
    <wizard-step name="start">
      <template slot="title">
          {{ $tc('addDevicesDrawer.autoscan.Scanning', 0, { count: devicesFound }) }}
          <a class="subtitle" @click="startAutoscan" v-if="!isLoading">{{ $t('common.Scan-again') }}</a>
      </template>

      <text-row v-if="keypads" :title="$tc('addDevicesDrawer.autoscan.KeypadsFound', keypadsFound, { count:keypadsFound })" :type="isLoading ? 'testing' : 'testComplete'" />
      <text-row v-if="readers" :title="$tc('addDevicesDrawer.autoscan.ReadersFound', readersFound, { count: readersFound })" :type="isLoading ? 'testing' : 'testComplete'" />
      <text-row v-if="zoneExpanders" :title="$tc('addDevicesDrawer.autoscan.ZoneExpandersFound', zoneExpandersFound, { count: zoneExpandersFound })" :type="isLoading ? 'testing' : 'testComplete'" />
      <text-row v-if="outputExpanders" :title="$tc('addDevicesDrawer.autoscan.OutputExpandersFound', outputExpandersFound, { count: outputExpandersFound })" :type="isLoading ? 'testing' : 'testComplete'" />
      <text-row :title="$tc('addDevicesDrawer.autoscan.DevicesFound', devicesFound, { count: devicesFound })" :type="isLoading ? 'testing' : 'testComplete'" />
      <a-button type="primary" block v-if="!isLoading" @click="finish">{{ $t('common.Finish') }}</a-button>

    </wizard-step>
  </wizard>

</template>

<script>
import { API } from '@/app/shared/services/api';

import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';
import autoscan from '@/app/shared/services/autoscan';
import { AsyncErrorHandling } from '@/app/shared/mixins';
import { AutoScanFailed } from '@/app/shared/errors';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';

const api = new API();

export default {
  mixins: [AsyncErrorHandling],
  components: {
    Wizard,
    WizardStep,
  },
  props: {
    keypads: { default: true },
    readers: { default: true },
    zoneExpanders: { default: true },
    outputExpanders: { default: true },
  },
  data() {
    return {
      isLoading: true,
      keypadsFound: 0,
      readersFound: 0,
      zoneExpandersFound: 0,
      outputExpandersFound: 0,
      devicesFound: 0,
      wizard: {
        start: {
          hideCancel: true,
        },
      },
    };
  },
  computed: {
  },
  created() {
    this.startAutoscan();
  },
  methods: {
    async startAutoscan() {
      this.isLoading = true;
      this.keypadsFound = 0;
      this.readersFound = 0;
      this.zoneExpandersFound = 0;
      this.outputExpandersFound = 0;
      this.devicesFound = 0;

      const results = await autoscan.scan({
        keypads: this.keypads,
        readers: this.readers,
        zoneExpanders: this.zoneExpanders,
        outputExpanders: this.outputExpanders,
      });

      this.keypadsFound = results.keypadsFound;
      this.readersFound = results.readersFound;
      this.zoneExpandersFound = results.zoneExpandersFound;
      this.outputExpandersFound = results.outputExpandersFound;
      this.devicesFound = results.totalDevicesFound;
      this.isLoading = false;
    },
    async cancel() {
      await autoscan.cancel();
      this.$emit('restart');
    },
    async finish() {
      try {
        await api.put('/Live/Devices/Autoscan/Bus/SaveScannedDevicesToConfig', true);

        // Fetch the required endpoint collections and store names for refresh, based on what devices we've scanned for
        // with autoscan complete, reload the zone-list and wizard endpoints
        // Get the requiredEndpoints for the list and wizard pages
        let endpointsToUpdateKP = [];
        let endpointsToUpdateZE = [];
        let endpointsToUpdateOE = [];
        let storesToRefreshKP = [];
        let storesToRefreshZE = [];
        let storesToRefreshOE = [];

        // get the requiredEndpoints for keypads and readers list page
        let requiredEndpointsList = await this.$store.dispatch('keypadsAndReadersState/list/requiredEndpoints');
        let requiredEndpointsAddDevice = (await this.$store.dispatch('keypadsAndReadersState/addKeypadOrReader/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
        endpointsToUpdateKP = requiredEndpointsList.concat(requiredEndpointsAddDevice);
        storesToRefreshKP = ['keypadsAndReadersState/list'];

        // get the requiredEndpoints for zone expanders list page
        requiredEndpointsList = await this.$store.dispatch('zoneExpandersState/list/requiredEndpoints');
        requiredEndpointsAddDevice = (await this.$store.dispatch('zoneExpandersState/addZoneExpander/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
        endpointsToUpdateZE = requiredEndpointsList.concat(requiredEndpointsAddDevice);
        storesToRefreshZE = ['zoneExpandersState/list'];

        // get the requiredEndpoints for output expanders list page
        requiredEndpointsList = await this.$store.dispatch('outputExpandersState/list/requiredEndpoints');
        requiredEndpointsAddDevice = (await this.$store.dispatch('outputExpandersState/addOutputExpander/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
        endpointsToUpdateOE = requiredEndpointsList.concat(requiredEndpointsAddDevice);
        storesToRefreshOE = ['outputExpandersState/list'];

        const endpointsToUpdate = endpointsToUpdateKP.concat(endpointsToUpdateZE, endpointsToUpdateOE);
        const storesToRefresh = storesToRefreshKP.concat(storesToRefreshZE, storesToRefreshOE);

        eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh });
      } catch (exception) {
        return;
      }
      this.$emit('done');
    },
  },
  async destroyed() {
    await autoscan.cancel();
  },
  async errorCaptured(err) {
    this.$notification.open({
      icon: <a-icon type="close-circle" style="color: #ff0000" />,
      placement: 'bottomRight',
      message: this.$t('errors.title'),
      description: err instanceof AutoScanFailed ? this.$t('errors.AutoScan.SCAN_FAILED') : undefined,
    });

    await this.cancel();
  },
};
</script>

<style lang="scss">
</style>
