import { createArray } from '@/app/shared/services/api';
import { storeFactory } from '@/app/shared/services/store-helper';
import { limits } from '@/app/shared/constants';
import { loadArea, pollPanelAreaLevels } from '@/app/panel/panel-areas/panel-areas-helpers';

let inPopulate = true;

function initialState() {
  return {
    items: createArray(limits.MAX_AREAS, i => ({ index: i })),
    areaTabs: [],
    areaActivityRunning: false,
    areaActivity: null,
    armingAreasFault: false,
    isZoneInArea: true,
    wasZoneInArea: true, // Store the state of last 'isZoneInArea' status to prevent polling config/zoneInfo/Zones each poll
    blockRemoteSet: false,
  };
}


const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints: () => ['infoAreaInfo', 'liveAreaInfo', 'configPanelAreaLevels'],

    async populate(context, { endpoints }) {
      inPopulate = true;
      // get hold of the 'block remote arming' configuration, used on dashboard web page
      const block = await api.get('/Config/sysInfo/MiscOptions/BlockRemoteSet');
      context.commit('set', { blockRemoteSet: block.data.Config.sysInfo.MiscOptions.BlockRemoteSet });

      for (let i = 0; i < limits.MAX_AREAS; i += 1) {
        context.commit('setItemByIndex', {
          index: i,
          item: {
            identifier: endpoints.infoAreaInfo.data.Info.areaInfo.Areas[i].Identifier,
            name: null,
            levels: createArray(limits.MAX_LEVELS, () => ({
              index: null,
              identifier: null,
              name: null,
            })),
            levelInfo: createArray(limits.MAX_LEVELS, () => false),
          },
        });
      }
      // we've initialised the levelInfo here, so need to refresh them on polling
      window.globalThis.zoneLevelsRequireReread = true;

      // Specify the maximum number of areas to display on each tab page.
      const areasPerTab = limits.MAX_AREAS > 15 ? 15 : limits.MAX_AREAS; // i.e. 15 areas per tab for 99 areas; 10 areas per tab for 10 areas
      // Calculate the number of tab pages needed to display ALL areas.
      const areaTabCount = Math.floor((limits.MAX_AREAS + (areasPerTab - 1)) / areasPerTab);
      // Set the state data for each tab page.
      for (let i = 0; i < areaTabCount; i += 1) {
        // Calculate the range of (zero-based) area indices (start...last+1) for this tab page.
        const startIndex = i * areasPerTab;
        const endIndex = Math.min(startIndex + areasPerTab, limits.MAX_AREAS);
        // Commit the state data for this tab page.
        context.commit('setItemByIndex', {
          index: i,
          collection: 'areaTabs',
          item: {
            startIndex,
            endIndex,
          },
        });
      }
      // fetch first page
      for (let i = 0; i < areasPerTab; i += 1) {
        /* eslint-disable no-await-in-loop */
        loadArea(api, context.state.items, i);
      }
      inPopulate = false;
    },
    async onPoll(context, { dataStatus, key, endpoint }) {
      if (context.state.areaActivityRunning) {
        return;
      }
      if (inPopulate) {
        return;
      }
      if (key === 'configPanelAreaLevels') {
        pollPanelAreaLevels(context, api, dataStatus);
        return;
      }
      if (key === 'liveAreaInfo') {
        const items = [];

        // Update the (dynamic) state data for each area.
        for (let i = 0; i < limits.MAX_AREAS; i += 1) {
          // Get the panel's live data for this area.
          const areaLive = endpoint.data.Live.areaInfo.Areas[i];
          const areaStatus = areaLive.AreaStatus;
          const levelsCanSet = areaLive.Levels.map(x => x.CanSet);

          const item = {
            areaStatus,
            zonesInAlarm: [],
            activeLevel: areaLive.ActiveLevel,
            levelsCanSet,
            inReArm: areaLive.inReArm,
          };

          const alertZones = endpoint.data.Live.areaInfo.Areas[i].AlertZones;
          const zone = alertZones.split(',');
          for (let z = 0; z < zone.length; z += 1) {
            const parts = zone[z].split(';');
            item.zonesInAlarm.push([parts[0], parts[1]]); // note possibility of zone names containing ;
          }

          for (let z = item.zonesInAlarm.length; z < limits.MAX_ZONES; z += 1) {
            item.zonesInAlarm.push([null, null]);
          }

          if (context.state.items[i]) {
            if (context.state.items[i].areaStatus === undefined) {
              item.initialAreaStatus = areaStatus;
            }
          }

          if (context.state.items[i]) {
            if (context.state.items[i].areaStatus === 'SETTING' && areaStatus !== 'SETTING' && areaStatus !== 'SET') {
              item.armingAreasFault = true;
            }
          }
          items.push(item);
        }

        // If 'isZoneInArea' was determined previously, use the previous state.
        if (!window.globalThis.zoneLevelsRequireReread) {
          // Use the previously determined state of isZoneInArea
          context.state.isZoneInArea = context.state.wasZoneInArea;
        }

        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: 'items', items });
      }
    },
    async startAreasActivity(context, { activity }) {
      context.commit('set', { areaActivityRunning: true, areaActivity: activity });
    },
    async endAreasActivity(context) {
      context.commit('set', { areaActivityRunning: false });
    },
    areaChanged(context, { index, state, levelIndex }) {
      // Update the (dynamic) state data for one area's arm state, including which level is active.
      context.commit('setItemByIndex', { index, item: { areaStatus: state, activeLevel: levelIndex } });
    },
  },
});

export default store;
