import { getField } from 'vuex-map-fields';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import {
  getWirelessZEMData,
  getWirelessZEMAddress,
  getWirelessZEMInfoAddresses,
} from '@/app/zone-expanders/shared/services/helpers';
import { limits } from '@/app/shared/constants';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';


function initialState() {
  return {
    form: {
      type: undefined,
      address: undefined,
      location: undefined,
    },

    availableAddresses: [],
    usedWirelessDeviceAddresses: [],
  };
}

const { api, store } = storeFactory(initialState, {
  getters: {
    getField,
  },
  actions: {
    requiredEndpoints() {
      return ['infoBusDevices', 'configBusDevices'];
    },
    async populate(context, { endpoints, payload }) {
      if (payload) {
        if (payload.type === 'TYPE_WIRED') {
          context.commit('setForm', {
            type: payload.type,
            location: endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[payload.index].Location,
          });
        } else if (payload.type === 'TYPE_WIRELESS') {
          context.commit('setForm', {
            type: payload.type,
            location: endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[payload.index].Location,
          });
        }
      }

      const usedWirelessDeviceAddresses = [];
      for (let i = 0; i < limits.MAX_WE_ZEM; i += 1) {
        if (endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[i].Installed) {
          const address = getWirelessZEMAddress(i, endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[i].ActiveInputs);
          usedWirelessDeviceAddresses.push(...address);
        }
      }

      const availableAddresses = [];
      for (let i = 0; i < limits.MAX_WIRED_ZEM; i += 1) {
        if (!endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[i].Installed) {
          availableAddresses.push(i);
        }
      }

      context.commit('set', { availableAddresses, usedWirelessDeviceAddresses });
    },
    async add(context) {
      const NUM_WE_PER_HUB = 4;
      const maxWirelessZemAddress = limits.MAX_WE_ZEM * NUM_WE_PER_HUB;
      const wePerHubAddressUpperLimit = [];
      if (context.state.form.type === 'TYPE_WIRED') {
        const data = {
          Location: context.state.form.location || ' '.repeat(16),
          Installed: true,
        };

        const { hubIndex, activeInputs } = getWirelessZEMInfoAddresses([context.state.form.address]);
        const activeInputIndex = activeInputs.indexOf(true);

        await api.put(`/Config/BusDevices/wiredHubs/${context.state.form.address}`, data);
        if (hubIndex != null) {
          const hubData = await getWirelessZEMData(api, hubIndex);
          const currentActiveInputs = hubData.ActiveInputs;
          const InstalledAddress = hubData.Installed;
          // Delete any previously learned Wireless ZEM at this address
          if (currentActiveInputs[activeInputIndex]) {
            currentActiveInputs[activeInputIndex] = false;
            InstalledAddress[activeInputIndex] = false;
            const wirelessZemData = {
              ActiveInputs: currentActiveInputs,
              Installed: InstalledAddress,
            };
            await api.put(`/Config/BusDevices/wirelessHubs/${hubIndex}`, wirelessZemData);
          }
        }
      } else {
        let addresses = [];
        const installedAddress = [];
        const activeInputIndex = [];

        // Get we (wireless zem) per hub upper limit address - each hub can have 4 addresses.
        const idx = maxWirelessZemAddress / NUM_WE_PER_HUB;
        for (let i = 0; i < idx; i += 1) {
          wePerHubAddressUpperLimit.push((i + 1) * NUM_WE_PER_HUB - 1);
        }
        const hub1UpperLimit = wePerHubAddressUpperLimit[0];
        const hub2UpperLimit = wePerHubAddressUpperLimit[1];
        const hub3UpperLimit = wePerHubAddressUpperLimit[2];

        if (context.state.form.address.length > 1) {
          addresses = context.state.form.address.split(', ');
        } else {
          addresses.push(context.state.form.address);
        }

        const { hubIndex, activeInputs } = getWirelessZEMInfoAddresses(addresses);
        // Individual address
        if (addresses.length === 1) {
          const address = addresses[0];
          for (let i = 0; i < maxWirelessZemAddress; i += 1) {
            if (i <= hub1UpperLimit && address <= hub1UpperLimit) {
              activeInputIndex.push(address === i);
              installedAddress.push(address === i);
            } else if (i > hub1UpperLimit && i <= hub2UpperLimit && address > hub1UpperLimit && address <= hub2UpperLimit) {
              activeInputIndex.push(address === i);
              installedAddress.push(address === i);
            } else if (i > hub2UpperLimit && i <= hub3UpperLimit && address > hub2UpperLimit && address <= hub3UpperLimit) {
              activeInputIndex.push(address === i);
              installedAddress.push(address === i);
            }
          }
        } else if (addresses.length === 4) {
          installedAddress.push(true);
        } else {
          const hubStartAddress = wePerHubAddressUpperLimit[hubIndex] + 1 - NUM_WE_PER_HUB;
          const hubAddressElements = [];
          for (let i = 0; i < NUM_WE_PER_HUB; i += 1) {
            hubAddressElements.push(hubStartAddress + i);
          }
          for (let i = 0; i < NUM_WE_PER_HUB; i += 1) {
            installedAddress.push(addresses.includes(hubAddressElements[i].toString()));
          }
        }

        const data = {
          Installed: installedAddress,
          ActiveInputs: addresses.length > 1 ? activeInputs : activeInputIndex,
        };

        const objDefaults = await generateDefaultObject(`/Config/BusDevices/wirelessHubs/${hubIndex}`);

        await api.put(`/Config/BusDevices/wirelessHubs/${hubIndex}`, objDefaults);
        await api.put(`/Config/BusDevices/wirelessHubs/${hubIndex}`, data);
        // Delete any previously learned wired ZEM at this address
        const wiredZemObjDefaults = await generateDefaultObject(`/Config/BusDevices/wiredHubs/${context.state.form.address}`);
        await api.put(`/Config/BusDevices/wiredHubs/${context.state.form.address}`, wiredZemObjDefaults);
      }

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('zoneExpandersState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('zoneExpandersState/addZoneExpander/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['zoneExpandersState/list'] });
    },
  },
});

export default store;
