import { getField } from 'vuex-map-fields';
import { createArray } from '@/app/shared/services/api';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { KEYPAD_AND_READER_TYPE } from '@/app/keypads-and-readers/shared/enums';
import { limits } from '@/app/shared/constants';
import { getDoorZones } from '@/app/keypads-and-readers/shared/services/helpers';

function initialState() {
  return {
    availableAddresses: [],
    areasInfo: undefined,
    areasConfig: undefined,
    zones: undefined,

    form: {
      type: undefined,
      name: undefined,
      location: undefined,
      address: undefined,
      setsAreas: createArray(limits.MAX_AREAS, () => false),
      unsetsAreas: createArray(limits.MAX_AREAS, () => false),
      inAreas: createArray(limits.MAX_AREAS, () => false),
      lockOpenTime: undefined,
      doorOpenTime: undefined,
      doorContactNumber: undefined,
      readerType: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {
    getField,
  },
  actions: {
    requiredEndpoints() {
      return ['configAreaInfoNames', 'configKeypadInfo', 'configZoneInfoParts', 'infoAreaInfo'];
    },
    async populate(context, { endpoints, payload }) {
      if (payload != null && payload.index != null) {
        const kpConfig = endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index];
        const type = KEYPAD_AND_READER_TYPE.fromInt(kpConfig.Type);
        context.commit('setForm', {
          type: type.key,
          name: kpConfig.Name,
          location: kpConfig.Location,
          setsAreas: kpConfig.KeypadSets,
          unsetsAreas: kpConfig.KeypadUnsets,
          inAreas: kpConfig.InAreas,
          lockOpenTime: kpConfig.LockOpenTime_secs,
          doorOpenTime: kpConfig.DoorOpenTime_secs,
          doorContactNumber: kpConfig.DoorContactZone,
          readerType: kpConfig.ReaderType,
        });
      }

      const availableAddresses = endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads
        .map((k, i) => ({ index: i, type: k.Type }))
        .filter(k => k.type === 2)
        .map(k => k.index);
      const doorZones = getDoorZones(endpoints.configZoneInfoParts);
      context.commit('set', {
        availableAddresses,
        areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
        areasConfig: endpoints.configAreaInfoNames.data.Config.areaInfo,
        zones: doorZones,
      });
    },
    async add(context) {
      const { form } = context.state;
      const keypadIndex = form.address;

      const data = {
        Type: KEYPAD_AND_READER_TYPE.fromKey(form.type).value,
        Name: form.name.padEnd(16),
        Location: form.location,
        KeypadSets: form.setsAreas,
        KeypadUnsets: form.unsetsAreas,
        InAreas: form.inAreas,
      };

      if (form.type === 'TYPE_READER') {
        if (form.lockOpenTime != null) {
          data.LockOpenTime_secs = parseInt(form.lockOpenTime || 0, 10);
        }
        if (form.doorOpenTime != null) {
          data.DoorOpenTime_secs = parseInt(form.doorOpenTime || 0, 10);
        }
        if (form.doorContactNumber != null) {
          data.DoorContactZone = parseInt(form.doorContactNumber || 0, 10);
        }
        if (form.readerType != null) {
          data.ReaderType = form.readerType;
        }
      }

      await api.put(`/Config/keypadInfo/Keypads/${keypadIndex}`, data);


      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('keypadsAndReadersState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('keypadsAndReadersState/addKeypadOrReader/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['keypadsAndReadersState/list'] });
    },
  },
});

export default store;
