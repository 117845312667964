import i18n from '@/app/shared/services/i18n';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import {
  translateOutputType, isSiren, isSirenOutputType, getAllOutputs, getOutputApiPath, generateAssociatedWithName, convertOutputType,
} from '@/app/outputs/shared/services/helpers';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';

function initialState() {
  return {
    outputTypes: [],
    _items: [],
  };
}

export function generateSirenName(type, number) {
  return i18n.t(`sirens.names.${type}`, { number });
}

function isVisible(output) {
  let isPresent = true;
  const isUsed = output.outputType !== 0;
  const isBell = (isSirenOutputType(output.outputType) || isSiren(output.associatedWith));
  if (output.associatedWith.type === 'ENDSTATION' && output.present === 0) {
    isPresent = false;
  }

  return isBell && isPresent && isUsed;
}

const { store, api } = storeFactory(initialState, {
  getters: {
    max: state => state._items.filter(i => (i.visible || i.outputType === 0) && i.associatedWith.type !== 'WIRELESS_ZONE_EXPANDER').length + 2,
    installed: state => state._items.filter(i => i.visible).length,
    items: state => state._items.filter(i => i.visible),
  },
  actions: {
    requiredEndpoints() {
      // getAllOutputs() needs: configBusDevices configEndstation configKeypadInfo infoBusDevices infoKeypadInfo infoEndstation infoOutputInfo livePanelStatus
      return ['configBusDevices', 'configEndstation', 'configKeypadInfo', 'configRadioDevicesLearnt', 'infoBusDevices', 'infoEndstation', 'infoKeypadInfo', 'infoOutputInfo', 'liveActiveFaults', 'livePanelStatus'];
    },
    async populate(context, { endpoints }) {
      const outputTypes = [];
      for (let i = 0; i < endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes.length; i += 1) {
        const outputType = endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes[i].Name;
        outputTypes[i] = { key: outputType, name: translateOutputType(outputType), isSirenOutputType: isSirenOutputType(i) };
      }

      const settingsTooltip = i18n.t('sirens.options.settingsTooltip');
      const cloneTooltip = i18n.t('sirens.options.cloneTooltip');
      const deleteTooltip = i18n.t('sirens.options.deleteTooltip');
      const toggleEnabledTooltip = i18n.t('sirens.options.enabledTooltip');
      const toggleDisabledTooltip = i18n.t('sirens.options.disabledTooltip');

      const allSirens = getAllOutputs(endpoints, true);

      const wiredSirens = allSirens
        .filter(o => o.associatedWith.type !== 'WIRELESS_ZONE_EXPANDER')
        .map(output => ({
          index: output.outputIndex,
          type: 'WIRED_SIREN',
          name: output.name,
          location: output.location,
          number: output.number,
          rawOutputType: output.rawOutputType,
          outputType: output.outputType,
          outputTypeName: outputTypes[convertOutputType(output.outputType)].key,
          associatedWith: output.associatedWith,
          state: output.state,
          visible: isVisible(output),
          settingsTooltip,
          cloneTooltip,
          deleteTooltip,
          toggleEnabledTooltip,
          toggleDisabledTooltip,
        }));

      const wirelessZEMOutputs = allSirens.filter(o => o.associatedWith.type === 'WIRELESS_ZONE_EXPANDER' && o.associatedWith.deviceIndex === 0 && o.associatedWith.deviceOutputIndex < 2);

      const wirelessSirens = [];
      for (let i = 0; i < 2; i += 1) {
        const wirelessSiren = {
          index: 3000 + i,
          wirelessSirenIndex: i,
          type: 'WIRELESS_SIREN',
          name: generateSirenName('WIRELESS', i + 1),
          location: generateAssociatedWithName('WIRELESS_ZONE_EXPANDER', 0),
          number: i + 1,
          rawOutputType: wirelessZEMOutputs.length > 0 ? { bell: wirelessZEMOutputs[0].rawOutputType, strobe: wirelessZEMOutputs[1].rawOutputType } : null,
          outputType: wirelessZEMOutputs.length > 0 ? { bell: wirelessZEMOutputs[0].outputType, strobe: wirelessZEMOutputs[1].outputType } : null,
          associatedWith: wirelessZEMOutputs.length > 0 ? wirelessZEMOutputs[0].associatedWith : null,
          state: wirelessZEMOutputs.length > 0 ? wirelessZEMOutputs[0].state === true || wirelessZEMOutputs[1].state === true : null,
          visible: endpoints.configRadioDevicesLearnt.data.Config.RadioDevicesLearnt.RadioBellsLearnt[i],
          settingsTooltip,
          cloneTooltip,
          deleteTooltip,
          toggleEnabledTooltip,
          toggleDisabledTooltip,
        };
        wirelessSirens.push(wirelessSiren);
      }

      context.commit('set', { outputTypes, _items: wiredSirens.concat(wirelessSirens) });

      try {
        context.dispatch('onPoll', { key: 'liveActiveFaults', endpoint: endpoints.liveActiveFaults, dataStatus: endpoints.liveActiveFaults.dataStatus });
        context.dispatch('onPoll', { key: 'livePanelStatus', endpoint: endpoints.livePanelStatus, dataStatus: endpoints.livePanelStatus.dataStatus });
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    async onPoll(context, { dataStatus, key, endpoint }) {
      if (key === 'livePanelStatus') {
        const items = [];
        for (let i = 0; i < context.state._items.length; i += 1) {
          const { rawOutputType } = context.state._items[i];
          if (rawOutputType != null && rawOutputType.bell != null && rawOutputType.strobe != null) {
            const bellOutputTypeState = endpoint.data.Live.PanelStatus.OutputTypes[rawOutputType.bell].state;
            const strobeOutputTypeState = endpoint.data.Live.PanelStatus.OutputTypes[rawOutputType.strobe].state;
            items.push({ state: bellOutputTypeState === true || strobeOutputTypeState === true });
          } else if (rawOutputType == null) {
            items.push({ state: null });
          } else {
            items.push({ state: endpoint.data.Live.PanelStatus.OutputTypes[rawOutputType].state });
          }
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: '_items', items });
      }

      if (key === 'liveActiveFaults') {
        const items = [];
        for (let i = 0; i < context.state._items.length; i += 1) {
          const { associatedWith, type, wirelessSirenIndex } = context.state._items[i];
          if (type === 'WIRED_SIREN') {
            items.push({
              faultState: associatedWith.type === 'ENDSTATION' && associatedWith.subType === 'PGMs' && associatedWith.deviceOutputIndex === 0
                ? !endpoint.data.Live.ActiveFaults.Devices.Endstation.BellFuse && !endpoint.data.Live.ActiveFaults.Devices.Endstation.BellTamper && !endpoint.data.Live.ActiveFaults.Devices.Endstation.WarningDevice
                : true,
            });
          } else {
            items.push({
              faultState: associatedWith != null
                && !endpoint.data.Live.ActiveFaults.WirelessOutputs[wirelessSirenIndex].Battery
                && !endpoint.data.Live.ActiveFaults.WirelessOutputs[wirelessSirenIndex].CaseTamper
                && !endpoint.data.Live.ActiveFaults.WirelessOutputs[wirelessSirenIndex].Polling
                && !endpoint.data.Live.ActiveFaults.WirelessOutputs[wirelessSirenIndex].Supervision,
            });
          }
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: '_items', items });
      }
    },
    async delete(context, { indexes }) {
      for (let i = 0; i < indexes.length; i += 1) {
        const { type, deviceIndex, deviceOutputIndex } = context.state._items.find(s => s.index === indexes[i]).associatedWith;
        if (type === 'WIRELESS_ZONE_EXPANDER') {
          const { wirelessSirenIndex } = context.state._items.find(s => s.index === indexes[i]);
          // eslint-disable-next-line no-await-in-loop
          await api.put(`/Live/Devices/WirelessSirens/${wirelessSirenIndex}/DeleteLearnedDevice`, true);
        } else {
          const path = getOutputApiPath(type, deviceIndex, deviceOutputIndex);
          // eslint-disable-next-line no-await-in-loop
          const objDefaults = await generateDefaultObject(path);
          // eslint-disable-next-line no-await-in-loop
          await api.put(path, objDefaults);
        }
      }
      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('sirensState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('sirensState/addSiren/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['sirensState/list'] });
    },
  },
});

export default store;
