import { API } from '@/app/shared/services/api';
import { limits } from '@/app/shared/constants';

const api = new API();
const zoneInfoEndpoint = '/Live/zoneInfo/Zones';

class WalkTest {
  testRunning = null;

  dropPolledEndpoint = () => {
    // Drop walk test specific endpoints from the current context
    const temp = window.globalThis.currentRequiredEndpoints;
    try {
      window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== zoneInfoEndpoint);
    } catch (err) {
      // nothing to do
    }
  }

  async startZoneTest(zone, onZoneFound, resetZone) {
    await api.put(`/Live/zoneInfo/Zones/${zone.index}`, { WalkTestOpened: false, WalkTestClosed: false });
    await api.put('/Live/Diagnostics/ZoneWalkTest', zone.index);

    this.testRunning = 'ZONE';

    await this._monitorZones([zone], onZoneFound, resetZone);

    await api.put('/Live/Diagnostics/ZoneWalkTest', -1);
    this.testRunning = null;
  }

  async startAreasTest(areas, zones, onZoneFound) {
    // Reset the "walk test complete" status of each zone that's to be walk tested.
    for (let i = 0; i < zones.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await api.put(`/Live/zoneInfo/Zones/${zones[i].index}`, { WalkTestOpened: false, WalkTestClosed: false });
    }

    // Actually start the walk test.
    await api.put('/Live/Diagnostics/AreasWalkTest', areas);
    this.testRunning = 'AREAS';

    // Monitor the "walk test complete" status of each zone that's being walk tested.
    await this._monitorZones(zones, onZoneFound);
    await api.put('/Live/Diagnostics/AreasWalkTest', areas.fill(false, 0));
    this.testRunning = null;
  }

  async _monitorZones(zones, onZoneFound, resetZone) {
    const zonesFound = [];

    let allFoundExternalPromiseResolver;
    const allFoundPromise = new Promise((resolve) => {
      allFoundExternalPromiseResolver = resolve;
    });
    // Bring the ad-hoc walk test endpoint into the scope of current endpoints
    if (window.globalThis.currentRequiredEndpoints.indexOf(zoneInfoEndpoint) === -1) {
      window.globalThis.currentRequiredEndpoints.push(zoneInfoEndpoint);
    }

    const pollingCall = api.poll(500, zoneInfoEndpoint, async (res) => {
      for (let i = 0; i < zones.length; i += 1) {
        if (res.data.Live.zoneInfo.Zones[zones[i].index].WalkTestClosed && res.data.Live.zoneInfo.Zones[zones[i].index].WalkTestOpened) {
          onZoneFound(zones[i]);
          if (!zonesFound.includes(zones[i].index)) {
            zonesFound.push(zones[i].index);
          }

          if (this.testRunning === 'ZONE' && resetZone != null) {
            // wait 1 sec before re-setting state
            setTimeout(async () => {
              await api.put(`/Live/zoneInfo/Zones/${zones[i].index}`, { WalkTestOpened: false, WalkTestClosed: false });
              resetZone(zones[i]);
            }, 1000);
          }
        }
      }

      if (zonesFound.length === zones.length && this.testRunning !== 'ZONE') {
        allFoundExternalPromiseResolver();
        this.dropPolledEndpoint();
        return false;
      }

      return true;
    });

    await allFoundPromise;
    pollingCall.polling.cancel();
    this.dropPolledEndpoint();
  }

  async finish() {
    api.cancelPolling();
    this.dropPolledEndpoint();
    const areas = Array(limits.MAX_AREAS).fill(false, 0);
    if (this.testRunning === 'AREAS') {
      await api.put('/Live/Diagnostics/AreasWalkTest', areas);
      this.testRunning = null;
    }
    if (this.testRunning === 'ZONE') {
      await api.put('/Live/Diagnostics/ZoneWalkTest', -1);
      this.testRunning = null;
    }
  }
}

const walkTest = new WalkTest();

export default walkTest;
