/* eslint-disable no-param-reassign */
import TwoColumnLayout from '@/app/shared/components/layouts/two-col-layout.vue';
import ThreeColumnLayout from '@/app/shared/components/layouts/three-col-layout.vue';
import SingleColumnLayout from '@/app/shared/components/layouts/single-col-layout.vue';
import PageNavigationLayout from '@/app/shared/components/layouts/page-navigation-layout.vue';
import DefaultLayout from '@/app/shared/components/layouts/default-layout.vue';

const Templates = {
  install(Vue) {
    Vue.component('two-col-layout', TwoColumnLayout);
    Vue.component('three-col-layout', ThreeColumnLayout);
    Vue.component('single-col-layout', SingleColumnLayout);
    Vue.component('page-navigation-layout', PageNavigationLayout);
    Vue.component('default-layout', DefaultLayout);
  },
};

export default Templates;
