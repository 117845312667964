import { storeFactory } from '@/app/shared/services/store-helper';
import { LOGIC_GATE_TYPE } from '@/app/outputs/shared/enums';
import i18n from '@/app/shared/services/i18n';
import { createRawOutputsList } from '@/app/outputs/shared/services/helpers';

function initialState() {
  return {
    _items: [],
    _outputTypes: [],
  };
}

const { store } = storeFactory(initialState, {
  getters: {
    items: state => state._items,
    outputTypes: state => state._outputTypes,
  },
  actions: {
    requiredEndpoints() {
      return ['infoOutputInfo', 'configModularOutput', 'configOutputInfo'];
    },
    async populate(context, { endpoints }) {
      // rawOutputTypes used by logic-gate-list.vue page to handle customRender for input triggers
      const udOutputs = endpoints.configOutputInfo.data.Config.outputInfo.UserDefinedOutputs;
      const rawOutputTypes = createRawOutputsList(endpoints.infoOutputInfo.data.Info.outputInfo, udOutputs);
      const totalGates = endpoints.configModularOutput.data.Config.modularOutput.OutputLogicGate.length;

      const items = [];
      for (let i = 0; i < totalGates; i += 1) {
        // Lookup the Logic Gate name in the internationalisation list e.g. 'LOGIC_GATE_04' is 'Logic gate 5'
        const translatedName = i18n.t(`enums.OUTPUT_TYPE.LOGIC_GATE_${i.toString().padStart(2, '0')}`);

        const gate = endpoints.configModularOutput.data.Config.modularOutput.OutputLogicGate[i];
        items.push({
          name: translatedName,
          // Store the gateType whose key is GateType value e.g. 'NOT_USED_GATE'
          gateType: LOGIC_GATE_TYPE.fromKey(gate.GateType),
          index: i,
          number: (i + 1).toString(), // Number shown to user is index+1
          inputTrigger0: gate.Input[0],
          inputTrigger1: gate.Input[1],
          inputTrigger2: gate.Input[2],
          inputTrigger3: gate.Input[3],
        });
      }

      context.commit('set', {
        _items: items,
        _outputTypes: rawOutputTypes,
      });
    },
  },
});

export default store;
