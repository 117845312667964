import Vue from 'vue';
import Vuelidate from 'vuelidate';

import App from './app/app.vue';
import router from './app/app-routes';
import store from './app/app-state';
import i18n from './app/shared/services/i18n';

import AntDesignVue from './plugins/AntDesignVue';
import FontAwesome from './plugins/FontAwesome';
import Templates from './plugins/Templates';
import PyronixFieldTypes from './plugins/PyronixFieldTypes';
import StringFormat from '@/plugins/StringFormat'; // eslint-disable-line import/order
import TextRow from '@/app/shared/components/Common/TextRow.vue'; // eslint-disable-line import/order

import Diagnostics from '@/app/shared/components/diagnostics/diagnostics.vue'; // eslint-disable-line import/order

Vue.component('text-row', TextRow);

Vue.component('diagnostics', Diagnostics);

// Vue.use(ExceptionHandler);
Vue.use(AntDesignVue);
Vue.use(FontAwesome);
Vue.use(Templates);
Vue.use(PyronixFieldTypes);
Vue.use(StringFormat);

Vue.use(Vuelidate);

Vue.config.productionTip = false;

// Reactive global variable to determine if LAN's mode setting is in progress
const gLanModeSetting = Vue.observable({
  // Flag to control/prevent 'Take Control' banner from popping up when LAN mode is being set.
  lanModeSettingInProgress: false,
  // Flag to indicate whether user can navigate away from panel page -user can't navigate until requested mode is set.
  panelPageModeForNavigation: null,
  // boolean flag to indicate if panel ('Dashboard') page is displayed.
  currentPageIsPanel: false,
  // Flag to control the 'Please wait' navigation banner when mode is being set and navigation is prevented.
  panelPageStopNavigationBanner: null,
  // To be used to prevent LAN navigation when panel is being armed.
  armingDisarmingInProgress: null,
  // Debug code to investigate BUG-2122.
  // Flag to check if panel page creation is taking too long.
  pageCreated: false,
  // Flag to check if panel page mounting is taking too long.
  pageMounted: false,
});

Vue.prototype.$gLanModeSetting = gLanModeSetting;

// Global variable to track the current required and ad-hoc polled endpoints for the active LAN page and sub-page.
window.globalThis.currentRequiredEndpoints = [];

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
