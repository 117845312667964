<template>
    <div class="styled-section">
        <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
            <div>
              <a-row type="flex" justify="space-between">
                <a-col>
                  <h3 v-if="endstation" class="h4">{{ $t('common.Endstation-Outputs') }}</h3>
                  <h3 v-else class="h4">{{ $t('common.Associated-Outputs') }}</h3>
                </a-col>
                <a-col v-if="enableAddOutput">
                  <a-button @click="addDrawerVisible = true">{{ $t('outputs.Add-output') }}</a-button>
                </a-col>
              </a-row>
            <p class="subtitle" v-if="max"><span>{{ installed || items.length }}/{{ max }}</span> {{ $t('common.Outputs') }}</p>
            <a-row :gutter="16">
                <template v-for="(item, index) in items">
                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 12}" :key="`output-${index}`">
                  <div @click.stop="navigateToOutput(item)">
                    <card>
                    <template slot:status>
                        <status :state="item.state ? 'active' : 'error'" />
                    </template>
                    <card-header>
                        <div>

                          <h3 v-if="item.name"><template v-if="item.number != null" >{{ item.number }} - </template>{{ item.name }}</h3>
                          <h3 v-else><template v-if="item.number != null" >{{ item.number }} - </template>{{ item.translatedOutputType }}</h3>
                        </div>
                        <template slot="actions">
                          <div @click="$event.stopPropagation()">
                            <a-tooltip>
                              <template slot="title">
                                <span v-if="item.state">{{ $t('common.Associated-Outputs-Active-Tooltip') }}</span>
                                <span v-else>{{ $t('common.Associated-Outputs-Inactive-Tooltip') }}</span>
                              </template>
                              <a-switch :checked="item.state" @change="activateOutput(index, item, !item.state)" />
                            </a-tooltip>
                          </div>
                        </template>
                    </card-header>
                    </card>
                  </div>
                </a-col>
                </template>
                <a-col class="gutter-row" v-if="items.length < 1">
                <p>{{ $t('common.No-associated-outputs') }}</p>
                </a-col>
            </a-row>
            </div>
        </a-skeleton>

      <add-output v-if="enableAddOutput" :visible="addDrawerVisible" :navigateToNewItem="false" @onClose="addDrawerVisible = false" :associatedDeviceKey="associatedDeviceKey" />
    </div>
</template>

<script>
import Card from '@/app/shared/components/Card/card.vue';
import CardHeader from '@/app/shared/components/Card/Child/cardHeader.vue';
import Status from '@/app/shared/components/Status/status.vue';
import AddOutput from '@/app/outputs/shared/components/add-output/add-output.vue';

export default {
  components: {
    Card,
    CardHeader,
    Status,
    AddOutput,
  },
  props: {
    items: { default: [] },
    isLoading: { default: true },
    max: { default: undefined },
    installed: { default: null },
    enableAddOutput: { default: false },
    associatedDeviceKey: { default: null },
    endstation: { default: undefined },
  },
  data() {
    return {
      addDrawerVisible: false,
    };
  },
  methods: {
    async activateOutput(index, item, checked) {
      this.$emit('activate', index, item, checked);
    },
    navigateToOutput(output) {
      this.$gLanModeSetting.currentPageIsPanel = false; // No longer showing dashboard page.
      if (output.associatedWith.type === 'wireless-siren') {
        this.$router.push({ name: 'wireless-siren', params: { index: output.associatedWith.outputIndex } });
      } else if (output.associatedWith.type === 'wired-siren') {
        this.$router.push({ name: 'wired-siren', params: { index: output.associatedWith.outputIndex } });
      } else {
        this.$router.push({ name: 'output', params: { index: output.associatedWith.outputIndex } });
      }
    },
  },
};
</script>

<style lang="scss">

</style>
