import { storeFactory } from '@/app/shared/services/store-helper';
import { translateOutputType } from '@/app/outputs/shared/services/helpers';

function initialState() {
  return {
    outputTypes: [],
  };
}

const { store } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['infoOutputInfo'];
    },
    async populate(context, { endpoints }) {
      const outputTypes = [];

      for (let i = 0; i < endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes.length; i += 1) {
        const outputType = endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes[i].Name;
        if (outputType != null) {
          outputTypes.push({
            value: i, key: outputType, name: translateOutputType(outputType),
          });
        }
      }

      context.commit('set', {
        outputTypes,
      });
    },
    async onPoll(context, { key, dataStatus, endpoint }) {
      if (key === 'liveZoneInfoDaysOnSoak') {
        context.commit('trySet', { dataStatus, data: { daysLeft: endpoint.data.Live.zoneInfo.DaysOnSoak } });
      }
    },
  },
});


export default store;
