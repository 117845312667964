<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('settings.siteOptions.navigationTitle') }}</h2>
      </a-row>
      <a-form>
        <p-switch :label="$t('settings.siteOptions.Fob-unset-entry')" :model="$v.form.fobUnsetEntry" style="width: 1000px"
                  :labelTooltip="$t('settings.siteOptions.Fob-unset-entry-tooltip')"
                  :switchTooltip="toggleTooltipText($v.form.fobUnsetEntry)" display-style="small"
                  :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.siteOptions.Download-if-set')" style="width: 1000px"
                  :model="$v.form.downloadIfSet"
                  :labelTooltip="$t('settings.siteOptions.Download-if-set-tooltip')"
                  :switchTooltip="toggleTooltipText($v.form.downloadIfSet)" display-style="small"
                  :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.siteOptions.Invert-ATE-Outputs')" :model="$v.form.invertATEOutputs" display-style="small" style="width: 1000px"
                  :labelTooltip="$t('settings.siteOptions.Invert-ATE-Outputs-tooltip')" :switchTooltip="toggleTooltipText($v.form.invertATEOutputs)"
                  :disabled="!configWriteLock.canWriteConfig" />
        <p-switch v-if="$v.form.wirelessFireInterlink.$model !== undefined"
                  :label="$t('settings.siteOptions.Wireless-fire-interlink')" style="width: 1000px"
                  :model="$v.form.wirelessFireInterlink"
                  :labelTooltip="$t('settings.siteOptions.Wireless-fire-interlink-tooltip')"
                  :switchTooltip="toggleTooltipText($v.form.wirelessFireInterlink)" display-style="small"
                  :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.siteOptions.Battery-load-test')" :model="$v.form.batteryLoadTest" display-style="small" style="width: 1000px"
                  :labelTooltip="$t('settings.siteOptions.Battery-load-test-tooltip')" :switchTooltip="toggleTooltipText($v.form.batteryLoadTest)"
                  :disabled="!configWriteLock.canWriteConfig" />
      </a-form>
      <div class="align-right">
          <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
      </div>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import TooltipHelper from '@/app/shared/services/tooltipHelper';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'settingsState/siteOptions';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
    };
  },
  computed: {
    ...mapState(['form']),
  },
  validations: {
    form: {
      fobUnsetEntry: {},
      downloadIfSet: {},
      invertATEOutputs: {},
      wirelessFireInterlink: {},
      batteryLoadTest: {},
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
    toggleTooltipText: TooltipHelper.toggleTooltipText,
  },
};
</script>
