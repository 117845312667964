<template>
    <a-layout>
        <div class="topBar">
            <a-button class="mobile-nav-button">
                <font-awesome-icon  :icon="['fal', 'bars']" @click="menuOpen = !menuOpen" />
            </a-button>
        </div>
        <a-layout-sider class="sideNavigation" width="300px" :class="{'sideNavigation--open': menuOpen}">
            <mainNavigation :configWriteLock="configWriteLock" class="fixed-navigation" />
        </a-layout-sider>
        <a-layout>
        <a-layout-content :style="{ margin: '24px 16px 0', overflow: 'initial' }">
            <breadcrumb />
            <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
            <a-row :gutter="23" class="header">
                <a-col :xs="{ span: 24}" :md="{ span: 18}" :lg="{ span: 18}" :xl="{ span: 18}">
                    <slot name="title" />
                </a-col>

                <a-col :xs="{ span: 24}" :md="{ span: 6}" :lg="{ span: 6}" :xl="{ span: 6}" class="align-right">
                    <slot name="titleCommands" />
                </a-col>
            </a-row>

            <slot />
        </a-layout-content>
        </a-layout>
  </a-layout>
</template>

<script>
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';
import mainNavigation from '@/app/shared/components/Navigation/MainNavigation/MainNavigation.vue';
import breadcrumb from '@/app/shared/components/Breadcrumb/breadcrumb.vue';

export default {
  name: 'two-col-layout',
  components: {
    breadcrumb,
    configWriteLockAlert,
    mainNavigation,
  },
  props: {
    configWriteLock: {
      default() {
        return {
          canWriteConfig: true,
          lockOwner: null,
        };
      },
    },
    anyAreaSet: { default: false },
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    setMine(value) {
      this.$emit('setMine-event', value);
    },
  },
};
</script>
