import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import {
  getAllOutputs, getOutputInfo, USER_DEFINED_OUTPUT_BEGIN, isUserDefined,
} from '@/app/outputs/shared/services/helpers';

function initialState() {
  return {
    name: undefined,
    state: undefined,
    associatedOutputs: [],

    form: {
      name: undefined,
      outputType: undefined,
      number: undefined,
      index: undefined,
      outputOnTime: undefined,
    },

    diagnostics: [
      { key: 'output.Diagnostics.Status', isLoading: true, value: undefined },
    ],
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {},
  actions: {
    requiredEndpoints() {
      return ['infoOutputInfo', 'infoBusDevices', 'infoKeypadInfo', 'infoEndstation', 'configOutputInfo', 'configEndstation', 'configBusDevices', 'configKeypadInfo', 'livePanelStatus'];
    },
    async populate(context, { endpoints, payload }) {
      const output = endpoints.configOutputInfo.data.Config.outputInfo.UserDefinedOutputs[payload.index];
      context.commit('setForm', {
        name: output.UserOutputName,
        outputType: output.UserOutputLatchedOrTimed,
        number: payload.index + 1,
        index: payload.index,
        outputOnTime: output.UserOutputOnTime_secs,
      });

      const associatedOutputs = getAllOutputs(endpoints)
        .filter(o => (isUserDefined(o.outputType) && (o.outputType - USER_DEFINED_OUTPUT_BEGIN) === payload.index))
        .map(o => ({
          name: o.name,
          type: o.associatedWith.type,
          deviceIndex: o.associatedWith.deviceIndex,
          deviceOutputIndex: o.associatedWith.deviceOutputIndex,
          associatedWith: getOutputInfo('USER_DEFINED_OUTPUT', null, o.outputIndex, o.outputType),
          state: o.state,
          outputType: o.outputType,
        }));

      context.commit('set', {
        name: output.UserOutputName,
        associatedOutputs,
      });
    },
    async onPoll(context, { dataStatus, key, endpoint }) {
      if (key === 'livePanelStatus') {
        // associated devices
        const items = [];
        for (let i = 0; i < context.state.associatedOutputs.length; i += 1) {
          const { state } = endpoint.data.Live.PanelStatus.OutputTypes[context.state.associatedOutputs[i].outputType];
          items.push({ state });
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: 'associatedOutputs', items });
        // main on/off switch
        const { state } = endpoint.data.Live.PanelStatus.OutputTypes[context.state.form.index + USER_DEFINED_OUTPUT_BEGIN];
        context.commit('trySet', { dataStatus, data: { state } });
        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'output.Diagnostics.Status', item: { value: state ? 'ON' : 'OFF', type: state ? 'OK' : 'ERROR' },
        });
      }
    },
    async save(context) {
      const { index } = context.state.form;
      await api.put(`/Config/outputInfo/UserDefinedOutputs/${index}`, {
        UserOutputName: context.state.form.name,
        UserOutputLatchedOrTimed: context.state.form.outputType,
        UserOutputOnTime_secs: parseInt(context.state.form.outputOnTime, 10),
      });

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('outputsState/userDefinedOutput/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['outputsState/userDefinedOutput'] });
    },
  },
});

export default store;
