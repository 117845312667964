<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('settings.globalVolume.navigationTitle') }}</h2>
      </a-row>
      <a-form>
        <p-switch v-if="this.soundAtKeypadsOnlyVisible" :label="$t('settings.globalVolume.Use-main-sounder')" :model="$v.form.soundAtKeypadsOnly" :labelTooltip="$t('settings.globalVolume.Use-main-sounder-tooltip')" :switchTooltip="toggleTooltipText($v.form.soundAtKeypadsOnly)" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.globalVolume.Silent-tech-alert')" :model="$v.form.silentTechAlert" :labelTooltip="$t('settings.globalVolume.Silent-tech-alert-tooltip')" :switchTooltip="toggleTooltipText($v.form.silentTechAlert)" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.globalVolume.Disable-call-fault')" :model="$v.form.disableCallFault" :labelTooltip="$t('settings.globalVolume.Disable-call-fault-tooltip')" :switchTooltip="toggleTooltipText($v.form.disableCallFault)" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
      </a-form>
      <div class="align-right">
        <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
      </div>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import TooltipHelper from '@/app/shared/services/tooltipHelper';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'settingsState/globalVolume';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
    };
  },
  computed: {
    ...mapState(['form', 'soundAtKeypadsOnlyVisible']),
  },
  validations: {
    form: {
      codeStopSound: {},
      soundAtKeypadsOnly: {},
      silentTechAlert: {},
      disableCallFault: {},
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
    toggleTooltipText: TooltipHelper.toggleTooltipText,
  },
};
</script>
