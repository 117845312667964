/* eslint-disable class-methods-use-this */
import { API } from '@/app/shared/services/api';
import { liveWiredZoneExpanderConsts, liveWirelessZoneExpanderConsts } from '@/app/shared/constants';

const api = new API();

// A count of the pages using modified polling.
let customPollPageCount = 0;

class ZoneExpanderHelper {
  // Private incrementor
  _incCustomPollPageCount() {
    customPollPageCount += 1;
  }

  // Private decrementor
  _decCustomPollPageCount() {
    // After the count has been reset, it is legal to attempt to decrement the count
    // (but the value should never go negative)
    if (customPollPageCount > 0) {
      customPollPageCount -= 1;
    }
    return customPollPageCount;
  }

  // Currently unused.
  resetCustomPollPageCount() {
    customPollPageCount = 0;
  }

  cancelPolling() {
    api.cancelPolling();
  }

  // Start polling the live zoneInfo endpoint with a specific interval and URL.
  customiseExpanderPollingWithIntervalAndUrl(key, loader, intervalMs, zoneUrl) {
    if (key === liveWiredZoneExpanderConsts.key) {
      loader.setEndpointIntervalAndUrl(liveWiredZoneExpanderConsts.key, intervalMs, zoneUrl);
    } else {
      // Must be wireless
      loader.setEndpointIntervalAndUrl(liveWirelessZoneExpanderConsts.key, intervalMs, zoneUrl);
    }
    this._incCustomPollPageCount();
  }

  // Revert to default polling of live zoneInfo endpoint, if there are no other pages using custom polling.
  revertExpanderCustomPollingIfPossible(key, loader) {
    const pageCount = this._decCustomPollPageCount();
    if (pageCount === 0) {
      // No other pages using customised polling, so revert back to default values.
      if (key === liveWiredZoneExpanderConsts.key) {
        loader.setEndpointIntervalAndUrl(liveWiredZoneExpanderConsts.key, liveWiredZoneExpanderConsts.defaultInterval, liveWiredZoneExpanderConsts.defaultUrl);
      } else {
        // Must be wireless
        loader.setEndpointIntervalAndUrl(liveWirelessZoneExpanderConsts.key, liveWirelessZoneExpanderConsts.defaultInterval, liveWirelessZoneExpanderConsts.defaultUrl);
      }
    }
  }
}

const helper = new ZoneExpanderHelper();

export default helper;
export { api };
