// Helper file for ConfigWriteLock related services.

// Todo- Move all duplicate functions to this file

// BUG-2059 Allow the LAN to take the config lock from site keypads and remote clients (WAN/APP) - no longer
// restricting the LAN to seize the lock from a site keypad alone.
export function isLockWithAnotherClient(configWriteLock) {
  const lanClientID = JSON.parse(window.localStorage.getItem('locallyStoreAuthStateSessionId'));
  return ((configWriteLock.lockOwner !== null) && (configWriteLock.lockOwner !== lanClientID));
}
