import list from './pages/users-list/users-list-data';
import user from './pages/user/user-data';
import addUser from './shared/components/add-user/add-user-data';
import addKeyFob from './shared/components/add-key-fob/add-key-fob-data';

export default {
  namespaced: true,
  modules: {
    list,
    user,
    addUser,
    addKeyFob,
  },
};
