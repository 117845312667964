import Login from './login.vue';

const loginRoutes = [
  {
    name: 'login',
    path: '/log-in',
    component: Login,
    meta: { allowAnonymous: true, breadcrumb: [] },
  },
];

export default loginRoutes;
