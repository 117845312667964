<template>
    <div>
        <a-row v-if="!options.isTabularView" :gutter="10" type="flex" justify="start">
            <a-col class="gutter-row" :xs="{ span: 24}" :md="{ span: 12}" :lg="{ span: 8}" :xl="{ span: 8}" :xxl="{ span: 24 / cardsPerRow }" v-for="(record, index) in pagedItems" :key="`record-${index}`">
                <div class="gutter-box"  @click="onClick(record)">
                    <card>
                        <template slot:status>
                            <status :state="options.rowState ? options.rowState(record) : 'none'" />
                        </template>
                        <cardHeader>
                          <div>
                            <a-checkbox v-if="options.canSelect && options.canSelect(record)" :checked="selectedRowKeys.includes(record[options.rowKey])" @change="onCheckboxChange(record, $event)" @click.stop="" />
                           </div>
                           <div>
                                <slot name="card-title" :record="record">
                                    <div>
                                        <h3>{{ record.name }}</h3>
                                    </div>
                                </slot>
                            </div>

                            <template slot="actions">
                              <span v-for="icon in record.icons" :key="`record-tooltip-${index}-${icon.type}`">
                                <a-tooltip placement="bottom" :title="icon.tooltip">
                                    <font-awesome-icon :icon="['fal', 'battery-full']" v-if="icon.type === 'battery' && icon.status === 'GOOD'" />
                                    <font-awesome-icon :icon="['fal', 'battery-slash']" v-if="icon.type === 'battery' && icon.status === 'REPLACE'" />
                                </a-tooltip>
                                <font-awesome-icon :icon="['fal', strengthIcon('signal', icon.status)]" v-if="icon.type === 'signal' &&  icon.status != null" />
                              </span>
                              <div @click.stop="$event.stopPropagation()" v-if="options.canToggle(record)">
                                <a-tooltip v-if="record.toggleEnabledTooltip && record.toggleDisabledTooltip">
                                  <template slot="title">
                                    <span v-if="options.toggleState(record)">{{ record.toggleEnabledTooltip }}</span>
                                    <span v-else>{{ record.toggleDisabledTooltip }}</span>
                                  </template>
                                  <a-switch defaultChecked :disabled="options.toggleDisabled(record)" :checked="options.toggleState(record)" @change="onSwitchChange(index, record, $event)" />
                                </a-tooltip>
                                <a-switch defaultChecked v-else :disabled="options.toggleDisabled(record)" :checked="options.toggleState(record)" @change="onSwitchChange(index, record, $event)" />
                              </div>
                            </template>

                        </cardHeader>
                        <hr class="dashed-bottom" />

                        <template slot="body">
                          <div v-for="(column, columnIndex) in options.columns" v-bind:key="`${column}-${columnIndex}`">
                            <template v-if="!column.isTitle">
                              <div v-if="getColumnValue(column, record) === LOADING">
                                <h4 class="subtitle">{{column.title}}</h4>
                                <font-awesome-icon :icon="['fal', 'spinner-third']" spin />
                              </div>
                              <div v-else>
                                <textBlock v-if="(column.visibility === undefined)" :title="column.title" :value="column.customRender ? column.customRender(record[column.dataIndex], record) : record[column.dataIndex]" :tableRows="record[column.rowsIndex]" :key="`record-col-${index}-${columnIndex}`" />
                                <textBlock v-else-if="column.visibility(record)" :title="column.title" :value="column.customRender ? column.customRender(record[column.dataIndex], record) : record[column.dataIndex]" :tableRows="record[column.rowsIndex]" :key="`record-col-${index}-${columnIndex}`" />
                                <textBlock v-else :title="column.title" :value="''" :tableRows="record[column.rowsIndex]" :key="`record-col-${index}-${columnIndex}`" />
                              </div>
                            </template>
                          </div>
                        </template>
                        <template slot="card-actions">
                          <a-tooltip v-if="record.settingsTooltip" :title="record.settingsTooltip">
                            <a @click.stop="onClick(record)">
                              <font-awesome-icon :icon="['fal', 'cog']" />
                            </a>
                          </a-tooltip>
                          <a v-else @click.stop="onClick(record)">
                            <font-awesome-icon :icon="['fal', 'cog']" />
                          </a>
                          <a-tooltip v-if="options.canClone && options.canClone(record) && record.cloneTooltip" :title="record.cloneTooltip">
                            <a @click.stop="onClone(record)">
                              <font-awesome-icon :icon="['fal', 'copy']" />
                            </a>
                          </a-tooltip>
                          <a v-else-if="options.canClone && options.canClone(record)" @click.stop="onClone(record)">
                            <font-awesome-icon :icon="['fal', 'copy']" />
                          </a>
                          <a-tooltip v-if="options.canDelete && options.canDelete(record)" :title="record.deleteTooltip">
                            <a @click.stop="onDelete(record)">
                              <font-awesome-icon :icon="['fal', 'trash']" />
                            </a>
                          </a-tooltip>
                        </template>
                    </card>
                </div>
            </a-col>
            <a-col v-if="records.length === 0">
              <p>{{ $t(noItemsResKey || 'common.No-items') }}</p>
            </a-col>
        </a-row>
        <div v-else>
            <!-- 'pagination = false' required to prevent duplicated stub ant pagination buttons appearing in list view -->
            <a-table
                :rowKey="options.rowKey"
                :pagination="false"
                :columns="allColumns"
                :dataSource="pagedItems"
                :rowSelection="options.canSelect && options.canSelect() ? {selectedRowKeys: selectedRowKeys, onChange: onSelectChange} : null"
                :rowClassName="rowClassName"
                :customRow="customRow">
                <div slot="icons" slot-scope="icons" class="ant-table-icons-column">
                    <a-tooltip placement="bottom" trigger="click" v-for="(icon, index) in icons" :key="`record-tooltip-${index}-${icon.type}`">
                        <template slot="title">
                            <span> {{ icon.tooltip }}</span>
                        </template>
                        <font-awesome-icon :icon="['fal', 'battery-full']" v-if="icon.type === 'battery' && icon.status === 'GOOD'" />
                        <font-awesome-icon :icon="['fal', 'battery-slash']" v-if="icon.type === 'battery' && icon.status === 'REPLACE'" />
                        <font-awesome-icon :icon="['fal', strengthIcon('signal', icon.status)]" v-if="icon.type === 'signal'" />
                    </a-tooltip>
                </div>
                 <div slot="actions" slot-scope="text, record" class="ant-table-actions-column">
                   <a @click.stop="onClone(record)" v-if="options.canClone && options.canClone(record)">
                    <font-awesome-icon :icon="['fal', 'copy']"  />
                   </a>
                   <a @click.stop="onDelete(record)" v-if="options.canDelete && options.canDelete(record)">
                    <font-awesome-icon :icon="['fal', 'trash']" />
                    </a>
                    <a-switch v-if="options.canToggle(record)" :checked="options.toggleState(record)" defaultChecked @change="onSwitchChange(index, record, $event)" />
                </div>
            </a-table>
        </div>
        <a-row>
          <a-col :span="24">
            <grid-paging
              :default-current="currentPage"
              :total="records.length"
              :pageSize="options.pagination.pageSize"
              :visiblePages="options.pagination.pages"
              :showTotal="(start, end, total) => $t('common.gridItemTotals', { start, end, total })"
              @change="onNewRoutePageChange"
              />
          </a-col>
        </a-row>
    </div>
</template>

<script>
import _ from 'lodash';
import card from '@/app/shared/components/Card/card.vue';
import cardHeader from '@/app/shared/components/Card/Child/cardHeader.vue';
import status from '@/app/shared/components/Status/status.vue';
import textBlock from '@/app/shared/components/Common/TextBlock.vue';
import gridPaging from '@/app/shared/components/grid/grid-paging.vue';
import { LOADING } from '@/app/shared/constants';

export default {
  components: {
    card,
    cardHeader,
    status,
    textBlock,
    gridPaging,
  },
  props: {
    noItemsResKey: {},
    cardsPerRow: { default: 3 },
    options: {
      default: {
        command: null,

        // state
        isTabularView: false,

        // data
        rowKey: 'index',
        columns: [],

        // state
        rowState: record => 'none', // eslint-disable-line no-unused-vars
        toggleState: (record) => {}, // eslint-disable-line no-unused-vars
        canToggle: record => false, // eslint-disable-line no-unused-vars
        canClone: record => false, // eslint-disable-line no-unused-vars
        canDelete: record => true, // eslint-disable-line no-unused-vars
        canSelect: record => true, // eslint-disable-line no-unused-vars

        // events
        click: (record) => {}, // eslint-disable-line no-unused-vars
        delete: (record) => {}, // eslint-disable-line no-unused-vars
        clone: (record) => {}, // eslint-disable-line no-unused-vars
        toggle: (record) => {}, // eslint-disable-line no-unused-vars
      },
    },
    records: { default: [] },
  },
  data() {
    return {
      selectedRowKeys: [],
      cachedSlice: [],
      LOADING,
    };
  },
  computed: {
    allColumns() {
      return [
        ...this.options.columns,
        {
          width: 90,
          dataIndex: 'icons',
          scopedSlots: { customRender: 'icons' },
        },
        {
          width: 150,
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
        },
      ];
    },
    currentPage() {
      const page = parseInt(this.$route.query.page, 10);
      if (!page) {
        return 1;
      }
      return page;
    },
    pagedItems() {
      return this.pagedItemsForPage(this.currentPage);
    },
  },
  created() {
  },
  methods: {
    getColumnValue(column, record) {
      return column.customRender ? column.customRender(record[column.dataIndex], record) : record[column.dataIndex];
    },
    strengthIcon(icon, level) {
      if (icon === 'signal') {
        if (level === 100) return icon;
        if (level >= 75) return `${icon}-alt-3`;
        if (level >= 50) return `${icon}-alt-2`;
        if (level >= 1) return `${icon}-alt-1`;
        if (level === 0) return `${icon}-slash`;
        return `${icon}-slash`;
      }
      return null;
    },
    rowClassName(record) {
      return this.options.rowState !== undefined ? this.options.rowState(record) : record.status;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;

      this.$emit('update:selectedRowKeys', this.selectedRowKeys);
    },
    onCheckboxChange(item, e) {
      if (e.target.checked) {
        this.selectedRowKeys.push(item[this.options.rowKey]);
      } else {
        this.selectedRowKeys = this.selectedRowKeys.filter(x => x !== item[this.options.rowKey]);
      }
      this.options.selectedRowKeys = this.selectedRowKeys;
    },
    onSwitchChange(index, record, event) {
      this.options.toggle(index, record, event);
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.options.click(record);
          },
        },
      };
    },
    onClick(record) {
      this.options.click(record);
    },
    onClone(record) {
      this.options.clone(record);
    },
    onDelete(record) {
      this.options.delete(record);
    },
    pagedItemsForPage(page) {
      const firstItem = (this.options.pagination.pageSize * (page - 1));
      const items = this.records.slice(firstItem, firstItem + this.options.pagination.pageSize);
      // TODO Quicker to compare indexes of items with cached slice indexes rather than deep isEqual()
      if (!_.isEqual(items, this.cachedSlice)) {
        // Compare with cache and emit pagedItemsChange event if delta.
        this.cachedSlice = items;
      }
      return items;
    },
    onNewRoutePageChange(page) {
      // This changes the routing.
      this.$router.push({ query: { page } });
    },
  },
  watch: {
    cachedSlice() {
      this.$emit('pagedItemsChange', { records: this.cachedSlice });
    },
    // eslint-disable-next-line func-names
    'options.command': function (n) {
      if (n === 'clear-selection') {
        this.selectedRowKeys = [];
        this.options.selectedRowKeys = [];
        this.options.command = null;
      }
    },
  },
};
</script>

<style lang="scss">
.ant-card-actions > li {
  margin: 0 !important;
}
.ant-card-actions > li > span {
  width:100%;
  a {
    display: block;
    padding:12px 0;
    width:100%;
  }
}

</style>
