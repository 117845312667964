import { storeFactory } from '@/app/shared/services/store-helper';

function initialState() {
  return {
    lastConnected: undefined,
  };
}

const { store } = storeFactory(initialState, {
  getters: {},
  actions: {
    requiredEndpoints() {
      return ['liveUserInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('set', { lastConnected: Date.parse(endpoints.liveUserInfo.data.Live.userInfo.Users.LastConnectedDateTime) });
    },
  },
});

export default store;
