<template>
    <two-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
          <h1>{{ $t('maintenance.navigationTitle') }}</h1>
        </template>

        <h2 class="h3">{{ $t('maintenance.Tests') }}</h2>
        <a-row :gutter="20">
            <a-col :xs="{ span: 12}" :md="{ span: 8}" :lg="{ span: 6}" :xl="{ span: 4}" :xxl="{ span: 3}">
                <card>
                    <div class="card__stat" @click="walkTestDrawerVisible = true">
                        <p class="stat">
                            <font-awesome-icon :icon="['fal', 'walking']" size="2x" />
                        </p>
                        <h3>{{ $t('maintenance.Walk') }}</h3>
                    </div>
                </card>
            </a-col>
            <a-col :xs="{ span: 12}" :md="{ span: 8}" :lg="{ span: 6}" :xl="{ span: 4}" :xxl="{ span: 3}">
                <card>
                    <div class="card__stat" @click="sirenAndStrobeTestDrawerVisible = true">
                        <p class="stat">
                            <font-awesome-icon :icon="['fal', 'bells']" size="2x" />
                        </p>
                        <h3>{{ $t('maintenance.Siren-and-Strobe') }}</h3>
                    </div>
                </card>
            </a-col>
            <a-col :xs="{ span: 12}" :md="{ span: 8}" :lg="{ span: 6}" :xl="{ span: 4}" :xxl="{ span: 3}">
                <soak-test :disabled="!configWriteLock.canWriteConfig"/>

            </a-col>
            <a-col :xs="{ span: 12}" :md="{ span: 8}" :lg="{ span: 6}" :xl="{ span: 4}" :xxl="{ span: 3}">
                <card>
                    <div class="card__stat" @click="pgmTestDrawerVisible = true">
                        <p class="stat">
                            <font-awesome-icon :icon="['fal', 'cogs']" size="2x" />
                        </p>
                        <h3>{{ $t('maintenance.PGM') }}</h3>
                    </div>
                </card>
            </a-col>
            <a-col :xs="{ span: 12}" :md="{ span: 8}" :lg="{ span: 6}" :xl="{ span: 4}" :xxl="{ span: 3}">
                <card>
                    <div class="card__stat" @click="arcTestDrawerVisible = true">
                        <p class="stat">
                            <font-awesome-icon :icon="['fal', 'bell']" size="2x" />
                        </p>
                        <h3>{{ $t('maintenance.ARC-comms') }}</h3>
                    </div>
                </card>
            </a-col>
            <a-col :xs="{ span: 12}" :md="{ span: 8}" :lg="{ span: 6}" :xl="{ span: 4}" :xxl="{ span: 3}">
                <card>
                    <div class="card__stat" @click="batteryTestDrawerVisible = true">
                        <p class="stat">
                            <font-awesome-icon :icon="['fal', 'battery-full']" size="2x" />
                        </p>
                        <h3>{{ $t('maintenance.Battery') }}</h3>
                    </div>
                </card>
            </a-col>
        </a-row>
        <a-row :gutter="20">
            <a-col :xs="{ span: 12}" :md="{ span: 8}" :lg="{ span: 6}" :xl="{ span: 4}" :xxl="{ span: 3}">
              <card>
                  <div class="card__stat" @click="firmwareUpdateDrawerVisible = true">
                      <p class="stat">
                          <font-awesome-icon :icon="['fal', 'microchip']" size="2x" />
                      </p>
                      <h3>{{ $t('maintenance.FirmwareUpdate') }}</h3>
                  </div>
              </card>
            </a-col>
        </a-row>
        <walk-test :visible="walkTestDrawerVisible" @onClose="walkTestDrawerVisible = false" />
        <siren-and-strobe-test :visible="sirenAndStrobeTestDrawerVisible" @onClose="sirenAndStrobeTestDrawerVisible = false" />
        <pgm-test :visible="pgmTestDrawerVisible" @onClose="pgmTestDrawerVisible = false" />
        <arc-comms-test :visible="arcTestDrawerVisible" @onClose="arcTestDrawerVisible = false" />
        <battery-test :visible="batteryTestDrawerVisible" @onClose="batteryTestDrawerVisible = false" />
        <firmware-update :visible="firmwareUpdateDrawerVisible" @onClose="firmwareUpdateDrawerVisible = false"/>
        <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
            @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
    </two-col-layout>
</template>

<script>
import {
  ConfigWriteLock, DisplayFaultsGuard,
} from '@/app/shared/mixins';
import Card from '@/app/shared/components/Card/card.vue';
import WalkTest from '@/app/maintenance/shared/components/walk-test/walk-test.vue';
import SirenAndStrobeTest from '@/app/maintenance/shared/components/siren-and-strobe-test/siren-and-strobe-test.vue';
import SoakTest from '@/app/maintenance/shared/components/soak-test/soak-test.vue';
import PgmTest from '@/app/maintenance/shared/components/pgm-test/pgm-test.vue';
import ArcCommsTest from '@/app/maintenance/shared/components/arc-comms-test/arc-comms-test.vue';
import BatteryTest from '@/app/maintenance/shared/components/battery-test/battery-test.vue';
import FirmwareUpdate from '@/app/maintenance/shared/components/firmware-update/firmware-update.vue';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

export default {
  mixins: [ConfigWriteLock, DisplayFaultsGuard],
  components: {
    Card,
    WalkTest,
    SirenAndStrobeTest,
    SoakTest,
    PgmTest,
    ArcCommsTest,
    BatteryTest,
    FirmwareUpdate,
    DisplayFaultsModal,
  },
  data() {
    return {
      walkTestDrawerVisible: false,
      sirenAndStrobeTestDrawerVisible: false,
      pgmTestDrawerVisible: false,
      arcTestDrawerVisible: false,
      batteryTestDrawerVisible: false,
      firmwareUpdateDrawerVisible: false,
    };
  },
};
</script>
