<template>
  <a-form-item :label="label" :label-col="labelCol" :wrapper-col="wrapperCol">
    <span>
        {{ value != null ? value : '&nbsp;' }}
    </span>
  </a-form-item>
</template>

<script>
export default {
  components: {

  },
  props: ['label', 'value', 'label-col', 'wrapper-col'],
};
</script>

<style lang="scss">
</style>
