import Vue from 'vue';

export const EVENTS = {
  CONFIG_WRITE: 'CONFIG_WRITE',
  UPDATE_LIVE: 'UPDATE_LIVE',
  PARTIAL_CONFIG_WRITE: 'PARTIAL_CONFIG_WRITE',
};

const eventHub = new Vue();

export default eventHub;
