/* eslint-disable import/no-named-as-default-member */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import Q from 'q';
import axios from 'axios';
import axiosInstance from '@/app/shared/utils/axiosInstance';
import debugAxiosInstance from '@/app/shared/utils/debugAxiosInstance';
import Request from '@/app/shared/utils/axiosRequestHandler';
import authState from '@/app/shared/state/auth-state';
import encryptionService from '@/app/shared/services/encryption-service';
import logger from '@/app/shared/services/logger';
import navService from '@/app/shared/services/navigation-service'; // eslint-disable-line import/no-cycle

logger.log('shared/services/api');
export function createArray(n, create) {
  const items = [];
  for (let i = 0; i < n; i += 1) {
    items.push(create(i));
  }
  return items;
}

function createObject(path, value) {
  const pathSegments = path.substring(1).split('/');

  let obj;
  for (let i = pathSegments.length - 1; i >= 0; i -= 1) {
    if (obj) {
      let newObj = {};

      if (!Number.isNaN(Number.parseInt(pathSegments[i], 10))) {
        newObj = [];
        newObj[Number.parseInt(pathSegments[i], 10)] = obj;
        obj = newObj;
      } else {
        newObj[pathSegments[i]] = obj;
        obj = newObj;
      }
    } else {
      if (!Number.isNaN(Number.parseInt(pathSegments[i], 10))) {
        obj = [];
      } else {
        obj = {};
      }
      obj[pathSegments[i]] = value;
    }
  }

  return obj;
}

export class API {
  logging = false;

  transactions = {};

  transactionData = {};

  constructor() {
    this.create('default');
  }

  getAxiosInstance() {
    return this.logging ? debugAxiosInstance : axiosInstance;
  }

  getHeaders(path) {
    const headers = {
      'Pdi-Session-Id': authState.sessionId,
    };
    if (authState.isAuthenticated()) {
      headers['Pdi-Session-Timestamp'] = encryptionService.encrypt(`${btoa(Date().toString())}:${authState.sessionId}:${path}`);
    }
    return headers;
  }

  create(name) {
    this.transactions[name] = {
      get: (path, retries = 0) => this._get(name, path, retries),
      poll: (delay, path, onResponse, onError) => this._poll(name, delay, path, onResponse, onError),
      put: (path, data, retries = 0, createObjectGraph = true) => this._put(name, path, data, retries, createObjectGraph),
    };
    this.transactionData[name] = { calls: [], polling: [] };
  }

  get(path, params, retries) { return this._get('default', path, params, retries); }

  put(path, data, retries = 0, createObjectGraph = true) { return this._put('default', path, data, retries, createObjectGraph); }

  poll(delay, path, onResponse, onError) { return this._poll('default', delay, path, onResponse, onError); }

  _get(name, path, params, retries) {
    const call = this.getAxiosInstance().get(`${path}`, {
      'axios-retry': { retries },
      transformResponse: [].concat(axios.defaults.transformResponse, this.transformResponse),
      headers: this.getHeaders(path),
      params,
    });

    this.transactionData[name].calls.push(call);
    return call.catch((error) => {
      if (error.response != null && error.response.status === 403) {
        this._onAccessDenied();
      }
      throw error;
    });
  }

  _put(name, path, data, retries, createObjectGraph) {
    const call = this.getAxiosInstance().put(`${path}`, encryptionService.encrypt(createObjectGraph ? createObject(path, data) : data), {
      'axios-retry': { retries },
      transformRequest: [].concat(this.transformRequest, axios.defaults.transformRequest),
      transformResponse: [].concat(axios.defaults.transformResponse, this.transformResponse),
      headers: this.getHeaders(path),
    });
    this.transactionData[name].calls.push(call);
    return call.catch((error) => {
      if (error.response != null && error.response.status === 403) {
        this._onAccessDenied();
      }
      throw error;
    });
  }

  _poll(name, delay, path, onResponse, onError) {
    const request = new Request(this.getAxiosInstance(), `${path}`, {
      lockable: false,
      cancelable: true,
      errorHandler: onError || ((error) => {
        if (error.response != null && error.response.status === 403) {
          this._onAccessDenied();
        }
      }),
      transformRequest: [].concat(this.transformRequest, axios.defaults.transformRequest),
      transformResponse: [].concat(axios.defaults.transformResponse, this.transformResponse),
      headers: this.getHeaders(path),
    });
    const call = request.poll(delay).get(res => onResponse(res));

    this.transactionData[name].calls.push(call);
    this.transactionData[name].polling.push(request);

    return { polling: request, call };
  }

  cancelPolling(name) {
    if (name) {
      if (this.logging) logger.log(`Cancelling polling x ${this.transactionData[name].polling.length}`);
      this.transactionData[name].polling.forEach((pollingRequest) => {
        pollingRequest.cancel();
      });
    } else {
      Object.keys(this.transactionData).forEach((tranKey) => {
        if (this.logging) logger.log(`Cancelling ${tranKey} polling x ${this.transactionData[tranKey].polling.length}`);
        this.transactionData[tranKey].polling.forEach((pollingRequest) => {
          pollingRequest.cancel();
        });
      });
    }
  }

  asyncDone(name) {
    return Q.allSettled(this.transactionData[name || 'default'].calls);
  }

  addCall(call, name) {
    this.transactionData[name || 'default'].calls.push(call);
  }

  // eslint-disable-next-line class-methods-use-this
  transformResponse(data) {
    return encryptionService.decrypt(data);
  }

  // eslint-disable-next-line class-methods-use-this
  transformRequest(data) {
    return data;
  }


  _onAccessDenied() {
    // Request that the login page reloads when it is created.
    window.localStorage.setItem('reloadLoginPageRequired', 'true');
    navService.replace('login');
  }

  toggleLogging(state) {
    this.logging = state;
  }
}
