import { API } from '@/app/shared/services/api';
import { AutoScanFailed } from '@/app/shared/errors';

// Required endpoints for inputs autoscan
const lockEndpoint = '/Live/CommitConfig';
const autoscanStateEndpoint = '/Live/Devices/Autoscan/Inputs/InputsAutoscanState';

const api = new API();

function dropPolledEndpoints() {
  // Drop inputs autoscan specific endpoints from the current context
  let temp = window.globalThis.currentRequiredEndpoints;
  try {
    window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== lockEndpoint);
    temp = window.globalThis.currentRequiredEndpoints;
    window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== autoscanStateEndpoint);
  } catch (err) {
    // nothing to do
  }
}

async function scan() {
  await api.put('/Live/Devices/Autoscan/Inputs/InputsAutoscan', true);

  let scanFailed = false;
  // Bring the ad-hoc inputs autoscan endpoint into the scope of current endpoints
  if (window.globalThis.currentRequiredEndpoints.indexOf(autoscanStateEndpoint) === -1) {
    window.globalThis.currentRequiredEndpoints.push(autoscanStateEndpoint);
  }

  await new Promise(((resolve) => {
    api.poll(1000, autoscanStateEndpoint, (res) => {
      const status = res.data.Live.Devices.Autoscan.Inputs.InputsAutoscanState;
      if (status === ' SCAN_FAILED') {
        scanFailed = true;
        resolve();
        // Remove the inputs autoscan endpoint
        const temp = window.globalThis.currentRequiredEndpoints;
        window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== autoscanStateEndpoint);
        return false; // do not keep polling
      }
      if (status !== 'SCAN_COMPLETE') {
        return true;
      }
      resolve();
      // Remove the inputs autoscan endpoint
      const temp = window.globalThis.currentRequiredEndpoints;
      window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== autoscanStateEndpoint);
      return false; // do not keep polling
    });
  }));

  api.cancelPolling();
  await api.put('/Live/Devices/Autoscan/Inputs/InputsAutoscan', false);

  if (scanFailed) {
    throw new AutoScanFailed();
  } else {
    await api.put('/Live/Devices/Autoscan/Inputs/SaveScannedDevicesToConfig', true);
  }

  const response = await api.get('/Info/zoneInfo/ZoneInstalledCount');

  const zonesFound = response.data.Info.zoneInfo.ZoneInstalledCount;

  dropPolledEndpoints();

  return zonesFound;
}

async function cancel() {
  api.cancelPolling();
  await api.put('/Live/Devices/Autoscan/Inputs/InputsAutoscan', false);
  dropPolledEndpoints();
}

export default {
  scan, cancel,
};
