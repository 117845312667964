import Communications from './communications.vue';
import ARCOptions from './pages/arc-options/arc-options.vue';
import SMSControls from './pages/sms-controls/sms-controls.vue';
import SMSNotifications from './pages/sms-notifications/sms-notifications.vue';
import CloudOptions from './pages/cloud-options/cloud-options.vue';
import AppOptions from './pages/app-options/app-options.vue';
import Cellular from './pages/cellular/cellular.vue';
import LAN from './pages/lan/lan.vue';
import WIFI from './pages/wifi/wifi.vue';
import Voice from './pages/voice/voice.vue';
import AdvancedOptions from './pages/advanced-options/advanced-options.vue';

const routes = [
  {
    path: '/communications',
    component: Communications,
    children: [
      { path: '', redirect: { name: 'arc-options' } },
      {
        path: 'arc-options',
        name: 'arc-options',
        component: ARCOptions,
        meta: { breadcrumb: [{ nameRes: 'communications.navigationTitle', link: 'arc-options' }, { nameRes: 'communications.arcOptions.navigationTitle' }] },
      },
      { path: 'sms', redirect: { name: 'sms-controls' } },
      {
        path: 'sms/controls',
        name: 'sms-controls',
        component: SMSControls,
        meta:
        {
          breadcrumb: [
            { nameRes: 'communications.navigationTitle', link: 'arc-options' },
            { nameRes: 'communications.sms.navigationTitle' },
            { nameRes: 'communications.smsControls.navigationTitle' },
          ],
        },
      },
      {
        path: 'sms/notifications',
        name: 'sms-notifications',
        component: SMSNotifications,
        meta:
        {
          breadcrumb: [
            { nameRes: 'communications.navigationTitle', link: 'arc-options' },
            { nameRes: 'communications.sms.navigationTitle' },
            { nameRes: 'communications.smsNotifications.navigationTitle' },
          ],
        },
      },

      { path: 'connectivity', redirect: { name: 'cloud-options' } },
      {
        path: 'connectivity/cloud-options',
        name: 'cloud-options',
        component: CloudOptions,
        meta: { breadcrumb: [{ nameRes: 'communications.navigationTitle', link: 'arc-options' }, { nameRes: 'communications.connectivity.navigationTitle' }, { nameRes: 'communications.cloudOptions.navigationTitle' }] },
      },
      {
        path: 'connectivity/app-options',
        name: 'app-options',
        component: AppOptions,
        meta: { breadcrumb: [{ nameRes: 'communications.navigationTitle', link: 'arc-options' }, { nameRes: 'communications.connectivity.navigationTitle' }, { nameRes: 'communications.appOptions.navigationTitle' }] },
      },
      {
        path: 'connectivity/cellular',
        name: 'cellular',
        component: Cellular,
        meta: { breadcrumb: [{ nameRes: 'communications.navigationTitle', link: 'arc-options' }, { nameRes: 'communications.connectivity.navigationTitle' }, { nameRes: 'communications.cellular.navigationTitle' }] },
      },
      {
        path: 'connectivity/lan',
        name: 'lan',
        component: LAN,
        meta: { breadcrumb: [{ nameRes: 'communications.navigationTitle', link: 'arc-options' }, { nameRes: 'communications.connectivity.navigationTitle' }, { nameRes: 'communications.lan.navigationTitle' }] },
      },
      {
        path: 'connectivity/wifi',
        name: 'wifi',
        component: WIFI,
        meta: { breadcrumb: [{ nameRes: 'communications.navigationTitle', link: 'arc-options' }, { nameRes: 'communications.connectivity.navigationTitle' }, { nameRes: 'communications.wifi.navigationTitle' }] },
      },
      {
        path: 'connectivity/voice',
        name: 'voice',
        component: Voice,
        meta: { breadcrumb: [{ nameRes: 'communications.navigationTitle', link: 'arc-options' }, { nameRes: 'communications.connectivity.navigationTitle' }, { nameRes: 'communications.voice.navigationTitle' }] },
      },

      { path: 'advanced-communications', redirect: { name: 'advanced-options' } },
      {
        path: 'advanced-communications/advanced-options',
        name: 'advanced-options',
        component: AdvancedOptions,
        meta: { breadcrumb: [{ nameRes: 'communications.navigationTitle', link: 'arc-options' }, { nameRes: 'communications.advancedCommunications.navigationTitle' }, { nameRes: 'communications.advancedOptions.navigationTitle' }] },
      },
    ],
  },
];

export default routes;
