<template>
  <div>
    <img src="@/assets/images/wizard-images/ui-graphics_learn-keypads.png" alt="" />
    <p><strong>{{ $t('addDevicesDrawer.wireless.keypadsAndReaders.InstructionsHeader') }}</strong></p>
    <ol>
      <li>{{ $t('addDevicesDrawer.wireless.keypadsAndReaders.Step1') }}</li>
      <li>{{ $t('addDevicesDrawer.wireless.keypadsAndReaders.Step2') }}</li>
      <li>{{ $t('addDevicesDrawer.wireless.keypadsAndReaders.Step3') }}</li>
      <li>{{ $t('addDevicesDrawer.wireless.keypadsAndReaders.Step4') }}</li>
      <li>{{ $t('addDevicesDrawer.wireless.keypadsAndReaders.Step5') }}</li>
    </ol>
    <ul>
      <li>{{ $t('addDevicesDrawer.wireless.keypadsAndReaders.FurtherDescription1') }}</li>
      <li>{{ $t('addDevicesDrawer.wireless.keypadsAndReaders.FurtherDescription2') }}</li>
    </ul>
    <p>{{ $t('addDevicesDrawer.wireless.keypadsAndReaders.FurtherHelp') }}</p>
  </div>
</template>

<script>
export default {
  name: 'StepKeypadsAndReaders',
};
</script>

<style scoped>
  ol { padding-left: 1em; }
  ul { list-style: none; padding-left: 0; }
</style>
