export function undottedToDottedIPAddress(ipaddr, uri) {
  if (ipaddr.length > 0) {
    let ipa = ipaddr.substr(0, 3);
    for (let i = 3; i < ipaddr.length; i += 3) {
      ipa += '.';
      ipa += ipaddr.substr(i, 3);
    }
    return ipa;
  }
  return uri;
}


export function dottedToUndottedIPAddress(ipaddrOrUri) {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddrOrUri)) {
    let result = '';
    const parts = ipaddrOrUri.split('.');
    for (let i = 0; i < parts.length; i += 1) {
      result += `000${parts[i]}`.slice(-3); // left zero pad
    }
    return result;
  }
  return '';
}


export function numericArrayToEncryptionKey(key, part) {
  let result = '';
  for (let i = part * 8; i < (part + 1) * 8; i += 1) {
    result += `00${key[i].toString(16)}`.slice(-2); // left zero pad
    if ((i % 8 !== 7) && (i % 2 === 1)) { // xxxx-xxxx-xxxx-xxxx
      result += '-';
    }
  }
  return result;
}


export function encryptionKeyToNumericArray(keysize, key1, key2, key3, key4) {
  const empty = '0000000000000000';
  const k1 = (`${key1}0000000000000000`).replace(/-/g, '').substr(0, empty.length);
  const k2 = (`${key2}0000000000000000`).replace(/-/g, '').substr(0, empty.length);
  const k3 = (`${key3}0000000000000000`).replace(/-/g, '').substr(0, empty.length);
  const k4 = (`${key4}0000000000000000`).replace(/-/g, '').substr(0, empty.length);

  let key = '';
  if (keysize === 'UNENCRYPTED') {
    key = `${empty}${empty}${empty}${empty}`;
  } else if (keysize === 'AES128') {
    key = `${k1}${k2}${empty}${empty}`;
  } else if (keysize === 'AES192') {
    key = `${k1}${k2}${k3}${empty}`;
  } else {
    key = `${k1}${k2}${k3}${k4}`;
  }

  const result = [];
  for (let i = 0; i < 64; i += 2) {
    result[i / 2] = parseInt(key.substring(i, i + 2), 16);
  }
  return result;
}
