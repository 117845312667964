import areasOptions from './pages/areas-options/areas-options-data';
import siteOptions from './pages/site-options/site-options-data';
import generalTimers from './pages/general-timers/general-timers-data';
import globalVolume from './pages/general-volumes/general-volumes-data';
import globalDisplay from './pages/general-displays/general-displays-data';
import dateAndTime from './pages/date-and-time/date-and-time-data';
import armingOptions from './pages/arming-options/arming-options-data';
import userSettings from './pages/user-settings/user-settings-data';
import zoneSettings from './pages/zone-settings/zone-settings-data';
import generalResponses from './pages/general-responses/general-responses-data';
import alarmResponses from './pages/alarm-responses/alarm-responses-data';
import engineerRestores from './pages/engineer-restores/engineer-restores-data';

export default {
  namespaced: true,
  modules: {
    areasOptions,
    siteOptions,
    generalTimers,
    globalVolume,
    globalDisplay,
    dateAndTime,
    armingOptions,
    userSettings,
    zoneSettings,
    generalResponses,
    alarmResponses,
    engineerRestores,
  },
};
