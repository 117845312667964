<template>
  <div>
    <!-- // Bring up the TakeControl banner if another client has the lock i.e. there is an active lock owner other than the LAN. -->
    <div v-if="(configWriteLock.isActive === true) && (configWriteLock.isMine === false) && isLockWithAnotherClient" class="container">
      <a-alert
        :message="$t('common.Panel-locked-by-another-client')"
        :description="$t(displayLockOwnerInfo)"
        showIcon
        type="warning" />
      <a-button v-if="requestWriteLockVisible && lanModeNotBeingSet" type="primary" @click="onRequestLock"> {{$t('common.Take-control')}} </a-button>
    </div>
    <!-- // Bring up the TakeControl banner if LAN has lost the lock but there is no new lock owner - i.e. panel is in master manager
      //  menu with a B-key plus master manager code entry via the keypad.
    -->
    <div v-else-if="(!anyAreaSet && !lanModeBeingSet && configWriteLock.lostLock === true && configWriteLock.isActive === false)" :hidden="lanInUserMode" class="container">
      <a-alert
        :message="$t('common.Panel-unlocked')"
        :description="$t('common.LAN-has-lost-the-lock')"
        showIcon
        type="warning" />
      <a-button v-if="requestWriteLockVisible && lanModeNotBeingSet" type="primary" @click="onRequestLock"> {{$t('common.Take-control')}} </a-button>
    </div>
    <div v-else-if="anyAreaSet" class="container">
      <a-alert
        :message="$t('common.Panel-armed')"
        showIcon
        type="warning" />
    </div>
    <!-- // Display the engineer-mode-setting banner only on an engineering page and hide it on the dashboard/panel page.
         // This is to prevent any flickering banners when navigating between pages.
    -->
    <div v-else-if="((configWriteLock.canWriteConfig === false) || lanModeBeingSet)" :hidden="lanInUserMode" class="container">
      <a-alert
        :message="$t('common.Updating-mode')"
        showIcon
        type="warning" />
    </div>
    <!-- // Hide the day-mode-setting banner on all engineering pages and allow it only on the dashboard page.
         // This is to prevent any flickering banners when navigating between pages.
    -->
    <div v-else-if="(isDayModeFullySet === false)" :hidden="!(lanInUserMode)" class="container">
      <a-alert
        :message="$t(displayModeSetting)"
        showIcon
        type="warning" />
    </div>
  </div>
</template>

<script>
import { API } from '@/app/shared/services/api';
import { panelPageConsts } from '@/app/shared/constants';
import { isLockWithAnotherClient } from '@/app/shared/services/config-write-lock-helper';

export default {
  name: 'config-write-lock-alert',
  props: {
    configWriteLock: {
      default() {
        return {
          canWriteConfig: true,
          lockOwner: null,
          lostLock: false,
        };
      },
    },
    requestWriteLockVisible: { default: true },
    anyAreaSet: { default: false },
  },
  methods: {
    async onRequestLock() {
      const api = new API();
      // This endpoint sets the mode and requests the lock
      // If it takes long for the lock status to be read, display a banner to the user to wait
      this.setMine(true);
      this.setLanModeSettingFlag();
      await api.put('/Live/ClientStatus/Mode', panelPageConsts.engineerMode);
      this.setPanelPageModeStatus(panelPageConsts.engineerModeRequested);
    },
    setLanModeSettingFlag() {
      this.$gLanModeSetting.lanModeSettingInProgress = true;
    },
    setMine(value) {
      this.$emit('setMine-event', value);
    },
    setPanelPageModeStatus(status) {
      this.$gLanModeSetting.panelPageModeForNavigation = status;
    },
    isLockWithAnotherClient,
  },
  computed: {
    lanModeBeingSet() {
      return this.$gLanModeSetting.lanModeSettingInProgress === true;
    },
    lanModeNotBeingSet() {
      return this.$gLanModeSetting.lanModeSettingInProgress === false;
    },
    lanInUserMode() {
      return this.$gLanModeSetting.lanModeSettingInProgress === panelPageConsts.panelPage;
    },
    isUserNavigatingBeforeModeSet() {
      return this.$gLanModeSetting.panelPageStopNavigationBanner === true;
    },
    isKeypadInEngineer() {
      return this.configWriteLock.lockOwner === panelPageConsts.keypadLockOwner;
    },
    isDayModeFullySet() {
      return (this.$gLanModeSetting.panelPageModeForNavigation === panelPageConsts.userModeSet);
    },
    displayModeSetting() {
      let dispString = 'common.Wait-setting-day-mode';
      if (this.isPageCreateMountStuck) {
        // Panel/dashboard page is stuck in creation and mounting stages.
        dispString = 'common.Wait-setting-day-mode-cms';
      } else if (this.isPageCreateStuck) {
        // Panel/dashboard page is stuck in creation stage - LAN is either waiting to set mode to USER or is waiting for the
        // panel to respond to mode change request.
        dispString = 'common.Wait-setting-day-mode-cs';
      } else if (this.isPageMountStuck) {
        // Panel/dashboard page is stuck in mounting stage - highly unlikely for this to happen.
        dispString = 'common.Wait-setting-day-mode-ms';
      } else {
        // Nothing to do
      }
      return dispString;
    },
    isPageCreateMountStuck() {
      return (this.$gLanModeSetting.pageCreated === true && this.$gLanModeSetting.pageMounted === true);
    },
    isPageCreateStuck() {
      return (this.$gLanModeSetting.pageCreated === true && this.$gLanModeSetting.pageMounted === false);
    },
    isPageMountStuck() {
      return (this.$gLanModeSetting.pageCreated === false && this.$gLanModeSetting.pageMounted === true);
    },
    displayLockOwnerInfo() {
      let dispString = 'common.Panel-lock-lost';
      const lanClientID = JSON.parse(window.localStorage.getItem('locallyStoreAuthStateSessionId'));
      // If there is a valid lock owner other than the LAN, display the client that owns the lock
      if (this.configWriteLock.lockOwner !== null && this.configWriteLock.isActive === true) {
        if (this.configWriteLock.lockOwner === panelPageConsts.keypadLockOwner) {
          dispString = 'common.Panel-lock-already-taken-by-keypad';
        } else if (this.configWriteLock.lockOwner !== lanClientID) {
          dispString = 'common.Panel-lock-already-taken-by-remote-client';
        } else {
          // Nothing to do
        }
      }
      return dispString;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  align-items: center;
  background-color: #fffdf0;
  border: 1px solid #fff0a6;
  border-radius: 4px;
  gap: 30px;
}
.ant-alert-warning {
    background-color: #fffdf0;
    border: 0px;
}
</style>
