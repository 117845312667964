export function mustBeValidEOLRange(usePerZoneOptionsArray) {
  return function validator(value, vm) {
    return (usePerZoneOptionsArray ? !this.perZoneOptions[vm.zoneIndex] : !vm.hasPerZoneOptions) || (vm.eolResistorMode !== 'NORMALLY_CLOSED' || value === 'ZONE_EOL_0K_0K');
  };
}

export function mustBeValidEOLMode(usePerZoneOptionsArray) {
  return function validator(value, vm) {
    return (usePerZoneOptionsArray ? !this.perZoneOptions[vm.zoneIndex] : !vm.hasPerZoneOptions) || (vm.eolResistorRange !== 'ZONE_EOL_0K_0K' || value === 'NORMALLY_CLOSED');
  };
}
