<template>
  <a-layout>
    <a-layout-content :style="{ margin: '0' }">
      <slot />
    </a-layout-content>
  </a-layout>
</template>

<script>
export default {
  name: 'default-layout',
};
</script>
