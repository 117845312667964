/* eslint-disable prefer-destructuring */
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { getWirelessZEMAddress } from '@/app/zone-expanders/shared/services/helpers';
import i18n from '@/app/shared/services/i18n';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import { toFaultString } from '@/app/shared/utils/activeFaults';
import { limits, liveZoneInfoConsts, liveWirelessZoneExpanderConsts } from '@/app/shared/constants';

function padWithZero(num, size) {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
}

function initialState() {
  return {
    name: undefined,
    version: undefined,
    state: undefined,
    zonesMax: undefined,
    associatedZones: [],

    form: {
      name: undefined,
      type: undefined,
      address: [],
      location: undefined,
    },

    diagnostics: [
      {
        key: 'wirelessZoneExpander.Diagnostics.Status',
        isLoading: true,
        value: undefined,
      },
      { key: 'wirelessZoneExpander.Diagnostics.DataBusStatus', isLoading: true, value: undefined },
      {
        key: 'wirelessZoneExpander.Diagnostics.Frequency',
        isLoading: true,
        value: undefined,
        format: '<span class="diagnostic__value">{0}</span><span class="diagnostic__extra">{1}</span>',
        valueIsHtml: true,
      },
      {
        key: 'wirelessZoneExpander.Diagnostics.Channel',
        isLoading: true,
        value: undefined,
        format: '<span class="diagnostic__value">{0}</span><span class="diagnostic__extra">{1}</span>',
        valueIsHtml: true,
      },
    ],
  };
}

const { api, store } = storeFactory(initialState, {
  getters: {},
  actions: {
    requiredEndpoints() {
      // TODO Investigate replacing liveActiveFaults with liveActiveFaultsWiredHubsAndWirelessHubs
      return ['infoBusDevices', liveWirelessZoneExpanderConsts.key, 'configBusDevices', 'configZoneInfoParts', 'configRadioDevicesLearnt', 'liveZoneInfo', 'liveActiveFaults'];
    },
    async populate(context, { endpoints, payload }) {
      const zemAddress = getWirelessZEMAddress(payload.index, endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[payload.index].ActiveInputs);
      const zonesMax = endpoints.infoBusDevices.data.Info.BusDevices.wirelessHubs[payload.index].AssociatedZones.length;
      context.commit('set', {
        name: endpoints.infoBusDevices.data.Info.BusDevices.wirelessHubs[payload.index].Name,
        version: endpoints.infoBusDevices.data.Info.BusDevices.wirelessHubs[payload.index].Version,
        zonesMax,
        state: endpoints.liveWirelessHubs.data.Live.BusDevices.wirelessHubs[payload.index].state,
      });

      context.commit('setForm', {
        type: 'TYPE_WIRELESS',
        name: endpoints.infoBusDevices.data.Info.BusDevices.wirelessHubs[payload.index].Name,
        address: zemAddress,
        hubIndex: payload.index,
        location: endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[zemAddress[0]].Location,
      });

      // Ensure wireless zones are awake.
      const AllDetectorsAwake = (await api.get('/Live/Devices/Wireless/ActivateAllDetectors')).data.Live.Devices.Wireless.ActivateAllDetectors;
      if (AllDetectorsAwake === false) {
        await api.put('/Live/Devices/Wireless/ActivateAllDetectors', true);
      }

      const associatedZones = [];
      for (let x = 0; x < zonesMax; x += 1) {
        // wireless zone exapnder can have 32 zones
        // ActiveInputs[0] = 0...7
        // ActiveInputs[1] = 8...15
        // ActiveInputs[2] = 16...23
        // ActiveInputs[3] = 24...31
        const number = endpoints.infoBusDevices.data.Info.BusDevices.wirelessHubs[payload.index].AssociatedZones[x].Number;
        const zoneType = endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[number - 1].ZoneType;
        if (zoneType !== 'ISOLATED') {
          const activeInputGroupIndex = Math.floor(x / 8); // wireless zones per ZEM address
          const notConfiguredForWiredUse = endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[payload.index].ActiveInputs[activeInputGroupIndex];
          if (notConfiguredForWiredUse) {
            associatedZones.push({
              number,
              zoneIndex: number - 1,
              name: endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[number - 1].Name,
              omittable: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[number - 1].Omittable,
              managerOmitted: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[number - 1].ManagerOmitted,
              setOmitted: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[number - 1].SetOmitted,
              forceOmitted: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[number - 1].ForceOmitted,
              zoneType,
              state: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[number - 1].state,
            });
          }
        }
      }
      context.commit('set', { associatedZones });
    },
    async onPoll(context, {
      dataStatus, key, endpoint, payload,
    }) {
      if (key === liveWirelessZoneExpanderConsts.key) {
        const wirelessHub = endpoint.data.Live.BusDevices.wirelessHubs[payload.index];

        let frequenceDescription;
        let frequencyDescriptionValue;
        if (wirelessHub.Radio.FirstSingleFrequencyDevice.Description != null) {
          const parts = wirelessHub.Radio.FirstSingleFrequencyDevice.Description.split('=');
          frequenceDescription = parts[0];
          if (parts.length > 1) frequencyDescriptionValue = parts[1];
        }
        if (frequenceDescription && frequenceDescription.length !== 0) {
          const frequencyValue = wirelessHub.Radio.SingleDoubleFrequencyStatus != null
            ? [i18n.t(`wirelessZoneExpander.Diagnostics.Frequency.enum.Status.${wirelessHub.Radio.SingleDoubleFrequencyStatus}`), i18n.t(`wirelessZoneExpander.Diagnostics.Frequency.enum.Description.${frequenceDescription}`, { extra: frequencyDescriptionValue })]
            : null;
          context.commit('trySetDiagnosticByKey', {
            dataStatus, key: 'wirelessZoneExpander.Diagnostics.Frequency', item: { value: frequencyValue },
          });

          const channelValue = wirelessHub.Radio.Channel != null && wirelessHub.Radio.ChannelChangeReason != null
            ? [padWithZero(wirelessHub.Radio.Channel, 2), i18n.t(`wirelessZoneExpander.Diagnostics.Channel.enum.ChangeReason.${wirelessHub.Radio.ChannelChangeReason}`)]
            : null;
          context.commit('trySetDiagnosticByKey', {
            dataStatus, key: 'wirelessZoneExpander.Diagnostics.Channel', item: { value: channelValue },
          });
        }
      }

      if (key === 'liveActiveFaults') {
        const busCommsFault = endpoint.data.Live.ActiveFaults.Devices.wirelessHubs[payload.index].BusComms;
        const caseTamperFault = endpoint.data.Live.ActiveFaults.Devices.wirelessHubs[payload.index].CaseTamper;

        context.commit('trySet', { dataStatus, data: { state: toFaultString(busCommsFault, caseTamperFault) } });
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wirelessZoneExpander.Diagnostics.Status',
          item: {
            value: caseTamperFault === true ? 'TAMPER' : toFaultString(busCommsFault),
            tooltip() {
              const value = this.value || 'FAULT';
              return i18n.t(`zoneExpanders.tooltips.state.${value}`);
            },
          },
        });
        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'wirelessZoneExpander.Diagnostics.DataBusStatus', item: { value: toFaultString(busCommsFault) },
        });

        const associatedZones = [];
        for (let x = 0; x < context.state.associatedZones.length; x += 1) {
          const { zoneIndex } = context.state.associatedZones[x];
          const {
            CaseTamper, Supervision, Battery, Polling,
          } = endpoint.data.Live.ActiveFaults.WirelessInputs[zoneIndex - limits.WE_ZONE_OFFSET];
          let isWirelessFault = context.state.associatedZones[x].isWirelessFault;
          if (CaseTamper === true || Supervision === true || Battery === true || Polling === true) {
            isWirelessFault = true;
          } else if (CaseTamper === false && Supervision === false && Battery === false && Polling === false) {
            isWirelessFault = false;
          }

          associatedZones.push({
            isWirelessFault,
          });
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: 'associatedZones', items: associatedZones });
      }

      if (key === liveZoneInfoConsts.key) {
        const associatedZones = [];
        for (let x = 0; x < context.state.associatedZones.length; x += 1) {
          const { zoneIndex } = context.state.associatedZones[x];
          const omittable = endpoint.data.Live.zoneInfo.Zones[zoneIndex].Omittable;
          const managerOmitted = endpoint.data.Live.zoneInfo.Zones[zoneIndex].ManagerOmitted;
          const setOmitted = endpoint.data.Live.zoneInfo.Zones[zoneIndex].SetOmitted;
          const forceOmitted = endpoint.data.Live.zoneInfo.Zones[zoneIndex].ForceOmitted;
          const { state } = endpoint.data.Live.zoneInfo.Zones[zoneIndex];

          associatedZones.push({
            omittable, forceOmitted, setOmitted, managerOmitted, state,
          });
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: 'associatedZones', items: associatedZones });
      }
    },
    async save(context) {
      for (let i = 0; i < context.state.form.address.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await api.put(`/Config/BusDevices/wiredHubs/${context.state.form.address[i]}/Location`, context.state.form.location);
      }

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('zoneExpandersState/wirelessZoneExpander/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['zoneExpandersState/wirelessZoneExpander'] });
    },
    async deleteItem(context) {
      const objDefaults = await generateDefaultObject(`/Config/BusDevices/wirelessHubs/${context.state.form.hubIndex}`);
      await api.put(`/Config/BusDevices/wirelessHubs/${context.state.form.hubIndex}`, objDefaults);

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('zoneExpandersState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('zoneExpandersState/addZoneExpander/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['zoneExpandersState/list'] });
    },
  },
});

export default store;
