<template>
    <a-modal :title="$t('common.Faults-exist')" v-model="visible">
        <li v-for="error in errors" :key="error">
          <p>{{ $t(`errors.ExitChecks.${error}`)}}</p>
          <p v-if="`${error}` === 'ARCCheckDetails'">{{$t(`errors.ExitChecks.ARCDetailsEnum.${arcDetails}`)}}</p>
        </li>
        <p>{{ $t('common.Do-you-want-to-exit-programming-mode') }}</p>
        <template slot="footer">
            <a-row :gutter="8">
                <a-col :span="12">
                    <a-button type="link" @click="returnControl">{{ $t('common.Yes') }}</a-button>
                    <a-button type="primary" @click="keepControl">{{ $t('common.No') }}</a-button>
                </a-col>
            </a-row>
        </template>
    </a-modal>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  props: {
    visible: { default: false },
    to: { default: null },
    errors: { default: [] },
    arcDetails: { default: '' },
  },
  methods: {
    returnControl() {
      // Yes = return to the browser to panel page, control panel to day mode and faults will alert.
      if (this.to) {
        // BUG-1608: This code mimics the code of the vue components mainNavigation.onClick() method for the
        //           situation where the User is trying to get back to the panel page from the engineers page
        //           after an error has been detected. We need to do this because this object consumes the click
        //           via this function and so the onClick method does not see it.

        // Clear the previous list of endpoints
        window.globalThis.currentRequiredEndpoints = [];

        // Jump to the panel page
        this.$router.push(this.to);

        // Clear prevent navigation banner
        this.$gLanModeSetting.panelPageStopNavigationBanner = false;
        this.$gLanModeSetting.currentPageIsPanel = true; // we are allowing navigation back to panel
      } else {
        this.$emit('logout');
      }
    },
    keepControl() {
      // No = close fault box and remain on the current page in the browser, control panel remains in programming mode.
      // BUG-1608: If trying to navigate to the panel page, it is likely that the 'currentPageIsPanel may have been set
      //           before the navigation failed and we ended up here. If we are here, we are not in the panel page so
      //           we need to reset this flag to prevent the system from thinking that we are.
      this.$gLanModeSetting.currentPageIsPanel = false;
      this.$emit('cancel');
    },
  },

};
</script>

<style lang="scss">

</style>
