<template>
    <a-drawer
    placement="right"
    :closable="false"
    @close="onClose"
    :destroyOnClose="true"
    :visible="visible">
    <wizard :config="wizard" :state="$v" @save="save" @done="onClose" :initialStep="clonePayload != null ? 'manual' : 'start'">

        <wizard-step name="start">
          <template slot="body" slot-scope="{ wizard }">
            <a-button class="draw-link" @click="wizard.navigateTo('autoscan')" type="link" block>
              <text-row :title="$t('common.Autoscan-wired-only')" title-icon="search" />
            </a-button>
            <a-button class="draw-link" @click="wizard.navigateTo('learn')" type="link" block>
              <text-row :title="$t('keypadsAndReaders.drawer.Learn-wireless-keypad')" title-icon="wifi" />
            </a-button>
            <a-button class="draw-link" @click="wizard.navigateTo('manual')" type="link" block>
              <text-row :title="$t('common.Add-manually')" title-icon="plus-circle" />
            </a-button>
          </template>
        </wizard-step>

        <wizard-step name="autoscan">
          <autoscan @done="onClose" @restart="onClose" :keypads="true" :readers="true" :zoneExpanders="false" :outputExpanders="false" />
        </wizard-step>

        <wizard-step name="learn">
           <learn-wireless @done="onClose" :start-at="{ stage: 'info', type: 'keypadsAndReaders' }" />
        </wizard-step>

        <wizard-step name="manual">
          <a-form>
            <p-select :label="$t('common.Type')" :model="$v.form.type" :items="types" :valueExpr="(i) => i.value" :textExpr="(i) => i.name" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-input :label="$t('common.Name')" :model="$v.form.name" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-input :label="$t('common.Location')" :model="$v.form.location" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-select :label="$t('common.Address')" :model="$v.form.address" :items="addresses" :valueExpr="(i) => i" :textExpr="(i) => i" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
          </a-form>
        </wizard-step>

        <wizard-step name="keypad">
          <a-form>
            <p-area-selector :label="$t('common.Sets')" :model="$v.form.setsAreas" :areasInfo="areasInfo" :areasConfig="areasConfig" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-area-selector :label="$t('common.Unsets')" :model="$v.form.unsetsAreas" :areasInfo="areasInfo" :areasConfig="areasConfig" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-area-selector :label="$t('common.In-area')" :model="$v.form.inAreas" :areasInfo="areasInfo" :areasConfig="areasConfig" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
          </a-form>
        </wizard-step>

        <wizard-step name="reader">
          <a-form>
            <p-select :label="$t('wiredReader.Reader-type')" :model="$v.form.readerType" :items="READER_TYPE.values" :valueExpr="(i) => i.value" :textExpr="(i) => i.name" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />

            <template v-if="$v.form.readerType.$model === READER_TYPE.ARM_DISARM.value || $v.form.readerType.$model === READER_TYPE.DISARM_ONLY.value || $v.form.readerType.$model === READER_TYPE.ENTRY_CONTROL.value">
              <p-area-selector :label="$t('common.Sets')" :model="$v.form.setsAreas" :areasInfo="areasInfo" :areasConfig="areasConfig" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
              <p-area-selector :label="$t('common.Unsets')" :model="$v.form.unsetsAreas" :areasInfo="areasInfo" :areasConfig="areasConfig" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
              <p-area-selector :label="$t('common.In-area')" :model="$v.form.inAreas" :areasInfo="areasInfo" :areasConfig="areasConfig" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            </template>
            <template v-if="$v.form.readerType.$model === READER_TYPE.ACCESS_CONTROL.value || $v.form.readerType.$model === READER_TYPE.ENTRY_CONTROL.value">
              <p-input :label="$t('wiredReader.Lock-open-time-secs')" :model="$v.form.lockOpenTime" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
              <p-input :label="$t('wiredReader.Door-open-time-secs')" :model="$v.form.doorOpenTime" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
              <p-zone-selector @selectedZone="onSelectZone" :label="$t('wiredReader.Door-contact-number')" :model="$v.form.doorContactNumber" :zones="zones" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            </template>
          </a-form>
        </wizard-step>
    </wizard>

    </a-drawer>
</template>
<script>
import {
  required, maxLength, between,
} from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import { arrayHasTrueValue } from '@/app/shared/utils/validation';

import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';
import LearnWireless from '@/app/shared/components/add-devices-drawer/learn-wireless.vue';
import Autoscan from '@/app/shared/components/add-devices-drawer/autoscan.vue';

import { READER_TYPE, WARD_READMITS, WARD_CONTROL } from '@/app/keypads-and-readers/shared/enums';

import { Clone } from '@/app/shared/mixins';
import { limits } from '@/app/shared/constants';

const storeNamespace = 'keypadsAndReadersState/addKeypadOrReader';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);
export default {
  mixins: [Clone],
  components: {
    Wizard,
    WizardStep,
    LearnWireless,
    Autoscan,
  },
  data() {
    return {
      clone: {
        storeNamespace,
      },
      wizard: {
        start: {
          title: this.$t('keypadsAndReaders.drawer.How-do-you-want-to-add-a-keypad-or-reader'),
          next: [],
        },
        autoscan: {
          mode: 'SIMPLE',
        },
        learn: {
          mode: 'SIMPLE',
        },
        manual: {
          title: this.$t('keypadsAndReaders.drawer.Add-a-keypad-or-reader'),
          progress: 30,
          onExitValidate: 'startStep',
          next: [
            { key: 'reader', buttonText: this.$t('common.Next'), if: () => this.$v.form.type.$model === 'TYPE_READER' },
            { key: 'keypad', buttonText: this.$t('common.Next'), if: () => this.$v.form.type.$model !== 'TYPE_READER' },
          ],
        },
        keypad: {
          title: () => this.$t('keypadsAndReaders.drawer.Add-wired-keypad'),
          onExitValidate: 'keypadStep',
          progress: 30,
          next: [
            { key: 'save', buttonText: this.$t('common.Add-device') },
          ],
        },
        reader: {
          title: this.$t('keypadsAndReaders.drawer.Add-wired-reader'),
          onExitValidate: 'readerStep',
          progress: 60,
          next: [
            { key: 'save', buttonText: this.$t('common.Add-device') },
          ],
        },
      },
      READER_TYPE,
      WARD_READMITS,
      WARD_CONTROL,
    };
  },
  validations: {
    startStep: ['form.name', 'form.type', 'form.location', 'form.address'],
    keypadStep: ['form.inAreas'],
    readerStep: ['form.name', 'form.readerType'],
    reader_areas: ['form.inAreas'],
    reader_access: ['form.lockOpenTime', 'form.doorOpenTime', 'form.doorContactNumber'],
    form: {
      name: {
        required,
        maxLength: maxLength(16),
      },
      type: {
        required,
      },
      location: {
        maxLength: maxLength(16),
      },
      address: {
        required,
      },
      setsAreas: {},
      unsetsAreas: {},
      inAreas: {
        arrayHasTrueValue,
      },
      lockOpenTime: {
        required,
        between: between(1, 255),
      },
      doorOpenTime: {
        required,
        between: between(1, 255),
      },
      doorContactNumber: {
        required,
      },
      areaSetUnset: {},
      readerType: {
        required,
      },
    },
  },
  computed: {
    ...mapState(['availableAddresses', 'areasInfo', 'areasConfig', 'zones']),
    ...mapFields(storeNamespace, ['form']),
    addresses() {
      if (this.$v.form.type.$model === 'TYPE_WKEYPAD') {
        return this.availableAddresses.filter(a => a > 29).map(a => a - 30);
      }
      return this.availableAddresses.filter(a => a < 30);
    },
    types() {
      return [
        { value: 'TYPE_KEYPAD', name: this.$t('keypadsAndReaders.enums.deviceType.TYPE_KEYPAD') },
        { value: 'TYPE_READER', name: this.$t('keypadsAndReaders.enums.deviceType.TYPE_READER') },
      ];
    },
  },
  watch: {
    // Whenever we close the wizard, the component should be reset
    visible(newVal) {
      if (!newVal) {
        this.$store.dispatch('keypadsAndReadersState/addKeypadOrReader/reset');
        this.$v.$reset();
      }
    },
  },
  methods: {
    ...mapActions(['add']),
    async onClose() {
      this.$emit('onClose');
    },
    async save() {
      this.$v.$touch();
      if (this.$v.form.readerType.$model === READER_TYPE.ARM_DISARM.value && this.$v.reader_areas.$invalid) return;
      if (this.$v.form.readerType.$model === READER_TYPE.ACCESS_CONTROL.value && this.$v.reader_areas.$invalid) return;
      if (this.$v.form.readerType.$model === READER_TYPE.DISARM_ONLY.value && this.$v.reader_areas.$invalid) return;
      if (this.$v.form.readerType.$model === READER_TYPE.ENTRY_CONTROL.value && (this.$v.reader_areas.$invalid || this.$v.reader_areas.$invalid)) return;

      await this.add();
      this.$emit('onClose');

      if (!this.navigateToNewItem) {
        return;
      }

      if (this.form.type === 'TYPE_KEYPAD') {
        this.$router.push({ name: 'wired-keypad', params: { index: this.form.address } });
      } else if (this.form.type === 'TYPE_READER') {
        this.$router.push({ name: 'wired-reader', params: { index: this.form.address } });
      }
    },
    onSelectZone(a) {
      this.$v.form.doorContactNumber.$model = (a !== null) ? a.internalKey : limits.MAX_ZONES;
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}
</style>
