<template>
  <single-column-layout>
    <div>
      <img src="@/assets/images/Logo.svg" class="logo"/>

      <a-form>
        <div class="spinner__wrapper" v-if="mode === null && error === null">
          <font-awesome-icon class="fa-spin" :icon="['fal', 'spinner']" size="8x" />
        </div>
        <div v-else-if="mode === SECURITY_MODE.LOGGED_OUT && error !== null">
          <a-form-item :label="mode === (SECURITY_MODE.LOW) ? $t('login.Engineer-code') :  $t('login.Configurator-password-from-panel')">
            <a-input type="password" @pressEnter="login" v-model="password" />
          </a-form-item>
        </div>
        <div v-else-if="mode === null && error !== null">
          <p class="login__error">{{ error }}</p>
        </div>
        <div v-else>
          <a-form-item :label="mode === (SECURITY_MODE.LOW) ? $t('login.Engineer-code') :  $t('login.Configurator-password-from-panel')"
          :validate-status="error != null ? 'error' : null"
          :help="error">
            <a-input type="password" @pressEnter="login" v-model="password" />
          </a-form-item>

          <a-button type="primary" @click="login" :loading="isLoggingIn">
            {{ $t('login.Log-in') }}
          </a-button>
        </div>
      </a-form>
    </div>
    <template slot="footer">
      <!-- <h4 class="text-center">{{ $t('changeLanguage.navigationTitle') }}</h4> -->
      <!-- <div class="text-center languages">
          <a-button class="clear">
              <img src="@/assets/images/english.svg" />
              <p>{{ $t('changeLanguage.English') }}</p>
          </a-button>
          <a-button class="clear">
              <img src="@/assets/images/french.svg" />
              <p>{{ $t('changeLanguage.French') }}</p>
          </a-button>
          <a-button class="clear">
              <img src="@/assets/images/italian.svg" />
              <p>{{ $t('changeLanguage.Italian') }}</p>
          </a-button>
          <a-button class="clear">
              <img src="@/assets/images/spanish.svg" />
              <p>{{ $t('changeLanguage.Spanish') }}</p>
          </a-button>
      </div> -->
      <slot> {{ $t('login.Copyright') }} {{ copyrightYear }} Pyronix Ltd {{ version }} </slot>
    </template>
  </single-column-layout>
</template>

<script>
import SingleColumnLayout from '@/app/shared/components/layouts//single-col-layout.vue';
import authService from '@/app/shared/services/auth-service'; // eslint-disable-line import/no-cycle
import { SECURITY_MODE } from '@/app/shared/state/auth-state';
import { getLimits } from '@/app/shared/constants'; // eslint-disable-line import/no-cycle
import { isSessionLoggedOut } from '@/app/shared/components/Navigation/MainNavigation/MainNavigation.vue';
// eslint-disable-next-line import/no-cycle
import { version } from '@/app/environment/environment';

let modeLoggedOut = SECURITY_MODE.LOGGED_OUT;

export default {
  components: {
    SingleColumnLayout,
  },
  data() {
    return {
      isLoggingIn: false,
      mode: null,
      password: '',
      error: null,
      SECURITY_MODE,
      copyrightYear: new Date().getFullYear(),
      version: `v${version}`,
    };
  },
  methods: {
    async login() {
      this.isLoggingIn = true;
      const result = await authService.validateSession(this.password);
      if (!result) {
        this.error = this.$t('common.CannotLogin');
        await this.startSession();
      } else {
        window.localStorage.setItem('isLoggedIn', 'true');
        await getLimits();
        this.$router.push({ name: 'panel' });
      }
      this.isLoggingIn = false;

      modeLoggedOut = this.mode;
    },
    async startSession() {
      try {
        this.mode = await authService.requestSession();
      } catch {
        if (isSessionLoggedOut() === true) {
          this.error = null;
        }
      }
    },
    reloadPageIfRequired() {
      if (window.localStorage.getItem('reloadLoginPageRequired') === 'true') {
        // Clear the flag, then reload this page.
        window.localStorage.setItem('reloadLoginPageRequired', 'false');
        window.location.reload();
      }
    },
  },
  async created() {
    // Force the login page to reload if flag set
    this.reloadPageIfRequired();
    // For a more uniform stable session, clear the entire cache when the log in page comes up (this includes the vuex object containing the stores and all other persistent variables in localstorage)
    window.localStorage.clear();

    if (isSessionLoggedOut() === true) {
      this.mode = modeLoggedOut;
      this.error = null;
    } else {
      await this.startSession();
    }
  },
};
export function isSessionLoggedIn() {
  return window.localStorage.getItem('isLoggedIn');
}
export function setIsLoggedIn(value) {
  window.localStorage.setItem('isLoggedIn', value);
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/base/variables';

  .spinner__wrapper {
    text-align: center;
  }

  .login__error {
    text-align: center;
    color: #f5222d;
  }
    .logo {
        margin:0 auto 2rem;
        width:100%;
        text-align: center;
        display:block;
    }

    .languages {
        button {
            margin-top:0.5rem;
            cursor: pointer;
            margin-bottom:0.5rem;

            &:hover {
                background:transparent;
            }
            p {
                margin-top:5px;
                color:#484848;

                &:hover {
                    color:$primary-color;
                }
            }
        }
    }

</style>
