<template>
  <three-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
    <template v-slot:title>
      <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
        <h1 id="title">{{ name }}</h1>
      </a-skeleton>
    </template>
    <template v-slot:titleCommands>
      <item-menu
        @save="saveForm" :isSaving="isSaving"
        @delete="onDelete"
        :canClone="true" @clone="addDrawerVisible = true"
        :hasToggle="true"
        :toggled="state"
        :toggleTooltip="isEnabledTooltip(state)"
        @toggle="activateOutput"
        :disabled="!configWriteLock.canWriteConfig" />
    </template>
    <diagnostics :globalState="faultState ? 'FAULT' : 'OK'" :items="diagnostics" :isLoading="false" />
    <form>
      <div class="styled-section">
        <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
          <div>
            <h3 class="h4">{{ $t("sirens.Output-information") }}</h3>
            <a-row :gutter="50">
              <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                <p-readonly :label="$t('common.Name')" :value="name" />
              </a-col>
              <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                <p-select
                  :label="$t('outputs.Output-type')"
                  :model="$v.form.outputType"
                  :items="outputTypes"
                  :valueExpr="(i) => i.value"
                  :textExpr="(i) => i.name"
                  :disabled="!configWriteLock.canWriteConfig"
                />

              </a-col>
              <a-col
                class="gutter-row"
                :xs="{ span: 24}"
                :lg="{ span: 8}"
                :validate-status="$v.form.associatedWithOutputIndex.$error ? 'error': 'success'"
              >
                <p-select
                  :label="$t('common.Number')"
                  :model="$v.form.associatedWithOutputIndex"
                  :items="outputIndexes"
                  :valueExpr="(i) => i"
                  :textExpr="(i) => i + 1"
                  :showPleaseSelect="true"
                  :disabled="!configWriteLock.canWriteConfig"
                />
              </a-col>
              <a-col
                class="gutter-row"
                :xs="{ span: 24}"
                :lg="{ span: 8}"
                :validate-status="$v.form.associatedWithDevice.$error ? 'error': 'success'"
              >
                <p-select
                  :label="$t('common.Associated-with')"
                  :model="$v.form.associatedWithDevice"
                  :items="associatedDevices"
                  :valueExpr="(i) => i.key"
                  :textExpr="(i) => i.name"
                  @change="$v.form.associatedWithOutputIndex.$model = null"
                  :disabled="!configWriteLock.canWriteConfig"
                />
              </a-col>
            </a-row>
          </div>
        </a-skeleton>
      </div>
    </form>

    <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
    <add-siren :visible="addDrawerVisible" :navigateToNewItem="true" @onClose="addDrawerVisible = false" :clone-payload="loader.payload()" />
    <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
      @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </three-col-layout>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';

import AddSiren from '@/app/sirens/shared/components/add-siren/add-siren.vue';
import ItemMenu from '@/app/shared/components/item-menu/item-menu.vue';

import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';

import outputHelper from '@/app/shared/services/output-helper';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

const storeNamespace = 'sirensState/wiredSiren';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, UnsavedGuard, StateLoader, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    AddSiren,
    ItemMenu,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
        payload: () => ({ index: this.$route.params.index }),
      },
      addDrawerVisible: false,
      isSaving: false,
    };
  },
  props: {},
  computed: {
    ...mapState(['form', 'name', 'diagnostics', 'state', 'faultState', 'associatedDevices']),
    ...mapGetters(['isLoading', 'outputTypes', 'isSiren']),
    outputIndexes() {
      const device = this.associatedDevices.find(v => v.key === this.$v.form.associatedWithDevice.$model);
      return device
        ? device.availableOutputs.map(i => i.deviceOutputIndex)
        : [];
    },

  },
  validations: {
    form: {
      outputType: {
        required,
      },
      associatedWithOutputIndex: {
        required,
      },
      associatedWithDevice: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(['save', 'delete']),
    onDelete() {
      return this.delete().then(() => {
        this.$router.push({ name: 'sirens-list' });
      });
    },
    async activateOutput() {
      await outputHelper.activateOutputType(
        this.form.rawOutputType,
        !this.state,
        this.loader.instance,
      );
    },
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });

      if (this.$route.params.index !== this.form.associatedWithOutputIndex || !this.isSiren) {
        this.$router.replace({ name: this.isSiren ? 'wired-siren' : 'output', params: { index: this.form.associatedWithOutputIndex } });
        this.load();
      }
    },
    isEnabledTooltip(isEnabled) {
      return isEnabled
        ? this.$t('sirens.tooltips.enabled')
        : this.$t('sirens.tooltips.disabled');
    },
  },
};
</script>

<style lang="scss">
</style>
