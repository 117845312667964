<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('settings.generalTimers.navigationTitle') }}</h2>
      </a-row>
      <a-form>
        <p-input :label="$t('settings.generalTimers.confirmTime')" :labelTooltip="$t('settings.generalTimers.confirmTime-tooltip')" :model="$v.form.confirmTime" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.PA')" :labelTooltip="$t('settings.generalTimers.PA-tooltip')" :model="$v.form.pa" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Re-arm-number')" :labelTooltip="$t('settings.generalTimers.Re-arm-number-tooltip')" :model="$v.form.rearmNumber" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Final-door-delay')" :labelTooltip="$t('settings.generalTimers.Final-door-delay-tooltip')" :model="$v.form.finalDoorDelay" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.AC-fail-delay')" :labelTooltip="$t('settings.generalTimers.AC-fail-delay-tooltip')" :model="$v.form.acFailDelay" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.AC-missing-delays')" :labelTooltip="$t('settings.generalTimers.AC-missing-delays-tooltip')" :model="$v.form.acMissingDelay" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-select :label="$t('settings.generalTimers.Exit-suspend-time')"
                  :labelTooltip="$t('settings.generalTimers.Exit-suspend-time-tooltip')"
                  :model="$v.form.exitSuspendTime"
                  :items="Object.keys(EXIT_SUSPEND_TIME)"
                  :textExpr="(i) => $t(`enums.EXIT_SUSPEND_TIME.${i}`)" :valueExpr="(i) => EXIT_SUSPEND_TIME[i]"
                  display-style="small"
                  :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Double-knock')" :labelTooltip="$t('settings.generalTimers.Double-knock-tooltip')" :model="$v.form.doubleKnock" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Set-fail')" :labelTooltip="$t('settings.generalTimers.Set-fail-tooltip')" :model="$v.form.setFail" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Set-fail-warning')" :labelTooltip="$t('settings.generalTimers.Set-fail-warning-tooltip')" :model="$v.form.setFailWarning" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Zone-NAT-days')" :labelTooltip="$t('settings.generalTimers.Zone-NAT-days-tooltip')" :model="$v.form.zoneNATDays" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Zone-NAT-hours')" :labelTooltip="$t('settings.generalTimers.Zone-NAT-hours-tooltip')" :model="$v.form.zoneNATHours" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Occupancy-timer')" :labelTooltip="$t('settings.generalTimers.Occupancy-timer-tooltip')" :model="$v.form.occupancyTimer" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Wireless-supervision-time')" :labelTooltip="$t('settings.generalTimers.Wireless-supervision-time-tooltip')" :model="$v.form.wirelessSupervisionTime" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Wireless-jamming-time')" :labelTooltip="$t('settings.generalTimers.Wireless-jamming-time-tooltip')" :model="$v.form.wirelessJammingTime" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Service-time-days')" :labelTooltip="$t('settings.generalTimers.Service-time-days-tooltip')" :model="$v.form.serviceTimeDays" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Entry-comms-delay')" :labelTooltip="$t('settings.generalTimers.Entry-comms-delay-tooltip')" :model="$v.form.entryCommsDelay" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Ethernet-comms-fault-delay')" :labelTooltip="$t('settings.generalTimers.Ethernet-comms-fault-delay-tooltip')" :model="$v.form.ethernetCommsFaultDelay" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.WIFI-comms-fault-delay')" :labelTooltip="$t('settings.generalTimers.WIFI-comms-fault-delay-tooltip')" :model="$v.form.wifiCommsFaultDelay" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Cellular-comms-fault-delay')" :labelTooltip="$t('settings.generalTimers.Cellular-comms-fault-delay-tooltip')" :model="$v.form.cellularCommsFaultDelay" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.generalTimers.Serial-comms-fault-delay')" :labelTooltip="$t('settings.generalTimers.Serial-comms-fault-delay-tooltip')" :model="$v.form.serialCommsFaultDelay" display-style="small" :disabled="!configWriteLock.canWriteConfig" />

      </a-form>
      <div class="align-right">
        <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
      </div>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </div>
</template>

<script>
import { required, between } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';

import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import { EXIT_SUSPEND_TIME } from '@/app/settings/shared/enums';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'settingsState/generalTimers';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      EXIT_SUSPEND_TIME,
      addDrawerVisible: false,
      isSaving: false,
    };
  },
  computed: {
    ...mapState(['form']),
  },
  validations: {
    form: {
      confirmTime: {
        required,
        between: between(0, 99),
      },
      pa: {
        required,
        between: between(8, 20),
      },
      rearmNumber: {
        required,
        between: between(0, 9),
      },
      finalDoorDelay: {
        required,
        between: between(0, 10),
      },
      acFailDelay: {
        required,
        between: between(0, 250),
      },
      acMissingDelay: {
        required,
        between: between(0, 250),
      },
      exitSuspendTime: {
        required,
      },
      doubleKnock: {
        required,
        between: between(0, 75),
      },
      setFail: {
        required,
        between: between(5, 250),
      },
      setFailWarning: {
        required,
        between: between(0, 99),
      },
      zoneNATDays: {
        required,
        between: between(0, 14),
      },
      zoneNATHours: {
        required,
        between: between(0, 23),
      },
      occupancyTimer: {
        required,
        between: between(0, 99),
      },
      wirelessSupervisionTime: {
        required,
        between: between(0, 99),
      },
      wirelessJammingTime: {
        required,
        between: between(0, 100),
      },
      serviceTimeDays: {
        required,
        between: between(0, 734),
      },
      entryCommsDelay: {
        required,
        between: between(0, 180),
      },
      ethernetCommsFaultDelay: {
        required,
        between: between(0, 999),
      },
      wifiCommsFaultDelay: {
        required,
        between: between(0, 999),
      },
      cellularCommsFaultDelay: {
        required,
        between: between(0, 999),
      },
      serialCommsFaultDelay: {
        required,
        between: between(0, 999),
      },
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
};
</script>
