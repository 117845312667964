<template>
  <a-row>
    <a-col :xs="{ span: 24}" :lg="{ span: lgSpan}">
      <card>
        <template slot:status>
            <status v-if="isLoading || diagnosticsUnavailable" state="blank" />
            <status v-else :state="mergedOptions.globalState(mergedOptions.hasGlobalFault(globalState))" />
        </template>
        <template slot="body">
          <div v-if="diagnosticsUnavailable" class="diagnotics__unavailable">
            <div class="diagnotics__unavailable--left">
              <font-awesome-icon class="error" :icon="['fal', 'exclamation-triangle']" size="2x" />
            </div>
            <div class="diagnotics__unavailable--right">
              <h3>{{ $t('diagnostics.title') }}</h3>
              <p>
                {{ $t('diagnostics.body', { type: deviceType || $t('common.Device') }) }}
              </p>
            </div>
          </div>
          <div v-else class="diagnostic__wrapper">
            <statusTextBlock v-show="diagnostic.hidden !== true" v-for="(diagnostic, index) in items" :key="`diagnostic-${index}`"
              :loading="diagnostic.isLoading || isLoading"
              :title="getTitle(diagnostic)"
              :value="formattedValue(diagnostic)"
              :hidden="isHidden(diagnostic)"
              :tooltip="diagnostic.tooltip ? diagnostic.tooltip(diagnostic) : null"
              :type="mergedOptions.statusType(diagnostic)"
              :valueIsHtml="diagnostic.valueIsHtml || false"
              :tooltipIsHtml="diagnostic.tooltipIsHtml || false" />
          </div>
        </template>
      </card>
    </a-col>
  </a-row>
</template>
<script>
import statusTextBlock from '@/app/shared/components/Common/StatusTextBlock.vue';
import card from '@/app/shared/components/Card/card.vue';
import status from '@/app/shared/components/Status/status.vue';
import { wrlsDevicesDiagnosticsConsts } from '@/app/shared/constants';

export default {
  components: {
    statusTextBlock,
    card,
    status,
  },
  props: {
    globalState: { required: false },
    isLoading: { type: Boolean, default: true },
    items: { required: true },
    options: { required: false },
    diagnosticsUnavailable: { type: Boolean, default: false },
    deviceType: { type: String, default: null },
  },
  data() {
    return {
      defaultOptions: {
        globalState(fault) {
          if (fault === undefined) return 'blank';
          return fault ? 'error' : 'active';
        },
        statusType(diagnostic) {
          return this.isError(diagnostic.value) || diagnostic.value === false ? 'error' : 'active';
        },
        hasGlobalFault(globalState) {
          if (globalState === undefined) return undefined;
          return globalState !== 'OK';
        },
        isError(value) {
          return value === 'FAULT'
            || value === 'FUSE'
            || value === 'TAMPER'
            || value === 'ERROR';
        },
      },
    };
  },
  computed: {
    mergedOptions() {
      return {
        ...this.defaultOptions,
        ...this.options,
      };
    },
    lgSpan() {
      if (this.items.length === 1) {
        return 4;
      }
      return this.items.length > 2 ? 24 : 15;
    },
  },
  methods: {
    // Determine if a diagnostic element should be displayed or not.
    isHidden(diagnostic) {
      // Only ZEM/OPM BatteryStatus diagnostic can be hidden.
      if ((diagnostic.key === 'wiredZoneExpander.Diagnostics.BatteryStatus') || (diagnostic.key === 'outputExpander.Diagnostics.BatteryStatus')) {
        // If the value is HIDDEN, hide the element.
        if (diagnostic.value === 'HIDDEN') {
          return true;
        }
      }
      // Do not hide the diagnostic element.
      return false;
    },
    // Determine the title string to use for the diagnostic item.
    // If a (new) title has been supplied in the diag key item, use that instead of the current title.
    getTitle(diagnostic) {
      let titleString = this.$t(`${diagnostic.key}.title`);
      const diagTitleString = diagnostic.title;
      if (diagTitleString !== undefined) {
        // A title string has been given in the diag key item, so use it instead.
        titleString = diagTitleString;
      }
      return titleString;
    },
    //
    formattedValue(diagnostic) {
      if (diagnostic.format != null) {
        if (diagnostic.format.format != null) {
          return diagnostic.format.format(diagnostic.value);
        }
        return diagnostic.format(diagnostic.value);
      }

      // Fixing feature broken by BUG-1460.
      // Not all enumerated strings are defined in locales.
      const arr = Object.values(wrlsDevicesDiagnosticsConsts);
      let isElementFound = false;
      // Prevent exception thrown when strings are not found in the array
      try {
        isElementFound = arr.find(element => element === diagnostic.value);
        // eslint-disable-next-line no-empty
      } catch { } // empty catch because if the string is not found, it is probably an enumnerated value.
      if (typeof diagnostic.value === 'string' && isElementFound) {
        return this.$t(`${diagnostic.value}`);
      }
      // Handle any strings that don't have an appropriate enumerated value yet.
      const enumString = this.$t(`${diagnostic.key}.enum.${diagnostic.value}`);
      if (enumString.indexOf('.') !== -1) {
        // return a string value since output is in dot notation.
        return this.$t(`${diagnostic.value}`);
      }
      // return enumerated value for all other values.
      return this.$t(`${diagnostic.key}.enum.${diagnostic.value}`);
    },
  },
};
</script>
<style lang="scss">
.diagnotics__unavailable {
  margin: 10px 0 20px 0;
  display: flex;
  align-items: center;

  &--left {
    margin-right: 20px;
  }
  &--right {
    justify-content: space-around;
  }

  h3, p {
    font-size: 0.95rem;
    margin: 0;
  }
}
</style>
