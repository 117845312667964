import { createArray } from '@/app/shared/services/api';
import { storeFactory } from '@/app/shared/services/store-helper';
import {
  readRawOutputType, generateEndstationOutputNumber, getOutputInfo, convertOutputType, getEndstationSubType,
} from '@/app/outputs/shared/services/helpers';
import { limits } from '@/app/shared/constants';


function initialState() {
  return {
    _items: createArray(limits.MAX_ES_OUTPUTS + 1, () => ({})),
    installed: undefined,
    maximum: undefined,
    endstation: undefined,
  };
}

const { store } = storeFactory(initialState, {
  getters: {
    items: state => state._items.sort((firstItem, secondItem) => firstItem.number - secondItem.number).filter(i => i.visible),
  },
  actions: {
    requiredEndpoints: () => ['infoOutputInfo', 'infoEndstation', 'configEndstation', 'livePanelStatus'],
    async populate(context, { endpoints }) {
      const maximumOutputs = endpoints.infoOutputInfo.data.Info.outputInfo.EndstationOutputsMaxCount;
      const endstOutputs = endpoints.infoEndstation.data.Info.Endstation.Outputs;
      const opDisplayNumbers = generateEndstationOutputNumber(endstOutputs);
      for (let i = 0; i < maximumOutputs; i += 1) {
        const rawOutputType = readRawOutputType(endpoints, 'ENDSTATION', null, i);
        const outputType = convertOutputType(rawOutputType);
        const { subType, subTypeIndex } = getEndstationSubType(i);
        const panelConfig = endpoints.configEndstation.data.Config.Endstation[subType][subTypeIndex];
        context.commit('setItemByIndex', {
          collection: '_items',
          index: i,
          item: {
            associatedWith: getOutputInfo('ENDSTATION', null, i, outputType),
            number: opDisplayNumbers[i],
            name: endstOutputs[i].Name,
            present: endstOutputs[i].Present,
            state: endpoints.livePanelStatus.data.Live.PanelStatus.OutputTypes[rawOutputType].state,
            rawOutputType,
            outputType,
            followTime: panelConfig.Timer_100ms,
            followType: panelConfig.FollowState,
            visible: outputType !== 0 && endstOutputs[i].Present,
          },
        });
      }
      context.commit('set', {
        installed: context.state._items.filter(i => i.visible).length,
        maximum: context.state._items.filter(i => i.present).length,
        endstation: true,
      });
    },
    async onPoll(context, { dataStatus, key, endpoint }) {
      if (key === 'livePanelStatus') {
        const items = [];
        for (let i = 0; i < context.state._items.length; i += 1) {
          const { rawOutputType } = context.state._items[i];
          items.push({
            state: endpoint.data.Live.PanelStatus.OutputTypes[rawOutputType].state,
          });
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: '_items', items });
      }
    },
  },
});

export default store;
