import axios from 'axios';
import axiosRetry from 'axios-retry';
import { apiBaseUrl } from '@/app/environment/environment';

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosRetry(axiosInstance, { retries: 0 });

export default axiosInstance;
