import Users from './users.vue';
import UsersList from './pages/users-list/users-list.vue';
import User from './pages/user/user.vue';

const routes = [
  {
    path: '/users',
    component: Users,
    children: [
      {
        path: '',
        name: 'users-list',
        component: UsersList,
        meta: { breadcrumb: [{ nameRes: 'users.navigationTitle' }] },
      },
      {
        path: 'user/:index',
        name: 'user',
        component: User,
        meta: { breadcrumb: [{ nameRes: 'users.navigationTitle', link: 'users-list' }, { nameRes: 'user.navigationTitle' }] },
      },
    ],
  },
];

export default routes;
