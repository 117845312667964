import axios from 'axios';
import axiosRetry from 'axios-retry';
import { apiBaseUrl } from '@/app/environment/environment';
import encryptionService from '@/app/shared/services/encryption-service';

function getUrl(config) {
  if (config.baseURL) {
    return config.url.replace(config.baseURL, '');
  }
  return config.url;
}

const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  if (config.method.toUpperCase() === 'PUT') {
    console.log(`DEBUG PUT ${getUrl(config)}\n${JSON.stringify(encryptionService.decrypt(config.data))}`);
  }
  return config;
}, error => Promise.reject(error));

axiosInstance.interceptors.response.use((response) => {
  if (response.config.method.toUpperCase() === 'GET') {
    console.log(`DEBUG GET  ${getUrl(response.config)} ${response.status}\n${JSON.stringify(response.data)}`);
  }
  return response;
}, error => Promise.reject(error));

axiosRetry(axiosInstance, { retries: 0 });

export default axiosInstance;
