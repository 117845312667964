/* eslint-disable no-param-reassign */
import Q from 'q';
import { API } from '@/app/shared/services/api';

let instance = null;

export default class DynamicLoader {
  constructor(id, $store) {
    this.api = new API();
    this.$store = $store;
    this.stores = {};
    this.requests = {};
    this.inprogress = false;
    this.id = id;
  }

  dispose() {
    this.api.cancelPolling();
  }

  async populate(storeKey, payload, callback) {
    const requiredRequests = await this.$store.getters[`${storeKey}/requiredRequests`];

    requiredRequests.forEach((requiredRequest) => {
      let request = this.requests[requiredRequest.key];
      if (request == null) {
        request = this._setupRequest(requiredRequest);
        this.requests[request.key] = request;
      }

      request.usedBy.push(storeKey);

      this.loadRequest(request);
    });

    this.stores[storeKey] = {
      key: storeKey, endpoints: requiredRequests, payload, requiredEndpoints: requiredRequests, overlapped: false,
    };

    this.stores[storeKey].watcher = this._createWatcher(storeKey, requiredRequests, payload);

    await this._populate(storeKey, requiredRequests, payload);

    await this.api.asyncDone();

    if (callback) callback();
  }

  // eslint-disable-next-line class-methods-use-this
  _setupRequest(requiredRequest) {
    const defaultParams = {
      usedBy: [], promise: null, data: null, type: 'once', watchers: {},
    };
    return { ...defaultParams, ...requiredRequest };
  }

  async _createWatcher(storeKey, requiredRequests, payload) {
    return this.$store.watch(
      (state, getters) => getters[`${storeKey}/requiredRequests`],
      async (newValue, oldValue) => {
        if (this.inprogress) {
          this.stores[storeKey].overlapped = true;
          return;
        }
        this.stores[storeKey].overlapped = false;
        this.inprogress = true;
        let requestReloaded = false;
        newValue.forEach((v, index) => {
          if (v.params !== oldValue[index].params) {
            this.reloadRequest(v);
            requestReloaded = true;
          }
        });

        if (requestReloaded) {
          await this._populate(storeKey, requiredRequests, payload);
        }
        this.inprogress = false;
      },
    );
  }

  async _populate(storeKey, requiredRequests, payload) {
    const requestsToWaitFor = requiredRequests.map(rr => this.requests[rr.key].promise);
    await Q.allSettled(requestsToWaitFor);
    await this.$store.dispatch(`${storeKey}/populateWrapper`, { requests: this.requests, payload });
  }


  async unload(storeKey) {
    this.$store.dispatch(`${storeKey}/unload`, { payload: this.stores[storeKey].payload });

    if (this.stores[storeKey].watcher != null) {
      (await this.stores[storeKey].watcher)();
      this.stores[storeKey].watcher = undefined;
    }

    Object.keys(this.requests).forEach(async (requestKey) => {
      const request = this.requests[requestKey];

      if (request.polling != null && request.usedBy.filter(e => e !== storeKey).length === 0) {
        request.polling.cancel();
        request.polling = null;
      }

      request.usedBy = request.usedBy.filter(i => i !== storeKey);
    });
    delete this.stores[storeKey];
  }

  async reloadRequest(request) {
    const existingRequest = this.requests[request.key];
    existingRequest.params = request.params;
    await this.loadRequest(existingRequest, true);
  }

  async loadRequest(request, force) {
    if (request.type === 'once' && (force || request.data == null)) {
      request.promise = this.api.get(request.url, request.params).then((res) => {
        request.data = res.data;
        request.dataStatus = res.status === 200 ? 'FULLY_LOADED' : 'PARTIALLY_LOADED';
      });
    }

    if (request.type === 'poll' && (force || request.polling == null)) {
      if (request.polling != null) {
        request.polling.cancel();
      }
      const { call, polling } = this.api.poll(request.interval, request.url, (res) => {
        if (res) request.data = res.data;
        const dataStatus = res.status === 200 ? 'FULLY_LOADED' : 'PARTIALLY_LOADED';
        Object.keys(this.stores).forEach((m) => {
          this.$store.dispatch(`${this.stores[m].key}/onPoll`, {
            key: request.key, request, payload: this.stores[m].payload, dataStatus,
          });
        });
      });
      request.promise = call;
      request.polling = polling;
    }
  }

  static getInstance(id, store) {
    if (instance === null) {
      instance = new DynamicLoader(id, store);
    }
    return instance;
  }

  static clearInstance() {
    if (instance != null) instance.dispose();
    instance = null;
  }
}
