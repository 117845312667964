import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import {
  getSingleOutputInfo, getAssociatedDevices, saveOutputType, saveOutput, getEndstationSubType, hasFollowTime,
  createOutputsList, areaSpecificToOutputType, isAreaSpecific,
} from '@/app/outputs/shared/services/helpers';
import { getZoneSelectList } from '@/app/zones/shared/services/helpers';

function initialState() {
  return {
    associatedDevices: [],
    _outputTypes: [],
    areasInfo: [],
    zones: [],

    form: {
      baseOutputType: undefined,
      areaIndex: undefined,
      associatedWith: undefined,
      number: undefined,
      associatedWithDevice: undefined,
      associatedWithOutputIndex: undefined,

      transistored: undefined,

      followType: undefined,
      followWhat: undefined,
      followZone: undefined, // FOLLOW_WHAT === 'zone'
      followArea: undefined, // FOLLOW_WHAT === 'area'
      followTime: undefined,
      resetZone: undefined,
      followWhen: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {
    outputTypes: state => state._outputTypes,
  },
  actions: {
    requiredEndpoints() {
      return ['infoEndstation', 'infoOutputInfo', 'infoAreaInfo', 'infoBusDevices', 'infoKeypadInfo', 'infoZoneInfo',
        'configAreaInfoNames', 'configEndstation', 'configBusDevices', 'configKeypadInfo', 'configOutputInfo', 'configZoneInfoParts'];
    },
    async populate(context, { endpoints, payload }) {
      if (payload != null && payload.index != null) {
        const item = getSingleOutputInfo(endpoints, payload.index);
        context.commit('setForm', {
          baseOutputType: item.outputType,
          areaIndex: item.area,
          associatedWithDevice: item.associatedWith.key,
          transistored: item.transistored,
          followType: item.followType,
          followWhat: item.followWhat,
          followZone: item.followWhat === 'ZONE' ? item.followZone : null,
          followArea: item.followWhat === 'AREA' ? item.followZone : null, // note: overloaded field Zone/Area
          followTime: item.followTime,
          resetZone: item.resetZone,
          followWhen: item.followWhen,
        });
      }

      const zones = getZoneSelectList(endpoints.configZoneInfoParts, endpoints.infoZoneInfo);

      const udOutputs = endpoints.configOutputInfo.data.Config.outputInfo.UserDefinedOutputs;
      const outputTypes = createOutputsList(endpoints.infoOutputInfo.data.Info.outputInfo, udOutputs);

      const devices = getAssociatedDevices(null, endpoints);
      context.commit('set', {
        associatedDevices: devices,
        _outputTypes: outputTypes,
        areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
        areasConfig: endpoints.configAreaInfoNames.data.Config.areaInfo,
        zones,
      });
    },
    async add(context) {
      const associatedDevice = context.state.associatedDevices.find(v => v.key === context.state.form.associatedWithDevice);
      const { deviceIndex, type } = associatedDevice;
      const { outputIndex, deviceOutputIndex } = associatedDevice.availableOutputs
        .find(type === 'ENDSTATION'
          ? i => i.deviceOutputIndexDisplay === context.state.form.associatedWithOutputIndex
          : i => i.deviceOutputIndex === context.state.form.associatedWithOutputIndex);

      // Set new
      if (context.state.form.baseOutputType === 35) {
        await saveOutputType(api, type, deviceIndex, deviceOutputIndex, 3000 + outputIndex);
      } else if (isAreaSpecific(context.state._outputTypes, context.state.form.baseOutputType)) {
        const outputType = areaSpecificToOutputType(context.state.form.baseOutputType, context.state.form.areaIndex);
        await saveOutputType(api, type, deviceIndex, deviceOutputIndex, outputType);
      } else {
        await saveOutputType(api, type, deviceIndex, deviceOutputIndex, context.state.form.baseOutputType);
      }

      // Set other data
      const {
        followType, followWhat, followWhen, followZone, followArea, resetZone,
      } = context.state.form;
      await saveOutput(api, 'FollowState', type, deviceIndex, deviceOutputIndex, followType);
      await saveOutput(api, 'FollowWhat', type, deviceIndex, deviceOutputIndex, followWhat);
      await saveOutput(api, 'FollowWhen', type, deviceIndex, deviceOutputIndex, followWhen);
      let followed;
      if (followWhat === 'ZONE') {
        followed = followZone;
      } else if (followWhat === 'AREA') {
        followed = followArea;
      } else {
        followed = undefined;
      }
      await saveOutput(api, 'FollowZone', type, deviceIndex, deviceOutputIndex, followed);
      await saveOutput(api, 'ResetZone', type, deviceIndex, deviceOutputIndex, resetZone);

      if (hasFollowTime(context.state.form.followType, type)) {
        await saveOutput(api, 'Timer_100ms', type, deviceIndex, deviceOutputIndex, parseInt(context.state.form.followTime, 10));
      }

      if (type === 'ENDSTATION') {
        const { subType, subTypeIndex } = getEndstationSubType(outputIndex);
        if (subType === 'ElectronicOutputs') {
          const { transistored } = context.state.form;
          await api.put(`/Config/Endstation/${subType}/${subTypeIndex}/Mode`, transistored);
        }
      }

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('outputsState/outputsList/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('outputsState/addOutput/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['outputsState/outputsList', 'outputExpandersState/outputExpander'] });
    },
  },
});

export default store;
