<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('settings.generalResponses.navigationTitle') }}</h2>
      </a-row>
      <a-form>
        <a-tabs defaultActiveKey="1">
              <a-tab-pane :tab="$t('settings.generalResponses.Fire')" key="1">
                <p-select :label="$t('settings.generalResponses.Starts-at')"
                          :labelTooltip="$t('settings.generalResponses.Starts-at-tooltip')"
                          :model="$v.form.fire.startAt" display-style="small-alternative"
                          :items="Object.keys(RESPONSE_LEVEL)" :valueExpr="(i) => RESPONSE_LEVEL[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL.${i}`)"
                          :disabled="!configWriteLock.canWriteConfig" />
                <p-select :label="$t('settings.generalResponses.Stops-at')"
                          :labelTooltip="$t('settings.generalResponses.Stops-at-tooltip')"
                          :model="$v.form.fire.stopAt" display-style="small-alternative"
                          :items="Object.keys(RESPONSE_LEVEL)" :valueExpr="(i) => RESPONSE_LEVEL[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL.${i}`)"
                          :disabled="!configWriteLock.canWriteConfig" />
              </a-tab-pane>

              <a-tab-pane :tab="$t('settings.generalResponses.Gas')" key="2">
                <p-select :label="$t('settings.generalResponses.Starts-at')"
                          :labelTooltip="$t('settings.generalResponses.Starts-at-tooltip')"
                          :model="$v.form.gas.startAt" display-style="small-alternative"
                          :items="Object.keys(RESPONSE_LEVEL)" :valueExpr="(i) => RESPONSE_LEVEL[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL.${i}`)"
                          :disabled="!configWriteLock.canWriteConfig" />
                <p-select :label="$t('settings.generalResponses.Stops-at')"
                          :labelTooltip="$t('settings.generalResponses.Stops-at-tooltip')"
                          :model="$v.form.gas.stopAt" display-style="small-alternative"
                          :items="Object.keys(RESPONSE_LEVEL)" :valueExpr="(i) => RESPONSE_LEVEL[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL.${i}`)"
                          :disabled="!configWriteLock.canWriteConfig" />
              </a-tab-pane>

              <a-tab-pane :tab="$t('settings.generalResponses.24-hour-alarm')" key="3">
                <p-select :label="$t('settings.generalResponses.Starts-at')"
                          :labelTooltip="$t('settings.generalResponses.Starts-at-tooltip')"
                          :model="$v.form.twentyFourHourAlarm.startAt" display-style="small-alternative"
                          :items="Object.keys(RESPONSE_LEVEL)" :valueExpr="(i) => RESPONSE_LEVEL[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL.${i}`)"
                          :disabled="!configWriteLock.canWriteConfig" />
                <p-select :label="$t('settings.generalResponses.Stops-at')"
                          :labelTooltip="$t('settings.generalResponses.Stops-at-tooltip')"
                          :model="$v.form.twentyFourHourAlarm.stopAt" display-style="small-alternative"
                          :items="Object.keys(RESPONSE_LEVEL)" :valueExpr="(i) => RESPONSE_LEVEL[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL.${i}`)"
                          :disabled="!configWriteLock.canWriteConfig" />
              </a-tab-pane>

              <a-tab-pane :tab="$t('settings.generalResponses.Medical')" key="4">
                <p-select :label="$t('settings.generalResponses.Starts-at')"
                          :labelTooltip="$t('settings.generalResponses.Starts-at-tooltip')"
                          :model="$v.form.medical.startAt" display-style="small-alternative"
                          :items="Object.keys(RESPONSE_LEVEL)" :valueExpr="(i) => RESPONSE_LEVEL[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL.${i}`)"
                          :disabled="!configWriteLock.canWriteConfig" />
                <p-select :label="$t('settings.generalResponses.Stops-at')"
                          :labelTooltip="$t('settings.generalResponses.Stops-at-tooltip')"
                          :model="$v.form.medical.stopAt" display-style="small-alternative"
                          :items="Object.keys(RESPONSE_LEVEL)" :valueExpr="(i) => RESPONSE_LEVEL[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL.${i}`)"
                          :disabled="!configWriteLock.canWriteConfig" />
              </a-tab-pane>

              <a-tab-pane :tab="$t('settings.generalResponses.PA')" key="5">
                <p-select :label="$t('settings.generalResponses.Starts-at')"
                          :labelTooltip="$t('settings.generalResponses.Starts-at-tooltip')"
                          :model="$v.form.pa.startAt" display-style="small-alternative"
                          :items="Object.keys(RESPONSE_LEVEL)" :valueExpr="(i) => RESPONSE_LEVEL[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL.${i}`)"
                          :disabled="!configWriteLock.canWriteConfig" />
                <p-select :label="$t('settings.generalResponses.Stops-at')"
                          :labelTooltip="$t('settings.generalResponses.Stops-at-tooltip')"
                          :model="$v.form.pa.stopAt" display-style="small-alternative"
                          :items="Object.keys(RESPONSE_LEVEL_STOP_ALARM)" :valueExpr="(i) => RESPONSE_LEVEL_STOP_ALARM[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL_STOP_ALARM.${i}`)"
                          :disabled="!configWriteLock.canWriteConfig" />
              </a-tab-pane>
        </a-tabs>
      </a-form>
      <div class="align-right">
         <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
      </div>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';

import { RESPONSE_LEVEL, RESPONSE_LEVEL_STOP_ALARM } from '@/app/settings/shared/enums';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'settingsState/generalResponses';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      RESPONSE_LEVEL,
      RESPONSE_LEVEL_STOP_ALARM,
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
    };
  },
  computed: {
    ...mapState(['form']),
  },
  validations: {
    form: {
      fire: {
        startAt: { required },
        stopAt: { required },
      },
      gas: {
        startAt: { required },
        stopAt: { required },
      },
      twentyFourHourAlarm: {
        startAt: { required },
        stopAt: { required },
      },
      medical: {
        startAt: { required },
        stopAt: { required },
      },
      pa: {
        startAt: { required },
        stopAt: { required },
      },
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
};
</script>

<style lang="scss">

</style>
