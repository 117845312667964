import BaseLoader from '@/app/shared/services/base-loader';
import {
  liveCommsInfoConsts, liveOutputExpanderConsts, livePanelStatusOutputsConsts, liveWiredZoneExpanderConsts, liveWirelessZoneExpanderConsts, liveZoneInfoConsts, liveZonePageConsts,
} from '@/app/shared/constants';

let instance = null;
export class Loader extends BaseLoader {
  constructor(id, $store) {
    const endpoints = {
      // Alphabetical order
      configAppData: {
        key: 'configAppData', url: '/Config/AppData', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configAreaInfo: {
        key: 'configAreaInfo', url: '/Config/areaInfo', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configAreaInfoNames: {
        key: 'configAreaInfoNames', url: '/Config/areaInfo/Areas/*/Name', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configLevelInfoNames: {
        key: 'configLevelInfoNames', url: '/Config/areaInfo/Areas/*/Level/*/Name', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configBusDevices: {
        key: 'configBusDevices', url: '/Config/BusDevices', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configCommsInfo: {
        key: 'configCommsInfo', url: '/Config/commsInfo', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configEndstation: {
        key: 'configEndstation', url: '/Config/Endstation', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configKeypadInfo: {
        key: 'configKeypadInfo', url: '/Config/keypadInfo', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configModularOutput: {
        key: 'configModularOutput', url: '/Config/modularOutput', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configOutputInfo: {
        key: 'configOutputInfo', url: '/Config/outputInfo', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configRadioDevicesLearnt: {
        key: 'configRadioDevicesLearnt', url: '/Config/RadioDevicesLearnt', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configRadioFobInfo: {
        key: 'configRadioFobInfo', url: '/Config/radioFobInfo', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configResponses: {
        key: 'configResponses', url: '/Config/Responses', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configSysInfo: {
        key: 'configSysInfo', url: '/Config/sysInfo', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configUserInfo: {
        key: 'configUserInfo', url: '/Config/userInfo', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      // TODO Find some way of generating these ten endpoints programatically?
      configZoneInfoAreas00s: {
        key: 'configZoneInfoAreas00s', url: '/Config/zoneInfo/Zones/*/Areas/0,1,2,3,4,5,6,7,8,9', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configZoneInfoAreas10s: {
        key: 'configZoneInfoAreas10s', url: '/Config/zoneInfo/Zones/*/Areas/10,11,12,13,14,15,16,17,18,19', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configZoneInfoAreas20s: {
        key: 'configZoneInfoAreas20s', url: '/Config/zoneInfo/Zones/*/Areas/20,21,22,23,24,25,26,27,28,29', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configZoneInfoAreas30s: {
        key: 'configZoneInfoAreas30s', url: '/Config/zoneInfo/Zones/*/Areas/30,31,32,33,34,35,36,37,38,39', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configZoneInfoAreas40s: {
        key: 'configZoneInfoAreas40s', url: '/Config/zoneInfo/Zones/*/Areas/40,41,42,43,44,45,46,47,48,49', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configZoneInfoAreas50s: {
        key: 'configZoneInfoAreas50s', url: '/Config/zoneInfo/Zones/*/Areas/50,51,52,53,54,55,56,57,58,59', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configZoneInfoAreas60s: {
        key: 'configZoneInfoAreas60s', url: '/Config/zoneInfo/Zones/*/Areas/60,61,62,63,64,65,66,67,68,69', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configZoneInfoAreas70s: {
        key: 'configZoneInfoAreas70s', url: '/Config/zoneInfo/Zones/*/Areas/70,71,72,73,74,75,76,77,78,79', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configZoneInfoAreas80s: {
        key: 'configZoneInfoAreas80s', url: '/Config/zoneInfo/Zones/*/Areas/80,81,82,83,84,85,86,87,88,89', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configZoneInfoAreas90s: { // For 99 areas, the index range is 0-98.
        key: 'configZoneInfoAreas90s', url: '/Config/zoneInfo/Zones/*/Areas/90,91,92,93,94,95,96,97,98', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      // For all 280 zones, get the Location,Name and ZoneType. Takes about 200ms.
      configZoneInfoParts: {
        key: 'configZoneInfoParts', url: '/Config/zoneInfo/Zones/*/Location,Name,ZoneType', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      infoAreaInfo: {
        key: 'infoAreaInfo', url: '/Info/areaInfo', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      infoBusDevices: {
        key: 'infoBusDevices', url: '/Info/BusDevices', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      infoEndstation: {
        key: 'infoEndstation', url: '/Info/Endstation', data: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      infoFeatures: {
        key: 'infoFeatures', url: '/Info/Features', data: undefined, promise: null, type: 'once', updateOnConfigWrite: false, usedBy: [],
      },
      infoKeypadInfo: {
        key: 'infoKeypadInfo', url: '/Info/keypadInfo', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      infoOutputInfo: {
        key: 'infoOutputInfo', url: '/Info/outputInfo', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      infoUserInfo: {
        key: 'infoUserInfo', url: '/Info/userInfo', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      infoZoneInfo: {
        key: 'infoZoneInfo', url: '/Info/zoneInfo', data: undefined, dataStatus: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      liveActiveFaults: {
        key: 'liveActiveFaults', url: '/Live/ActiveFaults', data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: 2000, usedBy: [],
      },
      liveActiveFaultsKeypadsAndReaders: {
        key: 'liveActiveFaultsKeypadsAndReaders', url: '/Live/ActiveFaults/Devices/Keypads,Readers', data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: 2000, usedBy: [],
      },
      liveActiveFaultsOutputExpanders: {
        key: 'liveActiveFaultsOutputExpanders', url: '/Live/ActiveFaults/Devices/OutputExpanders', data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: 2000, usedBy: [],
      },
      liveActiveFaultsWiredHubsAndWirelessHubs: {
        key: 'liveActiveFaultsWiredHubsAndWirelessHubs', url: '/Live/ActiveFaults/Devices/wiredHubs,wirelessHubs', data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: 2000, usedBy: [],
      },
      liveActiveFaultsWirelessInputs: {
        key: 'liveActiveFaultsWirelessInputs', url: '/Live/ActiveFaults/WirelessInputs', data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: 2000, usedBy: [],
      },
      liveActiveFaultsWirelessKeypads: {
        key: 'liveActiveFaultsWirelessKeypads', url: '/Live/ActiveFaults/WirelessKeypads', data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: 2000, usedBy: [],
      },
      liveAreaInfo: {
        key: 'liveAreaInfo', url: '/Live/areaInfo', data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: 3000, usedBy: [],
      },
      // Only used by communications/arc-options page. Note polling rate slowed to 5000ms after page loaded.
      liveCommsInfo: {
        key: liveCommsInfoConsts.key, url: liveCommsInfoConsts.defaultUrl, data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: liveCommsInfoConsts.defaultInterval, usedBy: [],
      },
      liveKeypadInfo: {
        key: 'liveKeypadInfo', url: '/Live/keypadInfo', data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: 5000, usedBy: [],
      },
      liveOutputExpanders: {
        key: liveOutputExpanderConsts.key, url: liveOutputExpanderConsts.baseUrl, data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: liveOutputExpanderConsts.interval, usedBy: [],
      },
      livePanelStatus: {
        key: 'livePanelStatus', url: '/Live/PanelStatus', data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: 5000, usedBy: [],
      },
      livePanelStatusOutputs: {
        key: livePanelStatusOutputsConsts.key, url: livePanelStatusOutputsConsts.defaultUrl, data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: livePanelStatusOutputsConsts.defaultInterval, usedBy: [],
      },
      liveReaderInfo: {
        key: 'liveReaderInfo', url: '/Live/readerInfo', data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: 5000, usedBy: [],
      },
      liveWiredHubs: {
        key: liveWiredZoneExpanderConsts.key, url: liveWiredZoneExpanderConsts.defaultUrl, data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: liveWiredZoneExpanderConsts.defaultInterval, usedBy: [],
      },
      liveWirelessHubs: {
        key: liveWirelessZoneExpanderConsts.key, url: liveWirelessZoneExpanderConsts.baseUrl, data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: liveWirelessZoneExpanderConsts.defaultInterval, usedBy: [],
      },
      liveWirelessSiren: {
        key: 'liveWirelessSiren', url: '/Live/Devices/wirelessSiren', data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: 5000, usedBy: [],
      },
      liveZoneInfo: {
        key: liveZoneInfoConsts.key, url: liveZoneInfoConsts.baseUrl, data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: liveZoneInfoConsts.interval, usedBy: [],
      },
      liveZoneInfoDaysOnSoak: {
        key: 'liveZoneInfoDaysOnSoak', url: '/Live/zoneInfo/DaysOnSoak', data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: 5000, usedBy: [],
      },
      liveZonePage: {
        key: liveZonePageConsts.key, url: liveZonePageConsts.defaultUrl, data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: liveZonePageConsts.defaultInterval, usedBy: [],
      },
    };

    super('GL-'.concat(id), $store, endpoints); // GL: Generic Loader
  }

  static getInstance(id, store) {
    if (instance === null) {
      instance = new Loader(id, store);
    }
    return instance;
  }

  static clearInstance() {
    if (instance != null) instance.dispose();
    instance = null;
  }
}
