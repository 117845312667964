<template>
    <div class="styled-section">
        <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
            <div>
            <h3 v-if="endstation" class="h4">{{ $t('common.Endstation-Zones') }}</h3>
            <h3 v-else class="h4">{{ $t('common.Associated-Zones') }}</h3>
            <p class="subtitle"><span>{{ installed || items.length }}/{{ max }}</span> {{ $t('common.Zones') }}</p>
            <a-row :gutter="16">
                <template v-for="(item, index) in items">
                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 12}" :key="`zone-${index}`" v-if="item.name">
                  <div @click.stop="navigateToZone(item)">
                    <card>
                    <template slot:status>
                        <status :state="item.state === 'CC' && item.isWirelessFault !== true && item.zoneType !== 'ISOLATED'  ? 'active' : 'error'" />
                    </template>
                    <card-header>
                        <div>
                        <h3>{{ item.number }} - {{ item.name }}</h3>
                        </div>
                        <template slot="actions">
                          <div @click="$event.stopPropagation()">
                            <a-tooltip>
                              <template slot="title">
                                <span v-if="!item.omittable">{{ $t('common.Associated-Zones-Disabled-Tooltip') }}</span>
                                <span v-else-if="!item.managerOmitted && !item.setOmitted && !item.forceOmitted">{{ $t('common.Associated-Zones-Active-Tooltip') }}</span>
                                <span v-else>{{ $t('common.Associated-Zones-Inactive-Tooltip') }}</span>
                              </template>
                              <a-switch :disabled="!item.omittable" :checked="!item.managerOmitted && !item.setOmitted && !item.forceOmitted" @change="omittZone(index, item, !item.setOmitted)" />
                            </a-tooltip>
                          </div>
                        </template>
                    </card-header>
                    </card>
                  </div>
                </a-col>
                </template>
                <a-col class="gutter-row" v-if="items.length < 1">
                <p>{{ $t('common.No-associated-zones') }}</p>
                </a-col>
            </a-row>
            </div>
        </a-skeleton>
    </div>
</template>

<script>
import Card from '@/app/shared/components/Card/card.vue';
import CardHeader from '@/app/shared/components/Card/Child/cardHeader.vue';
import Status from '@/app/shared/components/Status/status.vue';

export default {
  components: {
    Card,
    CardHeader,
    Status,
  },
  props: {
    items: { default: [] },
    isLoading: { default: true },
    max: { default: 0 },
    installed: { default: null },
    endstation: { default: undefined },
  },
  methods: {
    async omittZone(index, item, checked) {
      this.$emit('omitt', index, item, checked);
    },
    navigateToZone(zone) {
      this.$gLanModeSetting.currentPageIsPanel = false; // No longer showing dashboard page.
      const index = (zone.index != null) ? zone.index : zone.zoneIndex;
      this.$router.push({ name: 'zone', params: { index } });
    },
  },
};
</script>

<style lang="scss">

</style>
