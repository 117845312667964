import _ from 'lodash';
import { EnumWrapper } from '@/app/shared/utils/enumWrapper';

export const USER_TYPE = new EnumWrapper([
  { key: 'USER', value: 0 },
  { key: 'MANAGER', value: 1 },
  { key: 'DURESS', value: 2 },
  { key: 'GUARD', value: 3 },
  { key: 'ENGINEER', value: 4 },
  { key: 'MASTERMAN', value: 5 },
]);

export const ACCESS_TYPE = new EnumWrapper([
  { key: 'KEY_FOB', value: 'KEY_FOB' },
  { key: 'USER_TAG', value: 'USER_TAG' },
  { key: 'USER_CODE', value: 'USER_CODE' },
]);

ACCESS_TYPE.fromCode = function fromCode(code) {
  if (_.isEqual(code, [64, 64, 64, 64, 64, 64])) return ACCESS_TYPE.NONE;
  if (_.isEqual(code.slice(2), [0, 0, 0, 0])) return ACCESS_TYPE.KEY_FOB;
  if (code[0] > 9) return ACCESS_TYPE.USER_TAG;
  return ACCESS_TYPE.USER_CODE;
};

export const SET_OPTIONS = new EnumWrapper([
  { key: 'DISARM_ARM', value: 0 },
  { key: 'DISARM_ONLY', value: 1 },
  { key: 'ARM_ONLY', value: 2 },
  { key: 'NONE', value: 3 },
]);


export const BUTTON_ACTION_TYPE = new EnumWrapper([
  { key: 'NO_ACTION', value: 'NO_ACTION' },
  { key: 'SHOW_STATUS', value: 'SHOW_STATUS' },
  { key: 'SET_AREAS', value: 'SET_AREAS' },
  { key: 'UNSET_AREAS', value: 'UNSET_AREAS' },
  { key: 'OPERATE_OUTPUT_LATCHED', value: 'OPERATE_OUTPUT_LATCHED' },
  { key: 'OPERATE_OUTPUT_TIMED', value: 'OPERATE_OUTPUT_TIMED' },
  { key: 'PANIC_ALARM', value: 'PANIC_ALARM' },
]);
