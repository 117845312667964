import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import i18n from '@/app/shared/services/i18n';
import {
  translateOutputType, isSirenOutputType, getOutputInfo, convertOutputType, udPulseTime, isUserDefined, udTypeName,
} from '@/app/outputs/shared/services/helpers';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import { toFaultString } from '@/app/shared/utils/activeFaults';
import { limits, liveOutputExpanderConsts } from '@/app/shared/constants';
import { getPowerStr } from '@/app/shared/utils/diagnostics-helper';

let powerStr = '';
let batteryPowerStr = '';

// Assume this peripheral takes its power from the RS485 bus, rather than its own PSU/battery.
let isPowered = false;

function initialState() {
  return {
    name: undefined,
    state: undefined,
    outputsMax: undefined,

    associatedOutputs: [],

    form: {
      name: undefined,
      type: undefined,
      address: undefined,
      location: undefined,
    },

    diagnostics: [
      {
        key: 'outputExpander.Diagnostics.Status',
        isLoading: true,
        value: undefined,
      },
      {
        key: 'outputExpander.Diagnostics.PSUStatus',
        isLoading: true,
        value: undefined,
      },
      {
        key: 'outputExpander.Diagnostics.BatteryStatus',
        isLoading: true,
        value: undefined,
      },
      {
        key: 'outputExpander.Diagnostics.DataBusStatus',
        isLoading: true,
        value: undefined,
      },
    ],
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {},
  actions: {
    requiredEndpoints() {
      // TODO Investigate replacing liveActiveFaults with liveActiveFaultsOutputExpanders
      return ['configBusDevices', 'infoOutputInfo', 'livePanelStatus', 'liveActiveFaults', liveOutputExpanderConsts.key, 'configOutputInfo'];
    },
    async populate(context, { endpoints, payload }) {
      context.commit('set', {
        outputsMax: limits.MAX_ROX_OUTPUTS,
        state: endpoints.liveOutputExpanders.data.Live.Devices.OutputExpander[payload.index].state,
      });

      context.commit('setForm', {
        type: 'TYPE_WIRED',
        name: payload.index,
        address: parseInt(payload.index, 10),
        location: endpoints.configBusDevices.data.Config.BusDevices.OutputDevices[payload.index].Location,
      });

      const udOutputs = endpoints.configOutputInfo.data.Config.outputInfo.UserDefinedOutputs;
      const associatedOutputs = [];
      for (let x = 0; x < limits.MAX_ROX_OUTPUTS; x += 1) {
        const outputType = endpoints.configBusDevices.data.Config.BusDevices.OutputDevices[payload.index].Outputs[x].OutputType;
        if (outputType > 0) {
          const type = convertOutputType(outputType);
          const opTypeName = endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes[type].Name;
          const outputName = isSirenOutputType(outputType)
            ? i18n.t('sirens.names.OUTPUT_EXPANDER', { associatedWithNumber: payload.index, number: x + 1 })
            : i18n.t('outputs.names.OUTPUT_EXPANDER', { associatedWithNumber: payload.index, number: x + 1 });
          associatedOutputs.push({
            associatedWith: getOutputInfo('OUTPUT_EXPANDER', payload.index, x, type),
            rawOutputType: outputType,
            outputType: type,
            outputTypeName: opTypeName,
            number: x + 1,
            name: outputName,
            translatedOutputType: (isUserDefined(outputType) ? udTypeName(udOutputs, outputType) : translateOutputType(opTypeName)),
            udPulseTime: udPulseTime(udOutputs, outputType),
            state: endpoints.livePanelStatus.data.Live.PanelStatus.OutputTypes[outputType].state,
          });
        }
      }
      context.commit('set', { associatedOutputs });
    },
    async onPoll(context, {
      dataStatus, key, endpoint, payload,
    }) {
      const batteryDiagnostics = context.state.diagnostics.find(d => d.key === 'outputExpander.Diagnostics.BatteryStatus');
      const batteryDiagnosticsIndex = context.state.diagnostics.indexOf(batteryDiagnostics);

      if (key === 'liveActiveFaults') {
        const batteryFault = endpoint.data.Live.ActiveFaults.Devices.OutputExpanders[payload.index].Battery;
        const busCommsFault = endpoint.data.Live.ActiveFaults.Devices.OutputExpanders[payload.index].BusComms;
        const caseTamperFault = endpoint.data.Live.ActiveFaults.Devices.OutputExpanders[payload.index].CaseTamper;
        const mainsFault = endpoint.data.Live.ActiveFaults.Devices.OutputExpanders[payload.index].Mains;
        const powerSupplyFault = endpoint.data.Live.ActiveFaults.Devices.OutputExpanders[payload.index].PowerSupply;

        context.commit('trySet', { dataStatus, data: { state: toFaultString(batteryFault, busCommsFault, caseTamperFault, mainsFault, powerSupplyFault) } });
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'outputExpander.Diagnostics.Status',
          item: {
            value: caseTamperFault === true ? 'TAMPER' : toFaultString(batteryFault, busCommsFault, mainsFault, powerSupplyFault),
            tooltip() {
              const value = this.value || 'FAULT';
              return i18n.t(`${this.key}.tooltips.${value}`);
            },
          },
        });
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'outputExpander.Diagnostics.DataBusStatus',
          item: {
            value: toFaultString(busCommsFault),
            tooltip() {
              const value = this.value || 'FAULT';
              return i18n.t(`${this.key}.tooltips.${value}`);
            },
          },
        });

        // PSU/bus power diagnostics:
        // 'PSU' has voltage and current for powered peripheral, 'Bus power' has voltage only, for non-powered peripheral.
        //
        // Assume a non-powered peripheral.
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'outputExpander.Diagnostics.PSUStatus',
          item: {
            title: i18n.t(isPowered ? 'outputExpander.Diagnostics.PSUStatus.titlePowered' : 'outputExpander.Diagnostics.PSUStatus.title'),
            value: toFaultString(powerSupplyFault, mainsFault),
            tooltip() {
              if (this.value === 'OK') {
                return powerStr;
              }
              if (this.value === 'FAULT') {
                return i18n.t(`${this.key}.tooltips.${this.value}`);
              }
              return i18n.t(`${this.key}.tooltips.MISSING`);
            },
          },
        });

        const hasBatteryFaultValue = endpoint.data.Live.ActiveFaults.Devices.OutputExpanders[payload.index].Battery != null;
        if (hasBatteryFaultValue) {
          const hasBatteryValues = batteryDiagnostics.value != null;
          context.commit('setItemByIndex', {
            index: batteryDiagnosticsIndex, collection: 'diagnostics', item: { isLoading: !hasBatteryValues || !hasBatteryFaultValue, activeFault: toFaultString(batteryFault) },
          });
        }
      }

      if (key === liveOutputExpanderConsts.key) {
        let commitValue = 'OK'; // Assume the peripheral has a good battery.
        // Update the cached tooltip string.
        const mV = endpoint.data.Live.Devices.OutputExpander[payload.index].Voltage_mV;
        const psuMa = endpoint.data.Live.Devices.OutputExpander[payload.index].PsuCurrent_mA;
        const batteryMa = endpoint.data.Live.Devices.OutputExpander[payload.index].BatteryCurrent_mA;
        isPowered = endpoint.data.Live.Devices.OutputExpander[payload.index].IsPowered;
        const isBatteryDischarging = endpoint.data.Live.Devices.OutputExpander[payload.index].IsBatteryDischarging;

        if (isPowered) {
          // This is a powered peripheral.
          if ((mV != null) && (psuMa != null) && (batteryMa != null)) {
            const volts = mV / 1000;
            const psuAmps = psuMa / 1000;
            const batteryAmps = batteryMa / 1000;
            powerStr = getPowerStr(volts, psuAmps, isBatteryDischarging);
            batteryPowerStr = getPowerStr(volts, batteryAmps, isBatteryDischarging);
          }
          // Check the battery status.
          const hasActiveFault = batteryDiagnostics.activeFault != null && batteryDiagnostics.activeFault !== 'OK';
          if (hasActiveFault) {
            commitValue = 'FAULT'; // There's a problem with the battery.
          }
        } else {
          // This is a non-powered peripheral. It has no battery.
          // Set the battery commit value to stop the entire battery diag element from being shown.
          commitValue = 'HIDDEN';
          // Set the power string to just voltage: a non-powered peripheral does not report current.
          if (mV != null) {
            const volts = mV / 1000;
            powerStr = `${volts}V`;
          }
        }

        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'outputExpander.Diagnostics.BatteryStatus',
          item: {
            value: commitValue,
            isLoading: false,
            tooltip() {
              if (this.value === 'OK') {
                return batteryPowerStr;
              }
              return i18n.t('OutputExpander.tooltips.battery.MISSING');
            },
          },
        });
      }

      if (key === 'livePanelStatus') {
        for (let i = 0; i < context.state.associatedOutputs.length; i += 1) {
          const { rawOutputType } = context.state.associatedOutputs[i];
          const outputStatus = endpoint.data.Live.PanelStatus.OutputTypes[rawOutputType].state;
          context.commit('setItemByIndex', { index: i, collection: 'associatedOutputs', item: { state: outputStatus } });
        }
      }
    },
    async save(context) {
      const index = context.state.form.address;
      const response = await api.get(`/Config/BusDevices/OutputDevices/${index}`);
      const data = response.data.Config.BusDevices.OutputDevices[index];
      data.Location = context.state.form.location;
      await api.put(`/Config/BusDevices/OutputDevices/${index}`, data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('outputExpandersState/outputExpander/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['outputExpandersState/outputExpander'] });
    },
    async delete(context) {
      const index = context.state.form.address;
      // eslint-disable-next-line no-await-in-loop
      const objDefaults = await generateDefaultObject(`/Config/BusDevices/OutputDevices/${index}`);
      // eslint-disable-next-line no-await-in-loop
      await api.put(`/Config/BusDevices/OutputDevices/${index}`, objDefaults);

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('outputExpandersState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('outputExpandersState/addOutputExpander/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['outputExpandersState/list'] });
    },
  },
});

export default store;
