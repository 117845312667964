import History from './history.vue';
import PanelLogs from './pages/panel/panel-logs.vue';
import VoiceLogs from './pages/voice/voice-logs.vue';
import AccessLogs from './pages/access/access-logs.vue';
import CloudLogs from './pages/cloud/cloud-logs.vue';

const routes = [
  {
    path: '/history',
    component: History,
    children: [
      { path: '', redirect: { name: 'panel-logs' } },
      {
        path: 'panel',
        name: 'panel-logs',
        component: PanelLogs,
        meta: { breadcrumb: [{ nameRes: 'history.navigationTitle' }, { nameRes: 'history.panelLogs.navigationTitle' }] },
      },
      {
        path: 'access',
        name: 'access-logs',
        component: AccessLogs,
        meta: { breadcrumb: [{ nameRes: 'history.navigationTitle', link: 'panel-logs' }, { nameRes: 'history.accessLogs.navigationTitle' }] },
      },
      {
        path: 'voice',
        name: 'voice-logs',
        component: VoiceLogs,
        meta: { breadcrumb: [{ nameRes: 'history.navigationTitle', link: 'panel-logs' }, { nameRes: 'history.voiceLogs.navigationTitle' }] },
      },
      {
        path: 'cloud',
        name: 'cloud-logs',
        component: CloudLogs,
        meta: { breadcrumb: [{ nameRes: 'history.navigationTitle', link: 'panel-logs' }, { nameRes: 'history.cloudLogs.navigationTitle' }] },
      },
    ],
  },
];

export default routes;
