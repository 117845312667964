<template>
    <div v-if="active">
      <div v-if="mode === 'SIMPLE'">
        <slot :wizard="wizard"></slot>
      </div>
      <div v-else>
        <div class="draw-header">
          <div class="ant-drawer-title">
            <slot name="title" :wizard="wizard">
              {{ (lodash.isFunction(currentAction.title) ? currentAction.title() : currentAction.title) || (lodash.isFunction(wizard.config.title) ? wizard.config.title() : wizard.config.title) }}
            </slot>
          </div>
        </div>

        <slot :wizard="wizard"></slot>

        <a-progress v-if="currentAction.progress" :percent="lodash.isFunction(currentAction.progress) ? currentAction.progress() : currentAction.progress" :showInfo="false" class="draw-progress" />

        <div class="draw-body">
          <slot :wizard="wizard" name="body"></slot>
        </div>

        <div class="draw-footer" v-if="hasFooter">
          <slot name="footer" :wizard="wizard">
            <a-button v-if="!hideCancel" size="large" type="link" @click="done" style="float: left;">{{ $t('common.Cancel') }}</a-button>
            <a-button v-for="action in visibleActions" :key="action.key" size="large" type="primary" @click="performAction(action)">{{ lodash.isFunction(action.buttonText) ? action.buttonText() : action.buttonText }}</a-button>
          </slot>
        </div>
      </div>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
  components: {
  },
  inject: ['addStep'],
  props: {
    name: { required: true },
  },
  data() {
    return {
      lodash: _,
      active: false,
      wizard: null,
    };
  },
  computed: {
    currentAction() {
      return this.wizard.config[this.name];
    },
    mode() {
      return this.wizard.config[this.name].mode || undefined;
    },
    visibleActions() {
      if (this.wizard.config[this.name].next != null) {
        const actions = this.wizard.config[this.name].next.filter(n => n.key !== 'save' && (n.if ? n.if() : true));
        if (actions.length > 0) return actions;
        return this.wizard.config[this.name].next.filter(n => n.key === 'save');
      }
      return [];
    },
    hideCancel() {
      return this.wizard.config[this.name].hideCancel || false;
    },
    hasFooter() {
      return this.visibleActions.length > 0 || !this.hideCancel;
    },
  },
  methods: {
    async performAction(action) {
      if (this.wizard.state) {
        const validationGroup = this.wizard.config[this.name].onExitValidate;
        const form = validationGroup ? this.wizard.state[validationGroup] : this.wizard.state;
        form.$touch();
        if (form.$invalid) return;
      }

      if (action.key === 'save') {
        this.wizard.saveWizard();
      } else {
        if (action.preNavigate) await action.preNavigate(this.wizard);
        this.wizard.navigateTo(action.key);
        if (action.postNavigate) await action.postNavigate(this.wizard);
      }
    },
    done() {
      this.wizard.done();
    },
  },
  mounted() {
    this.wizard = this.addStep(this);
  },
};
</script>
