import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import displayFaultsHelper from '@/app/shared/services/display-faults-helper';

const DisplayFaultsGuard = {
  components: {
    DisplayFaultsModal,
  },
  data() {
    return {
      faultsGuard: {
        to: undefined,
        modalVisible: false,
        anyFaults: undefined,
      },
      errors: [],
      arcError: 'OK',
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'panel' && this.faultsGuard.anyFaults === undefined) {
      displayFaultsHelper.startExitChecks((anyFaults, errors, arcError) => {
        const canLeave = this.faultsGuard.modalVisible || anyFaults === false;
        if (!canLeave) {
          this.faultsGuard.to = to;
          this.faultsGuard.modalVisible = true;
          this.faultsGuard.anyFaults = anyFaults;
          this.errors = errors;
          this.arcError = arcError;
          next(false);
        } else {
          next();
        }
      });
    } else {
      next();
    }
  },
};

export default DisplayFaultsGuard;
