import { API } from '@/app/shared/services/api';

const api = new API();

async function startExitChecks(callback) {
  // Tell the panel to perform the exit checks and return the results
  // TODO: ping commitConfig.

  const errors = [];
  let arcError = 'OK';
  const areasOk = (await api.get('/Live/ClientStatus/ValidateConfiguration/AreasOk')).data.Live.ClientStatus.ValidateConfiguration.AreasOk;
  const keypadsOk = (await api.get('/Live/ClientStatus/ValidateConfiguration/KeypadsOk')).data.Live.ClientStatus.ValidateConfiguration.KeypadsOk;
  const radioZonesMismatchOk = (await api.get('/Live/ClientStatus/ValidateConfiguration/RadioZonesMismatchOk')).data.Live.ClientStatus.ValidateConfiguration.RadioZonesMismatchOk;
  const radioZonesTamperOk = (await api.get('/Live/ClientStatus/ValidateConfiguration/RadioZonesTamperOk')).data.Live.ClientStatus.ValidateConfiguration.RadioZonesTamperOk;
  const wiredZonesTamperOk = (await api.get('/Live/ClientStatus/ValidateConfiguration/WiredZonesTamperOk')).data.Live.ClientStatus.ValidateConfiguration.WiredZonesTamperOk;
  const endstationZonesTamperOk = (await api.get('/Live/ClientStatus/ValidateConfiguration/EndstationZonesTamperOk')).data.Live.ClientStatus.ValidateConfiguration.EndstationZonesTamperOk;
  const arcDetailsUDLOk = (await api.get('/Live/ClientStatus/ValidateConfiguration/ARCDetailsUDLOk')).data.Live.ClientStatus.ValidateConfiguration.ARCDetailsUDLOk;
  const arcCheckDetails = (await api.get('/Live/ClientStatus/ValidateConfiguration/ARCCheckDetails')).data.Live.ClientStatus.ValidateConfiguration.ARCCheckDetails;

  let anyFaults = false;
  if (!areasOk) {
    anyFaults = true;
    errors.push('AreasIssue');
  }
  if (!keypadsOk) {
    anyFaults = true;
    errors.push('KeypadsNotOk');
  }
  if (!radioZonesMismatchOk) {
    anyFaults = true;
    errors.push('RadioZonesMismatch');
  }
  if (!radioZonesTamperOk) {
    anyFaults = true;
    errors.push('ZonesTamper');
  }
  if (!wiredZonesTamperOk) {
    anyFaults = true;
    errors.push('ZonesTamper');
  }
  if (!endstationZonesTamperOk) {
    anyFaults = true;
    errors.push('ZonesTamper');
  }
  if (!arcDetailsUDLOk) {
    anyFaults = true;
    errors.push('ARCDetailsUDL');
  }
  if (arcCheckDetails !== 'OK') {
    anyFaults = true;
    errors.push('ARCCheckDetails');
    arcError = arcCheckDetails;
  }

  callback(anyFaults, errors, arcError);
}

export default {
  startExitChecks,
};
