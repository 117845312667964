/* eslint-disable class-methods-use-this */
import { API } from '@/app/shared/services/api';
import { liveOutputExpanderConsts } from '@/app/shared/constants';

const api = new API();

// A count of the pages using modified polling.
let customPollPageCount = 0;

class OutputExpanderHelper {
  // Private incrementor
  _incCustomPollPageCount() {
    customPollPageCount += 1;
  }

  // Private decrementor
  _decCustomPollPageCount() {
    // After the count has been reset, it is legal to attempt to decrement the count
    // (but the value should never go negative)
    if (customPollPageCount > 0) {
      customPollPageCount -= 1;
    }
    return customPollPageCount;
  }

  // Currently unused.
  resetCustomPollPageCount() {
    customPollPageCount = 0;
  }

  cancelPolling() {
    api.cancelPolling();
  }

  // Start polling the live endpoint with a specific URL.
  customiseExpanderPollingWithUrl(key, loader, zoneUrl) {
    if (key === liveOutputExpanderConsts.key) {
      loader.setEndpointIntervalAndUrl(liveOutputExpanderConsts.key, liveOutputExpanderConsts.interval, zoneUrl);
    } else {
      return;
    }
    this._incCustomPollPageCount();
  }

  // Revert to default polling of live endpoint, if there are no other pages using custom polling.
  revertExpanderCustomPollingIfPossible() {
    const pageCount = this._decCustomPollPageCount();
    if (pageCount === 0) {
      // No other pages using customised polling, so cancel polling.
      this.cancelPolling();
    }
  }
}

const helper = new OutputExpanderHelper();

export default helper;
export { api };
