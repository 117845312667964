import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import i18n from '@/app/shared/services/i18n';
import {
  translateOutputType, isSirenOutputType, getOutputInfo, convertOutputType, udPulseTime, isUserDefined, udTypeName,
} from '@/app/outputs/shared/services/helpers';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import { toFaultString } from '@/app/shared/utils/activeFaults';
import { liveZoneInfoConsts, liveWiredZoneExpanderConsts } from '@/app/shared/constants';
import { getPowerStr } from '@/app/shared/utils/diagnostics-helper';

// Flag true if the cached associatedZones are valid.
let associatedZonesPopulated = false;

let powerStr = '';
let batteryPowerStr = '';

// Assume this peripheral takes its power from the RS485 bus, rather than its own PSU/battery.
let isPowered = false;

function initialState() {
  return {
    name: undefined,
    state: undefined,
    zonesMax: undefined,
    outputsMax: undefined,

    associatedZones: [],
    associatedOutputs: [],

    form: {
      name: undefined,
      type: undefined,
      address: undefined,
      location: undefined,
    },

    diagnostics: [
      {
        key: 'wiredZoneExpander.Diagnostics.Status',
        isLoading: true,
        value: undefined,
      },
      {
        key: 'wiredZoneExpander.Diagnostics.PSUStatus',
        isLoading: true,
        value: undefined,
      },
      {
        key: 'wiredZoneExpander.Diagnostics.BatteryStatus',
        isLoading: true,
        value: undefined,
      },
      {
        key: 'wiredZoneExpander.Diagnostics.DataBusStatus',
        isLoading: true,
        value: undefined,
      },
    ],
  };
}

const { api, store } = storeFactory(initialState, {
  getters: {},
  actions: {
    requiredEndpoints() {
      // Each time the page is displayed, reset the associatedZones cache.
      // TODO Find a better location to reset the cache; requiredEndpoints() just *happens* to be called when the page is displayed.
      associatedZonesPopulated = false;
      return ['infoBusDevices', liveWiredZoneExpanderConsts.key, 'configBusDevices', 'configZoneInfoParts', 'liveZoneInfo', 'infoOutputInfo', 'configOutputInfo', 'livePanelStatus', 'liveActiveFaults'];
    },
    async populate(context, { endpoints, payload }) {
      context.commit('set', {
        name: endpoints.infoBusDevices.data.Info.BusDevices.wiredHubs[payload.index].Name,
        zonesMax: endpoints.infoBusDevices.data.Info.BusDevices.wiredHubs[payload.index].AssociatedZones.length,
        outputsMax: endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[payload.index].Outputs.length,
        state: endpoints.liveWiredHubs.data.Live.BusDevices.wiredHubs[payload.index].state,
      });

      context.commit('setForm', {
        type: 'TYPE_WIRED',
        name: endpoints.infoBusDevices.data.Info.BusDevices.wiredHubs[payload.index].Name,
        hubIndex: payload.index,
        address: payload.index,
        location: endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[payload.index].Location,
      });

      const associatedZones = [];
      for (let x = 0; x < endpoints.infoBusDevices.data.Info.BusDevices.wiredHubs[payload.index].AssociatedZones.length; x += 1) {
        const number = endpoints.infoBusDevices.data.Info.BusDevices.wiredHubs[payload.index].AssociatedZones[x].Number;
        const zoneType = endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[number - 1].ZoneType;
        if (zoneType !== 'ISOLATED') {
          associatedZones.push({
            number,
            zoneIndex: number - 1,
            name: endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[number - 1].Name,
            omittable: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[number - 1].Omittable,
            managerOmitted: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[number - 1].ManagerOmitted,
            setOmitted: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[number - 1].SetOmitted,
            forceOmitted: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[number - 1].ForceOmitted,
            zoneType,
            state: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[number - 1].state,
          });
        }
      }
      context.commit('set', { associatedZones });
      // The list of associated zones is now populated.
      associatedZonesPopulated = true;

      const udOutputs = endpoints.configOutputInfo.data.Config.outputInfo.UserDefinedOutputs;
      const associatedOutputs = [];
      for (let x = 0; x < endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[payload.index].Outputs.length; x += 1) {
        const rawOutputType = endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[payload.index].Outputs[x].OutputType;
        const outputType = convertOutputType(rawOutputType);
        const opTypeName = endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes[outputType].Name;
        const outputName = isSirenOutputType(outputType)
          ? i18n.t('sirens.names.WIRED_ZONE_EXPANDER', { associatedWithNumber: payload.index, number: x + 1 })
          : i18n.t('outputs.names.WIRED_ZONE_EXPANDER', { associatedWithNumber: payload.index, number: x + 1 });
        if (outputType > 0) {
          associatedOutputs.push({
            rawOutputType,
            outputType,
            associatedWith: getOutputInfo('WIRED_ZONE_EXPANDER', payload.index, x, outputType),
            outputTypeName: opTypeName,
            number: x + 1,
            name: outputName,
            translatedOutputType: (isUserDefined(rawOutputType) ? udTypeName(udOutputs, rawOutputType) : translateOutputType(opTypeName)),
            udPulseTime: udPulseTime(udOutputs, outputType),
            state: endpoints.livePanelStatus.data.Live.PanelStatus.OutputTypes[rawOutputType].state,
          });
        }
      }
      context.commit('set', { associatedOutputs });
    },
    async onPoll(context, {
      dataStatus, key, endpoint, payload,
    }) {
      const batteryDiagnostics = context.state.diagnostics.find(d => d.key === 'wiredZoneExpander.Diagnostics.BatteryStatus');


      if (key === 'liveActiveFaults') {
        const batteryFault = endpoint.data.Live.ActiveFaults.Devices.wiredHubs[payload.index].Battery;
        const busCommsFault = endpoint.data.Live.ActiveFaults.Devices.wiredHubs[payload.index].BusComms;
        const caseTamperFault = endpoint.data.Live.ActiveFaults.Devices.wiredHubs[payload.index].CaseTamper;
        const mainsFault = endpoint.data.Live.ActiveFaults.Devices.wiredHubs[payload.index].Mains;
        const powerSupplyFault = endpoint.data.Live.ActiveFaults.Devices.wiredHubs[payload.index].PowerSupply;

        context.commit('trySet', { dataStatus, data: { state: toFaultString(batteryFault, busCommsFault, caseTamperFault, mainsFault, powerSupplyFault) } });
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wiredZoneExpander.Diagnostics.Status',
          item: {
            value: caseTamperFault === true ? 'TAMPER' : toFaultString(batteryFault, busCommsFault, mainsFault, powerSupplyFault),
            tooltip() {
              const value = this.value || 'FAULT';
              return i18n.t(`zoneExpanders.tooltips.state.${value}`);
            },
          },
        });
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wiredZoneExpander.Diagnostics.DataBusStatus',
          item: {
            value: toFaultString(busCommsFault),
            tooltip() {
              const value = this.value || 'FAULT';
              return i18n.t(`zoneExpanders.tooltips.dataBus.${value}`);
            },
          },
        });

        // PSU/bus power diagnostics:
        // 'PSU' has voltage and current for powered peripheral, 'Bus power' has voltage only, for non-powered peripheral.
        //
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wiredZoneExpander.Diagnostics.PSUStatus',
          item: {
            title: i18n.t(isPowered ? 'wiredZoneExpander.Diagnostics.PSUStatus.titlePowered' : 'wiredZoneExpander.Diagnostics.PSUStatus.title'),
            value: toFaultString(powerSupplyFault, mainsFault),
            tooltip() {
              if (this.value === 'OK') {
                return powerStr;
              }
              if (this.value === 'FAULT') {
                return i18n.t(`zoneExpanders.tooltips.psu.${this.value}`);
              }
              return i18n.t('zoneExpanders.tooltips.psu.MISSING');
            },
          },
        });

        const hasBatteryFaultValue = endpoint.data.Live.ActiveFaults.Devices.wiredHubs[payload.index].Battery != null;
        if (hasBatteryFaultValue) {
          const hasBatteryValues = batteryDiagnostics.value != null;
          context.commit('setItemByIndex', {
            index: 2, collection: 'diagnostics', item: { isLoading: !hasBatteryValues || !hasBatteryFaultValue, activeFault: toFaultString(batteryFault) },
          });
        }
      }

      // Is the key liveWiredHubs?
      if (key === liveWiredZoneExpanderConsts.key) {
        let commitValue = 'OK'; // Assume the peripheral has a good battery.
        // Update the cached tooltip string.
        const mV = endpoint.data.Live.BusDevices.wiredHubs[payload.index].Voltage_mV;
        const psuMa = endpoint.data.Live.BusDevices.wiredHubs[payload.index].PsuCurrent_mA;
        const batteryMa = endpoint.data.Live.BusDevices.wiredHubs[payload.index].BatteryCurrent_mA;
        isPowered = endpoint.data.Live.BusDevices.wiredHubs[payload.index].IsPowered;
        const isBatteryDischarging = endpoint.data.Live.BusDevices.wiredHubs[payload.index].IsBatteryDischarging;

        if (isPowered) {
          // This is a powered peripheral.
          if ((mV != null) && (psuMa != null) && (batteryMa != null)) {
            const volts = mV / 1000;
            const psuAmps = psuMa / 1000;
            const batteryAmps = batteryMa / 1000;
            powerStr = getPowerStr(volts, psuAmps, isBatteryDischarging);
            batteryPowerStr = getPowerStr(volts, batteryAmps, isBatteryDischarging);
          }
          // Check the battery status.
          const hasActiveFault = batteryDiagnostics.activeFault != null && batteryDiagnostics.activeFault !== 'OK';
          if (hasActiveFault) {
            commitValue = 'FAULT'; // There's a problem with the battery.
          }
        } else {
          // This is a non-powered peripheral. It has no battery.
          // Set the battery commit value to stop the entire battery diag element from being shown.
          commitValue = 'HIDDEN';
          // Set the power string to just voltage: a non-powered peripheral does not report current.
          if (mV != null) {
            const volts = mV / 1000;
            powerStr = `${volts}V`;
          }
        }

        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wiredZoneExpander.Diagnostics.BatteryStatus',
          item: {
            value: commitValue,
            isLoading: false,
            tooltip() {
              if (this.value === 'OK') {
                return batteryPowerStr;
              }
              return i18n.t('zoneExpanders.tooltips.battery.MISSING');
            },
          },
        });
      }

      // Only use the cached associatedZones list if the cache is valid.
      if (key === liveZoneInfoConsts.key && associatedZonesPopulated === true) {
        const associatedZones = [];
        for (let x = 0; x < context.state.associatedZones.length; x += 1) {
          const { zoneIndex } = context.state.associatedZones[x];
          const omittable = endpoint.data.Live.zoneInfo.Zones[zoneIndex].Omittable;
          const managerOmitted = endpoint.data.Live.zoneInfo.Zones[zoneIndex].ManagerOmitted;
          const setOmitted = endpoint.data.Live.zoneInfo.Zones[zoneIndex].SetOmitted;
          const forceOmitted = endpoint.data.Live.zoneInfo.Zones[zoneIndex].ForceOmitted;
          const { state } = endpoint.data.Live.zoneInfo.Zones[zoneIndex];

          associatedZones.push({
            omittable, forceOmitted, setOmitted, managerOmitted, state,
          });
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: 'associatedZones', items: associatedZones });
      }

      if (key === 'livePanelStatus') {
        const associatedOutputs = [];
        for (let x = 0; x < context.state.associatedOutputs.length; x += 1) {
          const { rawOutputType } = context.state.associatedOutputs[x];
          const { state } = endpoint.data.Live.PanelStatus.OutputTypes[rawOutputType];
          associatedOutputs.push({ state });
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: 'associatedOutputs', items: associatedOutputs });
      }
    },
    async save(context) {
      const response = await api.get(`/Config/BusDevices/wiredHubs/${context.state.form.hubIndex}`);
      const data = response.data.Config.BusDevices.wiredHubs[context.state.form.hubIndex];
      data.Location = context.state.form.location;
      await api.put(`/Config/BusDevices/wiredHubs/${context.state.form.hubIndex}`, data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('zoneExpandersState/wiredZoneExpander/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['zoneExpandersState/wiredZoneExpander'] });
    },
    async deleteItem(context) {
      const objDefaults = await generateDefaultObject(`/Config/BusDevices/wiredHubs/${context.state.form.hubIndex}`);
      await api.put(`/Config/BusDevices/wiredHubs/${context.state.form.hubIndex}`, objDefaults);

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('zoneExpandersState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('zoneExpandersState/addZoneExpander/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['zoneExpandersState/list'] });
    },
  },
});

export default store;
