<template>
  <div>
    <a-drawer
    placement="right"
    :closable="false"
    :destroyOnClose="true"
    @close="closeDrawer"
    :visible="visible">

        <template v-if="stage == 'Start'">
            <template slot="title">
                {{ $t('addDevicesDrawer.Title') }}
            </template>
            <a-button class="draw-link" @click="goto('Autoscan')" type="link" block>
                <text-row :title="$t('addDevicesDrawer.Autoscan-for-all-devices-wired-only')" title-icon="search" />
            </a-button>
            <a-button class="draw-link" @click="goto('Wireless')" type="link" block>
                <text-row :title="$t('addDevicesDrawer.Learn-wireless-devices')" title-icon="search" />
            </a-button>
            <a-button class="draw-link" @click="goto('Manually')" type="link" block>
                <text-row :title="$t('common.Add-manually')" title-icon="plus-circle" />
            </a-button>
        </template>

        <autoscan v-else-if="stage == 'Autoscan'" @done="stage = 'Start'; closeDrawer();" @restart="stage = 'Start'" />

        <learn-wireless v-else-if="stage == 'Wireless'" @done="stage = 'Start'; closeDrawer();" />

        <template v-else-if="stage == 'Manually'">
            <template slot="title">
                {{ $t('addDevicesDrawer.Which-device-do-you-want-to-add') }}
            </template>
           <a-button @click="openManualDrawer('add-keypad-or-reader')" type="link" block>
                <text-row :title="$t('addDevicesDrawer.manual.Keypads-and-readers')" title-icon="scanner-keyboard" />
            </a-button>
            <a-button @click="openManualDrawer('add-zone-expander')" type="link" block>
                <text-row :title="$t('addDevicesDrawer.manual.Zone-expander')" title-icon="scanner-keyboard" />
            </a-button>
            <a-button @click="openManualDrawer('add-output-expander')" type="link" block>
                <text-row :title="$t('addDevicesDrawer.manual.Output-expander')" title-icon="scanner-keyboard" />
            </a-button>
            <a-button @click="openManualDrawer('add-zone')" type="link" block>
                <text-row :title="$t('addDevicesDrawer.manual.Zone')" title-icon="street-view" />
            </a-button>
            <a-button @click="openManualDrawer('add-output')" type="link" block>
                <text-row :title="$t('addDevicesDrawer.manual.Output')" title-icon="bell" />
            </a-button>
        </template>

    </a-drawer>

    <add-keypad-or-reader v-if="manualWizard === 'add-keypad-or-reader'" :visible="true" @onClose="closeDrawer" />
    <add-zone-expander v-if="manualWizard === 'add-zone-expander'" :visible="true" @onClose="closeDrawer" />
    <add-output-expander v-if="manualWizard === 'add-output-expander'" :visible="true" @onClose="closeDrawer" />
    <add-zone v-if="manualWizard === 'add-zone'" :visible="true" @onClose="closeDrawer"  />
    <add-output v-if="manualWizard === 'add-output'" :visible="true" @onClose="closeDrawer" />
  </div>
</template>
<script>
import Autoscan from './autoscan.vue'; // eslint-disable-line import/order
import LearnWireless from './learn-wireless.vue'; // eslint-disable-line import/order

import AddKeypadOrReader from '@/app/keypads-and-readers/shared/components/add-keypad-or-reader/add-keypad-or-reader.vue';
import AddZoneExpander from '@/app/zone-expanders/shared/components/add-zone-expander/add-zone-expander.vue';
import AddOutputExpander from '@/app/output-expanders/shared/components/add-output-expander/add-output-expander.vue';
import AddZone from '@/app/zones/shared/components/add-zone/add-zone.vue';
import AddOutput from '@/app/outputs/shared/components/add-output/add-output.vue';

export default {
  components: {
    Autoscan,
    LearnWireless,
    AddKeypadOrReader,
    AddZoneExpander,
    AddOutputExpander,
    AddZone,
    AddOutput,
  },
  props: {
    visible: { default: false },
  },
  data() {
    return {
      manualWizard: undefined,
      stage: 'Start',
    };
  },
  methods: {
    async closeDrawer() {
      this.stage = 'Start';
      this.manualWizard = undefined;
      this.$emit('update:visible', false);
      this.$emit('onClose');
    },
    async goto(stage) {
      this.stage = stage;
    },
    openManualDrawer(component) {
      this.$emit('update:visible', false);
      this.stage = 'Start';
      this.manualWizard = component;
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }
</style>
