import { environment } from '@/app/environment/environment';

class AppLogger {
  constructor() {
    this.initLogger();
  }

  initLogger() {
    if (environment !== 'production') {
      this.log = console.log.bind(console);
      this.debug = console.debug.bind(console);
      this.info = console.info.bind(console);
      this.warn = console.warn.bind(console);
      this.error = console.error.bind(console);
    } else {
      this.log = () => {};
      this.debug = () => {};
      this.info = () => {};
      this.warn = () => {};
      this.error = () => {};
    }
  }
}

const logger = new AppLogger();

export default logger;
