import panel from './shared/state/panel-data';
import panelTitle from './panel-title/panel-title-data';
import panelAreas from './panel-areas/panel-areas-data';
import panelZones from './panel-zones/panel-zones-data';
import panelOutputs from './panel-outputs/panel-outputs-data';
import panelCounts from './panel-counts/panel-counts-data';
import panelConnections from './panel-connections/panel-connections-data';
import panelDiagnostics from './panel-diagnostics/panel-diagnostics-data';
import silenceAlarm from './alarm-silence/alarm-silence-data';


export default {
  namespaced: true,
  modules: {
    panel,
    panelTitle,
    panelAreas,
    panelZones,
    panelOutputs,
    panelCounts,
    panelConnections,
    panelDiagnostics,
    silenceAlarm,
  },
};
