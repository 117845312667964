import i18n from '@/app/shared/services/i18n';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import {
  getOutputApiPath, getAllOutputs, createRawOutputsList, udPulseTime,
} from '@/app/outputs/shared/services/helpers';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import { limits, livePanelStatusOutputsConsts, LOADING } from '@/app/shared/constants';

let dataRequired = false;

export function setDataRequiredFlag() {
  dataRequired = true;
}

function initialState() {
  return {
    installed: undefined,
    max: undefined,
    outputTypes: {},

    _items: [],
  };
}

function isVisible(output) {
  let isPresent = true;
  const isUsed = output.outputType !== 0;

  if (output.associatedWith.type === 'ENDSTATION' && output.present === 0) {
    isPresent = false;
  }

  return isPresent && isUsed;
}

const { store, api } = storeFactory(initialState, {
  getters: {
    items: state => state._items.sort((firstItem, secondItem) => firstItem.number - secondItem.number).filter(i => i.visible),
  },
  actions: {
    requiredEndpoints() {
      // Note that the default polling interval for this endpoint is 1s; in order to get onPoll() to get the required data quickly.
      return [livePanelStatusOutputsConsts.key];
    },
    async populate(context) {
      context.commit('set', {
        installed: context.state._items.filter(i => i.visible).length,
        max: limits.MAX_OUTPUTS,
      });

      const outputTypes = {};
      outputTypes[0] = { key: 'NOT_USED', name: 'Not used', isSirenOutputType: false };

      const settingsTooltip = i18n.t('outputs.options.settingsTooltip');
      const cloneTooltip = i18n.t('outputs.options.cloneTooltip');
      const deleteTooltip = i18n.t('outputs.options.deleteTooltip');
      const toggleEnabledTooltip = i18n.t('outputs.options.enabledTooltip');
      const toggleDisabledTooltip = i18n.t('outputs.options.disabledTooltip');

      if (context.state.installed === 0) {
        const numItems = 12;
        const items = [];
        const emptyAssociatedWith = { name: '', key: 'ENDSTATION_0' };
        for (let index = 0; index < numItems; index += 1) {
          items.push({
            index,
            outputIndex: 0,
            name: LOADING,
            location: LOADING,
            number: LOADING,
            rawOutputType: 0,
            noFollowOutputType: 0,
            outputTypeName: '',
            followType: '',
            followTime: '',
            translatedOutputType: null,
            associatedWith: emptyAssociatedWith,
            state: false,
            visible: true,
            settingsTooltip,
            cloneTooltip,
            deleteTooltip,
            toggleEnabledTooltip,
            toggleDisabledTooltip,
            udPulseTime: null,
          });
        }
        context.commit('set', { outputTypes, _items: items });
        dataRequired = true;
      }
    },
    async onPoll(context, { dataStatus, key, endpoint }) {
      if (!context.state.status.isPopulated) {
        // populate() is still running, so handling any live endpoints at this time will slow it down.
        return;
      }

      if (dataRequired) {
        dataRequired = false;
        // Create the new empty endpoints
        const endpoints = {
          configBusDevices: { key: 'configBusDevices', data: undefined },
          configEndstation: { key: 'configEndstation', data: undefined },
          configKeypadInfo: { key: 'configEndstation', data: undefined },
          configOutputInfo: { key: 'configEndstation', data: undefined },
          infoBusDevices: { key: 'infoBusDevices', data: undefined },
          infoEndstation: { key: 'infoEndstation', data: undefined },
          infoKeypadInfo: { key: 'infoKeypadInfo', data: undefined },
          infoOutputInfo: { key: 'infoOutputInfo', data: undefined },
          livePanelStatus: { key: 'livePanelStatus', data: undefined }, // Required by getEndstationOutputItem() etc but otherwise unused.
        };
        // Get the endpoints data
        endpoints.configBusDevices.data = (await api.get('/Config/BusDevices')).data;
        endpoints.configEndstation.data = (await api.get('/Config/Endstation')).data;
        endpoints.configKeypadInfo.data = (await api.get('/Config/keypadInfo')).data;
        endpoints.configOutputInfo.data = (await api.get('/Config/outputInfo')).data;
        endpoints.infoBusDevices.data = (await api.get('/Info/BusDevices')).data;
        endpoints.infoEndstation.data = (await api.get('/Info/Endstation')).data;
        endpoints.infoKeypadInfo.data = (await api.get('/Info/keypadInfo')).data;
        endpoints.infoOutputInfo.data = (await api.get('/Info/outputInfo')).data;

        const udOutputs = endpoints.configOutputInfo.data.Config.outputInfo.UserDefinedOutputs;
        const outputTypes = createRawOutputsList(endpoints.infoOutputInfo.data.Info.outputInfo, udOutputs);

        const settingsTooltip = i18n.t('outputs.options.settingsTooltip');
        const cloneTooltip = i18n.t('outputs.options.cloneTooltip');
        const deleteTooltip = i18n.t('outputs.options.deleteTooltip');
        const toggleEnabledTooltip = i18n.t('outputs.options.enabledTooltip');
        const toggleDisabledTooltip = i18n.t('outputs.options.disabledTooltip');

        const outputs = getAllOutputs(endpoints)
          .map((output, index) => ({
            index,
            outputIndex: output.outputIndex,
            name: output.name,
            location: output.location,
            number: output.number,
            rawOutputType: output.rawOutputType,
            noFollowOutputType: output.noFollowOutputType, // i.e. 3005 => 35
            outputTypeName: outputTypes[output.noFollowOutputType].key,
            followType: output.followType,
            followTime: output.followTime,
            translatedOutputType: outputTypes[output.noFollowOutputType].name,
            associatedWith: output.associatedWith,
            state: output.state,
            visible: isVisible(output),
            settingsTooltip,
            cloneTooltip,
            deleteTooltip,
            toggleEnabledTooltip,
            toggleDisabledTooltip,
            udPulseTime: udPulseTime(udOutputs, output.rawOutputType),
          }));
        context.commit('set', { outputTypes, _items: outputs });
      }
      if (key === livePanelStatusOutputsConsts.key) {
        const items = [];
        for (let i = 0; i < context.state._items.length; i += 1) {
          const { rawOutputType } = context.state._items[i];
          items.push({ state: endpoint.data.Live.PanelStatus.OutputTypes[rawOutputType].state });
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: '_items', items });
        if (context.state.installed !== context.state._items.length) {
          context.commit('set', { installed: context.state._items.filter(i => i.visible).length });
        }
      }
    },
    async delete(context, { indexes }) {
      for (let i = 0; i < indexes.length; i += 1) {
        // the collection of items has been sorted according to OP numbers, so now find the right index
        const { type, deviceIndex, deviceOutputIndex } = context.state._items.find(item => item.index === indexes[i]).associatedWith;
        const path = getOutputApiPath(type, deviceIndex, deviceOutputIndex);
        // eslint-disable-next-line no-await-in-loop
        const objDefaults = await generateDefaultObject(path);
        // eslint-disable-next-line no-await-in-loop
        await api.put(path, objDefaults);
      }

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('outputsState/outputsList/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('outputsState/addOutput/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['outputsState/outputsList'] });
    },
  },
});

export default store;
