import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';

function initialState() {
  return {
    form: {
      engineerUseApp: undefined,
      useApp: undefined,
      appCloudPassword: undefined,
      appSecurity: undefined,
      appPassword: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configAppData'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        engineerUseApp: endpoints.configAppData.data.Config.AppData.Eng_App_Enable,
        useApp: endpoints.configAppData.data.Config.AppData.App_Enable,
        appCloudPassword: endpoints.configAppData.data.Config.AppData.App_ERIC_Password,
        appSecurity: endpoints.configAppData.data.Config.AppData.App_Security,
        appPassword: endpoints.configAppData.data.Config.AppData.App_Key,
      });
    },
    async save(context) {
      const data = {
        Eng_App_Enable: context.state.form.engineerUseApp,
        App_Enable: context.state.form.useApp,
        App_ERIC_Password: context.state.form.appCloudPassword,
        App_Security: context.state.form.appSecurity,
        App_Key: context.state.form.appPassword,
      };

      await api.put('/Config/AppData', data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('communicationsState/appOptions/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['communicationsState/appOptions'] });
    },
  },
});


export default store;
