import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';

function initialState() {
  return {
    form: {
      ssidName: undefined,
      password: undefined,
      networkDelayFault: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configCommsInfo', 'configSysInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        ssidName: endpoints.configCommsInfo.data.Config.commsInfo.WiFi_NetworkData[1].WiFiSSID_Name,
        password: endpoints.configCommsInfo.data.Config.commsInfo.WiFi_NetworkData[1].WiFiPassword,
        networkDelayFault: endpoints.configSysInfo.data.Config.sysInfo.Timers.LineFaultTimeWifi_mins,
      });
    },
    async save(context) {
      const data = {
        WiFiSSID_Name: context.state.form.ssidName,
        WiFiPassword: context.state.form.password,
      };

      await api.put('/Config/sysInfo/Timers/LineFaultTimeWifi_mins', parseInt(context.state.form.networkDelayFault, 10));
      await api.put('/Config/commsInfo/WiFi_NetworkData/1', data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('communicationsState/wifi/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['communicationsState/wifi'] });
    },
  },
});


export default store;
