<template>
    <a-dropdown class="alternative" @visibleChange="visibleChanged" :visible="dropdownVisible" :trigger="['click']">
        <a-menu slot="overlay">
            <a-menu-item v-for="(item, index) in itemsWithStatus" :key="`item-${index}`">
                <a-checkbox :checked="item.checked" @change="onCheckboxChange(item, $event)">
                    {{ item.name }}
                </a-checkbox>
            </a-menu-item>
        </a-menu>
        <a-button class="ant-dropdown-trigger">
          <span>
            {{ title }}
          </span>
          <a-icon type="down" />
        </a-button>
    </a-dropdown>
</template>

<script>
export default {
  components: {

  },
  props: ['value', 'items'],
  data() {
    return {
      dropdownVisible: false,
      _value: [], // eslint-disable-line vue/no-reserved-keys
    };
  },
  computed: {
    title() {
      const selected = this.itemsWithStatus.filter(x => x.checked);
      if (selected.length <= 1) {
        return selected.map(x => x.name).join('');
      }
      return this.$t('common.Items-selected', { count: selected.length });
    },
    itemsWithStatus() {
      return this.items.map(d => ({ ...d, checked: this.checked(d.value) }));
    },
  },
  methods: {
    checked(value) {
      return this.value.includes(value);
    },
    visibleChanged(e) {
      this.dropdownVisible = e;
    },
    onCheckboxChange(item, e) {
      if (e.target.checked) {
        this._value.push(item.value);
      } else {
        this._value = this._value.filter(v => v !== item.value);
      }
      this.$emit('input', this._value);
    },
  },
  created() {
    this._value = this.value;
  },
};
</script>

<style lang="scss">
form .ant-dropdown-trigger{
    width: 100%;
    color: #484848;

    span {
      width:100%;
    }
    i {
      color: #484848 !important;
      margin-left: -8px !important;
    }
}
</style>
