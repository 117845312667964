<template>
  <div>
    <div v-if="areaTrigger">
        <a-alert :showIcon="false" type="warning">
            <template v-slot:message>
                {{ $t('panel.Alarm-sounding-needs-silencing') }}
            </template>
            <template v-slot:description>
                <a-button class="alternative"
                  @click="silenceAlarm"
                  :loading="performingSilencing">
                  {{ $t('panel.Silence-alarm') }}
                </a-button>
                <a-button @click="{ listFaults(); showFaultsModal.visible = true; }" style="float: right;">
                  <font-awesome-icon :icon="['fal', 'exclamation-triangle']" />
                  {{ $t('panel.List-faults') }}
                </a-button>
            </template>
        </a-alert>
    </div>
    <div v-else-if="!areaTrigger">
        <div v-if="faultTrigger && EngineerRestoreTrigger">
          <a-alert :showIcon="false" type="warning" >
              <template v-slot:message>
                  {{ $t('panel.System-silenced-but-in-fault') }}
                    <a-button @click="{ listFaults(); showFaultsModal.visible = true; }" style="float: right;">
                    <font-awesome-icon :icon="['fal', 'exclamation-triangle']" />
                    {{ $t('panel.List-faults') }}
                  </a-button>
              </template>
          </a-alert>
        </div>
        <div v-else-if="!faultTrigger && EngineerRestoreTrigger">
          <a-alert :showIcon="false" type="warning">
            <template v-slot:message>
                {{ $t('panel.Weve-found-some-faults-on-the-panel-an-engineer-restore-is-required') }}
            </template>
            <template v-slot:description>
              <a-button class="alternative"
                @click="{ engineerRestore(); emptyFaultsList(); }"
                :loading="performingEngineerRestore">
                {{ $t('panel.Perform-Engineer-Restore') }}
              </a-button>
            </template>
          </a-alert>
      </div>
    </div>
    <a-modal :title="$t('panel.List-of-current-faults')" v-model="showFaultsModal.visible">
      <div class="faults-list">
        <div class="faults-list__items">
            <ul>
              <li v-for="fault in panelFaults" :key="fault"> {{ fault }}</li>
            </ul>
        </div>
      </div>
      <template slot="footer">
        <hr class="dashed-bottom" />
        <div style="margin-bottom: 5%;">
          <a-row :gutter="8">
            <a-col :span="12" style="text-align: left;">
              <a-button @click="showFaultsModal.visible = false;">{{ $t('common.Ok') }}</a-button>
            </a-col>
          </a-row>
          <router-link :to="{ name: 'panel-logs' }" style="float: left;">
            {{$t('panel.Faults-Additional-Details')}}
            <font-awesome-icon :icon="['fal', 'external-link-alt']" />
          </router-link>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers(
  'panelState/silenceAlarm',
  // 'panelState/engineerRestore',
);

export default {
  data() {
    return {
      showFaultsModal: {
        visible: false,
      },
      faultsList: [],
    };
  },
  props: ['loader'],
  computed: {
    ...mapState(['performingSilencing', 'areaTrigger', 'faultTrigger', 'performingEngineerRestore', 'EngineerRestoreTrigger', 'panelFaults']),
  },
  methods: {
    ...mapActions(['silenceAlarm', 'engineerRestore', 'listFaults']),
    updateFaultsList() {
      this.faultsList = JSON.parse(localStorage.getItem('vuex')).panelState.silenceAlarm.panelFaults;
    },
    emptyFaultsList() {
      const newVuex = JSON.parse(localStorage.getItem('vuex'));
      newVuex.panelState.silenceAlarm.panelFaults = [];
      localStorage.setItem('vuex', JSON.stringify(newVuex));
    },
  },
};
</script>

<style lang="scss">
  .ant-alert-description button.alternative {
    margin-top: 10px;
    background:  #fff0a6;
    color: #484848;
  }

</style>
