import { storeFactory } from '@/app/shared/services/store-helper';
import i18n from '@/app/shared/services/i18n';

function parseNumberOrZero(val) {
  const parsed = parseInt(val, 10);
  return Number.isNaN(parsed) ? 0 : val;
}

function initialState() {
  return {
    status: {
      isLoading: true,
    },
    globalState: undefined,
    items: [
      {
        isLoading: true,
        key: 'panel.Diagnostics.ConnectionStatus',
        deviceType: null,
      },
      {
        isLoading: true,
        key: 'panel.Diagnostics.TamperStatus',
        deviceName: null,
        deviceType: null,
        address: null,
      },
      {
        isLoading: true,
        key: 'panel.Diagnostics.ACStatus',
        deviceName: null,
        deviceType: null,
        address: null,
      },
      {
        isLoading: true,
        key: 'panel.Diagnostics.RS485CommsStatus',
        deviceName: null,
        deviceType: null,
        address: null,
      },
      {
        isLoading: true,
        key: 'panel.Diagnostics.CommsStatus',
        commsModuleNumber: null,
      },
      {
        isLoading: true,
        key: 'panel.Diagnostics.BackupBatteryStatus',
        mA: 0,
        mV: 0,
      },
    ],
  };
}

const { store } = storeFactory(initialState, {
  getters: {
    isLoading: state => state.status.isLoading,
  },
  actions: {
    requiredEndpoints: () => ['liveSystem', 'liveCommsInfo', 'liveEndstation', 'infoEndstation'],
    async populate(context, { endpoints }) {
      await context.dispatch('onPoll', { key: 'liveSystem', endpoint: endpoints.liveSystem });
      await context.dispatch('onPoll', { key: 'liveCommsInfo', endpoint: endpoints.liveCommsInfo });
      await context.dispatch('onPoll', { key: 'liveEndstation', endpoint: endpoints.liveEndstation });

      context.commit('setItemByKey', {
        key: 'panel.Diagnostics.ConnectionStatus',
        item: { deviceType: endpoints.infoEndstation.data.Info.Endstation.PanelType },
      });
    },
    async onPoll(context, { dataStatus, key, endpoint }) {
      if (key === 'liveSystem') {
        context.commit('trySet', { dataStatus, data: { globalState: endpoint.data.Live.System.state } });
        context.commit('setStatus', { isLoading: false });
        context.commit('trySetItemByKey', {
          dataStatus,
          key: 'panel.Diagnostics.TamperStatus',
          item: {
            value: endpoint.data.Live.System.TamperStatus,
            isLoading: false,
            tooltip() {
              const options = {
                deviceName: this.deviceName,
                deviceType: this.deviceType,
                address: this.address,
              };
              return this.value === 'OK'
                ? i18n.t('panel.Diagnostics.TamperStatus.okTooltip', options)
                : i18n.t('panel.Diagnostics.TamperStatus.faultTooltip', options);
            },
          },
        });
        context.commit('trySetItemByKey', {
          dataStatus,
          key: 'panel.Diagnostics.RS485CommsStatus',
          item: {
            value: endpoint.data.Live.System.RS485CommsStatus,
            isLoading: false,
            tooltip() {
              const options = {
                deviceName: this.deviceName,
                deviceType: this.deviceType,
                address: this.address,
              };
              return this.value === 'OK'
                ? i18n.t('panel.Diagnostics.RS485CommsStatus.okTooltip', options)
                : i18n.t('panel.Diagnostics.RS485CommsStatus.faultTooltip', options);
            },
          },
        });
        context.commit('trySetItemByKey', {
          dataStatus,
          key: 'panel.Diagnostics.CommsStatus',
          item: {
            value: endpoint.data.Live.System.CommsStatus,
            isLoading: false,
            tooltip() {
              if (this.value === 'OK') {
                return i18n.t('panel.Diagnostics.CommsStatus.okTooltip');
              }
              if (this.commsModuleNumber) {
                return i18n.t('panel.Diagnostics.CommsStatus.moduleFaultTooltip', { commsModuleNumber: this.commsModuleNumber });
              }
              return i18n.t('panel.Diagnostics.CommsStatus.lineFaultTooltip');
            },
          },
        });
      }

      if (key === 'liveCommsInfo') {
        context.commit('trySetItemByKey', {
          dataStatus,
          key: 'panel.Diagnostics.ConnectionStatus',
          item: {
            value: endpoint.data.Live.commsInfo.Cloud.ConnectionStatus,
            isLoading: false,
            tooltip() {
              return this.value === 'CONNECTED'
                ? i18n.t('panel.Diagnostics.ConnectionStatus.connectedTooltip', { deviceType: this.deviceType })
                : i18n.t('panel.Diagnostics.ConnectionStatus.notConnectedTooltip', { deviceType: this.deviceType });
            },
          },
        });
      }

      if (key === 'liveEndstation') {
        context.commit('trySetItemByKey', {
          dataStatus,
          key: 'panel.Diagnostics.ACStatus',
          item: {
            value: endpoint.data.Live.Endstation.ACStatus,
            current: endpoint.data.Live.Endstation.PowerSupplyCurrent_mA,
            voltage: endpoint.data.Live.Endstation.PowerSupplyVoltage_mV,
            isLoading: false,
            tooltip() {
              if (this.value === 'OK') {
                let voltage = parseNumberOrZero(this.voltage);
                voltage = voltage ? (voltage / 1000) : 0;
                let current = parseNumberOrZero(this.current);
                current = current ? (current / 1000) : 0;
                return `${voltage}V ${current}A`;
              }
              if (this.value === 'FAULT') {
                return i18n.t('panel.Diagnostics.ACStatus.faultTooltip');
              }
              return i18n.t('panel.Diagnostics.ACStatus.missingTooltip');
            },
          },
        });
        context.commit('trySetItemByKey', {
          dataStatus,
          key: 'panel.Diagnostics.BackupBatteryStatus',
          item: {
            value: endpoint.data.Live.Endstation.BackupBatteryStatus,
            mA: endpoint.data.Live.Endstation.BatteryCurrent_mA,
            mV: endpoint.data.Live.Endstation.BatteryVoltage_mV,
            isLoading: false,
            tooltip() {
              if (this.value === 'OK') {
                const volts = this.mV ? this.mV / 1000 : 0;
                const amps = this.mA ? this.mA / 1000 : 0;
                return `${volts}V ${amps}A`;
              }
              return i18n.t('panel.Diagnostics.BackupBatteryStatus.missingTooltip');
            },
          },
        });
      }
    },
  },
});

export default store;
