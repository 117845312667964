import logger from '@/app/shared/services/logger';

/* eslint-disable import/prefer-default-export */
export class CommandError extends Error {
  constructor(message, command, errors, innerError) {
    super(message);
    logger.log(command);
    this.command = command;
    this.subErrors = errors || [];
    this.innerError = innerError;
  }

  static SET_AREAS = 'SET_AREAS';

  static ACTIVATE_OUTPUT = 'ACTIVATE_OUTPUT';

  static OMMITT_ZONE = 'OMMITT_ZONE';
}

export class NotFoundError extends Error { }

export class AutoScanFailed extends Error { }

export class LearnWirelessFailed extends Error { }
