import { getField } from 'vuex-map-fields';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { ACCESS_TYPE } from '@/app/users/shared/enums';
import { READER_TYPE, KEYPAD_AND_READER_TYPE } from '@/app/keypads-and-readers/shared/enums';
import {
  toFriendlyCode, canHaveAutoDuress, TAG_LENGTH, getDefaultEngineersCode,
} from '@/app/users/shared/services/helpers';
import { limits } from '@/app/shared/constants';

function initialState() {
  return {
    availableIndexes: [],
    doors: [],
    form: {
      name: undefined,
      accessType: ACCESS_TYPE.USER_CODE.value,
      code: undefined,
      userType: undefined,
      areas: undefined,
      setOption: undefined,
      index: undefined,
      flexiSet: false,
      associatedDoors: [],
      validation: {
        usedCodes: [],
        minimumCodeLengths: 4,
        defEngCode: '1111',
      },
    },
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {
    getField,
  },
  actions: {
    requiredEndpoints() {
      return ['configUserInfo', 'configKeypadInfo', 'configSysInfo', 'infoAreaInfo', 'configAreaInfoNames'];
    },
    async populate(context, { endpoints, payload }) {
      let userToClone = null;

      const usedCodes = endpoints.configUserInfo.data.Config.userInfo.Users
        .filter(u => (ACCESS_TYPE.fromCode(u.Code) !== ACCESS_TYPE.NONE) && (ACCESS_TYPE.fromCode(u.Code) !== ACCESS_TYPE.KEY_FOB))
        .map(u => toFriendlyCode(u.Code));

      if (payload != null && payload.index != null) {
        userToClone = endpoints.configUserInfo.data.Config.userInfo.Users[payload.index];

        context.commit('setForm', {
          name: userToClone.Name,
          code: undefined,
          userType: userToClone.UserType,
          areas: userToClone.UserAreas,
          setOption: userToClone.UserSetOption,
          flexiSet: userToClone.UserFlexiSet,
        });
      }

      const availableIndexes = [];
      for (let i = 2; i <= limits.MAX_USERS; i += 1) {
        if (ACCESS_TYPE.fromCode(endpoints.configUserInfo.data.Config.userInfo.Users[i].Code) == null) {
          availableIndexes.push(i);
        }
      }

      const doors = [];
      const associatedDoors = [];

      for (let i = 0; i < endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads.length; i += 1) {
        const type = KEYPAD_AND_READER_TYPE.fromInt(endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[i].Type);
        const readerType = READER_TYPE.fromInt(endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[i].ReaderType);
        const name = endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[i].Name;
        const canAccess = userToClone != null && userToClone.UserCanAccess[i];
        if (type === KEYPAD_AND_READER_TYPE.TYPE_READER && (readerType === READER_TYPE.ACCESS_CONTROL || readerType === READER_TYPE.ENTRY_CONTROL)) {
          if (canAccess) associatedDoors.push({ value: i, name });
          doors.push({ value: i, name });
        }
      }

      context.commit('setForm', {
        associatedDoors,
        validation: {
          usedCodes,
          minimumCodeLengths: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.MinimumCodeLength,
          showCode: true,
          defEngCode: await getDefaultEngineersCode(api),
        },
      });

      context.commit('set', {
        doors,
        availableIndexes,
        areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
        areasConfig: endpoints.configAreaInfoNames.data.Config.areaInfo,
      });
    },
    async add(context) {
      const { index } = context.state.form;

      const data = {};

      data.Name = context.state.form.name;

      const code = [64, 64, 64, 64, 64, 64];
      if (context.state.form.code.length === TAG_LENGTH) {
        for (let i = 0; i < TAG_LENGTH; i += 2) {
          code[i / 2] = ((i === 0) ? 128 : 0) + parseInt(context.state.form.code.substr(i, 2), 16);
        }
      } else {
        let x = 0;
        for (let i = context.state.form.code.length - 1; i >= 0; i -= 1) {
          code[x] = parseInt(context.state.form.code[i], 10);
          x += 1;
        }
      }

      data.Code = code;

      data.UserType = context.state.form.userType;
      data.UserAreas = context.state.form.areas;
      data.UserSetOption = context.state.form.setOption;
      data.UserFlexiSet = context.state.form.flexiSet;

      data.UserCanAccess = [];

      for (let i = 0; i < limits.MAX_SUBAREAS; i += 1) { // subareas
        const door = context.state.form.associatedDoors.find(d => d.value === i);
        data.UserCanAccess[i] = door != null;
      }

      await api.put(`/Config/userInfo/Users/${index}`, data);
      if (canHaveAutoDuress(api, index, ACCESS_TYPE.USER_CODE.value)) {
        await api.put('/Live/userInfo/AutoDuressUser', index);
      }

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('usersState/list/requiredEndpoints');
      const requiredEndpointsAddUser = (await this.dispatch('usersState/addUser/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const requiredEndpointsAddKeyfob = (await this.dispatch('usersState/addKeyFob/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp) && !requiredEndpointsAddUser.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddUser, requiredEndpointsAddKeyfob);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['usersState/list'] });
    },
  },
});

export default store;
