<template>
  <div>
    <a-row>
      <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
      <h2 class="page-title">{{ $t('communications.smsControls.navigationTitle') }}</h2>
    </a-row>
    <a-form>
      <p-switch :label="$t('communications.smsControls.SMS-set')" :labelTooltip="$t('communications.smsControls.SMS-set-tooltip')" :model="$v.form.set"  display-style="small" :disabled="!configWriteLock.canWriteConfig" />
      <p-switch :label="$t('communications.smsControls.SMS-unset')" :labelTooltip="$t('communications.smsControls.SMS-unset-tooltip')" :model="$v.form.unset"  display-style="small" :disabled="!configWriteLock.canWriteConfig" />
      <p-switch :label="$t('communications.smsControls.SMS-status')" :labelTooltip="$t('communications.smsControls.SMS-status-tooltip')" :model="$v.form.status"  display-style="small" :disabled="!configWriteLock.canWriteConfig" />
      <p-switch :label="$t('communications.smsControls.SMS-output')" :labelTooltip="$t('communications.smsControls.SMS-output-tooltip')" :model="$v.form.output"  display-style="small" :disabled="!configWriteLock.canWriteConfig" />
      <p-switch :label="$t('communications.smsControls.SMS-number-edit')" :labelTooltip="$t('communications.smsControls.SMS-number-edit-tooltip')" :model="$v.form.numberEdit"  display-style="small" :disabled="!configWriteLock.canWriteConfig" />

      <a-tabs defaultActiveKey="item-0">
        <a-tab-pane v-for="(v, index) in $v.form.items.$each.$iter" :key="`item-${index}`">
          <template slot="tab">
            <span :class="{ 'has-error': v.$anyError }">
              {{ parseInt(index, 10) + 1 }} <span class="ant-form-explain" v-if="v.$anyError">*</span>
            </span>
          </template>
          <p-input :label="$t('communications.smsControls.Phone-number')" :model="v.phoneNumber" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
        </a-tab-pane>
      </a-tabs>
    </a-form>
    <div class="align-right">
      <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
    </div>

    <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
    <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
      @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />

  </div>
</template>

<script>
import { maxLength } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'communicationsState/smsControls';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
    };
  },
  computed: {
    ...mapState(['form']),
  },
  validations: {
    form: {
      set: { },
      unset: { },
      status: { },
      output: { },
      numberEdit: { },
      items: {
        $each: {
          phoneNumber: {
            maxLength: maxLength(20),
          },
        },
      },
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
};
</script>
