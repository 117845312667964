import { API } from '@/app/shared/services/api';
import { getName } from '@/app/users/shared/services/helpers';

const pollInterval = 2500;

let instance = null;

export class Poller {
  constructor() {
    this.api = new API();
    this.reset();
  }

  addCallback(fn) {
    this.callback.push(fn);
  }

  start() {
    if (!this.isPolling) {
      this.api.poll(pollInterval, '/Live/ConfigWriteLock', async (res) => {
        if (res) {
          let lockOwner = res.data.Live.ConfigWriteLock.IsActive ? res.data.Live.ConfigWriteLock.LockOwner : null;
          if (res.data.Live.ConfigWriteLock.IsActive && !res.data.Live.ConfigWriteLock.IsMine) {
            // someone else has just got the lock
            const refreshEndpointsRequired = JSON.parse(window.localStorage.getItem('refreshEndpointsRequired'));
            if (!refreshEndpointsRequired) {
              // set flag to refresh the endpoint collections with CONFIG_WRITE next time we take the lock in this session
              window.localStorage.setItem('refreshEndpointsRequired', 'true');
            }
            // Someone else owns the lock - try to get the user's name
            const userIndex = Number(lockOwner);
            if (!Number.isNaN(userIndex)) {
              const users = await this.api.get('/Config/userInfo/Users');
              const userName = getName(userIndex, users.data.Config.userInfo.Users[userIndex].Name);
              if (userName.length) {
                lockOwner = userName;
              }
            }
          }
          const lanClientID = JSON.parse(window.localStorage.getItem('locallyStoreAuthStateSessionId'));

          const lanHasLock = res.data.Live.ConfigWriteLock.IsActive && res.data.Live.ConfigWriteLock.IsMine && (lanClientID === lockOwner);

          // BUGRD-584 (side issue) - Start tracking lostLock only after the LAN acquires the lock.
          // If the LAN loses the lock without signing out or navigating to the dashboard page,
          // and there is no active lock owner, then the user must have entered Master Manager/USER menu via the keypad.
          // We want the TakeControl banner up when the LAN loses its lock but there's no lock owner.
          // Note: A better solution would be adding a value to the ConfigWriteLock endpoint, say 'userType',
          // which gets set to Master or User. If isActive is false, and userType is Manager or User, it means
          // the LAN has lost its lock, and thus, the 'TakeControl' banner should be displayed when the keypad
          // enters Master or User mode with a B-key press.
          if (res.data.Live.ConfigWriteLock.IsMine) {
            this.configWriteLock.trackIfLanLostLock = true;
            this.configWriteLock.lostLock = false;
          } else if (this.configWriteLock.trackIfLanLostLock) {
            this.configWriteLock.lostLock = true;
            this.configWriteLock.trackIfLanLostLock = false;
          }

          this.configWriteLock.isActive = res.data.Live.ConfigWriteLock.IsActive;
          this.configWriteLock.isMine = res.data.Live.ConfigWriteLock.IsMine;
          this.configWriteLock.lockOwner = lockOwner;
          this.configWriteLock.canWriteConfig = this.configWriteLock.isActive && this.configWriteLock.isMine;
          this.configWriteLock.isLockReleasedByBKeyPress = res.data.Live.ConfigWriteLock.LockReleasedByBKeyPress;
          // Handy comments when debugging lock owner / B-key issues
          // console.log(`LAN has lost lock ${this.configWriteLock.lostLock}`);
          // console.log(`Lock owner is ${this.configWriteLock.lockOwner}`);

          // Read if panel is armed - any area is set
          this.anyAreaSet = (await this.api.get('/Live/areaInfo/AnyAreaSet')).data.Live.areaInfo.AnyAreaSet;

          // Notify any components
          this.callback.forEach((fn, index) => fn(this.configWriteLock, this.anyAreaSet, lanHasLock, index));
        }
      });
      this.isPolling = true;
    }
  }

  stop() {
    if (this.isPolling) {
      this.api.cancelPolling();
      this.isPolling = false;
    }
  }

  reset() {
    this.stop();
    this.configWriteLock = {
      // Can the LAN edit the config? It must be owning the lock if it can edit the config.
      canWriteConfig: null,
      // Name of the current lock owner.
      lockOwner: null,
      // Was the LAN owning the lock and has it now lost the lock?
      lostLock: JSON.parse(window.localStorage.getItem('cachedLanLostLockStatus')),
      // Is the lock taken by some other client?
      isActive: null,
      // Does the LAN own the lock?
      isMine: null,
      isLockReleasedByBKeyPress: null,
      // Signals if 'lostLock' must be evaluated/tracked.
      trackIfLanLostLock: JSON.parse(window.localStorage.getItem('cachedLanLostLockStatus')),
    };
    this.isPolling = false;
    this.callback = [];
  }

  static getInstance() {
    if (instance === null) {
      instance = new Poller();
    }
    return instance;
  }
}
