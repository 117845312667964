<template>
      <div class="mainNavigation">
        <div class="mainNavigation__left">
            <div>
                <div>
                <!-- <img class="logo-symbol"
                     src="@/assets/images/Logo_Symbol_White.svg"
                     alt="Pyronix Symbol" /> -->
                </div>
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    <span v-if="this.configWriteLock.canWriteConfig">{{ $t('common.AddDevices') }}</span>
                    <span v-else>{{ $t('common.AddDevices-inhibited') }}</span>
                  </template>
                  <a-button class="clear" @click="showAddDevicesDrawer">
                      <font-awesome-icon class="large-icon" :icon="['fal', 'plus']" />
                  </a-button>
                </a-tooltip>
            </div>
            <a-button class="bottom clear icon-only" @click="logout">
                <font-awesome-icon class="large-icon" :icon="['fal', 'sign-out']" />
                <p>{{ $t('common.SignOut') }}</p>
            </a-button>
        </div>
        <div class="mainNavigation__right">
            <div>
                <img class="logo-main"
                     src="@/assets/images/Logo_White.svg"
                     alt="Pyronix Logo" />
                <a-menu
                    mode="inline"
                    theme="dark"
                    :openKeys="openKeys"
                    :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner"
                    @click="onClick"
                    @openChange="onOpenChange">

                    <a-menu-item key="/" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                      <a-tooltip placement="topRight">
                        <template slot="title">
                          <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                        </template>
                        <font-awesome-icon :icon="['fal', 'tachometer-alt-fast']" />
                        {{ $t('panel.navigationTitle') }}
                      </a-tooltip>
                    </a-menu-item>
                    <a-menu-item key="/keypads-and-readers" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                      <a-tooltip placement="topRight">
                        <template slot="title">
                          <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                        </template>
                        <font-awesome-icon :icon="['fal', 'scanner-keyboard']" />
                        {{ $t('keypadsAndReaders.navigationTitle') }}
                      </a-tooltip>
                    </a-menu-item>
                    <a-menu-item key="/users" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                      <a-tooltip placement="topRight">
                        <template slot="title">
                          <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                        </template>
                        <font-awesome-icon :icon="['fal', 'users']" />
                        {{ $t('users.navigationTitle') }}
                      </a-tooltip>
                    </a-menu-item>
                    <a-menu-item key="/zone-expanders" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                      <a-tooltip placement="topRight">
                        <template slot="title">
                          <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                        </template>
                        <font-awesome-icon :icon="['fal', 'scanner-keyboard']" />
                        {{ $t('zoneExpanders.navigationTitle') }}
                      </a-tooltip>
                    </a-menu-item>
                    <a-menu-item key="/zones" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                      <a-tooltip placement="topRight">
                        <template slot="title">
                          <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                        </template>
                        <font-awesome-icon :icon="['fal', 'street-view']" />
                        {{ $t('zones.navigationTitle') }}
                      </a-tooltip>
                    </a-menu-item>
                    <a-menu-item key="/output-expanders" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                      <a-tooltip placement="topRight">
                        <template slot="title">
                          <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                        </template>
                        <font-awesome-icon :icon="['fal', 'scanner-keyboard']" />
                        {{ $t('outputExpanders.navigationTitle') }}
                      </a-tooltip>
                    </a-menu-item>
                    <a-sub-menu key="/outputs" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                      <span slot="title">
                          <span>
                              <font-awesome-icon :icon="['fal', 'bullhorn']" />
                              {{ $t('outputs.navigationTitle.Outputs') }}
                          </span>
                      </span>
                      <a-menu-item key="/outputs/outputs">
                        <a-tooltip placement="topRight">
                          <template slot="title">
                            <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                          </template>
                          {{ $t('outputs.navigationTitle.Outputs') }}
                        </a-tooltip>
                      </a-menu-item>
                      <a-menu-item key="/outputs/user-defined-outputs">
                        <a-tooltip placement="topRight">
                          <template slot="title">
                            <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                          </template>
                          {{ $t('outputs.navigationTitle.UserDefinedOutputs') }}
                        </a-tooltip>
                      </a-menu-item>
                      <a-menu-item key="/outputs/logic-gates">
                        <a-tooltip placement="topRight">
                          <template slot="title">
                            <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                          </template>
                          {{ $t('outputs.navigationTitle.LogicGates') }}
                        </a-tooltip>
                      </a-menu-item>
                    </a-sub-menu>
                    <a-menu-item key="/sirens" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                      <a-tooltip placement="topRight">
                        <template slot="title">
                          <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                        </template>
                        <font-awesome-icon :icon="['fal', 'siren-on']" />
                        {{ $t('sirens.navigationTitle') }}
                      </a-tooltip>
                    </a-menu-item>
                    <a-menu-item key="/communications" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                      <a-tooltip placement="topRight">
                        <template slot="title">
                          <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                        </template>
                        <font-awesome-icon :icon="['fal', 'broadcast-tower']" />
                        {{ $t('communications.navigationTitle') }}
                      </a-tooltip>
                    </a-menu-item>
                    <a-menu-item key="/settings" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                      <a-tooltip placement="topRight">
                        <template slot="title">
                          <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                        </template>
                        <font-awesome-icon :icon="['fal', 'cog']" />
                        {{ $t('settings.navigationTitle') }}
                      </a-tooltip>
                    </a-menu-item>
                    <a-menu-item key="/maintenance" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                      <a-tooltip placement="topRight">
                        <template slot="title">
                          <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                        </template>
                        <font-awesome-icon :icon="['fal', 'wrench']" />
                        {{ $t('maintenance.navigationTitle') }}
                      </a-tooltip>
                    </a-menu-item>
                    <a-sub-menu key="/history" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                        <span slot="title">
                          <span>
                            <font-awesome-icon :icon="['fal', 'history']" />
                              {{ $t('history.navigationTitle') }}
                          </span>
                        </span>
                      <a-menu-item key="/history/panel">
                        <a-tooltip placement="topRight">
                          <template slot="title">
                            <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                          </template>
                        {{ $t('history.panelLogs.navigationTitle') }}
                        </a-tooltip>
                      </a-menu-item>
                      <a-menu-item key="/history/access">
                        <a-tooltip placement="topRight">
                            <template slot="title">
                              <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                            </template>
                        {{ $t('history.accessLogs.navigationTitle') }}
                        </a-tooltip>
                      </a-menu-item>
                      <a-menu-item key="/history/voice">
                        <a-tooltip placement="topRight">
                          <template slot="title">
                            <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                          </template>
                          {{ $t('history.voiceLogs.navigationTitle') }}
                        </a-tooltip>
                      </a-menu-item>
                      <a-menu-item key="/history/cloud" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                        <a-tooltip placement="topRight">
                          <template slot="title">
                            <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                          </template>
                          {{ $t('history.cloudLogs.navigationTitle') }}
                        </a-tooltip>
                      </a-menu-item>
                    </a-sub-menu>
                    <a-menu-item key="/import-export" :disabled="this.$gLanModeSetting.panelPageStopNavigationBanner">
                      <a-tooltip placement="topRight">
                        <template slot="title">
                          <span v-if="this.$gLanModeSetting.panelPageStopNavigationBanner">{{ $t('common.Please-wait-tool-tip') }}</span>
                        </template>
                        <font-awesome-icon :icon="['fal', 'file-export']" />
                        {{ $t('importExport.navigationTitle') }}
                      </a-tooltip>
                    </a-menu-item>
                </a-menu>
            </div>
            <!-- <a-button class="bottom" @click="languageModalVisible = true">
                {{ $t('changeLanguage.navigationTitle') }}
            </a-button>
           <a-modal title="Change language to..." v-model="languageModalVisible" @ok="changeLanguage">
                <div class="text-center languages">
                    <a-button class="clear" @click="language = 'en-GB'" style="box-shadow:none">
                        <img src="@/assets/images/english.svg" />
                        <p>{{ $t('changeLanguage.English') }}</p>
                    </a-button>
                    <a-button class="clear" @click="language = 'fr'" style="box-shadow:none">
                        <img src="@/assets/images/french.svg" />
                        <p>{{ $t('changeLanguage.French') }}</p>
                    </a-button>
                    <a-button class="clear" @click="language = 'it'" style="box-shadow:none">
                        <img src="@/assets/images/italian.svg" />
                        <p>{{ $t('changeLanguage.Italian') }}</p>
                    </a-button>
                    <a-button class="clear" @click="language = 'es'" style="box-shadow:none">
                        <img src="@/assets/images/spanish.svg" />
                        <p>{{ $t('changeLanguage.Spanish') }}</p>
                    </a-button>
                </div>
          </a-modal> -->
        </div>
        <add-devices-drawer v-bind:visible.sync="addDevicesDrawerVisible" />
        <display-faults-modal :visible="modalVisible" :errors="errors" :arcDetails="arcError" @logout="logout" @cancel="() => {modalVisible = false; errors = []; arcError = 'OK'}" />
    </div>
</template>
<script>
import authService from '@/app/shared/services/auth-service'; // eslint-disable-line import/no-cycle
import AddDevicesDrawer from '@/app/shared/components/add-devices-drawer/add-devices-drawer.vue';
import { Poller } from '@/app/shared/services/config-write-lock'; // eslint-disable-line import/no-cycle
import displayFaultsHelper from '@/app/shared/services/display-faults-helper'; // eslint-disable-line import/no-cycle
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import logger from '@/app/shared/services/logger';
import { panelPageConsts } from '@/app/shared/constants'; // eslint-disable-line import/no-cycle
import { API } from '@/app/shared/services/api'; // eslint-disable-line import/no-cycle

const api = new API();

export default {
  name: 'MainNavigation',
  props: {
    configWriteLock: {
      default() {
        return {
          canWriteConfig: true,
        };
      },
    },
  },
  components: {
    AddDevicesDrawer,
    DisplayFaultsModal,
  },
  data() {
    return {
      addDevicesDrawerVisible: false,
      rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
      openKeys: [],
      languageModalVisible: false,
      language: this.$i18n.locale,
      errors: [],
      arcError: 'OK',
      modalVisible: false,
    };
  },
  created() {
    if (this.$route.path.startsWith('/outputs-and-sirens')) {
      this.openKeys = ['/outputs-and-sirens'];
    }
    window.addEventListener('beforeunload', this.onPageReload);
  },
  methods: {
    setCommitConfig() {
      // Check the global flag to see if any changes have been PUT to the panel's /Config
      const commitConfigRequired = JSON.parse(window.localStorage.getItem('commitConfigRequired'));
      if (commitConfigRequired) {
        // reset the flag now that the config is being synced
        window.localStorage.setItem('commitConfigRequired', 'false');
        // do a CommitConfig command on the panel to sync up the existing config with the peripherals
        // do it async so it won't wait for the result
        api.put('/Live/CommitConfig', true);
      }
    },
    showAddDevicesDrawer() {
      if (this.configWriteLock.canWriteConfig) {
        this.addDevicesDrawerVisible = true;
      }
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    async onClick(item) {
      // The if condition below checks if user is navigating to the dashboard page or an engineering page immediately
      // after a page reload on an engineering page.
      if (this.$gLanModeSetting.panelPageModeForNavigation === null && window.localStorage.getItem('panelPageModeForNavigationOnReload') === panelPageConsts.engineerModeSet) {
        this.$gLanModeSetting.panelPageModeForNavigation = panelPageConsts.engineerModeSet;
        this.$gLanModeSetting.lanModeSettingInProgress = JSON.parse(window.localStorage.getItem('cachedLanModeSettingInProgress'));
      }

      // !!!ToDo!!! - Is the panel being armed/disarmed? - prevent navigation - BUGRD-380 - add if condition - this is required only if the LAN
      // and WAN must be consistent with the keypad in hiding engineering page when the panel is armed.

      if (this.$gLanModeSetting.panelPageModeForNavigation === panelPageConsts.panelArmed) {
        // Is the panel armed?
        // Clear the previous list of endpoints
        const backup = window.globalThis.currentRequiredEndpoints;
        window.globalThis.currentRequiredEndpoints = [];
        this.$router.push({ path: item.key, query: { page: 1 } }).catch(() => {
          // probably navigating to the same page, retain the endpoints, and catch JS navigation exception
          window.globalThis.currentRequiredEndpoints = backup;
        });
        this.$gLanModeSetting.panelPageStopNavigationBanner = false; // no banners
      } else if (this.$gLanModeSetting.currentPageIsPanel === true && item.key !== panelPageConsts.panelPageKey) {
        // Is the user navigating away from the panel page when LAN is busy requesting USER mode?
        if (this.$gLanModeSetting.panelPageModeForNavigation === panelPageConsts.userModeSet) {
        // User is navigating away after USER mode or engineer mode has been set - allow navigation
          // Clear the previous list of endpoints
          window.globalThis.currentRequiredEndpoints = [];
          // Request Engineer mode. Check the config write lock is free...
          let resp = (await api.get('/Live/ConfigWriteLock')).data.Live.ConfigWriteLock;
          if (!resp.IsActive || (resp.IsActive && resp.IsMine)) {
            // CWL is free: so move from User to Engineer mode
            await api.put('/Live/ClientStatus/Mode', panelPageConsts.engineerMode);
            // setPanelPageModeStatus(panelPageConsts.engineerModeRequested);
            this.$gLanModeSetting.panelPageModeForNavigation = panelPageConsts.engineerModeRequested;
          }
          this.$router.push({ path: item.key, query: { page: 1 } });
          // Check the CWL again to confirm we have the lock.
          resp = (await api.get('/Live/ConfigWriteLock')).data.Live.ConfigWriteLock;
          if (resp.IsActive && resp.IsMine) {
            // We own the CWL.
            // this.setPanelPageModeStatus(panelPageConsts.engineerModeSet);
            this.$gLanModeSetting.panelPageModeForNavigation = panelPageConsts.engineerModeSet;
            this.$gLanModeSetting.lanModeSettingInProgress = false;
          } else {
            // Something went wrong: the CWL is not ours. CWL Alert will handle this condition.
            logger.log('CWL not available.');
          }
          // Clear prevent navigation banner
          this.$gLanModeSetting.panelPageStopNavigationBanner = false;
          this.$gLanModeSetting.currentPageIsPanel = false; // since we've allowed navigation, page is no longer panel page.
        } else {
          // USER mode still not fully set, bring up 'stop navigation' banner
          this.$gLanModeSetting.panelPageStopNavigationBanner = true;
        }
      } else if (this.$gLanModeSetting.currentPageIsPanel === false && item.key === panelPageConsts.panelPageKey) {
        // Is the user navigating back to the panel page when engineer mode is being set?
        if (this.$gLanModeSetting.panelPageModeForNavigation === panelPageConsts.engineerModeSet) {
          // the user is navigating back to the panel page after engineer mode is set
          this.setCommitConfig();
          window.localStorage.setItem('panelPageModeForNavigationOnReload', panelPageConsts.engineerModeSet);
          // Clear the previous list of endpoints
          window.globalThis.currentRequiredEndpoints = [];
          this.$router.push({ path: item.key });
          // Clear prevent navigation banner
          this.$gLanModeSetting.panelPageStopNavigationBanner = false;
        } else {
          // Engineer mode still not fully set, bring up 'stop navigation' banner
          this.$gLanModeSetting.panelPageStopNavigationBanner = true;
        }
      } else if (this.$gLanModeSetting.currentPageIsPanel === true && item.key === panelPageConsts.panelPageKey) {
        // do nothing - avoid redundant navigation error when navigating to the panel page, when already on panel page.
      } else {
        // Allow navigation from one engineering page to another.
        // the user navigation from one main engineering page to another (only the ones on the navigation sidebar)
        this.setCommitConfig();
        // Clear the previous list of endpoints
        const backup = window.globalThis.currentRequiredEndpoints;
        window.globalThis.currentRequiredEndpoints = [];
        this.$router.push({ path: item.key, query: { page: 1 } }).catch(() => {
          // probably navigating to the same page, retain the endpoints, and catch JS navigation exception
          window.globalThis.currentRequiredEndpoints = backup;
        });
        // Retain 'lockLost' knowledge when navigating between engineering pages, except when going back to panel page or signing out
        window.localStorage.setItem('cachedLanLostLockStatus', (this.configWriteLock.lostLock).toString());
        this.$gLanModeSetting.panelPageStopNavigationBanner = false; // no banners
      }
    },
    changeLanguage() {
      this.$i18n.locale = this.language;
      this.languageModalVisible = false;
    },
    async logout() {
      let canLeave;
      await displayFaultsHelper.startExitChecks((anyFaults, errors, arcError) => {
        canLeave = this.modalVisible || anyFaults === false;
        if (!canLeave) {
          this.modalVisible = true;
          this.errors = errors;
          this.arcError = arcError;
        }
      });
      if (!canLeave) return;

      await authService.logout();
      Poller.getInstance().reset();
      setTimeout(() => { window.location.reload(); }, 50);
      this.$router.push({ name: 'login' });
    },
    // Someone pressed the reload button on the browser.
    onPageReload() {
      // cache current page = Engineering page
      window.localStorage.setItem('panelPageModeForNavigationOnReload', panelPageConsts.engineerModeSet);
      // clear lanModeSettingInProgress cached value since page has been reloaded.
      window.localStorage.setItem('cachedLanModeSettingInProgress', 'false');
      // Retain lost lost status when reloading an engineering page.
      window.localStorage.setItem('cachedLanLostLockStatus', (this.configWriteLock.lostLock).toString());
    },
  },
};

export function isSessionLoggedOut() {
  return window.localStorage.getItem('locallyStoreisSessionLoggedOut');
}

export function SetisSessionLoggedOut(value) {
  window.localStorage.setItem('locallyStoreisSessionLoggedOut', value);
}
</script>

<style lang="scss">
    @import 'MainNavigation.scss';
</style>
