import { limits } from '@/app/shared/constants';
import logger from '@/app/shared/services/logger';


export function recordFetched(areas, areaIndex) {
  if (areas !== undefined) {
    if (areas[areaIndex] !== undefined) {
      return (areas[areaIndex].areaText !== null);
    }
  }
  return false;
}

export function recordInvalid(areas, areaIndex) {
  if (areas !== undefined) {
    if (areas[areaIndex] !== undefined) {
      if (areas[areaIndex].$invalid) {
        logger.log('...area invalid', areaIndex, areas[areaIndex]);
        return true;
      }
    }
  }
  return false;
}

export async function loadArea(api, areas, areaIndex) {
  const areaInfo = (await api.get(`/Config/areaInfo/Areas/${areaIndex}`)).data.Config.areaInfo.Areas[areaIndex];
  const sysInfo = (await api.get(`/Config/sysInfo/Volumes/Areas/${areaIndex}`)).data.Config.sysInfo.Volumes.Areas[areaIndex];
  const respInfo = (await api.get(`/Config/Responses/AreaResponse/${areaIndex}`)).data.Config.Responses.AreaResponse[areaIndex];

  const areaData = areas[areaIndex];
  areaData.areaText = areaInfo.Name.replace(/\s+$/, '');
  areaData.restrictionTimer = areaInfo.VoiceRestrictTime_mins;
  areaData.appExit = sysInfo.RemoteExit;
  areaData.alarm = sysInfo.Alarm;
  areaData.fire = sysInfo.Fire;
  areaData.fault = sysInfo.Tamper;
  areaData.twentyFourHour = sysInfo.DayAlarm;
  areaData.chime = sysInfo.Chime;
  for (let j = 0; j < limits.MAX_LEVELS; j += 1) {
    const levelData = areaData.levels[j];
    levelData.levelText = areaInfo.Level[j].Name.replace(/\s+$/, '');
    levelData.timersEntry = areaInfo.Level[j].EntryTime_secs;
    levelData.timersEntry2 = areaInfo.Level[j].EntryTime2_secs;
    levelData.timersExit = areaInfo.Level[j].ExitTime_secs;
    levelData.timersSiren = areaInfo.Level[j].BellTime_mins;
    levelData.timersStrobe = areaInfo.Level[j].StrobeTime_mins;
    levelData.wiredBellSetStrobe = areaInfo.Level[j].WiredBellSetStrobe.toString();
    levelData.wiredBellUnsetStrobe = areaInfo.Level[j].WiredBellUnsetStrobe.toString();
    levelData.wiredBellSetSquawk = areaInfo.Level[j].WiredBellSetSquawk.toString();
    levelData.wiredBellUnsetSquawk = areaInfo.Level[j].WiredBellUnsetSquawk.toString();
    levelData.wirelessBellSetSquawk = areaInfo.Level[j].WirelessBellSetSquawk.toString();
    levelData.wirelessBellUnsetSquawk = areaInfo.Level[j].WirelessBellUnsetSquawk.toString();
    levelData.wirelessBellSetStrobe = areaInfo.Level[j].WirelessBellSetStrobe.toString();
    levelData.wirelessBellUnsetStrobe = areaInfo.Level[j].WirelessBellUnsetStrobe.toString();
    levelData.timersSpeakerTimeMinutes = areaInfo.Level[j].SpeakerTime_mins;
    levelData.timersFirebellTimeMinutes = areaInfo.Level[j].FireBellTime_mins;
    levelData.timersLevelbell = areaInfo.Level[j].LevelBellDelay;
    levelData.timersAppExit = areaInfo.Level[j].RemoteExitTime_secs;
    levelData.timersExitMode = areaInfo.Level[j].ExitModes;

    levelData.volumesEntry = sysInfo.Levels[j].Entry;
    levelData.volumesExit = sysInfo.Levels[j].Exit;

    levelData.responsesStartsAt = respInfo.Levels[j].StartAt;
    levelData.responsesStopsAt = respInfo.Levels[j].EndAt;
  }
}


async function writeAreaDetails(api, form, areaIndexFrom, areaIndexTo) {
  const areaLevelData = [];
  const volumeLevelData = [];
  const responseLevelData = [];
  for (let j = 0; j < limits.MAX_LEVELS; j += 1) {
    const levelData = form.areas[areaIndexFrom].levels[j];
    const alRecord = {
      EntryTime_secs: parseInt(levelData.timersEntry, 10),
      EntryTime2_secs: parseInt(levelData.timersEntry2, 10),
      ExitTime_secs: parseInt(levelData.timersExit, 10),
      BellTime_mins: parseInt(levelData.timersSiren, 10),
      StrobeTime_mins: parseInt(levelData.timersStrobe, 10),
      WiredBellSetStrobe: levelData.wiredBellSetStrobe === 'true',
      WiredBellUnsetStrobe: levelData.wiredBellUnsetStrobe === 'true',
      WiredBellSetSquawk: levelData.wiredBellSetSquawk === 'true',
      WiredBellUnsetSquawk: levelData.wiredBellUnsetSquawk === 'true',
      WirelessBellSetSquawk: levelData.wirelessBellSetSquawk === 'true',
      WirelessBellUnsetSquawk: levelData.wirelessBellUnsetSquawk === 'true',
      WirelessBellSetStrobe: levelData.wirelessBellSetStrobe === 'true',
      WirelessBellUnsetStrobe: levelData.wirelessBellUnsetStrobe === 'true',
      SpeakerTime_mins: parseInt(levelData.timersSpeakerTimeMinutes, 10),
      FireBellTime_mins: parseInt(levelData.timersFirebellTimeMinutes, 10),
      LevelBellDelay: parseInt(levelData.timersLevelbell, 10),
      RemoteExitTime_secs: parseInt(levelData.timersAppExit, 10),
      ExitModes: parseInt(levelData.timersExitMode, 10),
    };
    if (areaIndexTo === areaIndexFrom) { // save
      alRecord.Name = levelData.levelText;
    }
    areaLevelData.push(alRecord);

    volumeLevelData.push({
      Entry: parseInt(levelData.volumesEntry, 10),
      Exit: parseInt(levelData.volumesExit, 10),
    });

    responseLevelData.push({
      StartAt: levelData.responsesStartsAt,
      EndAt: levelData.responsesStopsAt,
    });
  }
  const aRecord = {
    Level: areaLevelData,
    VoiceRestrictTime_mins: parseInt(form.areas[areaIndexFrom].restrictionTimer, 10),
  };
  if (areaIndexTo === areaIndexFrom) { // save
    aRecord.Name = form.areas[areaIndexFrom].areaText;
  }
  const vRecord = {
    Levels: volumeLevelData,
    RemoteExit: parseInt(form.areas[areaIndexFrom].appExit, 10),
    Alarm: parseInt(form.areas[areaIndexFrom].alarm, 10),
    Fire: parseInt(form.areas[areaIndexFrom].fire, 10),
    Tamper: parseInt(form.areas[areaIndexFrom].fault, 10),
    DayAlarm: parseInt(form.areas[areaIndexFrom].twentyFourHour, 10),
    Chime: parseInt(form.areas[areaIndexFrom].chime, 10),
  };
  const rRecord = {
    Levels: responseLevelData,
  };
  await api.put(`/Config/areaInfo/Areas/${areaIndexTo}`, aRecord);
  await api.put(`/Config/sysInfo/Volumes/Areas/${areaIndexTo}`, vRecord);
  await api.put(`/Config/Responses/AreaResponse/${areaIndexTo}`, rRecord);
}

export async function cloneArea(api, form, areaIndexFrom, areaIndexTo) {
  await writeAreaDetails(api, form, areaIndexFrom, areaIndexTo);
}


export async function saveArea(api, form, areaIndex) {
  await writeAreaDetails(api, form, areaIndex, areaIndex);
}
