import outputsList from './pages/outputs-list/outputs-list-data';
import output from './pages/output/output-data';
import addOutput from './shared/components/add-output/add-output-data';
import userDefinedOutputsList from './pages/user-defined-outputs-list/user-defined-outputs-list-data';
import userDefinedOutput from './pages/user-defined-output/user-defined-output-data';
import logicGatesList from './pages/logic-gates-list/logic-gates-list-data';
import logicGate from './pages/logic-gate/logic-gate-data';

export default {
  namespaced: true,
  modules: {
    outputsList,
    output,
    addOutput,
    userDefinedOutputsList,
    userDefinedOutput,
    logicGatesList,
    logicGate,
  },
};
