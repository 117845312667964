import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import { panelState } from './panel';
import { keypadsAndReadersState } from './keypads-and-readers';
import { zoneExpandersState } from './zone-expanders';
import { zonesState } from './zones';
import { outputExpandersState } from './output-expanders';
import { outputsState } from './outputs';
import { usersState } from './users';
import { sirensState } from './sirens';
import { settingsState } from './settings';
import { communicationsState } from './communications';
import { maintenanceState } from './maintenance';
import { historyState } from './history';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  plugins: [vuexLocal.plugin],
  modules: {
    panelState,
    keypadsAndReadersState,
    zoneExpandersState,
    zonesState,
    outputExpandersState,
    outputsState,
    usersState,
    sirensState,
    settingsState,
    communicationsState,
    maintenanceState,
    historyState,
  },
});
