<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('communications.wifi.navigationTitle') }}</h2>
      </a-row>
      <a-form>
        <p-input :label="$t('communications.wifi.WIFI-SSID')" :labelTooltip="$t('communications.wifi.WIFI-SSID-tooltip')" :model="$v.form.ssidName"  display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-password :label="$t('communications.wifi.WIFI-password')" :labelTooltip="$t('communications.wifi.WIFI-password-tooltip')" :model="$v.form.password"  display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('communications.wifi.Network-delay-fault')" :labelTooltip="$t('communications.wifi.Network-delay-fault-tooltip')" :model="$v.form.networkDelayFault"  display-style="small" :disabled="!configWriteLock.canWriteConfig" />
      </a-form>
      <div class="align-right">
         <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
      </div>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />

  </div>
</template>

<script>
import { required, maxLength, between } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'communicationsState/wifi';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
    };
  },
  computed: {
    ...mapState(['form']),
  },
  validations: {
    form: {
      ssidName: { maxLength: maxLength(32) },
      password: { maxLength: maxLength(32) },
      networkDelayFault: { required, between: between(0, 999) },
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
};
</script>
