<template>
     <div class="flex-row">
        <div>
            <font-awesome-icon v-if="titleIcon" :icon="['fal', titleIcon]" />
            <a-tooltip placement="bottom" v-if="tooltip">
              <template slot="title">
                <span>{{tooltip}}</span>
              </template>
              <span :class="{ 'grey-text': disabled }">{{title}}</span>
            </a-tooltip>
            <span v-if="!tooltip" :class="{ 'grey-text': disabled }">{{title}}</span>
        </div>
        <font-awesome-icon v-if="listType.icon" :class="{'fa-spin': listType.isTesting, 'error' : listType.isFailure, 'active': listType.isSuccess}" :icon="['fal', listType.icon]" />
    </div>
</template>
<script>
export default {
  name: 'TextRow',
  props: {
    title: { type: String, default: null },
    titleIcon: { type: String, default: null },
    type: { type: String, default: null },
    tooltip: { type: String, default: null },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    listType() {
      if (this.type === 'testPending') {
        return {
          icon: null,
        };
      }
      if (this.type === 'testing') {
        return {
          icon: 'spinner',
          isTesting: true,
        };
      }
      if (this.type === 'testComplete') {
        return {
          isSuccess: true,
          icon: 'check-circle',
        };
      }
      if (this.type === 'testFailed') {
        return {
          isFailure: true,
          icon: 'times-circle',
        };
      }

      return {
        icon: 'chevron-right',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/base/variables';


.flex-row {
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:1rem;
  div {
    display:flex;
    align-items: center;

    span.grey-text {
      color: $color-disabled-text;
    }

    svg {
      margin-right:1rem;
    }
  }
}

.error {
    color:$error-color;
}

.active {
    color:$success-color;
}
</style>
