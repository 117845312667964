<template>
  <a-form-item :validate-status="model.$error ? 'error': 'success'"
               :label-col="style.labelSize || labelCol"
               :wrapper-col="style.wrapperSize || wrapperCol">
    <template slot="label">
      <a-tooltip v-if="labelTooltip" :title="labelTooltip">
        <label>{{ label }}</label>
      </a-tooltip>
      <label v-else>{{ label }}</label>
    </template>
    <area-selector v-model="model.$model" :singleCheck="this.singleCheck" :areasInfo="this.areasInfo" :areasConfig="this.areasConfig" :is-alternative="style.isAlternative" :disabled="disabled" @singleCheckedArea="singleCheckedArea" />
    <div class="ant-form-explain" v-if="model.$error && !model.required">{{
      $t('common.Field-is-required') }}
    </div>
  </a-form-item>
</template>

<script>
import AreaSelector from '@/app/shared/components/area-selector/area-selector.vue';

export default {
  components: {
    AreaSelector,
  },
  props: ['label', 'labelTooltip', 'model', 'label-col', 'wrapper-col', 'singleCheck', 'areasInfo', 'areasConfig', 'displayStyle', 'disabled'],
  data() {
    if (this.displayStyle === 'small-alternative') {
      return {
        style: {
          isAlternative: true,
          labelSize: {
            sm: { span: 12 }, md: { span: 8 }, lg: { span: 6 }, xl: { span: 5 },
          },
          wrapperSize: {
            sm: { span: 24 }, md: { span: 18 }, lg: { span: 18 }, xl: { span: 18 },
          },
        },
      };
    }
    if (this.displayStyle === 'small') {
      return {
        style: {
          isAlternative: false,
          labelSize: {
            sm: { span: 12 }, md: { span: 8 }, lg: { span: 6 }, xl: { span: 5 },
          },
          wrapperSize: {
            sm: { span: 24 }, md: { span: 18 }, lg: { span: 18 }, xl: { span: 18 },
          },
        },
      };
    }
    return {
      style: {
        isAlternative: true,
      },
    };
  },
  methods: {
    singleCheckedArea(areaIndex) {
      // Just forward on the event from the internal component.
      this.$emit('singleCheckedArea', areaIndex);
    },
  },
};
</script>

<style lang="scss">
</style>
