import { createArray } from '@/app/shared/services/api';
import { storeFactory } from '@/app/shared/services/store-helper';
import { limits } from '@/app/shared/constants';

function initialState() {
  return {
    _items: createArray(limits.MAX_ES_ZONES, () => ({ managerOmitted: false })),
    installed: undefined,
    maximum: undefined,
    endstation: undefined,
  };
}

const { store } = storeFactory(initialState, {
  getters: {
    items: state => state._items.filter(i => i.visible),
  },
  actions: {
    requiredEndpoints: () => ['infoZoneInfo', 'configZoneInfoES', 'infoEndstation', 'liveZoneInfoES'],
    async populate(context, { endpoints }) {
      context.commit('set', {
        maximum: endpoints.infoZoneInfo.data.Info.zoneInfo.EndstationZoneMaxCount,
        installed: endpoints.infoZoneInfo.data.Info.zoneInfo.EndstationZoneInstalledCount,
        endstation: true,
      });
      for (let i = 0; i < limits.MAX_ES_ZONES; i += 1) {
        const zoneType = endpoints.configZoneInfoES.data.Config.zoneInfo.Zones[i].ZoneType;
        context.commit('setItemByIndex', {
          collection: '_items',
          index: i,
          item: {
            visible: zoneType !== 'ISOLATED',
            zoneType,
            number: endpoints.infoEndstation.data.Info.Endstation.AssociatedZones[i].Number,
            name: endpoints.configZoneInfoES.data.Config.zoneInfo.Zones[i].Name,
            omittable: endpoints.liveZoneInfoES.data.Live.zoneInfo.Zones[i].Omittable,
          },
        });
      }

      await context.dispatch('onPoll', { key: 'liveZoneInfoES', endpoint: endpoints.liveZoneInfoES });
    },
    async onPoll(context, { dataStatus, key, endpoint }) {
      if (key === 'liveZoneInfoES') {
        const items = [];
        for (let i = 0; i < limits.MAX_ES_ZONES; i += 1) {
          items.push({
            state: endpoint.data.Live.zoneInfo.Zones[i].state,
            omittable: endpoint.data.Live.zoneInfo.Zones[i].Omittable,
            forceOmitted: endpoint.data.Live.zoneInfo.Zones[i].ForceOmitted,
            setOmitted: endpoint.data.Live.zoneInfo.Zones[i].SetOmitted,
            managerOmitted: endpoint.data.Live.zoneInfo.Zones[i].ManagerOmitted,
          });
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: '_items', items });
      }
    },
  },
});

export default store;
