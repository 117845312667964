<template>
  <!-- The modal subcomponent is by default enabled for being closed in 3 ways: clicking 'x' in the header; clicking outside of the popup window; pressing 'Esc' key on keyboard -->
  <!-- 'closable' controls the display of the 'x'; 'keyboard' controls data from keyboard; 'maskClosable' controls whether the pop up -->
    <a-modal :title="$t('common.You-have-unsaved-changes')" :closable="false" :keyboard="false" :maskClosable="false" v-model="visible">
        <p>{{ $t('common.Do-you-want-to-save-the-changes-made') }}</p>
        <template slot="footer">
            <a-row :gutter="8">
                <a-col :span="12" style="text-align: left;">
                    <a-button type="link" @click="dontSave">{{ $t('common.Dont-save') }}</a-button>
                </a-col>
                <a-col :span="12">
                    <a-button type="link" @click="cancel">{{ $t('common.Cancel') }}</a-button>
                    <a-button type="primary" @click="save">{{ $t('common.Save') }}</a-button>
                </a-col>
            </a-row>
        </template>
    </a-modal>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props: {
    visible: { default: false },
    to: { default: null },
  },
  methods: {
    async save() {
      this.$router.push(this.to);
      this.$emit('save');
    },
    dontSave() {
      this.$router.push(this.to);
    },
    cancel() {
      this.$emit('cancel');
    },
  },

};
</script>

<style lang="scss">
</style>
