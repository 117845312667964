import { API } from '@/app/shared/services/api';
import { AutoScanFailed } from '@/app/shared/errors';
/* eslint-disable no-return-assign */
// Required endpoint for bus autoscan
const busAutoscanStateEndpoint = '/Live/Devices/Autoscan/Bus/BusAutoscanState';
const api = new API();

function dropPolledEndpoint() {
  // Remove the bus autoscan endpoint
  try {
    const temp = window.globalThis.currentRequiredEndpoints;
    window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== busAutoscanStateEndpoint);
  } catch (err) {
    // Nothing to do
  }
}

async function scan(options) {
  // eslint-disable-next-line no-param-reassign
  options = options || {};

  options.keypads = options.keypads === undefined ? true : options.keypads;
  options.readers = options.readers === undefined ? true : options.readers;
  options.outputExpanders = options.outputExpanders === undefined ? true : options.outputExpanders;
  options.zoneExpanders = options.zoneExpanders === undefined ? true : options.zoneExpanders;

  await api.put('/Live/Devices/Autoscan/Bus/BusAutoscan', true);

  let scanFailed = false;
  // Bring the ad-hoc bus autoscan endpoint into the scope of current endpoints
  if (window.globalThis.currentRequiredEndpoints.indexOf(busAutoscanStateEndpoint) === -1) {
    window.globalThis.currentRequiredEndpoints.push(busAutoscanStateEndpoint);
  }

  await new Promise(((resolve) => {
    api.poll(1000, busAutoscanStateEndpoint, (res) => {
      const status = res.data.Live.Devices.Autoscan.Bus.BusAutoscanState;
      if (status === ' SCAN_FAILED') {
        scanFailed = true;
        resolve();
        dropPolledEndpoint();
        return false; // do not keep polling
      }
      if (status !== 'SCAN_COMPLETE') {
        return true;
      }
      resolve();
      dropPolledEndpoint();
      return false; // do not keep polling
    });
  }));

  api.cancelPolling();
  await api.put('/Live/Devices/Autoscan/Bus/BusAutoscan', false);

  if (scanFailed) {
    throw new AutoScanFailed();
  }

  let keypadsFound = 0;
  let readersFound = 0;
  let zoneExpandersFound = 0;
  let outputExpandersFound = 0;

  if (options.keypads) {
    await api.get('/Live/Devices/Autoscan/Bus/BusKeypadsDetected')
      .then(results => keypadsFound = results.data.Live.Devices.Autoscan.Bus.BusKeypadsDetected);
  }

  if (options.readers) {
    await api.get('/Live/Devices/Autoscan/Bus/BusReadersDetected')
      .then(results => readersFound = results.data.Live.Devices.Autoscan.Bus.BusReadersDetected);
  }

  if (options.zoneExpanders) {
    await api.get('/Live/Devices/Autoscan/Bus/BusZoneExpandersDetected')
      .then(results => zoneExpandersFound = results.data.Live.Devices.Autoscan.Bus.BusZoneExpandersDetected);
  }

  if (options.outputExpanders) {
    await api.get('/Live/Devices/Autoscan/Bus/BusOutputExpandersDetected')
      .then(results => outputExpandersFound = results.data.Live.Devices.Autoscan.Bus.BusOutputExpandersDetected);
  }

  dropPolledEndpoint();

  return {
    keypadsFound,
    readersFound,
    zoneExpandersFound,
    outputExpandersFound,
    totalDevicesFound: keypadsFound + readersFound + zoneExpandersFound + outputExpandersFound,
  };
}

async function cancel() {
  api.cancelPolling();
  await api.put('/Live/Devices/Autoscan/Bus/BusAutoscan', false);
  // Drop bus autoscan specific endpoints from the current context
  dropPolledEndpoint();
}

export default {
  scan, cancel,
};
