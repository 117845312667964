import Vue from 'vue';

// This mixin fixes following issue:
// errorHandler does not work with async component methods
// https://github.com/vuejs/vue/issues/7653

async function propagateErrorCaptured(component, error, vm) {
  let continuePropagation = true;
  const ec = component.$options.errorCaptured;
  if (ec instanceof Array) {
    for (let i = 0; i < ec.length; i += 1) {
      continuePropagation = ec[i].apply(component, [error, vm]);
      if (typeof continuePropagation === 'object' && typeof continuePropagation.then === 'function') {
        // wait for the promise
        continuePropagation = await continuePropagation; // eslint-disable-line no-await-in-loop
      }
      if (!continuePropagation) break;
    }
  }
  if (component.$parent && continuePropagation) {
    return await propagateErrorCaptured(component.$parent, error, vm); // eslint-disable-line no-return-await
  }
  return continuePropagation;
}

export default {
  beforeCreate() {
    const methods = this.$options.methods || {};
    Object.entries(methods).forEach(([key, method]) => {
      const wrappedMethod = function wrappedMethod(...args) {
        const result = method.apply(this, args);
        const resultIsPromise = result && typeof result.then === 'function';
        if (!resultIsPromise) return result;
        return new Promise(async (resolve, reject) => {
          try {
            resolve(await result);
          } catch (error) {
            const continuePropagation = await propagateErrorCaptured(this, error, this);
            if (!continuePropagation) {
              if (Vue.config.errorHandler) {
                Vue.config.errorHandler(error, this);
              } else {
                reject(error);
              }
            }
          }
        });
      };
      methods[key] = wrappedMethod;
    });
  },
};
