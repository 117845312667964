import i18n from '@/app/shared/services/i18n';
import { KEYPAD_AND_READER_TYPE } from '@/app/keypads-and-readers/shared/enums';
import { limits } from '@/app/shared/constants';
import {
  ZONE_TYPE,
} from '@/app/zones/shared/enums';

// Requires endpoints: configZoneInfoParts, infoZoneInfo
export function getZoneSelectList(configZoneInfoParts, infoZoneInfo) {
  const zones = [];
  configZoneInfoParts.data.Config.zoneInfo.Zones.forEach((z, i) => {
    if (z.ZoneType !== 'ISOLATED') {
      const number = infoZoneInfo.data.Info.zoneInfo.Zones[i].Number;
      const zoneName = configZoneInfoParts.data.Config.zoneInfo.Zones[i].Name;
      zones.push({
        value: i, name: `${number} - ${zoneName}`,
      });
    }
  });

  return zones;
}

export function canBeOmitted(zoneType) {
  const noByPass = [ZONE_TYPE.GAS, ZONE_TYPE.PA, ZONE_TYPE.SILENT_PA];
  return !noByPass.includes(zoneType);
}

export const DEVICE_TYPE = {
  ENDSTATION: 1,
  KEYPAD: 2,
  READER: 3,
  WIRED_HUB: 4,
  WIRELESS_HUB: 5,
};

export function generateAssociatedWithName(type, deviceIndex) {
  return i18n.t(`enums.ASSOCIATED_WITH.${type}`, { number: deviceIndex });
}

export function getAssociatedWith(associatedWith) {
  return {
    name: generateAssociatedWithName(associatedWith.DeviceType, associatedWith.DeviceNumber),
    type: associatedWith.DeviceType,
    number: associatedWith.DeviceNumber,
    zoneIndex: associatedWith.ZoneIndex,
  };
}

// Requires endpoints: configBusDevices configKeypadInfo configZoneInfoParts infoBusDevices infoKeypadInfo
export function getDevices(currentDeviceType, currentDeviceNumber, currentZoneIndex, endpoints) {
  const devices = [];
  const endstationAvailableZones = [];
  for (let i = 0; i < limits.MAX_ES_ZONES; i += 1) {
    const zoneType = endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[i].ZoneType;
    if (zoneType === 'ISOLATED' || (currentDeviceType === 'ENDSTATION' && currentZoneIndex === i)) {
      endstationAvailableZones.push(i);
    }
  }

  devices.push({
    key: 'ENDSTATION_0',
    type: 'ENDSTATION',
    name: generateAssociatedWithName('ENDSTATION'),
    availableZonesIndexes: endstationAvailableZones,
  });
  for (let i = 0; i < limits.MAX_WIRED_ZEM; i += 1) {
    if (endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[i].Installed) {
      const availableZonesIndexes = [];

      for (let x = 0; x < limits.MAX_ES_ZONES; x += 1) {
        const number = endpoints.infoBusDevices.data.Info.BusDevices.wiredHubs[i].AssociatedZones[x].Number;
        if (endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[number - 1].ZoneType === 'ISOLATED' || (currentDeviceType === 'WIRED_HUB' && currentDeviceNumber === i && currentZoneIndex === number - 1)) {
          availableZonesIndexes.push(number - 1);
        }
      }

      devices.push({
        key: `WIRED_HUB_${i}`,
        type: 'WIRED_HUB',
        name: generateAssociatedWithName('WIRED_HUB', i),
        availableZonesIndexes,
      });
    }
  }
  for (let i = 0; i < limits.MAX_WE_ZEM; i += 1) {
    if (endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[i].Installed) {
      const availableZonesIndexes = [];

      endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[i].ActiveInputs.forEach((activeInput, index) => {
        if (activeInput) {
          for (let x = index * 8; x < (index + 1) * 8; x += 1) { // number of wireless zones per ZEM address
            const number = endpoints.infoBusDevices.data.Info.BusDevices.wirelessHubs[i].AssociatedZones[x].Number;
            if (endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[number - 1].ZoneType === 'ISOLATED' || (currentDeviceType === 'WIRELESS_HUB' && currentDeviceNumber === i && currentZoneIndex === number - 1)) {
              availableZonesIndexes.push(number - 1);
            }
          }
        }
      });

      devices.push({
        key: `WIRELESS_HUB_${i}`,
        type: 'WIRELESS_HUB',
        name: generateAssociatedWithName('WIRELESS_HUB', i),
        availableZonesIndexes,
      });
    }
  }
  for (let i = 0; i < limits.MAX_KEYPADS; i += 1) {
    const availableZonesIndexes = [];
    const type = KEYPAD_AND_READER_TYPE.fromInt(endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[i].Type);

    if (type === KEYPAD_AND_READER_TYPE.TYPE_READER && i < 16) {
      for (let x = 0; x < 2; x += 1) {
        if (endpoints.infoKeypadInfo.data.Info.keypadInfo.Keypads[i].AssociatedZones) {
          const number = endpoints.infoKeypadInfo.data.Info.keypadInfo.Keypads[i].AssociatedZones[x].Number;
          if (endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[number - 1].ZoneType === 'ISOLATED' || (currentDeviceType === 'READER' && currentDeviceNumber === i && currentZoneIndex === number - 1)) {
            availableZonesIndexes.push(number - 1);
          }
        }
      }

      devices.push({
        key: `READER_${i}`,
        type: 'READER',
        name: generateAssociatedWithName('READER', i),
        availableZonesIndexes,
      });
    }

    if (type === KEYPAD_AND_READER_TYPE.TYPE_KEYPAD && i < 16) {
      for (let x = 0; x < 2; x += 1) {
        if (endpoints.infoKeypadInfo.data.Info.keypadInfo.Keypads[i].AssociatedZones) {
          const number = endpoints.infoKeypadInfo.data.Info.keypadInfo.Keypads[i].AssociatedZones[x].Number;
          if (endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[number - 1].ZoneType === 'ISOLATED' || (currentDeviceType === 'KEYPAD' && currentDeviceNumber === i && currentZoneIndex === number - 1)) {
            availableZonesIndexes.push(number - 1);
          }
        }
      }

      devices.push({
        key: `KEYPAD_${i}`,
        type: 'KEYPAD',
        name: generateAssociatedWithName('KEYPAD', i),
        availableZonesIndexes,
      });
    }
  }

  return devices;
}
