import walkTest from './shared/components/walk-test/walk-test-data';
import soakTest from './shared/components/soak-test/soak-test-data';
import pgmTest from './shared/components/pgm-test/pgm-test-data';
import arcCommsTest from './shared/components/arc-comms-test/arc-comms-test-data';

export default {
  namespaced: true,
  modules: {
    walkTest,
    soakTest,
    pgmTest,
    arcCommsTest,
  },
};
