import { Loader } from '@/app/shared/services/generic-loader';

const CloneMixin = {
  data() {
    return {
      clone: {
        _promise: new Promise((resolve) => {
          this._loaderFinishedResolve = resolve;
        }),
        type: Loader,
        instance: null,
        storeNamespace: null,
      },
    };
  },
  props: {
    visible: { default: false },
    clonePayload: { default: null },
    navigateToNewItem: { default: false },
  },
  methods: {
    _setupClone() {
      this.clone.instance = this.clone.type.getInstance('clone', this.$store);
      this.clone.instance.populate(this.clone.storeNamespace, this.clonePayload, () => {
        this._loaderFinishedResolve();
        this.$v.$reset();
      });
    },
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this._setupClone();
      } else if (this.clone.instance) {
        this.clone.instance.unload(this.clone.storeNamespace);
      }
    },
  },
  created() {
    if (this.clone.instance) this.clone.instance.unload(this.clone.storeNamespace);
    this._setupClone();
  },
  beforeDestroy() {
    if (this.clone.instance) {
      this.clone.instance.unload(this.clone.storeNamespace);
    }
  },
};

export default CloneMixin;
