import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';


function initialState() {
  return {
    form: {
      fire: {
        startAt: undefined,
        stopAt: undefined,
      },
      gas: {
        startAt: undefined,
        stopAt: undefined,
      },
      twentyFourHourAlarm: {
        startAt: undefined,
        stopAt: undefined,
      },
      medical: {
        startAt: undefined,
        stopAt: undefined,
      },
      pa: {
        startAt: undefined,
        stopAt: undefined,
      },
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configResponses'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        fire: {
          startAt: endpoints.configResponses.data.Config.Responses.GeneralResponse.Fire.StartAt,
          stopAt: endpoints.configResponses.data.Config.Responses.GeneralResponse.Fire.EndAt,
        },
        gas: {
          startAt: endpoints.configResponses.data.Config.Responses.GeneralResponse.Gas.StartAt,
          stopAt: endpoints.configResponses.data.Config.Responses.GeneralResponse.Gas.EndAt,
        },
        twentyFourHourAlarm: {
          startAt: endpoints.configResponses.data.Config.Responses.GeneralResponse.DayAlarm.StartAt,
          stopAt: endpoints.configResponses.data.Config.Responses.GeneralResponse.DayAlarm.EndAt,
        },
        medical: {
          startAt: endpoints.configResponses.data.Config.Responses.GeneralResponse.Medic.StartAt,
          stopAt: endpoints.configResponses.data.Config.Responses.GeneralResponse.Medic.EndAt,
        },
        pa: {
          startAt: endpoints.configResponses.data.Config.Responses.GeneralResponse.HoldUp.StartAt,
          stopAt: endpoints.configResponses.data.Config.Responses.GeneralResponse.HoldUp.EndAt,
        },
      });
    },
    async save(context) {
      const data = {
        Fire: { StartAt: context.state.form.fire.startAt, EndAt: context.state.form.fire.stopAt },
        Gas: { StartAt: context.state.form.gas.startAt, EndAt: context.state.form.gas.stopAt },
        DayAlarm: { StartAt: context.state.form.twentyFourHourAlarm.startAt, EndAt: context.state.form.twentyFourHourAlarm.stopAt },
        Medic: { StartAt: context.state.form.medical.startAt, EndAt: context.state.form.medical.stopAt },
        HoldUp: { StartAt: context.state.form.pa.startAt, EndAt: context.state.form.pa.stopAt },
      };
      await api.put('/Config/Responses/GeneralResponse', data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('settingsState/generalResponses/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['settingsState/generalResponses'] });
    },
  },
});


export default store;
