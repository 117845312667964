import i18n from '@/app/shared/services/i18n';

class TooltipHelper {
  toggleTooltipText(field, enabledText, disabledText) {
    return this.field && field.$model
      ? enabledText || i18n.t('common.OptionEnabledTooltip')
      : disabledText || i18n.t('common.OptionDisabledTooltip');
  }
}

const helper = new TooltipHelper();

export default helper;
