import { createArray } from '@/app/shared/services/api';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { trim } from '@/app/shared/utils/text-helper';
import { toDaysHoursMinutes, toMinutes } from '@/app/shared/utils/date-helper';
import { BUGLARY_ALARM_OPTIONS, TAMPER_OPTIONS } from '@/app/shared/enums';

function initialState() {
  return {
    form: {
      systemName: undefined,
      strategy: undefined,
      acknowledgement: undefined,
      voiceRestrictTimeMinutes: undefined,
      areasInfo: undefined,
      areasConfig: undefined,
      items: createArray(10, () => ({
        areas: undefined,
        redials: undefined,
        timeout: undefined,
        testCalls: undefined,

        eventTypes: undefined,
        userAcknowledgeCode: undefined,
        arm: undefined,
        disarm: undefined,
        specialArmDisarm: undefined,
        subAreaSHArm: undefined,
        subAreaSHDisarm: undefined,
        burglaryAlarm: undefined,
        burglaryRestore: undefined,
        fire: undefined,
        fireRestore: undefined,
        paAlarm: undefined,
        paRestore: undefined,
        medical: undefined,
        medicalRestore: undefined,
        tamper: undefined,
        tamperRestore: undefined,
        bypass: undefined,
        bypassRestore: undefined,
        technical: undefined,
        technicalRestore: undefined,
        acFaultRestore: undefined,
        wirelessFaults: undefined,
        telecomStatus: undefined,
        accessControl: undefined,
        maskRestore: undefined,
        specialLog: undefined,
        alarmSilenced: undefined,
        techAlarmSilence: undefined,
        information: undefined,
      })),
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configCommsInfo', 'infoAreaInfo', 'configAreaInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        systemName: endpoints.configCommsInfo.data.Config.commsInfo.VOICESystemName,
        strategy: endpoints.configCommsInfo.data.Config.commsInfo.VoiceStrategy,
        acknowledgement: endpoints.configCommsInfo.data.Config.commsInfo.VoiceACK,
        voiceRestrictTimeMinutes: endpoints.configAreaInfo.data.Config.areaInfo.Areas[0].VoiceRestrictTime_mins,
        areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
        areasConfig: endpoints.configAreaInfo.data.Config.areaInfo,
        items: endpoints.configCommsInfo.data.Config.commsInfo.VOICEDetails.map(s => ({
          phoneNumber: trim(s.FirstVoiceNumber),
          areas: s.ValidAreaBits,
          redials: s.Redials,
          timeout: s.DialTimeOut_secs,
          testCalls: s.Options === 'TIMED',
          intervalDays: toDaysHoursMinutes(s.Interval).days,
          intervalHours: toDaysHoursMinutes(s.Interval).hours,
          intervalMinutes: toDaysHoursMinutes(s.Interval).minutes,
          testHours: s.TestHours,
          testMinutes: s.TestMinutes,
          userAcknowledgeCode: trim(s.AckVoiceNumber),

          eventTypes: s.EventTypesDetails,
          arm: s.ChannelEnable[0],
          disarm: s.ChannelEnable[1],
          specialArmDisarm: s.ChannelEnable[2],
          subAreaSHArm: s.ChannelEnable[3],
          subAreaSHDisarm: s.ChannelEnable[4],
          burglaryAlarm: BUGLARY_ALARM_OPTIONS.fromValues(s.ChannelEnable[6], s.ChannelEnable[7]),
          burglaryRestore: s.ChannelEnable[8],
          fire: s.ChannelEnable[9],
          fireRestore: s.ChannelEnable[10],
          paAlarm: s.ChannelEnable[11],
          paRestore: s.ChannelEnable[12],
          medical: s.ChannelEnable[13],
          medicalRestore: s.ChannelEnable[14],
          tamper: TAMPER_OPTIONS.fromValues(s.ChannelRestore[0], s.ChannelEnable[5]),
          tamperRestore: s.ChannelRestore[1],
          bypass: s.ChannelRestore[2],
          bypassRestore: s.ChannelRestore[3],
          technical: s.ChannelRestore[4],
          technicalRestore: s.ChannelRestore[5],
          acFaultRestore: s.ChannelRestore[6],
          wirelessFaults: s.ChannelRestore[7],
          telecomStatus: s.ChannelRestore[8],
          accessControl: s.ChannelRestore[9],
          maskRestore: s.ChannelRestore[10],
          specialLog: s.ChannelRestore[11],
          alarmSilenced: s.ChannelRestore[12],
          techAlarmSilence: s.ChannelRestore[13],
          information: s.ChannelRestore[14],
        })),
      });
    },
    async save(context) {
      const details = [];
      for (let i = 0; i < context.state.form.items.length; i += 1) {
        details[i] = {};
        details[i].FirstVoiceNumber = context.state.form.items[i].phoneNumber;
        details[i].ValidAreaBits = context.state.form.items[i].areas;
        details[i].EventTypesDetails = context.state.form.items[i].eventTypes;
        details[i].Redials = parseInt(context.state.form.items[i].redials, 10);
        details[i].DialTimeOut_secs = parseInt(context.state.form.items[i].timeout, 10);
        details[i].Options = context.state.form.items[i].testCalls ? 'TIMED' : 'NONE';
        details[i].Interval = context.state.form.items[i].testCalls ? toMinutes(context.state.form.items[i].intervalDays, context.state.form.items[i].intervalHours, context.state.form.items[i].intervalMinutes) : 1440;
        details[i].TestHours = context.state.form.items[i].testHours != null ? parseInt(context.state.form.items[i].testHours, 10) : 0;
        details[i].TestMinutes = context.state.form.items[i].testMinutes != null ? parseInt(context.state.form.items[i].testMinutes, 10) : 0;
        details[i].AckVoiceNumber = context.state.form.items[i].userAcknowledgeCode;

        if (context.state.form.items[i].eventTypes === 'CUSTOM') {
          details[i].ChannelEnable = [];
          details[i].ChannelRestore = [];
          details[i].ChannelEnable[0] = context.state.form.items[i].arm;
          details[i].ChannelEnable[1] = context.state.form.items[i].disarm;
          details[i].ChannelEnable[2] = context.state.form.items[i].specialArmDisarm;
          details[i].ChannelEnable[3] = context.state.form.items[i].subAreaSHArm;
          details[i].ChannelEnable[4] = context.state.form.items[i].subAreaSHDisarm;
          details[i].ChannelEnable[6] = context.state.form.items[i].burglaryAlarm === 'ALARM_ALL';
          details[i].ChannelEnable[7] = context.state.form.items[i].burglaryAlarm === 'ALARM_ONCE';
          details[i].ChannelEnable[8] = context.state.form.items[i].burglaryRestore;
          details[i].ChannelEnable[9] = context.state.form.items[i].fire;
          details[i].ChannelEnable[10] = context.state.form.items[i].fireRestore;
          details[i].ChannelEnable[11] = context.state.form.items[i].paAlarm;
          details[i].ChannelEnable[12] = context.state.form.items[i].paRestore;
          details[i].ChannelEnable[13] = context.state.form.items[i].medical;
          details[i].ChannelEnable[14] = context.state.form.items[i].medicalRestore;
          details[i].ChannelRestore[0] = context.state.form.items[i].tamper === 'TAMPER_ALL';
          details[i].ChannelEnable[5] = context.state.form.items[i].tamper === 'TAMPER_ONCE';
          details[i].ChannelRestore[1] = context.state.form.items[i].tamperRestore;
          details[i].ChannelRestore[2] = context.state.form.items[i].bypass;
          details[i].ChannelRestore[3] = context.state.form.items[i].bypassRestore;
          details[i].ChannelRestore[4] = context.state.form.items[i].technical;
          details[i].ChannelRestore[5] = context.state.form.items[i].technicalRestore;
          details[i].ChannelRestore[6] = context.state.form.items[i].acFaultRestore;
          details[i].ChannelRestore[7] = context.state.form.items[i].wirelessFaults;
          details[i].ChannelRestore[8] = context.state.form.items[i].telecomStatus;
          details[i].ChannelRestore[9] = context.state.form.items[i].accessControl;
          details[i].ChannelRestore[10] = context.state.form.items[i].maskRestore;
          details[i].ChannelRestore[11] = context.state.form.items[i].specialLog;
          details[i].ChannelRestore[12] = context.state.form.items[i].alarmSilenced;
          details[i].ChannelRestore[13] = context.state.form.items[i].techAlarmSilence;
          details[i].ChannelRestore[14] = context.state.form.items[i].information;
        }
      }

      const areas = context.state.form.items.map(() => ({
        VoiceRestrictTime_mins: parseInt(context.state.form.voiceRestrictTimeMinutes, 10),
      }));
      await api.put('/Config/areaInfo/Areas', areas);
      await api.put('/Config/commsInfo', {
        VOICESystemName: context.state.form.systemName,
        VoiceStrategy: context.state.form.strategy,
        VoiceACK: parseInt(context.state.form.acknowledgement, 10),
        VOICEDetails: details,
      });

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('communicationsState/voice/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['communicationsState/voice'] });
    },
  },
});


export default store;
