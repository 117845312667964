import { getField } from 'vuex-map-fields';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { limits } from '@/app/shared/constants';


function initialState() {
  return {
    form: {
      address: undefined,
      location: undefined,
    },
    availableAddresses: [],
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {
    getField,
  },
  actions: {
    requiredEndpoints() {
      return ['configBusDevices'];
    },
    async populate(context, { endpoints, payload }) {
      if (payload != null && payload.index !== null) {
        context.commit('setForm', {
          location: endpoints.configBusDevices.data.Config.BusDevices.OutputDevices[payload.index].Location,
        });
      }
      const availableAddresses = [];
      for (let i = 0; i < limits.MAX_ROX; i += 1) {
        if (!endpoints.configBusDevices.data.Config.BusDevices.OutputDevices[i].Installed) {
          availableAddresses.push(i);
        }
      }

      context.commit('set', { availableAddresses });
    },
    async add(context) {
      const index = context.state.form.address;
      const response = await api.get(`/Config/BusDevices/OutputDevices/${index}`);
      const data = response.data.Config.BusDevices.OutputDevices[index];
      data.Location = context.state.form.location;
      data.Installed = true;
      await api.put(`/Config/BusDevices/OutputDevices/${index}`, data);

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('outputExpandersState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('outputExpandersState/addOutputExpander/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['outputExpandersState/list'] });
    },
  },
});

export default store;
