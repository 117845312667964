<template>
   <three-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
            <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
                <h1 id="title">{{ name }}</h1>
            </a-skeleton>
        </template>
        <template v-slot:titleCommands>
            <item-menu
              @save="saveForm" :isSaving="isSaving"
              :canDelete="false"
              :hasToggle="true"
              :toggled="state"
              :toggleTooltip="isEnabledTooltip(state)"
              @toggle="activateOutput"
              :disabled="!configWriteLock.canWriteConfig" />
        </template>

        <diagnostics :globalState="state ? 'OK' : 'ERROR'" :items="diagnostics" :isLoading="false" :options="diagnosticOptions" />

        <form>
          <div class="styled-section">
            <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
              <div>
                <h3 class="h4">{{ $t('userDefinedOutputs.Output-information') }}</h3>
                <a-row :gutter="50">
                  <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                    <p-input :label="$t('common.Name')" :model="$v.form.name" :disabled="!configWriteLock.canWriteConfig" />
                  </a-col>
                  <a-col class="gutter-row" :xs="{ span: 12}" :lg="{ span: 4}" >
                    <p-select :label="$t('userDefinedOutputs.Output-type')" :model="$v.form.outputType" :items="USER_DEFINED_OUTPUT_TYPE.values" :valueExpr="(i) => i.value" :textExpr="(i) => $t(`userDefinedOutputs.enums.outputType.${i.key}`)"
                      :disabled="!configWriteLock.canWriteConfig" />
                  </a-col>
                  <a-col class="gutter-row" :xs="{ span: 16}" :lg="{ span: 5}" >
                    <p-input v-if="$v.form.outputType.$model === USER_DEFINED_OUTPUT_TYPE.USER_OUTPUT_PULSED.value"
                      :label="$t('userDefinedOutputs.Output-on-time')" :model="$v.form.outputOnTime" :disabled="!configWriteLock.canWriteConfig" />
                  </a-col>
                  <a-col class="gutter-row" :xs="{ span: 12}" :lg="{ span: 4}" >
                    <p-readonly :label="$t('common.Number')" :value="$v.form.number.$model" />
                  </a-col>
                </a-row>
              </div>
            </a-skeleton>
          </div>

           <associated-outputs :isLoading="isLoading" :items="associatedOutputs" @activate="activateOutput" />

        </form>
        <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
          @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
   </three-col-layout>
</template>

<script>
import { required, between, maxLength } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import AssociatedOutputs from '@/app/shared/components/associated-outputs/associated-outputs.vue';
import ItemMenu from '@/app/shared/components/item-menu/item-menu.vue';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import { USER_DEFINED_OUTPUT_TYPE } from '@/app/outputs/shared/enums';
import { USER_DEFINED_OUTPUT_BEGIN } from '@/app/outputs/shared/services/helpers';
import outputHelper from '@/app/shared/services/output-helper';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

const storeNamespace = 'outputsState/userDefinedOutput';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);


export default {
  mixins: [ConfigWriteLock, UnsavedGuard, StateLoader, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    AssociatedOutputs,
    ItemMenu,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      intervalID: 0,
      addDrawerVisible: false,
      isSaving: false,
      USER_DEFINED_OUTPUT_TYPE,
      diagnosticOptions: {
        statusType(diagnostic) {
          if (diagnostic.key === 'output.Diagnostics.Status') {
            return diagnostic.value === 'OFF' ? null : 'active';
          }

          return diagnostic.value === 'FAULT' || !diagnostic.value ? 'error' : 'active';
        },
        globalState(fault) {
          return fault ? 'loading' : 'active';
        },
      },
    };
  },
  validations: {
    form: {
      name: {
        maxLength: maxLength(16),
      },
      outputType: {
        required,
      },
      outputOnTime: {
        required,
        between: between(0, 250),
      },
      number: {
        required,
      },
    },
  },
  computed: {
    ...mapState(['form', 'name', 'state', 'diagnostics', 'associatedOutputs']),
    ...mapGetters(['isLoading']),
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },

    startCountdown(timer, callback) {
      let initialValue = timer;
      this.intervalID = setInterval(() => {
        initialValue -= 1;
        if (initialValue <= 0) {
          clearInterval(this.intervalID);
          callback();
        }
      }, 1000);
    },

    async activateOutput() {
      const record = this.$v.form.$model;
      const outputNumber = parseInt(record.index, 10) + USER_DEFINED_OUTPUT_BEGIN;
      await outputHelper.activateOutputType(outputNumber, !this.state, this.loader.instance);

      if (record.outputType === USER_DEFINED_OUTPUT_TYPE.USER_OUTPUT_PULSED.value) {
        if (!this.state === true) {
          this.startCountdown(record.outputOnTime, async () => {
            await outputHelper.activateOutputType(outputNumber, !this.state, this.loader.instance);
          });
        }
      } else {
        clearInterval(this.intervalID);
      }
    },

    isEnabledTooltip(isEnabled) {
      return isEnabled
        ? this.$t('output.tooltips.outputEnabled')
        : this.$t('output.tooltips.outputDisabled');
    },
  },
};
</script>

<style lang="scss">

</style>
