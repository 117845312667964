import moment from 'moment';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';


function initialState() {
  return {
    form: {
      timezone: undefined,
      date: undefined,
      time: undefined,
      timePrompt: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configSysInfo', 'livePanelStatus'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        timezone: endpoints.configSysInfo.data.Config.sysInfo.MiscOptions.TimeZoneIndex,
        date: moment(endpoints.livePanelStatus.data.Live.PanelStatus.DateTime).startOf('day'),
        time: moment(endpoints.livePanelStatus.data.Live.PanelStatus.DateTime),
        timePrompt: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.TimePrompt,
      });
    },
    async save(context) {
      const data = {
        MiscOptions: {
          TimeZoneIndex: context.state.form.timezone,
        },
        SiteOptions: {
          TimePrompt: context.state.form.timePrompt,
        },
      };

      const { time } = context.state.form;
      const date = context.state.form.date.add(time.hours(), 'hour').add(time.minutes(), 'minute');

      await api.put('/Live/PanelStatus/DateTime', date);
      await api.put('/Config/sysInfo', data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('settingsState/dateAndTime/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['settingsState/dateAndTime'] });
    },
  },
});


export default store;
