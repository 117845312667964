<template>
	<two-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
		<template v-slot:title>
			<h1 id="title">{{ $t('logicGates.navigationTitle') }}</h1>
		</template>

		<div>
			<div>
				<a-input-search
					:placeholder="$t('logicGates.Search-for-a-logic-gate')"
					size="large"
					v-model="grid.searchQuery"
				/>

				<a-row :gutter="23" class="header">
          <a-col :xs="{ span: 18}" :md="{ span: 18}" :lg="{ span: 18}" :xl="{ span: 18}" class="filters">
          </a-col>
					<a-col :xs="{ span: 6}" :md="{ span: 6}" :lg="{ span: 6}" :xl="{ span: 6}" class="align-right hide-small">
						<grid-display-toggle :isTabularview="grid.options.isTabularView" @toggle="onGridDisplayToggle" />
					</a-col>
				</a-row>
			</div>
      <loading-indicator v-if="isLoading"/>
			<grid class="logic-gate" cardsPerRow="4" :options="grid.options" :records="gridItems"></grid>
		</div>
    <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
      @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
	</two-col-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
  ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard,
} from '@/app/shared/mixins';
import LoadingIndicator from '@/app/shared/components/loading-indicator/loading-indicator.vue';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import { MAX_GATE_TRIGGERS } from '@/app/shared/constants';

const storeNamespace = 'outputsState/logicGatesList';
const { mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard],
  components: {
    LoadingIndicator,
    DisplayFaultsModal,
  },
  data() {
    const gridColumns = [
      {
        title: 'name',
        dataIndex: 'number', // 'number' used in order to permit search on the gate number e.g. '10' for Logic Gate 10
        isTitle: true,
      },
      {
        title: this.$t('logicGates.Gate-type'),
        dataIndex: 'gateType',
        // Translate gateType key string e.g. 'NOT_USED_GATE' into localised string 'Not used'.
        customRender: gt => this.$t(`logicGates.enums.gateType.${gt.key}`),
      },
    ];
    // Add the four input triggers.
    for (let i = 0; i < MAX_GATE_TRIGGERS; i += 1) {
      // Title e.g 'logicGates.Input-trigger-4' becomes 'Trigger 4'
      const translatedTitle = this.$t(`logicGates.Input-trigger-${(i + 1).toString()}`);
      const columnIndex = 'inputTrigger'.concat(i.toString());
      const trigger = {
        title: translatedTitle,
        dataIndex: columnIndex, //  e.g. 'inputTrigger3'
        customRender: t => this.outputTypes[t].name, // convert trigger number to name
      };
      gridColumns.push(trigger);
    }
    // Finally add the unrendered name.
    const unshownName = {
      dataIndex: 'name', // 'name' must be used because it appears in the item...
      customRender: () => '', // ... but should not appear twice (see 'title' column above)
    };
    gridColumns.push(unshownName);

    return {
      addDrawerVisible: false,
      loader: {
        storeNamespace,
      },
      grid: {
        itemsStorePath: `${storeNamespace}/items`,
        deleteItemsStoreAction: `${storeNamespace}/delete`,

        searchFields: ['number'], // Search on the number column

        options: {
          columns: gridColumns,

          canDelete: () => false,
          canSelect: () => false,
          click: (record) => {
            this.$router.push({
              name: 'logic-gates-logic-gate',
              params: { index: record.index },
            });
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'outputTypes']),
  },
};
</script>

<style lang="scss" >
.logic-gate .card__body {
	padding: 0rem 1.1rem;
}
</style>
