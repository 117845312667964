<template>
  <div class="styled-section">
    <div class="styled-section__head">
      <div class="styled-section__head--left">
        <h3 class="h4">{{ $t('panel.Areas.title') }}</h3>
      </div>
      <div class="styled-section__head--right" :hidden="blockRemoteSet">
        <a-button v-show="disableArmSelectedBtn && disableDisarmSelectedBtn"
          class="alternative"
          @click="actionArmAllDisarmAll(true)"
          :loading="areaActivityRunning && areaActivity === 'ARM'"
          :disabled="!isZoneInArea || statusArmAll"
        >{{ $t('panel.Areas.Set-areas') }}</a-button>
        <a-button v-show="disableArmSelectedBtn && disableDisarmSelectedBtn"
          class="alternative"
          @click="actionArmAllDisarmAll(false)"
          :loading="areaActivityRunning && areaActivity === 'DISARM'"
          :hidden="hideDisarmAll"
          :disabled="statusDisarmAll"
        >{{ $t('panel.Areas.Unset-areas') }}</a-button>
        <a-button v-show="areaActivityRunning && areaActivity === 'ARM'"
          class="alternative"
          @click="actionArmAllDisarmAll(false)"
          :hidden="hideCancel"
          :disabled="disableCancelBtn"
        >{{ $t('panel.Areas.Cancel-arming') }}</a-button>
        <a-button v-show="!disableArmSelectedBtn || !disableDisarmSelectedBtn"
          class="alternative"
          @click="armSelectedAreas()"
          :loading="areaActivityRunning && areaActivity === 'ARM'"
          :disabled="statusArmAll"
        >{{ $t('panel.Areas.armSelectedAreas') }}</a-button>
        <a-button v-show="!disableDisarmSelectedBtn || !disableArmSelectedBtn"
          class="alternative"
          @click="disarmSelectedAreas()"
          :loading="areaActivityRunning && areaActivity === 'DISARM'"
          :disabled="statusDisarmAll"
          :hidden="hideDisarmAll"
        >{{ $t('panel.Areas.disarmSelectedAreas') }}</a-button>
      </div>
    </div>
    <loading-indicator v-if="isLoading"/>
    <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:10, width:'100%' }" style="padding-top: 1rem;">
      <div>
        <a-tabs defaultActiveKey="1" @change="onTabRangeChange">
          <a-tab-pane v-for="(tabRange, index) in areaTabs" :key="`tabRange-${index}`">
            <template slot="tab">
              <span v-if="hasAreaInAlarm(tabRange.startIndex, tabRange.endIndex - 1)" style="color:red">
                {{ items[tabRange.startIndex].identifier }}-{{ items[tabRange.endIndex - 1].identifier }}
                <font-awesome-icon :icon="['fal', 'exclamation-triangle']" />
              </span>
              <span v-else>
                {{ items[tabRange.startIndex].identifier }}-{{ items[tabRange.endIndex - 1].identifier }}
              </span>
            </template>
            <card v-for="(area, index) in items.slice(tabRange.startIndex, tabRange.endIndex)" :key="`area-${index}`" :hidden="hideDisableUnsettableAreas(area.areaStatus)">
              <cardHeader>
                <div>
                  <span :hidden="blockRemoteSet">
                    <a-checkbox :id="area.name" :disabled="hideDisableUnsettableAreas(area.areaStatus)" :defaultCheck="false" @change="onCheckboxChange(area, $event.target.checked)"> </a-checkbox>
                  </span>
                  <div>
                    <h3 style="display: inline; padding: 20px">{{area.identifier }} - {{ area.name }}</h3>
                    <div v-if="isAreaInAlarm(area)" style="display: inline; padding: 5px; border: thin solid lightgrey;">
                      <h4 style="display: inline;"> {{ $t('panel.Zones-in-alarm') }} </h4>
                      <div v-for="[idx,zone] in area.zonesInAlarm" :key=idx style="display: inline;">
                        <span v-if="zone">
                          <u style="color:red; display: inline; padding: 5px" @click="$router.push({ name: 'zone', params: { index: idx } })" >{{ `${zone}` }}</u>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </cardHeader>
              <a-row :gutter="8">
                <a-col
                  class="gutter-row"
                  :span="6"
                  style="padding-left: 1rem; padding-right: 1rem;"
                  v-for="(level, index) in area.levels" :key="`level-${index}`">
                  <card>
                    <cardHeader>
                      <div>
                        <h4>{{ level.identifier }} - {{ level.name }}</h4>
                      </div>
                      <template slot="actions">
                        <div v-if="(levelState(area, index) === true && area.activeLevel === level.index) && (area.inReArm === true || area.areaStatus === 'SETTING' || area.isLoading === true)">
                          <a-tooltip>
                            <template slot="title">
                              <span>{{ $t('panel.Areas.settingTooltip') }}</span>
                            </template>
                            <span>
                              <a-spin v-bind:key="`area-spin-${index}`">
                                <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                              </a-spin>
                            </span>
                          </a-tooltip>
                          <a-button
                            type="link"
                            @click="actionArmAllDisarmAll(false, [area], level.index)"
                            :loading="area.isUnsetting === true"
                            v-if="area.areaStatus === 'SETTING'"
                          >{{ $t('common.Cancel') }}</a-button>
                        </div>
                        <a-tooltip v-else-if="(levelState(area, index) === true && area.activeLevel === level.index && area.areaStatus === 'SET')">
                          <template slot="title">
                            <span>{{ $t('panel.Areas.setTooltip') }}</span>
                          </template>
                          <font-awesome-icon
                            :icon="['fas', 'lock-alt']"
                            class="error"
                            size="lg"
                            @click="actionArmAllDisarmAll(false, [area], level.index)"
                          />
                        </a-tooltip>
                        <a-tooltip v-else-if="(levelState(area, index) === true && area.areaStatus === 'IN_ALARM')">
                          <template slot="title">
                            <span>{{ $t('panel.Areas.inAlarmTooltip') }}</span>
                          </template>
                          <font-awesome-icon
                            :icon="['fas', 'bell']"
                            class="error"
                            size="lg"
                            @click="actionArmAllDisarmAll(false, [area], level.index)"
                          />
                        </a-tooltip>
                        <a-tooltip v-else-if="levelState(area, index) === true && area.levelsCanSet[index] === true">
                          <template slot="title">
                            <span>{{ $t('panel.Areas.unsetTooltip') }}</span>
                          </template>
                          <font-awesome-icon
                            :icon="['fas', 'lock-open-alt']"
                            class="active"
                            size="lg"
                            @click="actionArmAllDisarmAll(true, [area], level.index)"
                          />
                        </a-tooltip>
                        <font-awesome-icon v-else-if="levelState(area, index) === true && area.areaStatus === 'SET_CANCELLED'"
                          :icon="['fas', 'lock-open-alt']"
                          class="active"
                        />
                        <font-awesome-layers v-else class="fa-layers fa-fw">
                          <font-awesome-icon :icon="['fal', 'lock']" :style="{ color: 'red' }" />
                          <font-awesome-icon :icon="['fal', 'slash']" :style="{ color: 'red' }" />
                        </font-awesome-layers>
                      </template>
                    </cardHeader>
                  </card>
                </a-col>
              </a-row>
            </card>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-skeleton>

    <a-modal :title="$t('panel.Areas.Weve-found-some-faults-on-the-panel')" v-model="showFaultsModal.visible">
        <p>{{ $t('panel.Areas.Do-you-want-to-continue-to-arm-with-faults') }}</p>
        <div class="faults-list">
          <h3>{{ $t('panel.Areas.Faults-found') }}</h3>
          <div class="faults-list__items">
            <p v-for="fault in showFaultsModal.activeFaults" :key="fault"> {{ fault }}</p>
          </div>
        </div>
        <template slot="footer">
            <a-row :gutter="8">
                <a-col :span="12" style="text-align: left;">
                    <a-button type="link" @click="cancelArmWithFaults">{{ $t('common.Cancel') }}</a-button>
                </a-col>
                <a-col :span="12">
                    <a-button type="primary" @click="armWithFaults">{{ $t('panel.Areas.ARM-with-faults') }}</a-button>
                </a-col>
            </a-row>
        </template>
    </a-modal>

    <a-modal :title="$t('panel.Areas.Weve-found-some-areas-without-associated-keypads')" v-model="armOnlyAreasWithKeypadsModal.visible">
        <p v-if="armOnlyAreasWithKeypadsModal.areasToSet.length > 0">{{ $t('panel.Areas.Do-you-want-to-continue-to-arm-only-areas-with-keypads') }}</p>
        <p v-else>{{ $t('panel.Areas.There-are-no-areas-that-can-be-armed') }}</p>
        <template slot="footer">
            <a-row :gutter="8">
                <a-col :span="12" style="text-align: left;">
                    <a-button type="link" @click="cancelArmOnlyAreasWithKeypads">{{ $t('common.Cancel') }}</a-button>
                </a-col>
                <a-col :span="12">
                    <a-button v-if="armOnlyAreasWithKeypadsModal.areasToSet.length > 0" type="primary" @click="armOnlyAreasWithKeypads">{{ $t('panel.Areas.ARM-only-areas-with-keypads') }}</a-button>
                </a-col>
            </a-row>
        </template>
    </a-modal>

    <a-modal :title="$t('panel.Silence-alarm')" v-model="silenceAlarmForArmingModal.visible">
        <p>{{ $t('panel.Areas.Silence-alarm-before-arming') }}</p>
        <template slot="footer">
            <a-row :gutter="8">
                <a-col :span="12">
                    <a-button type="primary" @click="hideSilenceAlarmModal">{{ $t('common.Ok') }}</a-button>
                </a-col>
            </a-row>
        </template>
    </a-modal>

  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

import areaHelper from '@/app/panel/shared/services/area-helper';

import { API } from '@/app/shared/services/api';
import card from '@/app/shared/components/Card/card.vue';
import cardHeader from '@/app/shared/components/Card/Child/cardHeader.vue';
import LoadingIndicator from '@/app/shared/components/loading-indicator/loading-indicator.vue';

import { CommandError } from '@/app/shared/errors';
import { loadArea } from '@/app/panel/panel-areas/panel-areas-helpers';

const storeNamespace = 'panelState/panelAreas';

const { mapState, mapActions, mapGetters } = createNamespacedHelpers(
  storeNamespace,
);

export default {
  components: {
    card,
    cardHeader,
    LoadingIndicator,
  },
  data() {
    return {
      setAreasInProgress: false,
      unsetAreasInProgress: false,
      showFaultsModal: {
        visible: false,
        activeFaults: [],
        areasToSet: [],
      },
      silenceAlarmForArmingModal: {
        visible: false,
      },
      armOnlyAreasWithKeypadsModal: {
        visible: false,
        areasToSet: [],
      },
      disableArmSelectedBtn: true,
      disableDisarmSelectedBtn: true,
      selectedAreas: [],
      armButtonState: null,
      set: false,
      lastAreaToBeArmed: null,
      api: null,
    };
  },
  props: ['loader'],
  computed: {
    statusArmAll() {
      if (this.armButtonState === 'arming' || this.armButtonState === 'armed' || this.armButtonState === 'disarming') {
        return true;
      }
      return false;
    },
    statusDisarmAll() {
      if (this.armButtonState === 'disarming') {
        return true;
      }
      return false;
    },
    hideDisarmAll() {
      if (this.armButtonState === 'arming') {
        return true;
      }
      return false;
    },
    hideCancel() {
      if (this.armButtonState === 'arming') {
        return false;
      }
      return true;
    },
    disableCancelBtn() {
      if (this.lastAreaToBeArmed) {
        if (this.items[this.lastAreaToBeArmed].areaStatus === 'SETTING') {
          return false;
        }
      }
      return true;
    },
    ...mapState(['items', 'areaTabs', 'areaActivityRunning', 'areaActivity', 'isZoneInArea', 'blockRemoteSet']),
    ...mapGetters(['isLoading', 'hasArmingAreasFault']),
  },

  methods: {
    ...mapActions(['startAreasActivity', 'endAreasActivity', 'areaChanged']),
    async showArmWithFaultsModal(areas, areasInFault, activeFaults, openZones) {
      this.showFaultsModal.areasToSet = areas;
      this.showFaultsModal.areasInFault = areasInFault;
      this.showFaultsModal.activeFaults = activeFaults;
      this.showFaultsModal.activeFaults.push(...openZones);
      this.showFaultsModal.visible = true;
    },
    async armWithFaults() {
      this.startAreasActivity({ areas: this.showFaultsModal.areasToSet });
      try {
        this.showFaultsModal.visible = false;
        const { errors } = await areaHelper.armWithFaults(this.areaChanged, this.showFaultsModal.areasInFault);
        if (errors.length > 0) throw new CommandError('SetAreas Failed', CommandError.SET_AREAS, errors);
      } finally {
        this.endAreasActivity({ areas: this.showFaultsModal.areasToSet });
      }
    },
    hasAreaInAlarm(tabRangeStartIndex, tabRangeEndIndex) {
      let areaInAlarm = false;
      this.items.slice(tabRangeStartIndex, tabRangeEndIndex).forEach((area) => {
        if (area.zonesInAlarm && area.zonesInAlarm.length) {
          if (area.areaStatus === 'IN_ALARM' && area.zonesInAlarm[0][1]) {
            areaInAlarm = true;
          }
        }
      });
      return areaInAlarm;
    },
    isAreaInAlarm(area) {
      let areaInAlarm = false;
      if (area.zonesInAlarm && area.zonesInAlarm.length) {
        if (area.areaStatus === 'IN_ALARM' && area.zonesInAlarm[0][1]) {
          areaInAlarm = true;
        }
      }
      return areaInAlarm;
    },
    showSilenceAlarmModal() {
      this.silenceAlarmForArmingModal.visible = true;
    },
    hideSilenceAlarmModal() {
      this.silenceAlarmForArmingModal.visible = false;
    },
    cancelArmWithFaults() {
      this.toggleAreas(false, this.showFaultsModal.areasToSet, null);
      this.showFaultsModal.areasToSet = [];
      this.showFaultsModal.activeFaults = [];
      this.showFaultsModal.visible = false;
    },
    showArmOnlyAreasWithKeypadsModal(areas) {
      this.armOnlyAreasWithKeypadsModal.areasToSet = areas;
      this.armOnlyAreasWithKeypadsModal.visible = true;
    },
    async armOnlyAreasWithKeypads() {
      this.armOnlyAreasWithKeypadsModal.visible = false;
      await this.toggleAreas(true, this.armOnlyAreasWithKeypadsModal.areasToSet);
    },
    cancelArmOnlyAreasWithKeypads() {
      this.armOnlyAreasWithKeypadsModal.areasToSet = [];
      this.armOnlyAreasWithKeypadsModal.visible = false;
    },
    actionArmAllDisarmAll(set, areas, levelIndex) {
      if (!this.blockRemoteSet) {
        this.set = set;
        this.checkAreasWithKeypadsThenToggle(areas, levelIndex);
      }
    },
    async checkAreasWithKeypadsThenToggle(areas, levelIndex) {
      if (this.set) {
        // If any area is in alarm, show silence alarm popup and don't arm
        let selectAreas = areas;
        if (selectAreas === undefined) {
          // Arm/disarm ALL areas.
          selectAreas = this.items;
        }
        selectAreas = selectAreas.filter(value => value.areaStatus === 'IN_ALARM');
        if (selectAreas.length > 0) {
          this.showSilenceAlarmModal();
          this.set = false;
          return;
        }
      }

      let selectedAreas = areas;
      if (!selectedAreas) {
        // Arm/disarm ALL areas.
        selectedAreas = this.items;
      }
      let selectedLevel = levelIndex;
      if (!selectedLevel) {
        // Arm level A
        selectedLevel = 0;
      }
      selectedAreas = selectedAreas.filter((value) => {
        if (this.set) {
          // Check whether the panel thinks the specified level of the area is currently armable.
          return value.levelsCanSet[selectedLevel];
        }
        return value.areaStatus === 'SET' || value.areaStatus === 'SETTING' || value.areaStatus === 'IN_ALARM';
      });
      if (selectedAreas.length > 0) {
        if (this.set) {
          this.armButtonState = 'arming';
          const settableAreas = await areaHelper.getAreasWithKeypads(selectedAreas);
          // Capture the last area to be armed, so we can check its state and if it's safe to Cancel All arming
          this.lastAreaToBeArmed = settableAreas[settableAreas.length - 1].index;
          if (settableAreas.length === selectedAreas.length) {
            await this.toggleAreas(this.set, selectedAreas, selectedLevel);
          } else {
            this.showArmOnlyAreasWithKeypadsModal(settableAreas);
          }
        } else {
          this.armButtonState = 'disarming';
          await this.toggleAreas(this.set, selectedAreas, selectedLevel);
        }
      }
      if (this.set) {
        this.armButtonState = 'armed';
      } else {
        this.armButtonState = 'disarmed';
      }
    },
    async toggleAreas(set, areas, levelIndex) {
      this.startAreasActivity({ activity: set ? 'ARM' : 'DISARM' });
      try {
        const { errors } = await areaHelper.toggleArea(areas, levelIndex, set, this.areaChanged, this.areasUnableToSet);
        if (errors.length > 0) throw new CommandError('SetAreas Failed', CommandError.SET_AREAS, errors);
      } finally {
        this.endAreasActivity();
      }
    },
    onCheckboxChange(area, checked) {
      if (checked) {
        // Enable arm selected and disarm selected buttons
        this.disableArmSelectedBtn = false;
        this.disableDisarmSelectedBtn = false;
        // Push selected area for processing
        this.selectedAreas.push(area);
      } else {
        // Pop the selected area from the list of areas
        const index = this.selectedAreas.findIndex(p => p.name === area.name);
        if (index > -1) {
          this.selectedAreas.splice(index, 1);
        }
        // No more areas to process, disable arm selected and disarm selected buttons
        if (this.selectedAreas.length === 0) {
          this.disableArmSelectedBtn = true;
          this.disableDisarmSelectedBtn = true;
        }
      }
    },
    async armSelectedAreas() {
      // If any area is in alarm, show silence alarm popup and don't arm
      let selectAreas = this.selectedAreas;
      selectAreas = selectAreas.filter(value => value.areaStatus === 'IN_ALARM');
      if (selectAreas.length > 0) {
        this.showSilenceAlarmModal();
        return;
      }

      this.disableDisarmSelectedBtn = true;
      const set = true;

      // orderedUnsetAreas includes both UNSET and DELAYED areas,
      // as the latter has affected zones omitted and can be ignored when arming.
      const orderedUnsetAreas = this.selectedAreas.filter(value => value.areaStatus === 'UNSET' || value.areaStatus === 'SET_DELAYED');

      if (orderedUnsetAreas.length > 0) {
        this.armButtonState = 'arming';
        const settableAreas = await areaHelper.getAreasWithKeypads(orderedUnsetAreas);
        // Capture the last area to be armed, so we can check its state and if it's safe to Cancel All arming
        this.lastAreaToBeArmed = settableAreas[settableAreas.length - 1].index;
        if (settableAreas.length === orderedUnsetAreas.length) {
          await this.toggleAreas(set, settableAreas, settableAreas.activeLevel);
        } else {
          this.showArmOnlyAreasWithKeypadsModal(settableAreas);
        }
        this.armButtonState = 'armed';
      }
      // Disable the arm selected button
      this.disableArmSelectedBtn = true;
      // Clear the selected checkboxes
      this.clearCheckboxes();
      // Empty the selected areas list as there selected areas have been unchecked
      this.selectedAreas = [];
      this.armButtonState = null;
    },
    async disarmSelectedAreas() {
      // Disable arm selected button
      this.disableArmSelectedBtn = true;
      const set = false;
      const orderedSetAreas = this.selectedAreas.filter(value => value.areaStatus === 'SET' || value.areaStatus === 'SETTING' || value.areaStatus === 'IN_ALARM');
      if (orderedSetAreas.length > 0) {
        this.armButtonState = 'disarming';
        await this.toggleAreas(set, orderedSetAreas, orderedSetAreas.activeLevel);
        this.armButtonState = 'disarmed';
      }
      // Disable disarm selected button
      this.disableDisarmSelectedBtn = true;
      // Clear all selected checboxes
      this.clearCheckboxes();
      // Empty the list of selected areas as all checkboxes have been cleared
      this.selectedAreas = [];
      this.armButtonState = null;
    },
    clearCheckboxes() {
      // Simulating a click pops elements from the selected areas, hence keep reading the checbox element at index 0
      for (let x = this.selectedAreas.length; x > 0;) {
        const checkbox = document.getElementById(this.selectedAreas[0].name);
        const clickEvent = new MouseEvent('click', { area: this.selectedAreas[0], checked: false });
        checkbox.dispatchEvent(clickEvent);
        x = this.selectedAreas.length;
      }
    },
    hideDisableUnsettableAreas(areaStatus) {
      // Hide areas if keypad is in all areas (no zone is in any area)
      if (areaStatus === 'UNSET' && !this.isZoneInArea) {
        return true;
      }
      return false;
    },
    async areasUnableToSet(areas, areasInFault) {
      const activeFaults = await areaHelper.getActiveFaults();
      const openZones = await areaHelper.getZonesPreventingArmingText(areas);
      if ((activeFaults.length !== 0) || (openZones.length !== 0)) {
        this.showArmWithFaultsModal(areas, areasInFault, activeFaults, openZones);
      }
    },
    levelState(area, index) {
      if (area.levelInfo && area.levelInfo.length) {
        return area.levelInfo[index];
      }
      return false;
    },
    onTabRangeChange(activeKey) {
      if (this.api === null) {
        this.api = new API();
      }
      const index = parseInt(activeKey.split('-')[1], 10);
      const { startIndex, endIndex } = this.areaTabs[index];
      for (let i = startIndex; i < endIndex; i += 1) {
        loadArea(this.api, this.items, i);
      }
      this.currentTabPoll = index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/base/variables';
.card__head {
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
}

.active {
    color:$success-color;
}

.faults-list {

  h3 {
    font-size: 0.8em;
  }

  &__items {
  max-height: 10em;
  overflow: scroll;
   p {
    margin: 0 0 0 10px;
    font-weight: bold;
   }
  }

}
</style>
