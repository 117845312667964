<template>
   <three-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
            <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
                <h1 id="title">{{ name }}</h1>
            </a-skeleton>
        </template>
        <template v-slot:titleCommands>
            <item-menu
              @save="saveForm" :isSaving="isSaving"
              :canClone="$v.form.accessType.$model === ACCESS_TYPE.USER_CODE.value"  @clone="addDrawerVisible = true"
              :canDelete="$v.form.userType.$model !== USER_TYPE.ENGINEER.value && $v.form.userType.$model !== USER_TYPE.MASTERMAN.value"
              @delete="onDelete"
              :disabled="!configWriteLock.canWriteConfig" />
        </template>

        <form>
            <div class="styled-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('users.User-information') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-input :label="$t('common.Name')" :model="$v.form.name" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-readonly :label="$t('users.Access-type')" :value="$t(`users.enums.ACCESS_TYPE.${$v.form.accessType.$model}`)" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="hasEditableCode">
                              <a-form-item :validate-status="$v.form.code.$error ? 'error': 'success'">
                                <template slot="label">
                                  <label>{{ $t('users.Code') }}</label>
                                </template>
                                <a-button :disabled="!configWriteLock.canWriteConfig" v-show="!$v.form.validation.$model.showCode" class="alternative" @click="showCodeField" style="width:100%">{{ $t('users.Update-code') }}</a-button>
                                <a-input :disabled="!configWriteLock.canWriteConfig" v-show="$v.form.validation.$model.showCode" class="alternative" type="password" v-model="$v.form.code.$model" ref="codeField">
                                  <a-icon slot="addonAfter" type="close" @click="hideCodeField" />
                                </a-input>
                                <div class="ant-form-explain" v-if="$v.form.code.$error">
                                  <div v-if="$v.form.code.$params.required && !$v.form.code.required">{{ $t('common.Field-is-required') }}</div>
                                  <div v-if="$v.form.code.$params.validCodeLength && !$v.form.code.validCodeLength">{{ $t('users.Code-length-incorrect') }}</div>
                                  <div v-if="$v.form.code.$params.validCode && !$v.form.code.validCode">{{ $t('users.Code-invalid') }}</div>
                                  <div v-if="$v.form.code.$params.notReservedCode && !$v.form.code.notReservedCode">{{ $t('users.Using-reserved-code') }}</div>
                                  <div v-if="$v.form.code.$params.noCodeOverlap && !$v.form.code.noCodeOverlap">{{ $t('users.Code-already-used') }}</div>
                                  <div v-if="$v.form.code.$params.validTag && !$v.form.code.validTag">{{ $t('users.Tag-invalid') }}</div>
                                  <div v-if="$v.form.code.$params.validTagLength && !$v.form.code.validTagLength">{{ $t('users.Tag-length-incorrect', { len: TAG_LENGTH }) }}</div>
                                </div>
                              </a-form-item>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-readonly :label="$t('users.User-type')" :value="$t(`users.enums.USER_TYPE.${form.userType}`)" v-if="form.userType === USER_TYPE.ENGINEER.value || form.userType === USER_TYPE.MASTERMAN.value" />
                                <p-select v-else :label="$t('users.User-type')" :model="$v.form.userType" :items="USER_TYPE.values" :valueExpr="(i) => i.value" :disabledExpr="disableUserType" :textExpr="(i) => $t(`users.enums.USER_TYPE.${i.value}`)"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.accessType.$model !== ACCESS_TYPE.KEY_FOB.value && form.userType !== USER_TYPE.ENGINEER.value">
                                <p-area-selector :label="$t('common.Sets')" :model="$v.form.areas" :areasInfo="areasInfo" :areasConfig="areasConfig"
                                  :disabled="!configWriteLock.canWriteConfig"/>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.accessType.$model !== ACCESS_TYPE.KEY_FOB.value && form.userType !== USER_TYPE.ENGINEER.value">
                                <p-select :label="$t('users.Set-option')" :model="$v.form.setOption" :items="SET_OPTIONS.values" :valueExpr="(i) => i.value" :textExpr="(i) => $t(`users.enums.SET_OPTIONS.${i.value}`)"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 9}" >
                                <p-readonly :label="$t('common.Number')" :value="number" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.accessType.$model !== ACCESS_TYPE.KEY_FOB.value && form.userType !== USER_TYPE.ENGINEER.value">
                                <a-form-item :label="$t('users.Flexi-set')">
                                  <a-tooltip :title="toggleTooltipText($v.form.flexiSet)">
                                    <a-switch v-model="$v.form.flexiSet.$model" />
                                  </a-tooltip>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

            <div class="styled-section" v-if="$v.form.accessType.$model === ACCESS_TYPE.KEY_FOB.value" data-test-id="fob-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h3">{{ $t('users.Fob-settings') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 48}" :lg="{ span: 8}" >
                                <p-switch :label="$t('users.Fob-level-mode')" :model="$v.form.levelMode"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.levelMode.$model">
                                <p-select :label="$t('users.User-in-area')" :model="$v.form.levelArea"
                                  :items="areas"
                                  :valueExpr="(i) => i.index" :textExpr="(i) => i.label"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col />
                        </a-row>

                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-select :label="$t('users.Lock')" :model="$v.form.button0ActionType"
                                  :items="BUTTON_ACTION_TYPE.values.filter(OneButtonFilter)"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => $t(`users.enums.BUTTON_ACTION_TYPE.${i.value}`)"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <div v-if="$v.form.button0ActionType.$model === BUTTON_ACTION_TYPE.SET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button0Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                    <div v-else-if="$v.form.levelArea !== undefined"> <!-- level mode -->
                                        <p-select :label="$t('users.Select-level')" :model="$v.form.button0Level"
                                          :items="levels"
                                          :valueExpr="(i) => i.index" :textExpr="(i) => i.label"
                                          :disabled="!configWriteLock.canWriteConfig" />
                                    </div>
                                </a-col>
                            </div>
                            <div v-if="$v.form.button0ActionType.$model === BUTTON_ACTION_TYPE.UNSET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button0Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.button0ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || $v.form.button0ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value">
                                <p-select :label="$t('users.Select-output')" :model="$v.form.button0Output"
                                  :items="outputs"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>

                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-select :label="$t('users.Unlock')" :model="$v.form.button1ActionType"
                                  :items="BUTTON_ACTION_TYPE.values.filter(OneButtonFilter)"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => $t(`users.enums.BUTTON_ACTION_TYPE.${i.value}`)"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <div v-if="$v.form.button1ActionType.$model === BUTTON_ACTION_TYPE.SET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button1Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                    <div v-else-if="$v.form.levelArea !== undefined"> <!-- level mode -->
                                        <p-select :label="$t('users.Select-level')" :model="$v.form.button1Level"
                                          :items="levels"
                                          :valueExpr="(i) => i.index" :textExpr="(i) => i.label"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <div v-if="$v.form.button1ActionType.$model === BUTTON_ACTION_TYPE.UNSET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button1Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.button1ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || $v.form.button1ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value">
                                <p-select :label="$t('users.Select-output')" :model="$v.form.button1Output"
                                  :items="outputs"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>

                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-select :label="$t('users.I')" :model="$v.form.button2ActionType"
                                  :items="BUTTON_ACTION_TYPE.values.filter(OneButtonFilter)"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => $t(`users.enums.BUTTON_ACTION_TYPE.${i.value}`)"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <div v-if="$v.form.button2ActionType.$model === BUTTON_ACTION_TYPE.SET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button2Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                    <div v-else-if="$v.form.levelArea !== undefined"> <!-- level mode -->
                                        <p-select :label="$t('users.Select-level')" :model="$v.form.button2Level"
                                          :items="levels"
                                          :valueExpr="(i) => i.index" :textExpr="(i) => i.label"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <div v-if="$v.form.button2ActionType.$model === BUTTON_ACTION_TYPE.UNSET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button2Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.button2ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || $v.form.button2ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value">
                                <p-select :label="$t('users.Select-output')" :model="$v.form.button2Output"
                                  :items="outputs"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>

                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-select :label="$t('users.II')" :model="$v.form.button3ActionType"
                                  :items="BUTTON_ACTION_TYPE.values.filter(OneButtonFilter)"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => $t(`users.enums.BUTTON_ACTION_TYPE.${i.value}`)"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <div v-if="$v.form.button3ActionType.$model === BUTTON_ACTION_TYPE.SET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button3Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                    <div v-else-if="$v.form.levelArea !== undefined"> <!-- level mode -->
                                        <p-select :label="$t('users.Select-level')" :model="$v.form.button3Level"
                                          :items="levels"
                                          :valueExpr="(i) => i.index" :textExpr="(i) => i.label"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <div v-if="$v.form.button3ActionType.$model === BUTTON_ACTION_TYPE.UNSET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button3Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.button3ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || $v.form.button3ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value">
                                <p-select :label="$t('users.Select-output')" :model="$v.form.button3Output"
                                  :items="outputs"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>

                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-select :label="$t('Lock+Unlock')" :model="$v.form.button4ActionType"
                                  :items="BUTTON_ACTION_TYPE.values.filter(TwoButtonsFilter)"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => $t(`users.enums.BUTTON_ACTION_TYPE.${i.value}`)"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <div v-if="$v.form.button4ActionType.$model === BUTTON_ACTION_TYPE.SET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button4Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                    <div v-else-if="$v.form.levelArea !== undefined"> <!-- level mode -->
                                        <p-select :label="$t('users.Select-level')" :model="$v.form.button4Level"
                                          :items="levels"
                                          :valueExpr="(i) => i.index" :textExpr="(i) => i.label"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <div v-if="$v.form.button4ActionType.$model === BUTTON_ACTION_TYPE.UNSET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button4Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.button4ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || $v.form.button4ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value">
                                <p-select :label="$t('users.Select-output')" :model="$v.form.button4Output"
                                  :items="outputs"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>

                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-select :label="$t('users.I+II')" :model="$v.form.button5ActionType"
                                  :items="BUTTON_ACTION_TYPE.values.filter(TwoButtonsFilter)"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => $t(`users.enums.BUTTON_ACTION_TYPE.${i.value}`)"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <div v-if="$v.form.button5ActionType.$model === BUTTON_ACTION_TYPE.SET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button5Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                    <div v-else-if="$v.form.levelArea !== undefined"> <!-- level mode -->
                                        <p-select :label="$t('users.Select-level')" :model="$v.form.button5Level"
                                          :items="levels"
                                          :valueExpr="(i) => i.index" :textExpr="(i) => i.label"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <div v-if="$v.form.button5ActionType.$model === BUTTON_ACTION_TYPE.UNSET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button5Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.button5ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || $v.form.button5ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value">
                                <p-select :label="$t('users.Select-output')" :model="$v.form.button5Output"
                                  :items="outputs"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>

                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-select :label="$t('users.Lock+I')" :model="$v.form.button6ActionType"
                                  :items="BUTTON_ACTION_TYPE.values.filter(TwoButtonsFilter)"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => $t(`users.enums.BUTTON_ACTION_TYPE.${i.value}`)"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <div v-if="$v.form.button6ActionType.$model === BUTTON_ACTION_TYPE.SET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button6Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                    <div v-else-if="$v.form.levelArea !== undefined"> <!-- level mode -->
                                        <p-select :label="$t('users.Select-level')" :model="$v.form.button6Level"
                                          :items="levels"
                                          :valueExpr="(i) => i.index" :textExpr="(i) => i.label"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <div v-if="$v.form.button6ActionType.$model === BUTTON_ACTION_TYPE.UNSET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button6Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.button6ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || $v.form.button6ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value">
                                <p-select :label="$t('users.Select-output')" :model="$v.form.button6Output"
                                  :items="outputs"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>

                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-select :label="$t('users.Unlock+II')" :model="$v.form.button7ActionType"
                                  :items="BUTTON_ACTION_TYPE.values.filter(TwoButtonsFilter)"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => $t(`users.enums.BUTTON_ACTION_TYPE.${i.value}`)"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <div v-if="$v.form.button7ActionType.$model === BUTTON_ACTION_TYPE.SET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button7Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                    <div v-else-if="$v.form.levelArea !== undefined"> <!-- level mode -->
                                        <p-select :label="$t('users.Select-level')" :model="$v.form.button7Level"
                                          :items="levels"
                                          :valueExpr="(i) => i.index" :textExpr="(i) => i.label"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <div v-if="$v.form.button7ActionType.$model === BUTTON_ACTION_TYPE.UNSET_AREAS.value">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                    <div v-if="!$v.form.levelMode.$model"> <!-- multi-area mode -->
                                        <p-area-selector :label="$t('users.Select-areas')" :model="$v.form.button7Areas"
                                          :areasInfo="areasInfo" :areasConfig="areasConfig"
                                          :disabled="!configWriteLock.canWriteConfig"/>
                                    </div>
                                </a-col>
                            </div>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.button7ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || $v.form.button7ActionType.$model === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value">
                                <p-select :label="$t('users.Select-output')" :model="$v.form.button7Output"
                                  :items="outputs"
                                  :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>

                    </div>
                </a-skeleton>
            </div>

            <div class="styled-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <a-row>
                            <a-col :xs="{ span: 24}" :lg="{ span: 12}">
                                <h3 class="h4">{{ $t('users.Associated-doors') }}</h3>
                            </a-col>
                            <a-col :xs="{ span: 24}" :lg="{ span: 12}">
                                <div class="sub-button-group align-right">
                                    <a-button class="alternative" @click="addItemType = 'Door'; addItemVisible = true;" :disabled="!configWriteLock.canWriteConfig">{{ $t('users.Add-door') }}</a-button>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :xs="{ span: 24}" :lg="{ span: 12}">
                                <card class="card--small" v-for="(item, index) in form.associatedDoors" v-bind:key="`door-${index}`">
                                    <template slot:status>
                                        <status state="active" />
                                    </template>
                                    <cardHeader>
                                        <div>
                                            <h4>{{ item.name }}</h4>
                                        </div>
                                        <template slot="actions">
                                            <a-button :disabled="!configWriteLock.canWriteConfig" class="danger icon-only clear" @click="form.associatedDoors.splice(index, 1);">
                                                <font-awesome-icon :icon="['fal', 'minus-circle']" data-test-id="remove-door" />
                                            </a-button>
                                        </template>
                                    </cardHeader>
                                </card>
                                <a-col class="gutter-row" v-if="form.associatedDoors.length < 1">
                                    <p>{{ $t('users.No-doors') }}</p>
                                </a-col>
                            </a-col>
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

        </form>

        <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />

        <add-item :visible="addItemVisible" :availableItems="availableItems" @close="addItemVisible = false"
            :titleLabel="addItemLabel" :noItemsLabel="noItemsLabel" :addLabel="addLabel" @onAdd="addItems" />

        <add-user :visible="addDrawerVisible" :navigateToNewItem="true" @onClose="addDrawerVisible = false" :clone-payload="loader.payload()" />
        <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
          @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
   </three-col-layout>
</template>

<script>
import {
  required, maxLength, requiredIf, not,
} from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import Card from '@/app/shared/components/Card/card.vue';
import CardHeader from '@/app/shared/components/Card/Child/cardHeader.vue';
import Status from '@/app/shared/components/Status/status.vue';
import ItemMenu from '@/app/shared/components/item-menu/item-menu.vue';
import AddItem from '@/app/shared/components/add-item/add-item.vue';
import AddUser from '@/app/users/shared/components/add-user/add-user.vue';
import { LEVEL_IDS } from '@/app/shared/constants';

import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';

import {
  USER_TYPE, ACCESS_TYPE, SET_OPTIONS, BUTTON_ACTION_TYPE,
} from '@/app/users/shared/enums';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import { TAG_LENGTH } from '@/app/users/shared/services/helpers';
import {
  validTag, validTagLength, noCodeOverlap, validCodeLength, validCode, reservedCode,
} from '@/app/users/shared/services/validators';


const storeNamespace = 'usersState/user';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);


export default {
  mixins: [ConfigWriteLock, UnsavedGuard, StateLoader, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    Card,
    CardHeader,
    Status,
    ItemMenu,
    AddItem,
    AddUser,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      isSaving: false,
      addDrawerVisible: false,
      USER_TYPE,
      ACCESS_TYPE,
      SET_OPTIONS,
      BUTTON_ACTION_TYPE,
      TAG_LENGTH,
      addItemVisible: false,
      addItemType: undefined,
      levelIDs: LEVEL_IDS,
    };
  },
  validations: {
    form: {
      validation: {},
      name: {
        required,
        maxLength: maxLength(16),
      },
      accessType: {},
      code: {
        required: requiredIf(function isCodeRequired() { return this.$v.form.validation.showCode; }),
        noCodeOverlap: noCodeOverlap(),
        notReservedCode: not(reservedCode()),
        validCode: validCode(),
        validCodeLength: validCodeLength(),
        validTag: validTag(),
        validTagLength: validTagLength(),
      },
      userType: {},
      areas: {},
      setOption: {},
      number: {},
      flexiSet: {},
      levelMode: {},
      levelArea: {},
      button0ActionType: {},
      button0Areas: {},
      button0Output: {
        required: requiredIf(model => model.button0ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || model.button0ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value),
      },
      button0Level: {
        required: requiredIf(model => model.button0ActionType === BUTTON_ACTION_TYPE.SET_AREAS.value && model.levelMode === true),
      },
      button1ActionType: {},
      button1Areas: {},
      button1Output: {
        required: requiredIf(model => model.button1ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || model.button1ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value),
      },
      button1Level: {
        required: requiredIf(model => model.button1ActionType === BUTTON_ACTION_TYPE.SET_AREAS.value && model.levelMode === true),
      },
      button2ActionType: {},
      button2Areas: {},
      button2Output: {
        required: requiredIf(model => model.button2ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || model.button2ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value),
      },
      button2Level: {
        required: requiredIf(model => model.button2ActionType === BUTTON_ACTION_TYPE.SET_AREAS.value && model.levelMode === true),
      },
      button3ActionType: {},
      button3Areas: {},
      button3Output: {
        required: requiredIf(model => model.button3ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || model.button3ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value),
      },
      button3Level: {
        required: requiredIf(model => model.button3ActionType === BUTTON_ACTION_TYPE.SET_AREAS.value && model.levelMode === true),
      },
      button4ActionType: {},
      button4Areas: {},
      button4Output: {
        required: requiredIf(model => model.button4ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || model.button4ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value),
      },
      button4Level: {
        required: requiredIf(model => model.button4ActionType === BUTTON_ACTION_TYPE.SET_AREAS.value && model.levelMode === true),
      },
      button5ActionType: {},
      button5Areas: {},
      button5Output: {
        required: requiredIf(model => model.button5ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || model.button5ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value),
      },
      button5Level: {
        required: requiredIf(model => model.button5ActionType === BUTTON_ACTION_TYPE.SET_AREAS.value && model.levelMode === true),
      },
      button6ActionType: {},
      button6Areas: {},
      button6Output: {
        required: requiredIf(model => model.button6ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || model.button6ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value),
      },
      button6Level: {
        required: requiredIf(model => model.button6ActionType === BUTTON_ACTION_TYPE.SET_AREAS.value && model.levelMode === true),
      },
      button7ActionType: {},
      button7Areas: {},
      button7Output: {
        required: requiredIf(model => model.button7ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || model.button7ActionType === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value),
      },
      button7Level: {
        required: requiredIf(model => model.button7ActionType === BUTTON_ACTION_TYPE.SET_AREAS.value && model.levelMode === true),
      },
      associatedDoors: {},
    },
  },
  computed: {
    ...mapFields(storeNamespace, ['form']),
    ...mapState(['name', 'outputs', 'areasInfo', 'areasConfig', 'levelsConfig']),
    ...mapGetters(['isLoading', 'availableDoors']),
    number() {
      return (this.form.userType === USER_TYPE.USER.value || this.form.userType === USER_TYPE.MANAGER.value)
        && this.form.number >= 15
        && this.form.number <= 25 ? this.$t('users.Special-unset-user', { number: this.form.number }) : this.form.number;
    },
    availableItems() {
      if (this.addItemType != null) {
        return this.addItemType === 'Door' ? this.availableDoors : undefined;
      }
      return [];
    },
    addItemLabel() {
      return this.addItemType === 'Door' ? this.$t('users.drawer.Add-door-to-user') : 'undefined';
    },
    noItemsLabel() {
      return this.addItemType === 'Door' ? this.$t('users.drawer.No-doors-available') : 'undefined';
    },
    addLabel() {
      return this.addItemType === 'Door' ? this.$t('users.drawer.Add-to-doors') : 'undefined';
    },
    areas() {
      const res = this.form.areasConfig.Areas.map((areaConfig, i) => ({
        index: i,
        label: `${this.$t('common.Area')} ${this.form.areasInfo.Areas[i].Identifier} - ${areaConfig.Name}`,
      }));
      return res;
    },
    levels() {
      // Populate the internal data structure used for the drop down display.
      const area = this.levelsConfig.Areas[this.form.levelArea];
      const res = area.Level.map((level, i) => ({
        index: i,
        label: `${this.levelIDs[i]} - ${level.Name}`,
      }));
      return res;
    },
    hasEditableCode() {
      const type = this.$v.form.accessType.$model;
      return (type === ACCESS_TYPE.USER_CODE.value) || (type === ACCESS_TYPE.USER_TAG.value);
    },
  },
  methods: {
    OneButtonFilter(v) {
      return (v.value !== 'PANIC_ALARM') && (v.value !== 'OPERATE_OUTPUT_TIMED');
    },
    TwoButtonsFilter(v) {
      return (v.value !== 'OPERATE_OUTPUT_TIMED');
    },
    ...mapActions(['save', 'delete']),
    disableUserType(userType) {
      if (this.form.userType === USER_TYPE.USER.value || this.form.userType === USER_TYPE.MANAGER.value) {
        return userType.value !== USER_TYPE.USER.value && userType.value !== USER_TYPE.MANAGER.value;
      }
      return userType.value !== USER_TYPE.GUARD.value && userType.value !== USER_TYPE.DURESS.value;
    },
    showCodeField() {
      this.$v.form.validation.$model.showCode = true;
      this.$refs.codeField.focus();
    },
    hideCodeField() {
      this.$v.form.validation.$model.showCode = false;
      this.$v.form.code.$model = '';
      this.$v.form.code.$touch();
      this.$v.form.code.$reset();
    },
    onDelete() {
      return this.delete().then(() => { this.$router.push({ name: 'users-list' }); });
    },
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$v.form.validation.showCode = false;
      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();
      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;
      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
    addItems(items) {
      if (items.length > 0) {
        if (this.addItemType === 'Door') {
          this.form.associatedDoors.push(...items);
        }
      }
      this.addItemVisible = false;
    },
    toggleTooltipText(field) {
      return field && field.$model
        ? this.$t('common.OptionEnabledTooltip')
        : this.$t('common.OptionDisabledTooltip');
    },
  },
};
</script>

<style lang="scss">

.alternative.ant-input-group-wrapper {
  input, .ant-input-group-addon {
    background-color: #F8F8F8;
    border: 0;
  }
}

</style>
