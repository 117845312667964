<template>
    <two-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
             <h1 id="title">{{ $t('sirens.navigationTitle') }}</h1>
        </template>
        <template v-slot:titleCommands>
          <a-button type="settings" @click="$router.push({ name: 'settings.areasOptions' })">
            <font-awesome-icon :icon="['fal', 'cog']" />
          </a-button>
          <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" icon="plus-circle" @click="gridOpenDrawer">{{ $t('sirens.Add-siren') }}</a-button>
        </template>

        <div>
             <div>
        <a-input-search :placeholder="$t('sirens.Search-for-a-siren')" size="large" v-model="grid.searchQuery" />

        <a-row :gutter="23" class="header">
            <a-col :xs="{ span: 18}" :md="{ span: 18}" :lg="{ span: 18}" :xl="{ span: 18}" class="filters">
                <a-dropdown class="grid-filter">
                    <a-menu slot="overlay">
                        <a-menu-item @click="gridDeleteSelected">
                            {{ $t('common.Delete')}}
                        </a-menu-item>
                        <a-menu-item @click="enableSelected">
                          {{ $t('common.Enable')}}
                        </a-menu-item>
                        <a-menu-item @click="disableSelected">
                          {{ $t('common.Disable')}}
                        </a-menu-item>
                    </a-menu>
                    <a-button>
                        {{ $t('common.Actions') }} <a-icon type="down" />
                    </a-button>
                </a-dropdown>
                <grid-filter :title="$t('common.Status')" :records="allItems" field="faultState" @change="gridFilterChange" resPrefix="sirens.enums.state." :initialFilters="gridSavedFilters('faultState')" />
                <grid-order :options="[{ field: 'name', name:  $t('common.Name')},{ field: 'number', name: $t('common.Number')}]" @change="gridOrderByChange" />
                <grid-filter :title="$t('common.Associated-with')" :records="allItems" field="associatedWith" :customRender="(item) => item.name" :customValue="(item) => ({ key: item.key })" @change="gridFilterChange" :initialFilters="gridSavedFilters('associatedWith')" />
            </a-col>

            <a-col :xs="{ span: 6}" :md="{ span: 6}" :lg="{ span: 6}" :xl="{ span: 6}" class="align-right hide-small">
                <grid-display-toggle :isTabularview="grid.options.isTabularView" @toggle="onGridDisplayToggle" />
            </a-col>
        </a-row>
        <div>
            <ul class="inline-list">
              <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:1, width:'100%' }">
                <li><span>{{ installed }}/{{ max }} {{ $t('sirens.sirens') }}</span></li>
              </a-skeleton>
            </ul>
        </div>
    </div>
    <loading-indicator v-if="isLoading"/>
    <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:10, width:'100%' }">
      <grid
        :options="grid.options"
        :records="gridItems">
        <div slot="card-title" slot-scope="scope">
          <h3>
            {{ scope.record.name}}
          </h3>
          <p class="subtitle" v-on:click.stop>{{ $t('common.Associated-with') }}
            <router-link to="/" v-if="scope.record.associatedWith.type === 'ENDSTATION'">
              {{ scope.record.associatedWith.name }}
            </router-link>
            <router-link :to="`/output-expanders/output-expander/${scope.record.associatedWith.deviceIndex}`" v-if="scope.record.associatedWith.type === 'OUTPUT_EXPANDER'">
              {{ scope.record.associatedWith.name }}
            </router-link>
            <router-link :to="`/zone-expanders/wired-zone-expander/${scope.record.associatedWith.deviceIndex}`" v-if="scope.record.associatedWith.type === 'WIRED_ZONE_EXPANDER'">
              {{ scope.record.associatedWith.name }}
            </router-link>
            <router-link :to="`/zone-expanders/wireless-zone-expander/${scope.record.associatedWith.deviceIndex}`" v-if="scope.record.associatedWith.type === 'WIRELESS_ZONE_EXPANDER'">
              {{ scope.record.associatedWith.name }}
            </router-link>
            <router-link :to="`/keypads-and-readers/wired-keypad/${scope.record.associatedWith.deviceIndex}`" v-if="scope.record.associatedWith.type === 'KEYPAD'">
              {{ scope.record.associatedWith.name }}
            </router-link>
            <router-link :to="`/keypads-and-readers/wired-reader/${scope.record.associatedWith.deviceIndex}`" v-if="scope.record.associatedWith.type === 'READER'">
              {{ scope.record.associatedWith.name }}
            </router-link>
          </p>
        </div>
      </grid>
    </a-skeleton>
    </div>
    <add-siren :visible="grid.drawerVisible" @onClose="grid.drawerVisible = false" :clone-payload="grid.clonePayload" />
    <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </two-col-layout>
</template>

<script>
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';

import AddSiren from '@/app/sirens/shared/components/add-siren/add-siren.vue';
import LoadingIndicator from '@/app/shared/components/loading-indicator/loading-indicator.vue';

import {
  ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard,
} from '@/app/shared/mixins';
import outputHelper from '@/app/shared/services/output-helper';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

const storeNamespace = 'sirensState/list';
const { mapState, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard],
  components: {
    AddSiren,
    LoadingIndicator,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      grid: {
        itemsStorePath: `${storeNamespace}/items`,
        deleteItemsStoreAction: `${storeNamespace}/delete`,

        orderBy: 'number',

        options: {
          columns: [
            {
              title: this.$t('common.Name'),
              dataIndex: 'name',
              isTitle: true,
            },
            {
              title: this.$t('common.Location'),
              dataIndex: 'location',
            },
            {
              title: this.$t('common.Output'),
              dataIndex: 'number',
            },
            {
              title: this.$t('common.Type'),
              dataIndex: 'outputType',
              customRender: item => (item.bell != null ? `${this.outputTypes[item.bell].name}, ${this.outputTypes[item.strobe].name}` : this.outputTypes[item].name),
            },
            {
              title: this.$t('common.Associated-with'),
              dataIndex: 'associatedWith',
              isTitle: true,
              customRender: item => item.name,
            },
          ],
          rowState: (record) => { if (record.faultState != null) { return record.faultState ? 'active' : 'error'; } return 'loading'; },
          canClone: record => this.configWriteLock.canWriteConfig && record.type === 'WIRED_SIREN',
          canDelete: () => this.configWriteLock.canWriteConfig,
          canToggle: () => true,
          toggleState: record => record.state,

          click: (record) => {
            if (record.type === 'WIRED_SIREN') {
              this.$router.push({ name: 'wired-siren', params: { index: record.index } });
            } else {
              this.$router.push({ name: 'wireless-siren', params: { index: record.wirelessSirenIndex } });
            }
          },
          toggle: async (index, record) => {
            if (record.rawOutputType.bell != null && record.rawOutputType.strobe != null) {
              await outputHelper.activateOutputTypes([record.rawOutputType.bell, record.rawOutputType.strobe], !record.state, this.loader.instance);
            } else {
              await outputHelper.activateOutputType(record.rawOutputType, !record.state, this.loader.instance);
            }
          },
        },
      },
    };
  },
  computed: {
    ...mapState(['outputTypes']),
    ...mapGetters(['isLoading', 'max', 'installed']),
  },
  methods: {
    async enableSelected() {
      const selectedKeys = this.grid.options.selectedRowKeys;
      if (selectedKeys.length > 0) {
        const outputTypes = _.uniq(this.gridItems.filter(i => selectedKeys.includes(i.index)).map(i => i.outputType));
        await outputHelper.activateOutputTypes(outputTypes, true, this.loader.instance);
        this.grid.options.command = 'clear-selection';
      }
    },
    async disableSelected() {
      const selectedKeys = this.grid.options.selectedRowKeys;
      if (selectedKeys.length > 0) {
        const outputTypes = _.uniq(this.gridItems.filter(i => selectedKeys.includes(i.index)).map(i => i.outputType));
        await outputHelper.activateOutputTypes(outputTypes, false, this.loader.instance);
        this.grid.options.command = 'clear-selection';
      }
    },
  },
};
</script>

<style lang="scss">
</style>
