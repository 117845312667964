<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('settings.userSettings.navigationTitle') }}</h2>
      </a-row>
      <a-form>
        <p-select
          :label="$t('settings.userSettings.Minimum-Code-Length')"
          :model="$v.form.minimumCodeLength"
          :labelTooltip="$t('settings.userSettings.6-digit-pins-tooltip')"
          display-style="small"
          :disabled="!configWriteLock.canWriteConfig"
          :items="Object.keys(CODE_LENGTH_MODE)"
          :valueExpr="(i) => CODE_LENGTH_MODE[i]"
          :textExpr="(i) => $t(`enums.CODE_LENGTH_MODE.${i}`)"
        />
        <p-switch :label="$t('settings.userSettings.Quick-arm')" :model="$v.form.quickArm" :labelTooltip="$t('settings.userSettings.Quick-arm-tooltip')" :switchTooltip="toggleTooltipText($v.form.quickArm)" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.userSettings.Code-stop-sound')" :model="$v.form.codeStopSound" :labelTooltip="$t('settings.userSettings.Code-stop-sound-tooltip')" :switchTooltip="toggleTooltipText($v.form.codeStopSound)" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('settings.userSettings.Guard-code-delay')" :model="$v.form.guardCodeDelay" :labelTooltip="$t('settings.userSettings.Guard-code-delay-tooltip')" :switchTooltip="toggleTooltipText($v.form.guardCodeDelay)" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-switch v-if="isAutoDuressSupported" :label="$t('settings.userSettings.Auto-duress')" :model="$v.form.autoDuress" :labelTooltip="$t('settings.userSettings.Auto-duress-tooltip')" :switchTooltip="toggleTooltipText($v.form.autoDuress)" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.userSettings.Manager-program-PA')" :model="$v.form.manageProgramPA" :labelTooltip="$t('settings.userSettings.Manager-program-PA-tooltip')" :switchTooltip="toggleTooltipText($v.form.manageProgramPA)" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
      </a-form>
      <div class="align-right">
        <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
      </div>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </div>
</template>

<script>
import { required, between } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import TooltipHelper from '@/app/shared/services/tooltipHelper';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';
import { CODE_LENGTH_MODE } from '@/app/settings/shared/enums';


const storeNamespace = 'settingsState/userSettings';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
      CODE_LENGTH_MODE,
    };
  },
  computed: {
    ...mapState(['form', 'isAutoDuressSupported']),
  },
  validations: {
    form: {
      minimumCodeLength: {
        required,
      },
      quickArm: {
        required,
      },
      codeStopSound: {
        required,
      },
      guardCodeDelay: {
        required,
        between: between(0, 10),
      },
      autoDuress: {
        required,
      },
      manageProgramPA: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
    toggleTooltipText: TooltipHelper.toggleTooltipText,
  },
};
</script>
