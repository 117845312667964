<template>

  <a-form-item :validate-status="model.$error ? 'error': 'success'" :label-col="style.labelSize || labelCol" :wrapper-col="style.wrapperSize || wrapperCol">
    <template slot="label">
      <a-tooltip v-if="labelTooltip" :title="labelTooltip">
        <label>{{label}}</label>
      </a-tooltip>
      <label v-else>{{label}}</label>
    </template>
    <a-tooltip v-if="switchTooltip" :title="switchTooltip">
      <a-switch v-model="model.$model" @change="$emit('change')" :disabled="disabled"/>
    </a-tooltip>
    <a-switch v-else v-model="model.$model" @change="$emit('change')" :disabled="disabled"/>
    <div class="ant-form-explain" v-if="model.$params.required && model.$error && !model.required">{{ $t('common.Field-is-required') }}</div>
  </a-form-item>
</template>

<script>
export default {
  props: ['label', 'model', 'label-col', 'wrapper-col', 'displayStyle', 'labelTooltip', 'switchTooltip', 'disabled'],
  data() {
    if (this.displayStyle === 'small-alternative') {
      return {
        style: {
          isAlternative: true,
          labelSize: {
            sm: { span: 12 }, md: { span: 8 }, lg: { span: 6 }, xl: { span: 5 },
          },
          wrapperSize: {
            sm: { span: 24 }, md: { span: 18 }, lg: { span: 18 }, xl: { span: 18 },
          },
        },
      };
    }
    if (this.displayStyle === 'small') {
      return {
        style: {
          isAlternative: false,
          labelSize: {
            sm: { span: 12 }, md: { span: 8 }, lg: { span: 6 }, xl: { span: 5 },
          },
          wrapperSize: {
            sm: { span: 24 }, md: { span: 18 }, lg: { span: 18 }, xl: { span: 18 },
          },
        },
      };
    }
    return {
      style: {
        isAlternative: true,
      },
    };
  },
};
</script>

<style lang="scss">
</style>
