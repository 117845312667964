<template>
   <three-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
            <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
                <h1 id="title">{{ name }}</h1>
            </a-skeleton>
        </template>
        <template v-slot:titleCommands>
          <item-menu
            @save="saveForm" :isSaving="isSaving"
            @delete="onDelete"
            :disabled="!configWriteLock.canWriteConfig" />
        </template>

        <diagnostics :globalState="state" :items="diagnostics" :isLoading="isLoading" :options="diagnosticOptions" />

        <form>
            <div class="styled-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('common.Device-information') }}</h3>
                        <a-row :gutter="100">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-input :label="$t('common.Name')" :model="$v.form.name" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-readonly :label="$t('common.Device-type')" :value="$t(`keypadsAndReaders.enums.deviceType.${$v.form.type.$model}`)" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-input :label="$t('common.Location')" :model="$v.form.location" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>
                        <a-row :gutter="100">
                            <a-col class="gutter-row" :xs="{ span: 34}" :lg="{ span: 18}" >
                              <p-select :label="$t('keypadsAndReaders.keypadAndReaderOptions.Keypad-Fire-Key')" :model="$v.form.fireKey" display-style="small"
                                        :items="Object.keys(FIRE_KEY_ACTIVE)" :valueExpr="(i) => FIRE_KEY_ACTIVE[i]" :textExpr="(i) => $t(`enums.FIRE_KEY_ACTIVE.${i}`)"
                                        :labelTooltip="$t('keypadsAndReaders.keypadAndReaderOptions.Keypad-Fire-Key-tooltip')"
                                        :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 34}" :lg="{ span: 18}" >
                              <p-select :label="$t('keypadsAndReaders.keypadAndReaderOptions.Keypad-PA-Key')" :model="$v.form.paKey" display-style="small"
                                        :items="Object.keys(TWO_KEY_PA)" :valueExpr="(i) => TWO_KEY_PA[i]" :textExpr="(i) => $t(`enums.TWO_KEY_PA.${i}`)"
                                        :labelTooltip="$t('keypadsAndReaders.keypadAndReaderOptions.Keypad-PA-Key-tooltip')"
                                        :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>
                        <a-row :gutter="50">
                          <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                            <p-readonly :label="$t('common.Address')" :value="$v.form.address.$model + 1" />
                          </a-col>
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

            <div class="styled-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('keypadsAndReaders.Area-attributes') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-area-selector :label="$t('common.In-area')" :model="$v.form.inAreas" :areasInfo="$v.form.areasInfo.$model" :areasConfig="$v.form.areasConfig.$model"
                                @singleCheckedArea="keypadInArea"
                                :disabled="!configWriteLock.canWriteConfig"/>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-area-selector :label="$t('common.Sets')" :model="$v.form.setsAreas" :singleCheck="true" :areasInfo="$v.form.areasInfo.$model" :areasConfig="$v.form.areasConfig.$model"
                                :disabled="!configWriteLock.canWriteConfig"/>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                               <p-area-selector :label="$t('common.Unsets')" :model="$v.form.unsetsAreas" :areasInfo="$v.form.areasInfo.$model" :areasConfig="$v.form.areasConfig.$model"
                                :disabled="!configWriteLock.canWriteConfig"/>
                            </a-col>
                        </a-row>
                        <a-row :gutter="50" v-if="keypadInAreaIndex >= 0">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" />
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-select :label="$t('common.Level')"
                                :model="$v.form.readerLevel"
                                :items="levels"
                                :valueExpr="(i) => i.index"
                                :textExpr="(i) => i.label"
                                :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" />
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

            <div class="styled-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('wirelessKeypad.Options') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-switch :label="$t('wirelessKeypad.Tag-reader-enabled')" :switchTooltip="tooltipText($v.form.tagReader)" :model="$v.form.tagReader" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-switch :label="$t('wirelessKeypad.Auto-wake-up')" :switchTooltip="tooltipText($v.form.autoWakeup)" :model="$v.form.autoWakeup" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-switch :label="$t('wirelessKeypad.Supervision')" :switchTooltip="tooltipText($v.form.supervision)" :model="$v.form.supervision" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-switch :label="$t('wirelessKeypad.Backlight')" :switchTooltip="tooltipText($v.form.backLight)" :model="$v.form.backLight" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <a-tooltip :title="tooltipText($v.form.entryExitSound)">
                                <p-switch :label="$t('wirelessKeypad.EntryExit-sound')" :switchTooltip="tooltipText($v.form.entryExitSound)" :model="$v.form.entryExitSound" :disabled="!configWriteLock.canWriteConfig" />
                              </a-tooltip>
                            </a-col>
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

        </form>

        <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
        <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
          @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />

   </three-col-layout>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import { arrayHasTrueValue } from '@/app/shared/utils/validation';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import ItemMenu from '@/app/shared/components/item-menu/item-menu.vue';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

import { LEVEL_IDS } from '@/app/shared/constants';
import { TWO_KEY_PA, FIRE_KEY_ACTIVE } from '@/app/keypads-and-readers/shared/enums';

const storeNamespace = 'keypadsAndReadersState/wirelessKeypad';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    UnsavedChangesModal,
    ItemMenu,
    DisplayFaultsModal,
  },
  data() {
    return {
      keypadInAreaIndex: -1,
      TWO_KEY_PA,
      FIRE_KEY_ACTIVE,
      loader: {
        storeNamespace,
      },
      isSaving: false,
      diagnosticOptions: {
        statusType(diagnostic) {
          if (diagnostic.key === 'wirelessKeypad.Diagnostics.Status') {
            return diagnostic.value === 'OK' ? 'active' : 'error';
          }
          if (diagnostic.key === 'wirelessKeypad.Diagnostics.BatteryStatus') {
            return (diagnostic.value === 'updating...' || diagnostic.value === 'GOOD') ? 'active' : 'error';
          }
          if (diagnostic.key === 'wirelessKeypad.Diagnostics.SignalStrength') {
            return (diagnostic.value === 'updating...' || (diagnostic.value !== 0 && diagnostic.format === '{0}%')) ? 'active' : 'error';
          }

          return diagnostic.value === 'FAULT' ? 'error' : 'active';
        },
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(16),
      },
      type: {
        required,
      },
      location: {
        maxLength: maxLength(16),
      },
      address: {
        required,
      },
      fireKey: {
        required,
      },
      paKey: {
        required,
      },
      setsAreas: {},
      unsetsAreas: {},
      inAreas: {
        arrayHasTrueValue,
      },
      readerLevel: {},
      areasInfo: {},
      areasConfig: {},
      levelsConfig: {},
      autoWakeup: {},
      backLight: {},
      entryExitSound: {},
      supervision: {},
      tagReader: {},
    },
  },
  props: {
  },
  computed: {
    levels() {
      // Get the (single) area that the keypad is configured to be in.
      const area = this.form.levelsConfig.Areas[this.keypadInAreaIndex];

      // Return the list of levels for that area, each level in the format "A - <name>".
      const res = area.Level.map((level, i) => ({
        index: i,
        label: `${LEVEL_IDS[i]} - ${level.Name}`,
      }));
      return res;
    },
    ...mapState(['form', 'diagnostics', 'state', 'name']),
    ...mapGetters(['isLoading']),
  },
  methods: {
    ...mapActions(['save', 'delete']),
    keypadInArea(areaIndex) {
      // Note the index of the area that the keypad is configured to be in; -1 if the keypad is in more than one area.
      this.keypadInAreaIndex = areaIndex;
    },
    onDelete() {
      return this.delete().then(() => { this.$router.push({ name: 'keypads-and-readers-list' }); });
    },
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
    tooltipText(option) {
      return option && option.$model
        ? this.$t('common.OptionEnabledTooltip')
        : this.$t('common.OptionDisabledTooltip');
    },
  },
};
</script>

<style lang="scss">

</style>
