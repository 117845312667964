<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('settings.alarmResponses.navigationTitle') }}</h2>
      </a-row>
        <a-form>
          <a-row :gutter="23">
            <a-col :span="10">
              <p-select :label="$t('settings.alarmResponses.If-starts-at-is')" :model="$v.form.startAt" display-style="small"
                :items="Object.keys(RESPONSE_LEVEL)" :valueExpr="(i) => RESPONSE_LEVEL[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL.${i}`)"
                :disabled="!configWriteLock.canWriteConfig" />
            </a-col>
            <a-col :span="11">
              <p-area-selector :label="$t('settings.alarmResponses.then-set-areas')" :model="$v.form.startAtAreas" :areasInfo="$v.form.areasInfo.$model" :areasConfig="$v.form.areasConfig.$model" display-style="small"
                :disabled="!configWriteLock.canWriteConfig" />
            </a-col>
            <a-col :span="1">
              {{ $t('settings.alarmResponses.armed') }}
            </a-col>
          </a-row>
          <a-row :gutter="23">
            <a-col :span="10">
              <p-select :label="$t('settings.alarmResponses.If-stops-at-is')" :model="$v.form.stopAt" display-style="small"
                :items="Object.keys(RESPONSE_LEVEL_STOP_ALARM)" :valueExpr="(i) => RESPONSE_LEVEL_STOP_ALARM[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL_STOP_ALARM.${i}`)"
                :disabled="!configWriteLock.canWriteConfig" />
            </a-col>
            <a-col :span="11">
              <p-area-selector :label="$t('settings.alarmResponses.then-set-areas')" :model="$v.form.stopAtAreas" :areasInfo="$v.form.areasInfo.$model" :areasConfig="$v.form.areasConfig.$model" display-style="small"
                :disabled="!configWriteLock.canWriteConfig" />
            </a-col>
            <a-col :span="1">
              {{ $t('settings.alarmResponses.armed') }}
            </a-col>
          </a-row>
        </a-form>

        <div class="align-right">
          <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
        </div>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';

import { RESPONSE_LEVEL, RESPONSE_LEVEL_STOP_ALARM } from '@/app/settings/shared/enums';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'settingsState/alarmResponses';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      RESPONSE_LEVEL,
      RESPONSE_LEVEL_STOP_ALARM,
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
    };
  },
  computed: {
    ...mapState(['form']),
  },
  validations: {
    form: {
      startAt: { required },
      startAtAreas: { },
      stopAt: { required },
      stopAtAreas: { },
      areasInfo: { },
      areasConfig: { },
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
};
</script>

<style lang="scss">

</style>
