import { limits, LEVEL_IDS } from '@/app/shared/constants';


export function recordFetched(items, areaIndex) {
  return (items[areaIndex].name !== null);
}


export async function loadArea(api, items, areaIndex) {
  if (!recordFetched(items, areaIndex)) {
    const areaConfig = (await api.get(`/Config/areaInfo/Areas/${areaIndex}`)).data.Config.areaInfo.Areas[areaIndex];

    // Commit the (static) state data for this area.
    const areaData = items[areaIndex];
    areaData.name = areaConfig.Name;
    for (let i = 0; i < limits.MAX_LEVELS; i += 1) {
      areaData.levels[i].index = i;
      areaData.levels[i].identifier = LEVEL_IDS[i];
      areaData.levels[i].name = areaConfig.Level[i].Name;
    }
  }
}


async function pollArea(context, api, items, areaIndex) {
  // initialise to false
  const areaInfo = (await api.get(`/Live/areaInfo/Areas/${areaIndex}`)).data.Live.areaInfo.Areas[areaIndex];
  if (areaInfo.ActiveZones.length > 0) {
    // Check if any zone is in area - this is to disable all areas if the keypad is in all areas
    // because if it is, the status of all areas is UNSETTABLE. We want to hide areas that are settable
    // when keypad is in all areas/no zone is in areas. BUG-1386.
    // Note - We no longer calculate isZoneInArea per poll because if a zone's status changes outside
    // of it being changed via the WAN, LAN or the keypad, it could be because the zone's gone open (OC).
    // For example, an endstation zone going open (status OC), results in that zone's area being unsettable
    // but the zone is still in area. We want to hide areas only if no zone is in area, not if they
    // have become unsettable because of a fault in the zone.
    // This function also determines which levels in an area are settable.
    context.state.isZoneInArea = true;
    context.state.wasZoneInArea = context.state.isZoneInArea;

    const znArray = areaInfo.ActiveZones.split(',');
    for (let i = 0; i < znArray.length; i += 1) {
      // For all levels in an area
      /* eslint-disable no-await-in-loop */
      const zoneLevels = (await api.get(`/Config/zoneInfo/Zones/${znArray[i]}/Areas/${areaIndex}/Levels`)).data.Config.zoneInfo.Zones[znArray[i]].Areas[areaIndex].Levels;
      for (let k = 0; k < zoneLevels.length; k += 1) {
        if (zoneLevels[k]) {
          items[areaIndex].levelInfo[k] = true;
        }
      }
    }
  }
}


let gInPoll = false;

export async function pollPanelAreaLevels(context, api, dataStatus) {
  if (!window.globalThis.zoneLevelsRequireReread) {
    return;
  }
  if (gInPoll) {
    return;
  }
  gInPoll = true;
  // initialise with previous levels
  const items = [];
  const allItems = context.state.items;
  for (let ano = 0; ano < limits.MAX_AREAS; ano += 1) {
    items.push({ levelInfo: allItems[ano].levelInfo });
  }

  for (let ano = 0; ano < limits.MAX_AREAS; ano += 1) {
    /* eslint-disable no-await-in-loop */
    await pollArea(context, api, items, ano);
    // ...write every tab's worth
    if (ano % 15 === 14) {
      context.commit('tryMergeCollectionByIndex', { dataStatus, collection: 'items', items });
    }
  }
  window.globalThis.zoneLevelsRequireReread = false;
  context.commit('tryMergeCollectionByIndex', { dataStatus, collection: 'items', items });
  gInPoll = false;
}
