<template>
  <a-form-item :label="label" :validate-status="model.$error ? 'error': 'success'" :label-col="labelCol" :wrapper-col="wrapperCol" :disabled="disabled">
      <item-selector v-model="model.$model" :items="items" />
      <div class="ant-form-explain" v-if="model.$error && !model.required">{{ $t('common.Field-is-required') }}</div>
  </a-form-item>
</template>

<script>
import ItemSelector from '@/app/shared/components/item-selector/item-selector.vue';

export default {
  components: {
    ItemSelector,
  },
  props: ['label', 'model', 'label-col', 'wrapper-col', 'items', 'disabled'],
};
</script>

<style lang="scss">
</style>
