<template>
    <div v-on:click.stop>
        <h4 class="subtitle">{{title}}</h4>
        <p>{{value}}</p>
        <a-table v-if="tableRows != null" :columns="columns" :data-source="tableRows">
          <span slot="customTitle"> Area/s : Level/s</span>
         </a-table>
        <template v-if="tableRows != null">
          <p> <br> </p>
        </template>
    </div>
</template>
<script>

const columns = [
  {
    dataIndex: 'name',
    key: 'name',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'name' },
  },
];

export default {
  name: 'TextBlock',
  props: {
    title: { type: String, default: null },
    value: { default: null },
    tableRows: { default: null },
  },

  data() {
    return {
      columns,
    };
  },
};

</script>

<style lang="scss">
</style>
