<template>
   <three-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
            <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
                <h1 id="title">{{ $t('common.Output-Expander') }} {{ $route.params.index }}</h1>
            </a-skeleton>
        </template>
        <template v-slot:titleCommands>
            <item-menu
              @save="saveForm" :isSaving="isSaving"
              @delete="onDelete"
              :canClone="true" @clone="addDrawerVisible = true"
              :disabled="!configWriteLock.canWriteConfig" />
        </template>

        <diagnostics :globalState="state" :items="diagnostics" :isLoading="isLoading" :options="diagnosticOptions" />

        <form>
            <div class="styled-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('common.Device-information') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-input :label="$t('common.Location')" :model="$v.form.location" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-readonly :label="$t('common.Address')" :value="$v.form.address.$model" />
                            </a-col>
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

            <associated-outputs :isLoading="isLoading" :items="associatedOutputs" :max="outputsMax" :enableAddOutput="configWriteLock.canWriteConfig" :associatedDeviceKey="associatedDeviceKey" @activate="activateAssocOutput" />

        </form>

        <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
        <add-output-expander :visible="addDrawerVisible" :navigateToNewItem="true" @onClose="addDrawerVisible = false" :clone-payload="{ index: $route.params.index }" />
        <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
          @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />

   </three-col-layout>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import AddOutputExpander from '@/app/output-expanders/shared/components/add-output-expander/add-output-expander.vue';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import AssociatedOutputs from '@/app/shared/components/associated-outputs/associated-outputs.vue';
import ItemMenu from '@/app/shared/components/item-menu/item-menu.vue';
import outputExpanderHelper from '@/app/shared/services/output-expander-helper';
import outputHelper from '@/app/shared/services/output-helper';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import { liveOutputExpanderConsts } from '@/app/shared/constants';
import { DEVICE_TYPE } from '../../../outputs/shared/services/helpers';

const storeNamespace = 'outputExpandersState/outputExpander';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    AddOutputExpander,
    UnsavedChangesModal,
    AssociatedOutputs,
    ItemMenu,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      intervalID: [], // Stores the interval ID for clearing later
      diagnosticOptions: {
        statusType(diagnostic) {
          return diagnostic.value === 'FAULT' ? 'error' : 'active';
        },
      },
      addDrawerVisible: false,
      isSaving: false,
    };
  },
  validations: {
    form: {
      location: {
        maxLength: maxLength(16),
      },
      address: {
        required,
      },
    },
  },
  computed: {
    ...mapState(['form', 'outputsMax', 'associatedOutputs', 'diagnostics', 'state']),
    ...mapGetters(['isLoading']),
    associatedDeviceKey() {
      return `${DEVICE_TYPE.OUTPUT_EXPANDER}_${this.$v.form.address.$model}`;
    },
  },
  methods: {
    ...mapActions(['save', 'delete']),
    startCountdown(index, timer, callback) {
      let initialValue = timer;
      this.intervalID[index] = setInterval(() => {
        initialValue -= 1;
        if (initialValue <= 0) {
          clearInterval(this.intervalID[index]);
          callback();
        }
      }, 1000);
    },
    async activateAssocOutput(index, item, checked) {
      await outputHelper.activateOutputType(item.rawOutputType, checked, this.loader.instance);
      if (item.udPulseTime !== null) {
        if (checked === true) {
          this.startCountdown(index, item.udPulseTime, async () => {
            await outputHelper.activateOutputType(item.rawOutputType, !checked, this.loader.instance);
          });
        } else {
          clearInterval(this.intervalID[index]);
        }
      }
    },
    onDelete() {
      return this.delete().then(() => { this.$router.push({ name: 'output-expanders-list' }); });
    },
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
  created() {
    // Start polling the PSU diagnostics for this expander only
    // e.g. for OPM10, build the url Live/OutputExpander/10
    const expanderIndex = this.$route.params.index;
    const expanderUrl = liveOutputExpanderConsts.baseUrl.concat('/').concat(expanderIndex.toString());
    outputExpanderHelper.customiseExpanderPollingWithUrl(liveOutputExpanderConsts.key, this.loader.instance, expanderUrl);
  },
  destroyed() {
    outputExpanderHelper.revertExpanderCustomPollingIfPossible();
  },
};
</script>

<style lang="scss">

</style>
