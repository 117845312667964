import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';

const UnsavedGuard = {
  components: {
    UnsavedChangesModal,
  },
  data() {
    return {
      unsavedGuard: {
        to: undefined,
        forceLeave: false,
        modalVisible: false,
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!this.canLeave) {
      this.unsavedGuard.to = to;
      this.unsavedGuard.modalVisible = true;
    } else {
      next();
    }
  },
  computed: {
    canLeave() {
      return this.unsavedGuard.modalVisible || !this.$v.$anyDirty || this.unsavedGuard.forceLeave === true;
    },
  },
};

export default UnsavedGuard;
