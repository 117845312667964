<template>
  <associated-outputs :endstation="endstation" :isLoading="isLoading" :items="items" :max="maximum" :installed="installed" @activate="activateOutput" />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import outputHelper from '@/app/shared/services/output-helper';
import AssociatedOutputs from '@/app/shared/components/associated-outputs/associated-outputs.vue';
// This replaces StateLoader mixin. StateLoader uses the generic-loader (not the panel's loader).
import { Loader } from '@/app/shared/services/generic-loader';

const storeNamespace = 'panelState/panelOutputs';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);

let loaderInstance = null;

export default {
  components: {
    AssociatedOutputs,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      intervalID: [], // Stores the interval ID for clearing later
    };
  },
  computed: {
    ...mapState(['maximum', 'installed', 'endstation']),
    ...mapGetters(['isLoading', 'items']),
  },
  methods: {
    ...mapActions(['onOutputStateChange']),
    startCountdown(index, timer, callback) {
      let initialValue = timer;
      this.intervalID[index] = setInterval(() => {
        initialValue -= 1;
        if (initialValue <= 0) {
          clearInterval(this.intervalID[index]);
          callback();
        }
      }, 1000);
    },
    async activateOutput(index, item, checked) {
      // Lazy load the loaderInstance.
      if (loaderInstance === null) {
        loaderInstance = new Loader('panel-outputs', this.$store);
      }
      if (item.outputType === 35 && item.followType === 'TIMED') {
        await outputHelper.activateOutputType(item.rawOutputType, checked, loaderInstance);
        if (checked === true) {
          this.startCountdown(index, item.followTime, async () => {
            await outputHelper.activateOutputType(item.rawOutputType, !checked, loaderInstance);
          });
        } else {
          clearInterval(this.intervalID[index]);
        }
      } else {
        await outputHelper.activateOutputType(item.rawOutputType, checked, loaderInstance);
      }
    },
  },
};
</script>
