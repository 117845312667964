<template>
    <three-col-layout :configWriteLock="configWriteLock" :requestWriteLockVisible="false" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:header>
          <alarm-silence  :loader="loader" />
          <a-alert v-if="alertMessage !== undefined" :showIcon="false" type="warning">
            <span slot="description" v-html="alertMessage" />
          </a-alert>
        </template>
        <template v-slot:title>
          <panel-title />
        </template>
        <template v-slot:titleCommands>
          <panel-connections />
        </template>

        <div>
          <panel-diagnostics />
          <panel-areas :loader="loader"/>
          <panel-zones :loader="loader" />
          <panel-outputs  />
        </div>

        <template v-slot:sidebar>
          <panel-counts />
          <a-row :gutter="10" style="padding-left: 1rem;">
            <a-col class="gutter-row" :span="24">
              <p>{{  lastConnected ? $t('panel.Last-connected-at', { time: $d(lastConnected, 'time'), date: $d(lastConnected, 'short')} ) : '' }}</p>
            </a-col>
          </a-row>
        </template>
    </three-col-layout>
</template>

<script>
import { API } from '@/app/shared/services/api';
import { createNamespacedHelpers } from 'vuex';
import { ConfigWriteLock, ErrorToast } from '@/app/shared/mixins';
import { Poller } from '@/app/shared/services/config-write-lock';
import areaHelper from '@/app/panel/shared/services/area-helper';
import { panelPageConsts } from '@/app/shared/constants';

import PanelTitle from './panel-title/panel-title.vue';
import PanelDiagnostics from './panel-diagnostics/panel-diagnostics.vue';
import PanelAreas from './panel-areas/panel-areas.vue';
import PanelZones from './panel-zones/panel-zones.vue';
import PanelOutputs from './panel-outputs/panel-outputs.vue';
import PanelCounts from './panel-counts/panel-counts.vue';
import PanelConnections from './panel-connections/panel-connections.vue';
import AlarmSilence from './alarm-silence/alarm-silence.vue';

import { Loader } from './shared/services/loader';

const api = new API();
const { mapState, mapActions } = createNamespacedHelpers('panelState/panel');

export default {
  mixins: [ConfigWriteLock, ErrorToast],
  components: {
    PanelTitle,
    PanelDiagnostics,
    PanelAreas,
    PanelZones,
    PanelOutputs,
    PanelCounts,
    PanelConnections,
    AlarmSilence,
  },
  data() {
    return {
      alertMessage: undefined,
      loader: undefined,
      faultCount: 0,
    };
  },
  computed: {
    ...mapState(['lastConnected']),
  },
  methods: {
    ...mapActions(['load', 'reset']),
    setCurrentPageIsPanel() {
      this.$gLanModeSetting.currentPageIsPanel = true;
    },
  },
  async created() {
    this.setPageCreated(true);
    // Prevent entering engineer's mode when on panel page if user logs into the keypad in engineers
    // and exits engineers on the keypad i.e. releases lock while user is on the panel page
    // Set LAN mode setting to 'panelPage' to prevent any banners when panel page is loading
    this.setCustomLanModeSettingFlag(panelPageConsts.panelPage);
    // Set the mode as soon as the page is created
    // If the panel is already in ENGINEER mode, LAN's request to set mode to USER will be ignored.
    if (!this.anyAreaSet) {
      // We needn't wait for the panel to set the mode to USER when the LAN user logs in because USER and
      // UNDEFINED mean the same - the config edit lock isn't owned by any remote client. But we do
      // need a retry mechanism for the LAN to try setting the mode to USER if the LAN owns the lock when
      // the LAN user returns to the dashboard page. Instead of creating an async-await-retry loop here,
      // the poller will keep retrying to set the mode to USER, just in case the panel doesn't process the
      // first command.
      api.put('/Live/ClientStatus/Mode', panelPageConsts.userMode);
      this.setPanelPageModeStatus(panelPageConsts.userModeRequested);
      let happyPathSuccess = false;
      // Confirm that the configWriteLock has no lockOwner
      const resp = (await api.get('/Live/ConfigWriteLock')).data.Live.ConfigWriteLock;
      const isCwlActive = resp.IsActive;
      const cwlOwner = resp.LockOwner;
      if ((cwlOwner === '') && (isCwlActive === false)) {
        happyPathSuccess = true;
      }

      if (happyPathSuccess) {
        this.setPanelPageModeStatus(panelPageConsts.userModeSet);
      }
    } else {
      this.setPanelPageModeStatus(panelPageConsts.panelArmed);
    }
    this.loader = new Loader('panel', this.$store);
    this.loader.resetGLEndpoints();
    this.loader.populate('panelState/panelTitle');
    this.loader.populate('panelState/panelZones');
    this.loader.populate('panelState/panelOutputs');
    this.loader.populate('panelState/panelAreas');
    this.loader.populate('panelState/panelConnections');
    this.loader.populate('panelState/panelCounts');
    this.loader.populate('panelState/panelDiagnostics');
    this.loader.populate('panelState/silenceAlarm');
    this.loader.populate('panelState/panel');
    this.setPageCreated(false);
  },
  async beforeDestroy() {
    // clear this cached value
    window.localStorage.setItem('cachedLanLostLockStatus', 'false');
    // Clear mode setting when navigating away from panel page.
    this.clearLanModeSettingFlag();
    // Stop config write lock poller and set mode if navigating away from the page. Do this before other actions.
    const poller = Poller.getInstance();
    poller.stop();
    // Enter engineer's when navigating from panel page if LAN already
    // owns the lock (canWriteConfig depends on whether LAN owns the lock) when switching
    // between engineer's and user's page or the lock is not taken by any client.
    // Note: One of the condition is if LAN can edit write config, even then set Engineer's mode.
    // This is to cater for quick navigation from engineer's to panel and back to engineer's page.
    if (this.configWriteLock.canWriteConfig || !this.configWriteLock.isActive) {
      // Check if any areas are set
      const systemSet = await areaHelper.isSystemSet();
      // If the system is NOT set, we can proceed to navigate away as in ENGINEER mode
      if (!systemSet.result) {
        // The move to Engineer mode now handled by MainNavigation onClick().
      } else {
        // LAN remains in user mode, but set this variable to engineer mode to allow page navigation if system is armed.
        this.setPanelPageModeStatus(panelPageConsts.panelArmed);
      }
    } else {
      this.setPanelPageModeStatus(panelPageConsts.engineerModeSet); // LAN is already in engineer mode.
    }
    this.loader.unload('panelState/panelTitle');
    this.loader.unload('panelState/panelZones');
    this.loader.unload('panelState/panelOutputs');
    this.loader.unload('panelState/panelAreas');
    this.loader.unload('panelState/panelConnections');
    this.loader.unload('panelState/panelCounts');
    this.loader.unload('panelState/panelDiagnostics');
    this.loader.unload('panelState/silenceAlarm');
    this.loader.unload('panelState/panel');
    this.loader.dispose();
  },
  mounted() {
    this.setPageMounted(true);
    // clear this cached value.
    window.localStorage.setItem('cachedLanLostLockStatus', 'false');
    this.setCurrentPageIsPanel();
    this.setPageMounted(false);
  },
};
</script>

<style lang="scss">
  .header .ant-alert{
    margin-bottom: 1rem;
  }
</style>
