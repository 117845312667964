<template>
<div>

  <a-skeleton :loading="daysLeft == null" :title="false" :paragraph="{ rows:4, width:'100%' }">
    <card>
      <div :class="daysLeft > 0 ? 'card__stat card__stat--with-status' : 'card__stat'" @click="open">
        <p class="card__stat__status" v-if="daysLeft > 0">
            <span class="card__stat__status--in-progress">{{ $t('maintenance.soakTest.IN-PROGRESS') }}</span>
            <span class="card__stat__status--left">{{ $tc('maintenance.soakTest.DAYS-LEFT', daysLeft, { days: daysLeft }) }}</span>
        </p>

        <p class="stat">
            <font-awesome-icon :icon="['fal', 'tachometer-alt-average']" size="2x" />
        </p>
        <h3>{{ $t('maintenance.Soak') }}</h3>
      </div>
    </card>
  </a-skeleton>
    <a-drawer
    placement="right"
    :closable="false"
    @close="onClose"
    :destroyOnClose="true"
    :visible="soakTestDrawerVisible">

    <wizard :config="wizard" :state="$v" @save="startSoakTest" @done="onClose">
        <wizard-step name="start">

            <template slot="body">
            <a-form>
              <p-item-selector :label="$t('maintenance.drawer.soakTest.Select-zone')" :items="zones" :model="$v.form.zones" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
              <p-input :label="$t('maintenance.drawer.soakTest.Run-for-days')" :model="$v.form.runForDays" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"  />

              <h4>{{ $t('maintenance.drawer.soakTest.If-soak-test-fails') }}</h4>
              <p-input :label="$t('maintenance.drawer.soakTest.Run-for-days')" :model="$v.form.runForDaysIfFail" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            </a-form>
            </template>

            <template slot="footer">
                <a-button size="large" type="link" @click="onClose" style="float: left;">{{ $t('common.Cancel') }}</a-button>
                <a-button size="large" type="primary" @click="startSoakTest">{{ $t('maintenance.drawer.soakTest.Start-soak-test') }}</a-button>
            </template>

        </wizard-step>
      </wizard>
    </a-drawer>

     <a-modal :title="$t('maintenance.drawer.soakTest.Soak-test-is-current-in-progress')" v-model="modalVisible">
        <p>{{ $t('maintenance.drawer.soakTest.Do-you-want-to-end-the-current-test-and-start-a-new-one') }}</p>
        <template slot="footer">
            <a-row :gutter="8">
                <a-col :span="12" style="text-align: left;">
                    <a-button type="link" @click="modalVisible = false">{{ $t('common.Cancel') }}</a-button>
                </a-col>
                <a-col :span="12">
                    <a-button type="link" @click="endTest">{{ $t('common.End-test') }}</a-button>
                    <a-button type="primary" @click="modalVisible = false; soakTestDrawerVisible = true">{{ $t('common.Start-new-test') }}</a-button>
                </a-col>
            </a-row>
        </template>
    </a-modal>
</div>
</template>
<script>
import { required, between } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';

import Card from '@/app/shared/components/Card/card.vue';
import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';

import soakTest from '@/app/maintenance/shared/services/soak-test';

import { StateLoader } from '@/app/shared/mixins';

const storeNamespace = 'maintenanceState/soakTest';
const { mapState } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [StateLoader],
  components: {
    Card,
    Wizard,
    WizardStep,
  },
  props: ['disabled'],
  data() {
    return {
      loader: {
        storeNamespace,
      },
      soakTestDrawerVisible: false,
      modalVisible: false,
      form: {
        zones: [],
        runForDays: null,
        runForDaysIfFail: null,
      },
      wizard: {
        start: {
          title: this.$t('maintenance.drawer.soakTest.Start-soak-test'),
          next: [
            { key: 'save', buttonText: this.$t('maintenance.drawer.soakTest.Start-soak-test') },
          ],
        },
      },
    };
  },
  validations: {
    form: {
      zones: { required },
      runForDays: { required, between: between(0, 99) },
      runForDaysIfFail: {
        required,
        between: between(0, 99),
      },
    },
  },
  computed: {
    ...mapState(['daysLeft', 'zones']),
  },
  methods: {
    async onClose() {
      this.soakTestDrawerVisible = false;
    },
    startSoakTest() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      const zones = this.$v.form.zones.$model;
      const runForDays = parseInt(this.$v.form.runForDays.$model, 10);
      const runForDaysIfFail = parseInt(this.$v.form.runForDaysIfFail.$model, 10);

      soakTest.start(zones, runForDays, runForDaysIfFail, this.loader.instance);
      this.soakTestDrawerVisible = false;
    },
    open() {
      if (!this.disabled) {
        if (this.daysLeft > 0) {
          this.modalVisible = true;
        } else {
          this.soakTestDrawerVisible = true;
        }
      }
    },
    endTest() {
      soakTest.end(this.loader.instance);
      this.modalVisible = false;
    },
  },
};

</script>

<style lang="scss" scoped>
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

    .card__stat {

        &__status {
            font-size: 0.8rem;
            line-height: 1.2;

            &--in-progress {
                display: block;
                color:cornflowerblue;
            }
            &--days-left {
                display: block;
            }
        }

        &--with-status {
            margin-top: 0px;
        }
    }
</style>
