import { storeFactory } from '@/app/shared/services/store-helper';
import areaHelper from '@/app/panel/shared/services/area-helper';
import { limits } from '@/app/shared/constants';
import i18n from '@/app/shared/services/i18n';

// Local variables for checking conditions for handling context values.
let areaAlarm = false;
let faultAlarm = false;
let globalFaults = [];
let alarmSilenced = false;
// A set to retain values of areas in alarm.
const areasAlarmSet = new Set();
// local variable to monitor engineer reset requirement.
let engrResetRequired = false;
let panelLog = [];
/*
3 primary triggers controlling banner state in Vue file.
areaTrigger, faultTrigger, engineerRestoreTrigger.
Silence alarm banner shall always precede restore and 'panel still in faults' banner.
areaTrigger controls this and is always monitored on priority at Vue end.
Once areaTrigger is off, engineer reset requirement is monitored and appropriate banner,
(still in fault/perform restore) is displayed.
If still in faults - faultTrigger: True, engineerRestoreTrigger: True (still in faults banner)
If no faults - faultTrigger:False, engineerRestoreTrigger: True (Perform restore banner)

*/
const ZONE_LOG_FAULTS = [
  'TAMPER_ON_ZONE',
  'DETECTOR_MASKED',
  'DETECTOR_FAULT',
  'DAY_ALARM_ZONE_ACTIVE',
  'FIRE_ZONE_ACTIVE',
  'INTRUDER_ZONE_ACTIVE',
  'REMOTE_SET_FAIL_ZONE',
  'SET_FAIL_ZONE',
  'ZONE_LINE_FAIL',
];

// Map the zone fault log event to a fault message
function mapZoneEventToFault(event) {
  switch (event) {
    case 'TAMPER_ON_ZONE': return 'TAMPER';
    case 'DETECTOR_MASKED': return 'MASKED';
    case 'DETECTOR_FAULT': return 'DET_FAULT';
    case 'DAY_ALARM_ZONE_ACTIVE': return 'ALARM';
    case 'FIRE_ZONE_ACTIVE': return 'ALARM';
    case 'INTRUDER_ZONE_ACTIVE': return 'ALARM';
    case 'REMOTE_SET_FAIL_ZONE': return 'DET_FAULT';
    case 'SET_FAIL_ZONE': return 'DET_FAULT';
    case 'ZONE_LINE_FAIL': return 'DET_FAULT';
    default: return 'ALARM';
  }
}

// Filter the relevant zone log events
function getLatchedZoneFaultFromPanelLogs(index) {
  if (panelLog.length > 0) {
    const zoneLogs = panelLog.filter(l => ZONE_LOG_FAULTS.includes(l.Event) && l.Zone.Number === (index + 1));

    if (zoneLogs.length > 0 && zoneLogs !== undefined) {
      return mapZoneEventToFault(zoneLogs[0].Event);
    }
  }
  return 'ALARM';
}

function initialState() {
  return {
    performingSilencing: false,
    areaTrigger: false,
    faultTrigger: false,
    performingEngineerRestore: false,
    EngineerRestoreTrigger: false,
    panelFaults: [],
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints: () => ['liveActiveFaults', 'liveAreaInfo'],
    // constantly poll Live/AreaInfo to see if there are any faults or areas in alarm.
    async onPoll(context, { key, endpoint }) {
      if (key === 'liveAreaInfo') {
        engrResetRequired = false;
        for (let i = 0; i < limits.MAX_AREAS; i += 1) {
          const areaLive = endpoint.data.Live.areaInfo.Areas[i];
          const areaStatus = areaLive.AreaStatus;
          const resetRequired = areaLive.EngineerResetRequired;
          if (resetRequired) {
            engrResetRequired = true;
          }
          if (areaStatus === 'IN_ALARM') {
            // to set areaTrigger state to true.
            areaAlarm = true;
            if (!(areasAlarmSet.has(i))) {
              // add area in alarm to Set, (for monitoring if silence action taken from keypad).
              areasAlarmSet.add(i);
            }
          } else if (areaStatus !== 'IN_ALARM' && areasAlarmSet.has(i)) {
            // checking if alarm silenced from keypad by cross-checking if an area
            // previously in alarm is not anymore.
            areaAlarm = false;
            areasAlarmSet.delete(i);
            alarmSilenced = true;
            // Since alarm has been silenced from KPAD, need to check engineer restore requirement.
            if (engrResetRequired) {
              context.commit('set', { EngineerRestoreTrigger: true });
            }
          }
        }
      }
      if (key === 'liveActiveFaults') {
        const faults = await areaHelper.getActiveFaults(endpoint);
        globalFaults = faults;
        if (faults.length > 0) {
          // to set faultTrigger to true.
          faultAlarm = true;
          context.commit('set', { EngineerRestoreTrigger: true });
        } else if (faults.length === 0) {
          globalFaults = [];
          // Check for engineer restore done from KP
          if (!engrResetRequired && context.state.EngineerRestoreTrigger) {
            context.commit('set', { EngineerRestoreTrigger: false });
          }
        }
      }
      if (areaAlarm === true) {
        alarmSilenced = false;
        context.commit('set', { areaTrigger: true });
      } else {
        context.commit('set', { areaTrigger: false });
      }
      if (faultAlarm === true) {
        context.commit('set', { faultTrigger: true });
      } else {
        context.commit('set', { faultTrigger: false });
      }
      if (alarmSilenced && globalFaults.length === 0) {
        /*
        if the alarm was due to a fault, and faults > 0, the still-in-faults banner will show.
        i.e. faultTrigger- true , engineerRestoreTrigger - true.
        this if condition will be checked on each poll once the alarm is silenced and, when faults cleared,
        will set faultTrigger to false.
        If engineer reset is required,
        faultTrigger:False, engineerRestoreTrigger: True - Perform restore banner conditions met.
        */
        context.commit('set', { faultTrigger: false });
        alarmSilenced = false;
        faultAlarm = false;
      }
    },
    async silenceAlarm(context) {
      context.commit('set', { performingSilencing: true });
      const silenceAlarmEndpoint = '/Live/RemoteSilenceAlarm';
      // Bring the ad-hoc alarm silence endpoint into the scope of current endpoints
      if (window.globalThis.currentRequiredEndpoints.indexOf(silenceAlarmEndpoint) === -1) {
        window.globalThis.currentRequiredEndpoints.push(silenceAlarmEndpoint);
      }
      await api.put('/Live/RemoteSilenceAlarm', true);
      api.poll(500, '/Live/RemoteSilenceAlarm', (res) => {
        const doingRestore = res.data.Live.RemoteSilenceAlarm;
        if (doingRestore) {
          return true; // keep polling
        }
        // Remove the alarm silence endpoint
        const temp = window.globalThis.currentRequiredEndpoints;
        window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== silenceAlarmEndpoint);
        if (areaAlarm) {
          context.commit('set', { areaTrigger: false });
          context.commit('set', { performingSilencing: false });
          if (engrResetRequired) {
            context.commit('set', { EngineerRestoreTrigger: true });
            context.commit('set', { performingEngineerRestore: false });
          }
          areaAlarm = false;
        }
        if (faultAlarm) {
          if (engrResetRequired) {
            if (globalFaults.length === 0) {
              // silenced, reset required, check if faults fixed. If so, show restore banner.
              context.commit('set', { faultTrigger: false });
              context.commit('set', { EngineerRestoreTrigger: true });
              context.commit('set', { performingSilencing: false });
              faultAlarm = false;
            } else if (globalFaults.length > 0) {
              // silenced, reset required, faults still present, show still in faults banner.
              context.commit('set', { faultTrigger: true });
              context.commit('set', { EngineerRestoreTrigger: true });
              context.commit('set', { performingSilencing: false });
            }
          } else {
            // reset not required.
            context.commit('set', { EngineerRestoreTrigger: false });
            context.commit('set', { faultTrigger: false });
            faultAlarm = false;
          }
        }
        alarmSilenced = true;
        return false; // stop polling
      });
    },
    async engineerRestore(context) {
      context.commit('set', { performingEngineerRestore: true });
      const engineerRestoreEndpoint = '/Live/RemoteEngineerRestore';
      // Bring the ad-hoc engineer restore endpoint into the scope of current endpoints
      if (window.globalThis.currentRequiredEndpoints.indexOf(engineerRestoreEndpoint) === -1) {
        window.globalThis.currentRequiredEndpoints.push(engineerRestoreEndpoint);
      }
      await api.put(engineerRestoreEndpoint, true);
      api.poll(500, engineerRestoreEndpoint, (res) => {
        const doingRestore = res.data.Live.RemoteEngineerRestore;
        if (doingRestore) {
          return true; // keep polling
        }
        context.commit('set', { performingEngineerRestore: false });
        context.commit('set', { EngineerRestoreTrigger: false });
        const temp = window.globalThis.currentRequiredEndpoints;
        window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== engineerRestoreEndpoint);
        engrResetRequired = false;
        faultAlarm = false;
        return false; // stop polling
      });
    },
    // Creating a string list of all the faults/tampers/alarms on the system
    async listFaults(context) {
      const faultsList = [];

      // Getting the current active faults from area helper
      const newSystemFaults = await areaHelper.getActiveFaults();
      // If there are any new faults, they will be added to the FaultsList array
      newSystemFaults.forEach((el) => {
        if (faultsList.includes(el) === false) {
          faultsList.push(el);
        }
      });

      // Getting the current the current state of the zones
      const allZones = (await api.get('/Live/zoneInfo/Zones')).data.Live.zoneInfo.Zones;
      // If any zone has a new fault or tamper, add it to the faults array
      for (let i = 0; i < limits.MAX_ZONES; i += 1) {
        const zoneState = allZones[i].state;
        if (zoneState === ('TAMPER' || 'FAULT' || 'DET_FAULT' || 'MASKED')) {
          const zoneFaultString = `${i18n.t(`panel.FaultMessages.${zoneState}`, { index: i + 1 })}`;
          if (faultsList.includes(zoneFaultString) === false) {
            faultsList.push(zoneFaultString);
          }
        }
      }

      // Getting the current state of the areas and check their associated zones for alarms
      const allAreas = (await api.get('/Live/areaInfo/Areas')).data.Live.areaInfo.Areas;
      // Also capture any log event related to zones to check for any fixed but not silenced/restored alarms
      panelLog = (await api.get('/Log/Panel', { skip: 0, count: 20, refetch: false })).data.Log.Panel.filter(x => x.Zone);
      // Iterate through areas and check for faults
      if (allAreas && Array.isArray(allAreas)) {
        allAreas.forEach((area) => {
          if (area.AreaStatus === 'IN_ALARM') {
            const zoneIndexesInAlarm = area.AlarmZones.split(',').map(a => parseInt(a, 10));
            const zoneIndexesInTamper = area.TamperZones.split(',').map(a => parseInt(a, 10));

            // If any new zones in alarm  or tamper are found, add them to the faults list
            if ((area.TamperZones !== '') && zoneIndexesInTamper.length > 0) {
              zoneIndexesInTamper.forEach((i) => {
                const tamperString = `${i18n.t('panel.FaultMessages.TAMPER', { index: i + 1 })}`;
                if (faultsList.includes(tamperString) === false) {
                  faultsList.push(tamperString);
                }
              });
            }
            if ((area.AlarmZones !== '') && zoneIndexesInAlarm.length > 0) {
              zoneIndexesInAlarm.forEach((i) => {
                const zoneFault = getLatchedZoneFaultFromPanelLogs(i);
                const faultString = `${i18n.t(`panel.FaultMessages.${zoneFault}`, { index: i + 1 })}`;
                if (zoneFault !== undefined && faultsList.includes(faultString) === false) {
                  faultsList.push(faultString);
                }
              });
            }
          }
        });
      }

      // Commit the final faults list to local storage
      context.commit('set', { panelFaults: faultsList });
    },
  },
});

export default store;
