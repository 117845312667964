<template>
    <a-dropdown class="grid-filter">
        <a-menu slot="overlay">
            <a-menu-item @click="orderByChange(order.field)" v-for="(order, index) in options" :key="`order-${index}`">
                {{ order.name }}
            </a-menu-item>
        </a-menu>
        <a-button>
            {{ $t('common.OrderBy') }} <a-icon type="down" />
        </a-button>
    </a-dropdown>
</template>

<script>

export default {
  components: {

  },
  props: {
    options: { required: true },
  },
  methods: {
    orderByChange(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss">
</style>
