import Zones from './zones.vue';
import ZonesList from './pages/zones-list/zones-list.vue';
import Zone from './pages/zone/zone.vue';

const routes = [
  {
    path: '/zones',
    component: Zones,
    children: [
      {
        path: '',
        name: 'zones-list',
        component: ZonesList,
        meta: { breadcrumb: [{ nameRes: 'zones.navigationTitle' }] },
      },
      {
        path: 'zone/:index',
        name: 'zone',
        component: Zone,
        meta: { breadcrumb: [{ nameRes: 'zones.navigationTitle', link: 'zones-list' }, { nameRes: 'zone.navigationTitle' }] },
      },
    ],
  },
];

export default routes;
