<template>
<div class="card__head">
   <div class="card__head--left">
       <slot></slot>
    </div>
    <div class="card__head--right">
        <slot name="actions"></slot>
    </div>
</div>
</template>
<script>
export default {
  name: 'CardHeader',
};
</script>

<style lang="scss">
@import '../card.scss';

</style>
