import { createArray } from '@/app/shared/services/api';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { trim } from '@/app/shared/utils/text-helper';

function initialState() {
  return {
    form: {
      set: undefined,
      unset: undefined,
      status: undefined,
      output: undefined,
      numberEdit: undefined,
      items: createArray(10, () => ({ phoneNumber: undefined })),
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configCommsInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        set: endpoints.configCommsInfo.data.Config.commsInfo.SMSControl[0],
        unset: endpoints.configCommsInfo.data.Config.commsInfo.SMSControl[1],
        status: endpoints.configCommsInfo.data.Config.commsInfo.SMSControl[2],
        numberEdit: endpoints.configCommsInfo.data.Config.commsInfo.SMSControl[3],
        output: endpoints.configCommsInfo.data.Config.commsInfo.SMSControl[4],
        items: endpoints.configCommsInfo.data.Config.commsInfo.SMSDetails.map(d => ({ phoneNumber: trim(d.MobileNumber) })),
      });
    },
    async save(context) {
      const smsControls = (await api.get('/Config/commsInfo/SMSControl')).data.Config.commsInfo.SMSControl;

      smsControls[0] = context.state.form.set;
      smsControls[1] = context.state.form.unset;
      smsControls[2] = context.state.form.status;
      smsControls[3] = context.state.form.numberEdit;
      smsControls[4] = context.state.form.output;

      const data = {
        SMSControl: smsControls,
        SMSDetails: context.state.form.items.map(i => ({ MobileNumber: i.phoneNumber })),
      };

      await api.put('/Config/commsInfo', data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('communicationsState/smsControls/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['communicationsState/smsControls'] });
    },
  },
});


export default store;
