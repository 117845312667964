<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('communications.voice.navigationTitle') }}</h2>
      </a-row>
      <a-form>
        <p-select :label="$t('communications.voice.System-name')" :labelTooltip="$t('communications.voice.System-name-tooltip')" :model="$v.form.systemName"  display-style="small"
          :items="Object.keys(SYSTEM_NAME)" :valueExpr="(i) => SYSTEM_NAME[i]" :textExpr="(i) => $t(`enums.SYSTEM_NAME.${i}`)"
          :disabled="!configWriteLock.canWriteConfig" />
        <p-select :label="$t('communications.voice.Strategy')" :labelTooltip="$t('communications.voice.Strategy-tooltip')" :model="$v.form.strategy"  display-style="small"
          :items="Object.keys(VOICE_STRATEGY)" :valueExpr="(i) => VOICE_STRATEGY[i]" :textExpr="(i) => $t(`enums.VOICE_STRATEGY.${i}`)"
          :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('communications.voice.Acknowledgement')" :labelTooltip="$t('communications.voice.Acknowledgement-tooltip')" :model="$v.form.acknowledgement"  display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-input :label="$t('communications.voice.Voice-restrict-time-minutes')" :model="$v.form.voiceRestrictTimeMinutes"  display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <a-tabs defaultActiveKey="item-0">
          <a-tab-pane v-for="(v, index) in $v.form.items.$each.$iter" :key="`item-${index}`">
            <template slot="tab">
              <span :class="{ 'has-error': v.$anyError }">
                {{ parseInt(index, 10) + 1 }} <span class="ant-form-explain" v-if="v.$anyError">*</span>
              </span>
            </template>

            <p-input :label="$t('communications.voice.Phone-number')" :labelTooltip="$t('communications.voice.Phone-number-tooltip')" :model="v.phoneNumber" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
            <p-area-selector :label="$t('communications.voice.Valid-areas')" :labelTooltip="$t('communications.voice.Areas-tooltip')" :model="v.areas" :areasInfo="$v.form.areasInfo.$model" :areasConfig="$v.form.areasConfig.$model" display-style="small-alternative"
                            :disabled="!configWriteLock.canWriteConfig" />
            <p-input :label="$t('communications.voice.User-acknowledge-code')" :labelTooltip="$t('communications.voice.User-acknowledge-code-tooltip')" :model="v.userAcknowledgeCode" display-style="small-alternative"
            :customValidations="[{ key: 'lengthIsCorrect', message: $t('communications.voice.Must-be-less-than-six-digits') }, { key: 'allNumbers', message: $t('communications.voice.Only-digits-allowed') }]" :disabled="!configWriteLock.canWriteConfig" />
            <p-input :label="$t('communications.voice.Redials')" :labelTooltip="$t('communications.voice.Redials-tooltip')" :model="v.redials" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
            <p-input :label="$t('communications.voice.Time-out')" :labelTooltip="$t('communications.voice.Time-out-tooltip')" :model="v.timeout" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
            <p-switch :label="$t('communications.voice.Test-calls')" :labelTooltip="$t('communications.voice.Test-calls-tooltip')" :model="v.testCalls" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
            <div v-if="v.testCalls.$model" class="indent">
              <p-input :label="$t('communications.voice.Test-hours')" :model="v.testHours" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-input :label="$t('communications.voice.Test-minutes')" :model="v.testMinutes" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-input :label="$t('communications.voice.Interval-days')" :model="v.intervalDays" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-input :label="$t('communications.voice.Interval-hours')" :model="v.intervalHours" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-input :label="$t('communications.voice.Interval-minutes')" :model="v.intervalMinutes" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <div class="ant-row ant-form-item"  v-if="v.interval.$invalid">
                <div class="ant-col-sm-12 ant-col-md-8 ant-col-lg-6 ant-col-xl-5 ant-form-item-label"></div>
                <div class="ant-col-sm-24 ant-col-md-18 ant-col-lg-18 ant-col-xl-18 ant-form-item-control-wrapper">
                  <div class="ant-form-item-control has-error">
                    <div class="ant-form-explain">{{ $t('communications.voice.Valid-minutes') }}</div>
                  </div>
                </div>
              </div>
            </div>
            <p-select :label="$t('communications.voice.Event-types')" :labelTooltip="$t('communications.voice.Event-types-tooltip')" :model="v.eventTypes" display-style="small-alternative"
              :items="Object.keys(VOICE_EVENT_TYPES)" :valueExpr="(i) => VOICE_EVENT_TYPES[i]" :textExpr="(i) => $t(`enums.VOICE_EVENT_TYPES.${i}`)"
              :disabled="!configWriteLock.canWriteConfig" />

            <div class="indent" v-if="v.eventTypes.$model === VOICE_EVENT_TYPES.CUSTOM">
              <p-switch :label="$t('communications.voice.Arm')" :model="v.arm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Disarm')" :model="v.disarm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Special-Arm-Disarm')" :model="v.specialArmDisarm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Sub-area-SH-arm')" :model="v.subAreaSHArm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Sub-area-SH-disarm')" :model="v.subAreaSHDisarm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />

              <p-select :label="$t('communications.voice.Burglary-alarm')" :model="v.burglaryAlarm" display-style="small-alternative"
                :items="BUGLARY_ALARM_OPTIONS.values" :valueExpr="(i) => i.value" :textExpr="(i) => $t(`enums.BUGLARY_ALARM_OPTIONS.${i.key}`)"
                :disabled="!configWriteLock.canWriteConfig" />

              <p-switch :label="$t('communications.voice.Burglary-restore')" :model="v.burglaryRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Fire')" :model="v.fire" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Fire-restore')" :model="v.fireRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.PA-alarm')" :model="v.paAlarm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.PA-restore')" :model="v.paRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Medical')" :model="v.medical" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Medical-restore')" :model="v.medicalRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />

              <p-select :label="$t('communications.voice.Tamper')" :model="v.tamper" display-style="small-alternative"
                :items="TAMPER_OPTIONS.values" :valueExpr="(i) => i.value" :textExpr="(i) => $t(`enums.TAMPER_OPTIONS.${i.key}`)"
                :disabled="!configWriteLock.canWriteConfig" />

              <p-switch :label="$t('communications.voice.Tamper-restore')" :model="v.tamperRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Bypass')" :model="v.bypass" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Bypass-restore')" :model="v.bypassRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Technical')" :model="v.technical" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Technical-restore')" :model="v.technicalRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.AC-fault-restore')" :model="v.acFaultRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Wireless-faults')" :model="v.wirelessFaults" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Telecom-status')" :model="v.telecomStatus" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Access-control')" :model="v.accessControl" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Mask-restore')" :model="v.maskRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Special-log')" :model="v.specialLog" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Alarm-silenced')" :model="v.alarmSilenced" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Tech-alarm-silence')" :model="v.techAlarmSilence" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
              <p-switch :label="$t('communications.voice.Information')" :model="v.information" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
            </div>

          </a-tab-pane>
        </a-tabs>
      </a-form>
      <div class="align-right">
         <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
      </div>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />

  </div>
</template>

<script>
import { required, maxLength, between } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import { validMinutesValidator } from '@/app/shared/utils/date-helper';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import { BUGLARY_ALARM_OPTIONS, TAMPER_OPTIONS } from '@/app/shared/enums';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'communicationsState/voice';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

const TEST_CALLS = {
  YES: 1440,
  NO: 0,
};

const SYSTEM_NAME = {
  ALARM_SYSTEM: 'ALARM_SYSTEM',
  ALARM_SYSTEM_1: 'ALARM_SYSTEM_1',
  ALARM_SYSTEM_2: 'ALARM_SYSTEM_2',
  ALARM_SYSTEM_3: 'ALARM_SYSTEM_3',
  ALARM_SYSTEM_4: 'ALARM_SYSTEM_4',
};

const VOICE_STRATEGY = {
  SEQUENTIAL: 'SEQUENTIAL',
  REPEAT: 'REPEAT',
};

const VOICE_EVENT_TYPES = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM',
};

function allNumbers(value) {
  return value == null || /^\d+$/.test(value);
}

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
      TEST_CALLS,
      SYSTEM_NAME,
      VOICE_STRATEGY,
      VOICE_EVENT_TYPES,
      BUGLARY_ALARM_OPTIONS,
      TAMPER_OPTIONS,
    };
  },
  computed: {
    ...mapState(['form']),
  },
  validations: {
    form: {
      systemName: { required },
      strategy: { required },
      acknowledgement: { required, between: between(1, 10) },
      voiceRestrictTimeMinutes: { required, between: between(0, 30) },
      areasInfo: {},
      areasConfig: {},
      items: {
        $each: {
          phoneNumber: { maxLength: maxLength(20) },
          areas: { },
          redials: { required, between: between(0, 15) },
          timeout: { required, between: between(0, 99) },
          testCalls: { },
          interval: { validMinutes: validMinutesValidator(46079, 'intervalMinutes', 'intervalDays', 'intervalHours') },
          intervalDays: { between: between(0, 31) },
          intervalHours: { between: between(0, 23) },
          intervalMinutes: { between: between(0, 59) },
          testHours: { between: between(0, 23) },
          testMinutes: { between: between(0, 59) },
          userAcknowledgeCode: {
            required,
            allNumbers,
            lengthIsCorrect: maxLength(6),
          },

          eventTypes: { required },
          arm: {},
          disarm: {},
          specialArmDisarm: {},
          subAreaSHArm: {},
          subAreaSHDisarm: {},
          burglaryAlarm: { },
          burglaryRestore: {},
          fire: {},
          fireRestore: {},
          paAlarm: {},
          paRestore: {},
          medical: {},
          medicalRestore: {},
          tamper: {},
          tamperRestore: {},
          bypass: {},
          bypassRestore: {},
          technical: {},
          technicalRestore: {},
          acFaultRestore: {},
          wirelessFaults: {},
          telecomStatus: {},
          accessControl: {},
          maskRestore: {},
          specialLog: {},
          alarmSilenced: {},
          techAlarmSilence: {},
          information: {},
        },
      },
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
};
</script>

<style lang="scss">
  .indent .ant-form-item label {
    padding-left: 10px;
  }

</style>
