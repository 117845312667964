import { API } from '@/app/shared/services/api';

const api = new API();
const firmwareUpdateEndpoint = '/Live/System/Firmware';

class FirmwareUpdate {
  startTime = 0;

  endTime = 0;

  updateTime = 0;

  prevProgress = 0;

  dropPolledEndpoint = () => {
    // Drop firmware update specific endpoints from the current context
    const temp = window.globalThis.currentRequiredEndpoints;
    try {
      window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== firmwareUpdateEndpoint);
    } catch (err) {
      // nothing to do
    }
  }

  async startFWprogress(cb) {
    await api.put('/Live/System/Firmware/ApplyUpdate', true);

    let externalPromiseResolver;
    const promise = new Promise((resolve) => {
      externalPromiseResolver = resolve;
    });
    this.startTime = Date.now() / 1000;
    this.endTime = 500;
    const mintime = this.endTime;

    // Bring the ad-hoc firmware update endpoint into the scope of current endpoints
    if (window.globalThis.currentRequiredEndpoints.indexOf(firmwareUpdateEndpoint) === -1) {
      window.globalThis.currentRequiredEndpoints.push(firmwareUpdateEndpoint);
    }

    const pollingCall = api.poll(5000, firmwareUpdateEndpoint, (res) => {
      const progress = res.data.Live.System.Firmware.UpdateProgress;
      if (progress > 9995) {
        cb(0, 9999);
        externalPromiseResolver();
        this.dropPolledEndpoint();
        return false;
      }
      const now = Date.now() / 1000 - this.startTime;
      if (progress > this.prevProgress) {
        const eta = (now - this.updateTime) * (10000 - progress) / (progress - this.prevProgress) + now;
        if (eta < this.endTime) {
          this.endTime = this.endTime + (eta - this.endTime) / 16;
        } else {
          this.endTime = this.endTime + (eta - this.endTime) / 8;
        }
        if (this.endTime < mintime) {
          this.endTime = mintime;
        }
        this.updateTime = now;
        this.prevProgress = progress;
      }
      if (this.endTime <= now) {
        this.endTime = now + 5;
      }
      const n = this.endTime - now;
      cb(n, progress);
      return true;
    });
    await promise;
    pollingCall.polling.cancel();
    this.dropPolledEndpoint();
  }

  async endFWprogress() {
    api.cancelPolling();
    this.dropPolledEndpoint();
    await api.put('/Live/System/Firmware/ApplyUpdate', false);
    this.endTime = 500;
  }
}
const firmwareUpdate = new FirmwareUpdate();

export default firmwareUpdate;
