import Outputs from './outputs.vue';
import OutputsList from './pages/outputs-list/outputs-list.vue';
import Output from './pages/output/output.vue';
import UserDefinedOutputsList from './pages/user-defined-outputs-list/user-defined-outputs-list.vue';
import UserDefinedOutput from './pages/user-defined-output/user-defined-output.vue';
import LogicGatesList from './pages/logic-gates-list/logic-gates-list.vue';
import LogicGate from './pages/logic-gate/logic-gate.vue';

const routes = [
  {
    path: '/outputs',
    component: Outputs,
    children: [
      { path: '', redirect: { name: 'outputs-list' } },
      {
        path: 'outputs',
        name: 'outputs-list',
        component: OutputsList,
        meta: { breadcrumb: [{ nameRes: 'outputs.navigationTitle.Outputs' }] },
      },
      {
        path: 'outputs/output/:index',
        name: 'output',
        component: Output,
        meta: { breadcrumb: [{ nameRes: 'outputs.navigationTitle.Outputs', link: 'outputs-list' }, { nameRes: 'output.navigationTitle' }] },
      },
      {
        path: 'user-defined-outputs',
        name: 'user-defined-outputs-list',
        component: UserDefinedOutputsList,
        meta: { breadcrumb: [{ nameRes: 'outputs.navigationTitle.Outputs', link: 'outputs-list' }, { nameRes: 'userDefinedOutputs.navigationTitle' }] },
      },
      {
        path: 'user-defined-outputs/user-defined-output/:index',
        name: 'user-defined-outputs-user-defined-output',
        component: UserDefinedOutput,
        meta: { breadcrumb: [{ nameRes: 'outputs.navigationTitle.Outputs', link: 'outputs-list' }, { nameRes: 'userDefinedOutputs.navigationTitle', link: 'user-defined-outputs-list' }, { nameRes: 'userDefinedOutput.navigationTitle' }] },
      },
      {
        path: 'logic-gates',
        name: 'logic-gates-list',
        component: LogicGatesList,
        meta: { breadcrumb: [{ nameRes: 'outputs.navigationTitle.Outputs', link: 'outputs-list' }, { nameRes: 'logicGates.navigationTitle' }] },
      },
      {
        path: 'logic-gates/logic-gate/:index',
        name: 'logic-gates-logic-gate',
        component: LogicGate,
        meta: { breadcrumb: [{ nameRes: 'outputs.navigationTitle.Outputs', link: 'outputs-list' }, { nameRes: 'logicGates.navigationTitle', link: 'logic-gates-list' }, { nameRes: 'logicGate.navigationTitle' }] },
      },
    ],
  },
];

export default routes;
