<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('communications.cellular.navigationTitle') }}</h2>
      </a-row>
      <a-form>
        <p-input :label="$t('communications.cellular.Network-delay-fault')" :model="$v.form.networkDelayFault"  display-style="small" :disabled="!configWriteLock.canWriteConfig"/>

        <a-tabs defaultActiveKey="item-0">
          <a-tab-pane v-for="(v, index) in $v.form.items.$each.$iter" :key="`item-${index}`">
            <template slot="tab">
              <span :class="{ 'has-error': v.$anyError }">
                {{ parseInt(index, 10) + 1 }} <span class="ant-form-explain" v-if="v.$anyError">*</span>
              </span>
            </template>
            <p-input :label="$t('communications.cellular.APN')"
                     :labelTooltip="$t('communications.cellular.APN-tooltip')"
                     :model="v.apn" display-style="small-alternative"
                     :disabled="!configWriteLock.canWriteConfig" />
            <p-select :label="$t('communications.cellular.Purpose')"
                      :model="v.purpose" display-style="small-alternative"
                      :items="Object.keys(CELLULAR_PURPOSE)" :valueExpr="(i) => CELLULAR_PURPOSE[i]" :textExpr="(i) => $t(`enums.CELLULAR_PURPOSE.${i}`)"
                      :disabled="!configWriteLock.canWriteConfig" />
            <p-input :label="$t('communications.cellular.Username')"
                     :labelTooltip="$t('communications.cellular.Username-tooltip')"
                     :model="v.username" display-style="small-alternative"
                     :disabled="!configWriteLock.canWriteConfig" />
            <p-password :label="$t('communications.cellular.Password')"
                        :labelTooltip="$t('communications.cellular.Password-tooltip')"
                        :model="v.password" display-style="small-alternative"
                        :disabled="!configWriteLock.canWriteConfig" />
            <div v-if="v.purpose.$model === 'INTERNET'">
              <p-switch :label="$t('communications.cellular.Enable-auto-DNS')"
                        :labelTooltip="$t('communications.cellular.Enable-auto-DNS-tooltip')"
                        :model="v.enableAutoDNS" display-style="small"
                        :disabled="!configWriteLock.canWriteConfig" />
              <p-input :label="$t('communications.cellular.DNS-IP-address')"
                       :labelTooltip="$t('communications.cellular.DNS-IP-address-tooltip')"
                       :model="v.dnsIPAddress" display-style="small-alternative"
                       :disabled="!configWriteLock.canWriteConfig || v.enableAutoDNS.$model" />
              <p-input :label="$t('communications.cellular.Other-DNS-address')"
                       :labelTooltip="$t('communications.cellular.Other-DNS-address-tooltip')"
                       :model="v.otherDnsIPAddress" display-style="small-alternative"
                       :disabled="!configWriteLock.canWriteConfig || v.enableAutoDNS.$model" />
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-form>
      <div class="align-right">
         <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
      </div>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />

  </div>
</template>

<script>
import { required, maxLength, between } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';

import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'communicationsState/cellular';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

const CELLULAR_PURPOSE = {
  INTERNET: 'INTERNET',
  TELEPHONY: 'TELEPHONY',
};

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
      CELLULAR_PURPOSE,
    };
  },
  computed: {
    ...mapState(['form']),
  },
  validations: {
    form: {
      networkDelayFault: { required, between: between(0, 999) },
      items: {
        $each: {
          apn: { maxLength: maxLength(32) },
          purpose: { required },
          username: { maxLength: maxLength(32) },
          password: { maxLength: maxLength(32) },
          enableAutoDNS: { },
          dnsIPAddress: { maxLength: maxLength(15) },
          otherDnsIPAddress: { maxLength: maxLength(15) },
        },
      },
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
};
</script>
