import OutputExpanders from './output-expanders.vue';
import List from './pages/output-expanders-list/output-expanders-list.vue';
import OutputExpander from './pages/output-expander/output-expander.vue';

const routes = [
  {
    path: '/output-expanders',
    component: OutputExpanders,
    children: [
      {
        path: '',
        name: 'output-expanders-list',
        component: List,
        meta: { breadcrumb: [{ nameRes: 'outputExpanders.navigationTitle' }] },
      },
      {
        path: 'output-expander/:index',
        name: 'output-expander',
        component: OutputExpander,
        meta: { breadcrumb: [{ nameRes: 'outputExpanders.navigationTitle', link: 'output-expanders-list' }, { nameRes: 'outputExpander.navigationTitle' }] },
      },
    ],
  },
];

export default routes;
