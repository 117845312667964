/* eslint-disable no-await-in-loop */
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import {
  getWirelessZEMAddress,
} from '@/app/zone-expanders/shared/services/helpers';
import i18n from '@/app/shared/services/i18n';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import { toFaultString } from '@/app/shared/utils/activeFaults';
import { limits } from '@/app/shared/constants';

function initialState() {
  return {
    wiredZoneExpandersInstalled: undefined,
    wiredZoneExpandersMax: undefined,
    wirelessZoneExpandersInstalled: undefined,
    wirelessZoneExpandersMax: undefined,
    _items: [],
  };
}

function getWiredZEMInstalledZones(hub, configZoneInfoParts) {
  return hub.AssociatedZones
    .filter(z => configZoneInfoParts.data.Config.zoneInfo.Zones[z.Number - 1].ZoneType !== 'ISOLATED')
    .length;
}

function getWirelessZEMInstalledZones(infoHun, configHub, configZoneInfoParts) {
  return infoHun.AssociatedZones
    .filter((zone, index) => {
      const activeInputGroupIndex = Math.floor(index / 8); // wireless zones per ZEM address
      return configHub.ActiveInputs[activeInputGroupIndex] && configZoneInfoParts.data.Config.zoneInfo.Zones[zone.Number - 1].ZoneType !== 'ISOLATED';
    })
    .length;
}

// Return a list of zone indices for a particular wired ZEM.
function getListOfConfiguredZones(hubIndex, endpoints) {
  const configuredZones = [];
  for (let i = 0; i < endpoints.infoBusDevices.data.Info.BusDevices.wiredHubs[hubIndex].AssociatedZones.length; i += 1) {
    const zoneNumber = endpoints.infoBusDevices.data.Info.BusDevices.wiredHubs[hubIndex].AssociatedZones[i].Number;
    // Add the index for each zone, including those that are 'ISOLATED'.
    configuredZones.push(zoneNumber - 1);
  }
  return configuredZones;
}

const { api, store } = storeFactory(initialState, {
  getters: {
    items: state => state._items.filter(i => i.visible),
  },
  actions: {
    requiredEndpoints() {
      return ['infoBusDevices', 'liveActiveFaultsWiredHubsAndWirelessHubs', 'configBusDevices', 'configZoneInfoParts'];
    },
    async populate(context, { endpoints }) {
      context.commit('set', {
        wiredZoneExpandersInstalled: endpoints.infoBusDevices.data.Info.BusDevices.WiredHubInstalledCount,
        wiredZoneExpandersMax: endpoints.infoBusDevices.data.Info.BusDevices.WiredHubMaxCount,
        wirelessZoneExpandersInstalled: endpoints.infoBusDevices.data.Info.BusDevices.WirelessHubInstalledCount,
        wirelessZoneExpandersMax: endpoints.infoBusDevices.data.Info.BusDevices.WirelessHubMaxCount,
      });

      const settingsTooltip = i18n.t('wiredZoneExpander.settings.settingsTooltip');
      const cloneTooltip = i18n.t('wiredZoneExpander.settings.cloneTooltip');
      const deleteTooltip = i18n.t('wiredZoneExpander.settings.deleteTooltip');
      for (let i = 0; i < limits.MAX_WIRED_ZEM; i += 1) {
        const zoneList = getListOfConfiguredZones(i, endpoints);
        context.commit('setItemByIndex', {
          collection: '_items',
          index: i,
          item: {
            name: endpoints.infoBusDevices.data.Info.BusDevices.wiredHubs[i].Name,
            location: endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[i].Location,
            type: 'TYPE_WIRED',
            hubIndex: i,
            address: i,
            availableZones: endpoints.infoBusDevices.data.Info.BusDevices.wiredHubs[i].AssociatedZones.length - getWiredZEMInstalledZones(endpoints.infoBusDevices.data.Info.BusDevices.wiredHubs[i], endpoints.configZoneInfoParts),
            maxZones: endpoints.infoBusDevices.data.Info.BusDevices.wiredHubs[i].AssociatedZones.length,
            visible: endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[i].Installed,
            configuredZoneList: zoneList,
            settingsTooltip,
            cloneTooltip,
            deleteTooltip,
          },
        });
      }

      for (let i = 0; i < limits.MAX_WE_ZEM; i += 1) {
        const addresses = getWirelessZEMAddress(i, endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[i].ActiveInputs);
        const maxZones = addresses.length * 8; // max wireless zones per address
        context.commit('setItemByIndex', {
          collection: '_items',
          index: i + limits.MAX_WIRED_ZEM,
          item: {
            name: endpoints.infoBusDevices.data.Info.BusDevices.wirelessHubs[i].Name,
            location: addresses.length > 0 ? endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[addresses[0]].Location : '',
            type: 'TYPE_WIRELESS',
            hubIndex: i,
            address: addresses,
            availableZones: maxZones - getWirelessZEMInstalledZones(endpoints.infoBusDevices.data.Info.BusDevices.wirelessHubs[i], endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[i], endpoints.configZoneInfoParts),
            maxZones,
            visible: endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[i].Installed && addresses.length > 0,
            settingsTooltip,
            cloneTooltip,
            deleteTooltip,
          },
        });
      }
    },
    async onPoll(context, { dataStatus, key, endpoint }) {
      if (key === 'liveActiveFaultsWiredHubsAndWirelessHubs') {
        const items = [];
        for (let i = 0; i < limits.MAX_WIRED_ZEM; i += 1) {
          const batteryFault = endpoint.data.Live.ActiveFaults.Devices.wiredHubs[i].Battery;
          const busCommsFault = endpoint.data.Live.ActiveFaults.Devices.wiredHubs[i].BusComms;
          const caseTamperFault = endpoint.data.Live.ActiveFaults.Devices.wiredHubs[i].CaseTamper;
          const mainsFault = endpoint.data.Live.ActiveFaults.Devices.wiredHubs[i].Mains;
          const powerSupplyFault = endpoint.data.Live.ActiveFaults.Devices.wiredHubs[i].PowerSupply;

          items.push({ state: toFaultString(batteryFault, busCommsFault, caseTamperFault, mainsFault, powerSupplyFault) });
        }

        for (let i = 0; i < limits.MAX_WE_ZEM; i += 1) {
          const busCommsFault = endpoint.data.Live.ActiveFaults.Devices.wirelessHubs[i].BusComms;
          const caseTamperFault = endpoint.data.Live.ActiveFaults.Devices.wirelessHubs[i].CaseTamper;

          items.push({ state: toFaultString(busCommsFault, caseTamperFault) });
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: '_items', items });
      }
    },
    async delete(context, { indexes }) {
      for (let i = 0; i < indexes.length; i += 1) {
        const item = context.state._items[indexes[i]];
        if (item.type === 'TYPE_WIRED') {
          const objDefaults = await generateDefaultObject(`/Config/BusDevices/wiredHubs/${item.hubIndex}`);
          await api.put(`/Config/BusDevices/wiredHubs/${item.hubIndex}`, objDefaults);
        } else {
          const objDefaults = await generateDefaultObject(`/Config/BusDevices/wirelessHubs/${item.hubIndex}`);
          await api.put(`/Config/BusDevices/wirelessHubs/${item.hubIndex}`, objDefaults);
        }
      }

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('zoneExpandersState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('zoneExpandersState/addZoneExpander/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['zoneExpandersState/list'] });
    },
  },
});

export default store;
