export const APP_SECURITY = {
  LOW_SECURITY: 'LOW_SECURITY',
  HIGH_SECURITY: 'HIGH_SECURITY',
};


export const HOST_OPTIONS_UK = {
  URL: 'URL',
  IPA: 'IPA',
  CSL: 'CSL',
};
