import Q from 'q';
import { API } from '@/app/shared/services/api';

const api = new API();

function parse(value) {
  return {
    allowByPass: (0x01 & value) === 0x01,
    crossZone: (0x08 & value) === 0x08,
    normallyOpen: (0x20 & value) === 0x20,
    nonActivityInput: (0x10 & value) === 0x10,
    doubleKnock: (0x04 & value) === 0x04,
    maskTest: (0x40 & value) === 0x40,
    soakTest: (0x02 & value) === 0x02,
  };
}

function toInt(value) {
  let attributes = 0;
  if (value.allowByPass) attributes += 0x01;
  if (value.crossZone) attributes += 0x08;
  if (value.normallyOpen) attributes += 0x20;
  if (value.nonActivityInput) attributes += 0x10;
  if (value.doubleKnock) attributes += 0x04;
  if (value.maskTest) attributes += 0x40;
  if (value.soakTest) attributes += 0x02;
  return attributes;
}


async function start(zones, runForDays, ifFailsRunForDays, loader) {
  await api.put('/Live/zoneInfo/DaysOnSoak', runForDays);
  await api.put('/Config/sysInfo/Timers/SoakTime_days', ifFailsRunForDays);

  const getAttributesCalls = [];
  for (let i = 0; i < zones.length; i += 1) {
    getAttributesCalls.push(api.get(`/Config/zoneInfo/Zones/${zones[i]}/Attributes`));
  }
  const responses = await Q.all(getAttributesCalls);


  const calls = [];
  for (let i = 0; i < zones.length; i += 1) {
    const attributes = parse(responses[i].data.Config.zoneInfo.Zones[zones[i]].Attributes);
    attributes.soakTest = true;
    calls.push(api.put(`/Config/zoneInfo/Zones/${zones[i]}/Attributes`, toInt(attributes)));
  }

  await Q.all(calls);

  loader.reloadEndpoint('liveZoneInfoDaysOnSoak');
}

async function end(loader) {
  await api.put('/Live/zoneInfo/DaysOnSoak', 0);

  loader.reloadEndpoint('liveZoneInfoDaysOnSoak');
}


export default { start, end };
