import _ from 'lodash';

export default class GridHelper {
  static all(records) {
    return _(records).without(undefined).value();
  }

  static filter(columns, records, orderBy, orderByFunctions, filters, search, rowState) {
    // Copied from the previous sort function.
    function getRowState(item) {
      if (rowState !== null) {
        return (rowState(item) === 'active' ? 1 : 0);
      }
      return 0;
    }

    // Copied from the previous sort function.
    function getItemText(item) {
      const column = columns.find(c => c.dataIndex === orderBy);
      const value = _.isFunction(orderByFunctions[orderBy]) ? orderByFunctions[orderBy](item[orderBy]) : item[orderBy];
      const text = column.customRender ? column.customRender(value, item) : value;
      return text.toLowerCase ? text.toLowerCase() : text;
    }

    // Enumeration values to categorise a character.
    const CHAR_TYPE_DIGIT = 1; // decimal digit
    const CHAR_TYPE_LETTER = 2; // letter
    const CHAR_TYPE_SYMBOL = 3; // anything else

    // Get a type category for one character.
    function getCharType(char) {
      if ((char >= '0') && (char <= '9')) {
        return CHAR_TYPE_DIGIT;
      }
      if (((char >= 'A') && (char <= 'Z')) || ((char >= 'a') && (char <= 'z'))) {
        return CHAR_TYPE_LETTER;
      }
      return CHAR_TYPE_SYMBOL;
    }

    // Break apart a text string into an array, where each array element contains a subsequence
    // of characters of the same type category.
    function getTextElements(text) {
      const result = [];
      let resultIndex = 0;

      // Iterate through each character of the argument text string.
      for (let textIndex = 0; textIndex < text.length; textIndex += 1) {
        if (result.length <= resultIndex) {
          // Set the first array element to contain the first character.
          result[resultIndex] = text[textIndex];
        } else if (getCharType(result[resultIndex][0]) === getCharType(text[textIndex])) {
          // Add a further character of the same type category to the current array element.
          result[resultIndex] += text[textIndex];
        } else {
          // Set the next array element to contain a character of a different type category.
          resultIndex += 1;
          result[resultIndex] = text[textIndex];
        }
      }

      return result;
    }

    // Compare two values for their sort order:
    // - Return -1 if "a" should appear before "b".
    // - Return +1 if "a" should appear after "b".
    // - Return 0 if "a" and "b" are identical.
    function compare(a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return +1;
      }
      return 0;
    }

    // Compare two text strings for their "natural" sort order (as opposed to the default strict
    // character by character ASCII code comparison).
    // - Return -1 if "a" should appear before "b".
    // - Return +1 if "a" should appear after "b".
    // - Return 0 if "a" and "b" are identical.
    // Specifically:
    // - If the strings both contain a decimal value, the result of the comparison is based on
    //   their numerical order (rather than strict character by character alphabetical order).
    function naturalCompare(a, b) {
      // Break apart the two text strings into their letter, number, white space and symbol,
      // elements.
      const aElem = getTextElements(a);
      const bElem = getTextElements(b);

      // Compare the two text strings, element by element.
      let index = 0;
      while ((index < aElem.length) && (index < bElem.length)) {
        // Try interpreting the next element of each text string as a decimal value.
        const aVal = Number.parseInt(aElem[index], 10);
        const bVal = Number.parseInt(bElem[index], 10);

        if (!Number.isNaN(aVal) && !Number.isNaN(bVal)) {
          // BOTH elements are decimal numbers. Compare the elements by value.
          const diff = compare(aVal, bVal);
          if (diff !== 0) {
            return diff;
          }
        } else if (!Number.isNaN(aVal)) {
          // Element in "a" is a decimal number, but element in "b" is NOT. Numbers come after a
          // non-number.
          return +1;
        } else if (!Number.isNaN(bVal)) {
          // Element in "a" is NOT a decimal number, but element in "b" is. Non-numbers come before
          // a number.
          return -1;
        } else {
          // Neither element is a decimal number: just compare them alphabetically.
          const diff = compare(aElem[index], bElem[index]);
          if (diff !== 0) {
            return diff;
          }
        }

        // The current elements of each text string are identical: try comparing the next elements.
        index += 1;
      }

      // Text string "a" still has elements (but "b" does NOT). "a" is longer than "b", so comes
      // after it.
      if (index < aElem.length) {
        return +1;
      }

      // Text string "b" still has elements (but "a" does NOT). "a" is shorter than "b", so comes
      // before it.
      if (index < bElem.length) {
        return -1;
      }

      // The entirety of both text strings was compared, without finding any differences.
      return 0;
    }

    // Search
    let items = _.map(records, (item) => {
      if (!search.query) return item;

      let matchingItem;
      search.fields.forEach((field) => {
        const column = columns.find(c => c.dataIndex === field);
        const value = _.isFunction(orderByFunctions[orderBy]) ? orderByFunctions[orderBy](item[orderBy]) : item[orderBy];
        const text = column.customRender ? column.customRender(value, item) : item[field];

        if ((text || '').toString().toLowerCase().startsWith(search.query.toLowerCase())) {
          matchingItem = item;
        }
      });
      return matchingItem;
    });
    items = _(items).without(undefined).value();

    // Filter
    if (filters.size > 0) {
      items = _(items)
        .map((o) => {
          // Assume this item is visible UNLESS we find a filter key that it does NOT match.
          let matchesFilter = true;

          // Iterate through the different filter keys: each key identifies one property of the
          // item, whose value must match one of the filter values.
          const filterKeys = Array.from(filters.keys());
          filterKeys.forEach((key) => {
            // Get the filter values for this key.
            const filterValues = filters.get(key);

            // Assume this item is NOT visible unless we find a matching filter value.
            let matches = false;

            if (filterValues.length > 0) {
              const { isFilterByChar } = filterValues[0];
              if (isFilterByChar) {
                filterValues.forEach((v) => {
                  if (o[key].indexOf(v.value.value) !== -1) {
                    matches = true;
                  }
                });
              } else {
                filterValues.forEach((v) => {
                  const recordValue = o[key];
                  if (v.value.value === null) {
                    matches = true; // filter = "All": matches all items
                  } else if (_.isArray(recordValue)) {
                    if (recordValue.filter(rv => _.isEqual(rv, v.value.value)).length > 0) {
                      matches = true;
                    }
                  } else if (_.isObject(recordValue)) {
                    if (_.isMatch(recordValue, v.value.value)) {
                      matches = true;
                    }
                  } else if (_.isEqual(recordValue, v.value.value)) {
                    matches = true;
                  }
                });
              }
            }

            if (!matches) matchesFilter = false;
          });

          if (matchesFilter) return o;
          return undefined;
        }).value();

      items = _(items).without(undefined).value();
    }

    // sort & return
    items.sort((a, b) => {
      // First sort on each item's row state.
      const diff = compare(getRowState(a), getRowState(b));
      if (diff !== 0) {
        return diff;
      }

      const aTxt = getItemText(a);
      const bTxt = getItemText(b);

      // If both items are text strings, sort them according to their "natural" sort order.
      if ((typeof aTxt === 'string') && (typeof bTxt === 'string')) {
        return naturalCompare(aTxt, bTxt);
      }

      // Otherwise, fallback to a direct comparison.
      return compare(aTxt, bTxt);
    });

    return items;
  }

  static addFilter(currentFilters, { key, selectedFilters, isFilterByChar }) {
    const filters = new Map();

    // Copy (retain) the filter value lists for all other keys.
    currentFilters.forEach((oldList, oldKey) => {
      if (oldKey !== key) {
        filters.set(oldKey, oldList);
      }
    });

    // Copy the new list of filters values (for the specified key).
    const newList = selectedFilters.map(selectedFilter => ({
      value: selectedFilter,
      isFilterByChar,
    }));

    // Set the new list of filter values, for the specified key.
    filters.set(key, newList);
    return filters;
  }
}
