<template>
  <a-form-item :validate-status="model.$error ? 'error': 'success'" :label-col="style.labelSize || labelCol" :wrapper-col="style.wrapperSize || wrapperCol">
    <template slot="label">
      <a-tooltip v-if="labelTooltip" :title="labelTooltip">
        <label>{{ label }}</label>
      </a-tooltip>
      <label v-else>{{ label }}</label>
    </template>
    <a-input class="custom-input" :class="{ alternative: style.isAlternative }" type="text" v-model="model.$model" @change="onChange" :disabled="disabled">
      <div slot="addonAfter" v-if="hasSlot('addon')">
        <slot name="addon"></slot>
      </div>
    </a-input>
    <div class="ant-form-explain" v-if="model.$params.required && model.$error && !model.required">{{ $t('common.Field-is-required') }}</div>
    <div class="ant-form-explain" v-if="model.$params.between && model.$error && !model.between">{{ $t('common.Field-must-be-between', { min: model.$params.between.min, max: model.$params.between.max }) }}</div>
    <div class="ant-form-explain" v-if="model.$params.maxLength && model.$error && !model.maxLength">{{ $t('common.Field-must-have-less-than-characters', { max: model.$params.maxLength.max }) }}</div>
    <div class="ant-form-explain" v-for="customValidation in availableCustomValidations" :key="customValidation.key">{{ customValidation.message }}</div>

  </a-form-item>
</template>

<script>
export default {
  components: {

  },
  props: {
    label: {},
    model: {},
    'label-col': {},
    'wrapper-col': {},
    displayStyle: {},
    customValidations: {},
    labelTooltip: {},
    disabled: { default: false },
  },
  data() {
    if (this.displayStyle === 'small-alternative') {
      return {
        style: {
          isAlternative: true,
          labelSize: {
            sm: { span: 12 }, md: { span: 8 }, lg: { span: 6 }, xl: { span: 5 },
          },
          wrapperSize: {
            sm: { span: 24 }, md: { span: 18 }, lg: { span: 18 }, xl: { span: 18 },
          },
        },
      };
    }
    if (this.displayStyle === 'small') {
      return {
        style: {
          isAlternative: false,
          labelSize: {
            sm: { span: 12 }, md: { span: 8 }, lg: { span: 6 }, xl: { span: 5 },
          },
          wrapperSize: {
            sm: { span: 24 }, md: { span: 18 }, lg: { span: 18 }, xl: { span: 18 },
          },
        },
      };
    }
    return {
      style: {
        isAlternative: true,
      },
    };
  },
  methods: {
    onChange(e) {
      this.$emit('input', e.target.value);
    },
    hasSlot(name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
  },
  computed: {
    availableCustomValidations() {
      return this.customValidations
        ? this.customValidations.filter(v => this.model.$params[v.key] !== undefined && this.model.$error && !this.model[v.key])
        : [];
    },
  },
};
</script>

<style lang="scss">
  .custom-input .ant-input-group-addon { padding-right: 0; }
  form .ant-input.inputWithButton { width: calc(100% - 46px); }
</style>
