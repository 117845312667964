import { getField } from 'vuex-map-fields';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { ACCESS_TYPE } from '@/app/users/shared/enums';
import { READER_TYPE, KEYPAD_AND_READER_TYPE } from '@/app/keypads-and-readers/shared/enums';
import { limits } from '@/app/shared/constants';

function initialState() {
  return {
    availableIndexes: [],
    doors: [],
    minimumCodeLengths: 4,
    form: {
      name: undefined,
      accessType: ACCESS_TYPE.KEY_FOB.value,
      code: undefined,
      userType: undefined,
      index: undefined,
      associatedDoors: [],
      usedCodes: [],
    },
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {
    getField,
  },
  actions: {
    requiredEndpoints() {
      return ['configUserInfo', 'configKeypadInfo', 'configSysInfo'];
    },
    async populate(context, { endpoints }) {
      const availableIndexes = [];
      for (let i = 2; i <= limits.MAX_USERS; i += 1) {
        if (ACCESS_TYPE.fromCode(endpoints.configUserInfo.data.Config.userInfo.Users[i].Code) == null) {
          availableIndexes.push(i);
        }
      }

      const doors = [];
      const associatedDoors = [];

      for (let i = 0; i < endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads.length; i += 1) {
        const type = KEYPAD_AND_READER_TYPE.fromInt(endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[i].Type);
        const readerType = READER_TYPE.fromInt(endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[i].ReaderType);
        const name = endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[i].Name;
        if (type === KEYPAD_AND_READER_TYPE.TYPE_READER && (readerType === READER_TYPE.ACCESS_CONTROL || readerType === READER_TYPE.ENTRY_CONTROL)) {
          doors.push({ value: i, name });
        }
      }

      context.commit('setForm', {
        associatedDoors,
      });

      context.commit('set', { doors, availableIndexes });
    },
    async add(context, { fobIndex }) {
      const { index } = context.state.form;

      const data = {};

      data.Name = context.state.form.name;
      data.Code = [128 + fobIndex, 230, 0, 0, 0, 0];
      data.UserType = context.state.form.userType;

      data.UserCanAccess = [];

      for (let i = 0; i < limits.MAX_SUBAREAS; i += 1) { //  subareas.
        const door = context.state.form.associatedDoors.find(d => d.value === i);
        data.UserCanAccess[i] = door != null;
      }

      await api.put(`/Config/radioFobInfo/RadioFobs/${fobIndex}/UserForFob`, index);
      await api.put(`/Config/userInfo/Users/${index}`, data);

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('usersState/list/requiredEndpoints');
      const requiredEndpointsAddUser = (await this.dispatch('usersState/addUser/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const requiredEndpointsAddKeyfob = (await this.dispatch('usersState/addKeyFob/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp) && !requiredEndpointsAddUser.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddUser, requiredEndpointsAddKeyfob);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['usersState/list'] });
    },
  },
});

export default store;
