import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { NotFoundError } from '@/app/shared/errors';
import { KEYPAD_AND_READER_TYPE } from '@/app/keypads-and-readers/shared/enums';
import i18n from '@/app/shared/services/i18n';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import { toFaultString } from '@/app/shared/utils/activeFaults';
import { limits, wrlsDevicesDiagnosticsConsts } from '@/app/shared/constants';

const getDiagnostic = wrlsDevicesDiagnosticsConsts.gettingDiagnostics; // while loading the page the first time
const validState = 'valid';
const errorState = wrlsDevicesDiagnosticsConsts.retryingDiagnostics; // error condition
const wrlsKypdMaxDiagnosticMaskAndRetryLimit = 6; // based on 5 seconds poll duration, mask null value and retry for 30 seconds before reporting error
const wrlsKypdMaxDiagnosticUpdateLimit = 3; // max number to attempts to load diagnostics the first time, before reporting an error

let wrlsKypdDiagnosticState = getDiagnostic;
let wrlsKypdDiagnosticRetryCount = 0;
let wrlsKypdPreviousBatteryStatus = null;
let wrlsKypdPreviousSignalStrength = null;

function initialState() {
  return {
    name: undefined,

    form: {
      name: undefined,
      type: undefined,
      address: undefined,
      keypadsIndex: undefined,
      wirelessKpdsIndex: undefined,
      location: undefined,
      setsAreas: undefined,
      unsetsAreas: undefined,
      inAreas: undefined,
      readerLevel: undefined,
      areasInfo: undefined,
      areasConfig: undefined,
      levelsConfig: undefined,
      tagReader: undefined,
      autoWakeup: undefined,
      supervision: undefined,
      backLight: undefined,
      entryExitSound: undefined,
      fireKey: undefined,
      paKey: undefined,
    },

    state: undefined,
    diagnostics: [
      {
        isLoading: true,
        key: 'wirelessKeypad.Diagnostics.Status',
        value: undefined,
      },
      { isLoading: true, key: 'wirelessKeypad.Diagnostics.BatteryStatus', value: undefined },
      {
        isLoading: true, key: 'wirelessKeypad.Diagnostics.SignalStrength', value: undefined, format: undefined,
      },
    ],
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {},
  actions: {
    requiredEndpoints() {
      // TODO Investigate replacing liveActiveFaults with liveActiveFaultsWirelessKeypads
      return ['infoKeypadInfo', 'configKeypadInfo', 'infoAreaInfo', 'configAreaInfoNames', 'configLevelInfoNames', 'liveKeypadInfo', 'liveActiveFaults'];
    },
    async populate(context, { endpoints, payload }) {
      context.commit('set', {
        name: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Name,
        fireKey: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].FireKeyActive.toString(),
        paKey: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].TwoKeyPA,
      });
      const type = KEYPAD_AND_READER_TYPE.fromInt(endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Type);
      const address = payload.index - limits.MAX_WIRED_KP;
      const keypadsIndex = payload.index;
      const wirelessKpdsIndex = payload.index - limits.MAX_WIRED_KP;

      if (type !== KEYPAD_AND_READER_TYPE.TYPE_WKEYPAD) throw new NotFoundError();

      context.commit('setForm', {
        name: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Name.trim(),
        type: type.key,
        address,
        keypadsIndex,
        wirelessKpdsIndex,
        location: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Location.trim(),
        setsAreas: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].KeypadSets,
        unsetsAreas: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].KeypadUnsets,
        inAreas: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].InAreas,
        readerLevel: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].ReaderLevel,
        areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
        areasConfig: endpoints.configAreaInfoNames.data.Config.areaInfo,
        levelsConfig: endpoints.configLevelInfoNames.data.Config.areaInfo,
        tagReader: endpoints.configKeypadInfo.data.Config.keypadInfo.WirelessKpds[wirelessKpdsIndex].TagReader,
        autoWakeup: endpoints.configKeypadInfo.data.Config.keypadInfo.WirelessKpds[wirelessKpdsIndex].AutoWakeup,
        supervision: endpoints.configKeypadInfo.data.Config.keypadInfo.WirelessKpds[wirelessKpdsIndex].Supervision,
        backLight: endpoints.configKeypadInfo.data.Config.keypadInfo.WirelessKpds[wirelessKpdsIndex].BackLight,
        entryExitSound: endpoints.configKeypadInfo.data.Config.keypadInfo.WirelessKpds[wirelessKpdsIndex].EntryExitSound,
        fireKey: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].FireKeyActive.toString(),
        paKey: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].TwoKeyPA,
      });

      // Reset global variables
      wrlsKypdDiagnosticState = getDiagnostic;
      wrlsKypdDiagnosticRetryCount = 0;
      wrlsKypdPreviousBatteryStatus = null;
      wrlsKypdPreviousSignalStrength = null;
    },
    async onPoll(context, {
      dataStatus, key, payload, endpoint,
    }) {
      if (key === 'liveActiveFaults') {
        const batteryFault = endpoint.data.Live.ActiveFaults.WirelessKeypads[payload.index - limits.MAX_WIRED_KP].Battery;
        const caseTamperFault = endpoint.data.Live.ActiveFaults.WirelessKeypads[payload.index - limits.MAX_WIRED_KP].CaseTamper;
        const pollingFault = endpoint.data.Live.ActiveFaults.WirelessKeypads[payload.index - limits.MAX_WIRED_KP].Polling;
        const supervisionFault = endpoint.data.Live.ActiveFaults.WirelessKeypads[payload.index - limits.MAX_WIRED_KP].Supervision;

        context.commit('trySet', { dataStatus, data: { state: toFaultString(batteryFault, caseTamperFault, pollingFault, supervisionFault) } });

        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wirelessKeypad.Diagnostics.Status',
          item: {
            value: caseTamperFault === true ? 'TAMPER' : toFaultString(batteryFault, pollingFault, supervisionFault),
            tooltip() {
              return i18n.t(`wirelessKeypad.Diagnostics.Status.tooltips.${this.value}`);
            },
          },
        });
      }

      if (key === 'liveKeypadInfo') {
        let batteryStatusValue = endpoint.data.Live.keypadInfo.WirelessKpds[payload.index - limits.MAX_WIRED_KP].BatteryStatus;
        let signalStrengthValue = endpoint.data.Live.keypadInfo.WirelessKpds[payload.index - limits.MAX_WIRED_KP].SignalLevel;
        const gotNullValue = batteryStatusValue === signalStrengthValue;
        let formatStr = '{0}';

        // Initial state updating - Fetching for the first time
        if (gotNullValue && wrlsKypdDiagnosticState === getDiagnostic) {
          // Got null value in updating state
          batteryStatusValue = wrlsKypdDiagnosticState;
          signalStrengthValue = wrlsKypdDiagnosticState;
          wrlsKypdDiagnosticRetryCount += 1;
          if (wrlsKypdDiagnosticRetryCount === wrlsKypdMaxDiagnosticUpdateLimit) {
            wrlsKypdDiagnosticRetryCount = wrlsKypdMaxDiagnosticMaskAndRetryLimit; // force error state
            wrlsKypdDiagnosticState = errorState;
          }
        } else if (!gotNullValue) {
          // Got a valid value - transition from 'updating' or 'retrying' to 'valid' or remain in 'valid' state
          wrlsKypdDiagnosticState = validState;
          wrlsKypdPreviousBatteryStatus = batteryStatusValue; // backup previous good state
          wrlsKypdPreviousSignalStrength = signalStrengthValue; // backup previous good state
          wrlsKypdDiagnosticRetryCount = 0;
          formatStr = '{0}%';
        } else if (gotNullValue && wrlsKypdDiagnosticState === validState && wrlsKypdDiagnosticRetryCount < wrlsKypdMaxDiagnosticMaskAndRetryLimit) {
          // Got a null value in a valid state - silently retry for retry counts by masking the null value
          wrlsKypdDiagnosticRetryCount += 1;
          batteryStatusValue = wrlsKypdPreviousBatteryStatus;
          signalStrengthValue = wrlsKypdPreviousSignalStrength;
          formatStr = '{0}%';
        } else if (wrlsKypdDiagnosticRetryCount >= wrlsKypdMaxDiagnosticMaskAndRetryLimit) {
          // Exceeded retry attempts - don't mask null value
          wrlsKypdDiagnosticState = errorState;
          batteryStatusValue = wrlsKypdDiagnosticState;
          wrlsKypdPreviousBatteryStatus = wrlsKypdDiagnosticState;
          signalStrengthValue = wrlsKypdDiagnosticState;
          wrlsKypdPreviousSignalStrength = wrlsKypdDiagnosticState;
          wrlsKypdDiagnosticRetryCount += 1;
          formatStr = '{0}';
        }
        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'wirelessKeypad.Diagnostics.BatteryStatus', collection: 'diagnostics', item: { value: batteryStatusValue },
        });
        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'wirelessKeypad.Diagnostics.SignalStrength', collection: 'diagnostics', item: { value: signalStrengthValue, format: formatStr },
        });
      }
    },
    async save(context) {
      const { wirelessKpdsIndex, keypadsIndex } = context.state.form;

      const response = await api.get(`/Config/keypadInfo/Keypads/${keypadsIndex}`);
      const wirelessKpdResponse = await api.get(`/Config/keypadInfo/WirelessKpds/${wirelessKpdsIndex}`);

      const data = response.data.Config.keypadInfo.Keypads[keypadsIndex];
      const wirelessKpdData = wirelessKpdResponse.data.Config.keypadInfo.WirelessKpds[wirelessKpdsIndex];

      data.Name = context.state.form.name.padEnd(16);
      data.Location = context.state.form.location;
      data.KeypadSets = context.state.form.setsAreas;
      data.KeypadUnsets = context.state.form.unsetsAreas;
      data.InAreas = context.state.form.inAreas;
      data.ReaderLevel = context.state.form.readerLevel;
      data.FireKeyActive = context.state.form.fireKey === 'true';
      data.TwoKeyPA = context.state.form.paKey;

      wirelessKpdData.TagReader = context.state.form.tagReader;
      wirelessKpdData.AutoWakeup = context.state.form.autoWakeup;
      wirelessKpdData.Supervision = context.state.form.supervision;
      wirelessKpdData.BackLight = context.state.form.backLight;
      wirelessKpdData.EntryExitSound = context.state.form.entryExitSound;

      await api.put(`/Config/keypadInfo/Keypads/${keypadsIndex}`, data);
      await api.put(`/Config/keypadInfo/WirelessKpds/${wirelessKpdsIndex}`, wirelessKpdData);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('keypadsAndReadersState/wirelessKeypad/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['keypadsAndReadersState/wirelessKeypad'] });
    },
    async delete(context) {
      const { keypadsIndex } = context.state.form;
      const wirelessKeypadIndex = keypadsIndex - limits.MAX_WIRED_KP;

      const objDefaults = await generateDefaultObject(`/Config/keypadInfo/Keypads/${keypadsIndex}`);
      objDefaults.Type = 3;
      objDefaults.Name = `Wrls.Kpd ${(wirelessKeypadIndex + 1).toString().padStart(2, '0')}`;
      objDefaults.Location = ' '.repeat(16);

      await api.put(`/Config/keypadInfo/Keypads/${keypadsIndex}`, objDefaults);
      await api.put(`/Live/Devices/WirelessKeypads/${wirelessKeypadIndex}/DeleteLearnedDevice`, true);

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('keypadsAndReadersState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('keypadsAndReadersState/addKeypadOrReader/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['keypadsAndReadersState/list'] });
    },
  },
});

export default store;
