function getPGMArray(configEndpoint) {
  if (!configEndpoint
    || !configEndpoint.data
    || !configEndpoint.data.Config
    || !configEndpoint.data.Config.Endstation
    || !configEndpoint.data.Config.Endstation.PGMs) {
    return [];
  }

  return configEndpoint.data.Config.Endstation.PGMs;
}

function associatedDeviceIsPGM(pgmArray, outputType, associatedWith) {
  if (!pgmArray || !pgmArray.length || !associatedWith) {
    return false;
  }

  const numericalIndex = parseInt(associatedWith.subTypeIndex, 0);

  const pgmValue = pgmArray[numericalIndex];
  if (!pgmValue) {
    return false;
  }

  return associatedWith.subType === 'PGMs'
    && pgmValue.OutputType === outputType;
}

function liveEndstationFaults(activeFaultsEndpoint) {
  if (activeFaultsEndpoint
    && activeFaultsEndpoint.data
    && activeFaultsEndpoint.data.Live
    && activeFaultsEndpoint.data.Live.ActiveFaults
    && activeFaultsEndpoint.data.Live.ActiveFaults.Devices) {
    return activeFaultsEndpoint.data.Live.ActiveFaults.Devices.Endstation;
  }

  return null;
}

function bellFuseIsFaulted(activeFaultsEndpoint) {
  const faults = liveEndstationFaults(activeFaultsEndpoint);
  if (!faults) {
    return false;
  }

  return faults.BellFuse;
}

function bellTamperIsFaulted(activeFaultsEndpoint) {
  const faults = liveEndstationFaults(activeFaultsEndpoint);
  if (!faults) {
    return false;
  }

  return faults.BellTamper;
}

function warningDeviceIsFaulted(activeFaultsEndpoint) {
  const faults = liveEndstationFaults(activeFaultsEndpoint);
  if (!faults) {
    return false;
  }

  return faults.WarningDevice;
}

export function supportsLiveFaults(configEndpoint, outputType, associatedWith) {
  const pgmArray = getPGMArray(configEndpoint);

  return associatedDeviceIsPGM(pgmArray, outputType, associatedWith) && associatedWith.subTypeIndex === 0;
}

export function calculateLiveFaults(activeFaultsEndpoint) {
  const faults = [];
  if (bellFuseIsFaulted(activeFaultsEndpoint)) {
    faults.push('FUSE');
  }

  if (bellTamperIsFaulted(activeFaultsEndpoint)) {
    faults.push('TAMPER');
  }

  if (warningDeviceIsFaulted(activeFaultsEndpoint)) {
    faults.push('FAULT');
  }

  return faults;
}
