<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('settings.dateAndTime.navigationTitle') }}</h2>
      </a-row>
      <a-form>
        <p-select :label="$t('settings.dateAndTime.Timezone')" :model="$v.form.timezone" display-style="small"
          :items="timezones" :valueExpr="(i) => i.value" :textExpr="(i) => i.text"
          :disabled="!configWriteLock.canWriteConfig" />
        <p-date-picker :label="$t('settings.dateAndTime.Date')" :model="$v.form.date" display-style="small" :disabled="!configWriteLock.canWriteConfig"/>
        <p-time-picker :label="$t('settings.dateAndTime.Time')" :model="$v.form.time" display-style="small" :disabled="!configWriteLock.canWriteConfig"/>
        <p-switch :label="$t('settings.dateAndTime.Time-prompt')" :model="$v.form.timePrompt" :labelTooltip="$t('settings.dateAndTime.Time-prompt-tooltip')" :switchTooltip="toggleTooltipText($v.form.timePrompt)" display-style="small" :disabled="!configWriteLock.canWriteConfig" />

      </a-form>
      <div class="align-right">
          <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
      </div>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import TooltipHelper from '@/app/shared/services/tooltipHelper';
import { TIME_ZONE } from '@/app/settings/shared/enums';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'settingsState/dateAndTime';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
      timezones: Object.keys(TIME_ZONE).map(k => ({ value: parseInt(k, 10), text: this.$t(`enums.TIME_ZONE.${k}`) })).sort((a, b) => {
        const nameA = a.text.toUpperCase();
        const nameB = b.text.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }),
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
    ...mapState(['form']),
  },
  validations: {
    form: {
      timezone: {},
      date: {},
      time: {},
      timePrompt: {},
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
    toggleTooltipText: TooltipHelper.toggleTooltipText,
  },
};
</script>
