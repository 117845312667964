<template>
    <a-drawer
    placement="right"
    :closable="false"
    @close="onClose"
    :destroyOnClose="true"
    :visible="visible">

    <wizard :config="wizard" @done="onClose">
        <wizard-step name="start">

          <template slot="body" slot-scope="{ wizard }">
            <a-button class="draw-link" @click="wizard.navigateTo('select-areas')" type="link" block>
              <text-row :title="$t('maintenance.drawer.walkTest.Areas-walk-test')" title-icon="walking" />
            </a-button>
            <a-button class="draw-link" @click="wizard.navigateTo('select-zones')" type="link" block>
              <text-row :title="$t('maintenance.drawer.walkTest.Zones-walk-test')" title-icon="street-view" />
            </a-button>
          </template>

        </wizard-step>

        <wizard-step name="select-areas">
          <a-form>
            <a-skeleton :loading="isLoading" :paragraph="{ rows:1, width:'100%' }">
              <p-area-selector :label="$t('maintenance.drawer.walkTest.Select-areas')" :model="$v.form.areas" :areasInfo="$v.form.areasInfo.$model" :areasConfig="$v.form.areasConfig.$model"/>
            </a-skeleton>
          </a-form>
        </wizard-step>

        <wizard-step name="select-zones">
            <a-form>
              <p-select :label="$t('maintenance.drawer.walkTest.Select-zones')" :model="$v.form.zone"
                :items="availableZones" :valueExpr="(i) => i.index" :textExpr="(i) => i.name" />
            </a-form>
        </wizard-step>

        <wizard-step name="run-test">
          <template slot="title">
            {{ $t('maintenance.drawer.walkTest.Walk-test-in-progress') }}
             <a class="subtitle" @click="completeTest" v-if="isTesting">{{ $t('maintenance.drawer.walkTest.Stop-test')}}</a>
          </template>

          <template slot="body">
            <text-row v-for="zone in inProgressZones" :title="zone.name" :type="'testing'" :key="`testing-zone${zone.index}`" />

            <h3 v-if="areas != null">{{ foundZones.length }} {{ $t('maintenance.drawer.walkTest.tested')}}</h3>

            <text-row v-for="zone in foundZones" :title="zone.name" :type="'testComplete'" :key="`found-zone${zone.index}`" />
            <a-button block type="primary" @click="completeTest">{{ $t('common.Complete-test') }}</a-button>
          </template>

        </wizard-step>

      </wizard>
    </a-drawer>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';

import walkTest from '@/app/maintenance/shared/services/walk-test';

import { StateLoader } from '@/app/shared/mixins';

const storeNamespace = 'maintenanceState/walkTest';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [StateLoader],
  components: {
    Wizard,
    WizardStep,
  },
  props: {
    visible: { default: false },
  },
  data() {
    return {
      isTesting: false,
      type: '',
      zones: [],
      foundZoneIndexes: [],
      areas: [],
      loader: {
        storeNamespace,
      },
      wizard: {
        start: {
          title: this.$t('maintenance.drawer.walkTest.Start-walk-test'),
          next: [],
        },
        'select-areas': {
          title: this.$t('maintenance.drawer.walkTest.Start-walk-test'),
          next: [
            {
              key: 'run-test',
              buttonText: this.$t('maintenance.drawer.walkTest.Start-walk-test'),
              preNavigate: async () => {
                this.zones = await this.getZonesForAreas(this.$v.form.areas.$model);
                this.areas = this.$v.form.areas.$model;
              },
            },
          ],
        },
        'select-zones': {
          title: this.$t('maintenance.drawer.walkTest.Start-walk-test'),
          next: [
            {
              key: 'run-test',
              buttonText: this.$t('maintenance.drawer.walkTest.Start-walk-test'),
              preNavigate: async () => {
                this.zones = [{
                  index: this.$v.form.zone.$model,
                  name: this.availableZones.find(x => x.index === this.$v.form.zone.$model).name,
                  found: false,
                }];
                this.areas = null;
              },
            },
          ],
        },
        'run-test': {
          onShow: () => {
            this.isTesting = true;
            const self = this;
            if (self.areas != null) {
              walkTest.startAreasTest(self.areas, self.zones, (zone) => {
                self.foundZoneIndexes.push(zone.index);
              }).then(() => { this.isTesting = false; });
            } else {
              walkTest.startZoneTest(self.zones[0], (zone) => {
                self.foundZoneIndexes.push(zone.index);
              },
              (zone) => {
                self.foundZoneIndexes = self.foundZoneIndexes.filter(z => z !== zone.index);
              });
            }
          },
          hideCancel: true,
        },
      },
    };
  },
  validations: {
    form: {
      areas: {},
      areasInfo: {},
      areasConfig: {},
      zone: {},
    },
  },
  computed: {
    ...mapState(['availableZones', 'form']),
    ...mapGetters(['isLoading']),
    foundZones() {
      return this.zones.filter(z => this.foundZoneIndexes.includes(z.index));
    },
    inProgressZones() {
      return this.zones.filter(z => !this.foundZoneIndexes.includes(z.index));
    },
  },
  methods: {
    ...mapActions(['getZonesForAreas']),
    async onClose() {
      walkTest.finish();
      this.reset();
      this.$emit('onClose');
    },
    completeTest() {
      walkTest.finish();
      this.reset();
      this.$emit('onClose');
    },
    reset() {
      this.isTesting = false;
      this.type = '';
      this.zones = [];
      this.foundZoneIndexes = [];
      this.areas = [];
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

</style>
