/* eslint-disable no-await-in-loop */
import _ from 'lodash';
import { createArray } from '@/app/shared/services/api';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { getAssociatedWith } from '@/app/zones/shared/services/helpers';
import i18n from '@/app/shared/services/i18n';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import {
  limits, liveZonePageConsts, liveWirelessZoneExpanderConsts, LEVEL_IDS,
} from '@/app/shared/constants';
import zoneAreasHelper from '@/app/shared/services/zone-areas-helper';

let needToCheckDetectors = true;
let zoneAreas = null; // Cache of Config/zoneInfo/Zones/<list of active zones>/Areas array, or null if cache needs refreshing.

// From the subset details of /Config/zoneInfo for all zones, find array of zones that are in use (!ISOLATED)
function populateActiveZones(zonePartsArray) {
  const subsetOfZones = [];
  let zoneIndex = 0;
  zonePartsArray.forEach((zone) => {
    if (zone.ZoneType !== 'ISOLATED') {
      // Zone is in use, so add it to the set of zones to be shown.
      subsetOfZones.push(zoneIndex);
    }
    zoneIndex += 1;
  });
  return subsetOfZones;
}

function toAreaObject(areasArray) {
  const areas = [];
  let areaIndex = 0;

  areasArray.forEach((child) => {
    let l = 0;
    const levels = [];
    let areaHasLevels = false;
    child.Levels.forEach((level) => {
      if (level === true) {
        levels[l] = LEVEL_IDS[l];
        areaHasLevels = true;
      }
      l += 1;
    });
    areaIndex += 1;
    if (areaHasLevels === true) {
      const areaElement = i18n.t('common.Area').concat(' ').concat(areaIndex).concat(': ')
        .concat(levels.length > 1 ? i18n.t('Levels') : i18n.t('Level'))
        .concat(' ')
        .concat(levels.join(' '));
      areas.push({ key: areaIndex, name: areaElement });
      areaHasLevels = false;
    }
  });
  return areas;
}

function getState(state) {
  if (state === 'FAULT' || state === 'DET_FAULT' || state === 'UNKNOWN' || state === 'MASKED') return 'FAULT';
  if (state === 'TAMPER_2ND_IP' || state === 'TAMPER' || state === 'DOOR_FORCED') return 'TAMPER';
  return 'OK';
}

function initialState() {
  return {
    installed: undefined,
    max: undefined,
    _items: createArray(limits.MAX_ZONES, i => ({ index: i, visible: false, globalState_signalLevel: null })),
    activeZones: [], // Array of zone indexes
  };
}

// Initialise the item data with (cached) config and info data.
function initialiseItem(context, item, i, endpoints) {
  // Ensure index,address are initialised.
  item.index = i;
  item.address = i;

  item.name = endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[i].Name;
  item.location = endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[i].Location;
  item.zoneType = endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[i].ZoneType;
  item.areas = [];

  item.number = endpoints.infoZoneInfo.data.Info.zoneInfo.Zones[i].Number;
  item.associatedWith = getAssociatedWith(endpoints.infoZoneInfo.data.Info.zoneInfo.Zones[i].AssociatedWith);
  item.connectionType = item.associatedWith.type === 'WIRELESS_HUB' ? 'TYPE_WIRELESS' : 'TYPE_WIRED';

  // If the _item's zones state has not been set, it will be rendered as "zones.enums.state.undefined"
  // (which increases the height of the zone card and causes the grid to jump up and down), so set the state to UNKNOWN.
  // It will be updated when the next onPoll live endpoint is processed.
  try {
    if (context.state._items[i].state) {
      item.state = context.state._items[i].state;
    } else {
      item.state = 'UNKNOWN';
    }
  } catch {
    item.state = 'UNKNOWN';
  }
}

// Update the item data from the live zone endpoint.
function updateItem(context, lzEndpoint, item, i) {
  // Ensure index,address are populated.
  item.index = i;
  item.address = i;

  // Live data: zoneInfo may not have been populated yet, for those zones not on the current page
  const liveZone = lzEndpoint.data.Live.zoneInfo.Zones[i];
  if (liveZone) {
    item.omittable = liveZone.Omittable;
    item.managerOmitted = liveZone.ManagerOmitted;
    item.forceOmitted = liveZone.ForceOmitted;
    item.setOmitted = liveZone.SetOmitted;

    item.state = liveZone.state;
    item.globalState_state = getState(liveZone.state);
  } else if (!item.state) {
    // Don't have live state for this zone. If the _item's zones state has not been set, it will be rendered as "zones.enums.state.undefined"
    // (which increases the height of the zone card and causes the grid to jump up and down), so set the state to UNKNOWN.
    // It will be updated when the next onPoll live endpoint is processed.
    if (!context.state._items[i].state) {
      item.state = 'UNKNOWN';
    }
  }
}


const { api, store } = storeFactory(initialState, {
  getters: {
    items: state => state._items.filter(i => i.visible),
  },
  actions: {
    requiredEndpoints() {
      return ['configZoneInfoParts', 'infoZoneInfo', liveZonePageConsts.key, liveWirelessZoneExpanderConsts.key, 'liveActiveFaultsWirelessInputs'];
    },

    async populate(context, { endpoints }) {
      // Obtain the count of installed zones and the max zone count.
      const zoneInstalledCount = endpoints.infoZoneInfo.data.Info.zoneInfo.ZoneInstalledCount;
      const zoneMaxCount = endpoints.infoZoneInfo.data.Info.zoneInfo.ZoneMaxCount;
      context.commit('set', {
        installed: zoneInstalledCount,
        max: zoneMaxCount,
      });
      // Find the indexes of all zones that are not ISOLATED.
      context.commit('set', { activeZones: populateActiveZones(endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones) });

      const settingsTooltip = i18n.t('zones.settings.settingsTooltip');
      const cloneTooltip = i18n.t('zones.settings.cloneTooltip');
      const deleteTooltip = i18n.t('zones.settings.deleteTooltip');

      const items = [];
      for (let i = 0; i < limits.MAX_ZONES; i += 1) {
        const item = { index: i };
        // If this zone is ISOLATED, mark it invisible
        const zoneType = endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[i].ZoneType;
        if ((!zoneType) || (zoneType === 'ISOLATED')) {
          item.visible = false;
        } else {
          item.visible = true;
        }
        item.settingsTooltip = settingsTooltip;
        item.cloneTooltip = cloneTooltip;
        item.deleteTooltip = deleteTooltip;
        initialiseItem(context, item, i, endpoints);
        items.push(item);
      }
      // Both lines are needed to ensure the correct population of the areas
      context.state._items = items;
      context.commit('set', { _items: items });
      zoneAreas = null; // Force zone areas cache to be refreshed.
    },

    // eslint-disable-next-line no-unused-vars
    async onPoll(context, { dataStatus, key, endpoint }) {
      if (needToCheckDetectors) {
        needToCheckDetectors = false;
        // Ensure wireless zones are awake.
        const AllDetectorsAwake = (await api.get('/Live/Devices/Wireless/ActivateAllDetectors')).data.Live.Devices.Wireless.ActivateAllDetectors;
        if (AllDetectorsAwake === false) {
          await api.put('/Live/Devices/Wireless/ActivateAllDetectors', true);
        }
      }

      // There is no global cache of active zones list, or zone areas. So use the local cache.
      if (zoneAreas === null) {
        // Refresh the zone areas cache.
        // First: check if the active zones array has been set by populate()
        const az = context.state.activeZones;
        if ((az.length) && (context.state._items)) {
          // Start the areas merge processing...
          // Get the Config/zoneInfo/Zones/<active zones>/Areas cache, may take e.g. 700ms to populate.
          zoneAreas = (await api.get('/Config/zoneInfo/Zones/'.concat(context.state.activeZones).concat('/Areas'))).data.Config.zoneInfo.Zones;
          // Merge these zone areas into the state items array:
          const items = [];
          zoneAreas.forEach((zone, index) => {
            const item = { index };
            if (zone) {
              item.areas = toAreaObject(zone.Areas);
            }
            items.push(item);
          });
          context.commit('mergeCollectionByIndex', { collection: '_items', items });
        }
      }

      const items = [];
      for (let i = 0; i < limits.MAX_ZONES; i += 1) {
        const item = { index: i };

        if (key === liveZonePageConsts.key) { // 'liveZonePage'
          const zoneInfo = endpoint.data.Live.zoneInfo.Zones[i];
          if (zoneInfo) {
            updateItem(context, endpoint, item, i);
          }
        }

        if (key === 'liveWirelessHubs') {
          if (context.state._items[i]) {
            if (context.state._items[i].connectionType === 'TYPE_WIRELESS') {
              const detector = endpoint.data.Live.BusDevices.wirelessHubs[context.state._items[i].associatedWith.number].Detectors[context.state._items[i].associatedWith.zoneIndex];
              if (detector.SignalLevel != null) {
                item.globalState_signalLevel = detector.SignalLevel;
              }
            }
          }
        }

        // Set isWirelessFault to true if any radio detector fault is active.
        if (key === 'liveActiveFaultsWirelessInputs') {
          if (context.state._items[i]) {
            if (context.state._items[i].connectionType === 'TYPE_WIRELESS') {
              const {
                CaseTamper, Supervision, Battery, Polling,
              } = endpoint.data.Live.ActiveFaults.WirelessInputs[i - limits.WE_ZONE_OFFSET];
              if (CaseTamper === true || Supervision === true || Battery === true || Polling === true) {
                item.isWirelessFault = true;
              } else if (CaseTamper === false && Supervision === false && Battery === false && Polling === false) {
                item.isWirelessFault = false;
              }
            }
          }
        }

        if (context.state._items[i]) {
          if (context.state._items[i].connectionType != null) {
            const currentSignalLevel = item.globalState_signalLevel != null ? item.globalState_signalLevel : context.state._items[i].globalState_signalLevel;
            const currentWirelessFault = item.isWirelessFault != null ? item.isWirelessFault : context.state._items[i].isWirelessFault;
            const currentState = item.globalState_state != null ? item.globalState_state : context.state._items[i].globalState_state;
            if (context.state._items[i].connectionType === 'TYPE_WIRELESS') {
              if ((currentSignalLevel === 0) || (currentWirelessFault === true)) {
                item.globalState = 'FAULT';
              } else {
                item.globalState = currentState;
              }
            } else {
              item.globalState = currentState;
            }
          }
        }

        items.push(item);
      }
      context.commit('mergeCollectionByIndex', { collection: '_items', items });
    },

    async delete(context, { indexes }) {
      // When deleting an indivual zone, clear this variable to force recalculating
      // if any zones are in areas on panel page.
      window.globalThis.zoneLevelsRequireReread = true;
      const zoneAreaEndpointsToRefresh = [];
      for (let i = 0; i < indexes.length; i += 1) {
        // For each zone index being deleted
        const zoneI = indexes[i];
        const csZoneAreas = context.state._items[zoneI].areas;
        const endpoints = zoneAreasHelper.getZoneAreaEndpointsFromAreaStrs(context, csZoneAreas);
        endpoints.forEach((endp) => {
          zoneAreaEndpointsToRefresh.push(endp);
        });
        const objDefaults = await generateDefaultObject(`/Config/zoneInfo/Zones/${indexes[i]}`);
        objDefaults.Name = `Zone ${_.padStart(indexes[i] + 1, 3, '0')}`;
        objDefaults.Location = '                ';
        await api.put(`/Config/zoneInfo/Zones/${indexes[i]}`, objDefaults);
        if (context.state._items[indexes[i]].connectionType === 'TYPE_WIRELESS') {
          await api.put(`/Live/Devices/WirelessZones/${indexes[i] - limits.WE_ZONE_OFFSET}/DeleteLearnedDevice`, true);
        }
      }
      // Zone area endpoints may contain duplicates; de-dupe using Set constructor and spread syntax:
      const uniqZoneAreaEndpointsToRefresh = [...new Set(zoneAreaEndpointsToRefresh)];

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('zonesState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('zonesState/addZone/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      let endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);

      // Handle the zone area endpoints: take them all away, then add only the ones that will change:
      endpointsToUpdate = endpointsToUpdate.filter(endp => !zoneAreasHelper.zoneAreasEndpointsList.includes(endp));
      uniqZoneAreaEndpointsToRefresh.forEach((endp) => {
        endpointsToUpdate = endpointsToUpdate.concat(endp);
      });

      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['zonesState/list'] });
    },
  },
});

export default store;
