/* eslint-disable no-await-in-loop */
import _ from 'lodash';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { KEYPAD_AND_READER_TYPE } from '@/app/keypads-and-readers/shared/enums';
import i18n from '@/app/shared/services/i18n';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import { limits } from '@/app/shared/constants';

function initialState() {
  return {
    wiredReaderInstalled: undefined,
    wiredKeypadInstalled: undefined,
    wirelessKeypadInstalled: undefined,
    wiredKeypadsAndReadersMax: undefined,
    wirelessKeypadMax: undefined,
    items: [],
  };
}

// Return a list of zone indices for a particular wired keypad.
function getListOfConfiguredZones(peripheralIndex, endpoints) {
  const configuredZones = [];
  // If the keypad has any associated zones ...
  if (_.has(endpoints.infoKeypadInfo.data.Info.keypadInfo.Keypads[peripheralIndex], 'AssociatedZones')) {
    for (let i = 0; i < endpoints.infoKeypadInfo.data.Info.keypadInfo.Keypads[peripheralIndex].AssociatedZones.length; i += 1) {
      const zoneNumber = endpoints.infoKeypadInfo.data.Info.keypadInfo.Keypads[peripheralIndex].AssociatedZones[i].Number;
      // ... add the index for each zone, including those that are 'ISOLATED'.
      configuredZones.push(zoneNumber - 1);
    }
  }
  return configuredZones;
}

function hasActiveFault(value) {
  return Object.keys(value).filter(k => value[k] !== false).length > 0;
}

const { store, api } = storeFactory(initialState, {
  getters: {
    items: state => state.items.filter(i => i.visible),
  },
  actions: {
    requiredEndpoints() {
      return ['infoKeypadInfo', 'configKeypadInfo', 'configRadioDevicesLearnt', 'liveKeypadInfo', 'liveActiveFaultsWirelessKeypads', 'liveActiveFaultsKeypadsAndReaders'];
    },
    async populate(context, { endpoints }) {
      context.commit('set', {
        wiredKeypadsAndReadersMax: endpoints.infoKeypadInfo.data.Info.keypadInfo.WiredKeypadsAndReadersMaxCount,
        wirelessKeypadMax: endpoints.infoKeypadInfo.data.Info.keypadInfo.WirelessKeypadMaxCount,
        wiredReaderInstalled: endpoints.infoKeypadInfo.data.Info.keypadInfo.WiredReaderInstalledCount,
        wiredKeypadInstalled: endpoints.infoKeypadInfo.data.Info.keypadInfo.WiredKeypadInstalledCount,
        wirelessKeypadInstalled: endpoints.infoKeypadInfo.data.Info.keypadInfo.WirelessKeypadInstalledCount,
      });

      const items = [];
      for (let i = 0; i < limits.MAX_KEYPADS; i += 1) {
        const configEndpt = endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[i];
        const type = KEYPAD_AND_READER_TYPE.fromInt(configEndpt.Type);
        const settingsTooltip = i18n.t('keypadsAndReaders.settings.settingsTooltip');
        const cloneTooltip = i18n.t('keypadsAndReaders.settings.cloneTooltip');
        const deleteTooltip = i18n.t('keypadsAndReaders.settings.deleteTooltip');

        if (i < limits.MAX_WIRED_KP) {
          const zoneList = getListOfConfiguredZones(i, endpoints);
          const isWiredKeypad = (type === KEYPAD_AND_READER_TYPE.TYPE_KEYPAD) || (type === KEYPAD_AND_READER_TYPE.TYPE_READER) || (type === KEYPAD_AND_READER_TYPE.TYPE_REMOVABLE_KEYPAD);
          items.push({
            index: i,
            address: i,
            name: configEndpt.Name,
            type: type.key,
            location: configEndpt.Location,
            visible: isWiredKeypad,
            configuredZoneList: zoneList,
            settingsTooltip,
            cloneTooltip,
            deleteTooltip,
          });
        } else {
          // Wireless keypad
          const isWirelessKeypad = (type === KEYPAD_AND_READER_TYPE.TYPE_WKEYPAD);
          const isLearnt = endpoints.configRadioDevicesLearnt.data.Config.RadioDevicesLearnt.RadioKeypadsLearnt[i - limits.MAX_WIRED_KP];
          items.push({
            index: i,
            address: i - limits.MAX_WIRED_KP + 1,
            name: configEndpt.Name,
            type: type.key,
            location: configEndpt.Location,
            visible: isWirelessKeypad && isLearnt,
            settingsTooltip,
            cloneTooltip,
            deleteTooltip,
          });
        }
      }
      context.commit('mergeCollectionByIndex', { collection: 'items', items });
    },
    async onPoll(context, { key, dataStatus, endpoint }) {
      if (key === 'liveKeypadInfo') {
        const items = [];
        for (let i = 0; i < limits.MAX_KEYPADS; i += 1) {
          const item = { index: i };
          if (i >= limits.MAX_WIRED_KP) {
            const batteryStatus = endpoint.data.Live.keypadInfo.WirelessKpds[i - limits.MAX_WIRED_KP].BatteryStatus || 'NO_BATTERY';
            const signalLevel = endpoint.data.Live.keypadInfo.WirelessKpds[i - limits.MAX_WIRED_KP].SignalLevel;

            item.icons = [
              { type: 'battery', status: batteryStatus, tooltip: i18n.t(`keypadsAndReaders.battery.${batteryStatus}`) },
              { type: 'signal', status: signalLevel },
            ];
          }
          items.push(item);
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: 'items', items });
      }

      if (key === 'liveActiveFaultsKeypadsAndReaders') {
        const items = [];
        for (let i = 0; i < limits.MAX_WIRED_KP; i += 1) {
          if (context.state.items[i]) {
            const isReader = context.state.items[i].type === KEYPAD_AND_READER_TYPE.TYPE_READER.key;
            const endpt = isReader ? endpoint.data.Live.ActiveFaults.Devices.Readers[i] : endpoint.data.Live.ActiveFaults.Devices.Keypads[i];
            const item = {
              index: i,
              state: hasActiveFault(endpt) ? 'FAULT' : 'OK',
            };
            items.push(item);
          }
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: 'items', items });
      }

      if (key === 'liveActiveFaultsWirelessKeypads') {
        const items = [];
        for (let i = 0; i < limits.MAX_WIRED_KP; i += 1) {
          items.push(null);
        }
        for (let i = limits.MAX_WIRED_KP; i < limits.MAX_KEYPADS; i += 1) {
          const endpt = endpoint.data.Live.ActiveFaults.WirelessKeypads[i - limits.MAX_WIRED_KP];
          const item = {
            index: i,
            state: hasActiveFault(endpt) ? 'FAULT' : 'OK',
          };
          items.push(item);
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: 'items', items });
      }
    },
    async delete(context, { indexes }) {
      for (let i = 0; i < indexes.length; i += 1) {
        const objDefaults = await generateDefaultObject(`/Config/keypadInfo/Keypads/${indexes[i]}`);
        if (indexes[i] >= limits.MAX_WIRED_KP) {
          objDefaults.Type = 3;
          objDefaults.Name = `Wrls.Kpd ${(indexes[i] - limits.MAX_WIRED_KP + 1).toString().padStart(2, '0')}`;
          objDefaults.Location = ' '.repeat(16);
        } else {
          objDefaults.Name = '';
        }

        await api.put(`/Config/keypadInfo/Keypads/${indexes[i]}`, objDefaults);

        if (indexes[i] >= limits.MAX_WIRED_KP) {
          await api.put(`/Live/Devices/WirelessKeypads/${indexes[i] - limits.MAX_WIRED_KP}/DeleteLearnedDevice`, true);
        }
      }
      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('keypadsAndReadersState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('keypadsAndReadersState/addKeypadOrReader/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['keypadsAndReadersState/list'] });
    },
  },
});


export default store;
