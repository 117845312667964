<template>
    <a-card class="card card--log">
      <div class="card__head">
        <div class="card__head--left">
          <h3 :class="{ 'error': hasError }">
            <font-awesome-icon :class="{ 'error': hasError }" :icon="['fal', icon]" size="1x" v-if="icon != null" />
            <div v-if="item.LogEvent && item.LogInfo">
              {{ $t(`enums.HISTORY_TYPE.${item.type}`) }} - {{ $t(`enums.HISTORY_EVENT.${item.LogEvent}`) }} <br> Info - {{ item.LogInfo }}
            </div>
            <div v-else-if="item.LogEvent" >
              {{ $t(`enums.HISTORY_TYPE.${item.type}`) }} - {{ $t(`enums.HISTORY_EVENT.${item.LogEvent}`) }}
            </div>
            <div v-else >
              {{ $t(`enums.HISTORY_TYPE.${item.type}`) }} - {{ $t(`enums.HISTORY_EVENT.${item.Event}`) }}
            </div>
          </h3>
        </div>
        <div class="card__head--right">
          {{ date }}
        </div>
      </div>
      <div class="card__body">
        <p v-if="item.Device != null && item.Device.Type !== 'ENDSTATION'">
          {{ $t(`enums.ASSOCIATED_WITH.${item.Device.Type}`, { number: null })}}: {{ item.Device.Number }} {{ item.Device.Name ?  '-' : '' }} {{ item.Device.Name }}
        </p>
        <div v-if="item.ChannelChange != null">
          <p>{{ $t('history.Channel-change') }}: {{ item.ChannelChange.Channel }} - {{ $t(`enums.CHANNEL_CHANGE_REASON.${item.ChannelChange.Reason}`) }}</p>
          <p v-if="item.ChannelChange.Address != null">{{ $t('common.Address') }}: {{ item.ChannelChange.Address }}</p>
        </div>
        <p v-if="item.Device != null && item.Device.Type === 'ENDSTATION'">
          {{ $t(`enums.ASSOCIATED_WITH.${item.Device.Type}`, { number: null })}}
        </p>
        <p v-if="item.RemoteClient != null && item.RemoteClient.clientType != null">
          {{ $t(`enums.CLIENT_TYPE.${item.RemoteClient.clientType}`) }}
        </p>
        <p v-if="item.ClientType != null">
          {{ $t('common.Connection') }}: {{ $t(`enums.CLIENT_TYPE.${item.ClientType}`) }}
        </p>
        <p v-if="item.User != null">
            {{ $t('common.User') }}: {{ item.User.Number===999? $t('users.MasterManager') : item.User.Number }} {{ item.User.Name ?  '-' : '' }} {{ item.User.Name }}
        </p>
        <p v-if="item.Zone != null">
          {{ $t('common.Zone') }}: {{ item.Zone.Number }} {{ item.Zone.Name ?  '-' : '' }} {{ item.Zone.Name }}
        </p>
        <p v-if="item.Voice != null">
          {{ $t('common.Voice') }}: {{ item.Voice.PhoneNumber }}
        </p>
        <p v-if="item.CommsModule != null">
          {{ $t(`enums.COMMSFAULT_TYPE.${item.CommsModule.Type}`) }} {{ $t(`enums.COMMSFAULT_SLOT.${item.CommsModule.Slot}`) }}
        </p>
        <p v-if="item.CommsFault != null">
          {{ $t('common.CommsFault') }}:  {{ $t(`enums.COMMSFAULT_TYPE.${item.CommsFault.Type}`) }} {{ $t(`enums.COMMSFAULT_SLOT.${item.CommsFault.Slot}`) }}
        </p>
        <div v-if="item.Areas != null">
          <p v-if="item.Areas[0].Level != null" style="width: 1000px; word-wrap: break-word">
            {{ $t('common.Areas') }}: {{ item.Areas.map(a=> a.Name + ' (' + $t("common.Level") + ': ' + a.Level.Name + ')').join(',') }}
          </p>
          <p v-else style="width: 1000px; word-wrap: break-word">
            {{ $t('common.Areas') }}: {{ item.Areas.map(a=> a.Name).join(',') }}
          </p>
        </div>
        <p v-if="item.ArcPathDeviceId != null" style="width: 1000px; word-wrap: break-word">
          {{ $t('communications.arcOptions.ArcPathData', { arc: arc, path: path, commsmod: commsmod}) }}
        </p>
        <p v-if="item.Arc != null" style="width: 1000px; word-wrap: break-word">
          {{ $t('communications.arcOptions.ARC-title', { number: item.Arc }) }}
        </p>
        <p v-if="item.EngrMode != null && item.EngrMode.clientType != null">
          {{ $t(`enums.CLIENT_TYPE.${item.EngrMode.clientType}`) }}
        </p>
      </div>
    </a-card>

</template>
<script>
import { DateTime } from 'luxon';
import { TIME_ZONE } from '@/app/settings/shared/enums';
import { defaultLocale } from '@/app/environment/environment';

export default {
  name: 'List',
  props: {
    item: { required: true },
    timeZoneIndex: { required: true },
  },
  data() {
    return {
      Events: {
        LOG_EMPTY: { icon: 'exclamation-triangle', error: false },
        ALARM_ON_ZONE: { icon: 'exclamation-triangle', error: true },
        TAMPER_ON_ZONE: { icon: 'exclamation-triangle', error: true },
        DURESS_CODE: { icon: 'exclamation-triangle', error: true },
        TWO_KEY_PA: { icon: 'exclamation-triangle', error: true },
        CODE_GUESSING: { icon: 'exclamation-triangle', error: true },
        SYSTEM_RESTART: { icon: 'exclamation-triangle', error: true },
        BROWN_OUT: { icon: 'exclamation-triangle', error: true },
        LOW_VOLTS: { icon: 'exclamation-triangle', error: true },
        MAINS: { icon: 'exclamation-triangle', error: true },
        BATTERY_DISCONNECT: { icon: 'exclamation-triangle', error: true },
        BATTERY_LOAD_FAIL: { icon: 'exclamation-triangle', error: true },
        TELECOM_LINE: { icon: 'exclamation-triangle', error: true },
        HR_ON_ZONE: { icon: 'exclamation-triangle', error: true },
        ID_LINE_SHORT: { icon: 'exclamation-triangle', error: true },
        FUSE_1: { icon: 'exclamation-triangle', error: true },
        FUSE_2: { icon: 'exclamation-triangle', error: true },
        FUSE_3: { icon: 'exclamation-triangle', error: true },
        FUSE_4: { icon: 'exclamation-triangle', error: true },
        FUSE_5: { icon: 'exclamation-triangle', error: true },
        FUSE_6: { icon: 'exclamation-triangle', error: true },
        FUSE_7: { icon: 'exclamation-triangle', error: true },
        FUSE_8: { icon: 'exclamation-triangle', error: true },
        RS485: { icon: 'exclamation-triangle', error: true },
        CASE_TAMPER: { icon: 'exclamation-triangle', error: true },
        SYSTEM_TAMPER: { icon: 'exclamation-triangle', error: true },
        DIGI_STU_SIREN1: { icon: 'exclamation-triangle', error: true },
        DIGI_STU_SIREN2: { icon: 'exclamation-triangle', error: true },
        DIGI_STU_STROBE: { icon: 'exclamation-triangle', error: true },
        INVALID_TAG: { icon: 'exclamation-triangle', error: true },
        DOOR_LEFT_OPEN: { icon: 'exclamation-triangle', error: true },
        DOOR_FORCED: { icon: 'exclamation-triangle', error: true },
        DEVICE_FAIL: { icon: 'exclamation-triangle', error: true },
        EXCESS_CHARGE: { icon: 'exclamation-triangle', error: true },
        EXCESS_CURRENT: { icon: 'exclamation-triangle', error: true },
        BATTERY_CRITICAL: { icon: 'exclamation-triangle', error: true },
        MODEM_FAILED: { icon: 'exclamation-triangle', error: true },
        STU_TELECOM_LINE: { icon: 'exclamation-triangle', error: true },
        SET_FAIL: { icon: 'exclamation-triangle', error: true },
        WARD_ALARM: { icon: 'exclamation-triangle', error: true },
        CLEAN_STARTED: { icon: 'exclamation-triangle', error: true },
        DIGI_FAIL_TONE: { icon: 'exclamation-triangle', error: true },
        DIGI_FAIL_COMMS: { icon: 'exclamation-triangle', error: true },
        CONFIRMED_OUTPUT: { icon: 'exclamation-triangle', error: true },
        CHC_CALL_FAILED: { icon: 'exclamation-triangle', error: true },
        ZONE_FORCE_ARMED: { icon: 'exclamation-triangle', error: true },
        STU_FAIL_COMMS: { icon: 'exclamation-triangle', error: true },
        MAINS_FAIL_ALARM: { icon: 'exclamation-triangle', error: true },
        ZONE_LINE_FAIL: { icon: 'exclamation-triangle', error: true },
        STU_ONE_PATH_FAIL: { icon: 'exclamation-triangle', error: true },
        INVALID_CODE_USED: { icon: 'exclamation-triangle', error: true },
        DETECTOR_FAULT: { icon: 'exclamation-triangle', error: true },
        DETECTOR_MASKED: { icon: 'exclamation-triangle', error: true },
        EDS_SOAK_FAIL: { icon: 'exclamation-triangle', error: true },
        ALARM_TRIGGER: { icon: 'exclamation-triangle', error: true },
        DIGI_TRIGGER: { icon: 'exclamation-triangle', error: true },
        EDS_24HR_ZN_TAMPER: { icon: 'exclamation-triangle', error: true },
        EDS_FORCE_ALARM: { icon: 'exclamation-triangle', error: true },
        ZONE_WALK_FAILED: { icon: 'exclamation-triangle', error: true },
        FLOOD_ACTIVE: { icon: 'exclamation-triangle', error: true },
        HYDROG_PEROX_ACTIVE: { icon: 'exclamation-triangle', error: true },
        SHORE_DC_PROBLEM: { icon: 'exclamation-triangle', error: true },
        SHORE_AC_PROBLEM: { icon: 'exclamation-triangle', error: true },
        HYDROGEN_ACTIVE: { icon: 'exclamation-triangle', error: true },
        POWER_FAULT_ACTIVE: { icon: 'exclamation-triangle', error: true },
        FIRE_ZONE_ACTIVE: { icon: 'exclamation-triangle', error: true },
        DAY_ALARM_ZONE_ACTIVE: { icon: 'exclamation-triangle', error: true },
        INTRUDER_ZONE_ACTIVE: { icon: 'exclamation-triangle', error: true },
        RADIO_LOW_BATTERY: { icon: 'exclamation-triangle', error: true },
        RADIO_TAMPER: { icon: 'exclamation-triangle', error: true },
        RADIO_SUPERVN_FAIL: { icon: 'exclamation-triangle', error: true },
        FIRE_KEY_PRESSED: { icon: 'exclamation-triangle', error: true },
        RADIO_HUB_JAM: { icon: 'exclamation-triangle', error: true },
        RADIO_HUB_JAM_RST: { icon: 'exclamation-triangle', error: true },
        POLL_FAILS: { icon: 'exclamation-triangle', error: true },
        RADIO_FOB_PA: { icon: 'exclamation-triangle', error: true },
        WD_FAULT: { icon: 'exclamation-triangle', error: true },
        FAULT_INPUT_OPEN: { icon: 'exclamation-triangle', error: true },
        HU_INPUT_OPEN: { icon: 'exclamation-triangle', error: true },
        TAMPER_INPUT_OPEN: { icon: 'exclamation-triangle', error: true },
        POLL_FAILS_INPUT: { icon: 'exclamation-triangle', error: true },
        SMS_CALL_FAIL: { icon: 'exclamation-triangle', error: true },
        VOICE_SD_CARD_FAIL: { icon: 'exclamation-triangle', error: true },
        AUTO_UPLOAD_FAIL: { icon: 'exclamation-triangle', error: true },
        BELL_ON: { icon: 'exclamation-triangle', error: true },
        GSM_JAMMING: { icon: 'exclamation-triangle', error: true },
        REMOTE_SET_FAIL_ZONE: { icon: 'exclamation-triangle', error: true },
        SILENT_MEDICAL_INPUT: { icon: 'exclamation-triangle', error: true },
      },
      LocalLogs: {
        NONE: { icon: 'exclamation-triangle', error: true },
        E_SC_RESP_CIE: { icon: 'exclamation-triangle', error: true },
        E_SC_RESP_CRE: { icon: 'exclamation-triangle', error: true },
        E_SC_RESP_CME: { icon: 'exclamation-triangle', error: true },
        E_SC_RESP_PRE: { icon: 'exclamation-triangle', error: true },
        E_SC_RESP_PIE: { icon: 'exclamation-triangle', error: true },
        E_SC_RESP_PSE: { icon: 'exclamation-triangle', error: true },
        E_CODE_FAIL: { icon: 'exclamation-triangle', error: true },
        E_CLAIMING_FAIL: { icon: 'exclamation-triangle', error: true },
        E_MT_ERR: { icon: 'exclamation-triangle', error: true },
        E_MT_EXC: { icon: 'exclamation-triangle', error: true },
        E_CFG_HDR_FAIL: { icon: 'exclamation-triangle', error: true },
        E_CFG_FAIL: { icon: 'exclamation-triangle', error: true },
        E_HT_ERR: { icon: 'exclamation-triangle', error: true },
        E_HT_EXC: { icon: 'exclamation-triangle', error: true },
      },
    };
  },
  computed: {
    date() {
      if (this.item.DateTime) {
        return DateTime.fromISO(String(this.item.DateTime)).setZone(TIME_ZONE[this.timeZoneIndex]).setLocale(defaultLocale).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      }
      return '';
    },
    icon() {
      let eventOrLog = null;
      let icon = null;
      try {
        eventOrLog = this.item.Event ? 'event' : null;
        // eslint-disable-next-line no-empty
      } catch (error) { }
      if (eventOrLog == null) {
        try {
          eventOrLog = this.item.LogEvent ? 'localLog' : null;
          // eslint-disable-next-line no-empty
        } catch (error) { }
      }

      if (eventOrLog === 'event') {
        icon = this.Events[this.item.Event] != null ? this.Events[this.item.Event].icon : null;
      } else if (eventOrLog === 'localLog') {
        icon = this.LocalLogs[this.item.LogEvent] != null ? this.LocalLogs[this.item.LogEvent].icon : null;
      }

      if (icon == null) {
        if (this.item.Zone != null) {
          return 'street-view';
        }

        if (this.item.User != null) {
          return 'users';
        }

        if (this.item.Voice != null) {
          return 'volume';
        }

        return 'columns';
      }
      return icon;
    },
    hasError() {
      let eventOrLog = null;
      let isError = false;
      try {
        eventOrLog = this.item.Event ? 'event' : null;
        // eslint-disable-next-line no-empty
      } catch (error) { }
      if (eventOrLog == null) {
        try {
          eventOrLog = this.item.LogEvent ? 'localLog' : null;
          // eslint-disable-next-line no-empty
        } catch (error) { }
      }

      if (eventOrLog === 'event') {
        isError = this.Events[this.item.Event] != null ? this.Events[this.item.Event].error : null;
      } else if (eventOrLog === 'localLog') {
        isError = this.LocalLogs[this.item.LogEvent] != null ? this.LocalLogs[this.item.LogEvent].error : null;
      }
      return isError;
    },
    arc() {
      return Math.trunc(this.item.ArcPathDeviceId / 10000);
    },
    path() {
      return Math.trunc(this.item.ArcPathDeviceId / 100) % 100;
    },
    commsmod() {
      return this.$t(`communications.enums.COMMSMOD_INFO.${this.item.ArcPathDeviceId % 100}`);
    },
  },
};
</script>

<style lang="scss">
@import 'list-item.scss';
.card__head {
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
}
.card {
  &--log {
    border-bottom:none !important;
    border-right:none !important;
    border-left:none !important;
    background: none !important;
    margin-bottom: 0rem !important;

    h3 {
      font-size: 16px;
      svg {
        margin-right: 6px;
      }
    }

    .card__body {
      display: block;
      margin-left:7px;
      margin-top: -0.75rem;

      p {
        margin-bottom: 0.5em !important;
      }
    }
  }
}
</style>
