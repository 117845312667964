<template>
	<a-layout>
		<div class="topBar">
			<a-button class="mobile-nav-button">
				<font-awesome-icon :icon="['fal', 'bars']" />
			</a-button>
		</div>
		<a-layout-sider class="sideNavigation" width="300px">
			<mainNavigation class="fixed-navigation" />
		</a-layout-sider>
		<a-layout>
			<a-layout-content :style="{ margin: '24px 16px 0', overflow: 'initial' }">
				<a-row>
					<a-col span="24">
						<breadcrumb />
						<h1 class="header">Communications</h1>
					</a-col>
					<a-row gutter="20" >
						<a-col :md="{ span: 24}" :lg="{ span: 6}" :xl="{ span: 4}">
							<a-menu mode="inline" class="page-navigation">
								<a-menu-item-group key="g1">
									<template slot="title">
										<span>GENERAL</span>
									</template>
									<a-menu-item key="1">ARC options</a-menu-item>
								</a-menu-item-group>
								<a-menu-item-group key="g2">
									<template slot="title">
										<span>SMS</span>
									</template>
									<a-menu-item key="3">Controls</a-menu-item>
									<a-menu-item key="4">Notifications</a-menu-item>
								</a-menu-item-group>
								<a-menu-item-group key="g3">
									<template slot="title">
										<span>CONNECTIVITY</span>
									</template>
									<a-menu-item key="5">Cloud options</a-menu-item>
									<a-menu-item key="6">App options</a-menu-item>
									<a-menu-item key="7">GPRS</a-menu-item>
									<a-menu-item key="8">LAN</a-menu-item>
									<a-menu-item key="9">WIFI</a-menu-item>
									<a-menu-item key="10">Voice</a-menu-item>
								</a-menu-item-group>
							</a-menu>
						</a-col>
						<a-col :md="{ span: 24}" :lg="{ span: 18}" :xl="{ span: 18}">
							<a-row>
								<slot />
							</a-row>
						</a-col>
					</a-row>
				</a-row>
			</a-layout-content>
		</a-layout>
	</a-layout>
</template>

<script>
import mainNavigation from '@/app/shared/components/Navigation/MainNavigation/MainNavigation.vue';
import breadcrumb from '@/app/shared/components/Breadcrumb/breadcrumb.vue';

export default {
  name: 'page-navigation-layout',
  components: {
    breadcrumb,
    mainNavigation,
  },
};
</script>
