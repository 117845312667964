<template>
    <a-drawer
    placement="right"
    :closable="false"
    @close="onClose"
    :destroyOnClose="true"
    :visible="visible">

    <wizard :config="wizard" :state="$v" @save="save" @done="onClose" :initialStep="clonePayload != null ? 'manual' : 'start'">
      <wizard-step name="start">
        <a-form>
          <p-input :label="$t('common.Name')" :model="$v.form.name" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
          <p-readonly :label="$t('users.Access-type')" :value="$t(`users.enums.ACCESS_TYPE.${$v.form.accessType.$model}`) + ` (${fobIndex + 1})`" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
          <p-select :label="$t('users.User-type')" :model="$v.form.userType" :items="USER_TYPE.values.filter(v => v.value === 0 || v.value === 1)" :valueExpr="(i) => i.value" :textExpr="(i) => $t(`users.enums.USER_TYPE.${i.value}`)" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
          <p-select :label="$t('common.Number')" :model="$v.form.index" :items="availableIndexes" :valueExpr="(i) => i" :textExpr="(i) => i - 1" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
        </a-form>
      </wizard-step>

      <wizard-step name="final">
        <a-form>
          <p-item-selector :label="$t('users.Door')" :items="doors" :model="$v.form.associatedDoors" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
        </a-form>
      </wizard-step>

    </wizard>

    </a-drawer>
</template>
<script>
import {
  required, maxLength,
} from 'vuelidate/lib/validators';

import { createNamespacedHelpers } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';

import { Clone } from '@/app/shared/mixins';
import { USER_TYPE, SET_OPTIONS } from '@/app/users/shared/enums';

import service from '@/app/shared/services/learn-wireless-devices';

const storeNamespace = 'usersState/addKeyFob';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);


export default {
  mixins: [Clone],
  components: {
    Wizard,
    WizardStep,
  },
  props: {
    visible: { default: false },
    fobIndex: { required },
  },
  data() {
    return {
      stage: 'init',
      USER_TYPE,
      SET_OPTIONS,
      clone: {
        storeNamespace,
      },
      wizard: {
        start: {
          title: this.$t('users.drawer.Add-a-user'),
          progress: 30,
          next: [
            { key: 'final', buttonText: this.$t('common.Next') },
          ],
        },
        final: {
          progress: 60,
          next: [
            { key: 'save', buttonText: this.$t('common.Add-user') },
          ],
        },
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(16),
      },
      accessType: {
        required,
      },
      userType: {
        required,
      },
      index: {
        required,
      },
      associatedDoors: {},
    },
  },
  computed: {
    ...mapFields(storeNamespace, ['form']),
    ...mapState(['availableIndexes', 'doors', 'subAreas']),
  },
  watch: {
    // Whenever we close the wizard, the component should be reset
    visible(newVal) {
      if (!newVal) {
        this.$store.dispatch('usersState/addKeyFob/reset');
        this.$v.$reset();
      }
    },
  },
  methods: {
    ...mapActions(['add']),
    async onClose() {
      this.$emit('onClose');
      this.stage = 'init';
    },
    async save() {
      this.$v.$touch();

      await this.add({ fobIndex: this.fobIndex });
      service.setLearningCompleted(true);
      this.$emit('onClose');

      if (!this.navigateToNewItem) {
        return;
      }

      this.$router.push({ name: 'user', params: { index: this.form.index } });
    },
    toggleTooltipText(field) {
      return field && field.$model
        ? this.$t('common.OptionEnabledTooltip')
        : this.$t('common.OptionDisabledTooltip');
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

</style>
