import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import {
  translateOutputType, getSingleOutputInfo, getAssociatedDevices, isSirenOutputType, saveOutputType,
} from '@/app/outputs/shared/services/helpers';

function initialState() {
  return {
    associatedDevices: [],
    _outputTypes: [],

    form: {
      outputType: undefined,
      associatedWithDevice: undefined,
      index: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {
    outputTypes: state => state._outputTypes,
  },
  actions: {
    requiredEndpoints() {
      return ['infoEndstation', 'infoOutputInfo', 'infoBusDevices', 'infoKeypadInfo', 'configEndstation', 'configBusDevices', 'configKeypadInfo', 'configOutputInfo'];
    },
    async populate(context, { endpoints, payload }) {
      if (payload != null && payload.index != null) {
        const item = getSingleOutputInfo(endpoints, payload.index);

        context.commit('setForm', {
          outputType: item.outputType,
          associatedWithDevice: item.associatedWith.key,
        });
      }

      const outputTypes = [];
      for (let i = 0; i < endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes.length; i += 1) {
        const outputType = endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes[i].Name;
        if (outputType != null) {
          outputTypes.push({
            value: i, key: outputType, name: translateOutputType(outputType), isSirenOutputType: isSirenOutputType(i),
          });
        }
      }

      const devices = getAssociatedDevices(null, endpoints);
      context.commit('set', {
        associatedDevices: devices,
        _outputTypes: outputTypes,
      });
    },
    async add(context) {
      const associatedDevice = context.state.associatedDevices.find(v => v.key === context.state.form.associatedWithDevice);
      const { deviceIndex, type } = associatedDevice;
      // Find the output item in availableOutputs collection, that matches the number selected by the user on the wizard form
      // If it's an endstation slot, compare the form number against deviceOutputIndexDisplay property of the item
      // Otherwise use the real index on the device
      const { deviceOutputIndex } = associatedDevice.availableOutputs
        .find(type === 'ENDSTATION'
          ? i => i.deviceOutputIndexDisplay === context.state.form.index
          : i => i.deviceOutputIndex === context.state.form.index);

      await saveOutputType(api, type, deviceIndex, deviceOutputIndex, context.state.form.outputType);

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('sirensState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('sirensState/addSiren/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['sirensState/list'] });
    },
  },
});

export default store;
