<template>
  <associated-zones :endstation="endstation" :isLoading="isLoading" :items="items" :max="maximum" :installed="installed" @omitt="omittZone" />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import zoneHelper from '@/app/shared/services/zone-helper';

import AssociatedZones from '@/app/shared/components/associated-zones/associated-zones.vue';

const { mapState, mapGetters } = createNamespacedHelpers('panelState/panelZones');

export default {
  components: {
    AssociatedZones,
  },
  props: ['loader'],
  computed: {
    ...mapState(['isLoading', 'maximum', 'installed', 'endstation']),
    ...mapGetters(['isLoading', 'items']),
  },
  methods: {
    async omittZone(index, item, checked) {
      await zoneHelper.bypass(item.index, checked, this.loader);
    },
  },
};
</script>
