import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';


function initialState() {
  return {
    isAutoDuressSupported: false,
    form: {
      minimumCodeLength: undefined,
      quickArm: undefined,
      codeStopSound: undefined,
      guardCodeDelay: undefined,
      autoDuress: undefined,
      manageProgramPA: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configSysInfo', 'infoUserInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('set', { isAutoDuressSupported: endpoints.infoUserInfo.data.Info.userInfo.IsAutoDuressSupported });
      context.commit('setForm', {
        minimumCodeLength: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.MinimumCodeLength,
        quickArm: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.SimpleSetEnable,
        codeStopSound: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.ValidCodeStopsSound,
        guardCodeDelay: endpoints.configSysInfo.data.Config.sysInfo.Timers.GuardCodeAlarmTime_mins,
        autoDuress: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.AutoDuressCodes,
        manageProgramPA: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.AllowManagerToProgrammePA,
      });
    },
    async save(context) {
      const data = {
        SiteOptions: {
          MinimumCodeLength: context.state.form.minimumCodeLength,
          SimpleSetEnable: context.state.form.quickArm,
          ValidCodeStopsSound: context.state.form.codeStopSound,
          AutoDuressCodes: context.state.form.autoDuress,
          AllowManagerToProgrammePA: context.state.form.manageProgramPA,
        },
        Timers: {
          GuardCodeAlarmTime_mins: parseInt(context.state.form.guardCodeDelay, 10),
        },
      };

      await api.put('/Config/sysInfo', data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('settingsState/userSettings/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['settingsState/userSettings'] });
    },
  },
});


export default store;
