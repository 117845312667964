<template>
   <a-radio-group :defaultValue="isTabularview ? 'table' : 'card'">
    <a-tooltip :title="$t('common.ListView')">
    <a-radio-button value="table" @click="toggle(true)">
      <font-awesome-icon  :icon="['fal', 'table']" />
    </a-radio-button>
    </a-tooltip>
    <a-tooltip :title="$t('common.TileView')">
    <a-radio-button value="card" @click="toggle(false)">
      <font-awesome-icon  :icon="['fas', 'grip-horizontal']" />
    </a-radio-button>
    </a-tooltip>
  </a-radio-group>
</template>

<script>
export default {
  name: 'grid-display-toggle',
  props: {
    isTabularview: { type: Boolean, required: false, default: false },
  },
  methods: {
    toggle(value) {
      this.$emit('toggle', value);
    },
  },
};
</script>
