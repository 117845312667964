<template>
   <three-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
            <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
                <h1 id="title">{{ name }}</h1>
            </a-skeleton>
        </template>
        <template v-slot:titleCommands>
          <item-menu
            @save="saveForm" :isSaving="isSaving"
            @delete="onDelete"
            :canDelete="!isEngineerKeypadIndex"
            :canClone="!isEngineerKeypad" @clone="addDrawerVisible = true"
            :disabled="!configWriteLock.canWriteConfig" />
        </template>
        <diagnostics :globalState="state" :items="diagnostics" :isLoading="isLoading" :options="diagnosticOptions" />

        <form>
            <div class="styled-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('common.Device-information') }}</h3>
                        <a-row :gutter="100">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 6}" >
                              <p-input :label="$t('common.Name')" :model="$v.form.name" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 6}" >
                              <p-input :label="$t('common.Location')" :model="$v.form.location" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 6}" >
                              <p-select :label="$t('keypadsAndReaders.keypadAndReaderOptions.Keypad-Fire-Key')" :model="$v.form.fireKey"
                                        :items="Object.keys(FIRE_KEY_ACTIVE)" :valueExpr="(i) => FIRE_KEY_ACTIVE[i]" :textExpr="(i) => $t(`enums.FIRE_KEY_ACTIVE.${i}`)"
                                        :labelTooltip="$t('keypadsAndReaders.keypadAndReaderOptions.Keypad-Fire-Key-tooltip')"
                                        :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 6}" >
                              <p-select :label="$t('keypadsAndReaders.keypadAndReaderOptions.Keypad-PA-Key')" :model="$v.form.paKey"
                                        :items="Object.keys(TWO_KEY_PA)" :valueExpr="(i) => TWO_KEY_PA[i]" :textExpr="(i) => $t(`enums.TWO_KEY_PA.${i}`)"
                                        :labelTooltip="$t('keypadsAndReaders.keypadAndReaderOptions.Keypad-PA-Key-tooltip')"
                                        :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-readonly :label="$t('common.Device-type')" :value="$t(`keypadsAndReaders.enums.deviceType.${deviceType}`)" v-if="!isEngineerKeypadIndex" />
                              <p-select :label="$t('common.Type')" :model="$v.form.type" data-testid="deviceType-select" v-else
                              :items="deviceTypes" :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                              :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-readonly :label="$t('common.Address')" :value="$v.form.address.$model" />
                            </a-col>
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

            <div class="styled-section" data-testid="area-attributes-section" v-if="!isEngineerKeypad">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('keypadsAndReaders.Area-attributes') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-area-selector :label="$t('common.In-area')" :model="$v.form.inAreas" :areasInfo="$v.form.areasInfo.$model" :areasConfig="$v.form.areasConfig.$model"
                                @singleCheckedArea="keypadInArea"
                                :disabled="!configWriteLock.canWriteConfig"/>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-area-selector :label="$t('common.Sets')" :model="$v.form.setsAreas" :areasInfo="$v.form.areasInfo.$model" :areasConfig="$v.form.areasConfig.$model"
                                :disabled="!configWriteLock.canWriteConfig"/>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-area-selector :label="$t('common.Unsets')" :model="$v.form.unsetsAreas" :areasInfo="$v.form.areasInfo.$model" :areasConfig="$v.form.areasConfig.$model"
                                :disabled="!configWriteLock.canWriteConfig"/>
                            </a-col>
                        </a-row>
                        <a-row :gutter="50" v-if="keypadInAreaIndex >= 0">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" />
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-select :label="$t('common.Level')"
                                :model="$v.form.readerLevel"
                                :items="levels"
                                :valueExpr="(i) => i.index"
                                :textExpr="(i) => i.label"
                                :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" />
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

            <div data-testid="associated-devices-section" v-if="!isEngineerKeypad">
              <associated-zones :isLoading="isLoading" :items="associatedZones" :max="zonesMax" @omitt="omittZone" v-if="hasAssociatedZones" />
              <associated-outputs :isLoading="isLoading" :items="associatedOutputs" :max="outputsMax" @activate="activateAssocOutput" />
            </div>

        </form>

        <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
        <add-keypad-or-reader :visible="addDrawerVisible" :navigateToNewItem="true" @onClose="addDrawerVisible = false" :clone-payload="loader.payload()" />
        <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
          @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />

   </three-col-layout>
</template>

<script>
import _ from 'lodash';
import { required, maxLength } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import { arrayHasTrueValue } from '@/app/shared/utils/validation';
import AddKeypadOrReader from '@/app/keypads-and-readers/shared/components/add-keypad-or-reader/add-keypad-or-reader.vue';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import ItemMenu from '@/app/shared/components/item-menu/item-menu.vue';
import AssociatedOutputs from '@/app/shared/components/associated-outputs/associated-outputs.vue';
import AssociatedZones from '@/app/shared/components/associated-zones/associated-zones.vue';
import zoneHelper from '@/app/shared/services/zone-helper';
import outputHelper from '@/app/shared/services/output-helper';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import { ENGINEER_KEYPAD_INDEX, liveZoneInfoConsts, LEVEL_IDS } from '@/app/shared/constants';
import { KEYPAD_AND_READER_TYPE, TWO_KEY_PA, FIRE_KEY_ACTIVE } from '@/app/keypads-and-readers/shared/enums';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

const storeNamespace = 'keypadsAndReadersState/wiredKeypad';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    AddKeypadOrReader,
    UnsavedChangesModal,
    ItemMenu,
    AssociatedOutputs,
    AssociatedZones,
    DisplayFaultsModal,
  },
  data() {
    return {
      keypadInAreaIndex: -1,
      ENGINEER_KEYPAD_INDEX,
      TWO_KEY_PA,
      FIRE_KEY_ACTIVE,
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
      intervalID: [], // Stores the interval ID for clearing later
      diagnosticOptions: {
        statusType(diagnostic) {
          return diagnostic.value === 'FAULT' ? 'error' : 'active';
        },
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(16),
      },
      type: {
        required,
      },
      location: {
        maxLength: maxLength(16),
      },
      address: {
        required,
      },
      fireKey: {
        required,
      },
      paKey: {
        required,
      },
      setsAreas: {},
      unsetsAreas: {},
      inAreas: {
        arrayHasTrueValue,
      },
      readerLevel: {},
      areasInfo: {},
      areasConfig: {},
      levelsConfig: {},
    },
  },
  computed: {
    isEngineerKeypad() {
      return this.isEngineerKeypadIndex && this.$v.form.type.$model === KEYPAD_AND_READER_TYPE.TYPE_REMOVABLE_KEYPAD.value;
    },
    isEngineerKeypadIndex() {
      return this.loader.payload().index === ENGINEER_KEYPAD_INDEX;
    },
    deviceTypes() {
      return [
        { value: KEYPAD_AND_READER_TYPE.TYPE_KEYPAD.value, name: this.$t('keypadsAndReaders.enums.deviceType.TYPE_KEYPAD') },
        { value: KEYPAD_AND_READER_TYPE.TYPE_REMOVABLE_KEYPAD.value, name: this.$t('keypadsAndReaders.enums.deviceType.TYPE_REMOVABLE_KEYPAD') },
      ];
    },
    levels() {
      // Get the (single) area that the keypad is configured to be in.
      const area = this.form.levelsConfig.Areas[this.keypadInAreaIndex];

      // Return the list of levels for that area, each level in the format "A - <name>".
      const res = area.Level.map((level, i) => ({
        index: i,
        label: `${LEVEL_IDS[i]} - ${level.Name}`,
      }));
      return res;
    },
    ...mapState(['form', 'deviceType', 'zonesMax', 'outputsMax', 'associatedZones', 'hasAssociatedZones', 'associatedOutputs', 'diagnostics', 'state', 'name']),
    ...mapGetters(['isLoading']),
  },
  methods: {
    ...mapActions(['save', 'delete', 'setPolledZonesIndexList']),
    startCountdown(index, timer, callback) {
      let initialValue = timer;
      this.intervalID[index] = setInterval(() => {
        initialValue -= 1;
        if (initialValue <= 0) {
          clearInterval(this.intervalID[index]);
          callback();
        }
      }, 1000);
    },
    keypadInArea(areaIndex) {
      // Note the index of the area that the keypad is configured to be in; -1 if the keypad is in more than one area.
      this.keypadInAreaIndex = areaIndex;
    },
    async omittZone(index, item, checked) {
      await zoneHelper.bypass(item.zoneIndex, checked, this.loader.instance);
    },
    async activateAssocOutput(index, item, checked) {
      await outputHelper.activateOutputType(item.rawOutputType, checked, this.loader.instance);
      if (item.udPulseTime !== null) {
        if (checked === true) {
          this.startCountdown(index, item.udPulseTime, async () => {
            await outputHelper.activateOutputType(item.rawOutputType, !checked, this.loader.instance);
          });
        } else {
          clearInterval(this.intervalID[index]);
        }
      }
    },
    onDelete() {
      return this.delete().then(() => { this.$router.push({ name: 'keypads-and-readers-list' }); });
    },
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
  created() {
    let zoneListLength = 0;
    // If the keypad has any configured zones
    if (_.has(this.$route.params, 'configuredZoneList')) {
      zoneListLength = this.$route.params.configuredZoneList.length;
    }
    if (zoneListLength > 0) {
      // Poll only the zones belonging to this peripheral.
      const firstZoneIndex = this.$route.params.configuredZoneList[0];
      const lastZoneIndex = this.$route.params.configuredZoneList[zoneListLength - 1];
      // Build a list of associated zone numbers from first to last
      const zoneArray = [...Array(lastZoneIndex + 1).keys()].slice(firstZoneIndex);
      // Pass across the array of indexes of zones associated with this device
      this.setPolledZonesIndexList(zoneArray);
      const zoneList = zoneArray.toString();

      // Start polling the associated zones.
      const zoneUrl = liveZoneInfoConsts.baseUrl.concat('/Zones/').concat(zoneList);
      zoneHelper.customisePollingWithIntervalAndUrl(this.loader.instance, liveZoneInfoConsts.interval, zoneUrl);
    } else {
      // Keypad has no associated zones. This would fallback to polling /Live/zoneInfo i.e. all ZEMs would be polled.
      // This is to be avoided, so just poll something benign. e.g. Live/zoneInfo/DaysOnSoak
      const benignUrl = liveZoneInfoConsts.baseUrl.concat('/DaysOnSoak');
      zoneHelper.customisePollingWithIntervalAndUrl(this.loader.instance, liveZoneInfoConsts.interval, benignUrl);
    }
  },
  destroyed() {
    // Inform the zone helper to stop custom polling if there are no other pages using custom polling.
    zoneHelper.revertCustomPollingIfPossible(this.loader.instance);
  },
};
</script>

<style lang="scss">

</style>
