<template>
    <a-drawer
    placement="right"
    :closable="false"
    @close="onClose"
    :destroyOnClose="true"
    :visible="visible">

    <wizard :config="wizard" @done="onClose">
        <wizard-step name="start">
        </wizard-step>

        <wizard-step name="run">
        </wizard-step>

        <wizard-step name="end">
          <div class="icon-buttons__wrapper">
            <a @click="completeTest">
              <font-awesome-icon  :icon="['fal', 'thumbs-down']" size="4x" />
              <span>{{ $t('maintenance.drawer.sirenAndStrobeTest.Failed') }}</span>
            </a>
            <a @click="completeTest">
              <font-awesome-icon  :icon="['fal', 'thumbs-up']" size="4x" />
              <span>{{ $t('maintenance.drawer.sirenAndStrobeTest.Passed') }}</span>
            </a>
          </div>
        </wizard-step>

      </wizard>
    </a-drawer>
</template>
<script>
import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';

import sirenTest from '@/app/maintenance/shared/services/siren-test';

export default {
  components: {
    Wizard,
    WizardStep,
  },
  props: {
    visible: { default: false },
  },
  data() {
    return {
      form: {},
      wizard: {
        start: {
          title: this.$t('maintenance.drawer.sirenAndStrobeTest.Siren-and-strobe-test'),
          next: [
            { key: 'run', buttonText: this.$t('maintenance.drawer.sirenAndStrobeTest.Start-siren-and-strobe-test') },
          ],
        },
        run: {
          title: this.$t('maintenance.drawer.sirenAndStrobeTest.Siren-and-strobe-test'),
          next: [
            { key: 'end', buttonText: this.$t('maintenance.drawer.sirenAndStrobeTest.End-siren-and-strobe-test') },
          ],
          onShow: () => {
            sirenTest.start();
          },
        },
        end: {
          title: this.$t('maintenance.drawer.sirenAndStrobeTest.Siren-and-strobe-test'),
          onShow: () => {
            sirenTest.finish();
          },
        },
      },
    };
  },
  methods: {
    async onClose() {
      sirenTest.finish();
      this.$emit('onClose');
    },
    completeTest() {
      this.$emit('onClose');
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

.icon-buttons__wrapper {
  a {
    float: left;
    padding-right: 60px;
    text-align: center;
    color:#000 !important;
  }
  svg {
    color:#000 !important;
    display: block;
    margin-bottom:10px;
  }
}

</style>
