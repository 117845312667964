<template>
    <a-layout>
        <div class="topBar">
            <a-button class="mobile-nav-button">
                <font-awesome-icon :icon="['fal', 'bars']" @click="menuOpen = !menuOpen" />
            </a-button>
        </div>
        <a-layout-sider class="sideNavigation" width="300px" :class="{'sideNavigation--open': menuOpen}">
            <mainNavigation :configWriteLock="configWriteLock" class="fixed-navigation" />
        </a-layout-sider>
        <a-layout>
            <a-layout-content :style="{ margin: '24px 16px 0', overflow: 'initial' }">
                <a-row>
                    <a-row>
                        <a-col span="24">
                            <breadcrumb />
                            <h1 class="header">{{ $t('settings.navigationTitle') }}</h1>
                        </a-col>
                    </a-row>
                    <a-row :gutter="20">
                        <a-col :md="{ span: 24}" :lg="{ span: 4}" :xl="{ span: 4}">
                            <a v-if="isMobile" @click="openPageMenu" class="pagemenubutton ant-btn ant-dropdown-trigger m-b-1 ant-btn-primary ant-btn-block">Toggle settings menu <span role="img" aria-label="down" class="anticon anticon-down"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></span></a>
                            <a-menu v-if="showMenu || isMobile === false" @click="onClick" class="page-navigation">
                                <a-menu-item-group key="gAreas">
                                    <template slot="title">
                                        <span>{{ $t('settings.AREAS') }}</span>
                                    </template>
                                    <a-menu-item key="settings.areasOptions">{{ $t('settings.areasOptions.navigationTitle') }}</a-menu-item>
                                </a-menu-item-group>
                                <a-menu-item-group key="gGlobal">
                                    <template slot="title">
                                        <span>{{ $t('settings.GLOBAL-SETTINGS') }}</span>
                                    </template>
                                    <a-menu-item
                                        key="settings.generalTimers"
                                    >{{ $t('settings.generalTimers.navigationTitle') }}</a-menu-item>
                                    <a-menu-item
                                        key="settings.globalVolume"
                                    >{{ $t('settings.globalVolume.navigationTitle') }}</a-menu-item>
                                    <a-menu-item
                                        key="settings.globalDisplay"
                                    >{{ $t('settings.globalDisplay.navigationTitle') }}</a-menu-item>
                                    <a-menu-item key="settings.dateAndTime">{{ $t('settings.dateAndTime.navigationTitle') }}</a-menu-item>
                                    <a-menu-item
                                        key="settings.armingOptions"
                                    >{{ $t('settings.armingOptions.navigationTitle') }}</a-menu-item>
                                    <a-menu-item
                                        key="settings.userSettings"
                                    >{{ $t('settings.userSettings.navigationTitle') }}</a-menu-item>
                                    <a-menu-item
                                        key="settings.zoneSettings"
                                    >{{ $t('settings.zoneSettings.navigationTitle') }}</a-menu-item>
                                </a-menu-item-group>
                                <a-menu-item-group key="gResponses">
                                    <template slot="title">
                                        <span>{{ $t('settings.RESPONSES') }}</span>
                                    </template>
                                    <a-menu-item
                                        key="settings.generalResponses"
                                    >{{ $t('settings.generalResponses.navigationTitle') }}</a-menu-item>
                                    <a-menu-item
                                        key="settings.alarmResponses"
                                    >{{ $t('settings.alarmResponses.navigationTitle') }}</a-menu-item>
                                </a-menu-item-group>
                                <a-menu-item-group key="gGeneral">
                                    <template slot="title">
                                        <span>{{ $t('settings.GENERAL') }}</span>
                                    </template>
                                    <a-menu-item key="settings.siteOptions">{{ $t('settings.siteOptions.navigationTitle') }}</a-menu-item>
                                    <a-menu-item
                                        key="settings.engineerRestores"
                                    >{{ $t('settings.engineerRestores.navigationTitle') }}</a-menu-item>
                                </a-menu-item-group>
                            </a-menu>
                        </a-col>
                        <a-col :md="{ span: 24, offset: 0}" :lg="{ span: 18, offset: 5}" :xl="{ span: 18, offset: 1}">
                            <a-row class="settings">
                                <router-view></router-view>
                            </a-row>
                        </a-col>
                    </a-row>
                </a-row>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
import { ConfigWriteLock } from '@/app/shared/mixins';
import mainNavigation from '@/app/shared/components/Navigation/MainNavigation/MainNavigation.vue';
import breadcrumb from '@/app/shared/components/Breadcrumb/breadcrumb.vue';

export default {
  name: 'page-navigation-layout',
  mixins: [ConfigWriteLock],
  components: {
    breadcrumb,
    mainNavigation,
  },
  data() {
    return {
      menuOpen: false,
      showMenu: false,
      isMobile: false,
    };
  },
  created() {
    window.addEventListener('resize', this.windowResize);
    this.windowResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize);
  },
  methods: {
    windowResize() {
      if (window.innerWidth >= 992) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    openPageMenu() {
      if (this.isMobile) {
        if (this.showMenu) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }
      }
    },
    onClick(item) {
      this.$router.push({ name: item.key });
      this.showMenu = false;
    },
  },
};
</script>
<style lang="scss">
.settings .ant-form-item-label {

    text-align: left;
    padding-right: 1rem;

    @media only screen and (min-width: 991px) {
        text-align: right;
    }
}
</style>
