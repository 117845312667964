/* eslint-disable class-methods-use-this */
import Q from 'q';
import { API } from '@/app/shared/services/api';
import { CommandError } from '@/app/shared/errors';

const api = new API();

class OutputHelper {
  async activateOutputType(index, active, loader) {
    try {
      await api.put(`/Live/PanelStatus/OutputTypes/${index}/state`, active);

      loader.reloadEndpoint('livePanelStatus');
    } catch (error) {
      throw new CommandError('ActivateOutputFailed', CommandError.ACTIVATE_OUTPUT, [{ type: active ? 'Activated' : 'De-activated', id: index }], error);
    }
  }

  async activateOutputTypes(indexes, active, loader) {
    try {
      const calls = [];
      for (let i = 0; i < indexes.length; i += 1) {
        calls.push(api.put(`/Live/PanelStatus/OutputTypes/${indexes[i]}/state`, active));
      }

      await Q.all(calls);

      loader.reloadEndpoint('livePanelStatus');
    } catch (error) {
      throw new CommandError('ActivateOutputFailed', CommandError.ACTIVATE_OUTPUT, [{ type: active ? 'Activated' : 'De-activated', id: indexes }], error);
    }
  }
}

const helper = new OutputHelper();

export default helper;
export { api };
