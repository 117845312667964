export class EnumWrapper {
  constructor(values) {
    const self = this;
    this.values = values;

    return new Proxy(this, {
      get(target, prop) {
        if (!(prop in target)) {
          return self.values.find(v => v.key === prop);
        }
        return target[prop];
      },
    });
  }

  fromInt(number) {
    for (let i = 0; i < this.values.length; i += 1) {
      if (this.values[i].value === number) {
        return this.values[i];
      }
    }
    return null;
  }

  fromKey(key) {
    for (let i = 0; i < this.values.length; i += 1) {
      if (this.values[i].key === key) {
        return this.values[i];
      }
    }
    return null;
  }

  keys() {
    return this.values.map(v => v.key);
  }
}
