<template>
   <three-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
            <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
                <h1 id="title">{{ name }}</h1>
            </a-skeleton>
        </template>
        <template v-slot:titleCommands>
          <item-menu
            @save="saveForm" :isSaving="isSaving"
            @delete="onDelete"
            :canClone="true" @clone="addDrawerVisible = true"
            :disabled="!configWriteLock.canWriteConfig" />
        </template>

        <diagnostics :globalState="state" :items="diagnostics" :isLoading="isLoading" :options="diagnosticOptions" :diagnostics-unavailable="diagnosticsUnavailable" :device-type="$t('wiredZoneExpander.navigationTitle')" />

        <form>
            <div class="styled-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('common.Device-information') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-readonly :label="$t('common.Device-type')" :value="$t('zoneExpanders.enums.deviceType.'+$v.form.type.$model)" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-input :label="$t('common.Location')" :model="$v.form.location" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-readonly :label="$t('common.Address')" :value="$v.form.address.$model" />
                            </a-col>
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

          <associated-zones :isLoading="isLoading" :items="associatedZones" :max="zonesMax" @omitt="omittZone" />
          <associated-outputs :isLoading="isLoading" :items="associatedOutputs" :max="outputsMax" @activate="activateAssocOutput" />

        </form>

        <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />

        <add-zone-expander :visible="addDrawerVisible" :navigateToNewItem="true" @onClose="addDrawerVisible = false" :clone-payload="clonePayload" />
        <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
          @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
   </three-col-layout>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import _ from 'lodash';
import AddZoneExpander from '@/app/zone-expanders/shared/components/add-zone-expander/add-zone-expander.vue';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import AssociatedOutputs from '@/app/shared/components/associated-outputs/associated-outputs.vue';
import AssociatedZones from '@/app/shared/components/associated-zones/associated-zones.vue';
import ItemMenu from '@/app/shared/components/item-menu/item-menu.vue';
import zoneHelper from '@/app/shared/services/zone-helper';
import zoneExpanderHelper from '@/app/shared/services/zone-expander-helper';
import outputHelper from '@/app/shared/services/output-helper';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import { liveZoneInfoConsts, liveWiredZoneExpanderConsts } from '@/app/shared/constants';

const storeNamespace = 'zoneExpandersState/wiredZoneExpander';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    AddZoneExpander,
    UnsavedChangesModal,
    AssociatedOutputs,
    AssociatedZones,
    ItemMenu,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      intervalID: [], // Stores the interval ID for clearing later
      addDrawerVisible: false,
      isSaving: false,
      diagnosticOptions: {
        statusType(diagnostic) {
          return diagnostic.value === 'FAULT' ? 'error' : 'active';
        },
      },
    };
  },
  validations: {
    form: {
      type: {
        required,
      },
      location: {
        maxLength: maxLength(16),
      },
      address: {
        required,
      },
    },
  },
  computed: {
    clonePayload() {
      return { index: this.$route.params.index, type: 'TYPE_WIRED', configuredZoneList: this.$route.params.configuredZoneList };
    },
    ...mapState(['form', 'name', 'zonesMax', 'outputsMax', 'associatedZones', 'associatedOutputs', 'diagnostics', 'state']),
    ...mapGetters(['isLoading']),
    diagnosticsUnavailable() {
      const diagnostic = this.diagnostics.find(d => d.key === 'wiredZoneExpander.Diagnostics.DataBusStatus');
      return !diagnostic.isLoading && diagnostic.value === 'FAULT';
    },
  },
  methods: {
    ...mapActions(['save', 'deleteItem']),
    startCountdown(index, timer, callback) {
      let initialValue = timer;
      this.intervalID[index] = setInterval(() => {
        initialValue -= 1;
        if (initialValue <= 0) {
          clearInterval(this.intervalID[index]);
          callback();
        }
      }, 1000);
    },
    async omittZone(index, item, checked) {
      await zoneHelper.bypass(item.zoneIndex, checked, this.loader.instance);
    },
    async activateAssocOutput(index, item, checked) {
      await outputHelper.activateOutputType(item.rawOutputType, checked, this.loader.instance);
      if (item.udPulseTime !== null) {
        if (checked === true) {
          this.startCountdown(index, item.udPulseTime, async () => {
            await outputHelper.activateOutputType(item.rawOutputType, !checked, this.loader.instance);
          });
        } else {
          clearInterval(this.intervalID[index]);
        }
      }
    },
    onDelete() {
      return this.deleteItem().then(() => { this.$router.push({ name: 'zone-expanders-list' }); });
    },
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },

  created() {
    let zoneListLength = 0;
    if (_.has(this.$route.params, 'configuredZoneList')) {
      zoneListLength = this.$route.params.configuredZoneList.length;
    }
    if (zoneListLength > 0) {
      // Poll only the zones belonging to this ZEM.
      // E.g. ZEM29 has first:240 and last:247 (shown to user as 241 to 248)
      const firstZoneIndex = this.$route.params.configuredZoneList[0];
      const lastZoneIndex = this.$route.params.configuredZoneList[zoneListLength - 1];

      // Build a list of associated zone numbers from first to last e.g. 240,241,242,243,244,245,246,247
      const zoneArray = [...Array(lastZoneIndex + 1).keys()].slice(firstZoneIndex);
      const zoneList = zoneArray.toString();

      // Requesting the EOL resistance value of a zone is an expensive task for the panel,
      // so, as this ZEM page does not use the EOL value, don't request it:
      // Add all the subsidiary nodes except EndOfLine_ohm
      const subNodes = '/state,WalkTestOpened,Omittable,ManagerOmitted,ForceOmitted,WalkTestClosed,SetOmitted';

      // Start polling the associated zones.
      const zoneUrl = liveZoneInfoConsts.baseUrl.concat('/Zones/').concat(zoneList).concat(subNodes);
      zoneHelper.customisePollingWithIntervalAndUrl(this.loader.instance, liveZoneInfoConsts.interval, zoneUrl);

      // And start polling the PSU diagnostics for this ZEM
      // e.g. for ZEM10 build the url Live/BusDevices/wiredHubs/10
      const expanderIndex = this.$route.params.index;
      const zoneExpanderUrl = liveWiredZoneExpanderConsts.baseUrl.concat('/').concat(expanderIndex.toString());
      zoneExpanderHelper.customiseExpanderPollingWithIntervalAndUrl(liveWiredZoneExpanderConsts.key, this.loader.instance, liveWiredZoneExpanderConsts.activeInterval, zoneExpanderUrl);
    }
  },
  destroyed() {
    // Inform the zone helper to stop custom polling if there are no other pages using custom polling.
    zoneHelper.revertCustomPollingIfPossible(this.loader.instance);

    // Ditto for the zone expander helper
    zoneExpanderHelper.revertExpanderCustomPollingIfPossible(liveWiredZoneExpanderConsts.key, this.loader.instance);
  },
};
</script>

<style lang="scss">

</style>
