/* eslint-disable class-methods-use-this */
import Q from 'q';
import i18n from '@/app/shared/services/i18n';
import { API, createArray } from '@/app/shared/services/api';
import { KEYPAD_AND_READER_TYPE } from '@/app/keypads-and-readers/shared/enums';
import { limits } from '@/app/shared/constants';

export const api = new API();

// Global variable to determine once on panel page if any zones are in areas,
// if all areas status is UNSETTABLE (CANNOT_SET) it means keypad is in all areas.
window.globalThis.zoneLevelsRequireReread = true;

class AreaHelper {
  areasInFault = [];

  areasToSet = [];

  // Transform ints (eg. 1,2,3) to number format (eg. 01, 02, 03)
  intToNumber(index) {
    // eslint-disable-next-line prefer-template
    return ('00' + index).slice(-2);
  }

  // Group consecutive areas to be to increase UX, see below;
  //  - Goes from:      '01,02,03,04,05,08,09'
  //  - Transforms To:  '01-05,08-09'
  selectedAreasDisplay(areas) {
    const areasDisplay = [];

    if (areas.length === 0) {
      return i18n.t('common.None');
    }
    if (areas.length === 1) {
      return `${this.intToNumber(areas[0].key)}`;
    }
    if (areas.length === limits.MAX_AREAS) {
      return i18n.t('common.All');
    }

    for (let i = 0; i < areas.length - 1; i += 1) {
      let index = i;

      while (areas[index + 1].key === (areas[index].key + 1)) {
        index += 1;
        if (index >= areas.length - 1) {
          break;
        }
      }

      if (i !== index) {
        if (i < 10) {
          areasDisplay.push(`${this.intToNumber(areas[i].key)}-${this.intToNumber(areas[index].key)}`);
        } else {
          areasDisplay.push(`${areas[i].key}-${areas[index].key}`);
        }
      } else if (i < 10) {
        areasDisplay.push(`${this.intToNumber(areas[i].key)}`);
      } else {
        areasDisplay.push(`${areas[i].key}`);
      }

      i = index;
    }

    return areasDisplay.join(', ');
  }

  async isSystemSet() {
    const result = await api.get('/Live/areaInfo/AnyAreaSet').then(r => r.data.Live.areaInfo.AnyAreaSet);
    return { result };
  }

  async armWithFaults(areaChangedCallback, areasInFault) {
    const errors = [];
    const setAreaRequests = [];

    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }

    const allZonesConfig = (await api.get('/Config/zoneInfo/Zones')).data.Config.zoneInfo.Zones;

    const faultAreasToSet = areasInFault;

    const areaIndices = faultAreasToSet.map(item => item.index).join(',');

    // Retrieve zones preventing set for this area
    const zonesPreventingArmingObj = (await api.get(`/Live/areaInfo/Areas/${areaIndices}/ZonesPreventingArming`)).data.Live.areaInfo.Areas;

    // Iterate over each area object, and output a flat array with duplicates removed
    const zpa = zonesPreventingArmingObj.filter(area => (area != null)) // Remove null values
      .flatMap(item => item.ZonesPreventingArming.split(',')) // Split each property on commas
      .map(element => parseInt(element, 10)) // Turn array of strings into array of integers
      .filter(Number.isInteger) // Filter out NaNs resulting from empty strings
      .sort() // Sort in ascending order
      .filter(onlyUnique); // Filter out duplicates

    // Iterate over the zones preventing arming
    for (let i = 0; i < zpa.length; i += 1) {
      const zoneIndex = zpa[i];
      if ((allZonesConfig[zoneIndex].Attributes & 0x01) === 0x01) { // Check if zone is omittable
        setAreaRequests.push(api.put(`/Live/zoneInfo/Zones/${zoneIndex}/ForceOmitted`, true));
      }
    }

    await Q.allSettled(setAreaRequests).then((results) => {
      results.forEach((result) => {
        if (result.state !== 'fulfilled') {
          errors.push({ type: 'SetFailed', data: { } });
        }
      });
    });

    api.put('/Live/areaInfo/RemoteArmWithFaults', true);

    // Send request to set the affected areas again, now that zones and faults are omitted
    const armAreas = areaIndices.split(',');
    armAreas.map(index => api.put(`/Live/areaInfo/Areas/${index}/AreaSet`, true));

    return {
      errors,
    };
  }

  _resetAreasInFault() {
    this.areasInFault = []; // clear areas in fault every time we do a new arm / disarm
  }

  async getAreasWithKeypads(areas) {
    const keypadRequests = [];
    for (let i = 0; i < limits.MAX_KEYPADS; i += 1) {
      const keypad = api.get(`/Config/keypadInfo/Keypads/${i}`).then(r => r.data.Config.keypadInfo.Keypads[i]);
      keypadRequests.push(Q.allSettled([keypad]));
    }

    const keypadUnsets = new Array(limits.MAX_AREAS);
    keypadUnsets.fill(false, 0, limits.MAX_AREAS);

    await Q.all(keypadRequests).then((responses) => {
      responses.forEach((response) => {
        const keypad = response[0];
        if (keypad.state === 'fulfilled' && ((keypad.value.Type === KEYPAD_AND_READER_TYPE.TYPE_KEYPAD.value)
          || (keypad.value.Type === KEYPAD_AND_READER_TYPE.TYPE_WKEYPAD.value))) {
          for (let i = 0; i < keypad.value.KeypadUnsets.length; i += 1) {
            if (keypad.value.KeypadUnsets[i]) {
              keypadUnsets[i] = true;
            }
          }
        }
      });
    });
    const areasWithKeypads = areas.filter(area => keypadUnsets[area.index]);
    return areasWithKeypads;
  }

  async toggleArea(areas, levelIndex, set, areaChangedCallback, areasUnableToSetCbk) {
    this._resetAreasInFault();
    const errors = [];
    const setAreaRequests = [];
    const faults = await this.getActiveFaults();

    const areasThatCanBeSetResult = await this._getAreasThatCanBeSet(areas, levelIndex, set);
    errors.push(...areasThatCanBeSetResult.errors);

    areasThatCanBeSetResult.areasToSet.forEach((area) => {
      this.areasToSet[area.index] = set;
    });

    for (let i = 0; i < areasThatCanBeSetResult.areasToSet.length; i += 1) {
      if (set) {
        // When arming, we need to specify the level. (When disarming, the level is implicit: only
        // the already armed level can be disarmed).
        setAreaRequests.push(api.put(`/Live/areaInfo/Areas/${areasThatCanBeSetResult.areasToSet[i].index}/ActiveLevel`, levelIndex));
      }
      setAreaRequests.push(api.put(`/Live/areaInfo/Areas/${areasThatCanBeSetResult.areasToSet[i].index}/AreaSet`, set));
    }

    await Q.allSettled(setAreaRequests).then((results) => {
      results.forEach((result) => {
        if (result.state !== 'fulfilled') {
          errors.push({ type: 'SetFailed', data: { } });
        }
      });
    });

    if (areasThatCanBeSetResult.areasToSet.length > 0) {
      this.areasInFault = await this._pollStatusUntilActionFinished(areasThatCanBeSetResult.areasToSet, set, areaChangedCallback, areasUnableToSetCbk);
      if (set && this.areasInFault.length > 0) {
        this.areasInFault = this.areasInFault.filter(area => this.areasToSet[area.index] === true);
      }
    }

    let zoneFoundInAreaLevel = false;

    if (!set) {
      const allZonesConfig = (await api.get('/Config/zoneInfo/Zones')).data.Config.zoneInfo.Zones;
      const faultAreasToSet = areasThatCanBeSetResult.areasToSet;
      for (let i = 0; i < limits.MAX_ZONES; i += 1) {
        // Areas in fault within the zone
        for (let j = 0; j < faultAreasToSet.length; j += 1) {
          // Traverse through the levels.
          for (let k = 0; k < limits.MAX_LEVELS; k += 1) {
            if (allZonesConfig[i].Areas[faultAreasToSet[j].index].Levels[k] === true && (allZonesConfig[i].Attributes & 0x01) === 0x01) {
              zoneFoundInAreaLevel = true;
            }
          }
          // Omittable zone found to disarm.
          if (zoneFoundInAreaLevel) {
            setAreaRequests.push(
              api.put(`/Live/zoneInfo/Zones/${i}/ForceOmitted`, false),
            );
            zoneFoundInAreaLevel = false;
          }
        }
      }
    }

    return {
      hasFaults: this.areasInFault.length > 0 || faults.length > 0,
      errors,
    };
  }

  async _getAreasThatCanBeSet(areas, levelIndex, set) {
    const errors = [];
    const preSetCheckRequests = [];
    for (let i = 0; i < areas.length; i += 1) {
      const areaIndex = areas[i].index;
      const levelsCanSet = api.get(`/Live/areaInfo/Areas/${areaIndex}/Levels`).then(r => r.data.Live.areaInfo.Areas[areaIndex].Levels.map(x => x.CanSet));
      preSetCheckRequests.push(Q.allSettled([levelsCanSet]));
    }

    const areasToSet = [];
    await Q.all(preSetCheckRequests).then((results) => {
      for (let i = 0; i < areas.length; i += 1) {
        let canSet = true;

        const levelsCanSet = results[i][0];

        if (levelsCanSet.state !== 'fulfilled') {
          canSet = false;
        }

        // Check whether the panel thinks the specified level of the area is currently armable.
        if (set && (levelsCanSet.value[levelIndex] === false)) {
          errors.push({ type: 'CannotSet', data: { set, id: areas[i].identifier } });
          canSet = false;
        }

        if (canSet) {
          areasToSet.push(areas[i]);
        }
      }
    });

    return { errors, areasToSet };
  }

  dropPolledEndpoint(polledEndpoint) {
    try {
      const temp = window.globalThis.currentRequiredEndpoints;
      window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== polledEndpoint);
    } catch (err) {
      // nothing to do
    }
  }

  async _pollStatusUntilActionFinished(areas, set, areaChangedCallback, areasUnableToSetCbk) {
    const areaInfoPolledEndpoint = '/Live/areaInfo/Areas';
    let finishedActionPromiseResolver;
    const finishedActionPromise = new Promise((resolve) => {
      finishedActionPromiseResolver = resolve;
    });

    const areaStatus = createArray(areas.length, i => ({
      index: areas[i].index, initialState: areas[i].areaStatus, state: null, settingStarted: false,
    }));

    // Bring the ad-hoc areaInfo endpoint into the scope of current endpoints
    if (window.globalThis.currentRequiredEndpoints.indexOf(areaInfoPolledEndpoint) === -1) {
      window.globalThis.currentRequiredEndpoints.push(areaInfoPolledEndpoint);
    }

    let unableToSetChecked = false;

    const pollingCall = await api.poll(500, areaInfoPolledEndpoint, (res) => {
      for (let i = 0; i < areaStatus.length; i += 1) {
        areaStatus[i].state = res.data.Live.areaInfo.Areas[areaStatus[i].index].AreaStatus;

        // allow for SET_DELAYED zones state to set to 'setting started'.
        if (set && areaStatus[i].state === 'SET_DELAYED') {
          areaStatus[i].settingStarted = true;
        }

        if (set && areaStatus[i].state === 'SETTING') {
          areaStatus[i].settingStarted = true;
        }

        areaChangedCallback({
          index: areaStatus[i].index,
          state: res.data.Live.areaInfo.Areas[areaStatus[i].index].AreaStatus,
          levelIndex: res.data.Live.areaInfo.Areas[areaStatus[i].index].ActiveLevel,
        });
      }

      // Call areasUnableToSetCbk if any areas are SET_DELAYED and once we've collected all areas
      if (!unableToSetChecked && set && (areasUnableToSetCbk !== undefined)) {
        if (areaStatus.every(a => (a.state === 'SET_DELAYED') || (a.settingStarted === true && a.state === 'SETTING'))) {
          unableToSetChecked = true;
          // Collate list of areas in SET_DELAYED and pass to areasUnableToSetCbk
          const setDelayedIndices = areaStatus.filter(a => (a.state === 'SET_DELAYED')).map(a => a.index);
          if (setDelayedIndices.length > 0) {
            const setDelayed = areas.filter(a => (setDelayedIndices.includes(a.index)));
            areasUnableToSetCbk(setDelayed, areaStatus.filter(a => ((a.state !== 'SET') && (a.state !== 'SETTING'))));
          }
        }
      }

      // disarm and state has changed || arm and has changed from setting to something new
      if (areaStatus.every(a => (set === false && a.state !== 'SETTING') || (set === true && a.settingStarted === true && a.state !== 'SETTING'))) {
        finishedActionPromiseResolver();
        this.dropPolledEndpoint(areaInfoPolledEndpoint);
        return false;
      }
      // The above 'return' results in ESLint "consistent-return" - we must return something here!
      return true;
    });

    await finishedActionPromise;
    pollingCall.polling.cancel();
    this.dropPolledEndpoint(areaInfoPolledEndpoint);

    const areasInFault = set && areaStatus.filter(a => a.state !== 'SET');
    return areasInFault;
  }

  async getActiveFaults(endpoint) {
    const response = endpoint || await api.get('/Live/ActiveFaults');

    const faults = [];

    // Endpoints required for monitoring code guessing and line comms fault status
    const commsStatusEndpoint = await api.get('/Live/System/CommsStatus');
    if (commsStatusEndpoint.data.Live.System.CommsStatus !== 'OK') {
      faults.push(i18n.t('enums.FAULT.LineComms'));
    }

    if (response.data.Live.ActiveFaults.System.CodeGuessing === true) {
      faults.push(i18n.t('enums.FAULT.CodeGuessing'));
    }

    if (response.data.Live.ActiveFaults.System.PanicAlarm === true) {
      faults.push(i18n.t('enums.FAULT.PanicAlarm'));
    }

    if (response.data.Live.ActiveFaults.System.FireAlarm === true) {
      faults.push(i18n.t('enums.FAULT.FireAlarm'));
    }

    if (response.data.Live.ActiveFaults.System.NoOccupancyAlarm === true) {
      faults.push(i18n.t('enums.FAULT.NoOccupancyAlarm'));
    }

    Object.keys(response.data.Live.ActiveFaults.Devices.Endstation).forEach((key) => {
      if (response.data.Live.ActiveFaults.Devices.Endstation[key] === true) {
        faults.push(`${i18n.t('enums.ASSOCIATED_WITH.ENDSTATION')} - ${i18n.t(`enums.FAULT.${key}`)}`);
      }
    });

    for (let i = 0; i < response.data.Live.ActiveFaults.Devices.Keypads.length; i += 1) {
      Object.keys(response.data.Live.ActiveFaults.Devices.Keypads[i]).forEach((key) => {
        if (response.data.Live.ActiveFaults.Devices.Keypads[i][key] === true) {
          faults.push(`${i18n.t('enums.ASSOCIATED_WITH.KEYPAD', { number: i })} - ${i18n.t(`enums.FAULT.${key}`)}`);
        }
      });
    }

    for (let i = 0; i < response.data.Live.ActiveFaults.Devices.OutputExpanders.length; i += 1) {
      Object.keys(response.data.Live.ActiveFaults.Devices.OutputExpanders[i]).forEach((key) => {
        if (response.data.Live.ActiveFaults.Devices.OutputExpanders[i][key] === true) {
          faults.push(`${i18n.t('enums.ASSOCIATED_WITH.OUTPUT_EXPANDER', { number: i })} - ${i18n.t(`enums.FAULT.${key}`)}`);
        }
      });
    }

    for (let i = 0; i < response.data.Live.ActiveFaults.Devices.Readers.length; i += 1) {
      Object.keys(response.data.Live.ActiveFaults.Devices.Readers[i]).forEach((key) => {
        if (response.data.Live.ActiveFaults.Devices.Readers[i][key] === true) {
          faults.push(`${i18n.t('enums.ASSOCIATED_WITH.READER', { number: i })} - ${i18n.t(`enums.FAULT.${key}`)}`);
        }
      });
    }

    for (let i = 0; i < response.data.Live.ActiveFaults.Devices.wiredHubs.length; i += 1) {
      Object.keys(response.data.Live.ActiveFaults.Devices.wiredHubs[i]).forEach((key) => {
        if (response.data.Live.ActiveFaults.Devices.wiredHubs[i][key] === true) {
          faults.push(`${i18n.t('enums.ASSOCIATED_WITH.WIRED_HUB', { number: i })} - ${i18n.t(`enums.FAULT.${key}`)}`);
        }
      });
    }

    for (let i = 0; i < response.data.Live.ActiveFaults.Devices.wirelessHubs.length; i += 1) {
      Object.keys(response.data.Live.ActiveFaults.Devices.wirelessHubs[i]).forEach((key) => {
        if (response.data.Live.ActiveFaults.Devices.wirelessHubs[i][key] === true) {
          faults.push(`${i18n.t('enums.ASSOCIATED_WITH.WIRELESS_HUB', { number: i })} - ${i18n.t(`enums.FAULT.${key}`)}`);
        }
      });
    }

    for (let i = 0; i < response.data.Live.ActiveFaults.WirelessKeypads.length; i += 1) {
      Object.keys(response.data.Live.ActiveFaults.WirelessKeypads[i]).forEach((key) => {
        if (response.data.Live.ActiveFaults.WirelessKeypads[i][key] === true) {
          faults.push(`${i18n.t('enums.ASSOCIATED_WITH.WIRELESS_KEYPAD', { number: i + 1 })} - ${i18n.t(`enums.FAULT.${key}`)}`);
        }
      });
    }

    for (let i = 0; i < response.data.Live.ActiveFaults.WirelessOutputs.length; i += 1) {
      Object.keys(response.data.Live.ActiveFaults.WirelessOutputs[i]).forEach((key) => {
        if (response.data.Live.ActiveFaults.WirelessOutputs[i][key] === true) {
          faults.push(`${i18n.t('enums.ASSOCIATED_WITH.WIRELESS_OUTPUT', { number: i + 1 })} - ${i18n.t(`enums.FAULT.${key}`)}`);
        }
      });
    }

    for (let i = 0; i < response.data.Live.ActiveFaults.WirelessInputs.length; i += 1) {
      Object.keys(response.data.Live.ActiveFaults.WirelessInputs[i]).forEach((key) => {
        if (response.data.Live.ActiveFaults.WirelessInputs[i][key] === true) {
          // The number passed to the text element is formed using the index of the WE zone
          // in the WE zones faults array [0-95] + 1 (zero-based index) + max number of zones on the endstation
          faults.push(`${i18n.t('enums.ASSOCIATED_WITH.WIRELESS_INPUT', { number: i + 1 + limits.MAX_ES_ZONES })} - ${i18n.t(`enums.FAULT.${key}`)}`);
        }
      });
    }

    return faults;
  }

  async getZonesPreventingArming(areas) {
    const areaIndices = areas.map(item => item.index).join(',');

    // Retrieve zones preventing set for this area
    const zonesPreventingArmingObj = (await api.get(`/Live/areaInfo/Areas/${areaIndices}/ZonesPreventingArming`)).data.Live.areaInfo.Areas;

    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }

    // Iterate over each area object, and output a flat array with duplicates removed
    const zpa = zonesPreventingArmingObj.filter(area => (area != null)) // Remove null values
      .flatMap(item => item.ZonesPreventingArming.split(',')) // Split each property on commas
      .map(element => parseInt(element, 10)) // Turn array of strings into array of integers
      .filter(Number.isInteger) // Filter out NaNs resulting from empty strings
      .sort() // Sort in ascending order
      .filter(onlyUnique); // Filter out duplicates

    return zpa;
  }

  async getZonesPreventingArmingText(areas) {
    const zpa = await this.getZonesPreventingArming(areas);

    const zonesPreventingArmingText = [];

    if (zpa.length > 0) {
      const zoneInfo = (await api.get(`/Live/zoneInfo/Zones/${zpa.join(',')}/state`)).data.Live.zoneInfo.Zones;

      // Iterate over the zones preventing arming
      for (let i = 0; i < zpa.length; i += 1) {
        const zoneIndex = zpa[i];
        const zoneStatus = zoneInfo[zoneIndex].state;
        zonesPreventingArmingText.push(`${i18n.t('common.Zone')} ${zoneIndex + 1} - ${i18n.t(`zones.enums.state.${zoneStatus}`)}`);
      }
    }
    return zonesPreventingArmingText;
  }
}

const helper = new AreaHelper();

export default helper;
