import { helpers } from 'vuelidate/lib/validators';


function hostIsIPAddress(host) {
  return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(host));
}


function hostIsCslRef(host) {
  return (/^\d{5}$/.test(host));
}


export default () => helpers.withParams(
  {
    type: 'validArcHost',
  },
  (value, vm) => {
    if (vm.hostOption === 'CSL') {
      return hostIsCslRef(value);
    }
    if (vm.hostOption === 'IPA') {
      return hostIsIPAddress(value);
    }
    if (vm.hostOption === 'URL') {
      return true; // can't really validate this...
    }
    return false;
  },
);
