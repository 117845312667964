import { CommandError } from '@/app/shared/errors';

const ErrorToast = {
  data() {
    return {
      errors: {
        alertMessage: undefined,
      },
    };
  },
  errorCaptured(err) {
    if (err instanceof CommandError) {
      const topErrors = err.subErrors.filter(e => e.position === 'top');
      if (topErrors.length > 0) {
        this.alertMessage = topErrors.map(e => `<strong>${this.$t(`errors.${err.command}.${e.type}.title`, { id: e.data.id })}</strong> ${this.$t(`errors.${err.command}.${e.type}.description`, { id: e.data.id })}`).join('<br />');
      }

      const toastErrors = err.subErrors.filter(e => e.position === undefined);

      if (toastErrors.length > 0) {
        const description = toastErrors.map(e => this.$t(`errors.${err.command}.${e.type}.description`, { id: e.data.id })).join('\n');

        this.$notification.open({
          icon: <a-icon type="close-circle" style="color: #ff0000" />,
          placement: 'bottomRight',
          message: this.$t('errors.title'),
          description,
        });
      }
    }
  },
};

export default ErrorToast;
