import { KEYPAD_AND_READER_TYPE, READER_TYPE } from '@/app/keypads-and-readers/shared/enums';
import { limits } from '@/app/shared/constants';
import i18n from '@/app/shared/services/i18n';

export function getDoors(configKeypadInfo, user) {
  const doors = [];
  const associatedDoors = [];
  for (let i = 0; i < limits.MAX_WIRED_KP; i += 1) {
    const kpConfig = configKeypadInfo.data.Config.keypadInfo.Keypads[i];
    const type = KEYPAD_AND_READER_TYPE.fromInt(kpConfig.Type);
    const readerType = READER_TYPE.fromInt(kpConfig.ReaderType);
    const name = kpConfig.Name;
    const canAccess = user != null ? user.UserCanAccess[i] : false;
    if (type === KEYPAD_AND_READER_TYPE.TYPE_READER && (readerType === READER_TYPE.ACCESS_CONTROL || readerType === READER_TYPE.ENTRY_CONTROL)) {
      if (canAccess) {
        associatedDoors.push({ value: i, name });
      }
      doors.push({ value: i, name });
    }
  }

  return {
    doors, associatedDoors,
  };
}


export function getDoorZones(configZoneInfoParts) {
  const disabledZone = { internalKey: limits.MAX_ZONES, name: i18n.t('zones.doors.No-door-zone'), type: 'ISOLATED' };
  const zones = [disabledZone];

  const doorZones = configZoneInfoParts.data.Config.zoneInfo.Zones
    .map((k, i) => ({ internalKey: i, name: k.Name.trim(), type: k.ZoneType }))
    .filter(k => k.type !== 'ISOLATED');
  return zones.concat(doorZones);
}
