<template>
  <two-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
    <template v-slot:title>
          <h1 id="title">{{ $t('users.navigationTitle') }}</h1>
    </template>
    <template v-slot:titleCommands>
      <a-button type="settings" @click="$router.push({ name: 'settings.userSettings' })">
        <font-awesome-icon :icon="['fal', 'cog']" />
      </a-button>
      <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" icon="plus-circle" @click="grid.drawerVisible = true">{{ $t('users.Add-user') }}</a-button>
    </template>

    <div>
      <div>
        <a-input-search :placeholder="$t('users.Search-for-user')" size="large" v-model="grid.searchQuery" />

        <a-row :gutter="23" class="header">
          <a-col :xs="{ span: 18}" :md="{ span: 18}" :lg="{ span: 18}" :xl="{ span: 18}" class="filters">
            <a-dropdown class="grid-filter">
              <a-menu slot="overlay">
                <a-menu-item @click="gridDeleteSelected">
                  {{ $t('common.Delete')}}
                </a-menu-item>
              </a-menu>
              <a-button>
                {{ $t('common.Actions') }} <a-icon type="down" />
              </a-button>
            </a-dropdown>
            <grid-filter :title="$t('common.Access-type')" :records="allItems" field="accessType" @change="gridFilterChange" resPrefix="users.enums.ACCESS_TYPE." :initialFilters="gridSavedFilters('accessType')" />
            <grid-filter :title="$t('users.User-type')" :records="allItems" field="userType" @change="gridFilterChange" resPrefix="users.enums.USER_TYPE." :initialFilters="gridSavedFilters('userType')" />
            <grid-order :options="[{ field: 'name', name:  $t('common.Name')},{ field: 'number', name: $t('common.Number')}]" @change="gridOrderByChange" />
          </a-col>
          <a-col :xs="{ span: 6}" :md="{ span: 6}" :lg="{ span: 6}" :xl="{ span: 6}" class="align-right hide-small">
            <grid-display-toggle :isTabularview="grid.options.isTabularView" @toggle="onGridDisplayToggle" />
          </a-col>
        </a-row>
          <ul class="inline-list">
            <a-skeleton :loading="loadingConfig" :title="false" :paragraph="{ rows:1, width:'100%' }">
              <li><span>{{ installed }}/{{ max }} {{ $t('common.users') }}</span></li>
            </a-skeleton>
          </ul>
      </div>
      <a-skeleton :loading="loadingConfig" :title="false" :paragraph="{ rows:10, width:'100%' }">
        <grid
          :options="grid.options"
          :records="gridItems">
        </grid>
      </a-skeleton>
    </div>
    <add-user :visible="grid.drawerVisible" @onClose="grid.drawerVisible = false" :clone-payload="grid.clonePayload" />
    <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
      @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </two-col-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import AddUser from '@/app/users/shared/components/add-user/add-user.vue';

import {
  ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard,
} from '@/app/shared/mixins';

import { ACCESS_TYPE, USER_TYPE } from '@/app/users/shared/enums';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

const storeNamespace = 'usersState/list';
const { mapState } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard],
  components: {
    AddUser,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      grid: {
        itemsStorePath: `${storeNamespace}/items`,
        deleteItemsStoreAction: `${storeNamespace}/delete`,

        canToggle: true,
        searchFields: ['name'],

        options: {
          columns: [
            {
              title: this.$t('common.Name'),
              dataIndex: 'name',
              isTitle: true,
            },
            {
              title: this.$t('users.Access'),
              dataIndex: 'accessType',
              customRender: text => this.$t(`users.enums.ACCESS_TYPE.${text}`),
            },
            {
              title: this.$t('users.Code'),
              dataIndex: 'code',
              customRender: () => '****',
            },
            {
              title: this.$t('users.User-type'),
              dataIndex: 'userType',
              customRender: text => this.$t(`users.enums.USER_TYPE.${text}`),
            },
            {
              title: this.$t('common.Number'),
              dataIndex: 'number',
              customRender: text => (text > 0 ? text : ''),
            },
          ],

          rowState: () => 'none',
          canDelete: record => this.configWriteLock.canWriteConfig && record.userType !== USER_TYPE.ENGINEER.value && record.userType !== USER_TYPE.MASTERMAN.value,
          canClone: record => this.configWriteLock.canWriteConfig && record.accessType === ACCESS_TYPE.USER_CODE.value,
          canToggle: () => false,
          canSelect: record => record.userType !== USER_TYPE.ENGINEER.value && record.userType !== USER_TYPE.MASTERMAN.value,
          click: (record) => {
            this.$router.push({ name: 'user', params: { index: record.index } });
          },
        },
      },
    };
  },
  computed: {
    ...mapState(['max', 'installed', 'loadingConfig']),
  },
  methods: {
  },
};
</script>

<style lang="scss">
</style>
