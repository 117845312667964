<template>
<div>
  <a-drawer
    placement="right"
    :closable="false"
    @close="onClose"
    :destroyOnClose="true"
    :visible="visible">

    <wizard :config="wizard" :state="$v" @done="onClose" >
      <!-- Initial page - get pin code, start upload -->
      <wizard-step name="start">
        <template slot="body">
          <a-form>
            <p><b>{{ $t('maintenance.drawer.firmwareUpdate.Select-image-file') }}</b></p>
            <a-upload accept=".mender" :fileList="fileList" :remove="handleRemove" :before-upload="beforeUpload">
              <a-button type="primary" :disabled="uploading || installing" > {{ $t('maintenance.drawer.firmwareUpdate.Firmware-image') }} </a-button>
            </a-upload>

            <p style="margin-top: 16px"> </p>
            <p-password :label="$t('maintenance.drawer.firmwareUpdate.Manufacture-code')" :model="$v.form.passCode" :customValidations="[{ key: 'allNumbers'}]" />

            <a-button type="primary" :disabled="(fileList.length === 0) || (form.passCode !== '538354')" :loading="uploading" style="margin-top: 16px"  @click="handleUpload">
              {{ uploading ? $t('maintenance.drawer.firmwareUpdate.Uploading') : $t('maintenance.drawer.firmwareUpdate.Start-upload') }}
            </a-button>
          </a-form>
        <a-divider />
          <a-form>
            <h4>{{ $t('maintenance.drawer.firmwareUpdate.Progress') }} </h4>
            <span class="h4">{{ progressText }}</span>
            <a-progress :percent="cents" :showInfo=false />
            <a-alert v-if="rebooting" showIcon type="warning" style="margin-bottom: 24px;" :message="$t('maintenance.drawer.firmwareUpdate.Will-reboot-soon')" />
          </a-form>
        </template>
        <template slot="footer">
          <a-button size="large" type="link" @click="onClose" style="float: left;">{{ $t('common.Cancel') }}</a-button>
        </template>
      </wizard-step>
   </wizard>
  </a-drawer>
</div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';
import firmwareUpdate from '@/app/maintenance/shared/services/firmware-update';


function allNumbers(value) {
  return value == null || /^\d+$/.test(value);
}

export default {
  components: {
    Wizard,
    WizardStep,
  },
  props: {
    visible: { default: false },
  },
  data() {
    return {
      fileList: [],
      uploading: false,
      installing: false,
      rebooting: false,
      progressText: this.$t('maintenance.drawer.firmwareUpdate.Idle'),
      cents: 0,
      prevCents: 0,
      wizard: {
        start: {
          title: this.$t('maintenance.FirmwareUpdate'),
          next: [
            { key: 'upload', buttonText: this.$t('common.Next') },
          ],
        },
      },
      form: {
        passCode: null,
        passValid: true,
      },
    };
  },
  validations: {
    form: {
      passCode: {
        required, allNumbers,
      },
    },
  },
  methods: {
    resetData() {
      this.fileList = [];
      this.uploading = false;
      this.installing = false;
      this.rebooting = false;
      this.progressText = this.$t('maintenance.drawer.firmwareUpdate.Idle');
      this.cents = 0;
      this.prevCents = 0;
      this.form.passCode = null;
      this.form.passValid = true;
    },
    async onClose() {
      this.$v.$reset();
      this.resetData();
      firmwareUpdate.endFWprogress();
      this.$emit('onClose');
    },
    handleRemove() {
      this.fileList = [];
    },
    beforeUpload(file) {
      this.fileList = [file];
      return false;
    },
    afterUpload() {
      this.installing = true;
      this.uploading = false;
      this.cents = 1;
      this.fileList = [];
      this.progressText = this.$t('maintenance.drawer.firmwareUpdate.Checking-image');
      firmwareUpdate.startFWprogress((eta, cent) => {
        const prev = this.cents;
        if (cent >= 9999) {
          this.installing = false;
          this.rebooting = true;
        }
        if (cent > 75) {
          let doneCent = cent / 102;
          if (doneCent > this.cents) {
            if (doneCent > (prev + 0.9)) {
              doneCent = prev + 0.9;
            }
            this.cents = doneCent;
          }
          const t = this.$t('maintenance.drawer.firmwareUpdate.Install-time-remaining');
          const m = Math.floor(eta / 60);
          const s = Math.floor(eta % 60);
          if (s < 10) {
            this.progressText = `${t} ${m}:0${s}`;
          } else {
            this.progressText = `${t} ${m}:${s}`;
          }
          if (m < 1) {
            this.rebooting = true;
          }
        } else if (this.cents < 10) {
          this.cents = this.cents + 0.5;
        }
      });
    },
    handleUpload() {
      const formData = new FormData();
      this.uploading = true;
      this.rebooting = false;
      this.progressText = this.$t('maintenance.drawer.firmwareUpdate.Uploading');
      // Add upload code
      formData.append('mender-artifact', this.fileList[0]);
      // NOTE: the request below will fail in debug LAN instances;
      //       To fix this edit the path 'http://<PanelIP>/uploads.cgi'
      axios.post('/uploads.cgi',
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(() => {
        this.afterUpload();
      });
    },
  },
};

</script>

<style lang="scss" scoped>
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

    .card__stat {

        &__status {
            font-size: 0.8rem;
            line-height: 1.2;

            &--in-progress {
                display: block;
                color:cornflowerblue;
            }
            &--days-left {
                display: block;
            }
        }

        &--with-status {
            margin-top: 0px;
        }
    }
</style>
