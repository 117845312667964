import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { trim } from '@/app/shared/utils/text-helper';

function initialState() {
  return {
    form: {
      enableAutoIP: undefined,
      ipAddress: undefined,
      subnetMask: undefined,
      routerAddress: undefined,
      dnsIPAddress: undefined,
      otherDnsIPAddress: undefined,
      networkDelayFault: undefined,
    },
  };
}


const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configCommsInfo', 'configSysInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        enableAutoIP: endpoints.configCommsInfo.data.Config.commsInfo.Ethernet_NetworkData[0].DHCPEnable,
        ipAddress: trim(endpoints.configCommsInfo.data.Config.commsInfo.Ethernet_NetworkData[0].IP),
        subnetMask: trim(endpoints.configCommsInfo.data.Config.commsInfo.Ethernet_NetworkData[0].NetMask),
        routerAddress: trim(endpoints.configCommsInfo.data.Config.commsInfo.Ethernet_NetworkData[0].Gateway),
        dnsIPAddress: trim(endpoints.configCommsInfo.data.Config.commsInfo.Ethernet_NetworkData[0].DNS),
        otherDnsIPAddress: trim(endpoints.configCommsInfo.data.Config.commsInfo.Ethernet_NetworkData[0].OtherDNS),
        networkDelayFault: endpoints.configSysInfo.data.Config.sysInfo.Timers.LineFaultTimeEthernet_mins,
      });
    },
    async save(context) {
      const data = {
        DHCPEnable: context.state.form.enableAutoIP,
        IP: context.state.form.ipAddress,
        NetMask: context.state.form.subnetMask,
        Gateway: context.state.form.routerAddress,
        DNS: context.state.form.dnsIPAddress,
        OtherDNS: context.state.form.otherDnsIPAddress,
      };

      await api.put('/Config/sysInfo/Timers/LineFaultTimeEthernet_mins', parseInt(context.state.form.networkDelayFault, 10));
      await api.put('/Config/commsInfo/Ethernet_NetworkData/0', data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('communicationsState/lan/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['communicationsState/lan'] });
    },
  },
});


export default store;
