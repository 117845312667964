import i18n from '@/app/shared/services/i18n';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import {
  translateOutputType, getAssociatedDevices, saveOutputType, getSingleOutputInfo, isSiren, isSirenOutputType, getOutputApiPath,
} from '@/app/outputs/shared/services/helpers';
import { supportsLiveFaults, calculateLiveFaults } from '@/app/sirens/shared/services/sirens-helper';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';

export function generateSirenName(type, number) {
  return i18n.t(`sirens.names.${type}`, { number });
}

function initialState() {
  return {
    name: undefined,
    number: undefined,
    state: undefined,
    faultState: undefined,

    associatedDevices: [],
    _outputTypes: [],

    form: {
      rawOutputType: undefined,
      outputType: undefined,
      associatedWith: undefined,
      number: undefined,
      associatedWithDevice: undefined,
      associatedWithOutputIndex: undefined,
    },

    diagnostics: [
      {
        key: 'sirens.Diagnostics.Status',
        isLoading: true,
        value: undefined,
      },
    ],
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {
    outputTypes: state => state._outputTypes,
    isSiren: state => isSirenOutputType(state.form.outputType) || isSiren({ type: state.form.associatedWithDevice, deviceOutputIndex: state.form.associatedWithOutputIndex }),
  },
  actions: {
    requiredEndpoints() {
      return ['infoEndstation', 'infoOutputInfo', 'infoBusDevices', 'infoKeypadInfo', 'configEndstation', 'configBusDevices', 'configKeypadInfo', 'configOutputInfo', 'livePanelStatus', 'liveActiveFaults'];
    },
    async populate(context, { endpoints, payload }) {
      const item = getSingleOutputInfo(
        endpoints,
        payload.index,
      );

      context.commit('setForm', {
        rawOutputType: item.rawOutputType,
        outputType: item.outputType,
        associatedWith: item.associatedWith,
        associatedWithDevice: `${item.associatedWith.type}_${item.associatedWith.deviceIndex || 0}`,
        associatedWithOutputIndex: item.associatedWith.deviceOutputIndex,
      });

      const outputTypes = [];
      for (let i = 0; i < endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes.length; i += 1) {
        const outputType = endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes[i].Name;
        if (outputType != null) {
          outputTypes.push({
            value: i, key: outputType, name: translateOutputType(outputType), isSirenOutputType: isSirenOutputType(i),
          });
        }
      }

      const devices = getAssociatedDevices(payload.index, endpoints).filter(d => d.type !== 'WIRELESS_ZONE_EXPANDER');
      context.commit('set', {
        name: item.name,
        associatedDevices: devices,
        associatedWith: item.associatedWith,
        supportsLiveFaults: supportsLiveFaults(endpoints.configEndstation, item.outputType, item.associatedWith),
        _outputTypes: outputTypes,
      });

      await context.dispatch('onPoll', { key: 'livePanelStatus', endpoint: endpoints.livePanelStatus });
      await context.dispatch('onPoll', { key: 'liveActiveFaults', endpoint: endpoints.liveActiveFaults });
    },
    async onPoll(context, { key, endpoint }) {
      if (key === 'livePanelStatus' && context.state.form.rawOutputType != null) {
        const { state } = endpoint.data.Live.PanelStatus.OutputTypes[context.state.form.rawOutputType];
        context.commit('set', { state });
      }

      if (key === 'liveActiveFaults' && context.state.supportsLiveFaults != null) {
        if (context.state.supportsLiveFaults === true) {
          const liveFaults = calculateLiveFaults(endpoint, context.state.form.outputType, context.state.form.associatedWith);
          const newFaultState = liveFaults.length > 0;

          context.commit('set', { faultState: newFaultState });
          context.commit('setItemByKey', { key: 'sirens.Diagnostics.Status', collection: 'diagnostics', item: { isLoading: false, value: newFaultState ? 'FAULT' : 'OK', types: liveFaults } });
        } else {
          // if doesn't support live faults (not PGMs index 0) always return the status as OK with no faults
          context.commit('set', { faultState: false });
          context.commit('setItemByKey', { key: 'sirens.Diagnostics.Status', collection: 'diagnostics', item: { isLoading: false, value: 'OK' } });
        }
      }
    },
    async save(context) {
      // Unset existing
      await saveOutputType(api, context.state.associatedWith.type, context.state.associatedWith.deviceIndex, context.state.associatedWith.deviceOutputIndex, 0);

      // Get the new device index
      const associatedDevice = context.state.associatedDevices.find(v => v.key === context.state.form.associatedWithDevice);
      const deviceOutputIndex = context.state.form.associatedWithOutputIndex;
      const { deviceIndex, type } = associatedDevice;

      // Set new
      await saveOutputType(api, type, deviceIndex, deviceOutputIndex, context.state.form.outputType);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('sirensState/wiredSiren/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['sirensState/wiredSiren'] });
    },
    async delete(context) {
      const { type, deviceIndex, deviceOutputIndex } = context.state.associatedWith;
      const path = getOutputApiPath(type, deviceIndex, deviceOutputIndex);
      // eslint-disable-next-line no-await-in-loop
      const objDefaults = await generateDefaultObject(path);
      // eslint-disable-next-line no-await-in-loop
      await api.put(path, objDefaults);

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('sirensState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('sirensState/addSiren/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['sirensState/list'] });
    },
  },
});

export default store;
