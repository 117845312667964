import { storeFactory } from '@/app/shared/services/store-helper';
import { USER_DEFINED_OUTPUT_TYPE } from '@/app/outputs/shared/enums';
import {
  USER_DEFINED_OUTPUT_BEGIN, USER_DEFINED_OUTPUT_END,
} from '@/app/outputs/shared/services/helpers';
import i18n from '@/app/shared/services/i18n';

function initialState() {
  return {
    _items: [],
  };
}

const { store } = storeFactory(initialState, {
  getters: {
    items: state => state._items,
  },
  actions: {
    requiredEndpoints() {
      return ['configOutputInfo', 'livePanelStatus'];
    },

    async populate(context, { endpoints }) {
      context.commit('set', {
        _items: [],
      });

      const config = endpoints.configOutputInfo.data.Config.outputInfo.UserDefinedOutputs;
      const maxItems = config.length;
      const items = [];
      for (let i = 0; i < maxItems; i += 1) {
        const name = config[i].UserOutputName.trim();
        items.push({
          name: (name.length > 0) ? name : i18n.t('userDefinedOutput.defaultName', { index: i + 1 }),
          outputType: USER_DEFINED_OUTPUT_TYPE.fromInt(config[i].UserOutputLatchedOrTimed),
          number: i + 1,
          index: i,
          outputOnTime: config[i].UserOutputOnTime_secs,
        });
      }
      context.commit('set', { _items: items });
    },

    async onPoll(context, { dataStatus, key, endpoint }) {
      if (key === 'livePanelStatus') {
        const items = [];
        for (let i = USER_DEFINED_OUTPUT_BEGIN; i < USER_DEFINED_OUTPUT_END; i += 1) {
          items.push({ state: endpoint.data.Live.PanelStatus.OutputTypes[i].state });
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: '_items', items });
      }
    },
  },
});

export default store;
