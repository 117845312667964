import { storeFactory } from '@/app/shared/services/store-helper';
import { limits } from '@/app/shared/constants';
import zoneAreasHelper from '@/app/shared/services/zone-areas-helper';

function initialState() {
  return {
    availableZones: [],
    areas: [],
    zones: [],
    form: {
      zone: undefined,
      areas: [],
      areasInfo: undefined,
      areasConfig: undefined,
    },
  };
}

const { store } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configZoneInfoParts', 'infoAreaInfo', 'configAreaInfoNames'].concat(zoneAreasHelper.zoneAreasEndpointsList); // Ten ConfigZoneInfoArea endpoints
    },
    async populate(context, { endpoints }) {
      const areas = [];
      const zones = [];

      for (let x = 0; x < endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones.length; x += 1) {
        const zoneType = endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[x].ZoneType;
        const zoneName = endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[x].Name;
        if (zoneType !== 'ISOLATED') {
          zones.push({ index: x, name: zoneName });
        }
      }
      for (let i = 0; i < limits.MAX_AREAS; i += 1) {
        const area = { zones: [] };
        // TODO Could this use ActiveZones endpoint instead?
        for (let x = 0; x < endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones.length; x += 1) {
          const zoneAreas = zoneAreasHelper.getAllAreaDetailsForZone(endpoints, x);
          const zoneType = endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[x].ZoneType;
          // A zone should be walk tested (for the current area) if:
          // - It's configured to have any type other than "none" (a.k.a. "isolated").
          // AND:
          // - It's configured to be in any level of the area.
          if (zoneType !== 'ISOLATED' && zoneAreas[i].Levels.some(level => level === true)) {
            area.zones.push(x);
          }
        }
        areas.push(area);
      }

      context.commit('set', {
        areas,
        availableZones: zones,
      });

      context.commit('setForm', {
        areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
        areasConfig: endpoints.configAreaInfoNames.data.Config.areaInfo,
      });
    },
    async getZonesForAreas(context, areas) {
      const zones = [];
      for (let i = 0; i < areas.length; i += 1) {
        if (areas[i]) {
          zones.push(...context.state.areas[i].zones.map(z => ({
            index: z,
            name: context.state.availableZones.find(x => x.index === z).name,
            found: false,
          })));
        }
      }
      return zones;
    },
  },
});

export default store;
