<template>
    <a-dropdown :class="{ alternative: isAlternative }" @visibleChange="visibleChanged" :visible="dropdownVisible" :trigger="['click']" :getPopupContainer="getPopupContainer" :disabled="disabled">
      <div slot="overlay" class="area-selector-dropdown">
        <a-menu>
          <a-menu-item v-if="!singleCheck">
            <a-checkbox :checked="allSelected" @change="toggleSelectAll">
              <span v-if="allSelected">Deselect all</span>
              <span v-else>Select all</span>
            </a-checkbox>
          </a-menu-item>
          <a-menu-item v-for="(area, index) in areas" :key="`area-${index}`">
              <a-checkbox :checked="area.checked" @change="onCheckboxChange(area, $event)">
                  {{ $t('common.Area') }} {{ area.identifier }} - {{ area.name }}
              </a-checkbox>
          </a-menu-item>
        </a-menu>
      </div>
      <div class="ant-dropdown-trigger" @mouseenter="activeHover = true" @mouseleave="activeHover = false">
        <p v-if="title.length!=0">
          <span v-if="activeHover"> {{ title }} </span>
          <span v-if="!activeHover"> {{ title }} </span>
        </p>
        <p v-else-if="title.length===0">
          <a-button class="ant-dropdown-trigger">
            <span style="overflow: hidden;">
              {{ title }}
            </span>
            <a-icon type="down"/>
          </a-button>
        </p>
      </div>
    </a-dropdown>
</template>

<script>
import { every } from 'lodash';

export default {
  components: {

  },
  props: ['singleCheck', 'value', 'areasInfo', 'areasConfig', 'isAlternative', 'disabled'],
  data() {
    return {
      dropdownVisible: false,
      areas: [],
      activeHover: false,
      singleCheckedArea: undefined,
    };
  },
  computed: {
    title() {
      const clubbedAreas = [];
      let displayAreasString = '';
      let clubAreas = [];
      // Select areas numbers that have been selected
      const selectedAreas = this.areas.filter(x => x.checked).map(x => x.identifier);
      // Insert commas in the array to mark start of sequential/individual areas
      for (let x = 0; x < selectedAreas.length; x += 1) {
        if (x === 0) {
          clubAreas.push(parseInt(selectedAreas[0], 10));
        } else if (x > 0) {
          if (parseInt(selectedAreas[x], 10) - parseInt(selectedAreas[x - 1], 10) === 1) {
            clubAreas.push(parseInt(selectedAreas[x], 10));
          } else {
            clubAreas.push(',');
            clubAreas.push(parseInt(selectedAreas[x], 10));
          }
        }
      }
      // Club sequential areas with hyphenated text: starting Area number - Ending Area number
      while (clubAreas.length > 0) {
        const index = clubAreas.findIndex(y => y === ',');
        const startElement = clubAreas[0];
        let endElement = null;
        if (index !== -1) {
          if (index === 1) {
            clubbedAreas.push(` ${startElement}`);
          } else {
            endElement = clubAreas[index - 1];
            clubbedAreas.push(` ${startElement} - ${endElement}`);
          }
          clubAreas = clubAreas.slice(index + 1);
        } else if (clubAreas.length > 1) {
          endElement = clubAreas[clubAreas.length - 1];
          clubbedAreas.push(` ${startElement} - ${endElement}`);
          clubAreas = clubAreas.slice(endElement + 1);
        } else if (clubAreas.length === 1) {
          clubbedAreas.push(` ${startElement}`);
          clubAreas = clubAreas.slice(startElement + 1);
        } else {
          break;
        }
      }
      displayAreasString = clubbedAreas.join(',');
      return displayAreasString;
    },
    allSelected() {
      return every(this.areas, { checked: true });
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.closest('.ant-drawer-content') || document.body;
    },
    initialise() {
      // Ignore the initialisation call if the form data hasn't been populated yet.
      if (this.areasConfig !== undefined) {
        // Populate the internal data structure used for the drop down display.
        this.areas = this.areasConfig.Areas.map((areaConfig, i) => ({
          index: i,
          identifier: this.areasInfo.Areas[i].Identifier,
          name: areaConfig.Name,
          checked: (this.value != null) && (this.value[i] === true),
        }));

        // Find the first area (if any) that's checked.
        let singleCheckedArea = this.areas.findIndex(x => x.checked === true);

        // If an area was found to be checked, see whether there's a second area that's also checked.
        if ((singleCheckedArea !== -1) && (this.areas.findLastIndex(x => x.checked === true) !== singleCheckedArea)) {
          singleCheckedArea = -1;
        }

        // If just a single area is checked, note its index. Otherwise (if no areas, or more than one area, are
        // checked) the index is -1.
        if (singleCheckedArea !== this.singleCheckedArea) {
          this.singleCheckedArea = singleCheckedArea;
          this.$emit('singleCheckedArea', this.singleCheckedArea);
        }
      }
    },
    visibleChanged(e) {
      this.dropdownVisible = e;
    },
    onCheckboxChange(area, e) {
      if (this.singleCheck) {
        // Exactly one area must be checked: the check boxes act like radio buttons. Check if an area that is NOT
        // currently checked has been selected.
        if (this.singleCheckedArea !== area.index) {
          // Mark the old area as unchecked.
          if (this.singleCheckedArea >= 0) {
            this.areas[this.singleCheckedArea].checked = false;
          }

          // Mark the new area as checked.
          area.checked = true;

          // Update the data array recording the areas that are checked.
          this.emitValues();
        }
      } else {
        // Multiple areas can be checked: zero, one, or multiple areas. Anything goes.
        area.checked = e.target.checked;
        this.emitValues();
      }
    },
    toggleSelectAll(e) {
      this.areas.forEach((a) => { a.checked = e.target.checked; });
      this.emitValues();
    },
    emitValues() {
      this.$emit('input', this.areas.map(x => x.checked));
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.initialise();
      }
    },
    areasConfig(newValue, oldValue) {
      if (newValue !== undefined && newValue !== oldValue) {
        this.initialise();
      }
    },
  },
  created() {
    this.initialise();
  },
};
</script>

<style lang="scss">
/* Specify the style for the area selector drop down box. It's quite long (lots of areas) so limit
   its height, and add a vertical scroll bar. */
.area-selector-dropdown {
  border-style: solid;
  border-width: 1px;
  max-height: 60ex;
  overflow-y: scroll;
}
/* Specify the style for each area selection check box within the area selector drop down box.
   Reduce the vertical spacing, to squash everything up as much as possible. */
.area-selector-dropdown .ant-menu-item {
  line-height: 1.1;
  height: 1.2em;
  margin: 2px;
}
form .ant-dropdown-trigger{
    width: 100%;
    color: #484848;

    span {
      width:100%;
    }
    i {
      color: #484848 !important;
      margin-left: -8px !important;
    }
}
</style>
