import { API } from '@/app/shared/services/api';
import { Poller } from '@/app/shared/services/config-write-lock';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { panelPageConsts } from '@/app/shared/constants';
import { isLockWithAnotherClient } from '../services/config-write-lock-helper';

function GetPollerConfigWriteLockData() {
  const { configWriteLock } = Poller.getInstance();
  return {
    lockOwner: configWriteLock.lockOwner,
    lostLock: configWriteLock.lostLock,
    isActive: configWriteLock.isActive,
    isMine: configWriteLock.isMine,
    canWriteConfig: configWriteLock.canWriteConfig,
    isLockReleasedByBKeyPress: configWriteLock.isLockReleasedByBKeyPress,
  };
}

// Maximum attempts to poll before acquiring the lock for the LAN.
const noLockOwnerPollLimit = 3;
let noLockOwnerPollCount = 0;
// Maximum attempts to poll before clearing the LAN mode setting flag when the keypad owns the lock. Required mainly to address
// the side-effect of CASE 3 - when we want to acquire the lock using the 'Take Control' button, the LAN mode setting should be
// cleared only after LAN has acquired the lock. Backing-off by a few polls will ensure the flag is cleared only after LAN acquires
// the lock, and not by the logic of CASE 3 where the lock is still held by the keypad.
const kpdLockOwnerClearLanFlagPollLimit = 2;
let kpdLockOwnerClearLanFlagCount = 0;

const ConfigWriteLockMixin = {
  data() {
    return {
      configWriteLock: GetPollerConfigWriteLockData(),
      anyAreaSet: false,
    };
  },
  mounted() {
    setTimeout(() => {
      const poller = Poller.getInstance();
      poller.addCallback(async (configWriteLock, anyAreaSet, lanHasLock, index) => {
        // check if the lock has been taken at all so far in this session
        const refreshEndpointsRequired = JSON.parse(window.localStorage.getItem('refreshEndpointsRequired'));
        if (this.isLanModeSettingFlagSet()) {
          if (lanHasLock) {
            this.clearLanModeSettingFlag();
            this.clearAllCounters();
            // Just obtained config write lock. If the lock was lost previously, config store may have been modifed,
            // so reload any endpoints that have 'updateOnConfigWrite: true'.
            if (refreshEndpointsRequired) {
              window.localStorage.setItem('refreshEndpointsRequired', 'false');
              eventHub.$emit(EVENTS.CONFIG_WRITE);
            }
          }
        }
        // Handle LAN navigation when panel is armed - required to handle page navigation if LAN page is reloaded/refreshed
        if (anyAreaSet) {
          this.setPanelPageModeStatus(panelPageConsts.panelArmed);
        } else {
          // Complete setting PanelPageModeStatus = ENGINEER when exiting/loading the panel page, if either:
          // a) LAN had requested the lock and now has the lock.
          // b) LAN had requested the lock but the lock has lost its lock to another client.
          // This prevents overwriting/setting the mode endpoint again, and also cancels the navigation banner.
          if ((this.isPanelPageEngrModeRequested() && lanHasLock) || (this.isPanelPageEngrModeRequested() && configWriteLock.lostLock)) {
            this.setPanelPageModeStatus(panelPageConsts.engineerModeSet);
            this.clearPanelPageNavigationBannerFlag();
          }
          // Complete setting PanelPageModeStatus = USER if the LAN had requested USER mode when:
          // a) entering/loading the panel page,  and there is no lock owner.
          // b) entering/loading the panel page when keypad/WAN/App has the lock.
          //    BUG-2059 - assume USER mode has been set when the lock is with any client other than the LAN.
          //    This is required to a) prevent setting the mode endpoint on the panel, b) finish loading the panel page by clearing all flags -
          //    clearing the flags sooner prevents the panel from acquring the lock from the remote client if the user returns to an engineering
          //    before the user mode is fully set.
          // c) entering/loading the panel page, when the LAN is in engineers (LAN owns the config edit lock and is navigating back to
          //    the dashboard page).
          const lanClientID = JSON.parse(window.localStorage.getItem('locallyStoreAuthStateSessionId'));
          if (this.isPanelPageUserModeRequested()) {
            if ((configWriteLock.lockOwner === null) || isLockWithAnotherClient(configWriteLock) || anyAreaSet) {
              this.setPanelPageModeStatus(panelPageConsts.userModeSet);
              this.clearPanelPageNavigationBannerFlag();
            } else if (configWriteLock.lockOwner === lanClientID) {
              const api = new API();
              await api.put('/Live/ClientStatus/Mode', panelPageConsts.userMode);
              this.setPanelPageModeStatus(panelPageConsts.userModeSet);
              this.clearPanelPageNavigationBannerFlag();
            }
          }
          if (this.anyAreaSet && !anyAreaSet) {
            this.setPanelPageModeStatus(panelPageConsts.userModeSet);
            this.setCustomLanModeSettingFlag(panelPageConsts.panelPage); // Set LAN page to prevent engineer mode on panel page.
          }
        }
        // CASE 1 - In the previous poll lock was taken but was not mine (some other client was lock owner), next poll lock is not taken and still lock in not mine (client has just released the lock))
        if (this.isLockFullyReleasedByClient(configWriteLock, anyAreaSet)) {
          // Write lock has been released from a keypad by an A key press - request the appropriate client status mode if the user is not the panel page.
          if (index === 0) {
            this.setLanModeSettingAndIsMineFlags();
            this.clearAllCounters();
            const api = new API();
            await api.put('/Live/ClientStatus/Mode', panelPageConsts.engineerMode);
            this.setPanelPageModeStatus(panelPageConsts.engineerModeRequested);
            // Now we have got the Write lock, we must refresh the config data as it might have changed.
            // Because we lost the lock previously, another client might've modified the config so refresh endpoints only here
            // If the lock has been continuously owned through the session, no need to reload endpoints, they have already been populated
            if (refreshEndpointsRequired) {
              window.localStorage.setItem('refreshEndpointsRequired', 'false');
              eventHub.$emit(EVENTS.CONFIG_WRITE);
            }
          }
          // Check other clients (WAN?) here.
        } else if (this.isNoLockOwnerDetectedForFewPolls(configWriteLock, anyAreaSet)) {
          // CASE 2 - Handle three potential issues -
          // 1) Keypad quickly exiting engineers mode on the keypad (entering engineers and quickly pressing the A key to exit),
          // 2) BUG-1483 - LAN sets engineer mode, panel acknowledges the request but doesn't set the mode and keypad remains in day mode, while LAN keeps waiting
          //    to acquire the lock.
          // 3) User enters incorrect engineer code on the keypad and doesn't press No to cancel, or user brings up the engineer code menu but doesn't enter the code.
          //    Note: panel has its own timeout logic but it doesn't always seem to kick in. This condition ensures that we don't end up in a lock owner situation
          //    when the LAN is on an engineering page.
          // In all of the above scenarios, we end up with no client owning the lock. If for two polls, there is no lock owner, let LAN acquire the lock as the current page
          // is an engineering page.
          const api = new API();
          noLockOwnerPollCount += 1;
          this.setLanModeSettingFlag();

          if (noLockOwnerPollCount === noLockOwnerPollLimit) {
            // Assume lock is acquired successfully before poller runs and reads the client status endpoint.
            this.setMine(true);
            await api.put('/Live/ClientStatus/Mode', panelPageConsts.engineerMode);
            this.setPanelPageModeStatus(panelPageConsts.engineerModeRequested);
            // VD - Quick release/exit from engineers on the keypad couldn't have resulted in modified config via the keypad, therefore don't reload config??
            // What about engineer restore? Revisit this while optimising LAN speed.
            // Now we have got the Write lock, we must refresh the config data as it might have changed.
            if (refreshEndpointsRequired) {
              window.localStorage.setItem('refreshEndpointsRequired', 'false');
              eventHub.$emit(EVENTS.CONFIG_WRITE);
            }
            this.clearAllCounters();
          }
        } else if (this.stopLanModeSettingIfPanelTakesLock(configWriteLock, anyAreaSet)) {
          // CASE 3 - Handle yet another corner case - User enters engineers via keypad, LAN detects keypad is in control, user presses the A key on the keypad, LAN
          // detects lock has been released by keypad (first if condition 'controlled release of lock from keypad' condition is met) and LAN sets the mode
          // setting flag and attempts to acquire the lock. In the meantime, the user enters engineers on the keypad again by entering the code - the 'Take Control'
          // button is lost on the LAN because the lock owner has changed but LAN doesn't get to clear the mode setting flag. The user could exit engineers mode
          // on the keypad to relinquish control to the LAN but it is better to fix this anomaly and promptly show the 'Take Control' button on the LAN, instead
          // of leaving the user guessing.
          // Poll two counts to ensure this isn't a valid mode setting attempt by the LAN (Take Control).
          kpdLockOwnerClearLanFlagCount += 1;
          if (kpdLockOwnerClearLanFlagCount === kpdLockOwnerClearLanFlagPollLimit) {
            this.clearLanModeSettingFlag();
            this.clearAllCounters();
          }
        }
        this.configWriteLock = {
          lockOwner: configWriteLock.lockOwner,
          lostLock: configWriteLock.lostLock,
          isActive: configWriteLock.isActive,
          isMine: configWriteLock.isMine,
          canWriteConfig: configWriteLock.canWriteConfig,
          isLockReleasedByBKeyPress: configWriteLock.isLockReleasedByBKeyPress,
        };
        this.anyAreaSet = anyAreaSet;
      });
      poller.start();
    }, 1); // TODO May need to increase this timeout value if BUG-1332 race condition seen again.
  },
  beforeDestroy() {
    const poller = Poller.getInstance();
    poller.reset();
  },
  methods: {
    setLanModeSettingFlag() {
      this.$gLanModeSetting.lanModeSettingInProgress = true;
    },
    clearLanModeSettingFlag() {
      this.$gLanModeSetting.lanModeSettingInProgress = false;
    },
    setCustomLanModeSettingFlag(value) {
      this.$gLanModeSetting.lanModeSettingInProgress = value;
    },
    setLanModeSettingAndIsMineFlags() {
      // Note: !!!! This function should only be called where it is okay to set isMine and Mode setting flag sequentially
      // Not all uses cases qualify for this.
      // Assume engineer mode is set correctly to prevent the 'Take Control' banner from popping up,
      // until the poller gets to read the client status endpoint
      this.setMine(true);
      // Cater for any delay in reading the correct client status by displaying
      // a banner if it takes a little longer for the poller to read the correct status.
      this.setLanModeSettingFlag();
    },
    getLanModeSettingFlag() {
      return this.$gLanModeSetting.lanModeSettingInProgress;
    },
    isLanInPanelPage() {
      return this.$gLanModeSetting.lanModeSettingInProgress === panelPageConsts.panelPage;
    },
    isLanModeSettingFlagSet() {
      return this.$gLanModeSetting.lanModeSettingInProgress === true;
    },
    isLanModeSettingFlagCleared() {
      return this.$gLanModeSetting.lanModeSettingInProgress === false;
    },
    setPanelPageModeStatus(status) {
      this.$gLanModeSetting.panelPageModeForNavigation = status;
    },
    setPageCreated(status) {
      this.$gLanModeSetting.pageCreated = status;
    },
    setPageMounted(status) {
      this.$gLanModeSetting.pageMounted = status;
    },
    isPanelPageFullyProcessed() {
      return (this.$gLanModeSetting.panelPageModeForNavigation === panelPageConsts.userModeSet || this.$gLanModeSetting.panelPageModeForNavigation === panelPageConsts.engineerModeSet);
    },
    isPanelPageEngrModeRequested() {
      return (this.$gLanModeSetting.panelPageModeForNavigation === panelPageConsts.engineerModeRequested);
    },
    isPanelPageUserModeRequested() {
      return this.$gLanModeSetting.panelPageModeForNavigation === panelPageConsts.userModeRequested;
    },
    clearPanelPageNavigationBannerFlag() {
      this.$gLanModeSetting.panelPageStopNavigationBanner = false;
    },
    setMine(value) {
      this.configWriteLock.isMine = value;
    },
    clearAllCounters() {
      kpdLockOwnerClearLanFlagCount = 0;
      noLockOwnerPollCount = 0;
    },
    isLockFullyReleasedByClient(configWriteLock, anyAreaSet) {
      let result = false;
      const wasLockTakenByAnotherClient = (this.configWriteLock.isActive === true) && (this.configWriteLock.isMine === false);
      const isLockNowReleasedByOtherClient = (configWriteLock.isActive === false) && (configWriteLock.isLockReleasedByBKeyPress === false);
      if ((wasLockTakenByAnotherClient === true) && (isLockNowReleasedByOtherClient === true) && !this.isLanInPanelPage() && !anyAreaSet) {
        result = true;
      }
      return result;
    },
    isNoLockOwnerDetectedForFewPolls(configWriteLock, anyAreaSet) {
      let result = false;
      const isNoLockOwnerPreviouslyAndNow = (this.configWriteLock.isActive === false) && (configWriteLock.isActive === false) && (configWriteLock.lockOwner === null);
      if (isNoLockOwnerPreviouslyAndNow && (configWriteLock.isLockReleasedByBKeyPress === false) && !this.isLanInPanelPage() && !anyAreaSet) {
        result = true;
      }
      return result;
    },
    stopLanModeSettingIfPanelTakesLock(configWriteLock, anyAreaSet) {
      let result = false;
      // Note the function can be made generic if we consider other remote clients as well. Lock owner condition can change to not null and not LAN client ID.
      const isLockWithPanel = (this.configWriteLock.isActive === true) && (configWriteLock.isActive === true) && (configWriteLock.lockOwner === panelPageConsts.keypadLockOwner);
      const isLanModeSettingAlsoInProgress = this.isLanModeSettingFlagSet();
      if (isLockWithPanel && isLanModeSettingAlsoInProgress && !anyAreaSet) {
        result = true;
      }
      return result;
    },
  },
};

export default ConfigWriteLockMixin;
