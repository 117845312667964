import i18n from '@/app/shared/services/i18n';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { translateOutputType, isSirenOutputType } from '@/app/outputs/shared/services/helpers';
import { wrlsDevicesDiagnosticsConsts } from '@/app/shared/constants';

const getDiagnostic = wrlsDevicesDiagnosticsConsts.gettingDiagnostics; // while loading the page the first time
const validState = 'valid';
const errorState = wrlsDevicesDiagnosticsConsts.retryingDiagnostics; // error condition
const wrlsBellMaxDiagnosticRetryLimit = 6; // based on 5 seconds poll duration, mask null value retry for 50 seconds before reporting error
const wrlsBellMaxDiagnosticUpdateLimit = 3; // max number to attempts to load diagnostics the first time, before reporting an error

let wrlsBellDiagnosticState = getDiagnostic;
let wrlsBellDiagnosticRetryCount = 0;
let wrlsBellPreviousBatteryStatus = null;
let wrlsBellPreviousSignalStrength = null;


export function generateSirenName(type, number) {
  return i18n.t(`sirens.names.${type}`, { number });
}

function initialState() {
  return {
    name: undefined,
    number: undefined,

    state: undefined,
    globalState: undefined,

    _outputTypes: [],

    form: {
      bellOutputType: undefined,
      strobeOutputType: undefined,
    },

    diagnostics: [
      { key: 'sirens.Diagnostics.Tamper', isLoading: true, value: undefined },
      { key: 'sirens.Diagnostics.Supervision', isLoading: true, value: undefined },
      { key: 'sirens.Diagnostics.Battery', isLoading: true, value: undefined },
      {
        key: 'sirens.Diagnostics.SignalStrength', isLoading: true, value: undefined, format: undefined,
      },
    ],
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {
    outputTypes: state => state._outputTypes.filter(o => o != null),
  },
  actions: {
    requiredEndpoints() {
      // TODO Investigate replacing liveActiveFaults with a new endpoint liveActiveFaultsWirelessOutputs
      return ['infoOutputInfo', 'configBusDevices', 'liveActiveFaults', 'liveWirelessSiren', 'livePanelStatus'];
    },
    async populate(context, { endpoints, payload }) {
      context.commit('setForm', {
        bellOutputType: endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[0].OutputType[0],
        strobeOutputType: endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[0].OutputType[1],
      });

      const outputTypes = [];
      for (let i = 0; i < endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes.length; i += 1) {
        const outputType = endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes[i].Name;
        if (outputType != null) {
          outputTypes[i] = {
            value: i, key: outputType, name: translateOutputType(outputType), isSirenOutputType: isSirenOutputType(i),
          };
        }
      }

      context.commit('set', {
        index: payload.index,
        number: payload.index + 1,
        name: generateSirenName('WIRELESS', payload.index + 1),
        _outputTypes: outputTypes,
      });

      // Reset global variables
      wrlsBellDiagnosticState = getDiagnostic;
      wrlsBellDiagnosticRetryCount = 0;
      wrlsBellPreviousBatteryStatus = null;
      wrlsBellPreviousSignalStrength = null;

      await context.dispatch('onPoll', { key: 'livePanelStatus', endpoint: endpoints.livePanelStatus, dataStatus: endpoints.livePanelStatus.dataStatus });
    },
    async onPoll(context, {
      dataStatus, key, endpoint, payload,
    }) {
      if (key === 'liveActiveFaults') {
        const {
          CaseTamper, Supervision, Battery, Polling,
        } = endpoint.data.Live.ActiveFaults.WirelessOutputs[payload.index];
        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'sirens.Diagnostics.Tamper', collection: 'diagnostics', item: { value: !CaseTamper },
        });
        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'sirens.Diagnostics.Supervision', collection: 'diagnostics', item: { value: !Supervision },
        });

        if (CaseTamper === true || Supervision === true || Battery === true || Polling === true) {
          context.commit('set', { globalState: 'FAULT' });
        } else if (CaseTamper === false && Supervision === false && Battery === false && Polling === false) {
          context.commit('set', { globalState: 'OK' });
        }
      }
      if (key === 'liveWirelessSiren') {
        let batteryStatusValue = endpoint.data.Live.Devices.wirelessSiren[payload.index].BatteryStatus;
        let signalStrengthValue = endpoint.data.Live.Devices.wirelessSiren[payload.index].SignalLevel;
        const gotNullValue = batteryStatusValue === signalStrengthValue;
        let formatStr = '{0}';

        // Initial state Updating - Fetching for the first time
        if (gotNullValue && wrlsBellDiagnosticState === getDiagnostic) {
          // Got null value in updating state
          batteryStatusValue = wrlsBellDiagnosticState;
          signalStrengthValue = wrlsBellDiagnosticState;
          wrlsBellDiagnosticRetryCount += 1;
          if (wrlsBellDiagnosticRetryCount === wrlsBellMaxDiagnosticUpdateLimit) {
            wrlsBellDiagnosticRetryCount = wrlsBellMaxDiagnosticRetryLimit; // force error state
            wrlsBellDiagnosticState = errorState;
          }
        } else if (!gotNullValue) {
          // Got a valid value - transition from 'updating' or 'retrying' to 'valid' or remain in 'valid' state
          wrlsBellDiagnosticState = validState;
          wrlsBellPreviousBatteryStatus = batteryStatusValue; // backup previous good state
          wrlsBellPreviousSignalStrength = signalStrengthValue; // backup previous good state
          wrlsBellDiagnosticRetryCount = 0;
          formatStr = '{0}%';
        } else if (gotNullValue && wrlsBellDiagnosticState === validState && wrlsBellDiagnosticRetryCount < wrlsBellMaxDiagnosticRetryLimit) {
          // Got a null value in a valid state - silently retry for retry counts by masking the null value
          wrlsBellDiagnosticRetryCount += 1;
          batteryStatusValue = wrlsBellPreviousBatteryStatus;
          signalStrengthValue = wrlsBellPreviousSignalStrength;
          formatStr = '{0}%';
        } else if (wrlsBellDiagnosticRetryCount >= wrlsBellMaxDiagnosticRetryLimit) {
          // Exceeded retry attempts - don't mask null value
          wrlsBellDiagnosticState = errorState;
          batteryStatusValue = wrlsBellDiagnosticState;
          wrlsBellPreviousBatteryStatus = wrlsBellDiagnosticState;
          signalStrengthValue = wrlsBellDiagnosticState;
          wrlsBellPreviousSignalStrength = wrlsBellDiagnosticState;
          wrlsBellDiagnosticRetryCount += 1;
          formatStr = '{0}';
        }

        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'sirens.Diagnostics.Battery', collection: 'diagnostics', item: { value: batteryStatusValue },
        });

        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'sirens.Diagnostics.SignalStrength', collection: 'diagnostics', item: { value: signalStrengthValue, format: formatStr },
        });
      }
      if (key === 'livePanelStatus' && context.state.form.bellOutputType != null && context.state.form.strobeOutputType != null) {
        const bellOutputTypeState = endpoint.data.Live.PanelStatus.OutputTypes[context.state.form.bellOutputType].state;
        const strobeOutputTypeState = endpoint.data.Live.PanelStatus.OutputTypes[context.state.form.strobeOutputType].state;
        context.commit('trySet', { dataStatus, data: { state: bellOutputTypeState === true || strobeOutputTypeState === true } });
      }
    },
    async save(context) {
      await api.put('/Config/BusDevices/wirelessHubs/0/OutputType/0', context.state.form.bellOutputType);
      await api.put('/Config/BusDevices/wirelessHubs/0/OutputType/1', context.state.form.strobeOutputType);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('sirensState/wirelessSiren/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['sirensState/wirelessSiren'] });
    },
    async delete(context) {
      await api.put(`/Live/Devices/WirelessSirens/${context.state.index}/DeleteLearnedDevice`, true);

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('sirensState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('sirensState/addSiren/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['sirensState/list'] });
    },
  },
});

export default store;
