<template>
    <two-col-layout>
        <template v-slot:title>
          <h1>{{ $t('pageNotFound.navigationTitle') }}</h1>
        </template>


    </two-col-layout>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">

</style>
