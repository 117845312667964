const NoLeaveWhileSavingGuard = {
  // properties available from outside the mixin
  props: {
    isSavingGuard: { default: false },
  },
  // function called before you navigate away from the page
  beforeRouteLeave(to, from, next) {
    if (this.isSavingGuard) {
      next(false); // cancel the change
    } else {
      next(); // allow the change
    }
  },
};

export default NoLeaveWhileSavingGuard;
