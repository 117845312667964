import KeypadsAndReaders from './keypads-and-readers.vue';
import list from './pages/keypads-and-readers-list/keypads-and-readers-list.vue';
import wirelessKeypad from './pages/wireless-keypad/wireless-keypad.vue';
import wiredKeypad from './pages/wired-keypad/wired-keypad.vue';
import wiredReader from './pages/wired-reader/wired-reader.vue';

const routes = [
  {
    path: '/keypads-and-readers',
    component: KeypadsAndReaders,
    children: [
      {
        path: '',
        name: 'keypads-and-readers-list',
        component: list,
        meta: { breadcrumb: [{ nameRes: 'keypadsAndReaders.navigationTitle' }] },
      },
      {
        path: 'wireless-keypad/:index',
        name: 'wireless-keypad',
        component: wirelessKeypad,
        meta: { breadcrumb: [{ nameRes: 'keypadsAndReaders.navigationTitle', link: 'keypads-and-readers-list' }, { nameRes: 'wirelessKeypad.navigationTitle' }] },
      },
      {
        path: 'wired-keypad/:index',
        name: 'wired-keypad',
        component: wiredKeypad,
        meta: { breadcrumb: [{ nameRes: 'keypadsAndReaders.navigationTitle', link: 'keypads-and-readers-list' }, { nameRes: 'wiredKeypad.navigationTitle' }] },
      },
      {
        path: 'wired-reader/:index',
        name: 'wired-reader',
        component: wiredReader,
        meta: { breadcrumb: [{ nameRes: 'keypadsAndReaders.navigationTitle', link: 'keypads-and-readers-list' }, { nameRes: 'wiredReader.navigationTitle' }] },
      },
    ],
  },
];

export default routes;
