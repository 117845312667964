/* eslint-disable class-methods-use-this */
import { API } from '@/app/shared/services/api';
import { livePanelStatusOutputsConsts } from '@/app/shared/constants';

const api = new API();

// A count of the pages using modified polling.
let customPollPageCount = 0;

class OutputsPollHelper {
  // Private incrementor
  _incCustomPollPageCount() {
    customPollPageCount += 1;
  }

  // Private decrementor
  _decCustomPollPageCount() {
    // After the count has been reset, it is valid to attempt to decrement the count
    // (but the value should never go negative)
    if (customPollPageCount > 0) {
      customPollPageCount -= 1;
    }
    return customPollPageCount;
  }

  cancelPolling() {
    api.cancelPolling();
  }

  // Change the existing polling of this endpoint key to use a new URL and polling interval.
  customisePollingWithIntervalAndUrl(loader, newInterval, newUrl) {
    loader.setEndpointIntervalAndUrl(livePanelStatusOutputsConsts.key, newInterval, newUrl);
    this._incCustomPollPageCount();
  }

  // Revert to default polling of live endpoint, if there are no other pages using custom polling.
  revertCustomPollingIfPossible() {
    const pageCount = this._decCustomPollPageCount();
    if (pageCount === 0) {
      // No other pages using customised polling, so cancel polling.
      this.cancelPolling();
    }
  }
}

const helper = new OutputsPollHelper();

export default helper;
export { api };
