import Sirens from './sirens.vue';
import SirensList from './pages/sirens-list/sirens-list.vue';
import WirelessSiren from './pages/wireless-siren/wireless-siren.vue';
import WiredSiren from './pages/wired-siren/wired-siren.vue';

const routes = [
  {
    path: '/sirens',
    component: Sirens,
    children: [
      { path: '', redirect: { name: 'sirens-list' } },
      {
        path: 'sirens',
        name: 'sirens-list',
        component: SirensList,
        meta: { breadcrumb: [{ nameRes: 'sirens.navigationTitle' }] },
      },
      {
        path: 'sirens/wireless-siren/:index',
        name: 'wireless-siren',
        component: WirelessSiren,
        meta: { breadcrumb: [{ nameRes: 'sirens.navigationTitle', link: 'sirens-list' }, { nameRes: 'sirens.wirelessSirens.navigationTitle' }] },
      },
      {
        path: 'sirens/wired-siren/:index',
        name: 'wired-siren',
        component: WiredSiren,
        meta: { breadcrumb: [{ nameRes: 'sirens.navigationTitle', link: 'sirens-list' }, { nameRes: 'sirens.wiredSirens.navigationTitle' }] },
      },
    ],
  },
];

export default routes;
