import i18n from '@/app/shared/services/i18n';
import { storeFactory } from '@/app/shared/services/store-helper';

function initialState() {
  return {
    arcConfig: [],
  };
}

function toPathName(index) {
  return i18n.t(`communications.arcOptions.Path-${index + 1}`);
}

function toCommsName(commsModuleType, commsSlot) {
  let type;
  switch (commsModuleType) {
    case 'MODULE_NONE':
      type = 'NONE';
      break;
    case 'MODULE_RS232':
      type = 'RS232';
      break;
    case 'MODULE_CELLULAR':
      type = 'CELLULAR';
      break;
    case 'MODULE_ETHERNET':
      type = 'ETHERNET';
      break;
    case 'MODULE_WIFI':
      type = 'WIFI';
      break;
    default:
      type = 'NONE';
      break;
  }

  let slot;
  switch (commsSlot) {
    case 'BUILTIN_SLOT':
      slot = '';
      break;
    case 'COMMS_SLOT_1':
      if ((type === 'CELLULAR') || (type === 'RS232')) {
        slot = '_1';
      } else {
        slot = '';
      }
      break;
    case 'COMMS_SLOT_2':
      if ((type === 'CELLULAR') || (type === 'RS232')) {
        slot = '_2';
      } else {
        slot = '';
      }
      break;
    case 'SLOT_NONE':
      slot = '';
      break;
    default:
      slot = '';
      break;
  }

  return i18n.t(`enums.COMMS_TYPE.${type}${slot}`);
}

const { store } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configCommsInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('set', {
        // arcConfig is an array, one element per ARC.
        arcConfig: endpoints.configCommsInfo.data.Config.commsInfo.ARCDetails.map(d => ({
          // arcConfig[n].paths is an array, one element per communications path.
          paths: d.Paths.map((p, pathIndex) => ({
            // True if this path is configured.
            active: p.CommsModuleType !== 'MODULE_NONE',
            // Display text describing this path's configuration; for example:
            // Path 1:<EN QUAD>Ethernet
            name: `${toPathName(pathIndex)}:\u2000${toCommsName(p.CommsModuleType, p.CommsSlot)}`,
            // Initial test state for this path.
            testState: 'testPending',
          })),
        })),
      });
    },
  },
});

export default store;
