export const CHIMES = {
  CHIME_NO: 0,
  CHIME_SINGLE: 1,
  CHIME_FOLLOW: 2,
};

export const CONFIRMED_WHEN_OPTIONS = {
  FINAL: 0,
  START: 1,
};

export const SPECIAL_LOGGED = {
  SPECIAL_LOG_NO: 0,
  SPECIAL_LOG_SET: 1,
  SPECIAL_LOG_UNSET: 2,
  SPECIAL_LOG_ALWAYS: 3,
};

export const ZONE_TYPE = {
  ISOLATED: 'ISOLATED',
  FIRE: 'FIRE',
  GAS: 'GAS',
  PA: 'PA',
  SILENT_PA: 'SILENT_PA',
  TAMPER: 'TAMPER',
  INTRUDER: 'INTRUDER',
  FINAL_EXIT: 'FINAL_EXIT',
  ENTRY_ROUTE: 'ENTRY_ROUTE',
  GLOBAL_ER: 'GLOBAL_ER',
  GLOBAL_FX: 'GLOBAL_FX',
  PTS: 'PTS',
  SWITCHER: 'SWITCHER',
  DAY_ALARM: 'DAY_ALARM',
  // WARD_CONTROL: 'WARD_CONTROL', // UK Version only
  TROUBLE: 'TROUBLE',
  CLOSURE_SUPERVISION: 'CLOSURE_SUPERVISION',
  UNSET: 'UNSET',
  KEYSWITCH: 'KEYSWITCH',
  ENTRY_SHOCK: 'ENTRY_SHOCK',
  LINE_FAIL: 'LINE_FAIL',
  KEYSWITCH_PULSED: 'KEYSWITCH_PULSED',
  FLOOD: 'FLOOD',
  // CCTV: 'CCTV',
  // KEYBOX: 'KEYBOX',
  MEDICAL: 'MEDICAL',
  FINAL_EXIT_2: 'FINAL_EXIT_2',
  SILENT_MEDICAL: 'SILENT_MEDICAL',
  SILENT_INTRUDER: 'SILENT_INTRUDER',
};

export const ZONE_RESISTOR_MODE = {
  SINGLE: 'SINGLE',
  DOUBLE: 'DOUBLE',
  TRIPLE: 'TRIPLE',
  NORMALLY_CLOSED: 'NORMALLY_CLOSED',
};

export const ZONE_RESISTOR_RANGE = {
  ZONE_EOL_1K_1K: 'ZONE_EOL_1K_1K',
  ZONE_EOL_1K1_2K2: 'ZONE_EOL_1K1_2K2',
  ZONE_EOL_2K2_2K2: 'ZONE_EOL_2K2_2K2',
  ZONE_EOL_2K7_2K7: 'ZONE_EOL_2K7_2K7',
  ZONE_EOL_4K7_2K2: 'ZONE_EOL_4K7_2K2',
  ZONE_EOL_3K3_3K3: 'ZONE_EOL_3K3_3K3',
  ZONE_EOL_4K7_3K3: 'ZONE_EOL_4K7_3K3',
  ZONE_EOL_4K7_4K7: 'ZONE_EOL_4K7_4K7',
  ZONE_EOL_6K8_4K7: 'ZONE_EOL_6K8_4K7',
  ZONE_EOL_5K6_5K6: 'ZONE_EOL_5K6_5K6',
  ZONE_EOL_2K2_6K8: 'ZONE_EOL_2K2_6K8',
  ZONE_EOL_10K_10K: 'ZONE_EOL_10K_10K',
  ZONE_EOL_0K_0K: 'ZONE_EOL_0K_0K',
};

export const SUPERVISION = {
  NEVER: 'NEVER',
  ARMED: 'ARMED',
  DISARMED: 'DISARMED',
  ALWAYS: 'ALWAYS',
};
