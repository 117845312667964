import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { createArray } from '@/app/shared/services/api';
import i18n from '@/app/shared/services/i18n';
import { createRawOutputsListWithValue } from '@/app/outputs/shared/services/helpers';
import { LOGIC_GATE_TYPE } from '@/app/outputs/shared/enums';

function initialState() {
  return {
    name: undefined,

    form: {
      gateType: undefined,
      number: undefined,
      index: undefined,
      inputTriggers: undefined,
    },
    _outputTypes: [],
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {
    outputTypes: state => state._outputTypes,
  },
  actions: {
    requiredEndpoints() {
      return ['infoOutputInfo', 'configModularOutput', 'configOutputInfo'];
    },
    async populate(context, { endpoints, payload }) {
      // rawOutputTypes used by logic-gate.vue to handle p-select when editing the input triggers
      const udOutputs = endpoints.configOutputInfo.data.Config.outputInfo.UserDefinedOutputs;
      const rawOutputTypes = createRawOutputsListWithValue(endpoints.infoOutputInfo.data.Info.outputInfo, udOutputs);

      // e.g. 'enums.OUTPUT_TYPE.LOGIC_GATE_04' translates to 'Logic gate 5'
      const logicGateName = i18n.t(`enums.OUTPUT_TYPE.LOGIC_GATE_${payload.index.toString().padStart(2, '0')}`);

      context.commit('set', {
        name: logicGateName,
        _outputTypes: rawOutputTypes,
      });

      // Array of the four input trigger types
      const inputs = endpoints.configModularOutput.data.Config.modularOutput.OutputLogicGate[payload.index].Input;

      // Create the required trigger array
      const triggers = createArray(inputs.length, () => ({}));

      for (let i = 0; i < inputs.length; i += 1) {
        triggers[i].triggerType = inputs[i];
        triggers[i].triggerNumber = i + 1; // Number shown in UI is one more than index
      }

      context.commit('setForm', {
        gateType: LOGIC_GATE_TYPE.fromKey(endpoints.configModularOutput.data.Config.modularOutput.OutputLogicGate[payload.index].GateType).value,
        number: payload.index + 1,
        index: payload.index,
        inputTriggers: triggers,
      });
    },

    async save(context) {
      const gTypeObj = LOGIC_GATE_TYPE.fromInt(context.state.form.gateType);
      const { index } = context.state.form;
      const triggers = [];
      for (let i = 0; i < context.state.form.inputTriggers.length; i += 1) {
        const trigger = context.state.form.inputTriggers[i].triggerType;
        triggers.push(trigger);
      }
      await api.put(`/Config/modularOutput/OutputLogicGate/${index}`, {
        GateType: gTypeObj.key,
        Input: triggers,
      });

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('outputsState/logicGate/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['outputsState/logicGate'] });
    },
  },
});

export default store;
