<template>
  <a-skeleton :loading="isLoading" :paragraph="{ rows:1, width: '100%'}">
    <div>
      <h1 id="title">{{ panelType }} <span>v{{ version }}</span></h1>
        <p class="subtitle">
          <a-tooltip>
            <template slot="title">
              {{ systemID }}
            </template>
            <span>{{ systemID }}</span>
          </a-tooltip>
        </p>
    </div>
  </a-skeleton>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('panelState/panelTitle');

export default {
  components: {},
  computed: {
    ...mapState(['isLoading', 'panelType', 'version', 'systemID']),
  },
};
</script>
