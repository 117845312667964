<template>
  <div>
    <wizard :config="wizard" @save="finish" @done="close" :initialStep="initialStep">
        <wizard-step name="start">
          <template slot-scope="{ wizard }">
            <a-button @click="type = 'keypadsAndReaders'; wizard.navigateTo('info')" type="link" block>
                <text-row :title="$t('addDevicesDrawer.Keypads-and-readers')" title-icon="scanner-keyboard" />
            </a-button>
            <a-button @click="type = 'zones'; wizard.navigateTo('info')" type="link" block>
                <text-row :title="$t('addDevicesDrawer.Zone')" :tooltip="$t('addDevicesDrawer.wireless.zones.menuItemTooltip')" title-icon="street-view" />
            </a-button>
            <a-button @click="type = 'sirens'; wizard.navigateTo('info')" type="link" block>
                <text-row :title="$t('addDevicesDrawer.Wireless-Siren')" :tooltip="$t('addDevicesDrawer.wireless.sirens.menuItemTooltip')" title-icon="bell" />
            </a-button>
            <a-button @click="type = 'keyfobs'; wizard.navigateTo('info')" type="link" block>
                <text-row :title="$t('addDevicesDrawer.Fobs')" title-icon="users" />
            </a-button>
          </template>
        </wizard-step>

        <wizard-step name="info">
          <step-siren v-if="type === 'sirens'" />
          <step-fob v-else-if="type === 'keyfobs'" />
          <step-zone v-else-if="type === 'zones'" />
          <step-keypads-and-readers v-else-if="type === 'keypadsAndReaders'" />

        </wizard-step>

        <wizard-step name="learn">
            <template slot="title" slot-scope="{ wizard }">
                {{ $t(`addDevicesDrawer.wireless.${type}.learnTitle`) }}
                <a class="subtitle" @click="startLearning(() => wizard.navigateTo('finish')); wizard.navigateTo('learn')" v-if="!isLoading">Learn again</a>
            </template>

            <template v-if="type === 'keypadsAndReaders'">
                <text-row :title="$tc('addDevicesDrawer.wireless.KeypadsFound', keypadsFound, { count: keypadsFound })" :type="isLoading ? 'testing' : 'testComplete'" />
            </template>
            <template v-else-if="type === 'zones'">
                <text-row :title="$tc('addDevicesDrawer.wireless.zonesFound', zonesFound, { count: zonesFound })" :type="isLoading ? 'testing' : 'testComplete'" />
                <ul class="zones-list" v-if="foundZoneIndexes.length > 0">
                  <li v-for="zoneIndex in foundZoneIndexes" v-bind:key="zoneIndex">{{ $t('common.Zone') + ' '+ (zoneIndex + 1) }}</li>
                </ul>
            </template>
            <template v-else-if="type === 'sirens'">
                <text-row :title="$tc('addDevicesDrawer.wireless.sirensFound', sirensFound, { count: sirensFound })" :type="isLoading ? 'testing' : 'testComplete'" />
            </template>
            <template v-else-if="type === 'keyfobs'">
                <text-row :title="$tc('addDevicesDrawer.wireless.keyfobsFound', keyfobsFound, { count: keyfobsFound })" :type="isLoading ? 'testing' : 'testComplete'" />
            </template>
            <a-button type="primary" :loading="isReconfiguring" block v-if="!isLoading" @click="type === 'keyfobs' && keyfobsFound > 0 ? openManualDrawer('add-user') : finish()">{{ type === 'keyfobs' && keyfobsFound > 0 ? $t('addDevicesDrawer.wireless.Add-user')  : $t('common.Finish') }}</a-button>

        </wizard-step>
    </wizard>

    <add-key-fob v-if="manualWizard === 'add-user'" :fob-index="fobIndex" :visible="true" @onClose="closeDrawer"  />
  </div>
</template>

<script>
import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';
import StepSiren from '@/app/shared/components/add-devices-drawer/step-siren.vue';
import StepFob from '@/app/shared/components/add-devices-drawer/step-fob.vue';
import StepZone from '@/app/shared/components/add-devices-drawer/step-zone.vue';
import StepKeypadsAndReaders from '@/app/shared/components/add-devices-drawer/step-keypads-and-readers.vue';
import AddKeyFob from '@/app/users/shared/components/add-key-fob/add-key-fob.vue';
import service from '@/app/shared/services/learn-wireless-devices';
import { limits } from '@/app/shared/constants';
import { AsyncErrorHandling } from '@/app/shared/mixins';
import { LearnWirelessFailed } from '@/app/shared/errors';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import zoneAreasHelper from '@/app/shared/services/zone-areas-helper';

export default {
  mixins: [AsyncErrorHandling],
  components: {
    StepKeypadsAndReaders,
    Wizard,
    WizardStep,
    StepSiren,
    StepFob,
    StepZone,
    AddKeyFob,
  },
  props: {
    startAt: { default: null },
    manuallyAssociatedZone: { default: null },
  },
  data() {
    return {
      manualWizard: undefined,
      fobIndex: null,
      isLoading: true,
      keypadsFound: 0,
      readersFound: 0,
      zonesFound: 0,
      foundZoneIndexes: [],
      sirensFound: 0,
      keyfobsFound: 0,
      initialStep: null,
      type: null,
      wizard: {
        start: {
          title: this.$t('addDevicesDrawer.wireless.WhichDevices'),
        },
        info: {
          title: () => this.$t(`addDevicesDrawer.wireless.${this.type}.learnTitle`),
          hideCancel: true,
          next: [
            {
              key: 'learn',
              buttonText: () => this.$t(`addDevicesDrawer.wireless.${this.type}.buttonText`),
              postNavigate: async () => {
                this.startLearning();
              },
            },
          ],
        },
        learn: {
          hideCancel: false,
          title: () => this.$t(`addDevicesDrawer.wireless.${this.type}.learnTitle`),
        },
        finish: {
          hideCancel: true,
        },
      },
      isReconfiguring: false,
    };
  },
  created() {
    if (this.startAt != null) {
      this.initialStep = this.startAt.stage;
      this.type = this.startAt.type;
    }
  },
  computed: {
  },
  methods: {
    openManualDrawer(component) {
      this.stage = 'Start';
      this.manualWizard = component;
    },
    async closeDrawer() {
      this.stage = 'Start';
      this.manualWizard = undefined;
      this.$emit('done');
    },
    reset() {
      this.isLoading = true;
      this.keypadsFound = 0;
      this.foundZoneIndexes = [];
      this.zonesFound = 0;
      this.sirensFound = 0;
      this.keyfobsFound = 0;
    },
    async startLearning() {
      if (this.type === 'keypadsAndReaders') {
        this.reset();
        await service.learnKeypadsAndReaders(this.keypadAndReaderLearnt, this.showLearningFailed);
      } else if (this.type === 'zones') {
        this.reset();
        this.$emit('learnZonesStarted');
        await service.learnZones(this.zoneLearnt, this.showLearningFailed, this.manuallyAssociatedZone ? this.manuallyAssociatedZone - limits.WE_ZONE_OFFSET : null);
      } else if (this.type === 'sirens') {
        this.reset();
        await service.learnSirens(this.bellLearnt, this.showLearningFailed);
      } else if (this.type === 'keyfobs') {
        this.reset();
        await service.learnKeyfobs(this.keyfobLearnt, this.showLearningFailed);
      }
    },
    async finish() {
      this.close();
    },
    async close() {
      this.isReconfiguring = true;
      await service.finish(); // Wait for panel to reconfigure devices
      // now that learning is done, start refreshing the relevant list & wizard pages, based on the type learnt
      if (this.type === 'keypadsAndReaders') {
        const requiredEndpointsList = await this.$store.dispatch('keypadsAndReadersState/list/requiredEndpoints');
        const requiredEndpointsAddDevice = (await this.$store.dispatch('keypadsAndReadersState/addKeypadOrReader/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
        const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
        // Keypads and readers list page does not use config zone info areas.
        eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['keypadsAndReadersState/list'] });
      } else if (this.type === 'zones') {
        const requiredEndpointsList = await this.$store.dispatch('zonesState/list/requiredEndpoints');
        const requiredEndpointsAddDevice = (await this.$store.dispatch('zonesState/addZone/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
        let endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
        // Handle the zone area endpoints: take them all away, then add only the ones that will change:
        endpointsToUpdate = endpointsToUpdate.filter(endp => !zoneAreasHelper.zoneAreasEndpointsList.includes(endp));
        // After being learned, a zone will initially be associated with Area 01 only, i.e. using endpoint configZoneInfoArea00s
        endpointsToUpdate.concat('configZoneInfoAreas00s');
        eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['zonesState/list'] });
      } else if (this.type === 'sirens') {
        const requiredEndpointsList = await this.$store.dispatch('sirensState/list/requiredEndpoints');
        const requiredEndpointsAddDevice = (await this.$store.dispatch('sirensState/addSiren/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
        const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
        // Sirens list page does not use config zone info areas.
        eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['sirensState/list'] });
      } else if (this.type === 'keyfobs') {
        const requiredEndpointsList = await this.$store.dispatch('usersState/list/requiredEndpoints');
        const requiredEndpointsAddUser = (await this.$store.dispatch('usersState/addUser/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
        const requiredEndpointsAddKeyfob = (await this.$store.dispatch('usersState/addKeyFob/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp) && !requiredEndpointsAddUser.includes(endp));
        const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddUser, requiredEndpointsAddKeyfob);
        // Users list page does not use config zone info areas.
        eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['usersState/list'] });
      }

      this.$emit('done');
    },
    async keypadAndReaderLearnt() {
      this.keypadsFound += 1;
      this.isLoading = false;
      return true;
    },
    zoneLearnt({ index }) {
      this.zonesFound += 1;
      this.foundZoneIndexes.push(index + limits.WE_ZONE_OFFSET);
      this.isLoading = false;
      return true;
    },
    bellLearnt() {
      this.sirensFound += 1;
      this.isLoading = false;
      return true;
    },
    keyfobLearnt({ index }) {
      this.keyfobsFound += 1;
      this.fobIndex = index;
      service.stopLearning();
      this.isLoading = false;
      return false;
    },
    showLearningFailed() {
      service.stopLearning();
      this.isLoading = false;
      let description;
      if (this.type === 'keypadsAndReaders') {
        description = this.$t('errors.LearnWireless.KEYPAD');
      } else if (this.type === 'zones') {
        description = this.$t('errors.LearnWireless.ZONE');
      } else if (this.type === 'sirens') {
        description = this.$t('errors.LearnWireless.SIREN');
      } else if (this.type === 'keyfobs') {
        description = this.$t('errors.LearnWireless.KEYFOB');
      }
      this.$notification.open({
        icon: <a-icon type="close-circle" style="color: #ff0000" />,
        placement: 'bottomRight',
        message: description + this.$t('errors.LearnWireless.LEARNT_ALREADY'),
      });
    },
  },
  async errorCaptured(err) {
    this.$notification.open({
      icon: <a-icon type="close-circle" style="color: #ff0000" />,
      placement: 'bottomRight',
      message: this.$t('errors.title'),
      description: err instanceof LearnWirelessFailed ? this.$t('errors.LearnWireless.LEARN_FAILED') : undefined,
    });

    this.close();
  },
  async destroyed() {
    this.isReconfiguring = false;
    this.close();
  },
};
</script>

<style lang="scss">
.spinner__wrapper {
  text-align: center;
  margin: 20px 0;

  svg {
    margin-bottom: 20px;
  }
}
.zones-list {
  padding-left: 15px;
  margin-top: -10px;

  li {
    font-size: 0.9em;
    list-style-type: none;
    font-style: italic;
  }
}
</style>
