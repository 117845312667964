import list from './pages/zones-list/zones-list-data';
import zone from './pages/zone/zone-data';
import addZone from './shared/components/add-zone/add-zone-data';

export default {
  namespaced: true,
  modules: {
    list,
    zone,
    addZone,
  },
};
