import { getField } from 'vuex-map-fields';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { NotFoundError } from '@/app/shared/errors';
import { KEYPAD_AND_READER_TYPE } from '@/app/keypads-and-readers/shared/enums';
import i18n from '@/app/shared/services/i18n';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import { toFaultString } from '@/app/shared/utils/activeFaults';
import { roundToNumber } from '@/app/zones/pages/zone/zone-data';
import { getDoorZones } from '@/app/keypads-and-readers/shared/services/helpers';
/* eslint-disable no-await-in-loop */

// cached voltage
let gVoltageValue = null;
let gBusCommsFault = false; // i.e., ok


function initialState() {
  return {
    name: undefined,
    state: undefined,
    areasInfo: undefined,
    areasConfig: undefined,
    levelsConfig: undefined,
    zones: undefined,

    form: {
      name: undefined,
      type: undefined,
      address: undefined,
      keypadsIndex: undefined,
      location: undefined,
      readerType: undefined,
      setsAreas: undefined,
      unsetsAreas: undefined,
      inAreas: undefined,
      readerLevel: undefined,
      lockOpenTime: undefined,
      doorOpenTime: undefined,
      doorContactNumber: undefined,
      isLockOpenState: false,
    },

    diagnostics: [
      {
        isLoading: true,
        key: 'wiredReader.Diagnostics.Status',
      },
      {
        isLoading: true,
        key: 'wiredReader.Diagnostics.BusStatus',
      },
      {
        isLoading: true,
        key: 'wiredReader.Diagnostics.DataBus',
      },
    ],
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {
    getField,
  },
  actions: {
    requiredEndpoints() {
      // TODO Investigate replacing liveActiveFaults with liveActiveFaultsKeypadsAndReaders
      return ['configKeypadInfo', 'infoAreaInfo', 'configAreaInfoNames', 'configLevelInfoNames', 'configZoneInfoParts', 'liveReaderInfo', 'liveActiveFaults'];
    },
    async populate(context, { endpoints, payload }) {
      const type = KEYPAD_AND_READER_TYPE.fromInt(endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Type);

      if (type !== KEYPAD_AND_READER_TYPE.TYPE_READER) throw new NotFoundError();

      const doorZones = getDoorZones(endpoints.configZoneInfoParts);
      context.commit('set', {
        name: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Name,
        areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
        areasConfig: endpoints.configAreaInfoNames.data.Config.areaInfo,
        levelsConfig: endpoints.configLevelInfoNames.data.Config.areaInfo,
        zones: doorZones,
      });

      context.commit('setForm', {
        name: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Name.trim(),
        type: type.key,
        address: payload.index,
        keypadsIndex: payload.index,
        location: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Location,
        readerType: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].ReaderType,
        setsAreas: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].KeypadSets,
        unsetsAreas: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].KeypadUnsets,
        inAreas: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].InAreas,
        readerLevel: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].ReaderLevel,
        lockOpenTime: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].LockOpenTime_secs,
        doorOpenTime: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].DoorOpenTime_secs,
        doorContactNumber: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].DoorContactZone,
        isLockOpenState: false,
      });
    },
    async onPoll(context, {
      dataStatus, key, payload, endpoint,
    }) {
      if (key === 'liveActiveFaults') {
        const caseTamperFault = endpoint.data.Live.ActiveFaults.Devices.Readers[payload.index].CaseTamper;
        gBusCommsFault = endpoint.data.Live.ActiveFaults.Devices.Readers[payload.index].BusComms;

        context.commit('trySet', { dataStatus, data: { state: toFaultString(caseTamperFault, gBusCommsFault) } });

        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wiredReader.Diagnostics.Status',
          item: {
            value: caseTamperFault ? 'TAMPER' : toFaultString(gBusCommsFault),
            tooltip() {
              return i18n.t(`${this.key}.tooltips.${this.value}`);
            },
          },
        });
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wiredReader.Diagnostics.DataBus',
          item: {
            value: toFaultString(gBusCommsFault),
            tooltip() {
              return i18n.t(`${this.key}.tooltips.${this.value}`);
            },
          },
        });
      }

      if (key === 'liveReaderInfo') {
        const endpt = endpoint.data.Live.readerInfo.Readers[payload.index];
        if (endpt.BusVoltage_mV != null) {
          gVoltageValue = roundToNumber(endpt.BusVoltage_mV / 1000, 1);
        }
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wiredReader.Diagnostics.BusStatus',
          item: {
            title: i18n.t('wiredReader.Diagnostics.BusStatus.title'),
            value: toFaultString(gBusCommsFault), // on the assumption that if there's no comms, then the wire is cut
            tooltip() {
              if (this.value === 'OK') {
                return `${gVoltageValue}V`;
              }
              if (this.value === 'FAULT') {
                return i18n.t(`${this.key}.tooltips.${this.value}`);
              }
              return i18n.t(`${this.key}.tooltips.MISSING`);
            },
          },
        });
      }
    },
    async save(context) {
      const { keypadsIndex } = context.state.form;
      const response = await api.get(`/Config/keypadInfo/Keypads/${keypadsIndex}`);

      const data = response.data.Config.keypadInfo.Keypads[keypadsIndex];

      data.Name = context.state.form.name.padEnd(16);
      data.Location = context.state.form.location;
      data.KeypadSets = context.state.form.setsAreas;
      data.KeypadUnsets = context.state.form.unsetsAreas;
      data.InAreas = context.state.form.inAreas;
      data.ReaderLevel = context.state.form.readerLevel;
      data.ReaderType = context.state.form.readerType;
      data.LockOpenTime_secs = parseInt(context.state.form.lockOpenTime, 10);
      data.DoorOpenTime_secs = parseInt(context.state.form.doorOpenTime, 10);
      data.DoorContactZone = parseInt(context.state.form.doorContactNumber, 10);

      await api.put(`/Config/keypadInfo/Keypads/${keypadsIndex}`, data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('keypadsAndReadersState/wiredReader/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['keypadsAndReadersState/wiredReader'] });
    },
    async delete(context) {
      const { keypadsIndex } = context.state.form;
      // eslint-disable-next-line no-await-in-loop
      const objDefaults = await generateDefaultObject(`/Config/keypadInfo/Keypads/${keypadsIndex}`);
      objDefaults.Name = '';
      // eslint-disable-next-line no-await-in-loop
      await api.put(`/Config/keypadInfo/Keypads/${keypadsIndex}`, objDefaults);
      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('keypadsAndReadersState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('keypadsAndReadersState/addKeypadOrReader/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['keypadsAndReadersState/list'] });
    },
    async unlock(context) {
      api.put(`/Live/readerInfo/Readers/${context.state.form.address}/OpenDoor`, true);
    },
  },
});

export default store;
