<template>
    <a-dropdown :class="{ alternative: isAlternative }" @visibleChange="visibleChanged" :visible="dropdownVisible" :trigger="['click']" :getPopupContainer="getPopupContainer" :disabled="disabled">
      <div slot="overlay" class="zone-selector-dropdown">
        <a-menu>
          <a-menu-item v-for="(zone, index) in zones" :key="`zone-${index}`">
              <a-checkbox :checked="index === selectedIndex" @change="onCheckboxChange(zone, index, $event)">
                <span v-if="zone.internalKey < MAX_ZONES"> {{ $t('common.Zone') }} {{ zone.internalKey + 1 }} - {{ zone.name }}</span>
                <span v-else> {{ zone.name }}</span>
              </a-checkbox>
          </a-menu-item>
        </a-menu>
      </div>
      <div class="ant-dropdown-trigger" @mouseenter="activeHover = true" @mouseleave="activeHover = false">
        <p v-if="title.length!=0">
          <span v-if="activeHover"> {{ title }} </span>
          <span v-if="!activeHover"> {{ title }} </span>
        </p>
        <p v-else-if="title.length===0">
          <a-button class="ant-dropdown-trigger">
            <span style="overflow: hidden;">
              {{ title }}
            </span>
            <a-icon type="down"/>
          </a-button>
        </p>
      </div>
    </a-dropdown>
</template>

<script>
import { limits } from '@/app/shared/constants';

export default {
  components: {

  },
  props: ['value', 'zones', 'isAlternative', 'disabled'],
  data() {
    return {
      dropdownVisible: false,
      activeHover: false,
      selectedIndex: -1,
      MAX_ZONES: limits.MAX_ZONES,
    };
  },
  computed: {
    title() {
      if (this.selectedIndex >= 0) {
        const currZone = this.zones[this.selectedIndex];
        return currZone.name;
      }
      return 'disabled'; // should be impossible to reach
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.closest('.ant-drawer-content') || document.body;
    },
    initialise() {
      // Ignore the initialisation call if the form data hasn't been populated yet.
      if (this.zones !== undefined) {
        // Populate the internal data structure used for the drop down display.
        this.selectedIndex = this.zones.findIndex(x => x.internalKey === this.value);
        this.emitValues();
      }
    },
    visibleChanged(e) {
      this.dropdownVisible = e;
    },
    onCheckboxChange(zone, index) {
      // Exactly one zone must be checked: the check boxes act like radio buttons. Check if an zone that is NOT
      // currently checked has been selected.
      if (this.selectedIndex !== index) {
        this.selectedIndex = index;
        this.emitValues();
      }
    },
    emitValues() {
      if (this.selectedIndex >= 0) {
        const selected = this.zones[this.selectedIndex];
        this.$emit('selectedZone', selected);
      } else {
        this.$emit('selectedZone', null);
      }
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.initialise();
      }
    },
    zones(newValue, oldValue) {
      if (newValue !== undefined && newValue !== oldValue) {
        this.initialise();
      }
    },
  },
  created() {
    this.initialise();
  },
};
</script>

<style lang="scss">
/* Specify the style for the zone selector drop down box. It's quite long (lots of zones) so limit
   its height, and add a vertical scroll bar. */
.zone-selector-dropdown {
  border-style: solid;
  border-width: 1px;
  max-height: 60ex;
  overflow-y: scroll;
}
/* Specify the style for each area selection check box within the area selector drop down box.
   Reduce the vertical spacing, to squash everything up as much as possible. */
.zone-selector-dropdown .ant-menu-item {
  line-height: 1.1;
  height: 1.2em;
  margin: 2px;
}
form .ant-dropdown-trigger{
    width: 100%;
    color: #484848;

    span {
      width:100%;
    }
    i {
      color: #484848 !important;
      margin-left: -8px !important;
    }
}
</style>
