import list from './pages/sirens-list/sirens-list-data';
import wirelessSiren from './pages/wireless-siren/wireless-siren-data';
import addSiren from './shared/components/add-siren/add-siren-data';
import wiredSiren from './pages/wired-siren/wired-siren-data';

export default {
  namespaced: true,
  modules: {
    list,
    wirelessSiren,
    addSiren,
    wiredSiren,
  },
};
