<template>
  <a-form-item :validate-status="model.$error ? 'error': 'success'"
               :label-col="style.labelSize || labelCol"
               :wrapper-col="style.wrapperSize || wrapperCol">
    <template slot="label">
      <a-tooltip v-if="labelTooltip" :title="labelTooltip">
        <label>{{ label }}</label>
      </a-tooltip>
      <label v-else>{{ label }}</label>
    </template>
    <zone-selector v-model="model.$model" :zones="zones"
      :is-alternative="style.isAlternative" :disabled="disabled" @selectedZone="selectedZone" />
    <div class="ant-form-explain" v-if="model.$error && !model.required">{{ $t('common.Field-is-required') }}</div>
  </a-form-item>
</template>

<script>
import ZoneSelector from '@/app/shared/components/zone-selector/zone-selector.vue';

export default {
  components: {
    ZoneSelector,
  },
  props: ['label', 'labelTooltip', 'model', 'label-col', 'wrapper-col', 'zones', 'displayStyle', 'disabled'],
  data() {
    if (this.displayStyle === 'small-alternative') {
      return {
        style: {
          isAlternative: true,
          labelSize: {
            sm: { span: 12 }, md: { span: 8 }, lg: { span: 6 }, xl: { span: 5 },
          },
          wrapperSize: {
            sm: { span: 24 }, md: { span: 18 }, lg: { span: 18 }, xl: { span: 18 },
          },
        },
      };
    }
    if (this.displayStyle === 'small') {
      return {
        style: {
          isAlternative: false,
          labelSize: {
            sm: { span: 12 }, md: { span: 8 }, lg: { span: 6 }, xl: { span: 5 },
          },
          wrapperSize: {
            sm: { span: 24 }, md: { span: 18 }, lg: { span: 18 }, xl: { span: 18 },
          },
        },
      };
    }
    return {
      style: {
        isAlternative: true,
      },
    };
  },
  methods: {
    selectedZone(zoneIndex) {
      // Just forward on the event from the internal component.
      this.$emit('selectedZone', zoneIndex);
    },
  },
};
</script>

<style lang="scss">
</style>
