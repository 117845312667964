
import { EnumWrapper } from '@/app/shared/utils/enumWrapper';

export const ARM_OPTIONS = new EnumWrapper([
  { key: 'NO', value: 'NO' },
  { key: 'ARM', value: 'ARM' },
  { key: 'SPECIAL_ARM', value: 'SPECIAL_ARM' },
]);

ARM_OPTIONS.fromValues = function fromValues(normal, special) {
  if (normal) return 'ARM';
  if (special) return 'SPECIAL_ARM';
  return 'NO';
};

export const DISARM_OPTIONS = new EnumWrapper([
  { key: 'NO', value: 'NO' },
  { key: 'DISARM', value: 'DISARM' },
  { key: 'SPECIAL_DISARM', value: 'SPECIAL_DISARM' },
]);

DISARM_OPTIONS.fromValues = function fromValues(normal, special) {
  if (normal) return 'DISARM';
  if (special) return 'SPECIAL_DISARM';
  return 'NO';
};

export const BUGLARY_ALARM_OPTIONS = new EnumWrapper([
  { key: 'NO', value: 'NO' },
  { key: 'ALARM_ALL', value: 'ALARM_ALL' },
  { key: 'ALARM_ONCE', value: 'ALARM_ONCE' },
]);

BUGLARY_ALARM_OPTIONS.fromValues = function fromValues(all, once) {
  if (all) return 'ALARM_ALL';
  if (once) return 'ALARM_ONCE';
  return 'NO';
};

export const TAMPER_OPTIONS = new EnumWrapper([
  { key: 'NO', value: 'NO' },
  { key: 'TAMPER_ALL', value: 'TAMPER_ALL' },
  { key: 'TAMPER_ONCE', value: 'TAMPER_ONCE' },
]);

TAMPER_OPTIONS.fromValues = function fromValues(all, once) {
  if (all) return 'TAMPER_ALL';
  if (once) return 'TAMPER_ONCE';
  return 'NO';
};
