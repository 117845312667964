<template>
	<two-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
		<template v-slot:title>
			<h1 id="title">{{ $t('userDefinedOutputs.navigationTitle') }}</h1>
		</template>

		<div>
			<div>
				<a-input-search
					:placeholder="$t('userDefinedOutputs.Search-for-a-user-defined-output')"
					size="large"
					v-model="grid.searchQuery"
				/>

				<a-row :gutter="23" class="header">
					<a-col :xs="{ span: 18}" :md="{ span: 18}" :lg="{ span: 18}" :xl="{ span: 18}" class="filters">
						<grid-filter
							:title="$t('common.Type')"
							:records="allItems"
							field="outputType"
							@change="gridFilterChange"
							:customRender="(i) => $t(`userDefinedOutputs.enums.outputType.${i.key}`) "
              :initialFilters="gridSavedFilters('outputType')"
						/>
            <grid-order
							:options="[{ field: 'name', name:  $t('common.Name')},{ field: 'number', name: $t('common.Number')}]"
							@change="gridOrderByChange"
						/>
					</a-col>

					<a-col
						:xs="{ span: 6}"
						:md="{ span: 6}"
						:lg="{ span: 6}"
						:xl="{ span: 6}"
						class="align-right hide-small"
					>
						<grid-display-toggle :isTabularview="grid.options.isTabularView" @toggle="onGridDisplayToggle" />
					</a-col>
				</a-row>
			</div>
      <loading-indicator v-if="isLoading"/>
			<grid class="user-defined-output" cardsPerRow="4" :options="grid.options" :records="gridItems"></grid>
		</div>
    <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
      @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
	</two-col-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
  ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard,
} from '@/app/shared/mixins';
import LoadingIndicator from '@/app/shared/components/loading-indicator/loading-indicator.vue';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import outputHelper from '@/app/shared/services/output-helper';
import { USER_DEFINED_OUTPUT_TYPE } from '@/app/outputs/shared/enums';
import { USER_DEFINED_OUTPUT_BEGIN } from '@/app/outputs/shared/services/helpers';

const storeNamespace = 'outputsState/userDefinedOutputsList';
const { mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard],
  components: {
    LoadingIndicator,
    DisplayFaultsModal,
  },
  data() {
    return {
      addDrawerVisible: false,
      loader: {
        storeNamespace,
      },
      intervalID: [], // Stores the interval ID for clearing later
      grid: {
        itemsStorePath: `${storeNamespace}/items`,
        deleteItemsStoreAction: `${storeNamespace}/delete`,

        canToggle: true,
        searchFields: ['name', 'number'],
        orderBy: 'number',

        options: {
          columns: [
            {
              title: this.$t('common.Name'),
              dataIndex: 'name',
              isTitle: true,
            },
            {
              title: this.$t('common.Number'),
              dataIndex: 'number',
            },
            {
              title: this.$t('userDefinedOutputs.Output-type'),
              dataIndex: 'outputType',
              customRender: text => this.$t(
                `userDefinedOutputs.enums.outputType.${text.key}`,
              ),
            },
            {
              title: this.$t('userDefinedOutputs.Output-on-time'),
              dataIndex: 'outputOnTime',
              visibility: col => (col.outputType.key === 'USER_OUTPUT_PULSED'),
            },
          ],

          canDelete: () => false,
          canSelect: () => false,
          canToggle: () => true,
          toggleState: record => record.state,
          click: (record) => {
            this.$router.push({
              name: 'user-defined-outputs-user-defined-output',
              params: { index: record.index },
            });
          },
          toggle: async (index, record) => {
            const outputNumber = parseInt(record.index, 10) + USER_DEFINED_OUTPUT_BEGIN;
            await outputHelper.activateOutputType(outputNumber, !record.state, this.loader.instance);
            if (record.outputType.value === USER_DEFINED_OUTPUT_TYPE.USER_OUTPUT_PULSED.value) {
              if (!record.state === true) {
                this.startCountdown(index, record.outputOnTime, async () => {
                  await outputHelper.activateOutputType(outputNumber, !record.state, this.loader.instance);
                });
              } else {
                clearInterval(this.intervalID[index]);
              }
            }
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
  methods: {
    startCountdown(index, timer, callback) {
      let initialValue = timer;
      this.intervalID[index] = setInterval(() => {
        initialValue -= 1;
        if (initialValue <= 0) {
          clearInterval(this.intervalID[index]);
          callback();
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" >
.user-defined-output .card__body {
	padding: 0rem 1.1rem;
}
</style>
