import { storeFactory } from '@/app/shared/services/store-helper';
import i18n from '@/app/shared/services/i18n';

function initialState() {
  return {
    status: {
      hasPolled: false,
    },

    ethernet: { isLoading: true, tooltip() { } },
    wifi0: { isLoading: true, tooltip() { } },
    cellular0: { isLoading: true, tooltip() { } },
    cellular1: { isLoading: true, tooltip() { } },
  };
}

const { store } = storeFactory(initialState, {
  getters: {
    isLoading: state => !state.status.hasPolled,
  },
  actions: {
    requiredEndpoints: () => ['liveCommsInfo'],
    async onPoll(context, { key, dataStatus, endpoint }) {
      if (key === 'liveCommsInfo') {
        context.commit('trySet', {
          dataStatus,
          data: {
            ethernet: {
              state: endpoint.data.Live.commsInfo.Ethernet[0].state,
              tooltip() {
                if (this.state === 'LINK_UP') {
                  return i18n.t('communications.lan.ConnectedTooltip');
                }

                return i18n.t('communications.lan.DisconnectedTooltip');
              },
            },
            wifi0: {
              state: endpoint.data.Live.commsInfo.WiFi[0].state,
              level: endpoint.data.Live.commsInfo.WiFi[0].SignalLevel,
              quality: endpoint.data.Live.commsInfo.WiFi[0].SignalQuality,
              tooltip() {
                const desc = i18n.t(`communications.wifi.signalStrength.${this.quality}`);
                return `${desc} (${this.level}%)`;
              },
            },
            cellular0: {
              state: endpoint.data.Live.commsInfo.Cellular[0].state,
              level: endpoint.data.Live.commsInfo.Cellular[0].SignalLevel,
              quality: endpoint.data.Live.commsInfo.Cellular[0].SignalQuality,
              tooltip() {
                const desc = i18n.t(`communications.cellular.signalStrength.${this.quality}`);
                return `${desc} (${this.level}%)`;
              },
            },
            cellular1: {
              state: endpoint.data.Live.commsInfo.Cellular[1].state,
              level: endpoint.data.Live.commsInfo.Cellular[1].SignalLevel,
              quality: endpoint.data.Live.commsInfo.Cellular[1].SignalQuality,
              tooltip() {
                const desc = i18n.t(`communications.cellular.signalStrength.${this.quality}`);
                return `${desc} (${this.level}%)`;
              },
            },
          },
        });

        context.commit('setStatus', { hasPolled: true });
      }
    },
  },
});

export default store;
