<template>
    <a-drawer
    placement="right"
    :closable="false"
    @close="onClose"
    :destroyOnClose="true"
    :visible="visible">
    <wizard :config="wizard" :state="$v" @save="save" @done="onClose">
        <wizard-step name="start">
          <a-form>
            <p-select :label="$t('common.Associated-with')" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              @change="$v.form.associatedWithOutputIndex.$model = null"
              :model="$v.form.associatedWithDevice" :items="associatedDevices" :valueExpr="(i) => i.key" :textExpr="(i) => i.name" />

            <p-select :label="$t('outputs.Output-type')" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              :model="$v.form.baseOutputType" :items="outputTypes" :valueExpr="(i) => i.value" :textExpr="(i) => i.name" />
            <p-select v-if="isAreaSpecific" :label="$t('common.Area')" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              :model="$v.form.areaIndex" :items="areas" :valueExpr="(i) => i.value" :textExpr="(i) => i.label" />
            <a-row v-if="sirenOutputTypeSelected == true" class="field-info">
              <a-col :span="17" :offset="7">
                {{ $t('outputs.Siren-output-type')}}
              </a-col>
            </a-row>

            <p-select :label="$t('common.Number')" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              :model="$v.form.associatedWithOutputIndex" :items="outputIndexes" :valueExpr="(i) => i" :textExpr="(i) => i + 1" />
          </a-form>
        </wizard-step>

        <wizard-step name="transistored">
          <a-form>
            <p-select :label="$t('outputs.Transistored')" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              :model="$v.form.transistored" :items="Object.keys(TRANSISTORED_MODE)" :valueExpr="(i) => i" :textExpr="(i) => $t(`enums.TRANSISTORED_MODE.${i}`)" />
          </a-form>
        </wizard-step>

        <wizard-step name="follow">
              <a-form>
                <p-select :label="$t('outputs.Follow-type')" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
                  :model="$v.form.followType" @change="setDefaultFollowTime" :items="Object.keys(FOLLOW_TYPE)" :valueExpr="(i) => FOLLOW_TYPE[i]" :textExpr="(i) => $t(`enums.FOLLOW_TYPE.${i}`)" />

                <template>
                  <p-select :label="$t('outputs.Follow-what')" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
                    :model="$v.form.followWhat" :items="Object.keys(FOLLOW_WHAT)" :valueExpr="(i) => FOLLOW_WHAT[i]" :textExpr="(i) => $t(`enums.FOLLOW_WHAT.${i}`)" />

                  <template v-if="$v.form.followWhat.$model === FOLLOW_WHAT.ZONE">
                    <p-select :label="$t('outputs.Follow-input')" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
                      :model="$v.form.followZone" :items="zones" :valueExpr="(i) => i.value" :textExpr="(i) => i.name" />
                  </template>

                  <template v-if="$v.form.followWhat.$model === FOLLOW_WHAT.AREA">
                    <p-select :label="$t('outputs.Follow-area')" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
                      :model="$v.form.followArea" :items="areas" :valueExpr="(i) => i.value" :textExpr="(i) => i.name" />
                  </template>
                </template>

                <template v-if="hasFollowTime">
                  <a-form-item :validate-status="$v.form.followTime.$error ? 'error': 'success'">
                    <p-input :label="$t('outputs.Follow-time')" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" :model="$v.form.followTime" />
                    <div class="ant-form-explain" v-if="!$v.form.followTime.followTimeValidator">{{ $t('common.Field-must-be-between', { min: 0, max: 63 }) }}</div>
                  </a-form-item>
                </template>

                <template v-if="hasResetZone">
                  <p-select :label="$t('outputs.Reset-zone')" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
                    :model="$v.form.resetZone" :items="zones" :valueExpr="(i) => i.value" :textExpr="(i) => i.name" />
                </template>

                <div>
                  <p-select :label="$t('outputs.Follow-when')" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
                    :model="$v.form.followWhen" :items="Object.keys(FOLLOW_WHEN)" :valueExpr="(i) => FOLLOW_WHEN[i]" :textExpr="(i) => $t(`enums.FOLLOW_WHEN.${i}`)" />
                </div>
              </a-form>
        </wizard-step>

    </wizard>

    </a-drawer>
</template>
<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import { findIndex } from 'lodash';

import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';

import { Clone } from '@/app/shared/mixins';

import {
  FOLLOW_TYPE, FOLLOW_WHAT, FOLLOW_WHEN, TRANSISTORED_MODE,
} from '@/app/outputs/shared/enums';
import { followTimeValidator, isTransistored } from '@/app/outputs/shared/services/helpers';

const storeNamespace = 'outputsState/addOutput';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [Clone],
  components: {
    Wizard,
    WizardStep,
  },
  props: {
    associatedDeviceKey: { default: null },
  },
  data() {
    return {
      clone: {
        storeNamespace,
      },
      FOLLOW_TYPE,
      FOLLOW_WHAT,
      FOLLOW_WHEN,
      TRANSISTORED_MODE,
      wizard: {
        title: this.$t('outputs.drawer.Add-an-output'),
        start: {
          onExitValidate: 'startStep',
          progress: 30,
          next: [
            { key: 'transistored', buttonText: this.$t('common.Next'), if: () => this.isTransistored },
            { key: 'follow', buttonText: this.$t('common.Next'), if: () => !this.isTransistored && this.isFollow },
            { key: 'save', buttonText: this.$t('common.Save') },
          ],
        },
        transistored: {
          onExitValidate: 'transistoredStep',
          progress: 60,
          next: [
            { key: 'follow', buttonText: this.$t('common.Next'), if: () => this.isFollow },
            { key: 'save', buttonText: this.$t('common.Save') },
          ],
        },
        follow: {
          onExitValidate: 'followStep',
          progress: 60,
          next: [
            { key: 'save', buttonText: this.$t('common.Save') },
          ],
        },
      },
    };
  },
  validations: {
    startStep: ['form.baseOutputType', 'form.areaIndex', 'form.associatedWithDevice', 'form.associatedWithOutputIndex'],
    transistoredStep: ['form.transistored'],
    followStep: ['form.followType', 'form.followWhat', 'form.followZone', 'form.followArea', 'form.followTime', 'form.resetZone', 'form.followWhen'],
    form: {
      baseOutputType: {
        required,
      },
      areaIndex: {
        required: requiredIf(function validate() {
          return this.isAreaSpecific;
        }),
      },
      associatedWithDevice: {
        required,
      },
      associatedWithOutputIndex: {
        required,
      },
      transistored: {
        required,
      },
      followType: {
        required: requiredIf(function validate() {
          return this.isFollow;
        }),
      },
      followWhat: {
        required: requiredIf(function validate() {
          return this.isFollow;
        }),
      },
      followZone: {
        required: requiredIf(function validate(m) {
          return this.isFollow && m.followWhat === FOLLOW_WHAT.ZONE;
        }),
      },
      followArea: {
        required: requiredIf(function validate(m) {
          return this.isFollow && m.followWhat === FOLLOW_WHAT.AREA;
        }),
      },
      followTime: {
        required: requiredIf(function validate() {
          return this.isFollow && this.hasFollowTime;
        }),
        followTimeValidator,
      },
      resetZone: {
        required: requiredIf(function validate() {
          return this.isFollow && this.hasResetZone;
        }),
      },
      followWhen: {
        required: requiredIf(function validate() {
          return this.isFollow;
        }),
      },
    },
  },
  computed: {
    ...mapState(['form', 'associatedDevices', 'areasInfo', 'areasConfig', 'zones']),
    ...mapGetters(['outputTypes']),
    areas() {
      const res = this.areasConfig.Areas.map((areaConfig, i) => ({
        value: i,
        name: this.areasInfo.Areas[i].Identifier,
        label: `${this.$t('common.Area')} ${this.areasInfo.Areas[i].Identifier} - ${areaConfig.Name}`,
      }));
      return res;
    },
    outputIndexes() {
      const device = this.associatedDevices.find(v => v.key === this.$v.form.associatedWithDevice.$model);
      let listAvailableOutputs = [];
      if (device && device.type === 'ENDSTATION') {
        listAvailableOutputs = device ? device.availableOutputs.map(i => i.deviceOutputIndexDisplay) : [];
      } else {
        listAvailableOutputs = device ? device.availableOutputs.map(i => i.deviceOutputIndex) : [];
      }
      listAvailableOutputs.sort((a, b) => a - b);
      return listAvailableOutputs;
    },
    isTransistored() {
      const device = this.associatedDevices.find(v => v.key === this.$v.form.associatedWithDevice.$model);
      return isTransistored(device, this.$v.form.associatedWithOutputIndex.$model);
    },
    isFollow() {
      return this.$v.form.baseOutputType.$model === 35;
    },
    hasFollowTime() {
      if (this.$v.form.followType.$model === FOLLOW_TYPE.TIMED) {
        const device = this.associatedDevices.find(v => v.key === this.$v.form.associatedWithDevice.$model);
        return device.type === 'ENDSTATION' || device.type === 'KEYPAD' || device.type === 'READER' || device.type === 'WIRED_ZONE_EXPANDER' || device.type === 'OUTPUT_EXPANDER';
      }
      return false;
    },
    hasResetZone() {
      return this.$v.form.followType.$model === FOLLOW_TYPE.LATCHED;
    },
    sirenOutputTypeSelected() {
      if (this.$v.form.baseOutputType.$model != null) {
        const outputType = this.outputTypes.find(o => o.value === this.$v.form.baseOutputType.$model);
        if (outputType !== undefined) {
          return outputType.isSirenOutputType;
        }
      }
      return false;
    },
    isAreaSpecific() {
      const outputType = this.outputTypes.find(o => o.value === this.$v.form.baseOutputType.$model);
      if ((outputType !== undefined) && (outputType.specific !== undefined)) {
        return outputType.specific === 'AREA';
      }
      return false;
    },
  },
  watch: {
    associatedDevices(value) {
      if (!value.length || this.associatedDeviceKey === undefined) {
        return;
      }

      this.assignAssociatedDevice(value, this.associatedDeviceKey);
    },
    associatedDeviceKey(value) {
      if (value === undefined || !this.associatedDevices.length) {
        return;
      }

      this.assignAssociatedDevice(this.associatedDevices, value);
    },
    // Whenever we close the wizard, the component should be reset
    visible(newVal) {
      if (!newVal) {
        this.$store.dispatch('outputsState/addOutput/reset');
        this.$v.$reset();
      }
    },
  },
  methods: {
    ...mapActions(['add']),
    async assignAssociatedDevice(devices, key) {
      const deviceIndex = findIndex(devices, d => d.key === key);

      if (deviceIndex === -1) {
        return;
      }

      this.$v.form.associatedWithDevice.$model = devices[deviceIndex].key;
    },
    // This is called when selecting a new Follow type for a Follow output
    setDefaultFollowTime() {
      // Set the default follow timer value to 0, if default was 197 (atm Panel sets all output timers to 197 by default)
      if (this.$v.form.followType.$model === FOLLOW_TYPE.TIMED && this.$v.form.followTime.$model === 197) {
        this.$v.form.followTime.$model = 0;
      }
    },
    async onClose() {
      this.$emit('onClose');
    },
    async save() {
      await this.add();
      this.$emit('onClose');

      if (!this.navigateToNewItem) {
        return;
      }

      this.$router.push({ name: 'output', params: { index: this.form.associatedWithOutputIndex } });
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

</style>
