<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('settings.engineerRestores.navigationTitle') }}</h2>
      </a-row>
      <a-form>
        <p-switch :label="$t('settings.engineerRestores.Burglary')"
                  :model="$v.form.burglary"
                  :labelTooltip="$t('settings.engineerRestores.BurglaryTooltip')"
                  :switchTooltip="toggleTooltipText($v.form.burglary)" display-style="small"
                  :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.engineerRestores.PA')"
                  :model="$v.form.pa"
                  :labelTooltip="$t('settings.engineerRestores.PATooltip')"
                  :switchTooltip="toggleTooltipText($v.form.pa)" display-style="small"
                  :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.engineerRestores.Tamper')"
                  :model="$v.form.tamper"
                  :labelTooltip="$t('settings.engineerRestores.TamperTooltip')"
                  :switchTooltip="toggleTooltipText($v.form.tamper)" display-style="small"
                  :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.engineerRestores.Soak')"
                  :model="$v.form.soak"
                  :switchTooltip="toggleTooltipText($v.form.soak)"
                  :labelTooltip="$t('settings.engineerRestores.SoakTooltip')" display-style="small"
                  :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.engineerRestores.Confirm')"
                  :model="$v.form.confirm"
                  :switchTooltip="toggleTooltipText($v.form.confirm)"
                  :labelTooltip="$t('settings.engineerRestores.ConfirmTooltip')" display-style="small"
                  :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.engineerRestores.ConfirmPA')"
                  :model="$v.form.confirmPA"
                  :switchTooltip="toggleTooltipText($v.form.confirmPA)"
                  :labelTooltip="$t('settings.engineerRestores.ConfirmPATooltip')" display-style="small"
                  :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.engineerRestores.Faults')"
                  :model="$v.form.faults"
                  :switchTooltip="toggleTooltipText($v.form.faults)"
                  :labelTooltip="$t('settings.engineerRestores.FaultsTooltip')" display-style="small"
                  :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.engineerRestores.Anti-code-restore')"
                  :model="$v.form.antiCodeRestore"
                  :switchTooltip="toggleTooltipText($v.form.antiCodeRestore)"
                  :labelTooltip="$t('settings.engineerRestores.Anti-code-restore-tooltip')" display-style="small"
                  :disabled="!configWriteLock.canWriteConfig" />
      </a-form>
      <div class="align-right">
          <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
      </div>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import TooltipHelper from '@/app/shared/services/tooltipHelper';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'settingsState/engineerRestores';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
    };
  },
  computed: {
    ...mapState(['form']),
  },
  validations: {
    form: {
      burglary: {},
      pa: {},
      tamper: {},
      soak: {},
      confirm: {},
      confirmPA: {},
      faults: {},
      antiCodeRestore: {},
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
    toggleTooltipText(field) {
      return TooltipHelper.toggleTooltipText(field,
        this.$t('settings.engineerRestores.enabledTooltip'),
        this.$t('settings.engineerRestores.disabledTooltip'));
    },
  },
};
</script>
