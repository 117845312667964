import cloudOptions from './pages/cloud-options/cloud-options-data';
import appOptions from './pages/app-options/app-options-data';
import smsControls from './pages/sms-controls/sms-controls-data';
import cellular from './pages/cellular/cellular-data';
import advancedOptions from './pages/advanced-options/advanced-options-data';
import smsNotificiations from './pages/sms-notifications/sms-notifications-data';
import voice from './pages/voice/voice-data';
import lan from './pages/lan/lan-data';
import wifi from './pages/wifi/wifi-data';
import arcOptions from './pages/arc-options/arc-options-data';

export default {
  namespaced: true,
  modules: {
    cloudOptions,
    appOptions,
    smsControls,
    cellular,
    advancedOptions,
    smsNotificiations,
    voice,
    lan,
    wifi,
    arcOptions,
  },
};
