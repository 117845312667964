import Vue from 'vue';
import Router from 'vue-router';

import { panelRoutes } from './panel';
import { loginRoutes } from './login';
import { keypadsAndReadersRoutes } from './keypads-and-readers';
import { zoneExpandersRoutes } from './zone-expanders';
import { zonesRoutes } from './zones';
import { maintenanceRoutes } from './maintenance';
import { historyRoutes } from './history';
import { outputExpandersRoutes } from './output-expanders';
import { outputsRoutes } from './outputs';
import { sirensRoutes } from './sirens';

import PageNotFound from './shared/components/PageNotFound.vue';
import { usersRoutes } from './users';
import { importExportRoutes } from './import-export';
import { communicationsRoutes } from './communications';
import { settingsRoutes } from './settings';

import authState from '@/app/shared/state/auth-state'; // eslint-disable-line import/order
// eslint-disable-next-line import/no-cycle
import authService from '@/app/shared/services/auth-service'; // eslint-disable-line import/order
import { isSessionLoggedIn } from '@/app/login/login.vue'; // eslint-disable-line import/order

Vue.use(Router);

const appRoutes = [
  {
    path: '*',
    name: 'page-not-found',
    component: PageNotFound,
  },
];

const routes = [
  ...panelRoutes,
  ...loginRoutes,
  ...keypadsAndReadersRoutes,
  ...zoneExpandersRoutes,
  ...zonesRoutes,
  ...appRoutes,
  ...maintenanceRoutes,
  ...historyRoutes,
  ...outputExpandersRoutes,
  ...outputsRoutes,
  ...usersRoutes,
  ...importExportRoutes,
  ...communicationsRoutes,
  ...settingsRoutes,
  ...sirensRoutes,
];

const router = new Router({
  routes,
  scrollBehavior: function scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// https://scotch.io/tutorials/handling-authentication-in-vue-using-vuex
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.allowAnonymous !== true)) {
    if (authState.isAuthenticated()) {
      next();
      return;
    }
    if (isSessionLoggedIn()) {
      await authService.refreshAuthState();
      next();
    } else {
      next({ name: 'login' });
    }
  } else {
    next();
  }
});

export default router;
