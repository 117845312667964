<template>

    <a-drawer
    placement="right"
    :closable="false"
    @close="close"
    :destroyOnClose="true"
    :visible="visible">

    <wizard :config="wizard" :state="$v" @save="save" @done="close" :initialStep="clonePayload != null ? 'manual' : 'start'">
      <wizard-step name="start">
        <template slot="body" slot-scope="{ wizard }">
          <a-button class="draw-link" @click="wizard.navigateTo('learn')" type="link" block>
            <text-row :title="$t('sirens.drawer.Learn-wireless-sirens')" title-icon="users" />
          </a-button>
          <a-button class="draw-link" @click="wizard.navigateTo('manual')" type="link" block>
            <text-row :title="$t('common.Add-manually')" title-icon="plus-circle" />
          </a-button>
        </template>
      </wizard-step>

      <wizard-step name="learn">
        <learn-wireless @done="close" :start-at="{ stage: 'info', type: 'sirens' }" />
      </wizard-step>

      <wizard-step name="manual">
        <a-form>
          <p-select :label="$t('common.Associated-with')" :model="$v.form.associatedWithDevice" :items="associatedDevices" :valueExpr="(i) => i.key" :textExpr="(i) => i.name" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
            @change="$v.form.index.$model = null" />
          <p-select :label="$t('outputs.Output-type')" :model="$v.form.outputType" :items="outputTypes" :valueExpr="(i) => i.value" :textExpr="(i) => i.name" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
          <a-row v-if="sirenOutputTypeSelected == false" class="field-info">
              <a-col :span="17" :offset="7">
                {{ $t('outputs.Non-siren-output-type')}}
              </a-col>
          </a-row>
          <p-select :label="$t('common.Number')" :model="$v.form.index" :items="availableNumbers" :valueExpr="(i) => i" :textExpr="(i) => i + 1" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
        </a-form>
      </wizard-step>
    </wizard>

  </a-drawer>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';

import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';
import LearnWireless from '@/app/shared/components/add-devices-drawer/learn-wireless.vue';

import { Clone } from '@/app/shared/mixins';

const storeNamespace = 'sirensState/addSiren';
const { mapGetters, mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [Clone],
  components: {
    Wizard,
    WizardStep,
    LearnWireless,
  },
  props: {
    visible: { default: false },
  },
  data() {
    return {
      clone: {
        storeNamespace,
      },
      wizard: {
        start: {
          title: this.$t('sirens.drawer.How-do-you-want-to-add-siren'),
          next: [],
        },
        learn: {
          mode: 'SIMPLE',
        },
        manual: {
          title: this.$t('sirens.drawer.Add-a-siren'),
          progress: 60,
          next: [
            { key: 'save', buttonText: this.$t('sirens.drawer.Add-siren') },
          ],
        },
      },
    };
  },
  validations: {
    form: {
      associatedWithDevice: {
        required,
      },
      outputType: {
        required,
      },
      index: {
        required,
      },
    },
  },
  computed: {
    ...mapState(['form', 'associatedDevices']),
    ...mapGetters(['outputTypes']),
    availableNumbers() {
      const device = this.associatedDevices.find(v => v.key === this.$v.form.associatedWithDevice.$model);
      // make a list with available outputs on the device type selected
      // if the device type is Endstation, use the deviceOutputIndexDisplay numbers
      let listAvailableOutputs = [];
      if (device && device.type === 'ENDSTATION') {
        listAvailableOutputs = device ? device.availableOutputs.map(i => i.deviceOutputIndexDisplay) : [];
      } else {
        listAvailableOutputs = device ? device.availableOutputs.map(i => i.deviceOutputIndex) : [];
      }
      listAvailableOutputs.sort((a, b) => a - b);
      return listAvailableOutputs;
    },
    sirenOutputTypeSelected() {
      if (this.$v.form.outputType.$model != null) {
        return this.outputTypes.find(o => o.value === this.$v.form.outputType.$model).isSirenOutputType;
      }
      return null;
    },
  },
  watch: {
    // Whenever we close the wizard, the component should be reset
    visible(newVal) {
      if (!newVal) {
        this.$store.dispatch('sirensState/addSiren/reset');
        this.$v.$reset();
      }
    },
  },
  methods: {
    ...mapActions(['add']),
    async close() {
      this.$emit('onClose');
      this.stage = 'init';
    },
    async save() {
      this.$v.$touch();

      await this.add();
      this.$emit('onClose');

      if (!this.navigateToNewItem) {
        return;
      }

      this.$router.push({ name: 'wired-siren', params: { index: this.form.index } });
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

</style>
