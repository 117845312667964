/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
import CryptoJS from 'crypto-js';
import crypto from 'diffie-hellman/browser';
import { API } from '@/app/shared/services/api'; // eslint-disable-line import/no-cycle
import authState, { SECURITY_MODE } from '@/app/shared/state/auth-state';
import encryptionService from '@/app/shared/services/encryption-service';
import logger from '@/app/shared/services/logger';
import { getLimits } from '@/app/shared/constants'; // eslint-disable-line import/no-cycle
import { setIsLoggedIn } from '@/app/login/login.vue';
import { SetisSessionLoggedOut } from '@/app/shared/components/Navigation/MainNavigation/MainNavigation.vue';

const api = new API();
logger.log('shared/services/auth-service');
class AuthService {
  async requestSession() {
    // To do - Create a function to clear all cached values in localStorage
    // Clear the following cached values
    window.localStorage.setItem('panelPageModeForNavigationOnReload', '');
    window.localStorage.setItem('cachedLanModeSettingInProgress', 'false');
    window.localStorage.setItem('cachedLanLostLockStatus', 'false');
    // flag to indicate whether we need to refresh all endpoints when changing Mode to Engineer throughout a session
    // should start as false at the beginning of every session
    window.localStorage.setItem('refreshEndpointsRequired', 'false');
    // flag to indicate whether we need to trigger /CommitConfig on navigation between main pages; only done if LAN has modified the config
    // should start as false at the beginning of every session
    window.localStorage.setItem('commitConfigRequired', 'false');
    authState.reset();
    logger.log('requestSession');
    const requestSessionResponse = await api.get('/Live/Auth/RequestSession');

    authState.sessionId = requestSessionResponse.data.Live.Auth.RequestSession.SessionID;
    window.localStorage.setItem('locallyStoreAuthStateSessionId', JSON.stringify(authState.sessionId));

    if (!requestSessionResponse.data.Live.Auth.RequestSession.Salt && !requestSessionResponse.data.Live.Auth.RequestSession.Challenge) {
      logger.log('Yes, mode=low');
      authState.mode = SECURITY_MODE.LOW;
      authState.dhGenerator = Buffer.from(requestSessionResponse.data.Live.Auth.RequestSession.DHGenerator, 'base64');
      authState.dhPrime = Buffer.from(requestSessionResponse.data.Live.Auth.RequestSession.DHPrime, 'base64');
      authState.dhServerKey = Buffer.from(requestSessionResponse.data.Live.Auth.RequestSession.DHServerKey, 'base64');
      window.localStorage.setItem('locallyStoreAuthStateDhGenerator', JSON.stringify(authState.dhGenerator));
      window.localStorage.setItem('locallyStoreAuthStateDhPrime', JSON.stringify(authState.dhPrime));
      window.localStorage.setItem('locallyStoreAuthStateDhServerKey', JSON.stringify(authState.dhServerKey));
    } else {
      logger.log('No, mode=high');
      authState.mode = SECURITY_MODE.HIGH;
      authState.salt = requestSessionResponse.data.Live.Auth.RequestSession.Salt;
      authState.challenge = requestSessionResponse.data.Live.Auth.RequestSession.Challenge;
      window.localStorage.setItem('locallyStoreAuthStateSalt', JSON.stringify(authState.salt));
      window.localStorage.setItem('locallyStoreAuthStateChallenge', JSON.stringify(authState.challenge));
    }

    return authState.mode;
  }

  _convertUint8ArrayToWordArray(u8Array) {
    const words = [];
    let i = 0;
    const len = u8Array.length;

    while (i < len) {
      words.push(
        (u8Array[i++] << 24)
        | (u8Array[i++] << 16)
        | (u8Array[i++] << 8)
        | (u8Array[i++]),
      );
    }

    return CryptoJS.lib.WordArray.create(words, 32);
  }

  async validateSession(password) {
    if (password == null) {
      return false;
    }
    logger.log('validateSession');
    if (authState.mode === SECURITY_MODE.LOW) {
      await this.requestSession();

      const dh = crypto.createDiffieHellman(authState.dhPrime, authState.dhGenerator);
      dh.generateKeys();
      const clientPublicKey = dh.getPublicKey().toString('base64');
      const secret = dh.computeSecret(authState.dhServerKey);
      const encryptionKey = this._convertUint8ArrayToWordArray(secret);
      const encryptedPassword = encryptionService.encrypt(password, encryptionKey, true);

      try {
        await api.put('/Live/Auth/ValidateSession', `${clientPublicKey}:${encryptedPassword}`);
        authState.encryptionKey = encryptionKey;
        window.localStorage.setItem('locallyStoreEncryptionKey', JSON.stringify(encryptionKey));
        window.localStorage.setItem('locallyStoreAuthStateMode', authState.mode);
        window.localStorage.setItem('locallyStorePassword', password);
        return true;
      } catch {
        authState.reset();
        return false;
      }
    }

    if (authState.mode === SECURITY_MODE.HIGH) {
      authState.encryptionKey = CryptoJS.SHA256(`${password}:${authState.salt}`);
      window.localStorage.setItem('locallyStoreEncryptionKey', JSON.stringify(authState.encryptionKey));
      window.localStorage.setItem('locallyStoreAuthStateMode', authState.mode);
      window.localStorage.setItem('locallyStorePassword', password);
      try {
        await api.put('/Live/Auth/ValidateSession', `${authState.sessionId}:${authState.challenge}`);
        return true;
      } catch {
        authState.reset();
        return false;
      }
    }

    return false;
  }

  async logout() {
    SetisSessionLoggedOut(true);
    window.localStorage.setItem('locallyStoreisSessionLoggedOut', 'true');
    setIsLoggedIn(false);
    // Clear the following cached values - doesn't work reliably when the values are cleared at logout.
    // Thus, the values are also cleared when a new session is created (login).
    window.localStorage.setItem('panelPageModeForNavigationOnReload', '');
    window.localStorage.setItem('cachedLanModeSettingInProgress', 'false');
    window.localStorage.setItem('cachedLanLostLockStatus', 'false');
    // Check the global flag to see if any changes have been PUT to the panel's /Config
    const commitConfigRequired = JSON.parse(window.localStorage.getItem('commitConfigRequired'));
    if (commitConfigRequired) {
      // reset the flag now that the config is being synced
      window.localStorage.setItem('commitConfigRequired', 'false');
      // do a CommitConfig command on the panel to sync up the existing config with the peripherals
      // do it async so it won't wait for the result
      api.put('/Live/CommitConfig', true);
    }
    // For a more uniform stable session, clear the entire cache when the authentication logout happens
    window.localStorage.clear();

    await api.put('/Live/ClientStatus/Mode', 'UNDEFINED');
    await api.put('/Live/Auth/DeleteSession', true);
  }

  // This is meant to handle logout when user closes tab by clicking x, but I believe it's not called anywhere atm...
  logoutOnclose() {
    // Check the global flag to see if any changes have been PUT to the panel's /Config
    const commitConfigRequired = JSON.parse(window.localStorage.getItem('commitConfigRequired'));
    if (commitConfigRequired) {
      // reset the flag now that the config is being synced
      window.localStorage.setItem('commitConfigRequired', 'false');
      // do a CommitConfig command on the panel to sync up the existing config with the peripherals
      // do it async so it won't wait for the result
      api.put('/Live/CommitConfig', true);
    }
    api.put('/Live/ClientStatus/Mode', 'UNDEFINED');
    api.put('/Live/Auth/DeleteSession', true);
    authState.resetMode();
    authState.reset();
  }


  async refreshAuthState() {
    authState.sessionId = JSON.parse(window.localStorage.getItem('locallyStoreAuthStateSessionId'));
    authState.dhGenerator = JSON.parse(window.localStorage.getItem('locallyStoreAuthStateDhGenerator'));
    authState.dhPrime = JSON.parse(window.localStorage.getItem('locallyStoreAuthStateDhPrime'));
    authState.dhServerKey = JSON.parse(window.localStorage.getItem('locallyStoreAuthStateDhServerKey'));
    authState.salt = JSON.parse(window.localStorage.getItem('locallyStoreAuthStateSalt'));
    authState.challenge = JSON.parse(window.localStorage.getItem('locallyStoreAuthStateChallenge'));
    authState.encryptionKey = JSON.parse(window.localStorage.getItem('locallyStoreEncryptionKey'));
    authState.mode = window.localStorage.getItem('locallyStoreAuthStateMode');
    await getLimits();
  }
}

const authService = new AuthService();

export default authService;
