<template>
  <a-form-item :validate-status="'success'"
               :label-col="labelCol"
               :wrapper-col="wrapperCol">
    <template slot="label">
      <a-tooltip v-if="labelTooltip" :title="labelTooltip">
        <label>{{ label }}</label>
      </a-tooltip>
      <label v-else>{{ label }}</label>
    </template>
    <level-selector v-model="model.$model" :areasInfo="this.areasInfo" :areasConfig="this.areasConfig" :disabled="disabled"/>
    <div class="ant-form-explain" v-if="model.$error && !model.required">{{
      $t('common.Field-is-required') }}
    </div>
  </a-form-item>
</template>

<script>
import LevelSelector from '@/app/shared/components/level-selector/level-selector.vue';

export default {
  components: {
    LevelSelector,
  },
  props: ['label', 'labelTooltip', 'model', 'label-col', 'wrapper-col', 'areasInfo', 'areasConfig', 'disabled'],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>
