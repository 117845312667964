import { limits } from '@/app/shared/constants';

export async function getWirelessZEMData(api, wirelessHubIndex) {
  const wirelessHub = await api.get(`/Config/BusDevices/wirelessHubs/${wirelessHubIndex}`);
  return wirelessHub.data.Config.BusDevices.wirelessHubs[wirelessHubIndex];
}

export function getWirelessZEMAddress(index, activeInputs) {
  const start = index * 4;

  const addresses = [];

  for (let i = 0; i < 4; i += 1) {
    if (activeInputs[i]) {
      addresses.push(start + i);
    }
  }

  return addresses;
}

export function getWirelessZEMInfoAddresses(addresses) {
  if (addresses.length < 1) return null;

  const hubIndex = Math.floor(addresses[0] / 4);
  const activeInputs = [false, false, false, false];

  for (let i = 0; i < addresses.length; i += 1) {
    activeInputs[addresses[i] - (hubIndex * 4)] = true;
  }
  return {
    hubIndex: hubIndex >= limits.MAX_WE_ZEM ? null : hubIndex,
    activeInputs,
  };
}
