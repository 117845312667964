<template>
  <div>
    <a-row>
      <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
      <h2 class="page-title">{{ $t('settings.areasOptions.navigationTitle') }}</h2>
    </a-row>
    <loading-indicator v-if="isLoading"/>
    <a-pagination model="areaNumber" :total="MaxAreas" :pageSize="1" show-quick-jumper responsive @change="onAreaChange" :key="tabredraw"/>
    <a-form v-if="!isLoading && (areaNumber > 0)" class="styled-section">
      <h2 class="page-title" :span="24">
        <a-row type="flex" justify="center">
          <a-col :span="6" >
            </a-col>
          <a-col :span="4" >Area
            <a-col type="flex" :push="1" >
              {{ $t(areaNumber) }}
            </a-col>
          </a-col>
          <a-col :span="12" >Levels
            <a-row type="flex" >
              <a-col :flex="1">
                A
              </a-col>
              <a-col :flex="1">
                B
              </a-col>
              <a-col :flex="1">
                C
              </a-col>
              <a-col :flex="1">
                D
              </a-col>
              <a-col :span="12" :pull="1">
                <a-tooltip :title="$t('settings.areasOptions.Copy-area-tooltip')">
                  <a-button @click="confirmCopyArea(areaIndex)" class="float-right" :disabled="!configWriteLock.canWriteConfig">
                    <font-awesome-icon  :icon="['fal', 'copy']" />
                    {{ $t('settings.areasOptions.Copy-area') }}
                  </a-button>
                </a-tooltip>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </h2>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3>{{$t('settings.areasOptions.Area-text')}}</h3>
          </a-col>
          <a-col :span="4">
            <p-input :model="$v.form.areas.$each[areaIndex].areaText" :labelTooltip="$t('settings.areasOptions.Area-text-tooltip')" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-input :model="level.levelText" :labelTooltip="$t('settings.areasOptions.Area-text-tooltip')" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <h3>{{ $t('settings.areasOptions.Timers-and-exit-modes') }}</h3>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Entry-seconds')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-input  :model="level.timersEntry" :labelTooltip="$t('settings.areasOptions.Entry-seconds-tooltip')" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Entry-time-2')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-input :model="level.timersEntry2" :labelTooltip="$t('settings.areasOptions.Entry-time-2-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Exit-seconds')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-input :model="level.timersExit" :labelTooltip="$t('settings.areasOptions.Exit-seconds-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Siren')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-input :model="level.timersSiren" :labelTooltip="$t('settings.areasOptions.Siren-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Strobe')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-input :model="level.timersStrobe" :labelTooltip="$t('settings.areasOptions.Strobe-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Wired-Bell-Set-Squawk')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-select :model="level.wiredBellSetSquawk"
              :items="Object.keys(SQUAWK_SET_UNSET)" :textExpr="(i) => $t(`enums.SQUAWK_SET_UNSET.${i}`)" :valueExpr="(i) => SQUAWK_SET_UNSET[i]" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" :labelTooltip="$t('settings.areasOptions.Wired-Bell-Set-Squawk-tooltip')" >
            </p-select>
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Wired-Bell-Unset-Squawk')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-select :model="level.wiredBellUnsetSquawk"
              :items="Object.keys(SQUAWK_SET_UNSET)" :textExpr="(i) => $t(`enums.SQUAWK_SET_UNSET.${i}`)" :valueExpr="(i) => SQUAWK_SET_UNSET[i]" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" :labelTooltip="$t('settings.areasOptions.Wired-Bell-Unset-Squawk-tooltip')" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Wired-Bell-Set-Strobe')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-select :model="level.wiredBellSetStrobe"
              :items="Object.keys(STROBE_SET_UNSET)" :textExpr="(i) => $t(`enums.STROBE_SET_UNSET.${i}`)" :valueExpr="(i) => STROBE_SET_UNSET[i]" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" :labelTooltip="$t('settings.areasOptions.Wired-Bell-Set-Strobe-tooltip')" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Wired-Bell-Unset-Strobe')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-select :model="level.wiredBellUnsetStrobe"
              :items="Object.keys(STROBE_SET_UNSET)" :textExpr="(i) => $t(`enums.STROBE_SET_UNSET.${i}`)" :valueExpr="(i) => STROBE_SET_UNSET[i]" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" :labelTooltip="$t('settings.areasOptions.Wired-Bell-Unset-Strobe-tooltip')" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Wireless-Bell-Set-Squawk')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
          <p-select :model="level.wirelessBellSetSquawk"
            :items="Object.keys(SQUAWK_SET_UNSET)" :textExpr="(i) => $t(`enums.SQUAWK_SET_UNSET.${i}`)" :valueExpr="(i) => SQUAWK_SET_UNSET[i]" display-style="small-alternative"
            :disabled="!configWriteLock.canWriteConfig" :labelTooltip="$t('settings.areasOptions.Wireless-Bell-Set-Squawk-tooltip')" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Wireless-Bell-Unset-Squawk')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-select :model="level.wirelessBellUnsetSquawk"
              :items="Object.keys(SQUAWK_SET_UNSET)" :textExpr="(i) => $t(`enums.SQUAWK_SET_UNSET.${i}`)" :valueExpr="(i) => SQUAWK_SET_UNSET[i]" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" :labelTooltip="$t('settings.areasOptions.Wireless-Bell-Unset-Squawk-tooltip')" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Wireless-Bell-Set-Strobe')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-select :model="level.wirelessBellSetStrobe"
              :items="Object.keys(STROBE_SET_UNSET)" :textExpr="(i) => $t(`enums.STROBE_SET_UNSET.${i}`)" :valueExpr="(i) => STROBE_SET_UNSET[i]" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" :labelTooltip="$t('settings.areasOptions.Wireless-Bell-Set-Strobe-tooltip')" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Wireless-Bell-Unset-Strobe')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-select :model="level.wirelessBellUnsetStrobe"
              :items="Object.keys(STROBE_SET_UNSET)" :textExpr="(i) => $t(`enums.STROBE_SET_UNSET.${i}`)" :valueExpr="(i) => STROBE_SET_UNSET[i]" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" :labelTooltip="$t('settings.areasOptions.Wireless-Bell-Unset-Strobe-tooltip')" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Speaker-Time-Minutes')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-input :model="level.timersSpeakerTimeMinutes" :labelTooltip="$t('settings.areasOptions.Speaker-Time-Minutes-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Firebell-Time-Minutes')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-input :model="level.timersFirebellTimeMinutes" :labelTooltip="$t('settings.areasOptions.Firebell-Time-Minutes-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Levelbell-Delay')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-input :model="level.timersLevelbell" :labelTooltip="$t('settings.areasOptions.Levelbell-Delay-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.APP-Exit')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-input :model="level.timersAppExit" :labelTooltip="$t('settings.areasOptions.APP-Exit-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Exit-Mode')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-select :model="level.timersExitMode"
              :items="Object.keys(EXIT_MODE)" :textExpr="(i) => $t(`enums.EXIT_MODE.${i}`)" :valueExpr="(i) => EXIT_MODE[i]" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Restriction-timer')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="10" >
            <p-input :labelTooltip="$t('settings.areasOptions.Restriction-timer-tooltip')" :model="$v.form.areas.$each[areaIndex].restrictionTimer"  display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <h3>{{ $t('settings.areasOptions.Volumes') }}</h3>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Entry')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-input :model="level.volumesEntry" :labelTooltip="$t('settings.areasOptions.Entry-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Exit')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index">
            <p-input  :model="level.volumesExit" :labelTooltip="$t('settings.areasOptions.Exit-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.App-exit')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="15" :push="1">
            <p-input  :model="$v.form.areas.$each[areaIndex].appExit" :labelTooltip="$t('settings.areasOptions.App-exit-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Alarm')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="15" :push="1">
            <p-input  :model="$v.form.areas.$each[areaIndex].alarm" :labelTooltip="$t('settings.areasOptions.Alarm-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Fire')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="15" :push="1">
            <p-input  :model="$v.form.areas.$each[areaIndex].fire" :labelTooltip="$t('settings.areasOptions.Fire-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Fault')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="15" :push="1">
            <p-input  :model="$v.form.areas.$each[areaIndex].fault" :labelTooltip="$t('settings.areasOptions.Fault-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.24-hour')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="15" :push="1">
            <p-input  :model="$v.form.areas.$each[areaIndex].twentyFourHour" :labelTooltip="$t('settings.areasOptions.24-hour-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Chime')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="15" :push="1">
            <p-input  :model="$v.form.areas.$each[areaIndex].chime" :labelTooltip="$t('settings.areasOptions.Chime-tooltip')" display-style="small-alternative"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <h3>{{ $t('settings.areasOptions.Responses') }}</h3>
      <br />
      <br />
      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Starts-at')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index" class="fixed-width-level-col">
            <p-select :model="level.responsesStartsAt" :labelTooltip="$t('settings.areasOptions.Starts-at-tooltip')" display-style="small-alternative"
              :items="Object.keys(RESPONSE_LEVEL)" :valueExpr="(i) => RESPONSE_LEVEL[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL.${i}`)"
              :disabled="!configWriteLock.canWriteConfig" />
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />

      <a-input-group size="large">
        <a-row :gutter="8">
          <a-col :span="6" :push="2">
            <h3 >{{$t('settings.areasOptions.Stops-at')}}</h3>
          </a-col>
          <a-col :span="4">
          </a-col>
          <a-col :span="3" v-for="(level, level_index) in areaLevels(areaIndex)" :key="level_index" class="fixed-width-level-col">
            <p-select :model="level.responsesStopsAt" :labelTooltip="$t('settings.areasOptions.Stops-at-tooltip')" display-style="small-alternative"
            :items="Object.keys(RESPONSE_LEVEL_STOP_ALARM)" :valueExpr="(i) => RESPONSE_LEVEL_STOP_ALARM[i]" :textExpr="(i) => $t(`enums.RESPONSE_LEVEL_STOP_ALARM.${i}`)"
            :disabled="!configWriteLock.canWriteConfig"/>
          </a-col>
        </a-row>
      </a-input-group>
      <br />
      <br />
    </a-form>
    <div class="align-right">
      <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
    </div>

    <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
    <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
      @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </div>
</template>


<script>
import { required, maxLength, between } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import Q from 'q';

import {
  RESPONSE_LEVEL, RESPONSE_LEVEL_STOP_ALARM, EXIT_MODE, SQUAWK_SET_UNSET, STROBE_SET_UNSET,
} from '@/app/settings/shared/enums';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import { limits, LEVEL_IDS } from '@/app/shared/constants';
import LoadingIndicator from '@/app/shared/components/loading-indicator/loading-indicator.vue';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';
import { API } from '@/app/shared/services/api';
import {
  recordFetched, recordInvalid, loadArea, cloneArea, saveArea,
} from '@/app/settings/pages/areas-options/areas-options-helpers';


const storeNamespace = 'settingsState/areasOptions';
const { mapState, mapGetters, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    LoadingIndicator,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    const highArea = limits.MAX_AREAS;
    return {
      levels: LEVEL_IDS,
      MaxAreas: limits.MAX_AREAS,
      LastArea: highArea,
      MaxLevels: limits.MAX_LEVELS,
      RESPONSE_LEVEL,
      RESPONSE_LEVEL_STOP_ALARM,
      SQUAWK_SET_UNSET,
      STROBE_SET_UNSET,
      EXIT_MODE,
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
      areaNumber: 1,
      api: new API(),
      tabredraw: 0,
    };
  },
  computed: {
    ...mapState(['form']),
    ...mapGetters(['isLoading']),
    areas() {
      const areas = [];
      // Specify the maximum number of areas to display on each tab page.
      const areasPerTab = 15;
      // Calculate the number of tab pages needed to display ALL areas.
      const areaTabCount = Math.floor((limits.MAX_AREAS + (areasPerTab - 1)) / areasPerTab);

      for (let i = 0; i < areaTabCount; i += 1) {
        const startIndex = i * areasPerTab;
        const endIndex = Math.min(startIndex + areasPerTab, limits.MAX_AREAS);
        areas.push(`${startIndex + 1}-${endIndex}`);
      }
      return areas;
    },
    areaIndex() {
      return (this.areaNumber - 1);
    },
  },
  validations: {
    form: {
      areas: {
        $each: {
          areaText: {
            required,
            maxLength: maxLength(16),
          },
          restrictionTimer: {
            required,
            between: between(0, 30),
          },
          appExit: {
            required,
            between: between(0, 7),
          },
          alarm: {
            required,
            between: between(0, 7),
          },
          fire: {
            required,
            between: between(0, 7),
          },
          fault: {
            required,
            between: between(0, 7),
          },
          twentyFourHour: {
            required,
            between: between(0, 7),
          },
          chime: {
            required,
            between: between(0, 7),
          },
          levels: {
            $each: {
              levelText: {
                required,
                maxLength: maxLength(16),
              },
              timersEntry: {
                required,
                between: between(1, 255),
              },
              timersEntry2: {
                required,
                between: between(1, 255),
              },
              timersExit: {
                required,
                between: between(1, 255),
              },
              timersSiren: {
                required,
                between: between(1, 15),
              },
              timersStrobe: {
                required,
              },
              wiredBellSetSquawk: {
                required,
              },
              wiredBellUnsetSquawk: {
                required,
              },
              wiredBellSetStrobe: {
                required,
              },
              wiredBellUnsetStrobe: {
                required,
              },
              wirelessBellSetSquawk: {
                required,
              },
              wirelessBellUnsetSquawk: {
                required,
              },
              wirelessBellSetStrobe: {
                required,
              },
              wirelessBellUnsetStrobe: {
                required,
              },
              timersSpeakerTimeMinutes: {
                required,
                between: between(0, 99),
              },
              timersFirebellTimeMinutes: {
                required,
              },
              timersLevelbell: {
                required,
              },
              timersAppExit: {
                required,
                between: between(0, 199),
              },
              timersExitMode: {
                required,
                between: between(0, 3),
              },
              volumesEntry: {
                required,
                between: between(0, 7),
              },
              volumesExit: {
                required,
                between: between(0, 7),
              },
              responsesStartsAt: {
                required,
              },
              responsesStopsAt: {
                required,
              },
            },
          },
        },
      },
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      const toSave = [];
      let invalid = false;
      for (let ano = 0; ano < this.MaxAreas; ano += 1) {
        if (recordFetched(this.$v.form.areas.$model, ano)) {
          if (recordInvalid(this.$v.form.areas.$each, ano)) {
            invalid = true;
            break;
          }
          toSave.push(ano);
        }
      }
      if (invalid || (toSave.length === 0)) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();
      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      for (let i = 0; i < toSave.length; i += 1) {
        /* eslint-disable no-await-in-loop */
        await saveArea(this.api, this.$v.form.$model, toSave[i]);
      }
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
      // since we're not doing a traditional save() or PARTIAL_CONFIG_WRITE, set the commitConfigRequired here
      const commitConfigRequired = JSON.parse(window.localStorage.getItem('commitConfigRequired'));
      // if it hasn't been set before, do it now
      if (!commitConfigRequired) {
        window.localStorage.setItem('commitConfigRequired', 'true');
      }
    },
    confirmCopyArea(areaIndex) {
      this.$confirm({
        title: this.$t('settings.areasOptions.copyConfirmation'),
        onOk: this.copyArea(areaIndex),
      });
    },

    copyArea(areaIndex) {
      const calls = [];
      for (let i = 0; i < this.MaxAreas; i += 1) {
        if (i !== areaIndex) {
          calls.push(cloneArea(this.api, this.$v.form.$model, areaIndex, i));
        }
      }
      Q.all(calls).then(() => {
        for (let i = 0; i < this.MaxAreas; i += 1) {
          if (i !== areaIndex) {
            this.$v.form.areas.$each[i].areaText.$model = null; // force reread
          }
        }
        this.$notification.success({
          placement: 'bottomRight',
          message: this.$t('common.ItemHasBeenSaved'),
        });
      });
    },

    areaLevels(areaIndex) {
      const result = [];
      for (let levelIndex = 0; levelIndex < this.MaxLevels; levelIndex += 1) {
        result.push(this.$v.form.areas.$each[areaIndex].levels.$each[levelIndex]);
      }
      return result;
    },

    async onAreaChange(newArea) {
      const newIndex = (newArea - 1);
      if (!recordInvalid(this.$v.form.areas.$each, this.areaNumber - 1)) {
        await loadArea(this.api, this.$v.form.areas.$model, newIndex);
        this.areaNumber = newArea;
      } else {
        this.tabredraw += 1;
      }
    },
  },
};
</script>

<style lang="scss">
  .float-right { float: right; }
  /* By default, Ant Design sets the width of the div with class ant-select, enclosing the combobox, to "auto". This
     makes it resize to the width of the current selection's text - which looks messy (varying widths), and can overflow
     the column width (overlapping). Override the style (which requires lots of class selectors, to take priority) to a
     fixed width. The div with class ant-col has a width of 75%, so a fixed width of 120% takes us back to 90% of the
     overall column width. */
  .fixed-width-level-col.ant-col .ant-select { width: 120%; }
</style>
