/* eslint-disable class-methods-use-this */
import { API } from '@/app/shared/services/api';
import { liveCommsInfoConsts } from '@/app/shared/constants';

const api = new API();

// A count of the pages using modified polling.
let customPollPageCount = 0;
let cachedUrl = '';

class PollHelper {
  // Private incrementor
  _incCustomPollPageCount() {
    customPollPageCount += 1;
  }

  // Private decrementor
  _decCustomPollPageCount() {
    // After the count has been reset, it is legal to attempt to decrement the count
    // (but the value should never go negative)
    if (customPollPageCount > 0) {
      customPollPageCount -= 1;
    }
    return customPollPageCount;
  }

  // Update the polling endpoint with a new URL.
  changeUrlOnTheFlyWithLoader(loader, newUrl, newInterval) {
    loader.changeUrlOnTheFly(liveCommsInfoConsts.key, newUrl, newInterval);
    this.updateCreList(newUrl);
    // Force the new URL to be used.
    loader.reloadEndpoint(liveCommsInfoConsts.key);
    this._incCustomPollPageCount();
  }

  // Revert to default polling of live zoneInfo endpoint, if there are no other pages using custom polling.
  revertCustomPollingIfPossible(loader) {
    const pageCount = this._decCustomPollPageCount();
    if (pageCount === 0) {
      // No other pages using customised polling, so revert back to default values.
      loader.setEndpointIntervalAndUrl(liveCommsInfoConsts.key, liveCommsInfoConsts.defaultInterval, liveCommsInfoConsts.defaultUrl);
      this.updateCreList(liveCommsInfoConsts.defaultUrl);
    }
  }

  // Keep the currentRequiredEndpoints list up-to-date, by removing old (cached) url and adding new url
  updateCreList(newUrl) {
    const temp = window.globalThis.currentRequiredEndpoints;
    if (cachedUrl.length > 0) {
      window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== cachedUrl);
    }
    if (newUrl.length > 0) {
      window.globalThis.currentRequiredEndpoints.push(newUrl);
    }
    cachedUrl = newUrl;
  }
}

const helper = new PollHelper();

export default helper;
export { api };
