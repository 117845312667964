import { API, createArray } from '@/app/shared/services/api';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { getOutputExpanderName } from '@/app/output-expanders/shared/services/helpers';
import { translateOutputType, convertOutputType } from '@/app/outputs/shared/services/helpers';
import i18n from '@/app/shared/services/i18n';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import { toFaultString } from '@/app/shared/utils/activeFaults';
import { limits } from '@/app/shared/constants';

export const api = new API();


function initialState() {
  return {
    installed: undefined,
    max: undefined,
    _items: createArray(limits.MAX_ROX, i => ({ index: i, visible: false })),
  };
}

const { store } = storeFactory(initialState, {
  getters: {
    items: state => state._items.filter(i => i.visible),
  },
  actions: {
    requiredEndpoints() {
      return ['infoOutputInfo', 'configBusDevices', 'liveActiveFaultsOutputExpanders'];
    },
    async populate(context, { endpoints }) {
      context.commit('set', {
        installed: endpoints.infoOutputInfo.data.Info.outputInfo.OutputExpanderInstalledCount,
        max: endpoints.infoOutputInfo.data.Info.outputInfo.OutputExpanderMaxCount,
      });

      const settingsTooltip = i18n.t('outputExpanders.settings.settingsTooltip');
      const cloneTooltip = i18n.t('outputExpanders.settings.cloneTooltip');
      const deleteTooltip = i18n.t('outputExpanders.settings.deleteTooltip');
      for (let i = 0; i < limits.MAX_ROX; i += 1) {
        context.commit('setItemByIndex', {
          collection: '_items',
          index: i,
          item: {
            name: getOutputExpanderName(i),
            location: endpoints.configBusDevices.data.Config.BusDevices.OutputDevices[i].Location,
            address: i,
            availableOutputs: endpoints.configBusDevices.data.Config.BusDevices.OutputDevices[i].Outputs.filter(x => x.OutputType === 0).length,
            outputTypes: endpoints.configBusDevices.data.Config.BusDevices.OutputDevices[i].Outputs
              .filter(x => x.OutputType !== 0)
              .map((x) => {
                const type = convertOutputType(x.OutputType);
                const name = endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes[type].Name;
                return {
                  name,
                  render: translateOutputType(name),
                };
              }),
            maxOutputs: endpoints.configBusDevices.data.Config.BusDevices.OutputDevices[i].Outputs.length,
            visible: endpoints.configBusDevices.data.Config.BusDevices.OutputDevices[i].Installed,
            settingsTooltip,
            cloneTooltip,
            deleteTooltip,
          },
        });
      }
    },
    async onPoll(context, { dataStatus, key, endpoint }) {
      if (key === 'liveActiveFaultsOutputExpanders') {
        const items = [];
        for (let i = 0; i < limits.MAX_ROX; i += 1) {
          const busComms = endpoint.data.Live.ActiveFaults.Devices.OutputExpanders[i].BusComms;
          const caseTamper = endpoint.data.Live.ActiveFaults.Devices.OutputExpanders[i].CaseTamper;
          const powerSupply = endpoint.data.Live.ActiveFaults.Devices.OutputExpanders[i].PowerSupply;
          const battery = endpoint.data.Live.ActiveFaults.Devices.OutputExpanders[i].Battery;
          const mains = endpoint.data.Live.ActiveFaults.Devices.OutputExpanders[i].Mains;
          items.push({ state: toFaultString(battery, busComms, caseTamper, powerSupply, mains) });
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: '_items', items });
      }
    },
    async  delete(context, { indexes }) {
      for (let i = 0; i < indexes.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const objDefaults = await generateDefaultObject(`/Config/BusDevices/OutputDevices/${indexes[i]}`);
        // eslint-disable-next-line no-await-in-loop
        await api.put(`/Config/BusDevices/OutputDevices/${indexes[i]}`, objDefaults);
      }

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('outputExpandersState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('outputExpandersState/addOutputExpander/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['outputExpandersState/list'] });
    },
  },
});

export default store;
