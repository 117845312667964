<template>
    <two-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
          <h1>{{ $t('importExport.navigationTitle') }}</h1>
        </template>

        <a-row>
            <a-col>
                <h2 class="h3">{{ $t('importExport.Import') }}</h2>
                <p>{{ $t('importExport.Import-configuration-from-JSON-file') }}</p>
                <a-upload :fileList="[]" :customRequest="fileUploadRequest">
                <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" :loading="isUploading">{{ $t('importExport.Import-JSON') }}</a-button>
                <div class="load-error" v-if="isUnzipError">{{ $t('importExport.JSON-Unzip-error') }}</div>
                <div class="load-error" v-if="zipFileError">{{ $t('importExport.JSON-zipFileError-error') }}</div>
                </a-upload>
            </a-col>
        </a-row>
        <a-row class="ant-row--spacer">
            <a-col>
                <h2 class="h3">{{ $t('importExport.Export') }}</h2>
                <p>{{ $t('importExport.Export-configuration-from-JSON-file') }}</p>
                <a-button type="primary" :loading="isDownloading" @click="download">{{ $t('importExport.Export-JSON') }}</a-button>
                <a download="config.zip" href="" ref="downloadLink" style="display:none" />
            </a-col>
        </a-row>
        <password-modal :visible="isGettingPassword" :isDownloading="isDownloading" @ok="onPasswordEntered" @cancel="onPasswordCancel" />
        <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
          @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
    </two-col-layout>
</template>

<script>
import { API } from '@/app/shared/services/api';
import {
  ConfigWriteLock, DisplayFaultsGuard,
} from '@/app/shared/mixins';
import PasswordModal from '@/app/import-export/pages/landing/password-modal.vue';
import Minizip from 'minizip-asm.js';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';

const api = new API();
const configFilename = 'config.json';

export default {
  mixins: [ConfigWriteLock, DisplayFaultsGuard],
  components: {
    PasswordModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      isDownloading: false,
      isUploading: false,
      isGettingPassword: false,
      isUnzipError: false,
      zipFileError: false,
      json: undefined,
      text: undefined,
      fileInfo: undefined,
    };
  },
  methods: {
    async download() {
      this.isDownloading = true;

      // Get the config data from the panel and save it compression after getting a password
      const { data } = await api.get('/Config');
      this.text = JSON.stringify(data);

      // Display password modal to get ZIP file password
      this.isGettingPassword = true;
    },
    fileUploadRequest(info) {
      this.isUploading = true;
      this.fileInfo = info;
      // Display password modal to get ZIP file password
      this.isGettingPassword = true;
    },
    configCallback() {
      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('importExport.Importing-please-wait'),
        duration: 5, // Show the notification for five seconds
      });
    },
    onPasswordEntered(password) {
      // Close password modal
      this.isGettingPassword = false;

      if (this.isDownloading) {
        // Compress config data using password
        const mz = new Minizip();
        mz.append(`${configFilename}`, this.text, { password });

        // Encode as base64 and initiate file saving via data URL
        const dataStr = `data:text/plain;base64,${mz.zip().toString('base64')}`;
        this.$refs.downloadLink.href = dataStr;
        this.$refs.downloadLink.click();
        this.isDownloading = false;
      } else if (this.isUploading) {
        return new Promise((resolve) => {
          this.isUnzipError = false;
          this.zipFileError = false;
          const fileReader = new FileReader();
          fileReader.onload = async (e) => {
            const mz = new Minizip(Buffer.from(e.target.result));
            try {
              const jsonString = mz.extract(`${configFilename}`, { password });
              this.json = JSON.parse(jsonString);
              await api.put('/Config', this.json.Config);

              // After successfully putting the new config, inform the base-loader that the config has changed.
              eventHub.$emit(EVENTS.CONFIG_WRITE, { callback: this.configCallback });
              // Wait a few seconds for the refreshed endpoints to load.
              setTimeout(() => {
                this.$notification.success({
                  placement: 'bottomRight',
                  message: this.$t('importExport.Import-successful'),
                });
                this.isUploading = false;
              }, 12000); // Wait 12 seconds.
            } catch (err) {
              if (err.message.includes('error with zipfile in unzOpenCurrentFilePassword')) {
                this.isUnzipError = true;
              } else {
                this.zipFileError = true;
              }
              this.isUploading = false;
            }
            resolve();
          };
          fileReader.readAsArrayBuffer(this.fileInfo.file);
        });
      }
      return null;
    },
    onPasswordCancel() {
      this.isGettingPassword = false;
      this.isUploading = false;
      this.isDownloading = false;
    },
  },
};
</script>

<style lang="scss">
    .ant-row{
        &--spacer {
            margin-top:50px;
        }
    }
    .load-error {
      margin: 3px;
      color: red;
      font-weight: bold;
    }
</style>
