<template>
<div>
    <a-row>
      <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
      <h2 class="page-title">ARC options</h2>
    </a-row>
    <a-form>
      <a-tabs defaultActiveKey="item-0">
        <a-tab-pane v-for="({v, vs}, index) in settings" :key="`item-${index}`">
          <template slot="tab">
            <span :class="{ 'has-error': v.$anyError }">
              {{ $t('communications.arcOptions.ARC-title', { number: parseInt(index, 10) + 1 }) }} <span class="ant-form-explain" v-if="v.$anyError">*</span>
            </span>
          </template>

          <h3>{{ $t('communications.arcOptions.ARC-Settings') }}</h3>
          <p-area-selector :label="$t('communications.arcOptions.Valid-areas')" :model="v.validAreas" :areasInfo="areasInfo" :areasConfig="areasConfig" display-style="small-alternative"
                          :disabled="!configWriteLock.canWriteConfig" />
          <p-switch :label="$t('communications.arcOptions.Area-account')"
                    :model="v.areaAccount"
                    :labelTooltip="$t('communications.arcOptions.Area-account-tooltip')" display-style="small-alternative"
                    :switchTooltip="toggleTooltipText(v.areaAccount)"
                    :disabled="!configWriteLock.canWriteConfig" />

          <div v-if="v.areaAccount.$model === false">
            <p-input :label="$t('communications.arcOptions.ARC-account-number')" :model="v.accountNumber"  display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig"/>
          </div>
          <div v-else>
            <a-collapse accordion>
              <a-collapse-panel :header="$t('communications.arcOptions.Area-account-numbers')">
                <p v-if="v.validAreas.$model.every(a => a === false)">{{ $t('communications.arcOptions.No-valid-areas-selected') }}</p>
                  <div v-for="(area, areaIndex) in areaAccounts(index)" :key="`item-${areaIndex}`">
                    <p-input
                      :label="$t('common.Area')+' '+areasInfo.Areas[areaIndex].Identifier"
                      :model="area.accountNumber"
                      display-style="small-alternative"
                      v-if="v.validAreas.$model[areaIndex] === true"
                      :disabled="!configWriteLock.canWriteConfig" />
                  </div>
              </a-collapse-panel>
            </a-collapse>
          </div>

          <hr class="dashed-bottom" />
          <h3>{{ $t('communications.arcOptions.Path-Configuration') }}</h3>

          <loading-indicator v-if="!commsTypesFullyLoaded"/>

          <a-skeleton :loading="!commsTypesFullyLoaded" :title="false" :paragraph="{ rows:11, width:'100%' }">
            <p-select v-if="isEnipActive(index)"
                      :label="$t('communications.arcOptions.Registration-type')"
                      :labelTooltip="$t('communications.arcOptions.Registration-type-tooltip')"
                      :model="v.registrationType"  display-style="small-alternative"
                      :items="Object.keys(REGISTRATION_TYPE)" :valueExpr="(i) => REGISTRATION_TYPE[i]" :textExpr="(i) => $t(`enums.REGISTRATION_TYPE.${i}`)"
                      :disabled="!configWriteLock.canWriteConfig" />
            <a-collapse accordion v-for="({f, s}, pathIndex) in paths(index)" :key="`path-${pathIndex}`">
              <a-collapse-panel :header="$t('communications.arcOptions.Path-' + (parseInt(pathIndex, 10) + 1))" :key="pathIndex">
                <p-select :label="$t('communications.arcOptions.Comm-type')"
                          :labelTooltip="$t('communications.arcOptions.Comm-type-tooltip')"
                          :model="f.commType"  display-style="small-alternative"
                          :items="availableCommsTypes(f)" :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                          @change="commTypeChanged(f)"
                          :disabled="!configWriteLock.canWriteConfig" />

                <div v-if="f.commType.$model != null && !f.commType.$model.startsWith('MODULE_NONE')">
                  <p-select :label="$t('communications.arcOptions.Send-via')"
                            :labelTooltip="$t('communications.arcOptions.Send-via-tooltip')"
                            :model="f.sendVia"  display-style="small-alternative"
                            :items="senderOptions(f.commType.$model)" :textExpr="(i) => $t(`enums.SEND_VIA.${i}`)"
                            @change="senderChanged(f)"
                            :disabled="!configWriteLock.canWriteConfig"  />

                  <p-select :label="$t('communications.arcOptions.Format')"
                            :labelTooltip="$t('communications.arcOptions.Format-tooltip')"
                            :model="f.format"  display-style="small-alternative"
                            :items="protocolOptions(f.sendVia.$model)"
                            :textExpr="(i) => $t(`enums.FORMAT_TYPES.${i}`)"
                            :disabled="!configWriteLock.canWriteConfig" />

                  <div v-if="f.sendVia.$model === 'Tone' || f.sendVia.$model === 'ModemData' || f.sendVia.$model === 'SMS'">
                    <p-input :label="$t('communications.arcOptions.Phone-number')"
                            :labelTooltip="$t('communications.arcOptions.Phone-number-tooltip')"
                            :model="f.phoneNumber" display-style="small-alternative"
                            :disabled="!configWriteLock.canWriteConfig" />
                  </div>

                  <p-input :label="$t('communications.arcOptions.Retries')"
                          :labelTooltip="$t('communications.arcOptions.Retries-tooltip')"
                          :model="f.retries" display-style="small-alternative"
                          :disabled="!configWriteLock.canWriteConfig" />

                  <p-input
                    v-if="f.sendVia.$model !== 'ENIP'"
                    :label="$t('communications.arcOptions.Timeout')" :model="f.timeout" display-style="small-alternative"
                    :disabled="!configWriteLock.canWriteConfig" />

                  <p-switch v-if="f.format.$model === 'FF6_16_1'"
                            :label="$t('communications.arcOptions.Send-battery-information')"
                            :model="f.sendBatteryInformation"
                            :labelTooltip="$t('communications.arcOptions.Send-battery-information-tooltip')" display-style="small-alternative"
                            :switchTooltip="toggleTooltipText(f.sendBatteryInformation)"
                            :disabled="!configWriteLock.canWriteConfig" />

                  <div v-if="isDC09PathActive(index, pathIndex)">
                    <p-select :label="$t('communications.arcOptions.Supervision')"
                              :labelTooltip="$t('communications.arcOptions.Supervision-tooltip')"
                              :model="f.supervisionOption"  display-style="small-alternative"
                              :items="SupervisionOptionEnums" :textExpr="(i) => $t(`enums.SUPERVISION_OPTIONS.${i}`)"
                              :disabled="!configWriteLock.canWriteConfig"/>
                  </div>
                </div>

                <template v-if="v.registrationType.$model === 'MANUAL' && isEnipPathActive(index, pathIndex)">
                  <template v-if="f.showSignup.$model">
                    <h4 class="h3">{{ $t('communications.arcOptions.ARC-Sign-Up') }}</h4>
                    <p-select :label="$t('communications.arcOptions.Arc-host-option')"
                              :labelTooltip="$t('communications.arcOptions.Arc-host-option-tooltip')"
                              :model="s.hostOption" display-style="small-alternative"
                              :items="Object.keys(HOST_OPTIONS_UK)" :valueExpr="(i) => i" :textExpr="(i) => $t(`enums.HOST_OPTIONS_UK.${i}`)"
                              :disabled="!configWriteLock.canWriteConfig" />
                    <p-input :label="$t('communications.arcOptions.ARC-Host-name')"
                             :labelTooltip="$t('communications.arcOptions.ARC-Host-name-tooltip')"
                             :model="s.arcHostName" display-style="small-alternative"
                             :disabled="!configWriteLock.canWriteConfig"
                             :customValidations="[{ key: 'validArcHost', message: hostError(s.hostOption.$model) }]" />
                    <div v-if="s.hostOption.$model !== 'CSL'">
                      <p-input :label="$t('communications.arcOptions.Sign-up-port')"
                              :labelTooltip="$t('communications.arcOptions.Sign-up-port-tooltip')"
                              :model="s.signupPort" display-style="small-alternative"
                              :disabled="!configWriteLock.canWriteConfig" />
                      <p-select :label="$t('communications.arcOptions.Security')"
                                :labelTooltip="$t('communications.arcOptions.Security-tooltip')"
                                :model="s.signupSecurity" display-style="small-alternative"
                                :items="Object.keys(SECURITY_TYPE)" :valueExpr="(i) => SECURITY_TYPE[i]" :textExpr="(i) => $t(`enums.SECURITY_TYPE.${i}`)"
                                :disabled="!configWriteLock.canWriteConfig" />
                      <div v-if="s.signupSecurity.$model === 'STANDARD'">
                        <p-input :label="$t('communications.arcOptions.ARC-password')"
                                :labelTooltip="$t('communications.arcOptions.ARC-password-tooltip')"
                                :model="s.arcPassword" display-style="small-alternative"
                                :disabled="!configWriteLock.canWriteConfig" />
                      </div>
                      <div v-else-if="s.signupSecurity.$model === 'HIGH'">
                        <p-input :label="$t('communications.arcOptions.Key')" :model="s.arcKey" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig"/>
                        <p-input :label="$t('communications.arcOptions.CRC-key')" :model="s.crcKey" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig"/>
                      </div>
                      <p-input :label="$t('communications.arcOptions.Connection-handle')"
                              :labelTooltip="$t('communications.arcOptions.Connection-handle-tooltip')"
                              :model="s.connectionHandle" display-style="small-alternative"
                              :disabled="!configWriteLock.canWriteConfig" />
                    </div><!-- not CSL -->
                    <div class="align-right">
                        <a-button class="alternative" @click="signUpClick(s, index, pathIndex)"
                          :loading="signupStatus === 'IN_PROGRESS' && whatSignup.arcIndex == index && whatSignup.pathIndex == pathIndex"
                          :disabled="!configWriteLock.canWriteConfig || (signupStatus === 'IN_PROGRESS' && (whatSignup.arcIndex != index ||  whatSignup.pathIndex != pathIndex))">
                          {{ $t('communications.arcOptions.Sign-up') }}
                        </a-button>
                    </div>
                  </template>
                  <div v-else class="align-right">
                    <a-button class="alternative" @click="resignUpClick(f, index, pathIndex)" :disabled="!configWriteLock.canWriteConfig">{{ $t('communications.arcOptions.Resign-up') }}</a-button>
                  </div>
                </template>

                <div v-if="isDC09PathActive(index, pathIndex)">
                  <h4 class="h3">{{ $t('communications.arcOptions.DC09-arc-setup') }}</h4>
                  <p-input :label="$t('communications.arcOptions.ARC-Host-name')" :model="f.arcHost" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig"/>
                  <p-input :label="$t('communications.arcOptions.ARC-port')" :model="f.arcPort" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig"/>
                  <p-select :label="$t('communications.arcOptions.Network-protocol-title')"
                            :labelTooltip="$t('communications.arcOptions.Network-protocol-tooltip')"
                            :model="f.networkProtocol" display-style="small-alternative"
                            :items="NetworkProtocolEnums" :textExpr="(i) => $t(`enums.NETWORK_PROTOCOL.${i}`)"
                            :disabled="!configWriteLock.canWriteConfig"/>
                  <p-select :label="$t('communications.arcOptions.Key-size-title')"
                            :labelTooltip="$t('communications.arcOptions.Key-size-tooltip')"
                            :model="f.keySize" display-style="small-alternative"
                            :items="KeySizeEnums" :textExpr="(i) => $t(`enums.ENCRYPTION.${i}`)"
                            :disabled="!configWriteLock.canWriteConfig"/>
                  <div v-if="f.keySize.$model !== 'UNENCRYPTED'">
                    <p-input :label="$t('communications.arcOptions.Key-1')" :model="f.keypart1" display-style="small-alternative"
                            :disabled="!configWriteLock.canWriteConfig"/>
                    <p-input :label="$t('communications.arcOptions.Key-2')" :model="f.keypart2" display-style="small-alternative"
                            :disabled="!configWriteLock.canWriteConfig"/>
                    <div v-if="f.keySize.$model !== 'AES128'">
                      <p-input :label="$t('communications.arcOptions.Key-3')" :model="f.keypart3" display-style="small-alternative"
                              :disabled="!configWriteLock.canWriteConfig"/>
                      <div v-if="f.keySize.$model !== 'AES192'">
                        <p-input :label="$t('communications.arcOptions.Key-4')" :model="f.keypart4" display-style="small-alternative"
                                :disabled="!configWriteLock.canWriteConfig"/>
                      </div>
                    </div>
                  </div>
                </div>

              </a-collapse-panel>
            </a-collapse>
          </a-skeleton>

          <hr class="dashed-bottom" />
          <div v-if="!isFastFormatActive(index)"> <!-- normal event types -->
            <p-select :label="$t('communications.arcOptions.Event-types')" :model="v.eventTypes"  display-style="small-alternative"
              :items="Object.keys(EVENT_TYPES)" :valueExpr="(i) => EVENT_TYPES[i]" :textExpr="(i) => $t(`enums.EVENT_TYPES.${i}`)"
              :disabled="!configWriteLock.canWriteConfig" />

            <div v-if="v.eventTypes.$model === EVENT_TYPES.CUSTOM">
              <a-collapse accordion>
                <a-collapse-panel :header="$t('communications.arcOptions.Custom-event-types')">
                  <p-select :label="$t('communications.arcOptions.Arm')" :model="v.arm" display-style="small-alternative"
                    :items="ARM_OPTIONS.values" :valueExpr="(i) => i.value" :textExpr="(i) => $t(`enums.ARM_OPTIONS.${i.key}`)"
                    :disabled="!configWriteLock.canWriteConfig" />
                  <p-select :label="$t('communications.arcOptions.Disarm')" :model="v.disarm" display-style="small-alternative"
                    :items="DISARM_OPTIONS.values" :valueExpr="(i) => i.value" :textExpr="(i) => $t(`enums.DISARM_OPTIONS.${i.key}`)"
                    :disabled="!configWriteLock.canWriteConfig" />
                  <p-select :label="$t('communications.arcOptions.Alarms')" :model="v.burglaryAlarm" display-style="small-alternative"
                    :items="BUGLARY_ALARM_OPTIONS.values" :valueExpr="(i) => i.value" :textExpr="(i) => $t(`enums.BUGLARY_ALARM_OPTIONS.${i.key}`)"
                    :disabled="!configWriteLock.canWriteConfig" />

                  <p-switch :label="$t('communications.arcOptions.Bypass')" :model="v.bypass" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Confirmed-alarm')" :model="v.confirmedAlarm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Comms-status')" :model="v.commsStatus" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Technical-fault')" :model="v.technicalFault" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Abort')" :model="v.abort" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Information')" :model="v.information" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Access-alarm')" :model="v.accessAlarm" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Access-event')" :model="v.accessEvent" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Bypass-restore')" :model="v.bypassRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Special-log')" :model="v.specialLog" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />

                  <p-select :label="$t('communications.arcOptions.Tamper-alarms')" :model="v.tamper" display-style="small-alternative"
                    :items="TAMPER_OPTIONS.values" :valueExpr="(i) => i.value" :textExpr="(i) => $t(`enums.TAMPER_OPTIONS.${i.key}`)"
                    :disabled="!configWriteLock.canWriteConfig" />

                  <p-switch :label="$t('communications.arcOptions.Invalid-access-tag')" :model="v.invalidAccessTag" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Walk-test')" :model="v.walkTest" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Restore')" :model="v.restore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Technical-restore')" :model="v.techFaultRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Test')" :model="v.test" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Mains-fail')" :model="v.mainsFail" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Mains-fail-restore')" :model="v.mainsFailRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Arm-fail')" :model="v.armFail" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Engineer-entry')" :model="v.engineerEntry" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                  <p-switch :label="$t('communications.arcOptions.Engineer-exit')" :model="v.engineerExit" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                </a-collapse-panel>
              </a-collapse>
            </div> <!-- if custom event types -->
          </div> <!-- if event types used -->
          <div v-else> <!-- if IsFastFormatActive -->
            <a-collapse accordion>
              <a-collapse-panel :header="$t('communications.arcOptions.Fast-format-output-types')">
                <a-row :gutter="50">
                    <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                      <h4>{{ $t('outputs.Output-type') }}</h4>
                    </a-col>
                    <a-col class="gutter-row" :xs="{ span: 12}" :lg="{ span: 4}" >
                      <h4>{{ $t('communications.arcOptions.Enable') }}</h4>
                    </a-col>
                    <a-col class="gutter-row" :xs="{ span: 12}" :lg="{ span: 4}" >
                      <h4>{{ $t('communications.arcOptions.Restore') }}</h4>
                    </a-col>
                </a-row>

                <div v-for="(ff,i) in v.ffChannels.$each.$iter" :key="`ff-${i}`">
                  <a-row :gutter="50">
                    <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                      <p-select :model="ff.outputTrigger"
                        :items="outputTypes" :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                        :disabled="!configWriteLock.canWriteConfig" style="margin: -20px 20px 0 0;"/>
                    </a-col>
                    <a-col class="gutter-row" :xs="{ span: 12}" :lg="{ span: 4}">
                      <p-switch :labelTooltip="'some'" :model="ff.onEnable" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                    </a-col>
                    <a-col class="gutter-row" :xs="{ span: 12}" :lg="{ span: 4}">
                      <p-switch :labelTooltip="'none'" :model="ff.onRestore" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
                    </a-col>
                  </a-row>
                </div>
              </a-collapse-panel>
            </a-collapse>
          </div> <!-- isFastFormatActive -->

          <p-switch :label="$t('communications.arcOptions.Test-calls')" :labelTooltip="$t('communications.arcOptions.Test-calls-tooltip')" :model="v.testCalls" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig" />
          <div v-if="v.testCalls.$model" class="indent">
            <p-input :label="$t('communications.arcOptions.Test-hours')" :model="v.testHours" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig"/>
            <p-input :label="$t('communications.arcOptions.Test-minutes')" :model="v.testMinutes" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig"/>
            <p-input :label="$t('communications.arcOptions.Interval-days')" :model="v.intervalDays" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig"/>
            <p-input :label="$t('communications.arcOptions.Interval-hours')" :model="v.intervalHours" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig"/>
            <p-input :label="$t('communications.arcOptions.Interval-minutes')" :model="v.intervalMinutes" display-style="small-alternative" :disabled="!configWriteLock.canWriteConfig"/>
            <div class="ant-row ant-form-item"  v-if="v.interval.$invalid">
              <div class="ant-col-sm-12 ant-col-md-8 ant-col-lg-6 ant-col-xl-5 ant-form-item-label"></div>
              <div class="ant-col-sm-24 ant-col-md-18 ant-col-lg-18 ant-col-xl-18 ant-form-item-control-wrapper">
                <div class="ant-form-item-control has-error">
                  <div class="ant-form-explain">{{ $t('communications.arcOptions.Valid-minutes') }}</div>
                </div>
              </div>
            </div>
          </div>

          <template v-if="(v.registrationType.$model === 'AUTOMATIC') && isEnipActive(index)">
            <hr class="dashed-bottom" />
            <template v-if="v.showSignup.$model">
              <h4 class="h3">{{ $t('communications.arcOptions.ARC-Sign-Up') }}</h4>
              <p-select :label="$t('communications.arcOptions.Arc-host-option')"
                        :labelTooltip="$t('communications.arcOptions.Arc-host-option-tooltip')"
                        :model="vs.hostOption" display-style="small-alternative"
                        :items="Object.keys(HOST_OPTIONS_UK)" :valueExpr="(i) => i" :textExpr="(i) => $t(`enums.HOST_OPTIONS_UK.${i}`)"
                        :disabled="!configWriteLock.canWriteConfig" />
              <p-input :label="$t('communications.arcOptions.ARC-Host-name')"
                :labelTooltip="$t('communications.arcOptions.ARC-Host-name-tooltip')"
                :model="vs.arcHostName" display-style="small-alternative"
                :disabled="!configWriteLock.canWriteConfig"
                :customValidations="[{ key: 'validArcHost', message: hostError(vs.hostOption.$model) }]" />
              <p-input v-if="vs.hostOption.$model !== 'CSL'"
                :label="$t('communications.arcOptions.Sign-up-port')" :model="vs.signupPort" display-style="small-alternative"
                :disabled="!configWriteLock.canWriteConfig"/>
              <div class="align-right">
                  <a-button class="alternative" @click="signUpClick(vs, index)"
                    :loading="signupStatus === 'IN_PROGRESS' && whatSignup.arcIndex == index"
                    :disabled="!configWriteLock.canWriteConfig || (signupStatus === 'IN_PROGRESS' && (whatSignup.arcIndex != index))">
                    {{ $t('communications.arcOptions.Sign-up') }}
                  </a-button>
              </div>
            </template>
            <div v-else class="align-right">
              <a-button class="alternative" @click="resignUpClick(vs, index)" :disabled="!configWriteLock.canWriteConfig">{{ $t('communications.arcOptions.Resign-up') }}</a-button>
            </div>
          </template>

        </a-tab-pane>
      </a-tabs>
    </a-form>
    <div class="align-right">
      <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
    </div>

    <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
    <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
      @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />

  </div>
</template>

<script>
import {
  required, maxLength, between, requiredIf,
} from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import { validMinutesValidator } from '@/app/shared/utils/date-helper';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import LoadingIndicator from '@/app/shared/components/loading-indicator/loading-indicator.vue';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import TooltipHelper from '@/app/shared/services/tooltipHelper';
import {
  ARM_OPTIONS, DISARM_OPTIONS, BUGLARY_ALARM_OPTIONS, TAMPER_OPTIONS,
} from '@/app/shared/enums';
import { getCommsInfoARCDetailsPathsEnums } from '@/app/shared/services/schema-helper';
import { EVENT_TYPES } from '@/app/communications/pages/arc-options/arc-options-data';
import { HOST_OPTIONS_UK } from '@/app/communications/shared/enums';
import validArcHost from '@/app/communications/shared/validArcHost';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';
import pollHelper from '@/app/communications/shared/poll-helper';
import { liveCommsInfoConsts } from '@/app/shared/constants';

const storeNamespace = 'communicationsState/arcOptions';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);

// After the arc-options page is rendered, slow the live polling down.
let isLiveSlowPollRequired = true;

const REGISTRATION_TYPE = {
  MANUAL: 'MANUAL',
  AUTOMATIC: 'AUTOMATIC',
};

const SECURITY_TYPE = {
  STANDARD: 'STANDARD',
  HIGH: 'HIGH',
};

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    LoadingIndicator,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
      arcSignupInProgress: true,
      REGISTRATION_TYPE,
      EVENT_TYPES,
      SECURITY_TYPE,
      ARM_OPTIONS,
      DISARM_OPTIONS,
      BUGLARY_ALARM_OPTIONS,
      TAMPER_OPTIONS,
      HOST_OPTIONS_UK,
      SenderEnums: [],
      ProtocolEnums: [],
      SupervisionOptionEnums: [],
      NetworkProtocolEnums: [],
      KeySizeEnums: [],
    };
  },
  computed: {
    ...mapState(['form', 'arcItemSignupForms', 'arcSignupForms', 'commsTypes', 'commsTypesFullyLoaded', 'signupStatus', 'whatSignup',
      'fastFormatEnabled', 'webWayOneEnabled', 'areasInfo', 'areasConfig',
    ]),
    ...mapGetters(['outputTypes']),
    settings() {
      const self = this;
      return Object.keys(this.$v.form.items.$each.$iter).map((v, i) => {
        const a = {
          v: self.$v.form.items.$each.$iter[i],
          vs: self.$v.arcSignupForms.$each.$iter[i],
        };
        return a;
      });
    },
    areaAccounts() {
      return (arcIndex) => {
        const self = this;
        return Object.keys(this.$v.form.items.$each.$iter[arcIndex].areaAccountCodes.$each.$iter).map((v, i) => (
          self.$v.form.items.$each.$iter[arcIndex].areaAccountCodes.$each.$iter[i]
        ));
      };
    },
    paths() {
      return (arcIndex) => {
        const self = this;
        return Object.keys(this.$v.form.items.$each.$iter[arcIndex].paths.$each.$iter).map((v, i) => ({
          f: self.$v.form.items.$each.$iter[arcIndex].paths.$each.$iter[i],
          s: self.$v.arcItemSignupForms.$each.$iter[arcIndex].paths.$each.$iter[i],
        }));
      };
    },
    protocolOptions() {
      return (sender) => {
        // Filter available protocols according to the selected sender type
        if (sender === 'SMS') {
          return this.ProtocolEnums.filter(p => p === 'CID');
        }
        if (sender === 'WebWayOne') {
          return this.ProtocolEnums.filter(p => p === 'SIA3');
        }
        if (sender === 'DC09') {
          return this.ProtocolEnums.filter(p => p === 'CID' || p === 'SIA3');
        }
        // FF6_16_1 is panel feature-controlled
        return this.ProtocolEnums.filter(p => p === 'SIA1' || p === 'SIA3' || p === 'CID' || (p === 'FF6_16_1' && this.fastFormatEnabled));
      };
    },
    senderOptions() {
      return (module) => {
        // Filter available senders according to the selected comms module type
        if (module.startsWith('MODULE_RS232')) {
          // WebWayOne is panel feature-controlled
          return this.SenderEnums.filter(e => (e === 'WebWayOne' && this.webWayOneEnabled) || e === 'PyxSerial');
        }
        if (module.startsWith('MODULE_CELLULAR')) {
          return this.SenderEnums.filter(e => e === 'ENIP' || e === 'DC09' || e === 'SMS');
        }
        if (module.startsWith('MODULE_ETHERNET') || module.startsWith('MODULE_WIFI')) {
          return this.SenderEnums.filter(e => e === 'ENIP' || e === 'DC09');
        }
        return null;
      };
    },
    isFastFormatActive() {
      return (arcIndex) => {
        const self = this;
        return Object.keys(self.$v.form.items.$each.$iter[arcIndex].paths.$each.$iter)
          .map((v, i) => (self.$v.form.items.$each.$iter[arcIndex].paths.$each.$iter[i].format.$model))
          .filter(p => p === 'FF6_16_1')
          .length > 0;
      };
    },
    isEnipActive() {
      return (arcIndex) => {
        const self = this;
        return Object.keys(self.$v.form.items.$each.$iter[arcIndex].paths.$each.$iter)
          .map((v, i) => (self.$v.form.items.$each.$iter[arcIndex].paths.$each.$iter[i]))
          .filter(p => (p.sendVia.$model === 'ENIP') && (!p.commType.$model.startsWith('MODULE_NONE')))
          .length > 0;
      };
    },
    isDC09Active() {
      return (arcIndex) => {
        const self = this;
        return Object.keys(self.$v.form.items.$each.$iter[arcIndex].paths.$each.$iter)
          .map((v, i) => (self.$v.form.items.$each.$iter[arcIndex].paths.$each.$iter[i]))
          .filter(p => (p.sendVia.$model === 'DC09'))
          .length > 0;
      };
    },
    isEnipPathActive() {
      return (arcIndex, pathIndex) => {
        const self = this;
        const path = self.$v.form.items.$each.$iter[arcIndex].paths.$each.$iter[pathIndex];
        return (path.sendVia.$model === 'ENIP') && (!path.commType.$model.startsWith('MODULE_NONE'));
      };
    },
    isDC09PathActive() {
      return (arcIndex, pathIndex) => {
        const self = this;
        const path = self.$v.form.items.$each.$iter[arcIndex].paths.$each.$iter[pathIndex];
        return (path.sendVia.$model === 'DC09');
      };
    },
    hostError() {
      return (s) => {
        if (s === 'CSL') {
          return this.$t('communications.arcOptions.CSL-reg-error');
        }
        if (s === 'IPA') {
          return this.$t('communications.arcOptions.IP-address-error');
        }
        return this.$t('communications.arcOptions.URL-error');
      };
    },
  },
  validations: {

    arcItemSignupForms: {
      $each: {
        paths: {
          $each: {
            hostOption: {
              required,
            },
            arcHostName: {
              required,
              validArcHost: validArcHost(),
            },
            signupPort: {
              required: requiredIf(model => model.hostOption !== 'CSL'),
              between: between(0, 65535),
            },
            signupSecurity: { },
            arcPassword: { }, // required: requiredIf(model => model.signupSecurity === 'STANDARD') },
            arcKey: { }, // required: requiredIf(model => model.signupSecurity === 'HIGH') },
            crcKey: { }, // required: requiredIf(model => model.signupSecurity === 'HIGH') },
            connectionHandle: { },
          },
        },
      },
    },

    arcSignupForms: {
      $each: {
        hostOption: {
          required,
        },
        arcHostName: {
          required,
          validArcHost: validArcHost(),
        },
        signupPort: {
          required: requiredIf(model => model.hostOption !== 'CSL'),
          between: between(0, 65535),
        },
      },
    },

    form: {
      items: {
        $each: {
          areaAccount: {},
          accountNumber: { maxLength: maxLength(9) },
          validAreas: {},
          areaAccountCodes: {
            $each: {
              accountNumber: { maxLength: maxLength(9) },
            },
          },

          paths: {
            $each: {
              commType: {},
              sendVia: {},
              phoneNumber: { maxLength: maxLength(20) },
              retries: { between: between(0, 15) },
              timeout: { between: between(0, 99) },
              format: {},
              sendBatteryInformation: {},
              supervisionOption: {},
              arcHost: {},
              arcPort: {},
              networkProtocol: {},
              keySize: {},
              keypart1: {},
              keypart2: {},
              keypart3: {},
              keypart4: {},

              showSignup: {},
            },
          }, // paths

          eventTypes: { required },
          arm: {},
          disarm: {},
          burglaryAlarm: {},
          bypass: {},
          confirmedAlarm: {},
          commsStatus: {},
          technicalFault: {},
          abort: {},
          information: {},
          accessAlarm: {},
          accessEvent: {},
          bypassRestore: {},
          specialLog: {},
          tamper: {},
          invalidAccessTag: {},
          walkTest: {},
          restore: {},
          techFaultRestore: {},
          test: {},
          mainsFail: {},
          mainsFailRestore: {},
          armFail: {},
          engineerEntry: {},
          engineerExit: {},

          ffChannels: {
            $each: {
              outputTrigger: {},
              onEnable: {},
              onRestore: {},
            },
          },

          registrationType: { required },
          testCalls: { },
          interval: { validMinutes: validMinutesValidator(46079, 'intervalMinutes', 'intervalDays', 'intervalHours') },
          intervalDays: { between: between(0, 31) },
          intervalHours: { between: between(0, 23) },
          intervalMinutes: { between: between(0, 59) },
          testHours: { between: between(0, 23) },
          testMinutes: { between: between(0, 59) },

          showSignup: {},
        },
      },
    },
  },
  methods: {
    availableCommsTypes(pathModel) {
      // RS232 module can only be assigned to one ARC path
      // Check if any ARC path is configured for RS232 comms
      let isRS232Used = false;
      this.form.items.forEach((arc) => {
        arc.paths.forEach((path) => {
          if (path.commType && path.commType.startsWith('MODULE_RS232')) {
            isRS232Used = true;
          }
        });
      });
      // Filter out RS232 option, if it's already in use, except for the path currently configured for it
      return this.commsTypes.filter(type => (pathModel.commType.$model && pathModel.commType.$model.startsWith('MODULE_RS232'))
        || !(type.value.startsWith('MODULE_RS232') && isRS232Used));
    },
    ...mapActions(['save', 'signUp']),
    commTypeChanged(pathModel) {
      // Check currently selected sender is still valid for the changed comms module type
      const senderOptions = this.senderOptions(pathModel.commType.$model);
      if (senderOptions && !senderOptions.find(s => s === pathModel.sendVia.$model)) {
        // Currently selected sender is invalid - select the first available option
        [pathModel.sendVia.$model] = senderOptions;
        this.senderChanged(pathModel);
      }
    },
    senderChanged(pathModel) {
      // Check currently selected protocol is still valid for the changed sender type
      const protocolOptions = this.protocolOptions(pathModel.sendVia.$model);
      if (protocolOptions && !protocolOptions.find(p => p === pathModel.format.$model)) {
        // Currently selected protocol is invalid - select the first available option
        [pathModel.format.$model] = protocolOptions;
      }
    },
    async saveForm() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      this.unsavedGuard.modalVisible = false;

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      try {
        await this.save();
        this.$v.$reset(); // on saving, un-dirty everything so one can navigate away without signing up, even if you've changed the signup details
      } finally {
        NoLeaveWhileSavingGuard.isSavingGuard = false;
        this.isSaving = false;
      }

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
    async signUpClick(v, arcIndex, pathIndex) {
      v.$touch();
      if (v.$invalid) return;

      // make sure arc info has been saved
      if (this.$v.form.$anyDirty) {
        this.saveForm();
        if (this.$v.form.$anyDirty) {
          return;
        }
      }

      // arc info is saved during signup
      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      try {
        const host = v.$model.arcHostName;
        const port = (v.$model.hostOption !== 'CSL') ? v.$model.signupPort : null;
        if (pathIndex != null) { // manual
          const securityType = v.$model.signupSecurity;
          const password = securityType === 'STANDARD' ? v.$model.arcPassword : undefined;
          const key = securityType === 'HIGH' ? v.$model.arcKey : undefined;
          const crcKey = securityType === 'HIGH' ? v.$model.crcKey : undefined;
          const { connectionHandle } = v.$model;

          await this.signUp({
            arcIndex, pathIndex, host, port, securityType, password, key, crcKey, connectionHandle,
          });
        } else { // auto
          await this.signUp({ arcIndex, host, port });
        }
      } finally {
        NoLeaveWhileSavingGuard.isSavingGuard = false;
        this.isSaving = false;
      }

      v.$reset();
    },
    resignUpClick(v, itemIndex, pathIndex) {
      if (pathIndex != null) {
        this.$v.form.$model.items[itemIndex].paths[pathIndex].showSignup = true;
        this.$v.form.$model.items[itemIndex].paths[pathIndex].arcKey = null;
      } else {
        this.$v.form.$model.items[itemIndex].showSignup = true;
        this.$v.form.$model.items[itemIndex].paths[0].arcKey = null;
      }
      v.$reset();
    },
    toggleTooltipText: TooltipHelper.toggleTooltipText,
  },
  async beforeMount() {
    ({
      protocolEnums: this.ProtocolEnums,
      senderEnums: this.SenderEnums,
      supervisionOptionEnums: this.SupervisionOptionEnums,
      networkProtocolEnums: this.NetworkProtocolEnums,
      keySizeEnums: this.KeySizeEnums,
    } = await getCommsInfoARCDetailsPathsEnums());
  },
  updated() {
    if (this.commsTypesFullyLoaded) {
      if (isLiveSlowPollRequired) {
        // The arc-options page is not fully rendered until the commsTypesFullyLoaded flag is set.
        // Since it is now set, this means the landing page has rendered, so the aggressive high-speed
        // poll rate of /Live/commsInfo (which is fast to improve page loading times) can be slowed down.
        isLiveSlowPollRequired = false;
        pollHelper.changeUrlOnTheFlyWithLoader(this.loader.instance, liveCommsInfoConsts.defaultUrl, liveCommsInfoConsts.activeInterval);
      }
    }
  },
  destroyed() {
    // Restore the polling rate ready for re-use.
    pollHelper.revertCustomPollingIfPossible(this.loader.instance);
    isLiveSlowPollRequired = true;
  },
};
</script>
