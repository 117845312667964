<template>
  <a-row :gutter="10" class="sidebar">
    <a-col class="gutter-row" :xs="{ span: 24}" :xxl="{ span: 12, justify: 'bottom'}" v-for="(sideBox, index) in items" :key="`sideBox-${index}`" >
      <count-card :info="sideBox" :isLoading="isLoading" />
    </a-col>
  </a-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import CountCard from '../shared/components/count-card.vue';

const { mapState, mapGetters } = createNamespacedHelpers('panelState/panelCounts');

export default {
  components: {
    CountCard,
  },
  computed: {
    ...mapState(['items']),
    ...mapGetters(['isLoading']),
  },
};
</script>

<style lang="scss">
</style>
