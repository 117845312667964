import Settings from './settings.vue';
import AreasOptions from './pages/areas-options/areas-options.vue';
import DateAndTime from './pages/date-and-time/date-and-time.vue';
import EngineerRestores from './pages/engineer-restores/engineer-restores.vue';
import ArmingOptions from './pages/arming-options/arming-options.vue';
import GeneralDisplays from './pages/general-displays/general-displays.vue';
import GeneralTimers from './pages/general-timers/general-timers.vue';
import GeneralVolumes from './pages/general-volumes/general-volumes.vue';
import GeneralResponses from './pages/general-responses/general-responses.vue';
import AlarmResponses from './pages/alarm-responses/alarm-responses.vue';
import SiteOptions from './pages/site-options/site-options.vue';
import UserSettings from './pages/user-settings/user-settings.vue';
import ZoneSettings from './pages/zone-settings/zone-settings.vue';

const routes = [
  {
    path: '/settings',
    component: Settings,
    children: [
      { path: '', redirect: { name: 'settings.areasOptions' }, name: 'settings.general' },

      { path: 'areas', redirect: { name: 'settings.areasOptions' }, name: 'settings.areas' },
      {
        path: 'areas/areas-options',
        name: 'settings.areasOptions',
        component: AreasOptions,
        meta: { breadcrumb: [{ nameRes: 'settings.navigationTitle' }, { nameRes: 'settings.areas.navigationTitle' }, { nameRes: 'settings.areasOptions.navigationTitle' }] },
      },
      { path: 'global', redirect: { name: 'settings.generalTimers' }, name: 'settings.global' },
      {
        path: 'global/general-timers',
        name: 'settings.generalTimers',
        component: GeneralTimers,
        meta: { breadcrumb: [{ nameRes: 'settings.navigationTitle' }, { nameRes: 'settings.global.navigationTitle' }, { nameRes: 'settings.generalTimers.navigationTitle' }] },
      },
      {
        path: 'global/general-volumes',
        name: 'settings.globalVolume',
        component: GeneralVolumes,
        meta: { breadcrumb: [{ nameRes: 'settings.navigationTitle' }, { nameRes: 'settings.global.navigationTitle' }, { nameRes: 'settings.globalVolume.navigationTitle' }] },
      },
      {
        path: 'global/general-displays',
        name: 'settings.globalDisplay',
        component: GeneralDisplays,
        meta: { breadcrumb: [{ nameRes: 'settings.navigationTitle' }, { nameRes: 'settings.global.navigationTitle' }, { nameRes: 'settings.globalDisplay.navigationTitle' }] },
      },
      {
        path: 'global/date-and-time',
        name: 'settings.dateAndTime',
        component: DateAndTime,
        meta: { breadcrumb: [{ nameRes: 'settings.navigationTitle' }, { nameRes: 'settings.global.navigationTitle' }, { nameRes: 'settings.dateAndTime.navigationTitle' }] },
      },
      {
        path: 'global/arming-options',
        name: 'settings.armingOptions',
        component: ArmingOptions,
        meta: { breadcrumb: [{ nameRes: 'settings.navigationTitle' }, { nameRes: 'settings.global.navigationTitle' }, { nameRes: 'settings.armingOptions.navigationTitle' }] },
      },
      {
        path: 'global/user-settings',
        name: 'settings.userSettings',
        component: UserSettings,
        meta: { breadcrumb: [{ nameRes: 'settings.navigationTitle' }, { nameRes: 'settings.global.navigationTitle' }, { nameRes: 'settings.userSettings.navigationTitle' }] },
      },
      {
        path: 'global/zone-settings',
        name: 'settings.zoneSettings',
        component: ZoneSettings,
        meta: { breadcrumb: [{ nameRes: 'settings.navigationTitle' }, { nameRes: 'settings.global.navigationTitle' }, { nameRes: 'settings.zoneSettings.navigationTitle' }] },
      },

      { path: 'responses', redirect: { name: 'settings.generalResponses' } },
      {
        path: 'responses/general-responses',
        name: 'settings.generalResponses',
        component: GeneralResponses,
        meta: { breadcrumb: [{ nameRes: 'settings.navigationTitle' }, { nameRes: 'settings.responses.navigationTitle' }, { nameRes: 'settings.generalResponses.navigationTitle' }] },
      },
      {
        path: 'responses/alarm-responses',
        name: 'settings.alarmResponses',
        component: AlarmResponses,
        meta: { breadcrumb: [{ nameRes: 'settings.navigationTitle' }, { nameRes: 'settings.responses.navigationTitle' }, { nameRes: 'settings.alarmResponses.navigationTitle' }] },
      },

      { path: 'general', redirect: { name: 'settings.siteOptions' } },
      {
        path: 'general/site-options',
        name: 'settings.siteOptions',
        component: SiteOptions,
        meta: { breadcrumb: [{ nameRes: 'settings.navigationTitle' }, { nameRes: 'settings.general.navigationTitle' }, { nameRes: 'settings.siteOptions.navigationTitle' }] },
      },
      {
        path: 'general/engineer-restores',
        name: 'settings.engineerRestores',
        component: EngineerRestores,
        meta: { breadcrumb: [{ nameRes: 'settings.navigationTitle' }, { nameRes: 'settings.general.navigationTitle' }, { nameRes: 'settings.engineerRestores.navigationTitle' }] },
      },
    ],
  },
];

export default routes;
