<template>
    <two-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
             <h1 id="title">{{ $t('zoneExpanders.navigationTitle') }}</h1>
        </template>
        <template v-slot:titleCommands>
              <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" icon="plus-circle" @click="gridOpenDrawer">{{ $t('zoneExpanders.Add-zone-expander') }}</a-button>
        </template>

        <div>
          <div>
            <a-input-search :placeholder="$t('zoneExpanders.Search-for-a-zone-expander')" size="large" v-model="grid.searchQuery" />

            <a-row :gutter="23" class="header">
              <a-col :xs="{ span: 18}" :md="{ span: 18}" :lg="{ span: 18}" :xl="{ span: 18}" class="filters">
                  <a-dropdown class="grid-filter">
                      <a-menu slot="overlay">
                          <a-menu-item @click="gridDeleteSelected">
                              {{ $t('common.Delete')}}
                          </a-menu-item>
                      </a-menu>
                      <a-button>
                          {{ $t('common.Actions') }} <a-icon type="down" />
                      </a-button>
                  </a-dropdown>
                  <grid-filter :title="$t('common.Status')" :records="allItems" field="state" @change="gridFilterChange"  resPrefix="zoneExpanders.enums.state." :initialFilters="gridSavedFilters('state')" />
                  <grid-filter :title="$t('common.Type')" :records="allItems" field="type" resPrefix="zoneExpanders.enums.deviceType." @change="gridFilterChange" :initialFilters="gridSavedFilters('type')" />
                  <grid-order :options="[{ field: 'name', name:  $t('common.Name')},{ field: 'address', name: $t('common.Address')}]" @change="gridOrderByChange" />
              </a-col>

              <a-col :xs="{ span: 6}" :md="{ span: 6}" :lg="{ span: 6}" :xl="{ span: 6}" class="align-right hide-small">
                <grid-display-toggle :isTabularview="grid.options.isTabularView" @toggle="onGridDisplayToggle" />
              </a-col>
          </a-row>
          <div>
            <ul class="inline-list">
              <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:1, width:'100%' }">
                <li><span>{{ wiredZoneExpandersInstalled }}/{{ wiredZoneExpandersMax }} {{ $t('zoneExpanders.wired-zone-expanders') }}</span></li>
              </a-skeleton>
              <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:1, width:'100%' }">
                <li><span>{{ wirelessZoneExpandersInstalled }}/{{ wirelessZoneExpandersMax }} {{ $t('zoneExpanders.wireless-zone-expanders') }}</span></li>
              </a-skeleton>
            </ul>
          </div>
        </div>
        <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:10, width:'100%' }">
          <grid
            :options="grid.options"
            :records="gridItems">
              <div slot="card-title" slot-scope="scope">
                  <h3>{{ scope.record.name }}</h3>
                  <p class="subtitle">{{ $t('zoneExpanders.availableZones', { available: scope.record.availableZones, max: scope.record.maxZones }) }}</p>
              </div>
          </grid>
        </a-skeleton>
      </div>
    <add-zone-expander :visible="grid.drawerVisible" @onClose="grid.drawerVisible = false" :clone-payload="grid.clonePayload" />
    <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
      @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </two-col-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { isArray } from 'lodash';
import AddZoneExpander from '@/app/zone-expanders/shared/components/add-zone-expander/add-zone-expander.vue';
import {
  ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard,
} from '@/app/shared/mixins';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

const storeNamespace = 'zoneExpandersState/list';
const { mapState, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard],
  components: {
    AddZoneExpander,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      grid: {
        itemsStorePath: `${storeNamespace}/items`,
        deleteItemsStoreAction: `${storeNamespace}/delete`,

        searchFields: ['name', 'location', 'address'],
        orderBy: 'name',
        orderByFunctions: {
          address: address => (isArray(address) ? address[0] : address),
        },

        options: {
          columns: [
            {
              title: this.$t('common.Name'),
              dataIndex: 'name',
              isTitle: true,
            },
            {
              title: this.$t('common.Type'),
              dataIndex: 'type',
              customRender: text => this.$t(`zoneExpanders.enums.deviceType.${text}`),
            },
            {
              title: this.$t('common.Available'),
              dataIndex: 'availableZones',
              isTitle: true,
            },
            {
              title: this.$t('common.Available'),
              dataIndex: 'maxZones',
              isTitle: true,
            },
            {
              title: this.$t('common.Location'),
              dataIndex: 'location',
            },
            {
              title: this.$t('common.Address'),
              dataIndex: 'address',
              customRender: address => (isArray(address) ? address.join(', ') : address),
            },
            {
              title: this.$t('common.Available'),
              dataIndex: 'count',
              isTitle: true,
            },
          ],

          orderByRowStateFirst: false,
          rowState: (record) => { if (record.state != null) { return record.state === 'OK' ? 'active' : 'error'; } return 'loading'; },
          canClone: record => this.configWriteLock.canWriteConfig && record.type === 'TYPE_WIRED',
          canDelete: () => this.configWriteLock.canWriteConfig,

          click: (record) => {
            if (record.type === 'TYPE_WIRED') {
              this.$router.push({ name: 'wired-zone-expander', params: { index: record.hubIndex, type: record.type, configuredZoneList: record.configuredZoneList } });
            } else {
              this.$router.push({ name: 'wireless-zone-expander', params: { index: record.hubIndex, type: record.type } });
            }
          },
          clone: (record) => {
            this.grid.clonePayload = { index: record.hubIndex, type: record.type };
            this.grid.drawerVisible = true;
          },
        },
      },
    };
  },
  computed: {
    ...mapState(['wiredZoneExpandersInstalled', 'wiredZoneExpandersMax', 'wirelessZoneExpandersInstalled', 'wirelessZoneExpandersMax']),
    ...mapGetters(['isLoading', 'items']),
  },
};
</script>

<style lang="scss">
</style>
