import { API } from '@/app/shared/services/api';

const api = new API();

function dropPolledEndpoint(polledEndpoint) {
  // Drop arc-comms test specific endpoints from the current context
  const temp = window.globalThis.currentRequiredEndpoints;
  try {
    window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== polledEndpoint);
  } catch (err) {
    // nothing to do
  }
}

async function test(index) {
  // Command the start of test calls to ARC [index].
  const arcEndpoint = `/Live/Diagnostics/ArcTest/${index}/state`;
  await api.put(arcEndpoint, true);

  let externalPromiseResolver;
  const promise = new Promise((resolve) => {
    externalPromiseResolver = resolve;
  });

  // Bring the ad-hoc arc-comms test endpoint into the scope of current endpoints
  if (window.globalThis.currentRequiredEndpoints.indexOf(arcEndpoint) === -1) {
    window.globalThis.currentRequiredEndpoints.push(arcEndpoint);
  }

  const pollingCall = await api.poll(5000, arcEndpoint, (res) => {
    if (res.data.Live.Diagnostics.ArcTest[index].state === false) {
      externalPromiseResolver();
      dropPolledEndpoint(arcEndpoint);
      return false;
    }
    return true;
  });

  // Await the completion of test calls to ARC [index].
  await promise;
  pollingCall.polling.cancel();
  dropPolledEndpoint(arcEndpoint);

  // Read the test call result for each path of the ARC.
  const response = await api.get(`/Live/Diagnostics/ArcResult/${index}`);

  return response.data.Live.Diagnostics.ArcResult[index];
}

async function end() {
  api.cancelPolling();
  dropPolledEndpoint(`/Live/Diagnostics/ArcTest/${0}/state`);
  dropPolledEndpoint(`/Live/Diagnostics/ArcTest/${1}/state`);
  dropPolledEndpoint(`/Live/Diagnostics/ArcTest/${2}/state`);
  dropPolledEndpoint(`/Live/Diagnostics/ArcTest/${3}/state`);
  await api.put(`/Live/Diagnostics/ArcTest/${0}/state`, false);
  await api.put(`/Live/Diagnostics/ArcTest/${1}/state`, false);
  await api.put(`/Live/Diagnostics/ArcTest/${2}/state`, false);
  await api.put(`/Live/Diagnostics/ArcTest/${3}/state`, false);
}


export default { test, end };
