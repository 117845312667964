import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';

function initialState() {
  return {
    form: {
      prefixTelephoneNumber: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configCommsInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        prefixTelephoneNumber: endpoints.configCommsInfo.data.Config.commsInfo.TelPrefixNumber,
      });
    },
    async save(context) {
      await api.put('/Config/commsInfo/TelPrefixNumber', context.state.form.prefixTelephoneNumber);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('communicationsState/advancedOptions/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['communicationsState/advancedOptions'] });
    },
  },
});


export default store;
