<template>
   <three-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
            <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
                <h1 id="title">{{ name }}</h1>
            </a-skeleton>
        </template>
        <template v-slot:titleCommands>
            <item-menu
              @save="saveForm" :isSaving="isSaving"
              :canDelete="false"
              :disabled="!configWriteLock.canWriteConfig" />
        </template>

        <form>
          <div class="styled-section">
            <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
              <div>
                <h3 class="h4">{{ $t('logicGates.Logic-gate-settings') }}</h3>
                <a-row :gutter="50">
                  <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                    <p-select :label="$t('logicGates.Gate-type')" :model="$v.form.gateType"
                    :items="LOGIC_GATE_TYPE.values" :valueExpr="(i) => i.value"
                    :textExpr="(i) => $t(`logicGates.enums.gateType.${i.key}`)"
                    :disabled="!configWriteLock.canWriteConfig" />
                  </a-col>

                  <!-- label is e.g. 'Trigger 4' -->
                  <div v-for="(trigger,index) in $v.form.inputTriggers.$each.$iter" :key="`trigger-${index}`">
                    <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                      <p-select :model="trigger.triggerType"
                        :label="$t(`logicGates.Input-trigger-number`, { number: trigger.triggerNumber.$model })"
                        :items="outputTypes" :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                        :disabled="!configWriteLock.canWriteConfig"/>
                    </a-col>
                  </div>
                </a-row>
              </div>
            </a-skeleton>
          </div>

        </form>
        <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
          @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
   </three-col-layout>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import ItemMenu from '@/app/shared/components/item-menu/item-menu.vue';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import { LOGIC_GATE_TYPE, USER_DEFINED_OUTPUT_TYPE } from '@/app/outputs/shared/enums';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

const storeNamespace = 'outputsState/logicGate';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, UnsavedGuard, StateLoader, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    ItemMenu,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
      USER_DEFINED_OUTPUT_TYPE,
      LOGIC_GATE_TYPE,
    };
  },
  validations: {
    form: {
      gateType: {
        required,
      },
      inputTriggers: {
        $each: {
          triggerType: { required },
          triggerNumber: {},
        },
      },
    },
  },
  computed: {
    ...mapState(['form', 'name', 'associatedOutputs']),
    ...mapGetters(['isLoading', 'outputTypes']),
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
};
</script>

<style lang="scss">

</style>
