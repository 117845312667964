var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{attrs:{"placement":"right","closable":false,"destroyOnClose":true,"visible":_vm.visible},on:{"close":_vm.onClose}},[_c('wizard',{attrs:{"config":_vm.wizard,"state":_vm.$v,"initialStep":_vm.clonePayload != null ? 'manual' : 'start'},on:{"save":_vm.save,"done":_vm.onClose}},[_c('wizard-step',{attrs:{"name":"start"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var wizard = ref.wizard;
return [_c('a-button',{staticClass:"draw-link",attrs:{"type":"link","block":""},on:{"click":function($event){return wizard.navigateTo('learn')}}},[_c('text-row',{attrs:{"title":_vm.$t('users.drawer.Learn-fob'),"title-icon":"users"}})],1),_c('a-button',{staticClass:"draw-link",attrs:{"type":"link","block":""},on:{"click":function($event){return wizard.navigateTo('manual')}}},[_c('text-row',{attrs:{"title":_vm.$t('common.Add-manually'),"title-icon":"plus-circle"}})],1)]}}])}),_c('wizard-step',{attrs:{"name":"learn"}},[_c('learn-wireless',{attrs:{"start-at":{ stage: 'info', type: 'keyfobs' }},on:{"done":_vm.onClose}})],1),_c('wizard-step',{attrs:{"name":"manual"}},[_c('a-form',[_c('p-input',{attrs:{"label":_vm.$t('common.Name'),"model":_vm.$v.form.name,"label-col":{ span: 7 },"wrapper-col":{ span: 17 }}}),_c('p-select',{attrs:{"label":_vm.$t('users.Access-type'),"model":_vm.$v.form.accessType,"items":_vm.ACCESS_TYPE.values.filter(function (t) { return t.key !== 'KEY_FOB'; }),"valueExpr":function (i) { return i.value; },"textExpr":function (i) { return _vm.$t(("users.enums.ACCESS_TYPE." + (i.value))); },"label-col":{ span: 7 },"wrapper-col":{ span: 17 }}}),(_vm.$v.form.accessType.$model === 'USER_CODE')?_c('p-password',{attrs:{"label":_vm.$t('users.Code'),"model":_vm.$v.form.code,"label-col":{ span: 7 },"wrapper-col":{ span: 17 },"customValidations":[
          { key: 'notReservedCode', message: _vm.$t('users.Using-reserved-code') },
          { key: 'validCodeLength', message: _vm.$t('users.Code-length-incorrect') },
          { key: 'validCode', message: _vm.$t('users.Code-invalid') },
          { key: 'noCodeOverlap', message: _vm.$t('users.Code-already-used') }
        ]}}):_c('p-input',{attrs:{"label":_vm.$t('users.Code'),"model":_vm.$v.form.code,"label-col":{ span: 7 },"wrapper-col":{ span: 17 },"customValidations":[
          { key: 'validTag', message: _vm.$t('users.Tag-invalid') },
          { key: 'validTagLength', message: _vm.$t('users.Tag-length-incorrect', { len: _vm.TAG_LENGTH }) },
          { key: 'noCodeOverlap', message: _vm.$t('users.Code-already-used') }
        ]}}),_c('p-select',{attrs:{"label":_vm.$t('users.User-type'),"model":_vm.$v.form.userType,"items":_vm.USER_TYPE.values.filter( function (type) { return type.key != 'ENGINEER' && type.key != 'MASTERMAN'}),"valueExpr":function (i) { return i.value; },"textExpr":function (i) { return _vm.$t(("users.enums.USER_TYPE." + (i.value))); },"label-col":{ span: 7 },"wrapper-col":{ span: 17 }}}),_c('p-area-selector',{attrs:{"label":_vm.$t('common.Areas'),"model":_vm.$v.form.areas,"areasInfo":_vm.areasInfo,"areasConfig":_vm.areasConfig,"label-col":{ span: 7 },"wrapper-col":{ span: 17 }}}),_c('p-select',{attrs:{"label":_vm.$t('users.Set-option'),"model":_vm.$v.form.setOption,"items":_vm.SET_OPTIONS.values,"valueExpr":function (i) { return i.value; },"textExpr":function (i) { return _vm.$t(("users.enums.SET_OPTIONS." + (i.value))); },"label-col":{ span: 7 },"wrapper-col":{ span: 17 }}}),_c('p-select',{attrs:{"label":_vm.$t('common.Number'),"model":_vm.$v.form.index,"items":_vm.numbers,"valueExpr":function (i) { return i.index; },"textExpr":function (i) { return i.number; },"label-col":{ span: 7 },"wrapper-col":{ span: 17 }}}),_c('p-switch',{attrs:{"label":_vm.$t('users.Flexi-set'),"model":_vm.$v.form.flexiSet,"switchTooltip":_vm.toggleTooltipText(_vm.$v.form.flexiSet),"label-col":{ span: 7 },"wrapper-col":{ span: 17 }}})],1)],1),_c('wizard-step',{attrs:{"name":"final"}},[_c('a-form',[_c('p-item-selector',{attrs:{"label":_vm.$t('users.Door'),"items":_vm.doors,"model":_vm.$v.form.associatedDoors,"label-col":{ span: 7 },"wrapper-col":{ span: 17 }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }