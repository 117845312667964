import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';


function initialState() {
  return {
    outputTypeNotUsed: undefined,
    outputTypeFire: undefined,

    form: {
      fobUnsetEntry: undefined,
      downloadIfSet: undefined,
      invertATEOutputs: undefined,
      wirelessFireInterlink: undefined,
      batteryLoadTest: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configSysInfo', 'configBusDevices', 'infoFeatures', 'infoOutputInfo'];
    },
    async populate(context, { endpoints }) {
      // Is the "WirelessFireInterlink" feature enabled?
      const features = endpoints.infoFeatures.data.Info.Features;
      const enableWirelessFireInterlink = features.Menu !== undefined && features.Menu.WirelessFireInterlink === true;

      // Get some output type numbers.
      context.commit('set', {
        outputTypeNotUsed: endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes.findIndex(x => x.Name === 'NOT_USED'),
        outputTypeFire: endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes.findIndex(x => x.Name === 'FIRE'),
      });

      context.commit('setForm', {
        fobUnsetEntry: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.FobUnsetEntryOnly,
        downloadIfSet: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.AllowRemoteConfigWhileSet,
        invertATEOutputs: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.InvertAteOutputs,
        batteryLoadTest: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.DoBattLoadTest,
        // Map the configured type for the (first) radio hub's 3rd output to the switch state:
        // - FIRE          --> true (on)
        // - anything else --> false (off)
        // Use undefined to hide the switch entirely.
        wirelessFireInterlink: enableWirelessFireInterlink
          ? endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[0].OutputType[2] === context.state.outputTypeFire
          : undefined,
      });
    },
    async save(context) {
      const data = {
        FobUnsetEntryOnly: context.state.form.fobUnsetEntry,
        AllowRemoteConfigWhileSet: context.state.form.downloadIfSet,
        InvertAteOutputs: context.state.form.invertATEOutputs,
        DoBattLoadTest: context.state.form.batteryLoadTest,
      };

      await api.put('/Config/sysInfo/SiteOptions', data);

      // If the "WirelessFireInterlink" feature is enabled...
      if (context.state.form.wirelessFireInterlink !== undefined) {
        // Map the switch state to an output type:
        // - true (on)   --> FIRE
        // - false (off) --> NOT_USED
        const outputType = context.state.form.wirelessFireInterlink ? context.state.outputTypeFire : context.state.outputTypeNotUsed;
        // Configure the (first) radio hub's 3rd output.
        await api.put('/Config/BusDevices/wirelessHubs/0/OutputType/2', outputType);
      }

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('settingsState/siteOptions/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['settingsState/siteOptions'] });
    },
  },
});


export default store;
