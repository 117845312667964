import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';


function initialState() {
  return {
    soundAtKeypadsOnlyVisible: false,

    form: {
      codeStopSound: undefined,
      soundAtKeypadsOnly: undefined,
      silentTechAlert: undefined,
      disableCallFault: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configSysInfo', 'infoFeatures'];
    },
    async populate(context, { endpoints }) {
      const features = endpoints.infoFeatures.data.Info.Features;

      context.commit('set', {
        soundAtKeypadsOnlyVisible: (features.Menu !== undefined) && (features.Menu.EESoundAtKeypadsOnly === true),
      });

      context.commit('setForm', {
        codeStopSound: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.ValidCodeStopsSound,
        soundAtKeypadsOnly: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.EntryExitSoundAtKeypadsOnly,
        silentTechAlert: endpoints.configSysInfo.data.Config.sysInfo.MiscOptions.EnableSilentTechAlert,
        disableCallFault: endpoints.configSysInfo.data.Config.sysInfo.MiscOptions.ARCCallFailQuiet,
      });
    },
    async save(context) {
      const data = {
        SiteOptions: {
          ValidCodeStopsSound: context.state.form.codeStopSound,
          EntryExitSoundAtKeypadsOnly: context.state.form.soundAtKeypadsOnly,
        },
        MiscOptions: {
          EnableSilentTechAlert: context.state.form.silentTechAlert,
          ARCCallFailQuiet: context.state.form.disableCallFault,
        },
      };

      await api.put('/Config/sysInfo', data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('settingsState/globalVolume/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['settingsState/globalVolume'] });
    },
  },
});


export default store;
