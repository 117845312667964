<template>
    <a-drawer
    placement="right"
    :closable="false"
    @close="onClose"
    :destroyOnClose="true"
    :visible="visible">
      <wizard :config="wizard" :state="$v" @save="save" @done="onClose" :initialStep="clonePayload != null ? 'manual' : 'start'">
          <wizard-step name="start">
            <template slot="body" slot-scope="{ wizard }">
              <a-button class="draw-link" @click="wizard.navigateTo('autoscan')" type="link" block>
                <text-row :title="$t('zoneExpanders.drawer.Autoscan-for-zone-expanders-wired-only')" title-icon="search" />
              </a-button>
              <a-button class="draw-link" @click="wizard.navigateTo('manual')" type="link" block>
                <text-row :title="$t('common.Add-manually')" title-icon="plus-circle" />
              </a-button>
            </template>
          </wizard-step>

        <wizard-step name="autoscan">
          <autoscan @done="onClose" @restart="onClose" :keypads="false" :readers="false" :zoneExpanders="true" :outputExpanders="false" />
        </wizard-step>

          <wizard-step name="manual">
            <a-form>
              <p-select :label="$t('common.Type')" :model="$v.form.type" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
                :items="[{ name: $t('common.Wired'), value: 'TYPE_WIRED' }, { name: $t('common.Wireless'), value: 'TYPE_WIRELESS' }]" :valueExpr="(i) => i.value" :textExpr="(i) => i.name" />
              <p-input :label="$t('common.Location')" :model="$v.form.location" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
              <p-select :label="$t('common.Address')" :model="$v.form.address" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
                :items="addresses" :valueExpr="(i) => i" :textExpr="(i) => i" />
            </a-form>
          </wizard-step>
      </wizard>
    </a-drawer>
</template>
<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';
import Autoscan from '@/app/shared/components/add-devices-drawer/autoscan.vue';
import { limits } from '@/app/shared/constants';
import { Clone } from '@/app/shared/mixins';

const storeNamespace = 'zoneExpandersState/addZoneExpander';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [Clone],
  components: {
    Wizard,
    WizardStep,
    Autoscan,
  },
  data() {
    return {
      clone: {
        storeNamespace,
      },
      wizard: {
        start: {
          title: this.$t('zoneExpanders.drawer.How-do-you-want-to-add-zone-expanders'),
        },
        autoscan: {
          mode: 'SIMPLE',
        },
        manual: {
          title: this.$t('zoneExpanders.drawer.Add-a-zone-expander'),
          next: [
            { key: 'save', buttonText: this.$t('common.Add-device') },
          ],
        },
      },
    };
  },
  validations: {
    form: {
      type: {
        required,
      },
      location: {
        maxLength: maxLength(16),
      },
      address: {
        required,
      },
    },
  },
  computed: {
    ...mapFields(storeNamespace, ['form']),
    ...mapState(['availableAddresses', 'usedWirelessDeviceAddresses']),
    addresses() {
      if (this.$v.form.type.$model === 'TYPE_WIRED') {
        return this.availableAddresses;
      }
      if (this.$v.form.type.$model === 'TYPE_WIRELESS') {
        const addresses = [];
        const NUM_WE_PER_HUB = 4;
        const maxWirelessZemAddress = limits.MAX_WE_ZEM * NUM_WE_PER_HUB;
        const wePerHubAddressUpperLimit = [];
        // Get we (wireless zem) per hub upper limit address - each hub can have 4 addresses.
        const idx = maxWirelessZemAddress / NUM_WE_PER_HUB;
        for (let i = 0; i < idx; i += 1) {
          wePerHubAddressUpperLimit.push((i + 1) * NUM_WE_PER_HUB - 1);
        }
        const hub1UpperLimit = wePerHubAddressUpperLimit[0];
        const hub2UpperLimit = wePerHubAddressUpperLimit[1];
        const hub3UpperLimit = wePerHubAddressUpperLimit[2];

        const hub1 = this.availableAddresses.filter(i => i <= hub1UpperLimit && this.usedWirelessDeviceAddresses.indexOf(i) === -1); // 0,1,2,3
        if (hub1.length > 1) addresses.push(hub1.join(', '));

        const hub2 = this.availableAddresses.filter(i => i > hub1UpperLimit && i <= hub2UpperLimit && this.usedWirelessDeviceAddresses.indexOf(i) === -1); // 4,5,6,7
        if (hub2.length > 1) addresses.push(hub2.join(', '));

        const hub3 = this.availableAddresses.filter(i => i > hub2UpperLimit && i <= hub3UpperLimit && this.usedWirelessDeviceAddresses.indexOf(i) === -1); // 8,9,10,11
        if (hub3.length > 1) addresses.push(hub3.join(', '));

        // add individual address - max 12 addresses for wireless ZEMs (max 3 hubs)
        for (let i = 0; i < maxWirelessZemAddress; i += 1) {
          // If address is not already assigned to a wireless ZEM, add it to available addresses for wireless ZEMs.
          if (this.usedWirelessDeviceAddresses.indexOf(i) === -1) {
            addresses.push(i);
          }
        }

        return addresses;
      }
      return [];
    },
  },
  watch: {
    // Whenever we close the wizard, the component should be reset
    visible(newVal) {
      if (!newVal) {
        this.$store.dispatch('zoneExpandersState/addZoneExpander/reset');
        this.$v.$reset();
      }
    },
  },
  methods: {
    ...mapActions(['add']),
    async onClose() {
      this.$emit('onClose');
    },
    async save() {
      await this.add();
      this.$emit('onClose');

      if (!this.navigateToNewItem) {
        return;
      }

      this.$router.push({ name: 'wired-zone-expander', params: { index: this.form.address } });
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

</style>
