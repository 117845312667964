<template>
  <div>
    <img src="@/assets/images/wizard-images/ui-graphics_learn-zones.png" alt="" />
    <p><strong>{{ $t('addDevicesDrawer.wireless.zones.InstructionsHeader') }}</strong></p>
    <ol>
      <li>{{ $t('addDevicesDrawer.wireless.zones.Step1') }}</li>
      <li>{{ $t('addDevicesDrawer.wireless.zones.Step2') }}</li>
      <li>{{ $t('addDevicesDrawer.wireless.zones.Step3') }}</li>
      <li>{{ $t('addDevicesDrawer.wireless.zones.Step4') }}</li>
      <li>{{ $t('addDevicesDrawer.wireless.zones.Step5') }}</li>
    </ol>
    <ul>
      <li>{{ $t('addDevicesDrawer.wireless.zones.FurtherDescription1') }}</li>
      <li>{{ $t('addDevicesDrawer.wireless.zones.FurtherDescription2') }}</li>
    </ul>
    <p>{{ $t('addDevicesDrawer.wireless.zones.FurtherHelp') }}</p>
  </div>
</template>

<script>
export default {
  name: 'StepZone',
};
</script>

<style scoped>
  ol { padding-left: 1em; }
  ul { list-style: none; padding-left: 0; }
</style>
