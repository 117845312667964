<template>
  <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{ name: 'panel' }" >
            <font-awesome-icon  :icon="['fal', 'home']" />
          </router-link>
      </a-breadcrumb-item>
      <template v-for="(item, index) in breadcrumb" >
        <a-breadcrumb-item  @click="routeTo(item.link)" v-bind:key="`breadcrumb-${index}`">
          <span v-if="breadcrumb.indexOf(item) === breadcrumb.length - 1" class="current">
           {{ $t(item.nameRes) }}
          </span>
          <router-link v-else :to="{ name: item.link }" >
            {{ $t(item.nameRes) }}
          </router-link>
        </a-breadcrumb-item>
      </template>
  </a-breadcrumb>
</template>

<script>
export default {
  name: 'breadcrumb',
  computed: {
    breadcrumb() {
      // Clear endpoints when navigating between sub-pages
      window.globalThis.currentRequiredEndpoints = [];
      return this.$route.meta.breadcrumb;
    },
  },
};
</script>

<style scoped>
    @import 'breadcrumb.scss';
</style>
