import { createArray } from '@/app/shared/services/api';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { trim } from '@/app/shared/utils/text-helper';

function initialState() {
  return {
    form: {
      networkDelayFault: undefined,
      items: createArray(2, () => ({
        apn: undefined,
        purpose: undefined,
        username: undefined,
        password: undefined,
        enableAutoDNS: undefined,
        dnsIPAddress: undefined,
        otherDnsIPAddress: undefined,
      })),
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configCommsInfo', 'configSysInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        networkDelayFault: endpoints.configSysInfo.data.Config.sysInfo.Timers.LineFaultTimeCellular_mins,
        items: endpoints.configCommsInfo.data.Config.commsInfo.Cellular.slice(1, 3).map(c => ({
          apn: c.APN,
          purpose: c.Purpose,
          username: c.UserName,
          password: c.Password,
          enableAutoDNS: c.AutoDNS,
          dnsIPAddress: trim(c.DNS),
          otherDnsIPAddress: trim(c.OtherDNS),
        })),
      });
    },
    async save(context) {
      const items = [
        null,
        {
          APN: context.state.form.items[0].apn,
          Purpose: context.state.form.items[0].purpose,
          UserName: context.state.form.items[0].username,
          Password: context.state.form.items[0].password,
          AutoDNS: context.state.form.items[0].enableAutoDNS,
          DNS: context.state.form.items[0].dnsIPAddress,
          OtherDNS: context.state.form.items[0].otherDnsIPAddress,
        },
        {
          APN: context.state.form.items[1].apn,
          Purpose: context.state.form.items[1].purpose,
          UserName: context.state.form.items[1].username,
          Password: context.state.form.items[1].password,
          AutoDNS: context.state.form.items[1].enableAutoDNS,
          DNS: context.state.form.items[1].dnsIPAddress,
          OtherDNS: context.state.form.items[1].otherDnsIPAddress,
        },
      ];

      await api.put('/Config/sysInfo/Timers/LineFaultTimeCellular_mins', parseInt(context.state.form.networkDelayFault, 10));
      await api.put('/Config/commsInfo/Cellular', items);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('communicationsState/cellular/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['communicationsState/cellular'] });
    },
  },
});


export default store;
