import { storeFactory } from '@/app/shared/services/store-helper';

const PAGE_SIZE = 25;

function initialState() {
  return {
    page: 1,
    moreItems: true,
    dataFetched: true,
    _items: [],
  };
}

const { store } = storeFactory(initialState, {
  getters: {
    items: state => state._items,
    requiredRequests: state => [
      { key: 'timeZoneIndex', url: '/Config/sysInfo/MiscOptions/TimeZoneIndex', params: { } },
      { key: 'cloudLogs', url: '/Log/Cloud', params: { skip: ((state.page - 1) * PAGE_SIZE), count: PAGE_SIZE, refetch: false } },
    ],
  },
  actions: {
    async populate(context, { requests }) {
      context.commit('set', { timeZoneIndex: requests.timeZoneIndex.data.Config.sysInfo.MiscOptions.TimeZoneIndex });
      if (context.state.page === 1) {
        context.commit('set', { _items: [] });
      }
      if (requests.cloudLogs.data.Log != null) {
        const items = requests.cloudLogs.data.Log.Cloud.map((x) => {
          x.type = 'CLOUD';
          return x;
        });
        context.commit('addItems', { collection: '_items', items });
        if (items.count < PAGE_SIZE) {
          context.commit('set', { moreItems: false });
        }
      } else {
        context.commit('set', { moreItems: false });
      }
    },
    loadPage(context) {
      if (context.state.moreItems && context.state.dataFetched) {
        context.commit('set', { dataFetched: false });
        const next = parseInt(context.state.page + 1, 10);
        context.commit('set', { page: next });
        return true;
      }
      return false;
    },
    resetPage(context) {
      if (!context.state.moreItems) {
        context.commit('set', { moreItems: true });
        context.commit('set', { _items: [] });
        context.commit('set', { page: 1 });
      }
      context.commit('set', { dataFetched: true });
    },
    setReadFinished(context) {
      context.commit('set', { dataFetched: true });
    },
    // an attempt to knock out a slippery overlapped request.
    // The dynamic loader checks if the parameters have changed before it refetches the request
    // so invent a 'refetch' parameter, which may trigger it
    rewindPage(context) {
      if (context.state.moreItems && context.state.dataFetched) {
        context.commit('set', { dataFetched: false });
        const currPage = parseInt(context.state.page, 10);

        const req = context.getters.requiredRequests[1].params;
        if (req.skip === (currPage - 1) * PAGE_SIZE) {
          context.commit('set', { page: currPage - 1 });
          req.refetch = true;
        }
      }
    },
  },
});

export default store;
