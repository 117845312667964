<template>
    <a-drawer
    placement="right"
    :closable="false"
    @close="completeTest"
    :destroyOnClose="true"
    :visible="visible">

    <wizard :config="wizard" @done="completeTest">
        <wizard-step name="start">
          <div v-for="(arc, arcIndex) in this.arcConfig" :key="`item-${arcIndex}`">
            <h3>{{ $t('communications.arcOptions.ARC-title', { number: parseInt(arcIndex, 10) + 1 }) }}</h3>
            <div v-for="(path, pathIndex) in arc.paths" :key="`item-${pathIndex}`">
              <text-row :title="path.name" :type="path.testState" :disabled="!path.active" />
            </div>
          </div>
        </wizard-step>

        <wizard-step name="run">
          <template slot="title">
            {{ $t('maintenance.drawer.arcComms.ARC-comms') }}
             <a class="subtitle" @click="startTest" v-if="!testing">{{ $t('maintenance.drawer.arcComms.Test-again')}}</a>
          </template>

          <template slot="body">
            <div class="align-center">
              <font-awesome-icon v-if="success" :icon="['fal', 'check-circle']" size="10x" class="success" />
              <font-awesome-icon v-if="success === false" :icon="['fal', 'times-circle']" size="10x" class="error" />
              <font-awesome-icon v-if="success == null" class="fa-spin" :icon="['fal', 'spinner']" size="10x" />
              <h3 class="title1">{{ $t('maintenance.drawer.arcComms.ARC-comms-tested') }}</h3>
            </div>
            <div v-for="(arc, arcIndex) in this.arcConfig" :key="`item-${arcIndex}`">
              <h3>{{ $t('communications.arcOptions.ARC-title', { number: parseInt(arcIndex, 10) + 1 }) }}</h3>
              <div v-for="(path, pathIndex) in arc.paths" :key="`item-${pathIndex}`">
                <text-row :title="path.name" :type="path.testState" :disabled="!path.active" />
              </div>
            </div>
            <div class="align-center">
              <a-button block type="primary" @click="completeTest">{{ $t('common.Complete-test') }}</a-button>
            </div>
          </template>

        </wizard-step>

      </wizard>
    </a-drawer>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';

import arcCommsTest from '@/app/maintenance/shared/services/arc-comms-test';

import { StateLoader } from '@/app/shared/mixins';

const storeNamespace = 'maintenanceState/arcCommsTest';
const { mapState } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [StateLoader],
  components: {
    Wizard,
    WizardStep,
  },
  props: {
    visible: { default: false },
  },
  data() {
    return {
      testing: undefined,
      success: undefined,
      loader: {
        storeNamespace,
      },
      wizard: {
        start: {
          title: this.$t('maintenance.drawer.arcComms.Start-ARC-comms'),
          next: [
            { key: 'run', buttonText: this.$t('maintenance.drawer.arcComms.Start-test-call') },
          ],
        },
        run: {
          onShow: () => {
            this.startTest();
          },
        },
      },
    };
  },
  computed: {
    ...mapState(['arcConfig']),
  },
  methods: {
    onClose() {
      arcCommsTest.end();
      this.$emit('onClose');
    },
    completeTest() {
      arcCommsTest.end();

      // Reset the displayed test state for each ARC path, ready for another test.
      this.arcConfig.forEach((arc) => {
        arc.paths.forEach((path) => {
          if (path.active) {
            path.testState = 'testPending';
          }
        });
      });

      this.$emit('onClose');
    },
    async startTest() {
      let overallResult = true;

      // Show test in progress.
      this.success = null;
      this.testing = true;

      // Reset the displayed test state for each ARC path, ready for the new test.
      this.arcConfig.forEach((arc) => {
        arc.paths.forEach((path) => {
          if (path.active) {
            path.testState = 'testPending';
          }
        });
      });

      // Make test calls to each ARC, in turn.
      for (let arcIndex = 0; arcIndex < this.arcConfig.length; arcIndex += 1) {
        const arc = this.arcConfig[arcIndex];
        let hasActivePath = false;

        // Show spinning wheel beside each path for this ARC.
        for (let pathIndex = 0; pathIndex < arc.paths.length; pathIndex += 1) {
          const path = arc.paths[pathIndex];
          if (path.active) {
            path.testState = 'testing';
            hasActivePath = true;
          }
        }

        // Only make test calls to this ARC if it has at least one active path
        // (otherwise, there's no point).
        if (hasActivePath) {
          // Make test calls to this ARC (via each path), and await the results.
          // Note: disable lint error:
          // - Unexpected `await` inside a loop
          // for this line: we WANT each blocking call to be performed in sequence,
          // (i.e. perform each call, and await its completion, before making the
          // next) as the panel interface only supports one ARC test call at a time.
          const result = await arcCommsTest.test(arcIndex); // eslint-disable-line

          // Show the result of the test call via each path.
          for (let pathIndex = 0; pathIndex < result.Path.length; pathIndex += 1) {
            if (arc.paths[pathIndex].active) {
              if (result.Path[pathIndex].state === true) {
                arc.paths[pathIndex].testState = 'testComplete';
              } else {
                arc.paths[pathIndex].testState = 'testFailed';
                overallResult = false;
              }
            }
          }
        }
      }

      // Show test complete.
      this.success = overallResult;
      this.testing = false;
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

.success {
  color: #97D577;
}

.error {
  color: #FF1A1A;
}

</style>
