import CryptoJS from 'crypto-js';
import authState from '@/app/shared/state/auth-state';

function encrypt(data, encryptionKeyArg, raw = false) {
  let encryptionKey = encryptionKeyArg;
  encryptionKey = encryptionKey || authState.encryptionKey;
  if (encryptionKey != null) {
    const json = raw ? data : JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(json, encryptionKey, { mode: CryptoJS.mode.ECB });
    const encryptedDataBase64Encoded = encryptedData.ciphertext.toString(CryptoJS.enc.Base64);
    return encryptedDataBase64Encoded;
  }
  return data;
}

function decrypt(data, encryptionKeyArg) {
  let encryptionKey = encryptionKeyArg;
  encryptionKey = encryptionKey || authState.encryptionKey;
  if (encryptionKey != null && data != null && data.length > 0) {
    const decryptedData = CryptoJS.AES.decrypt(data, encryptionKey, { mode: CryptoJS.mode.ECB });
    const text = decryptedData.toString(CryptoJS.enc.Utf8);
    const json = JSON.parse(text);
    return json;
  }
  return data;
}

export default { encrypt, decrypt };
