<template>
  <div>
    <a-tooltip v-if="hasToggle && toggleTooltip" :title="toggleTooltip">
      <a-switch v-if="hasToggle" :disabled="disabled || switchDisabled" :checked="toggled" @change="$emit('toggle')" />
    </a-tooltip>
    <a-switch v-else-if="hasToggle" :disabled="disabled || switchDisabled" :checked="toggled" @change="$emit('toggle')" />
    <a-button :disabled="disabled" v-if="canSave" type="primary" @click="$emit('save')" :loading="isSaving">{{ $t('common.Save') }}</a-button>
    <a-dropdown class="icon-only" v-if="canDelete || canClone" data-testid="menu-dropdown">
        <a-menu slot="overlay" @click="menuClick">
            <a-menu-item :disabled="disabled" key="delete" v-if="canDelete" data-testid="menu-delete">{{ $t('common.Delete') }}</a-menu-item>
            <a-menu-item :disabled="disabled" key="clone" v-if="canClone" data-testid="menu-clone">{{ $t('common.Clone') }}</a-menu-item>
        </a-menu>
        <a-button>
            <font-awesome-icon :icon="['fal', 'ellipsis-h']" />
        </a-button>
    </a-dropdown>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    isSaving: {},
    canClone: { default: false },
    hasToggle: { default: false },
    canDelete: { default: true },
    canSave: { default: true },
    toggled: {},
    toggleTooltip: { default: null },
    disabled: { default: false },
    switchDisabled: { default: false },
  },
  methods: {
    async menuClick({ key }) {
      const self = this;
      if (key === 'delete') {
        this.$confirm({
          title: self.$t('common.Do-you-want-to-delete-this-item'),
          okText: self.$t('common.Ok'),
          cancelText: self.$t('common.Cancel'),
          onOk() {
            self.$emit('delete');
          },
          onCancel() {},
        });
      }

      if (key === 'clone') {
        this.$emit('clone');
      }
    },
  },
};
</script>

<style lang="scss">
</style>
