export function toDaysHoursMinutes(totalMinutes) {
  const days = Math.floor(totalMinutes / (24 * 60));
  const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
  const minutes = Math.floor((totalMinutes % (24 * 60)) % 60);

  return {
    days,
    hours,
    minutes,
  };
}

export function toMinutes(days, hours, minutes) {
  return ((parseInt(days, 10) || 0) * 24 * 60) + ((parseInt(hours, 10) || 0) * 60) + (parseInt(minutes, 10) || 0);
}

export const validMinutesValidator = (max, minutesField, daysField, hoursField) => (value, vm) => toMinutes(vm[daysField], vm[hoursField], vm[minutesField]) < max;
