<template>
    <a-card class="card" hoverable>
        <slot name="status"></slot>
            <slot></slot>
        <div class="card__body">
            <slot name="body"></slot>
        </div>
        <template class="ant-card-actions card-actions" slot="actions">
            <slot name="card-actions"></slot>
        </template>
    </a-card>
</template>
<script>
export default {
  name: 'Card',
};
</script>

<style lang="scss">
@import 'card.scss';

</style>
