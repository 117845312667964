import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';


function initialState() {
  return {
    form: {
      confirmTime: undefined,
      pa: undefined,
      strobeTime: undefined,
      sirenDelay: undefined,
      rearmNumber: undefined,
      finalDoorDelay: undefined,
      acFailDelay: undefined,
      acMissingDelay: undefined,
      exitSuspendTime: undefined,
      doubleKnock: undefined,
      setFail: undefined,
      fireSirenTime: undefined,
      setFailWarning: undefined,
      zoneNATDays: undefined,
      zoneNATHours: undefined,
      occupancyTimer: undefined,
      wirelessSupervisionTime: undefined,
      wirelessJammingTime: undefined,
      serviceTimeDays: undefined,
      entryCommsDelay: undefined,
      ethernetCommsFaultDelay: undefined,
      wifiCommsFaultDelay: undefined,
      cellularCommsFaultDelay: undefined,
      serialCommsFaultDelay: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configSysInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        confirmTime: endpoints.configSysInfo.data.Config.sysInfo.Timers.ConfirmTime_mins,
        pa: endpoints.configSysInfo.data.Config.sysInfo.Timers.PanicAlarmConfirmTime_mins,
        rearmNumber: endpoints.configSysInfo.data.Config.sysInfo.Timers.RearmCount,
        finalDoorDelay: endpoints.configSysInfo.data.Config.sysInfo.Timers.SettleTime_secs,
        acFailDelay: endpoints.configSysInfo.data.Config.sysInfo.Timers.AC_OffDelay_mins,
        acMissingDelay: endpoints.configSysInfo.data.Config.sysInfo.MiscOptions.AC_MissingDelay_mins,
        exitSuspendTime: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.ExitSuspend.toString(),
        doubleKnock: endpoints.configSysInfo.data.Config.sysInfo.Timers.DoubleKnockTime_secs,
        setFail: endpoints.configSysInfo.data.Config.sysInfo.Timers.SetFailTime_secs,
        setFailWarning: endpoints.configSysInfo.data.Config.sysInfo.Timers.SetFailWarningTime_mins,
        zoneNATDays: endpoints.configSysInfo.data.Config.sysInfo.Timers.ZoneNonActivityDays,
        zoneNATHours: endpoints.configSysInfo.data.Config.sysInfo.Timers.ZoneNonActivityHours,
        occupancyTimer: endpoints.configSysInfo.data.Config.sysInfo.Timers.OccupancyTime_hours,
        wirelessSupervisionTime: endpoints.configSysInfo.data.Config.sysInfo.Timers.RadioSupervisionTime_hours,
        wirelessJammingTime: endpoints.configSysInfo.data.Config.sysInfo.Timers.RadioJammingTime_secs,
        serviceTimeDays: endpoints.configSysInfo.data.Config.sysInfo.Timers.ServiceTime_days,
        entryCommsDelay: endpoints.configSysInfo.data.Config.sysInfo.Timers.PreAlarmTime_secs,
        ethernetCommsFaultDelay: endpoints.configSysInfo.data.Config.sysInfo.Timers.LineFaultTimeEthernet_mins,
        wifiCommsFaultDelay: endpoints.configSysInfo.data.Config.sysInfo.Timers.LineFaultTimeWifi_mins,
        cellularCommsFaultDelay: endpoints.configSysInfo.data.Config.sysInfo.Timers.LineFaultTimeCellular_mins,
        serialCommsFaultDelay: endpoints.configSysInfo.data.Config.sysInfo.Timers.LineFaultTimeSerial_mins,
      });
    },
    async save(context) {
      const miscOptions = {
        AC_MissingDelay_mins: parseInt(context.state.form.acMissingDelay, 10),
      };

      const data = {
        ConfirmTime_mins: parseInt(context.state.form.confirmTime, 10),
        PanicAlarmConfirmTime_mins: parseInt(context.state.form.pa, 10),
        RearmCount: parseInt(context.state.form.rearmNumber, 10),
        SettleTime_secs: parseInt(context.state.form.finalDoorDelay, 10),
        AC_OffDelay_mins: parseInt(context.state.form.acFailDelay, 10),
        DoubleKnockTime_secs: parseInt(context.state.form.doubleKnock, 10),
        SetFailTime_secs: parseInt(context.state.form.setFail, 10),
        SetFailWarningTime_mins: parseInt(context.state.form.setFailWarning, 10),
        ZoneNonActivityDays: parseInt(context.state.form.zoneNATDays, 10),
        ZoneNonActivityHours: parseInt(context.state.form.zoneNATHours, 10),
        OccupancyTime_hours: parseInt(context.state.form.occupancyTimer, 10),
        RadioSupervisionTime_hours: parseInt(context.state.form.wirelessSupervisionTime, 10),
        RadioJammingTime_secs: parseInt(context.state.form.wirelessJammingTime, 10),
        ServiceTime_days: parseInt(context.state.form.serviceTimeDays, 10),
        PreAlarmTime_secs: parseInt(context.state.form.entryCommsDelay, 10),
        LineFaultTimeEthernet_mins: parseInt(context.state.form.ethernetCommsFaultDelay, 10),
        LineFaultTimeWifi_mins: parseInt(context.state.form.wifiCommsFaultDelay, 10),
        LineFaultTimeCellular_mins: parseInt(context.state.form.cellularCommsFaultDelay, 10),
        LineFaultTimeSerial_mins: parseInt(context.state.form.serialCommsFaultDelay, 10),
      };

      const siteOptions = {
        ExitSuspend: context.state.form.exitSuspendTime === 'true',
      };

      await api.put('/Config/sysInfo/Timers', data);
      await api.put('/Config/sysInfo/MiscOptions', miscOptions);
      await api.put('/Config/sysInfo/SiteOptions', siteOptions);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('settingsState/generalTimers/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['settingsState/generalTimers'] });
    },
  },
});


export default store;
