/* eslint-disable no-param-reassign */
import {
  Base,
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Collapse,
  Col,
  Dropdown,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Layout,
  List,
  Modal,
  Menu,
  Pagination,
  Progress,
  Select,
  Switch,
  Tabs,
  Table,
  Tooltip,
  Radio,
  Row,
  Skeleton,
  Notification,
  Icon,
  Alert,
  Spin,
  DatePicker,
  TimePicker,
  Upload,
} from 'ant-design-vue';

import '@/assets/styles/base/ant.less';
import '@/assets/styles/base/global.scss';

const AntDesignVue = {
  install(Vue) {
    Vue.use(Base);
    Vue.use(Breadcrumb);
    Vue.use(Button);
    Vue.use(Card);
    Vue.use(Collapse);
    Vue.use(Checkbox);
    Vue.use(Col);
    Vue.use(Drawer);
    Vue.use(Dropdown);
    Vue.use(Divider);
    Vue.use(Form);
    Vue.use(Input);
    Vue.use(InputNumber);
    Vue.use(Layout);
    Vue.use(List);
    Vue.use(Pagination);
    Vue.use(Progress);
    Vue.use(Modal);
    Vue.use(Menu);
    Vue.use(Switch);
    Vue.use(Select);
    Vue.use(Row);
    Vue.use(Radio);
    Vue.use(Tabs);
    Vue.use(Table);
    Vue.use(Tooltip);
    Vue.use(Skeleton);
    Vue.use(Notification);
    Vue.use(Icon);
    Vue.use(Alert);
    Vue.use(Spin);
    Vue.use(DatePicker);
    Vue.use(TimePicker);
    Vue.use(Upload);

    Vue.prototype.$notification = Notification;
    Vue.prototype.$confirm = Modal.confirm;
  },
};

export default AntDesignVue;
