import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import {
  getAssociatedDevices, saveOutputType, endstationOutputType, saveOutput, getSingleOutputInfo,
  isSiren, isSirenOutputType, hasFollowTime, getOutputApiPath, isAreaSpecific, areaSpecificToOutputType, createOutputsList,
} from '@/app/outputs/shared/services/helpers';
import { getZoneSelectList } from '@/app/zones/shared/services/helpers';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';

function initialState() {
  return {
    name: undefined,
    state: undefined,

    associatedDevices: [],
    _outputTypes: [],
    areasConfig: [],
    areasInfo: [],
    zones: [],

    form: {
      rawOutputType: undefined,
      baseOutputType: undefined,
      areaIndex: undefined,
      location: undefined,
      associatedWith: undefined,
      number: undefined,
      associatedWithDevice: undefined,
      associatedWithOutputIndex: undefined,

      transistored: undefined,

      followType: undefined,
      followWhat: undefined,
      followZone: undefined, // FOLLOW_WHAT === 'zone'
      followArea: undefined, // FOLLOW_WHAT === 'area'
      followTime: undefined,
      resetZone: undefined,
      followWhen: undefined,
    },

    diagnostics: [
      { key: 'output.Diagnostics.Status', isLoading: true, value: undefined },
    ],
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {
    outputTypes: state => state._outputTypes,
    isSiren: state => isSirenOutputType(state.form.baseOutputType) || isSiren({ type: state.form.associatedWithDevice, deviceOutputIndex: state.form.associatedWithOutputIndex }),
  },
  actions: {
    requiredEndpoints() {
      return ['infoEndstation', 'infoOutputInfo', 'infoAreaInfo', 'infoBusDevices', 'infoKeypadInfo', 'infoZoneInfo',
        'configEndstation', 'configBusDevices', 'configKeypadInfo', 'configOutputInfo', 'configZoneInfoParts', 'configAreaInfoNames',
        'livePanelStatus'];
    },
    isAreaSpecific() {
      if (this.outputTypes !== undefined) {
        const outputType = this.outputTypes.find(o => o.value === this.$v.form.baseOutputType.$model);
        if ((outputType !== undefined) && (outputType.specific !== undefined)) {
          return outputType.specific === 'AREA';
        }
      }
      return false;
    },
    async populate(context, { endpoints, payload }) {
      const item = getSingleOutputInfo(endpoints, payload.index);
      context.commit('setForm', {
        rawOutputType: item.rawOutputType,
        baseOutputType: item.outputType,
        areaIndex: item.area,
        location: item.location,
        associatedWithDevice: `${item.associatedWith.type}_${item.associatedWith.deviceIndex || 0}`,
        associatedWithOutputIndex: item.associatedWith.deviceOutputIndex,
        transistored: item.transistored,
        followType: item.followType,
        followWhat: item.followWhat,
        followZone: item.followWhat === 'ZONE' ? item.followZone : null,
        followArea: item.followWhat === 'AREA' ? item.followZone : null, // note: overloaded field Zone/Area
        followTime: item.followTime,
        resetZone: item.resetZone,
        followWhen: item.followWhen,
      });

      const udOutputs = endpoints.configOutputInfo.data.Config.outputInfo.UserDefinedOutputs;
      const zones = getZoneSelectList(endpoints.configZoneInfoParts, endpoints.infoZoneInfo);
      const outputTypes = createOutputsList(endpoints.infoOutputInfo.data.Info.outputInfo, udOutputs);
      const devices = getAssociatedDevices(payload.index, endpoints);

      context.commit('set', {
        name: item.name,
        associatedWith: item.associatedWith,
        associatedDevices: devices,
        _outputTypes: outputTypes,
        areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
        areasConfig: endpoints.configAreaInfoNames.data.Config.areaInfo,
        zones,
      });

      await context.dispatch('onPoll', { key: 'livePanelStatus', endpoint: endpoints.livePanelStatus });
    },
    async onPoll(context, { dataStatus, key, endpoint }) {
      if (key === 'livePanelStatus' && context.state.form.rawOutputType != null) {
        let outputType;
        if (context.state.form.baseOutputType === 35) {
          outputType = 3000 + context.state.form.associatedWithOutputIndex;
        } else if (isAreaSpecific(context.state._outputTypes, context.state.form.baseOutputType)) {
          outputType = areaSpecificToOutputType(context.state.form.baseOutputType, context.state.form.areaIndex);
        } else {
          outputType = context.state.form.baseOutputType;
        }

        const { state } = endpoint.data.Live.PanelStatus.OutputTypes[outputType];
        context.commit('trySet', { dataStatus, data: { state } });
        context.commit('trySetDiagnosticByKey', {
          dataStatus, key: 'output.Diagnostics.Status', item: { value: state ? 'ON' : 'OFF', type: state ? 'OK' : 'ERROR' },
        });
      }
    },
    async save(context) {
      // Unset existing
      await saveOutputType(api, context.state.associatedWith.type, context.state.associatedWith.deviceIndex, context.state.associatedWith.deviceOutputIndex, 0);

      const associatedDevice = context.state.associatedDevices.find(v => v.key === context.state.form.associatedWithDevice);
      const deviceOutputIndex = context.state.form.associatedWithOutputIndex;
      const { outputIndex } = associatedDevice.availableOutputs.find(i => i.deviceOutputIndex === deviceOutputIndex);
      const { deviceIndex, type } = associatedDevice;

      // Set new
      if (context.state.form.baseOutputType === 35) {
        await saveOutputType(api, type, deviceIndex, deviceOutputIndex, 3000 + outputIndex);
      } else if (isAreaSpecific(context.state._outputTypes, context.state.form.baseOutputType)) {
        const outputType = areaSpecificToOutputType(context.state.form.baseOutputType, context.state.form.areaIndex);
        await saveOutputType(api, type, deviceIndex, deviceOutputIndex, outputType);
      } else {
        await saveOutputType(api, type, deviceIndex, deviceOutputIndex, context.state.form.baseOutputType);
      }

      // Set other data
      const {
        followType, followWhat, followWhen, followZone, followArea, resetZone,
      } = context.state.form;
      let following;
      if (followWhat === 'ZONE') {
        following = followZone;
      } else if (followWhat === 'AREA') {
        following = followArea;
      } else {
        following = undefined;
      }
      await saveOutput(api, 'FollowState', type, deviceIndex, deviceOutputIndex, followType);
      await saveOutput(api, 'FollowWhat', type, deviceIndex, deviceOutputIndex, followWhat);
      await saveOutput(api, 'FollowWhen', type, deviceIndex, deviceOutputIndex, followWhen);
      await saveOutput(api, 'FollowZone', type, deviceIndex, deviceOutputIndex, following);
      await saveOutput(api, 'ResetZone', type, deviceIndex, deviceOutputIndex, resetZone);

      if (hasFollowTime(context.state.form.followType, type)) {
        await saveOutput(api, 'Timer_100ms', type, deviceIndex, deviceOutputIndex, parseInt(context.state.form.followTime, 10));
      }

      if (type === 'ENDSTATION') {
        const endstationType = endstationOutputType(outputIndex);
        if (endstationType.type === 'ElectronicOutputs') {
          const { transistored } = context.state.form;
          await api.put(`/Config/Endstation/ElectronicOutputs/${endstationType.index}/Mode`, transistored);
        }
      }

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('outputsState/output/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['outputsState/output'] });
    },
    async delete(context) {
      const { type, deviceIndex, deviceOutputIndex } = context.state.associatedWith;
      const path = getOutputApiPath(type, deviceIndex, deviceOutputIndex);
      // eslint-disable-next-line no-await-in-loop
      const objDefaults = await generateDefaultObject(path);
      // eslint-disable-next-line no-await-in-loop
      await api.put(path, objDefaults);

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('outputsState/outputsList/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('outputsState/addOutput/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['outputsState/outputsList'] });
    },
  },
});

export default store;
