import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';


function initialState() {
  return {
    form: {
      startAt: undefined,
      startAtAreas: undefined,
      stopAt: undefined,
      stopAtAreas: undefined,
      areasInfo: undefined,
      areasConfig: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configResponses', 'infoAreaInfo', 'configAreaInfoNames'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        startAt: endpoints.configResponses.data.Config.Responses.UpgradeToLevel,
        startAtAreas: endpoints.configResponses.data.Config.Responses.UpgradeDigiAreas,
        stopAt: endpoints.configResponses.data.Config.Responses.UpgradeDigiLevel,
        stopAtAreas: endpoints.configResponses.data.Config.Responses.UpgradeToAreas,
        areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
        areasConfig: endpoints.configAreaInfoNames.data.Config.areaInfo,
      });
    },
    async save(context) {
      const data = {
        UpgradeToLevel: context.state.form.startAt,
        UpgradeDigiAreas: context.state.form.startAtAreas,
        UpgradeDigiLevel: context.state.form.stopAt,
        UpgradeToAreas: context.state.form.stopAtAreas,
      };

      await api.put('/Config/Responses', data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('settingsState/alarmResponses/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['settingsState/alarmResponses'] });
    },
  },
});


export default store;
