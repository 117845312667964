import { storeFactory } from '@/app/shared/services/store-helper';

function initialState() {
  return {
    zones: [],
    daysLeft: undefined,
  };
}

const { store } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configZoneInfoParts', 'infoZoneInfo', 'liveZoneInfoDaysOnSoak'];
    },
    async populate(context, { endpoints }) {
      const zones = [];

      for (let x = 0; x < endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones.length; x += 1) {
        const zoneType = endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[x].ZoneType;
        if (zoneType !== 'ISOLATED') {
          const zoneNumber = endpoints.infoZoneInfo.data.Info.zoneInfo.Zones[x].Number;
          const zoneName = endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones[x].Name;
          zones.push({ value: x, name: `${zoneNumber} - ${zoneName}` });
        }
      }

      context.commit('set', {
        zones,
      });
    },
    async onPoll(context, { key, dataStatus, endpoint }) {
      if (key === 'liveZoneInfoDaysOnSoak') {
        context.commit('trySet', { dataStatus, data: { daysLeft: endpoint.data.Live.zoneInfo.DaysOnSoak } });
      }
    },
  },
});


export default store;
