<template>
  <a-drawer
    placement="right"
    :closable="false"
    @close="onClose"
    :destroyOnClose="true"
    :visible="visible">

    <wizard :config="wizard" :state="$v" @save="save" @done="onClose" :initialStep="clonePayload != null ? 'manual' : 'start'">
      <wizard-step name="start">
        <template slot="body" slot-scope="{ wizard }">
          <a-button class="draw-link" @click="wizard.navigateTo('autoscan')" type="link" block>
            <text-row :title="$t('common.Autoscan-wired-only')" title-icon="search" />
          </a-button>
          <a-button class="draw-link" @click="wizard.navigateTo('manual')" type="link" block>
            <text-row :title="$t('common.Add-manually')" title-icon="plus-circle" />
          </a-button>
        </template>
      </wizard-step>

      <wizard-step name="autoscan">
        <autoscan @done="onClose" @restart="onClose" :keypads="false" :readers="false" :zoneExpanders="false" :outputExpanders="true"/>
      </wizard-step>

      <wizard-step name="manual">
        <a-form>
          <p-input :label="$t('common.Location')" :model="$v.form.location" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
          <p-select :label="$t('common.Address')" :model="$v.form.address"
            :items="availableAddresses" :valueExpr="(i) => i" :textExpr="(i) => i" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
        </a-form>
      </wizard-step>
    </wizard>
  </a-drawer>
</template>
<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';
import Autoscan from '@/app/shared/components/add-devices-drawer/autoscan.vue';

import { Clone } from '@/app/shared/mixins';

const storeNamespace = 'outputExpandersState/addOutputExpander';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [
    Clone,
  ],
  components: {
    Wizard,
    WizardStep,
    Autoscan,
  },
  data() {
    return {
      clone: {
        storeNamespace,
      },
      wizard: {
        start: {
          title: this.$t('outputExpanders.drawer.How-do-you-want-to-add-output-expanders'),
          next: [],
        },
        autoscan: {
          mode: 'SIMPLE',
        },
        manual: {
          title: this.$t('outputExpanders.drawer.Add-an-output-expander'),
          next: [
            { key: 'save', buttonText: this.$t('common.Add-device') },
          ],
        },
      },
    };
  },
  validations: {
    form: {
      location: {
        maxLength: maxLength(16),
      },
      address: {
        required,
      },
    },
  },
  computed: {
    ...mapFields(storeNamespace, ['form']),
    ...mapState(['availableAddresses']),
  },
  watch: {
    // Whenever we close the wizard, the component should be reset
    visible(newVal) {
      if (!newVal) {
        this.$store.dispatch('outputExpandersState/addOutputExpander/reset');
        this.$v.$reset();
      }
    },
  },
  methods: {
    ...mapActions(['add']),
    async onClose() {
      this.$emit('onClose');
    },
    async save() {
      await this.add();
      this.$emit('onClose');

      if (!this.navigateToNewItem) {
        return;
      }

      this.$router.push({ name: 'output-expander', params: { index: this.form.address } });
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

</style>
