<template>
  <a-form-item :label="label" :validate-status="model.$error ? 'error': 'success'" :label-col="labelCol" :wrapper-col="wrapperCol">
    <a-input-number class="alternative" type="text" v-model="model.$model" @change="onChange" :disabled="disabled"/>
    <div class="ant-form-explain" v-if="model.$params.required && model.$error && !model.required">{{ $t('common.Field-is-required') }}</div>
    <div class="ant-form-explain" v-if="model.$params.between && model.$error && !model.between">{{ $t('common.Field-must-be-between', { min: model.$params.between.min, max: model.$params.between.max }) }}</div>
    <div class="ant-form-explain" v-if="model.$params.maxLength && model.$error && !model.maxLength">{{ $t('common.Field-must-have-less-than-characters', { max: model.$params.maxLength.max }) }}</div>
  </a-form-item>
</template>

<script>
export default {
  components: {

  },
  props: ['label', 'model', 'label-col', 'wrapper-col', 'disabled'],
  methods: {
    onChange(e) {
      this.$emit('input', e);
    },
  },
};
</script>

<style lang="scss">
</style>
