import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';

function initialState() {
  return {
    form: {
      burglary: undefined,
      pa: undefined,
      tamper: undefined,
      soak: undefined,
      confirm: undefined,
      confirmPA: undefined,
      faults: undefined,
      antiCodeRestore: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configSysInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        burglary: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.EngResetIntruder === 1,
        pa: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.EngResetPA,
        tamper: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.EngResetTamp,
        soak: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.EngResetSoak,
        confirm: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.EngResetConfirm,
        confirmPA: endpoints.configSysInfo.data.Config.sysInfo.MiscOptions.EngResetConfirmHU,
        faults: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.FaultsResetByEngineer,
        antiCodeRestore: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.AntiCodeReset,
      });
    },
    async save(context) {
      const data = {
        EngResetIntruder: context.state.form.burglary ? 1 : 0,
        EngResetPA: context.state.form.pa,
        EngResetTamp: context.state.form.tamper,
        EngResetSoak: context.state.form.soak,
        EngResetConfirm: context.state.form.confirm,
        FaultsResetByEngineer: context.state.form.faults,
        AntiCodeReset: context.state.form.antiCodeRestore,
      };

      await api.put('/Config/sysInfo/SiteOptions', data);
      await api.put('/Config/sysInfo/MiscOptions/EngResetConfirmHU', context.state.form.confirmPA);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('settingsState/engineerRestores/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['settingsState/engineerRestores'] });
    },
  },
});


export default store;
