/* eslint-disable no-restricted-globals */
import { API, createArray } from '@/app/shared/services/api';

const api = new API();
async function getSchema() {
  const response = await api.get('/Config?op=GetSchema');
  return response.data;
}

function generateObjectFromSchema(schema) {
  if (schema.type === 'integer') {
    return schema.default;
  }

  const obj = {};
  for (let i = 0; i < Object.keys(schema.properties).length; i += 1) {
    const key = Object.keys(schema.properties)[i];
    const property = schema.properties[key];

    if (property.type === 'array') {
      obj[key] = createArray(property.maxItems, () => property.items.default);
    } else {
      obj[key] = property.default;
    }
  }

  return obj;
}

function getSchemaByPath(schema, path) {
  const pathSegments = path.split('/').filter(p => p !== '');
  let pathSchema = schema;
  for (let i = 0; i < pathSegments.length; i += 1) {
    const pathSegment = pathSegments[i];
    if (!Number.isNaN(Number.parseInt(pathSegments[i], 10))) {
      pathSchema = pathSchema.items;
    } else {
      pathSchema = pathSchema.properties[pathSegment];
    }
  }

  return pathSchema;
}

export async function generateDefaultObject(path) {
  const schema = await getSchema();
  const pathSchema = getSchemaByPath(schema, path);
  return generateObjectFromSchema(pathSchema);
}

export async function getCommsInfoARCDetailsPathsEnums() {
  const schema = await getSchema();
  const pathSchema = getSchemaByPath(schema, '/Config/commsInfo/ARCDetails/0/Paths/0');
  return {
    protocolEnums: pathSchema.properties.Protocol.enum,
    senderEnums: pathSchema.properties.Sender.enum,
    supervisionOptionEnums: pathSchema.properties.SupervisionOption.enum,
    networkProtocolEnums: pathSchema.properties.NetworkProtocol.enum,
    keySizeEnums: pathSchema.properties.Key_Size.enum,
  };
}
