import list from './pages/zone-expanders-list/zone-expanders-list-data';
import wiredZoneExpander from './pages/wired-zone-expander/wired-zone-expander-data';
import wirelessZoneExpander from './pages/wireless-zone-expander/wireless-zone-expander-data';
import addZoneExpander from './shared/components/add-zone-expander/add-zone-expander-data';

export default {
  namespaced: true,
  modules: {
    list,
    wiredZoneExpander,
    wirelessZoneExpander,
    addZoneExpander,
  },
};
