export const RESPONSE_LEVEL = {
  KEYPAD: 'KEYPAD',
  INT_SOUNDERS: 'INT_SOUNDERS',
  LOCAL_ALARM: 'LOCAL_ALARM',
  DIGI: 'DIGI',
};

export const RESPONSE_LEVEL_STOP_ALARM = {
  KEYPAD: 'KEYPAD',
  INT_SOUNDERS: 'INT_SOUNDERS',
  LOCAL_ALARM: 'LOCAL_ALARM',
  DIGI: 'DIGI',
  CONFIRM: 'CONFIRM',
};

export const SQUAWK_OPTION = {
  NONE: 0,
  STROBE: 1,
  SQUAWK: 2,
  BOTH: 3,
};

export const EOL_RANGE_OPTIONS = {
  EOL_1K_1K: 'EOL_1K_1K',
  EOL_4K7_2K2: 'EOL_4K7_2K2',
  EOL_4K7_4K7: 'EOL_4K7_4K7',
};

export const ZONE_RESISTOR_MODE = {
  SINGLE: 'SINGLE',
  DOUBLE: 'DOUBLE',
  TRIPLE: 'TRIPLE',
  NORMALLY_CLOSED: 'NORMALLY_CLOSED',
};

export const EXIT_MODE = {
  TIMED: 0,
  FINAL_DOOR: 1,
  TIMED_FINAL: 2,
  PUSH_TO_ARM: 3,
};

export const EXIT_SUSPEND_TIME = {
  NO: 'false',
  YES: 'true',
};

export const CODE_LENGTH_MODE = {
  CODE_LENGTH_4: 4,
  CODE_LENGTH_5: 5,
  CODE_LENGTH_6: 6,
};

export const STROBE_SET_UNSET = {
  NO: 'false',
  YES: 'true',
};

export const SQUAWK_SET_UNSET = {
  NO: 'false',
  YES: 'true',
};

export const TIME_ZONE = {
  '000': '+00:00',
  '001': '+00:00',
  '002': '-10:00',
  '003': '+09:30',
  '004': '+01:00',
  '005': '+06:00',
  '006': '+02:00',
  '007': '+01:00',
  '008': '+12:00',
  '009': '-09:00',
  '010': '+01:00',
  '011': '+13:00',
  '012': '+05:00',
  '013': '+05:00',
  '014': '-03:00',
  '015': '+05:00',
  '016': '+04:00',
  '017': '-04:00',
  '018': '+02:00',
  '019': '-05:00',
  '020': '+05:00',
  '021': '+12:00',
  '022': '-01:00',
  '023': '+03:00',
  '024': '-03:00',
  '025': '-06:00',
  '026': '+04:00',
  '027': '+07:00',
  '028': '-04:00',
  '029': '+07:00',
  '030': '+02:00',
  '031': '-03:00',
  '032': '+01:00',
  '033': '-06:00',
  '034': '+01:00',
  '035': '-04:00',
  '036': '-06:00',
  '037': '+06:00',
  '038': '+00:00',
  '039': '-04:00',
  '040': '-04:00',
  '041': '-05:00',
  '042': '-07:00',
  '043': '+11:00',
  '044': '+10:00',
  '045': '+09:30',
  '046': '+08:00',
  '047': '+01:00',
  '048': '+02:00',
  '049': '+01:00',
  '050': '-03:00',
  '051': '+01:00',
  '052': '-06:00',
  '053': '+02:00',
  '054': '-07:00',
  '055': '-04:00',
  '056': '+00:00',
  '057': '-05:00',
  '058': '-01:00',
  '059': '-04:00',
  '060': '+01:00',
  '061': '+08:00',
  '062': '-03:00',
  '063': '-03:00',
  '064': '-06:00',
  '065': '+01:00',
  '066': '+06:00',
  '067': '+12:45',
  '068': '-06:00',
  '069': '-07:00',
  '070': '+02:00',
  '071': '+09:00',
  '072': '+08:00',
  '073': '+07:00',
  '074': '+10:00',
  '075': '+06:30',
  '076': '+05:30',
  '077': '+01:00',
  '078': '-03:00',
  '079': '-06:00',
  '080': '-07:00',
  '081': '-04:00',
  '082': '-04:00',
  '083': '+10:00',
  '084': '+02:00',
  '085': '+00:00',
  '086': '+09:30',
  '087': '+07:00',
  '088': '-08:00',
  '089': '-07:00',
  '090': '-07:00',
  '091': '-05:00',
  '092': '+06:00',
  '093': '+09:00',
  '094': '+04:00',
  '095': '+01:00',
  '096': '+10:00',
  '097': '+05:00',
  '098': '+02:00',
  '099': '-05:00',
  100: '-05:00',
  101: '-06:00',
  102: '-07:00',
  103: '+11:00',
  104: '-05:00',
  105: '+01:00',
  106: '-06:00',
  107: '+13:00',
  108: '+08:45',
  109: '+00:00',
  110: '+13:00',
  111: '+02:00',
  112: '+00:00',
  113: '+12:00',
  114: '-07:00',
  115: '-03:00',
  116: '+12:00',
  117: '+00:00',
  118: '-01:00',
  119: '-10:00',
  120: '-11:00',
  121: '-12:00',
  122: '-02:00',
  123: '-03:00',
  124: '-04:00',
  125: '-05:00',
  126: '-06:00',
  127: '-07:00',
  128: '-08:00',
  129: '-09:00',
  130: '+01:00',
  131: '+10:00',
  132: '+11:00',
  133: '+12:00',
  134: '+13:00',
  135: '+14:00',
  136: '+02:00',
  137: '+03:00',
  138: '+04:00',
  139: '+05:00',
  140: '+06:00',
  141: '+07:00',
  142: '+08:00',
  143: '+09:00',
  144: '-06:00',
  145: '-09:00',
  146: '+02:00',
  147: '+01:00',
  148: '-04:00',
  149: '-03:00',
  150: '-04:00',
  151: '-05:00',
  152: '+11:00',
  153: '+10:00',
  154: '-06:00',
  155: '-05:00',
  156: '-04:00',
  157: '-10:00',
  158: '-04:00',
  159: '-05:00',
  160: '+02:00',
  161: '+02:00',
  162: '-07:00',
  163: '+07:00',
  164: '+10:00',
  165: '+08:00',
  166: '-10:00',
  167: '+07:00',
  168: '-05:00',
  169: '-07:00',
  170: '-05:00',
  171: '+08:00',
  172: '+03:00',
  173: '+07:00',
  174: '-05:00',
  175: '+09:00',
  176: '+02:00',
  177: '+02:00',
  178: '+03:00',
  179: '-03:00',
  180: '-09:00',
  181: '+04:30',
  182: '+02:00',
  183: '+12:00',
  184: '+05:00',
  185: '+05:45',
  186: '+05:00',
  187: '+09:00',
  188: '+02:00',
  189: '+02:00',
  190: '+14:00',
  191: '+03:00',
  192: '-06:00',
  193: '+05:30',
  194: '+11:00',
  195: '+07:00',
  196: '+08:00',
  197: '+08:00',
  198: '+12:00',
  199: '-04:00',
  200: '-03:00',
  201: '+01:00',
  202: '-05:00',
  203: '+10:00',
  204: '+00:00',
  205: 'Europe/London',
  206: '+10:30',
  207: '-08:00',
  208: '-05:00',
  209: '+01:00',
  210: '+01:00',
  211: '-07:00',
  212: '-07:00',
  213: '+08:00',
  214: '-03:00',
  215: '+11:00',
  216: '+00:00',
  217: '+01:00',
  218: '+11:00',
  219: '+04:00',
  220: '+12:00',
  221: '+08:00',
  222: '+05:00',
  223: '+01:00',
  224: '-06:00',
  225: '-04:00',
  226: '+08:00',
  227: '+02:00',
  228: '-05:00',
  229: '-09:30',
  230: '-04:00',
  231: '-06:00',
  232: '+04:00',
  233: '+05:00',
  234: '-07:00',
  235: '+10:00',
  236: '-03:00',
  237: '-06:00',
  238: '-06:00',
  239: '-09:00',
  240: '-06:00',
  241: '+03:00',
  242: '-03:00',
  243: '+01:00',
  244: '-04:00',
  245: '+00:00',
  246: '-06:00',
  247: '-03:00',
  248: '-05:00',
  249: '+03:00',
  250: '+03:00',
  251: '-05:00',
  252: '+12:00',
  253: '+01:00',
  254: '-06:00',
  255: '-05:00',
  256: '+02:00',
  257: '-05:00',
  258: '-11:00',
  259: '-09:00',
  260: '+11:00',
  261: '-02:00',
  262: '+11:00',
  263: '+07:00',
  264: '+07:00',
  265: '-07:00',
  266: '+06:00',
  267: '+05:00',
  268: '+01:00',
  269: '-08:00',
  270: '-11:00',
  271: '+09:00',
  272: '-03:00',
  273: '-05:00',
  274: '-05:00',
  275: '-03:00',
  276: '+01:00',
  277: '+08:00',
  278: '-05:00',
  279: '-07:00',
  280: '-08:00',
  281: '+11:00',
  282: '+07:00',
  283: '-05:00',
  284: '+10:00',
  285: '-04:00',
  286: '-04:00',
  287: '+01:00',
  288: '-04:00',
  289: '-03:00',
  290: '+09:00',
  291: '+03:00',
  292: '+06:00',
  293: '+05:00',
  294: '-06:00',
  295: '-06:00',
  296: '-10:00',
  297: '-03:00',
  298: '-06:00',
  299: '-06:00',
  300: '+04:00',
  301: '+00:00',
  302: '+02:00',
  303: '-05:00',
  304: '-03:00',
  305: '+03:00',
  306: '+01:00',
  307: '-03:00',
  308: '+11:00',
  309: '-03:00',
  310: '+04:00',
  311: '+05:00',
  312: '-03:00',
  313: '-03:00',
  314: '-03:00',
  315: '-04:00',
  316: '-04:00',
  317: '-03:00',
  318: '+00:00',
  319: '+04:00',
  320: '-01:00',
  321: '+09:00',
  322: '+08:00',
  323: '+03:00',
  324: '+08:00',
  325: '-09:00',
  326: '+02:00',
  327: '-02:00',
  328: '+11:00',
  329: '-03:30',
  330: '-03:00',
  331: '+01:00',
  332: '-06:00',
  333: 'Australia/Sydney',
  334: '+03:00',
  335: '-10:00',
  336: '+08:00',
  337: '+02:00',
  338: '+12:00',
  339: '+05:00',
  340: '+04:00',
  341: '-06:00',
  342: '+03:30',
  343: '-06:00',
  344: '+06:00',
  345: '-04:00',
  346: '-05:00',
  347: '-08:00',
  348: '+01:00',
  349: '+09:00',
  350: '+07:00',
  351: '+13:00',
  352: '-05:00',
  353: '+02:00',
  354: '+00:00',
  355: '-03:00',
  356: '+01:00',
  357: '+00:00',
  358: '+08:00',
  359: '+04:00',
  360: '+06:00',
  361: '-03:00',
  362: '+10:00',
  363: '+02:00',
  364: '-08:00',
  365: '-05:00',
  366: '+01:00',
  367: '+02:00',
  368: '-05:00',
  369: '+10:00',
  370: '+04:00',
  371: '+06:00',
  372: '+00:00',
  373: '+12:00',
  374: '+12:00',
  375: '+01:00',
  376: '-08:00',
  377: '-05:00',
  378: '+02:00',
  379: '-06:00',
  380: '-09:00',
  381: '+09:00',
  382: '+06:30',
  383: '+05:00',
  384: '-07:00',
  385: '+04:00',
  386: '+02:00',
  387: '+01:00',
};
