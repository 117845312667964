import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { NotFoundError } from '@/app/shared/errors';
import { KEYPAD_AND_READER_TYPE } from '@/app/keypads-and-readers/shared/enums';
import i18n from '@/app/shared/services/i18n';
import {
  getOutputInfo, convertOutputType, translateOutputType, udPulseTime, isUserDefined, udTypeName,
} from '@/app/outputs/shared/services/helpers';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import { ENGINEER_KEYPAD_INDEX, liveZoneInfoConsts } from '@/app/shared/constants';
import { toFaultString } from '@/app/shared/utils/activeFaults';
import { roundToNumber } from '@/app/zones/pages/zone/zone-data';

// Flag true if cached associatedZones are valid
let associatedZonesPopulated = false;

// Array of indexes of zones to be polled (because they are associated with this device)
let polledZonesIndexList = [];

// cached voltage
let gVoltageValue = null;
let gBusCommsFault = false; // i.e., ok


function initialState() {
  return {
    name: undefined,
    state: undefined,
    zonesMax: undefined,
    outputsMax: undefined,

    deviceType: undefined,

    associatedZones: [],
    hasAssociatedZones: true,
    associatedOutputs: [],

    form: {
      type: undefined,
      address: undefined,
      keypadsIndex: undefined,
      name: undefined,
      location: undefined,
      setsAreas: undefined,
      unsetsAreas: undefined,
      inAreas: undefined,
      readerLevel: undefined,
      areasInfo: undefined,
      areasConfig: undefined,
      levelsConfig: undefined,
      fireKey: undefined,
      paKey: undefined,
    },

    diagnostics: [
      {
        key: 'wiredKeypad.Diagnostics.Status',
        isLoading: true,
      },
      {
        key: 'wiredKeypad.Diagnostics.BusStatus',
        isLoading: true,
      },
      {
        key: 'wiredKeypad.Diagnostics.DataBusStatus',
        isLoading: true,
      },
    ],
  };
}


const { store, api } = storeFactory(initialState, {
  getters: {},
  actions: {
    requiredEndpoints() {
      // Each time the page is displayed, reset the associatedZones cache.
      // TODO Find a better location to reset the cache; requiredEndpoints() just *happens* to be called when the page is displayed.
      associatedZonesPopulated = false;
      // TODO Investigate replacing liveActiveFaults with liveActiveFaultsKeypadsAndReaders
      return ['infoKeypadInfo', 'configKeypadInfo', 'infoAreaInfo', 'configAreaInfoNames', 'configLevelInfoNames',
        'liveActiveFaults', 'liveZoneInfo', 'infoOutputInfo', 'configOutputInfo', 'livePanelStatus',
        'liveKeypadInfo'];
    },
    async populate(context, { endpoints, payload }) {
      context.commit('set', {
        name: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Name,
        zonesMax: endpoints.infoKeypadInfo.data.Info.keypadInfo.WiredKeypadZonesMaxCount,
        outputsMax: endpoints.infoKeypadInfo.data.Info.keypadInfo.WiredKeypadOutputsMaxCount,
        fireKey: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].FireKeyActive.toString(),
        paKey: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].TwoKeyPA,
      });

      const type = KEYPAD_AND_READER_TYPE.fromInt(endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Type);

      if (type !== KEYPAD_AND_READER_TYPE.TYPE_KEYPAD && type !== KEYPAD_AND_READER_TYPE.TYPE_REMOVABLE_KEYPAD) {
        throw new NotFoundError();
      }

      context.commit('set', { deviceType: type.key });
      context.commit('setForm', {
        type: type.value,
        address: payload.index,
        keypadsIndex: payload.index,
        name: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Name.trim(),
        location: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Location,
        setsAreas: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].KeypadSets,
        unsetsAreas: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].KeypadUnsets,
        inAreas: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].InAreas,
        readerLevel: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].ReaderLevel,
        areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
        areasConfig: endpoints.configAreaInfoNames.data.Config.areaInfo,
        levelsConfig: endpoints.configLevelInfoNames.data.Config.areaInfo,
        isRemovableKeypadAttributeSet: (0x02 & endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].SpecialAttributes_bits) === 0x02,
        fireKey: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].FireKeyActive.toString(),
        paKey: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].TwoKeyPA,
      });

      // Get details of 'Associated zones' aka zones present in this device.
      // For keypads with zones numbered less than 280
      if (payload.index < 16) {
        let cachedConfigZoneInfoItems;
        if (polledZonesIndexList.length > 0) {
          // Get the config zoneInfo for just the polled zones.
          const polledZonesStr = polledZonesIndexList.toString();
          cachedConfigZoneInfoItems = (await api.get('/Config/zoneInfo/Zones/'.concat(polledZonesStr)));
        }
        const associatedZones = [];
        for (let x = 0; x < polledZonesIndexList.length; x += 1) {
          const i = polledZonesIndexList[x];
          const zoneType = cachedConfigZoneInfoItems.data.Config.zoneInfo.Zones[i].ZoneType;
          if (zoneType !== 'ISOLATED') {
            associatedZones.push({
              zoneIndex: i,
              number: i + 1,
              name: cachedConfigZoneInfoItems.data.Config.zoneInfo.Zones[i].Name,
              omittable: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[i].Omittable,
              managerOmitted: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[i].ManagerOmitted,
              setOmitted: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[i].SetOmitted,
              forceOmitted: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[i].ForceOmitted,
              zoneType: cachedConfigZoneInfoItems.data.Config.zoneInfo.Zones[i].ZoneType,
              state: endpoints.liveZoneInfo.data.Live.zoneInfo.Zones[i].state,
              // index is required
              index: i,
            });
          }
          context.commit('set', { hasAssociatedZones: true, associatedZones });
          // The list of associated zones is now populated.
          associatedZonesPopulated = true;
        }
      }
      if (associatedZonesPopulated === false) {
        context.commit('set', { hasAssociatedZones: false });
      }

      const udOutputs = endpoints.configOutputInfo.data.Config.outputInfo.UserDefinedOutputs;
      const associatedOutputs = [];
      for (let x = 0; x < 1; x += 1) {
        const number = endpoints.infoKeypadInfo.data.Info.keypadInfo.Keypads[payload.index].Outputs[x].Number;
        const outputType = endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Outputs[number - 1].OutputType;
        const optype = convertOutputType(outputType);
        const opTypeName = endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes[optype].Name;

        if (outputType !== 0) {
          associatedOutputs.push({
            number,
            associatedWith: getOutputInfo('KEYPAD', payload.index, x, outputType),
            rawOutputType: outputType,
            outputType: optype,
            outputTypeName: opTypeName,
            name: (isUserDefined(outputType) ? udTypeName(udOutputs, outputType) : translateOutputType(opTypeName)),
            hasOutput: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[payload.index].Outputs[number - 1].Output !== 0,
            udPulseTime: udPulseTime(udOutputs, outputType),
            state: endpoints.livePanelStatus.data.Live.PanelStatus.OutputTypes[outputType].state,
          });
        }
      }
      context.commit('set', { associatedOutputs });
    },
    async onPoll(context, {
      dataStatus, key, endpoint, payload,
    }) {
      if (key === 'liveActiveFaults') {
        const caseTamperFault = endpoint.data.Live.ActiveFaults.Devices.Keypads[payload.index].CaseTamper;
        gBusCommsFault = endpoint.data.Live.ActiveFaults.Devices.Keypads[payload.index].BusComms;

        context.commit('trySet', { dataStatus, data: { state: toFaultString(caseTamperFault, gBusCommsFault) } });

        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wiredKeypad.Diagnostics.Status',
          item: {
            value: caseTamperFault ? 'TAMPER' : toFaultString(gBusCommsFault),
            tooltip() {
              return i18n.t(`${this.key}.tooltips.${this.value}`);
            },
          },
        });
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wiredKeypad.Diagnostics.DataBusStatus',
          item: {
            value: toFaultString(gBusCommsFault),
            tooltip() {
              return i18n.t(`${this.key}.tooltips.${this.value}`);
            },
          },
        });
      }

      if (key === 'liveKeypadInfo') {
        const endpt = endpoint.data.Live.keypadInfo.Keypads[payload.index];
        if (endpt.BusVoltage_mV != null) {
          gVoltageValue = roundToNumber(endpt.BusVoltage_mV / 1000, 1);
        }
        context.commit('trySetDiagnosticByKey', {
          dataStatus,
          key: 'wiredKeypad.Diagnostics.BusStatus',
          item: {
            title: i18n.t('wiredKeypad.Diagnostics.BusStatus.title'),
            value: toFaultString(gBusCommsFault), // on the assumption that if there's no comms, then the wire is cut
            tooltip() {
              if (this.value === 'OK') {
                return `${gVoltageValue}V`;
              }
              if (this.value === 'FAULT') {
                return i18n.t(`${this.key}.tooltips.${this.value}`);
              }
              return i18n.t(`${this.key}.tooltips.MISSING`);
            },
          },
        });
      }

      // Only use if the cached associated zones list is valid.
      if (key === liveZoneInfoConsts.key && associatedZonesPopulated === true) {
        const associatedZones = [];
        for (let x = 0; x < context.state.associatedZones.length; x += 1) {
          const { number } = context.state.associatedZones[x];
          const index = number - 1;
          const omittable = endpoint.data.Live.zoneInfo.Zones[index].Omittable;
          const managerOmitted = endpoint.data.Live.zoneInfo.Zones[index].ManagerOmitted;
          const setOmitted = endpoint.data.Live.zoneInfo.Zones[index].SetOmitted;
          const forceOmitted = endpoint.data.Live.zoneInfo.Zones[index].ForceOmitted;
          const { state } = endpoint.data.Live.zoneInfo.Zones[index];

          associatedZones.push({
            omittable, forceOmitted, setOmitted, managerOmitted, state,
          });
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: 'associatedZones', items: associatedZones });
      }

      if (key === 'livePanelStatus') {
        const associatedOutputs = [];
        for (let x = 0; x < context.state.associatedOutputs.length; x += 1) {
          const { rawOutputType } = context.state.associatedOutputs[x];
          const { state } = endpoint.data.Live.PanelStatus.OutputTypes[rawOutputType];
          associatedOutputs.push({ state });
        }
        context.commit('tryMergeCollectionByIndex', { dataStatus, collection: 'associatedOutputs', items: associatedOutputs });
      }
    },
    async save(context) {
      const { keypadsIndex } = context.state.form;
      const response = await api.get(`/Config/keypadInfo/Keypads/${keypadsIndex}`);

      const data = response.data.Config.keypadInfo.Keypads[keypadsIndex];

      data.Name = context.state.form.name.padEnd(16);
      data.Location = context.state.form.location;

      if (keypadsIndex === ENGINEER_KEYPAD_INDEX && (context.state.form.type === KEYPAD_AND_READER_TYPE.TYPE_REMOVABLE_KEYPAD.value || context.state.form.type === KEYPAD_AND_READER_TYPE.TYPE_KEYPAD.value)) {
        data.Type = context.state.form.type;
      }

      if (data.Type !== KEYPAD_AND_READER_TYPE.TYPE_REMOVABLE_KEYPAD.value) {
        data.KeypadSets = context.state.form.setsAreas;
        data.KeypadUnsets = context.state.form.unsetsAreas;
        data.InAreas = context.state.form.inAreas;
        data.ReaderLevel = context.state.form.readerLevel;
      } else if (!context.state.form.isRemovableKeypadAttributeSet) {
        data.SpecialAttributes_bits += 0x02;
      }

      data.FireKeyActive = context.state.form.fireKey === 'true';
      data.TwoKeyPA = context.state.form.paKey;

      await api.put(`/Config/keypadInfo/Keypads/${keypadsIndex}`, data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('keypadsAndReadersState/wiredKeypad/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['keypadsAndReadersState/wiredKeypad'] });
    },
    async delete(context) {
      const { keypadsIndex } = context.state.form;
      // eslint-disable-next-line no-await-in-loop
      const objDefaults = await generateDefaultObject(`/Config/keypadInfo/Keypads/${keypadsIndex}`);
      objDefaults.Name = '';
      // eslint-disable-next-line no-await-in-loop
      await api.put(`/Config/keypadInfo/Keypads/${keypadsIndex}`, objDefaults);
      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('keypadsAndReadersState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('keypadsAndReadersState/addKeypadOrReader/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['keypadsAndReadersState/list'] });
    },
    setPolledZonesIndexList(context, zonesIndexList) {
      polledZonesIndexList = zonesIndexList;
    },
  },
});

export default store;
