import { getField } from 'vuex-map-fields';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { ACCESS_TYPE, BUTTON_ACTION_TYPE } from '@/app/users/shared/enums';
import { getDoors } from '@/app/keypads-and-readers/shared/services/helpers';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import {
  getName, getNumber, getUserType, getApiUserType, toFriendlyCode, canHaveAutoDuress, getDefaultEngineersCode, TAG_LENGTH,
} from '@/app/users/shared/services/helpers';
import { translateOutputType, USER_DEFINED_OUTPUT_BEGIN, USER_DEFINED_OUTPUT_END } from '@/app/outputs/shared/services/helpers';
import { limits } from '@/app/shared/constants';


function isAreaBasedAction(action) {
  return (action === BUTTON_ACTION_TYPE.SET_AREAS.value || action === BUTTON_ACTION_TYPE.UNSET_AREAS.value);
}

function getFirstAreaActionButton(fob) {
  for (let b = 0; b < 8; b += 1) {
    if (isAreaBasedAction(fob.Buttons[b].Action)) {
      return b;
    }
  }
  return 0;
}

function generateButtonsObj(form) {
  const buttons = [];
  const { levelMode } = form;

  for (let i = 0; i < 8; i += 1) { // keyfob buttons
    const action = form[`button${i}ActionType`];
    let value = 0;
    let areas = [];

    if (action === BUTTON_ACTION_TYPE.SET_AREAS.value) {
      if (levelMode) {
        areas = form[`button${i}Areas`].map((a, idx) => (idx === form.levelArea)) || [true];
        value = form[`button${i}Level`] || 0;
      } else {
        areas = form[`button${i}Areas`] || [true];
      }
    } else if (action === BUTTON_ACTION_TYPE.UNSET_AREAS.value) {
      if (levelMode) {
        // must be levelset area
        areas = form[`button${i}Areas`].map((a, idx) => (idx === form.levelArea)) || [true];
      } else {
        areas = form[`button${i}Areas`] || [true];
      }
    } else if (action === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || action === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value) {
      value = form[`button${i}Output`] || 0;
    }

    buttons.push({
      Action: form[`button${i}ActionType`],
      Details: value,
      Areas: areas,
    });
  }

  return buttons;
}

function getAreas(fob, buttonIndex) {
  if (isAreaBasedAction(fob.Buttons[buttonIndex].Action)) {
    if (fob.LevelMode) {
      const fobAreas = fob.Buttons[buttonIndex].Areas;
      const firstArea = fobAreas.indexOf(true);
      if (firstArea > -1) { // we have at least 1 area
        // remove any area's beyond the first
        const idxareas = fobAreas.map((v, i) => ({ index: i, value: v })).filter(a => (a.value === true) && (a.index !== firstArea));
        for (let i = 0; i < idxareas.length; i += 1) {
          fob.Buttons[buttonIndex].Areas[idxareas[i].index] = false;
        }
      } else {
        fob.Buttons[buttonIndex].Areas[0] = true;
      }
    }
    return fob.Buttons[buttonIndex].Areas;
  }
  return null;
}

function getOutput(fob, buttonIndex) {
  return fob.Buttons[buttonIndex].Action === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_LATCHED.value || fob.Buttons[buttonIndex].Action === BUTTON_ACTION_TYPE.OPERATE_OUTPUT_TIMED.value
    ? parseInt(fob.Buttons[buttonIndex].Details, 10) : null;
}

function getLevel(fob, buttonIndex, numLevels) {
  let level;
  const action = fob.Buttons[buttonIndex].Action;
  if (action === BUTTON_ACTION_TYPE.SET_AREAS.value) {
    if (fob.LevelMode) {
      const details = parseInt(fob.Buttons[buttonIndex].Details, 10);
      if ((details >= 0) && (details < numLevels)) {
        level = details;
      } else {
        level = 0;
      }
    }// level mode
  } // set
  return level;
}

function initialState() {
  return {
    name: undefined,
    _allDoors: [],
    outputs: [],

    form: {
      name: undefined,
      accessType: undefined,
      code: undefined,
      userType: undefined,
      areas: undefined,
      areasInfo: undefined,
      areasConfig: undefined,
      levelsConfig: undefined,
      setOption: undefined,
      number: undefined,
      flexiSet: undefined,
      levelMode: undefined,
      levelArea: undefined,
      button0ActionType: undefined,
      button0Areas: undefined,
      button0Output: undefined,
      button0Level: undefined,
      button1ActionType: undefined,
      button1Areas: undefined,
      button1Output: undefined,
      button1Level: undefined,
      button2ActionType: undefined,
      button2Areas: undefined,
      button2Output: undefined,
      button2Level: undefined,
      button3ActionType: undefined,
      button3Areas: undefined,
      button3Output: undefined,
      button3Level: undefined,
      button4ActionType: undefined,
      button4Areas: undefined,
      button4Output: undefined,
      button4Level: undefined,
      button5ActionType: undefined,
      button5Areas: undefined,
      button5Output: undefined,
      button5Level: undefined,
      button6ActionType: undefined,
      button6Areas: undefined,
      button6Output: undefined,
      button6Level: undefined,
      button7ActionType: undefined,
      button7Areas: undefined,
      button7Output: undefined,
      button7Level: undefined,
      associatedDoors: [],
      validation: {
        usedCodes: [],
        minimumCodeLengths: 4,
        showCode: false,
        defEngCode: '1111',
      },
    },
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {
    getField,
    availableDoors: state => state._allDoors.filter(d => state.form.associatedDoors.find(a => a.value === d.value) == null),
  },
  actions: {
    requiredEndpoints() {
      return ['infoOutputInfo', 'configUserInfo', 'configKeypadInfo', 'configRadioFobInfo', 'configSysInfo', 'infoAreaInfo', 'configAreaInfoNames', 'configLevelInfoNames'];
    },
    async populate(context, { endpoints, payload }) {
      const user = endpoints.configUserInfo.data.Config.userInfo.Users[payload.index];
      const numLevels = limits.MAX_LEVELS;

      const usedCodes = endpoints.configUserInfo.data.Config.userInfo.Users
        .filter(u => u.Code !== user.Code && ACCESS_TYPE.fromCode(u.Code) === ACCESS_TYPE.USER_CODE)
        .map(u => toFriendlyCode(u.Code));

      context.commit('set', {
        name: getName(payload.index, user.Name),
        areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
        areasConfig: endpoints.configAreaInfoNames.data.Config.areaInfo,
        levelsConfig: endpoints.configLevelInfoNames.data.Config.areaInfo,
      });

      const accessType = ACCESS_TYPE.fromCode(user.Code);
      context.commit('setForm', {
        name: getName(payload.index, user.Name),
        accessType: accessType.value,
        code: '', // i.e. it's blank until changed
        userType: getUserType(payload.index, user.UserType),
        areas: user.UserAreas,
        setOption: user.UserSetOption,
        index: payload.index,
        number: getNumber(payload.index),
        flexiSet: user.UserFlexiSet,
        levelMode: false,
        levelArea: [],
        validation: {
          minimumCodeLengths: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.MinimumCodeLength,
          usedCodes,
          showCode: false,
          defEngCode: await getDefaultEngineersCode(api),
        },
      });

      if (accessType === ACCESS_TYPE.KEY_FOB) {
        const fobs = endpoints.configRadioFobInfo.data.Config.radioFobInfo.RadioFobs;
        const fobIndex = user.Code[0] - 128;
        const userfob = fobs[fobIndex];
        context.commit('setForm', {
          levelMode: userfob.LevelMode,
          levelArea: userfob.Buttons[getFirstAreaActionButton(userfob)].Areas.indexOf(true),
          button0ActionType: userfob.Buttons[0].Action,
          button0Areas: getAreas(userfob, 0),
          button0Output: getOutput(userfob, 0),
          button0Level: getLevel(userfob, 0, numLevels),
          button1ActionType: userfob.Buttons[1].Action,
          button1Areas: getAreas(userfob, 1),
          button1Output: getOutput(userfob, 1),
          button1Level: getLevel(userfob, 1, numLevels),
          button2ActionType: userfob.Buttons[2].Action,
          button2Areas: getAreas(userfob, 2),
          button2Output: getOutput(userfob, 2),
          button2Level: getLevel(userfob, 2, numLevels),
          button3ActionType: userfob.Buttons[3].Action,
          button3Areas: getAreas(userfob, 3),
          button3Output: getOutput(userfob, 3),
          button3Level: getLevel(userfob, 3, numLevels),
          button4ActionType: userfob.Buttons[4].Action,
          button4Areas: getAreas(userfob, 4),
          button4Output: getOutput(userfob, 4),
          button4Level: getLevel(userfob, 4, numLevels),
          button5ActionType: userfob.Buttons[5].Action,
          button5Areas: getAreas(userfob, 5),
          button5Output: getOutput(userfob, 5),
          button5Level: getLevel(userfob, 5, numLevels),
          button6ActionType: userfob.Buttons[6].Action,
          button6Areas: getAreas(userfob, 6),
          button6Output: getOutput(userfob, 6),
          button6Level: getLevel(userfob, 6, numLevels),
          button7ActionType: userfob.Buttons[7].Action,
          button7Areas: getAreas(userfob, 7),
          button7Output: getOutput(userfob, 7),
          button7Level: getLevel(userfob, 7, numLevels),
        });

        context.commit('set', { fobIndex });
      }

      const {
        doors: allDoors,
        associatedDoors,
      } = getDoors(endpoints.configKeypadInfo, user);

      context.commit('setForm', {
        associatedDoors,
      });

      const outputTypes = [];
      for (let i = USER_DEFINED_OUTPUT_BEGIN; i < USER_DEFINED_OUTPUT_END; i += 1) {
        const outputType = endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes[i].Name;
        if (outputType != null) {
          outputTypes.push({
            value: i, key: outputType, name: translateOutputType(outputType),
          });
        }
      }

      context.commit('set', {
        index: payload.index, _allDoors: allDoors, outputs: outputTypes,
      });
    },
    async save(context) {
      const { index } = context.state;

      const data = {};

      data.Name = context.state.form.name;

      if (context.state.form.code !== '' && context.state.form.code != null) {
        const code = [64, 64, 64, 64, 64, 64];
        if (context.state.form.accessType === ACCESS_TYPE.USER_CODE.value) {
          let x = 0;
          for (let i = context.state.form.code.length - 1; i >= 0; i -= 1) {
            code[x] = parseInt(context.state.form.code[i], 10);
            x += 1;
          }
        } else if (context.state.form.code.length === TAG_LENGTH) {
          for (let i = 0; i < TAG_LENGTH; i += 2) {
            code[i / 2] = ((i === 0) ? 128 : 0) + parseInt(context.state.form.code.substr(i, 2), 16);
          }
        }

        data.Code = code; // only add the code if it's been changed
      }

      data.UserType = getApiUserType(index, context.state.form.userType);
      data.UserAreas = context.state.form.areas;
      data.UserSetOption = context.state.form.setOption;
      data.UserFlexiSet = context.state.form.flexiSet;

      data.UserCanAccess = [];

      for (let i = 0; i < limits.MAX_SUBAREAS; i += 1) {
        const door = context.state.form.associatedDoors.find(d => d.value === i);
        data.UserCanAccess[i] = door != null;
      }

      await api.put(`/Config/userInfo/Users/${index}`, data);

      if (context.state.form.accessType === ACCESS_TYPE.KEY_FOB.value) {
        const buttons = generateButtonsObj(context.state.form);
        await api.put(`/Config/radioFobInfo/RadioFobs/${context.state.fobIndex}/Buttons`, buttons);
        await api.put(`/Config/radioFobInfo/RadioFobs/${context.state.fobIndex}/LevelMode`, context.state.form.levelMode);
      }

      if (canHaveAutoDuress(api, index, context.state.form.accessType)) {
        await api.put('/Live/userInfo/AutoDuressUser', index);
      }

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('usersState/user/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['usersState/user'] });
    },
    async delete(context) {
      const { index } = context.state.form;
      const objDefaults = await generateDefaultObject(`/Config/userInfo/Users/${index}`);
      objDefaults.Code = [64, 64, 64, 64, 64, 64];
      objDefaults.UserAreas.fill(false, 1);
      // eslint-disable-next-line no-await-in-loop
      await api.put(`/Config/userInfo/Users/${index}`, objDefaults);

      if (context.state.form.accessType === ACCESS_TYPE.KEY_FOB.value) {
        await api.put(`/Live/Devices/WirelessFobs/${context.state.fobIndex}/DeleteLearnedDevice`, true);
      }

      if (canHaveAutoDuress(api, index, context.state.form.accessType)) {
        await api.put('/Live/userInfo/AutoDuressUser', index);
      }

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('usersState/list/requiredEndpoints');
      const requiredEndpointsAddUser = (await this.dispatch('usersState/addUser/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const requiredEndpointsAddKeyfob = (await this.dispatch('usersState/addKeyFob/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp) && !requiredEndpointsAddUser.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddUser, requiredEndpointsAddKeyfob);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['usersState/list'] });
    },
  },
});

export default store;
