<template>
    <a-dropdown class="grid-filter" @visibleChange="visibleChanged" :visible="dropdownVisible">
        <a-menu slot="overlay">
            <a-menu-item v-for="(filter, index) in filters" :key="`filter-${index}`">
                <a-checkbox :checked="filter.checked" @change="onCheckboxChange(filter, $event)">
                  <template v-if="filter.key === $t('common.All')">
                    {{ filter.key }} <span v-if="filter.count">({{ filter.count}})</span>
                  </template>
                  <template v-else-if="customRender">
                    {{ filter.key }} <span v-if="filter.count">({{ filter.count}})</span>
                  </template>
                  <template v-else>
                    {{ resPrefix ? $t(resPrefix + filter.key) : filter.key }} <span v-if="filter.count">({{ filter.count}})</span>
                  </template>
                    </a-checkbox>
            </a-menu-item>
        </a-menu>
        <a-button>
            {{ title }} <a-icon type="down" />
        </a-button>
    </a-dropdown>
</template>

<script>
import _ from 'lodash';

export default {
  components: {

  },
  props: {
    title: { required: true },
    records: { required: true },
    field: { required: true },
    resPrefix: { required: false },
    customRender: { required: false, default: undefined },
    customValue: { required: false, default: undefined },
    initialFilters: { required: false, default: undefined },
  },
  data() {
    // Which items (in the dropdown) are initially selected (checked):
    // - If an initial selection is specified (typically restored from a previously saved selection),
    //   restore it.
    // else:
    // - (initialFilters is null or undefined) by default just select the "All" item.
    const selectedFilters = (this.initialFilters != null) ? this.initialFilters : [{ key: this.$t('common.All'), value: null }];

    // Apply the initial filter selection.
    this.$emit('change', { key: this.field, selectedFilters });

    return {
      dropdownVisible: false,
      selectedFilters,
    };
  },
  computed: {
    filters() {
      const self = this;
      const counts = {};
      this.records.forEach((r) => {
        if (_.isArray(r[this.field])) {
          r[this.field].forEach((v) => {
            const key = this.customRender ? this.customRender(v) : v;
            const value = this.customValue ? this.customValue(v) : v;
            if (!counts[key]) counts[key] = { value, count: 1 };
            else counts[key].count += 1;
          });
        } else {
          const key = this.customRender ? this.customRender(r[this.field]) : r[this.field];
          const value = this.customValue ? this.customValue(r[this.field]) : r[this.field];
          if (!counts[key]) counts[key] = { value, count: 1 };
          else counts[key].count += 1;
        }
      });

      const filters = Object.keys(counts).map(key => ({
        key, value: counts[key].value, count: counts[key].count, checked: self.filterSelected({ key, value: counts[key].value }),
      }));
      filters.sort((l, r) => {
        if (l.value.key !== r.value.key) { // r.value.key is the area number
          return (l.value.key - r.value.key);
        }
        if (l.value.name < r.value.name) { // r.value.name == r.key is the label
          return -1;
        }
        return 1;
      });
      filters.unshift({
        key: this.$t('common.All'), value: null, count: null, checked: self.filterSelected({ key: self.$t('common.All'), value: null }),
      });
      return filters;
    },
  },
  methods: {
    visibleChanged(e) {
      this.dropdownVisible = e;
    },
    onCheckboxChange(filter, e) {
      // Check whether it's the "All" item which has changed state.
      if (filter.value === null) {
        if (e.target.checked) {
          // "All" changed from unchecked to checked: set the filter list to a single null value,
          // which signifies all grid items are selected.
          this.selectedFilters = [{ key: this.$t('common.All'), value: null }];
        } else {
          // "All" changed from checked to unchecked: set the filter list to be empty, meaning
          // NO grid items are selected.
          this.selectedFilters = [];
        }
        this.$emit('change', { key: this.field, selectedFilters: this.selectedFilters });
        return;
      }

      // Otherwise, it must be a specific filter item which has changed state.
      if (e.target.checked) {
        // Changed from unchecked to checked: add the filter value to the list.
        this.selectedFilters.push({ key: filter.key, value: filter.value });
      } else if (this.selectedFilters.filter(f => _.isEqual(f.value, null)).length > 0) {
        // Changed from checked to unchecked AND "All" is checked: remove the filter value from the list, and 'all'
        // But start from the 'full' set.
        this.selectedFilters = this.filters.filter(f => (f.key !== filter.key) && (f.value !== null));
      } else {
        // Changed from checked to unchecked AND "All" isn't checked: remove the filter value from
        // the list.
        this.selectedFilters = this.selectedFilters.filter(f => f.key !== filter.key);
      }

      // If ALL filter items (except for the "All" item, which cannot be checked at this point) are
      // now checked, then set the filter list to "All" being checked.
      if (this.selectedFilters.length === (this.filters.length - 1)) {
        this.selectedFilters = [{ key: this.$t('common.All'), value: null }];
      }

      this.$emit('change', { key: this.field, selectedFilters: this.selectedFilters, isFilterByChar: false });
    },
    filterSelected(filter) {
      // The specified filter item is shown as checked if:
      // - The "All" item is selected.
      // OR:
      // - The specified item is selected.
      return (this.selectedFilters.filter(f => _.isEqual(f.value, null)).length > 0) || (this.selectedFilters.filter(f => _.isEqual(f.value, filter.value)).length > 0);
    },
  },
};
</script>

<style lang="scss">
    .grid-filter {
        color: #484848 !important;
    }
</style>
