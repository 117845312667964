import ZoneExpanders from './zone-expanders.vue';
import ZoneExpandersList from './pages/zone-expanders-list/zone-expanders-list.vue';
import WiredZoneExpander from './pages/wired-zone-expander/wired-zone-expander.vue';
import WirelessZoneExpander from './pages/wireless-zone-expander/wireless-zone-expander.vue';

const routes = [
  {
    path: '/zone-expanders',
    component: ZoneExpanders,
    children: [
      {
        path: '',
        name: 'zone-expanders-list',
        component: ZoneExpandersList,
        meta: { breadcrumb: [{ nameRes: 'zoneExpanders.navigationTitle' }] },
      },
      {
        path: 'wired-zone-expander/:index',
        name: 'wired-zone-expander',
        component: WiredZoneExpander,
        meta: { breadcrumb: [{ nameRes: 'zoneExpanders.navigationTitle', link: 'zone-expanders-list' }, { nameRes: 'wiredZoneExpander.navigationTitle' }] },
      },
      {
        path: 'wireless-zone-expander/:index',
        name: 'wireless-zone-expander',
        component: WirelessZoneExpander,
        meta: { breadcrumb: [{ nameRes: 'zoneExpanders.navigationTitle', link: 'zone-expanders-list' }, { nameRes: 'wirelessZoneExpander.navigationTitle' }] },
      },
    ],
  },
];

export default routes;
