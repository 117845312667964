const StringFormat = {
  install() {
    if (!String.prototype.format) {
      // eslint-disable-next-line no-extend-native
      String.prototype.format = function format(...args) {
        const myArgs = Array.isArray(args[0]) ? args[0] : args;
        return this.replace(/{(\d+)}/g, (match, number) => (typeof myArgs[number] !== 'undefined'
          ? myArgs[number]
          : match));
      };
    }
  },
};

export default StringFormat;
