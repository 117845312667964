<template>
    <div class="indicator">
        {{ $t('common.Loading')}}
        <br>
        <a-icon type="loading" />
    </div>
</template>

<script>
export default {

};
</script>

<style scoped lang="scss">
    @import 'loading-indicator.scss';
</style>
