/* eslint-disable lines-between-class-members */
import { encryptionEnabled } from '@/app/environment/environment';

export const SECURITY_MODE = {
  LOW: 0,
  HIGH: 1,
  LOGGED_OUT: 2,
};


class AuthState {
  mode = null;
  sessionId = null;
  encryptionKey = null;

  isAuthenticated() {
    if (this.mode === SECURITY_MODE.HIGH) {
      return !encryptionEnabled || this.sessionId != null;
    }

    if (this.mode === SECURITY_MODE.LOW) {
      return !encryptionEnabled || (this.sessionId != null && this.encryptionKey != null);
    }

    return !encryptionEnabled || false;
  }

  reset() {
    this.sessionId = null;
    this.encryptionKey = null;
    this.dhGenerator = null;
    this.dhPrime = null;
    this.dhServerKey = null;
    this.salt = null;
    this.challenge = null;
  }

  resetMode() {
    this.mode = null;
  }
}

const state = new AuthState();
export default state;
