<template>
  <a-dropdown @visibleChange="visibleChanged" :visible="dropdownVisible" :trigger="['click']" :disabled="disabled">
    <div slot="overlay" class="level-selector-dropdown">
      <a-menu>
        <a-menu-item>
          <a-checkbox :checked="allSelected" @change="toggleSelectAll">
            <span v-if="allSelected">Deselect all</span>
            <span v-else>Select all</span>
          </a-checkbox>
        </a-menu-item>
        <a-menu-item-group v-for="(area, index) in areas" :key="`area-${index}`">
          <template slot="title">
            <span>
              {{ $t('common.Area') }} {{ area.identifier }} - {{ area.name }}
            </span>
          </template>
          <a-menu-item v-for="(level, index) in area.levels" :key="`level-${index}`">
            <a-checkbox :checked="level.checked" @change="onCheckboxChange(level, $event)">
              {{ level.identifier }} - {{ level.name }}
            </a-checkbox>
          </a-menu-item>
        </a-menu-item-group>
      </a-menu>
    </div>
    <a-button class="ant-dropdown-trigger">
      <span style="overflow: hidden;">
        {{ title }}
      </span>
      <a-icon type="down" />
    </a-button>
  </a-dropdown>
</template>

<script>
import { LEVEL_IDS } from '@/app/shared/constants';

export default {
  props: ['value', 'areasInfo', 'areasConfig', 'disabled'],
  data() {
    return {
      dropdownVisible: false,
      levelIDs: LEVEL_IDS,
      areas: [],
    };
  },
  watch: {
    value(newValue, oldValue) {
      // When new area data comes through from the parent component, update the areas structure
      if (newValue !== oldValue) {
        this.initialise();
      }
    },
    areasConfig(newValue, oldValue) {
      if (newValue !== undefined && newValue !== oldValue) {
        this.initialise();
      }
    },
  },
  computed: {
    title() {
      // Get the areas which have at least one level checked.
      const selectedAreas = [];
      this.areas.forEach((area) => {
        if (area.levels.some(level => level.checked)) {
          selectedAreas.push(area.identifier);
        }
      });
      // Return the area identifiers, comma separated.
      return selectedAreas.join(',');
    },
    allSelected() {
      let x = true;
      this.areas.forEach((area) => {
        area.levels.forEach((level) => {
          if (level.checked === false) {
            x = false;
          }
        });
      });
      return x;
    },
  },
  methods: {
    initialise() {
      if (this.areasConfig !== undefined) {
        // Populate the internal data structure used for the drop down display.
        this.areas = this.areasConfig.Areas.map((areaDetails, i) => ({
          index: i,
          identifier: this.areasInfo.Areas[i].Identifier,
          name: areaDetails.Name,
          levels: areaDetails.Level.map((levelDetails, j) => ({
            index: j,
            identifier: this.levelIDs[j],
            name: levelDetails.Name,
            checked: (this.value !== undefined) && (this.value[i].Levels[j] === true),
          })),
        }));
      }
    },
    visibleChanged(e) {
      this.dropdownVisible = e;
    },
    toggleSelectAll(e) {
      this.areas.forEach((a) => {
        a.levels.forEach((l) => { l.checked = e.target.checked; });
      });
      this.emitValues();
    },
    emitValues() {
      const allValue = this.areas.map(area => ({
        Levels: area.levels.map(level => level.checked),
      }));
      this.$emit('input', allValue);
    },
    onCheckboxChange(selectedLevel, e) {
      // Save the new check box state in the internal data structure.
      selectedLevel.checked = e.target.checked;
      // Write all the check box states (in the internal data structure) back to the panel's Config
      // endpoint.
      const newValue = this.areas.map(area => ({
        Levels: area.levels.map(level => level.checked),
      }));
      this.$emit('input', newValue);
    },
  },
  created() {
    // Create the initial structure with existing data from the parent component
    this.initialise();
  },
};
</script>

<style lang="scss">
/* Specify the style for the level selector drop down box. It's quite long (lots of areas, each
   with several levels) so limit its height, and add a vertical scroll bar. */
.level-selector-dropdown {
  border-style: solid;
  border-width: 1px;
  max-height: 60ex;
  overflow-y: scroll;
}
/* Specify the style for each area heading within the level selector drop down box. Reduce the
   vertical spacing, to squash everything up as much as possible. */
.level-selector-dropdown .ant-menu-item-group-title {
  padding-top: 4px;
  padding-bottom: 0;
}
/* Specify the style for each level selection check box within the level selector drop down box.
   Reduce the vertical spacing, to squash everything up as much as possible. */
.level-selector-dropdown .ant-menu-item {
  line-height: 1.1;
  height: 1.2em;
  margin: 2px;
}
form .ant-dropdown-trigger{
  width: 100%;
  color: #484848;

  span {
    width:100%;
  }
  i {
    color: #484848 !important;
    margin-left: -8px !important;
  }
}
</style>
