<template>
    <a-drawer
    placement="right"
    :closable="false"
    @close="close"
    :destroyOnClose="true"
    :visible="visible">

    <wizard :config="wizard" :state="$v" @save="save" @done="close" :initialStep="clonePayload != null ? 'step-1' : 'start'">

        <wizard-step name="start">
          <template slot="body" slot-scope="{ wizard }">
            <a-button class="draw-link" @click="wizard.navigateTo('autoscan')" type="link" block>
              <text-row :title="$t('common.Autoscan-wired-only')" title-icon="search" />
            </a-button>
            <a-button class="draw-link" @click="wizard.navigateTo('learn')" type="link" block>
              <text-row :title="$t('zones.drawer.Learn-wireless-zones')" title-icon="wifi" />
            </a-button>
            <a-button class="draw-link" @click="wizard.navigateTo('step-1')" type="link" block>
              <text-row :title="$t('common.Add-manually')" title-icon="plus-circle" />
            </a-button>
          </template>
        </wizard-step>

        <wizard-step name="autoscan">
          <template slot="title">
            {{ $tc('zones.drawer.autoscan.Scanning', 0, { count: zonesFound }) }}
            <a class="subtitle" @click="cancelAutoscan" v-if="isScanning">{{ $t('common.Stop-scanning') }}</a>
            <a class="subtitle" @click="startAutoscan" v-else>{{ $t('common.Scan-again') }}</a>
          </template>

          <text-row :title="$tc('zones.drawer.autoscan.Zones-found', zonesFound, { count:zonesFound })" :type="isScanning ? 'testing' : 'testComplete'" />
          <a-button type="primary" block v-if="!isScanning" @click="finishAutoscan">{{ $t('common.Finish') }}</a-button>
        </wizard-step>

        <wizard-step name="learn">
           <learn-wireless @done="close" :start-at="{ stage: 'info', type: 'zones' }" @learnZonesStarted="onLearnZonesStarted" :manuallyAssociatedZone="manuallyAddedZone"/>
        </wizard-step>

        <wizard-step name="step-1">
          <a-form>
            <p-select :label="$t('common.Associated-with')" :model="$v.form.associatedWithDevice" @change="$v.form.zoneIndex.$model = ''; $v.form.name.$model = '';" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              :items="associatedDevices" :valueExpr="(i) => i.key" :textExpr="(i) => i.name" />
            <p-select :label="$t('common.Number')" :model="$v.form.zoneIndex" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              :items="zoneIndexes" :valueExpr="(i) => i" :textExpr="(i) => (i + 1)" @change="$v.form.name.$model = $v.form.zoneNames.$model[$v.form.zoneIndex.$model]"/>
            <p-select :label="$t('zones.Zone-type')" :model="$v.form.zoneType" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              :items="Object.keys(ZONE_TYPE)" :valueExpr="(i) => i" :textExpr="(i) => $t(`zones.enums.zoneType.${i}`)" />
            <p-input :label="$t('common.Name')" :model="$v.form.name" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-input :label="$t('common.Location')" :model="$v.form.location" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-level-selector :label="$t('common.Areas')" :model="$v.form.areas" :areasInfo="$v.form.areasInfo.$model" :areasConfig="$v.form.areasConfig.$model" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-switch :label="$t('zones.Common-input')" :model="$v.form.anyAllArea" />
          </a-form>
        </wizard-step>

        <wizard-step name="step-2">
          <a-form>
            <p-select :label="$t('zones.Chime')" :model="$v.form.chime" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              :items="Object.keys(CHIMES)" :valueExpr="(i) => CHIMES[i]" :textExpr="(i) => $t(`enums.CHIMES.${i}`)" />
            <p-switch :label="$t('zones.Allow-bypass')" :model="$v.form.allowByPass" v-if="isOmittable" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-switch :label="$t('zones.Double-knock')" :model="$v.form.doubleKnock" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-switch :label="$t('zones.Cross-zone')" :model="$v.form.crossZone" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-switch :label="$t('zones.Normally-open')" :model="$v.form.normallyOpen" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-switch :label="$t('zones.Mask-test')" :model="$v.form.maskTest" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-switch :label="$t('zones.Non-activity-input')" :model="$v.form.nonActivityInput" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-switch :label="$t('zones.Occupancy')" :model="$v.form.occupancy" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-select :label="$t('zones.Special-log')" :model="$v.form.specialLogged" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              :items="Object.keys(SPECIAL_LOGGED)" :valueExpr="(i) => SPECIAL_LOGGED[i]" :textExpr="(i) => $t(`enums.SPECIAL_LOGGED.${i}`)" />

            <p-input :label="$t('zones.Swinger-limit')" :model="$v.form.swingerLimit" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />

            <p-select :label="$t('zones.Supervision')" :model="$v.form.supervision" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              :items="Object.keys(SUPERVISION)" :valueExpr="(i) => i" :textExpr="(i) => $t(`zones.enums.SUPERVISION.${i}`)"
              v-if="$v.form.zoneIndex.$model != null && connectionType[$v.form.zoneIndex.$model] === 'TYPE_WIRELESS'" />

            <p-input :label="$t('zones.Confirm-group')" :model="$v.form.confirmGroup" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <p-switch :label="$t('zones.Inertia-input')" :model="$v.form.inertiaInput" @change="inertiaInputChanged" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            <div class="indent" v-if="$v.form.inertiaInput.$model">
              <p-input :label="$t('zones.Gross-attack')" :model="$v.form.inertiaDebounceTime" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
              <p-input :label="$t('zones.Inertia-pulse-count')" :model="$v.form.inertiaDebounceCount" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
            </div>

            <p-select :label="$t('zones.EOL-resistor-range')" :model="$v.form.eolResistorRange" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              v-if="$v.form.zoneIndex.$model != null && perZoneOptions[$v.form.zoneIndex.$model] == true" @change="eolResistorRangeChanged"
              :items="Object.keys(ZONE_RESISTOR_RANGE)" :valueExpr="(i) => ZONE_RESISTOR_RANGE[i]" :textExpr="(i) => $t(`enums.ZONE_RESISTOR_RANGE.${i}`)"
              :customValidations="[{ key: 'mustBeValidEOLRange', message: $t('zones.Must-be-valid-eol-range') }]" />

            <p-select :label="$t('zones.EOL-resistor-mode')" :model="$v.form.eolResistorMode" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              v-if="$v.form.zoneIndex.$model != null && perZoneOptions[$v.form.zoneIndex.$model] == true" @change="eolResistorModeChanged"
              :items="Object.keys(ZONE_RESISTOR_MODE)" :valueExpr="(i) => ZONE_RESISTOR_MODE[i]" :textExpr="(i) => $t(`enums.ZONE_RESISTOR_MODE.${i}`)"
              :customValidations="[{ key: 'mustBeValidEOLMode', message: $t('zones.Must-be-valid-eol-mode') }]" />

            <!-- <p-switch :label="$t('zones.Soak-test')" :model="$v.form.soakTest" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" /> -->
          </a-form>

        </wizard-step>

    </wizard>
  </a-drawer>
</template>
<script>
import { required, maxLength, between } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';

import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';
import LearnWireless from '@/app/shared/components/add-devices-drawer/learn-wireless.vue';

import { Clone } from '@/app/shared/mixins';
import {
  CHIMES, SPECIAL_LOGGED, SUPERVISION, ZONE_TYPE, ZONE_RESISTOR_MODE, ZONE_RESISTOR_RANGE,
} from '@/app/zones/shared/enums';
import autoscan from '@/app/zones/shared/services/autoscan';
import { mustBeValidEOLRange, mustBeValidEOLMode } from '@/app/zones/shared/services/validations';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { canBeOmitted } from '@/app/zones/shared/services/helpers';

const storeNamespace = 'zonesState/addZone';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [Clone],
  components: {
    Wizard,
    WizardStep,
    LearnWireless,
  },
  data() {
    return {
      manuallyAddedZone: null,
      clone: {
        storeNamespace,
      },

      isScanning: false,
      zonesFound: undefined,

      CHIMES,
      SPECIAL_LOGGED,
      SUPERVISION,
      ZONE_TYPE,
      ZONE_RESISTOR_MODE,
      ZONE_RESISTOR_RANGE,
      wizard: {
        title: this.$t('zones.drawer.Add-a-zone'),
        start: {
          title: this.$t('zones.drawer.How-do-you-want-to-add-zones'),
          next: [],
        },
        autoscan: {
          onShow: () => this.startAutoscan(),
        },
        learn: {
          mode: 'SIMPLE',
        },
        'step-1': {
          progress: 33,
          onExitValidate: 'step1',
          next: [
            { key: 'step-2', buttonText: this.$t('common.Next') },
          ],
        },
        'step-2': {
          progress: 66,
          next: [
            { key: 'save', buttonText: this.$t('common.Add-zone') },
          ],
        },
      },
    };
  },
  validations: {
    step1: ['form.name', 'form.associatedWithDevice', 'form.zoneIndex', 'form.zoneType', 'form.location', 'form.areas', 'form.anyAllArea'],
    form: {
      zoneIndex: {
        required,
      },
      name: {
        required,
        maxLength: maxLength(16),
      },
      zoneType: {
        required,
      },
      location: {
        maxLength: maxLength(16),
      },
      areas: {},
      areasInfo: {},
      areasConfig: {},
      zoneNames: {},
      anyAllArea: {},
      associatedWithDevice: {
        required,
      },
      chime: {
        required,
      },
      allowByPass: {},
      crossZone: {},
      normallyOpen: {},
      specialLogged: {},
      nonActivityInput: {},
      occupancy: {},
      swingerLimit: {
        between: between(0, 99),
      },
      supervision: {},
      confirmGroup: {
        required,
        between: between(0, 99),
      },
      eolResistorMode: {
        mustBeValidEOLMode: mustBeValidEOLMode(true),
      },
      eolResistorRange: {
        mustBeValidEOLRange: mustBeValidEOLRange(true),
      },
      doubleKnock: {},
      maskTest: {},
      inertiaInput: {},
      inertiaDebounceCount: {
        between: between(0, 20),
      },
      inertiaDebounceTime: {
        between: between(0, 99),
      },
      // soakTest: {},
    },
  },
  computed: {
    ...mapState(['form', 'associatedDevices', 'perZoneOptions', 'connectionType', 'manuallyAssociatedZone']),
    zoneIndexes() {
      const device = (this.associatedDevices || []).find(v => v.key === this.$v.form.associatedWithDevice.$model);
      return device ? device.availableZonesIndexes : [];
    },
    isOmittable() {
      return canBeOmitted(this.$v.form.zoneType.$model);
    },
  },
  watch: {
    // Whenever we close the wizard, the component should be reset
    visible(newVal) {
      if (!newVal) {
        this.$store.dispatch('zonesState/addZone/reset');
        this.$v.$reset();
      }
    },
  },
  methods: {
    ...mapActions(['add']),
    async close() {
      await autoscan.cancel();
      this.$emit('update:visible', false);
      this.$emit('onClose');
    },
    async startAutoscan() {
      // Regardless of the outcome of scanning zones, clear this flag to recalculate if zone is in area.
      // No need to clear this flag again in the cancel function.
      window.globalThis.zoneLevelsRequireReread = true;
      this.isScanning = true;
      this.zonesFound = 0;

      this.zonesFound = await autoscan.scan();
      // with autoscan complete, reload the zone-list and wizard endpoints
      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.$store.dispatch('zonesState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.$store.dispatch('zonesState/addZone/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['zonesState/list'] });
      this.isScanning = false;
    },
    async cancelAutoscan() {
      await autoscan.cancel();
      this.$emit('update:visible', false);
      this.$emit('onClose');
    },
    async finishAutoscan() {
      await autoscan.cancel();
      this.$emit('update:visible', false);
      this.$emit('onClose');
    },
    async save() {
      // This is for the 'add zone manually' option - Regardless of the outcome of manually adding a zone,
      // clear this flag to recalculate if zone is in area.
      window.globalThis.zoneLevelsRequireReread = true;
      // No need to clear this flag again in the add-zone-data.js' add() function.
      await this.add();
      this.$emit('update:visible', false);
      this.$emit('onClose');
      this.manuallyAddedZone = this.manuallyAssociatedZone;

      if (!this.navigateToNewItem) {
        return;
      }

      this.$router.push({ name: 'zone', params: { index: this.form.zoneIndex } });
    },
    onLearnZonesStarted() {
      // Regardless of the outcome of learning a wireless zones, clear this flag to recalculate if zone is in area.
      window.globalThis.zoneLevelsRequireReread = true;
      this.manuallyAddedZone = null;
    },
    inertiaInputChanged() {
      this.$v.form.inertiaDebounceCount.$model = 4;
      this.$v.form.inertiaDebounceTime.$model = 4;
      if (this.$v.form.inertiaInput.$model === true) {
        this.$v.form.eolResistorMode.$model = 'DOUBLE';
        this.$v.form.eolResistorRange.$model = 'ZONE_EOL_1K_1K';
      }
    },
    eolResistorRangeChanged() {
      if (this.$v.form.eolResistorRange.$model === 'ZONE_EOL_0K_0K') {
        // Choosing EOL resistance of 0K/0K implies EOL mode of "normally closed".
        this.$v.form.eolResistorMode.$model = 'NORMALLY_CLOSED';
      }
      this.$v.form.eolResistorMode.$touch();
    },
    eolResistorModeChanged() {
      if (this.$v.form.eolResistorMode.$model === 'NORMALLY_CLOSED') {
        // Choosing EOL mode of "normally closed" implies EOL resistance of 0K/0K.
        this.$v.form.eolResistorRange.$model = 'ZONE_EOL_0K_0K';
      }
      this.$v.form.eolResistorRange.$touch();
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

</style>
