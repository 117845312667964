const {
  VUE_APP_API_BASE_URL, VUE_APP_DEFAULT_LOCAL = 'en-GB', NODE_ENV = '', VUE_APP_DISABLE_ENCRYPTION = 'false', VUE_APP_VERSION,
} = process.env;

const environment = NODE_ENV.toLowerCase();
const apiBaseUrl = window.base_url || VUE_APP_API_BASE_URL;
const defaultLocale = VUE_APP_DEFAULT_LOCAL;
const encryptionEnabled = VUE_APP_DISABLE_ENCRYPTION !== 'true';
const version = VUE_APP_VERSION;

export {
  environment, apiBaseUrl, defaultLocale, encryptionEnabled, version,
};
