import Maintenance from './maintenance.vue';
import Landing from './pages/landing/landing.vue';

const routes = [
  {
    path: '/maintenance',
    component: Maintenance,
    children: [
      {
        path: '',
        name: 'maintenance',
        component: Landing,
        meta: { breadcrumb: [{ nameRes: 'maintenance.navigationTitle' }] },
      },
    ],
  },
];

export default routes;
