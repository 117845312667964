/* eslint-disable class-methods-use-this */

// Rather than having one huge monolithic Config.zoneInfo.Zones.Areas endpoint for all 99 areas which takes 5+sec to load
// split it into ten smaller endpoints (areas 0 to 9, 10 to 19 etc) which each take only 0.5s to load.
// Then when adding/deleting a zone, only need to refresh a smaller subset of endpoints depending on which areas the zone is in.

class ZoneAreasHelper {
  zoneAreasEndpointsList = ['configZoneInfoAreas00s', 'configZoneInfoAreas10s', 'configZoneInfoAreas20s',
    'configZoneInfoAreas30s', 'configZoneInfoAreas40s', 'configZoneInfoAreas50s', 'configZoneInfoAreas60s',
    'configZoneInfoAreas70s', 'configZoneInfoAreas80s', 'configZoneInfoAreas90s'];

  getAllAreaDetailsForZone(endpoints, zoneI) {
    // For one zone, with index zoneI, return all ten area endpoints as one, simulating the original monolithic configZone InfoAreas endpoint.
    const allAreas = [];
    // d is some decade cutoff: to ensure that e.g. only the ten areas with indexes 50..59 of configZoneInfoArea50s are added to the final array.
    // TODO Find some way to replace this mess with a programmatic solution
    let d = 0; endpoints.configZoneInfoAreas00s.data.Config.zoneInfo.Zones[zoneI].Areas.forEach((area, areaI) => { if (areaI >= d) allAreas.push(area); });
    d += 10; endpoints.configZoneInfoAreas10s.data.Config.zoneInfo.Zones[zoneI].Areas.forEach((area, areaI) => { if (areaI >= d) allAreas.push(area); });
    d += 10; endpoints.configZoneInfoAreas20s.data.Config.zoneInfo.Zones[zoneI].Areas.forEach((area, areaI) => { if (areaI >= d) allAreas.push(area); });
    d += 10; endpoints.configZoneInfoAreas30s.data.Config.zoneInfo.Zones[zoneI].Areas.forEach((area, areaI) => { if (areaI >= d) allAreas.push(area); });
    d += 10; endpoints.configZoneInfoAreas40s.data.Config.zoneInfo.Zones[zoneI].Areas.forEach((area, areaI) => { if (areaI >= d) allAreas.push(area); });
    d += 10; endpoints.configZoneInfoAreas50s.data.Config.zoneInfo.Zones[zoneI].Areas.forEach((area, areaI) => { if (areaI >= d) allAreas.push(area); });
    d += 10; endpoints.configZoneInfoAreas60s.data.Config.zoneInfo.Zones[zoneI].Areas.forEach((area, areaI) => { if (areaI >= d) allAreas.push(area); });
    d += 10; endpoints.configZoneInfoAreas70s.data.Config.zoneInfo.Zones[zoneI].Areas.forEach((area, areaI) => { if (areaI >= d) allAreas.push(area); });
    d += 10; endpoints.configZoneInfoAreas80s.data.Config.zoneInfo.Zones[zoneI].Areas.forEach((area, areaI) => { if (areaI >= d) allAreas.push(area); });
    d += 10; endpoints.configZoneInfoAreas90s.data.Config.zoneInfo.Zones[zoneI].Areas.forEach((area, areaI) => { if (areaI >= d) allAreas.push(area); });
    return allAreas;
  }

  _getZoneAreaEndpointFromAreaIndex(areaI) {
    // Determine which one of ten zone area endpoints is associated with an area index.
    // Use if-immediate as it is fastest
    if (areaI < 10) return ('configZoneInfoAreas00s');
    if (areaI < 20) return ('configZoneInfoAreas10s');
    if (areaI < 30) return ('configZoneInfoAreas20s');
    if (areaI < 40) return ('configZoneInfoAreas30s');
    if (areaI < 50) return ('configZoneInfoAreas40s');
    if (areaI < 60) return ('configZoneInfoAreas50s');
    if (areaI < 70) return ('configZoneInfoAreas60s');
    if (areaI < 80) return ('configZoneInfoAreas70s');
    if (areaI < 90) return ('configZoneInfoAreas80s');
    return ('configZoneInfoAreas90s');
  }

  getZoneAreaEndpointsFromAreaStrs(context, zoneAreasStrs) {
    // For a zone with areas represented by zoneAreasStrs, return all the configZoneInfoArea endpoints in use.
    const endpoints = [];
    // zoneAreasStrs example ['Area 31: Level A', 'Area 42: Level B']
    zoneAreasStrs.forEach((area) => {
      const decomposed = area.name.split(' ');
      const areaNumberAsStr = decomposed[1].slice(0, -1); // Lose the trailing ':'
      const areaI = Number(areaNumberAsStr) - 1; // From human-readable number to area index.
      endpoints.push(this._getZoneAreaEndpointFromAreaIndex(areaI));
    });
    return endpoints;
  }

  getZoneAreaEndpointsFromArea(context, areasArray) {
    // Return the zone area endpoints used by a single zone whose areas are represented by areasArray
    const endpoints = [];
    // areasArray example Area.Levels[true, false, false, false]
    areasArray.forEach((area, areaI) => {
      let areaHasLevels = false;
      area.Levels.forEach((level) => {
        // If any level in this area is being used, set the flag.
        if (level === true) {
          areaHasLevels = true;
        }
      });
      if (areaHasLevels) {
        endpoints.push(this._getZoneAreaEndpointFromAreaIndex(areaI));
      }
    });
    return endpoints;
  }
}

const helper = new ZoneAreasHelper();
export default helper;
