<template>
    <a-drawer
    placement="right"
    :closable="false"
    @close="onClose"
    :destroyOnClose="true"
    :visible="visible">

    <wizard :config="wizard" @done="onClose">
        <wizard-step name="start">
          <template slot="body" >
            <div>
              <a-col>
                <hr>
                <a-row>
                  {{$t(`maintenance.drawer.batteryTest.faults.batteryLoadTest`)}}
                  <div style="float:right">
                    <font-awesome-icon v-if="batteryLoadTest === true" class="error" :icon="['fal', 'exclamation-triangle']" size="2x" />
                    <font-awesome-icon v-else-if="batteryLoadTest === false" class="success" :icon="['fal', 'check-circle']" size="2x" />
                    <font-awesome-icon v-else class="default" :icon="['fal', 'ellipsis-h']" size="2x" />
                  </div>
                </a-row>
                <hr>
                <a-row>
                  {{$t(`maintenance.drawer.batteryTest.faults.batteryVoltageCritical`)}}
                  <div style="float:right">
                    <font-awesome-icon v-if="batteryVoltageCritical === true" class="error" :icon="['fal', 'exclamation-triangle']" size="2x" />
                    <font-awesome-icon v-else-if="batteryVoltageCritical === false" class="success" :icon="['fal', 'check-circle']" size="2x" />
                    <font-awesome-icon v-else class="default" :icon="['fal', 'ellipsis-h']" size="2x" />
                  </div>
                </a-row>
                <hr>
                <a-row>
                  {{$t(`maintenance.drawer.batteryTest.faults.batteryVoltageLow`)}}
                  <div style="float:right">
                    <font-awesome-icon v-if="batteryVoltageLow === true" class="error" :icon="['fal', 'exclamation-triangle']" size="2x" />
                    <font-awesome-icon v-else-if="batteryVoltageLow === false" class="success" :icon="['fal', 'check-circle']" size="2x" />
                    <font-awesome-icon v-else class="default" :icon="['fal', 'ellipsis-h']" size="2x" />
                  </div>
                </a-row>
                <hr>
              </a-col>
            </div>
          </template>
        </wizard-step>

        <wizard-step name="run">
          <template slot="title">
            {{ testing ? $t('maintenance.drawer.batteryTest.Battery-test-inprogress') :
              success ? $t('maintenance.drawer.batteryTest.Battery-test-Passed') : $t('maintenance.drawer.batteryTest.Battery-test-Failed')}}
            <a class="subtitle" @click="startTest" v-if="!testing">{{ $t('maintenance.drawer.batteryTest.Test-again')}}</a>
          </template>

          <template slot="body">
            <div class="align-center">

              <font-awesome-icon v-if="testing" class="fa-spin" :icon="['fal', 'spinner']" size="10x" />
              <font-awesome-icon v-else-if="success" :icon="['fal', 'check-circle']" size="10x" class="success" />
              <font-awesome-icon v-else :icon="['fal', 'times-circle']" size="10x" class="error" />
              <div  v-if="!testing && success">
                <h3 class="h4">{{ $t('maintenance.drawer.batteryTest.Last-battery-reading-is') }}</h3>
                <span class="h1">{{ voltage }}<sub>v</sub> {{ current }}<sub>a</sub></span>
              </div>
              <a-button block type="primary" @click="completeTest" style="margin-top:20px;">{{ $t('common.Complete-test') }}</a-button>
            </div>
          </template>

        </wizard-step>

      </wizard>
    </a-drawer>
</template>
<script>
import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';

import batteryTest from '@/app/maintenance/shared/services/battery-test';

export default {
  components: {
    Wizard,
    WizardStep,
  },
  props: {
    visible: { default: false },
  },
  data() {
    return {
      form: {},
      testing: undefined,
      success: undefined,
      voltage: undefined,
      current: undefined,
      batteryLoadTest: undefined,
      batteryVoltageCritical: undefined,
      batteryVoltageLow: undefined,
      wizard: {
        start: {
          title: this.$t('maintenance.drawer.batteryTest.Battery-test'),
          next: [
            { key: 'run', buttonText: this.$t('maintenance.drawer.batteryTest.Start-battery-test') },
          ],
        },
        run: {
          onShow: () => {
            this.startTest();
          },
        },
      },
    };
  },
  methods: {
    async onClose() {
      batteryTest.finish();
      this.$emit('onClose');
    },
    completeTest() {
      batteryTest.finish();
      this.$emit('onClose');
    },
    async getChecks() {
      await batteryTest.doChecks().then((res) => {
        this.batteryLoadTest = res.batteryLoadTest;
        this.batteryVoltageCritical = res.batteryVoltageCritical;
        this.batteryVoltageLow = res.batteryVoltageLow;
      });
    },
    startTest() {
      this.testing = true;
      batteryTest.start().then((result) => {
        this.testing = false;
        this.success = result.success;
        this.voltage = result.voltage;
        this.current = result.current;
      });
    },
  },
  beforeUpdate() {
    this.getChecks();
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

</style>
