/* eslint-disable prefer-arrow-callback */
import { helpers } from 'vuelidate/lib/validators';
import { TAG_LENGTH, MIN_CODE_LENGTH, MAX_CODE_LENGTH } from '@/app/users/shared/services/helpers';
import { ACCESS_TYPE } from '@/app/users/shared/enums';


export const validTag = () => helpers.withParams(
  {
    type: 'validTag',
  },
  (value, vm) => {
    const normalisedValue = (value || '').toLowerCase();
    if ((normalisedValue.length === 0) || (!vm.validation.showCode)) {
      return true;
    }
    if (vm.accessType === ACCESS_TYPE.USER_TAG.key) {
      // first char must have top bit clear (set during write to indicate tag/fob)
      const firstCharOk = (normalisedValue.length > 0) ? /^[0-7]$/.test(normalisedValue[0]) : true;
      return firstCharOk && /^[\dabcdef]+$/.test(normalisedValue);
    }
    return true; // code
  },
);


export const validTagLength = () => helpers.withParams(
  {
    type: 'validTagLength',
  },
  (value, vm) => {
    const normalisedValue = (value || '').toLowerCase();
    if ((normalisedValue.length === 0) || (!vm.validation.showCode)) {
      return true;
    }
    if (vm.accessType === ACCESS_TYPE.USER_TAG.value) {
      return (normalisedValue.length === TAG_LENGTH);
    }
    return true; // code
  },
);


/**
 * Checks whether a new code overlaps (or is the same as) with an existing code.
 * e.g. one can't have a code 211117 when the engineers code is 1111
 */
export const noCodeOverlap = () => helpers.withParams(
  {
    type: 'noCodeOverlap',
  },
  (value, vm) => {
    const normalisedValue = (value || '').toLowerCase();
    if ((normalisedValue.length < MIN_CODE_LENGTH) || (!vm.validation.showCode)) {
      return true;
    }
    let overlapped;
    if (vm.accessType === ACCESS_TYPE.USER_CODE.value) {
      const usedCodes = vm.validation.usedCodes.filter(v => v.length !== TAG_LENGTH);
      overlapped = usedCodes.some((v) => {
        const smaller = (v.length >= normalisedValue.length) ? normalisedValue : v;
        const larger = (v.length < normalisedValue.length) ? normalisedValue : v;
        return larger.includes(smaller);
      });
    } else { // tag
      overlapped = vm.validation.usedCodes.filter(v => v.length === TAG_LENGTH).some(v => v === normalisedValue);
    }
    return !overlapped;
  },
);


export const validCodeLength = () => helpers.withParams(
  {
    type: 'validCodeLength',
  },
  (value, vm) => {
    const normalisedValue = (value || '');
    if ((normalisedValue.length === 0) || (!vm.validation.showCode)) {
      return true;
    }
    if (vm.accessType === ACCESS_TYPE.USER_CODE.value) {
      let correctLength = false;
      // The code length should be between minimum length and max length.
      correctLength = (normalisedValue.length >= vm.validation.minimumCodeLength) && (normalisedValue.length <= MAX_CODE_LENGTH);
      return correctLength;
    }
    return true; // tag
  },
);


export const validCode = () => helpers.withParams(
  {
    type: 'validCode',
  },
  (value, vm) => {
    const normalisedValue = (value || '');
    if ((normalisedValue.length === 0) || (!vm.validation.showCode)) {
      return true;
    }
    if (vm.accessType === ACCESS_TYPE.USER_CODE.value) {
      return /^\d+$/.test(normalisedValue);
    }
    return true; // tag
  },
);


export const reservedCode = () => helpers.withParams(
  {
    type: 'reservedCode',
  },
  (value, vm) => {
    const normalisedValue = (value || '');
    if ((normalisedValue.length === 0) || (!vm.validation.showCode)) {
      return true;
    }
    if (vm.accessType === ACCESS_TYPE.USER_CODE.value) {
      return (normalisedValue === vm.validation.defEngCode);
    }
    return true; // tag
  },
);
