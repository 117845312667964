import { EnumWrapper } from '@/app/shared/utils/enumWrapper';

export const FOLLOW_TYPE = {
  FOLLOW: 'FOLLOW',
  TIMED: 'TIMED',
  LATCHED: 'LATCHED',
  CODE_RESET: 'CODE_RESET',
};

export const FOLLOW_WHAT = {
  ZONE: 'ZONE',
  AREA: 'AREA',
};

export const FOLLOW_WHEN = {
  SET: 'SET',
  UNSET: 'UNSET',
  ALWAYS: 'ALWAYS',
};

export const TRANSISTORED_MODE = {
  MODE_ASSERTED_H: 'MODE_ASSERTED_H',
  MODE_ASSERTED_L: 'MODE_ASSERTED_L',
  MODE_PULL_UP: 'MODE_PULL_UP',
  MODE_PULL_DOWN: 'MODE_PULL_DOWN',
};

export const USER_DEFINED_OUTPUT_TYPE = new EnumWrapper([
  { key: 'USER_OUTPUT_LATCHED', value: 0 },
  { key: 'USER_OUTPUT_PULSED', value: 1 },
]);

export const LOGIC_GATE_TYPE = new EnumWrapper([
  { key: 'NOT_USED_GATE', value: 0 },
  { key: 'AND_GATE', value: 1 },
  { key: 'OR_GATE', value: 2 },
  { key: 'NOT_GATE', value: 3 },
]);
