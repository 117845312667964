/* eslint-disable no-param-reassign */
import CustomInput from '@/app/shared/components/fields/custom-input.vue';
import CustomInputNumber from '@/app/shared/components/fields/custom-input-number.vue';
import CustomSelect from '@/app/shared/components/fields/custom-select.vue';
import CustomReadonly from '@/app/shared/components/fields/custom-readonly.vue';
import CustomAreaSelector from '@/app/shared/components/fields/custom-area-selector.vue';
import CustomLevelSelector from '@/app/shared/components/fields/custom-level-selector.vue';
import CustomItemSelector from '@/app/shared/components/fields/custom-item-selector.vue';
import CustomSwitch from '@/app/shared/components/fields/custom-switch.vue';
import CustomDatePicker from '@/app/shared/components/fields/custom-date-picker.vue';
import CustomTimePicker from '@/app/shared/components/fields/custom-time-picker.vue';
import CustomPassword from '@/app/shared/components/fields/custom-password.vue';
import CustomZoneSelector from '@/app/shared/components/fields/custom-zone-selector.vue';
import TextRow from '@/app/shared/components/Common/TextRow.vue';

const Templates = {
  install(Vue) {
    Vue.component('p-input', CustomInput);
    Vue.component('p-input-number', CustomInputNumber);
    Vue.component('p-select', CustomSelect);
    Vue.component('p-readonly', CustomReadonly);
    Vue.component('p-area-selector', CustomAreaSelector);
    Vue.component('p-level-selector', CustomLevelSelector);
    Vue.component('p-item-selector', CustomItemSelector);
    Vue.component('p-switch', CustomSwitch);
    Vue.component('p-date-picker', CustomDatePicker);
    Vue.component('p-time-picker', CustomTimePicker);
    Vue.component(CustomPassword.name, CustomPassword);
    Vue.component('p-zone-selector', CustomZoneSelector);
    Vue.component('text-row', TextRow);
  },
};

export default Templates;
