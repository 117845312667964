import Vue from 'vue';
import VueI18n from 'vue-i18n';
import localLoader from './locale-loader';
import { defaultLocale } from '@/app/environment/environment'; // eslint-disable-line import/order

function loadLocaleInfo() {
  const locales = localLoader;
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return { id: locales.id, messages };
}

const { messages } = loadLocaleInfo();

const dateTimeFormats = {
  'en-GB': {
    time: {
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
  },
};

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: defaultLocale,
  dateTimeFormats,
  messages,
  silentTranslationWarn: true,
});

export default i18n;
