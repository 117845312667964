<template>
  <two-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
    <template v-slot:title>
      <h1 id="title">{{ $t('keypadsAndReaders.navigationTitle') }}</h1>
    </template>
    <template v-slot:titleCommands>
      <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" icon="plus-circle" @click="gridOpenDrawer">{{ $t('keypadsAndReaders.Add-to-keypads-and-readers') }}</a-button>
    </template>
    <div>
      <div>
        <a-input-search :placeholder="$t('keypadsAndReaders.Search-for-keypad-or-reader')" size="large" v-model="grid.searchQuery" />

        <a-row :gutter="23" class="header">
          <a-col :xs="{ span: 24}" :md="{ span: 24}" :lg="{ span: 18}" :xl="{ span: 18}" class="filters">
            <a-dropdown class="grid-filter">
              <a-menu slot="overlay">
                <a-menu-item @click="gridDeleteSelected">
                  {{ $t('common.Delete')}}
                </a-menu-item>
              </a-menu>
              <a-button>
                {{ $t('common.Actions') }} <a-icon type="down" />
              </a-button>
            </a-dropdown>
            <grid-filter :title="$t('common.Status')" :records="allItems" field="state" @change="gridFilterChange" resPrefix="keypadsAndReaders.enums.state." :initialFilters="gridSavedFilters('state')" />
            <grid-filter :title="$t('common.Type')" :records="allItems" field="type" @change="gridFilterChange" resPrefix="keypadsAndReaders.enums.deviceType." :initialFilters="gridSavedFilters('type')"/>
            <grid-order :options="orderByOptions" @change="gridOrderByChange" />
          </a-col>

          <a-col :xs="{ span: 6}" :md="{ span: 6}" :lg="{ span: 6}" :xl="{ span: 6}" class="align-right hide-small">
            <grid-display-toggle :isTabularview="grid.options.isTabularView" @toggle="onGridDisplayToggle" />
          </a-col>
        </a-row>
        <div>
          <ul class="inline-list">
            <li>
              <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:1, width:'100%' }">
                <span>{{ wiredReaderInstalled + wiredKeypadInstalled }} /{{ wiredKeypadsAndReadersMax }} {{ $t('keypadsAndReaders.wired-keypads-and-readers') }}</span>
              </a-skeleton>
            </li>
            <li>
              <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:1, width:'100%' }">
                <span>{{ wirelessKeypadInstalled }}/{{ wirelessKeypadMax }} {{ $t('keypadsAndReaders.wireless-keypads') }}</span>
              </a-skeleton>
            </li>
          </ul>
        </div>
      </div>
      <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:10, width:'100%' }">
        <grid
            :options="grid.options"
            :records="gridItems">
        </grid>
      </a-skeleton>
    </div>
    <add-keypad-or-reader :visible="grid.drawerVisible" @onClose="grid.drawerVisible = false" :clone-payload="grid.clonePayload" />
    <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
      @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </two-col-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import AddKeypadOrReader from '@/app/keypads-and-readers/shared/components/add-keypad-or-reader/add-keypad-or-reader.vue';
import { ENGINEER_KEYPAD_INDEX } from '@/app/shared/constants';
import {
  ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard,
} from '@/app/shared/mixins';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

const storeNamespace = 'keypadsAndReadersState/list';
const { mapState, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard],
  components: {
    AddKeypadOrReader,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      grid: {
        itemsStorePath: `${storeNamespace}/items`,
        deleteItemsStoreAction: `${storeNamespace}/delete`,

        searchFields: ['name', 'location'],
        orderBy: 'address',

        options: {
          columns: [
            {
              title: this.$t('common.Name'),
              dataIndex: 'name',
              isTitle: true,
            },
            {
              title: this.$t('common.Type'),
              dataIndex: 'type',
              customRender: text => this.$t(`keypadsAndReaders.enums.deviceType.${text}`),
            },
            {
              title: this.$t('common.Location'),
              dataIndex: 'location',
            },
            {
              title: this.$t('common.Address'),
              dataIndex: 'address',
            },
          ],

          rowState: (record) => {
            if (record.state != null) {
              return record.state === 'OK' ? 'active' : 'error';
            }
            return 'loading';
          },
          canClone: record => this.configWriteLock.canWriteConfig && (record.type === 'TYPE_READER' || record.type === 'TYPE_KEYPAD'),
          canDelete: record => this.configWriteLock.canWriteConfig && record.index !== ENGINEER_KEYPAD_INDEX,

          click: (record) => {
            if (record.type === 'TYPE_WKEYPAD') {
              this.$router.push({ name: 'wireless-keypad', params: { index: record.index } });
            } else if (record.type === 'TYPE_READER') {
              this.$router.push({ name: 'wired-reader', params: { index: record.index } });
            } else if (record.type === 'TYPE_KEYPAD') {
              this.$router.push({ name: 'wired-keypad', params: { index: record.index, configuredZoneList: record.configuredZoneList } });
            }
          },
        },
      },
      orderByOptions: [
        { field: 'address', name: this.$t('common.Address') },
        { field: 'name', name: this.$t('common.Name') },
        { field: 'type', name: this.$t('common.Type') },
      ],
    };
  },
  computed: {
    ...mapState(['wiredReaderInstalled', 'wiredKeypadInstalled', 'wirelessKeypadInstalled', 'wiredKeypadsAndReadersMax', 'wirelessKeypadMax']),
    ...mapGetters(['isLoading']),
  },
};
</script>

<style lang="scss">
</style>
