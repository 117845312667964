import BaseLoader from '@/app/shared/services/base-loader';
import { limits } from '@/app/shared/constants';

let instance = null;

export class Loader extends BaseLoader {
  constructor(id, $store) {
    const endstationZones = Array.from(Array(limits.MAX_ES_ZONES).keys()).toString();
    const endpoints = {
      // Alphabetical order
      configAreaInfo: {
        key: 'configAreaInfo', url: '/Config/areaInfo', data: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configBusDevices: {
        key: 'configBusDevices', url: '/Config/BusDevices', data: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configEndstation: {
        key: 'configEndstation', url: '/Config/Endstation', data: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configOutputInfo: {
        key: 'configOutputInfo', url: '/Config/outputInfo', data: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      configPanelAreaLevels: {
        // dashboard polling for zones for area levels - placeholder url (used for polling interval only)
        key: 'configPanelAreaLevels', url: '/Live/areaInfo/AnyAreaSet', updateOnConfigWrite: false, data: undefined, promise: null, type: 'poll', interval: 3000, usedBy: [],
      },
      configZoneInfoES: {
        key: 'configZoneInfoES', url: `/Config/zoneInfo/Zones/${endstationZones}`, data: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      infoAreaInfo: {
        key: 'infoAreaInfo', url: '/Info/areaInfo', data: undefined, promise: null, type: 'once', updateOnConfigWrite: false, usedBy: [],
      },
      infoBusDevices: {
        key: 'infoBusDevices', url: '/Info/BusDevices', data: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      infoEndstation: {
        key: 'infoEndstation', url: '/Info/Endstation', data: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      infoKeypadInfo: {
        key: 'infoKeypadInfo', url: '/Info/keypadInfo', data: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      infoOutputInfo: {
        key: 'infoOutputInfo', url: '/Info/outputInfo', data: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      infoUserInfo: {
        key: 'infoUserInfo', url: '/Info/userInfo', data: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      infoZoneInfo: {
        key: 'infoZoneInfo', url: '/Info/zoneInfo', data: undefined, promise: null, type: 'once', updateOnConfigWrite: true, usedBy: [],
      },
      liveActiveFaults: {
        key: 'liveActiveFaults', url: '/Live/ActiveFaults', data: undefined, promise: null, type: 'poll', interval: 5000, usedBy: [],
      },
      liveAreaInfo: {
        key: 'liveAreaInfo', url: '/Live/areaInfo', data: undefined, promise: null, type: 'poll', interval: 5000, usedBy: [],
      },
      liveCommsInfo: {
        key: 'liveCommsInfo', url: '/Live/commsInfo', data: undefined, promise: null, type: 'poll', interval: 2000, usedBy: [],
      },
      liveEndstation: {
        key: 'liveEndstation', url: '/Live/Endstation', data: undefined, promise: null, type: 'poll', interval: 7000, updateOnConfigWrite: true, usedBy: [],
      },
      livePanelStatus: {
        key: 'livePanelStatus', url: '/Live/PanelStatus', data: undefined, dataStatus: undefined, promise: null, type: 'poll', interval: 5000, usedBy: [],
      },
      liveSystem: {
        key: 'liveSystem', url: '/Live/System', data: undefined, promise: null, type: 'poll', interval: 5000, usedBy: [],
      },
      liveUserInfo: {
        key: 'liveUserInfo', url: '/Live/userInfo', data: undefined, promise: null, type: 'once', updateOnConfigWrite: false, usedBy: [],
      },
      liveZoneInfoES: {
        // Only used by the panel page, so poll only the zones belonging to the endstation (0-7) as other zones are polled elsewhere.
        // Used for reporting tampers in zones 001 to 008. TODO has to be polled quite often; every second?
        key: 'liveZoneInfoES', url: `/Live/zoneInfo/Zones/${endstationZones}`, data: undefined, promise: null, type: 'poll', interval: 5000, usedBy: [],
      },
    };

    super('PL-'.concat(id), $store, endpoints); // PL: Panel Loader
  }

  static getInstance(id, store) {
    if (instance === null) {
      instance = new Loader(id, store);
    }
    return instance;
  }

  static clearInstance() {
    if (instance != null) instance.dispose();
    instance = null;
  }
}
