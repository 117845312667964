<template>
     <div class="diagnostic">
        <h4>{{title}}</h4>
                    <a-skeleton
                        :loading="loading"
                        :title="false"
                        :paragraph="{ rows:1, width:'100%'}"
                        :style="loading ? 'margin-top: 10px' :''">
        <a-tooltip placement="bottom" v-if="tooltip">
          <template slot="title">
            <span v-if="tooltipIsHtml" v-html="tooltip"></span><span v-else>{{tooltip}}</span>
          </template>
          <p v-bind:class="[status.hasError == null ? '' : (status.hasError ? 'error' : 'active')]"><span v-if="valueIsHtml" v-html="value"></span><span v-else>{{value}}</span></p>
        </a-tooltip>
        <p v-else v-bind:class="[status.hasError == null ? '' : (status.hasError ? 'error' : 'active')]"><span v-if="valueIsHtml" v-html="value"></span><span v-else>{{value}}</span></p>
                    </a-skeleton>
     </div>
</template>
<script>
export default {
  name: 'StatusTextBlock',
  props: {
    title: { type: String, default: null },
    value: { type: String, default: null },
    type: { type: String, default: null },
    tooltip: { type: String, default: null },
    valueIsHtml: { type: Boolean, default: false },
    tooltipIsHtml: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  computed: {
    status() {
      if (this.type === 'error') {
        return {
          hasError: true,
        };
      }
      if (this.type === 'active') {
        return {
          hasError: false,
        };
      }

      return {
        hasError: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/base/variables';

    .error {
        color:$error-color;
    }

    .active {
        color:$success-color;
    }
</style>
