<template>
    <a-drawer
    placement="right"
    :closable="false"
    @close="onClose"
    :destroyOnClose="true"
    :visible="visible">

    <wizard :config="wizard" :state="$v" @done="onClose">
        <wizard-step name="start">
          <a-form>
            <p-select :label="$t('outputs.Output-type')" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
              :model="$v.form.outputType" :items="outputTypes" :valueExpr="(i) => i.value" :textExpr="(i) => i.name" />
          </a-form>
        </wizard-step>

        <wizard-step name="run">

          <div class="loader">
            <font-awesome-icon class="fa-spin" :icon="['fal', 'spinner']" size="8x" />
            <h5>{{ $t('maintenance.drawer.pgmTest.Testing')}}</h5>
            <span>{{ $t('maintenance.drawer.pgmTest.only', { type: getOutputTypeName() })}}</span>
          </div>
          <h4>{{ $t('maintenance.drawer.pgmTest.Did-type-only-outputs-pass-the-test', { type: getOutputTypeName() }) }}</h4>
          <div class="icon-buttons__wrapper">
            <a @click="completeTest">
              <font-awesome-icon  :icon="['fal', 'thumbs-down']" size="4x" />
              <span>{{ $t('maintenance.drawer.sirenAndStrobeTest.Failed') }}</span>
            </a>
            <a @click="completeTest">
              <font-awesome-icon  :icon="['fal', 'thumbs-up']" size="4x" />
              <span>{{ $t('maintenance.drawer.sirenAndStrobeTest.Passed') }}</span>
            </a>
          </div>

        </wizard-step>

      </wizard>
    </a-drawer>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';

import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';

import outputHelper from '@/app/shared/services/output-helper';

import { StateLoader } from '@/app/shared/mixins';

const storeNamespace = 'maintenanceState/pgmTest';
const { mapState } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [StateLoader],
  components: {
    Wizard,
    WizardStep,
  },
  props: {
    visible: { default: false },
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      form: {
        outputType: undefined,
      },
      wizard: {
        start: {
          title: this.$t('maintenance.drawer.pgmTest.Start-PGM-test'),
          next: [
            { key: 'run', buttonText: this.$t('maintenance.drawer.pgmTest.Start-PGM-test') },
          ],
        },
        run: {
          title: this.$t('maintenance.drawer.pgmTest.PGM-test-In-progress'),
          onShow: () => {
            outputHelper.activateOutputType(this.$v.form.outputType.$model, true, this.loader.instance);
          },
        },
      },
    };
  },
  validations: {
    form: {
      outputType: { required },
    },
  },
  computed: {
    ...mapState(['outputTypes']),
  },
  methods: {
    getOutputTypeName() {
      const formOutputType = this.$v.form.outputType.$model ? this.$v.form.outputType.$model : 0;
      const outputType = this.outputTypes.find(x => x.value === formOutputType);
      const name = outputType ? outputType.name : 'Not used';
      return name;
    },
    async onClose() {
      outputHelper.activateOutputType(this.$v.form.outputType.$model, false, this.loader.instance);
      this.$emit('onClose');
    },
    completeTest() {
      // Deactivate the test.
      outputHelper.activateOutputType(this.$v.form.outputType.$model, false, this.loader.instance);
      this.$emit('onClose');
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

.icon-buttons__wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  a {
    float: left;
    padding-right: 60px;
    text-align: center;
    color:#000 !important;
  }
  svg {
    color:#000 !important;
    display: block;
    margin-bottom:10px;
  }
}

.align-center {
  text-align: center;
}

.loader {
  text-align: center;
  margin-bottom: 30px;

  h5 {
    margin:20px 0 0 0;
    font-size: 16px;
    font-weight:bold;
  }
  span {
    font-size: 16px;
  }
}

</style>
