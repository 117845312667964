<template>
    <a-drawer
    placement="right"
    :closable="false"
    @close="onClose"
    :destroyOnClose="true"
    :visible="visible">

    <wizard :config="wizard" :state="$v" @save="save" @done="onClose" :initialStep="clonePayload != null ? 'manual' : 'start'">
      <wizard-step name="start">
        <template slot="body" slot-scope="{ wizard }">
          <a-button class="draw-link" @click="wizard.navigateTo('learn')" type="link" block>
            <text-row :title="$t('users.drawer.Learn-fob')" title-icon="users" />
          </a-button>
          <a-button class="draw-link" @click="wizard.navigateTo('manual')" type="link" block>
            <text-row :title="$t('common.Add-manually')" title-icon="plus-circle" />
          </a-button>
        </template>
      </wizard-step>

      <wizard-step name="learn">
        <learn-wireless @done="onClose" :start-at="{ stage: 'info', type: 'keyfobs' }" />
      </wizard-step>

      <wizard-step name="manual">
        <a-form>
          <p-input :label="$t('common.Name')" :model="$v.form.name" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
          <p-select :label="$t('users.Access-type')" :model="$v.form.accessType" :items="ACCESS_TYPE.values.filter(t => t.key !== 'KEY_FOB')"
            :valueExpr="(i) => i.value" :textExpr="(i) => $t(`users.enums.ACCESS_TYPE.${i.value}`)"
            :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
          <p-password v-if="$v.form.accessType.$model === 'USER_CODE'" :label="$t('users.Code')" :model="$v.form.code" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
            :customValidations="[
              { key: 'notReservedCode', message: $t('users.Using-reserved-code') },
              { key: 'validCodeLength', message: $t('users.Code-length-incorrect') },
              { key: 'validCode', message: $t('users.Code-invalid') },
              { key: 'noCodeOverlap', message: $t('users.Code-already-used') }
            ]" />
          <p-input v-else :label="$t('users.Code')" :model="$v.form.code" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"
            :customValidations="[
              { key: 'validTag', message: $t('users.Tag-invalid') },
              { key: 'validTagLength', message: $t('users.Tag-length-incorrect', { len: TAG_LENGTH }) },
              { key: 'noCodeOverlap', message: $t('users.Code-already-used') }
            ]" />
          <p-select :label="$t('users.User-type')" :model="$v.form.userType"
            :items="USER_TYPE.values.filter( type => { return type.key != 'ENGINEER' && type.key != 'MASTERMAN'}) "
            :valueExpr="(i) => i.value"
            :textExpr="(i) => $t(`users.enums.USER_TYPE.${i.value}`)"
            :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
          <p-area-selector :label="$t('common.Areas')" :model="$v.form.areas" :areasInfo="areasInfo" :areasConfig="areasConfig" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
          <p-select :label="$t('users.Set-option')" :model="$v.form.setOption" :items="SET_OPTIONS.values" :valueExpr="(i) => i.value" :textExpr="(i) => $t(`users.enums.SET_OPTIONS.${i.value}`)" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
          <p-select :label="$t('common.Number')" :model="$v.form.index" :items="numbers" :valueExpr="(i) => i.index" :textExpr="(i) => i.number" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
          <p-switch :label="$t('users.Flexi-set')" :model="$v.form.flexiSet" :switchTooltip="toggleTooltipText($v.form.flexiSet)" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
        </a-form>
      </wizard-step>

      <wizard-step name="final">
        <a-form>
          <p-item-selector :label="$t('users.Door')" :items="doors" :model="$v.form.associatedDoors" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" />
        </a-form>
      </wizard-step>

    </wizard>

    </a-drawer>
</template>
<script>
import {
  required, maxLength, not,
} from 'vuelidate/lib/validators';

import { createNamespacedHelpers } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import Wizard from '@/app/shared/components/wizard/wizard.vue';
import WizardStep from '@/app/shared/components/wizard/wizard-step.vue';
import LearnWireless from '@/app/shared/components/add-devices-drawer/learn-wireless.vue';

import { Clone } from '@/app/shared/mixins';
import { USER_TYPE, SET_OPTIONS, ACCESS_TYPE } from '@/app/users/shared/enums';
import { TAG_LENGTH } from '@/app/users/shared/services/helpers';
import {
  validTag, validTagLength, noCodeOverlap, validCodeLength, validCode, reservedCode,
} from '@/app/users/shared/services/validators';


const storeNamespace = 'usersState/addUser';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);


export default {
  mixins: [Clone],
  components: {
    Wizard,
    WizardStep,
    LearnWireless,
  },
  props: {
    visible: { default: false },
  },
  data() {
    return {
      stage: 'init',
      USER_TYPE,
      SET_OPTIONS,
      ACCESS_TYPE,
      TAG_LENGTH,
      clone: {
        storeNamespace,
      },
      wizard: {
        start: {
          title: this.$t('users.drawer.How-do-you-want-to-add-users'),
        },
        learn: {
          mode: 'SIMPLE',
        },
        manual: {
          title: this.$t('users.drawer.Add-a-user'),
          progress: 30,
          next: [
            { key: 'final', buttonText: this.$t('common.Next') },
          ],
        },
        final: {
          progress: 60,
          next: [
            { key: 'save', buttonText: this.$t('common.Add-user') },
          ],
        },
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(16),
      },
      accessType: {
        required,
      },
      code: {
        required,
        noCodeOverlap: noCodeOverlap(),
        notReservedCode: not(reservedCode()),
        validCode: validCode(),
        validCodeLength: validCodeLength(),
        validTag: validTag(),
        validTagLength: validTagLength(),
      },
      userType: {
        required,
      },
      areas: {
        required,
      },
      setOption: {
        required,
      },
      index: {
        required,
      },
      flexiSet: {
        required,
      },
      associatedDoors: {},
    },
  },
  computed: {
    ...mapFields(storeNamespace, ['form']),
    ...mapState(['availableIndexes', 'doors', 'subAreas', 'areasInfo', 'areasConfig']),
    numbers() {
      if (this.$v.form.userType.$model === USER_TYPE.DURESS.value || this.$v.form.userType.$model === USER_TYPE.GUARD.value) {
        return this.availableIndexes.filter(i => i >= 502).map(i => ({ number: `${i - 501}`, index: i }));
      }

      return this.availableIndexes.filter(i => i < 502).map(i => ({ number: `${i - 1}`, index: i }));
    },
  },
  watch: {
    // Whenever we open the wizard, the component should be reset
    visible(newVal) {
      if (newVal) {
        this.$store.dispatch('usersState/addUser/reset');
        this.$v.$reset();
      }
    },
  },
  methods: {
    ...mapActions(['add']),
    async onClose() {
      this.$emit('onClose');
      this.stage = 'init';
    },
    async save() {
      this.$v.$touch();

      await this.add();
      this.$emit('onClose');

      if (!this.navigateToNewItem) {
        return;
      }

      this.$router.push({ name: 'user', params: { index: this.form.index } });
    },
    toggleTooltipText(field) {
      return field && field.$model
        ? this.$t('common.OptionEnabledTooltip')
        : this.$t('common.OptionDisabledTooltip');
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}

</style>
