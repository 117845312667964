import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';


function initialState() {
  return {
    riscoEolRangeSupportedEnabled: undefined,
    form: {
      forceArm: undefined,
      bypassOnRearm: undefined,
      eol: undefined,
      eolMode: undefined,
      applyChangesToAllZones: false,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configSysInfo', 'infoFeatures', 'infoZoneInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        forceArm: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.ForceArm,
        bypassOnRearm: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.ReArmIsolatesZone,
        eol: endpoints.configSysInfo.data.Config.sysInfo.MiscOptions.EOLResistorRange,
        eolMode: endpoints.configSysInfo.data.Config.sysInfo.MiscOptions.PerZoneEolMode,
        applyChangesToAllZones: false,
      });

      const lowCostZemZones = endpoints.infoZoneInfo.data.Info.zoneInfo.Zones
        .map((z, i) => ({ index: i, hasPerZoneOptions: z.PerZoneOptions }));

      context.commit('set', {
        lowCostZemZones,
        riscoEolRangeSupportedEnabled: endpoints.infoFeatures.data.Info.Features.ZoneDefaultEol.RiscoEolRangeSupportedEnabled,
      });
    },
    async save(context) {
      const data = {
        SiteOptions: {
          ForceArm: context.state.form.forceArm,
          ReArmIsolatesZone: context.state.form.bypassOnRearm,
        },
        MiscOptions: {
          EOLResistorRange: context.state.form.eol,
          PerZoneEolMode: context.state.form.eolMode,
        },
      };

      await api.put('/Config/sysInfo', data);

      if (context.state.form.applyChangesToAllZones) {
        const eolValue = context.state.form.eol.replace('EOL_', 'ZONE_EOL_');
        const lowCostZemOverrideData = context.state.lowCostZemZones.map(z => (z.hasPerZoneOptions ? { ZoneEol: eolValue } : null));
        await api.put('/Config/zoneInfo/Zones', lowCostZemOverrideData);
      }

      await api.put('/Live/zoneInfo/RemoteEOLMode', true);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('settingsState/zoneSettings/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['settingsState/zoneSettings'] });
    },
  },
});


export default store;
