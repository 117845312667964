/* eslint-disable no-await-in-loop */
import { createArray, API } from '@/app/shared/services/api';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { getDevices } from '@/app/zones/shared/services/helpers';
import { limits } from '@/app/shared/constants';
import zoneAreasHelper from '@/app/shared/services/zone-areas-helper';

const apiInstance = new API();
const NoZonesAvailable = -1;

async function _nextUnlearntZone() {
  let found = false;
  let zone = 0;
  while (!found && (zone < limits.MAX_WE_ZONES)) {
    const learntResponse = await apiInstance.get(`/Config/RadioDevicesLearnt/RadioInputsLearnt/${zone}`);
    const learnt = learntResponse.data.Config.RadioDevicesLearnt.RadioInputsLearnt[zone];
    if (!learnt) {
      found = true;
    } else {
      zone += 1;
    }
  }
  zone = (found ? zone : NoZonesAvailable);
  return zone;
}

function initialState() {
  return {
    associatedDevices: [],
    perZoneOptions: createArray(limits.MAX_ZONES, () => (false)),
    connectionType: createArray(limits.MAX_ZONES, () => (undefined)),
    manuallyAssociatedZone: null,

    form: {
      zoneType: undefined,
      name: undefined,
      location: undefined,
      areas: undefined,
      areasInfo: undefined,
      areasConfig: undefined,
      associatedWithDevice: undefined,
      zoneIndex: undefined,
      anyAllArea: false,
      chime: 0,
      allowByPass: undefined,
      crossZone: undefined,
      normallyOpen: undefined,
      specialLogged: 0,
      nonActivityInput: undefined,
      swingerLimit: 6,
      doubleKnock: undefined,
      maskTest: undefined,
      inertiaInput: undefined,
      inertiaDebounceCount: 0,
      inertiaDebounceTime: 0,
      // soakTest: undefined,
      eolResistorMode: 'DOUBLE',
      eolResistorRange: 'ZONE_EOL_4K7_2K2',
      occupancy: undefined,
      supervision: undefined,
      confirmGroup: 0,
      zoneNames: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {},
  actions: {
    requiredEndpoints() {
      // Note that getDevices() requires endpoints: configBusDevices configKeypadInfo configZoneInfoParts infoBusDevices infoKeypadInfo
      return ['configAreaInfo', 'configBusDevices', 'configKeypadInfo', 'configZoneInfoParts', 'infoAreaInfo', 'infoBusDevices', 'infoKeypadInfo', 'infoZoneInfo'];
    },
    async populate(context, { endpoints, payload }) {
      let associatedWithDeviceType;
      let associatedWithDeviceNumber;

      if (payload != null && payload.index != null) {
        // Use case: e.g clone zone
        const cachedConfigZoneInfo = (await api.get('/Config/zoneInfo/Zones/'.concat(payload.index.toString())));

        const zoneType = cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].ZoneType;
        associatedWithDeviceType = endpoints.infoZoneInfo.data.Info.zoneInfo.Zones[payload.index].AssociatedWith.DeviceType;
        associatedWithDeviceNumber = endpoints.infoZoneInfo.data.Info.zoneInfo.Zones[payload.index].AssociatedWith.DeviceNumber;
        context.commit('setForm', {
          name: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Name,
          zoneType,
          location: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Location,
          anyAllArea: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].AnyAllArea === 1,
          areas: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Areas,
          areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
          areasConfig: endpoints.configAreaInfo.data.Config.areaInfo,
          associatedWithDevice: `${associatedWithDeviceType}_${associatedWithDeviceNumber}`,
          chime: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Chime,
          allowByPass: (0x01 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x01,
          crossZone: (0x08 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x08,
          normallyOpen: (0x20 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x20,
          specialLogged: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].SpecialLogged,
          nonActivityInput: (0x10 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x10,
          swingerLimit: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].SwingerLimit,
          doubleKnock: (0x04 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x04,
          maskTest: (0x40 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x40,
          inertiaInput: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].InertiaZoneDebounceTime_ms !== 0 && cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].InertiaZoneDebounceCount !== 0,
          inertiaDebounceCount: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].InertiaZoneDebounceCount,
          inertiaDebounceTime: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].InertiaZoneDebounceTime_ms,
          // soakTest: (0x02 & cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Attributes) === 0x02,
          eolResistorMode: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].EolMode,
          eolResistorRange: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].ZoneEol,
          occupancy: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Occupancy,
          supervision: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].Supervision,
          confirmGroup: cachedConfigZoneInfo.data.Config.zoneInfo.Zones[payload.index].ConfirmGroup,
        });
      } else {
        // Use case: e.g. add zone
        context.commit('setForm', {
          areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
          areasConfig: endpoints.configAreaInfo.data.Config.areaInfo,
          zoneNames: endpoints.configZoneInfoParts.data.Config.zoneInfo.Zones.map(x => x.Name),
        });
      }

      const associatedDevices = getDevices(associatedWithDeviceType, associatedWithDeviceNumber, null, endpoints);

      const perZoneOptions = endpoints.infoZoneInfo.data.Info.zoneInfo.Zones.map(z => z.PerZoneOptions);

      const connectionType = endpoints.infoZoneInfo.data.Info.zoneInfo.Zones.map(z => (z.AssociatedWith.DeviceType === 'WIRELESS_HUB' ? 'TYPE_WIRELESS' : 'TYPE_WIRED'));

      context.commit('set', {
        associatedDevices,
        perZoneOptions,
        connectionType,
      });
    },
    async add(context) {
      const { zoneIndex } = context.state.form;

      const data = {};

      data.Name = context.state.form.name;
      data.ZoneType = context.state.form.zoneType;
      data.Location = context.state.form.location;
      data.AnyAllArea = context.state.form.anyAllArea ? 1 : 0;
      data.Areas = context.state.form.areas;
      data.Attributes = 0;
      data.Chime = parseInt(context.state.form.chime, 10);
      data.Occupancy = context.state.form.occupancy;
      data.Supervision = context.state.form.supervision;
      data.ConfirmGroup = parseInt(context.state.form.confirmGroup, 10);
      if (context.state.form.allowByPass) data.Attributes += 0x01;
      if (context.state.form.crossZone) data.Attributes += 0x08;
      if (context.state.form.normallyOpen) data.Attributes += 0x20;
      if (context.state.form.nonActivityInput) data.Attributes += 0x10;
      if (context.state.form.doubleKnock) data.Attributes += 0x04;
      if (context.state.form.maskTest) data.Attributes += 0x40;
      // if (context.state.form.soakTest) data.Attributes += 0x02;
      data.SpecialLogged = context.state.form.specialLogged;
      data.SwingerLimit = parseInt(context.state.form.swingerLimit, 10);
      if (context.state.perZoneOptions[zoneIndex]) {
        data.EolMode = context.state.form.eolResistorMode;
        data.ZoneEol = context.state.form.eolResistorRange;
      }
      if (context.state.form.inertiaInput) {
        data.InertiaZoneDebounceCount = parseInt(context.state.form.inertiaDebounceCount, 10);
        data.InertiaZoneDebounceTime_ms = parseInt(context.state.form.inertiaDebounceTime, 10);
      } else {
        data.InertiaZoneDebounceCount = 0;
        data.InertiaZoneDebounceTime_ms = 0;
      }
      const isWirelessZone = (zoneIndex >= limits.WE_ZONE_OFFSET) && (zoneIndex < (limits.WE_ZONE_OFFSET + limits.MAX_WE_ZONES));
      const nextUnlearntZone = isWirelessZone ? await _nextUnlearntZone() : undefined;
      await api.put(`/Config/zoneInfo/Zones/${zoneIndex}`, data);
      if (nextUnlearntZone !== undefined && nextUnlearntZone !== NoZonesAvailable) {
        await api.put(`/Live/Devices/WirelessZones/${zoneIndex - limits.WE_ZONE_OFFSET}/ManuallyAssociatedZone`, zoneIndex);
        context.commit('set', { manuallyAssociatedZone: zoneIndex });
      } else {
        context.commit('set', { manuallyAssociatedZone: null });
      }

      // Determine how many of the ten zone area endpoints the new zone will use:
      const zoneAreaEndpointsToRefresh = zoneAreasHelper.getZoneAreaEndpointsFromArea(context, data.Areas);
      // Zone area endpoints may contain duplicates; de-dupe using Set constructor and spread syntax:
      const uniqZoneAreaEndpointsToRefresh = [...new Set(zoneAreaEndpointsToRefresh)];

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('zonesState/list/requiredEndpoints');
      const requiredEndpointsAddDevice = (await this.dispatch('zonesState/addZone/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      let endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddDevice);

      // Handle the zone area endpoints: take them all away, then add only the ones that will change:
      endpointsToUpdate = endpointsToUpdate.filter(endp => !zoneAreasHelper.zoneAreasEndpointsList.includes(endp));
      uniqZoneAreaEndpointsToRefresh.forEach((endp) => {
        endpointsToUpdate = endpointsToUpdate.concat(endp);
      });

      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['zonesState/list'] });
    },
  },
});
export default store;
