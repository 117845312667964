import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';


function initialState() {
  return {
    form: {
      armWithFault: undefined,
      armWithTamper: undefined,
      armWithModernFault: undefined,
      armFailAlarm: undefined,
      confirmedWhen: undefined,
      disarmWithCode: undefined,
      disarmChoice: undefined,
      tagDisarmChoice: undefined,
      armWithSupervisionFault: undefined,
      wirelessBellSupervision: undefined,
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return ['configSysInfo'];
    },
    async populate(context, { endpoints }) {
      context.commit('setForm', {
        armWithFault: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.SetWithFault,
        armWithTamper: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.SetWithTamper,
        armWithModernFault: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.SetWithATSFault,
        armFailAlarm: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.SetFailAlarm,
        // this comes in as a bool but it's needed as an int for the drop down display
        confirmedWhen: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.ConfirmedWhen ? 1 : 0,
        // "disarm with code" (LAN UI) is inverse of "prevent pin codes" (endpoint)
        disarmWithCode: !endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.PreventPinCodes,
        disarmChoice: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.FlexiUnset,
        tagDisarmChoice: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.TagUnlocksDoors,
        armWithSupervisionFault: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.SetRadioPollFails,
        wirelessBellSupervision: endpoints.configSysInfo.data.Config.sysInfo.SiteOptions.WirelessBellSupervision,
      });
    },
    async save(context) {
      const data = {
        SetWithFault: context.state.form.armWithFault,
        SetWithTamper: context.state.form.armWithTamper,
        SetWithATSFault: context.state.form.armWithModernFault,
        SetFailAlarm: context.state.form.armFailAlarm,
        // this option is recorded as an integer from the p-select Input, but needed as a bool for the panel
        // eslint-disable-next-line no-unneeded-ternary
        ConfirmedWhen: context.state.form.confirmedWhen === 1 ? true : false,
        // "disarm with code" (LAN UI) is inverse of "prevent pin codes" (endpoint)
        PreventPinCodes: !context.state.form.disarmWithCode,
        FlexiUnset: context.state.form.disarmChoice,
        TagUnlocksDoors: context.state.form.tagDisarmChoice,
        SetRadioPollFails: context.state.form.armWithSupervisionFault,
        WirelessBellSupervision: context.state.form.wirelessBellSupervision,
      };

      await api.put('/Config/sysInfo/SiteOptions', data);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('settingsState/armingOptions/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['settingsState/armingOptions'] });
    },
  },
});


export default store;
