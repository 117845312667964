<template>
  <two-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
    <template v-slot:title>
      <h1 id="title">{{ $t('outputExpanders.navigationTitle') }}</h1>
    </template>
    <template v-slot:titleCommands>
      <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" icon="plus-circle" @click="gridOpenDrawer">{{ $t('outputExpanders.Add-output-expander') }}</a-button>
    </template>

    <div>
      <div>
        <a-input-search :placeholder="$t('outputExpanders.Search-for-a-output-expander')" size="large" v-model="grid.searchQuery" />

        <a-row :gutter="23" class="header">
            <a-col :xs="{ span: 18}" :md="{ span: 18}" :lg="{ span: 18}" :xl="{ span: 18}" class="filters">
                <a-dropdown class="grid-filter">
                    <a-menu slot="overlay">
                        <a-menu-item @click="gridDeleteSelected">
                            {{ $t('common.Delete')}}
                        </a-menu-item>
                    </a-menu>
                    <a-button>
                        {{ $t('common.Actions') }} <a-icon type="down" />
                    </a-button>
                </a-dropdown>
                <grid-filter :title="$t('common.Status')" :records="allItems" resPrefix="outputExpanders.enums.State." field="state" @change="gridFilterChange" :initialFilters="gridSavedFilters('state')" />
                <grid-filter :title="$t('common.Type')" :records="allItems" field="outputTypes" :customRender="(item) => item.render" @change="gridFilterChange" :initialFilters="gridSavedFilters('outputTypes')" />
                <grid-order :options="[{ field: 'name', name:  $t('common.Name')},{ field: 'address', name: $t('common.Address')}]" @change="gridOrderByChange" />
            </a-col>

            <a-col :xs="{ span: 6}" :md="{ span: 6}" :lg="{ span: 6}" :xl="{ span: 6}" class="align-right hide-small">
                <grid-display-toggle :isTabularview="grid.options.isTabularView" @toggle="onGridDisplayToggle" />
            </a-col>
        </a-row>
        <div>
            <ul class="inline-list">
              <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:1, width:'100%' }">
                <li><span>{{ installed }}/{{ max }} {{ $t('outputExpanders.output-expanders') }}</span></li>
              </a-skeleton>
            </ul>
        </div>
      </div>
      <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:10, width:'100%' }">
        <grid
            :options="grid.options"
            :records="gridItems">
            <div slot="card-title" slot-scope="scope">
              <h3>{{ scope.record.name }}</h3>
              <p class="subtitle">{{ $t('outputExpanders.availableOutputs', { available: scope.record.availableOutputs, max: scope.record.maxOutputs }) }}</p>
            </div>
        </grid>
      </a-skeleton>
    </div>
    <add-output-expander :visible="grid.drawerVisible" @onClose="grid.drawerVisible = false" :clone-payload="grid.clonePayload" />
    <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
      @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </two-col-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import AddOutputExpander from '@/app/output-expanders/shared/components/add-output-expander/add-output-expander.vue';
import {
  ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard,
} from '@/app/shared/mixins';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

const storeNamespace = 'outputExpandersState/list';
const { mapState, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard],
  components: {
    AddOutputExpander,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      grid: {
        itemsStorePath: `${storeNamespace}/items`,
        deleteItemsStoreAction: `${storeNamespace}/delete`,

        searchFields: ['name', 'location', 'address'],
        orderBy: 'address',

        options: {
          columns: [
            {
              title: this.$t('common.Name'),
              dataIndex: 'name',
              isTitle: true,
            },
            {
              title: this.$t('common.Location'),
              dataIndex: 'location',
            },
            {
              title: this.$t('common.Address'),
              dataIndex: 'address',
            },
            {
              title: this.$t('common.Available'),
              dataIndex: 'count',
              isTitle: true,
            },
          ],

          rowState: (record) => { if (record.state != null) { return record.state === 'OK' ? 'active' : 'error'; } return 'loading'; },
          canClone: () => this.configWriteLock.canWriteConfig,
          canDelete: () => this.configWriteLock.canWriteConfig,

          click: (record) => {
            this.$router.push({ name: 'output-expander', params: { index: record.index } });
          },
        },
      },
    };
  },
  computed: {
    ...mapState(['installed', 'max']),
    ...mapGetters(['isLoading']),
  },
};
</script>

<style lang="scss">
</style>
