<template>
  <div id="app" :data-version="version">
      <component :is="layout">
        <router-view/>
      </component>
  </div>
</template>
<script>
import { version } from '@/app/environment/environment';

export default {
  data() {
    return {
      version,
    };
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || 'default'}-layout`;
    },
  },
  created() {
    window.version = this.version;
  },
};
</script>
