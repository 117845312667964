import i18n from '@/app/shared/services/i18n';
import { EnumWrapper } from '@/app/shared/utils/enumWrapper';

export const WARD_READMITS = {
  IF_AREA_ARMED: 0,
  NEVER: 1,
};

export const WARD_CONTROL = {
  CARD_TAG: 0,
  ZONE: 1,
};


export const KEYPAD_AND_READER_TYPE = new EnumWrapper([
  { key: 'TYPE_KEYPAD', value: 0 },
  { key: 'TYPE_READER', value: 1 },
  { key: 'TYPE_NONE', value: 2 },
  { key: 'TYPE_WKEYPAD', value: 3 },
  { key: 'TYPE_REMOVABLE_KEYPAD', value: 4 },
]);

export const READER_TYPE = new EnumWrapper([
  { key: 'ARM_DISARM', name: i18n.t('enums.READER_TYPE.ARM_DISARM'), value: 0 },
  { key: 'ACCESS_CONTROL', name: i18n.t('enums.READER_TYPE.ACCESS_CONTROL'), value: 2 },
  { key: 'DISARM_ONLY', name: i18n.t('enums.READER_TYPE.DISARM_ONLY'), value: 3 },
  { key: 'ENTRY_CONTROL', name: i18n.t('enums.READER_TYPE.ENTRY_CONTROL'), value: 4 },
]);

export const TWO_KEY_PA = {
  SILENT: 0,
  NOISY: 1,
  BOTH: 2,
  NONE: 3,
};

export const FIRE_KEY_ACTIVE = {
  YES: 'true',
  NO: 'false',
};
