<template>
      <div class="status" :class="state"></div>
</template>
<script>
export default {
  name: 'Status',
  props: {
    state: {
      validator(value) {
        return ['active', 'error', 'none', 'blank', 'loading'].indexOf(value) !== -1;
      },
    },
  },
};
</script>

<style scoped lang="scss">
    @import 'status.scss';
</style>
