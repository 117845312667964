<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('communications.appOptions.navigationTitle') }}</h2>
      </a-row>
      <a-form>
        <p-switch :label="$t('communications.appOptions.Engineer-use-app')" :labelTooltip="$t('communications.appOptions.Engineer-use-app-tooltip')" :model="$v.form.engineerUseApp"  display-style="small" />
        <p-switch :label="$t('communications.appOptions.Use-app')" :labelTooltip="$t('communications.appOptions.Use-app-tooltip')" :model="$v.form.useApp"  display-style="small" />
        <p-password :label="$t('communications.appOptions.App-cloud-password')" :labelTooltip="$t('communications.appOptions.App-cloud-password-tooltip')" :model="$v.form.appCloudPassword"  display-style="small" />
        <p-select :label="$t('communications.appOptions.App-security')" :labelTooltip="$t('communications.appOptions.App-security-tooltip')" :model="$v.form.appSecurity"  display-style="small"
          :items="Object.keys(APP_SECURITY)" :valueExpr="(i) => APP_SECURITY[i]" :textExpr="(i) => $t(`enums.APP_SECURITY.${i}`)" />
        <p-password :label="$t('communications.appOptions.App-password')" :labelTooltip="$t('communications.appOptions.App-password-tooltip')" :model="$v.form.appPassword"  display-style="small" />
      </a-form>
      <div class="align-right">
         <a-button type="primary" size="large" @click="saveForm" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
      </div>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import { APP_SECURITY } from '@/app/communications/shared/enums';
import {
  StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'communicationsState/appOptions';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
      APP_SECURITY,
    };
  },
  computed: {
    ...mapState(['form']),
  },
  validations: {
    form: {
      engineerUseApp: {},
      useApp: {},
      appCloudPassword: { required },
      appSecurity: { required },
      appPassword: { required },
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
};
</script>
