/* eslint-disable no-unused-vars */
import gridHelper from '@/app/shared/services/grid-helper';

import Grid from '@/app/shared/components/grid/grid.vue';
import GridFilter from '@/app/shared/components/grid/grid-filter.vue';
import GridOrder from '@/app/shared/components/grid/grid-order.vue';
import GridDisplayToggle from '@/app/shared/components/grid/grid-display-toggle.vue';

import { MAX_ITEMS_PER_PAGE } from '@/app/shared/constants';

const staticData = {
  filtersByStorePath: [],
  orderByByStorePath: [],
  tabularViewByStorePath: [],
};

const GridMixin = {
  components: {
    Grid,
    GridFilter,
    GridOrder,
    GridDisplayToggle,
  },
  data() {
    return {
      grid: {
        clonePayload: undefined,
        drawerVisible: false,

        // store
        itemsStorePath: '',
        deleteItemsStoreAction: '',

        // filters, search & ordering
        searchFields: ['name'],
        orderBy: 'name',
        orderByFunctions: {},
        filters: new Map(),
        searchQuery: null,

        options: {
          pagination: { pageSize: MAX_ITEMS_PER_PAGE },
          command: null,
          // state
          isTabularView: false,
          selectedRowKeys: [],

          // data
          rowKey: 'index',
          columns: [],

          orderByRowStateFirst: true,

          // record state
          rowState: record => 'none',
          canClone: record => false,
          canDelete: record => true,
          canToggle: record => false,
          canSelect: record => true,
          toggleDisabled: record => false,
          toggleState: (record) => {},


          // events
          click: (record) => {},
          delete: record => this.gridDeleteSingleItem(record),
          clone: (record) => {
            this.grid.clonePayload = { index: record.index };
            this.grid.drawerVisible = true;
          },
          toggle: (record) => {},
        },
      },
    };
  },
  computed: {
    allItems() {
      return gridHelper.all(this.$store.getters[this.grid.itemsStorePath]);
    },
    gridItems() {
      return gridHelper.filter(this.grid.options.columns, this.$store.getters[this.grid.itemsStorePath], this.grid.orderBy, this.grid.orderByFunctions, this.grid.filters, { query: this.grid.searchQuery, fields: this.grid.searchFields }, this.grid.options.orderByRowStateFirst ? this.grid.options.rowState : null);
    },
  },
  methods: {
    onGridDisplayToggle(isTabularview) {
      this.grid.options.isTabularView = isTabularview;
    },
    async gridDeleteItems(payload) {
      await this.$store.dispatch(this.grid.deleteItemsStoreAction, payload);
    },
    gridOpenDrawer() {
      this.grid.clonePayload = null;
      this.grid.drawerVisible = true;
    },
    gridOrderByChange(value) {
      this.grid.orderBy = value;
    },
    gridFilterChange(e) {
      this.grid.filters = gridHelper.addFilter(this.grid.filters, e);
    },
    gridDeleteSingleItem(record) {
      const self = this;
      this.$confirm({
        title: self.$t('common.Do-you-want-to-delete-this-item'),
        okText: self.$t('common.Ok'),
        cancelText: self.$t('common.Cancel'),
        onOk() {
          return self.gridDeleteItems({ indexes: [record.index] });
        },
        onCancel() {},
      });
    },
    gridDeleteSelected() {
      if (this.grid.options.selectedRowKeys.length > 0) {
        const self = this;
        this.$confirm({
          title: self.$t('common.Do-you-want-to-delete-these-items'),
          okText: self.$t('common.Ok'),
          cancelText: self.$t('common.Cancel'),
          onOk() {
            return self.gridDeleteItems({ indexes: self.grid.options.selectedRowKeys });
          },
          onCancel() {},
        });
      }
    },
    gridSavedFilters(type) {
      // Default return value, if no saved filters found.
      let result = null;

      // Get saved filters (if any).
      const filters = staticData.filtersByStorePath[this.grid.itemsStorePath];

      // If saved filters are found, return an array of the saved filters of the specified type.
      if (filters) {
        result = filters.get(type).map(savedFilter => (
          { key: savedFilter.value.key, value: savedFilter.value.value }
        ));
      }

      return result;
    },
  },
  created() {
    if (staticData.filtersByStorePath[this.grid.itemsStorePath] !== undefined) {
      this.grid.filters = staticData.filtersByStorePath[this.grid.itemsStorePath];
    }
    if (staticData.orderByByStorePath[this.grid.itemsStorePath] !== undefined) {
      this.grid.orderBy = staticData.orderByByStorePath[this.grid.itemsStorePath];
    }
    if (staticData.tabularViewByStorePath[this.grid.itemsStorePath] !== undefined) {
      this.grid.options.isTabularView = staticData.tabularViewByStorePath[this.grid.itemsStorePath];
    }
  },
  beforeDestroy() {
    staticData.filtersByStorePath[this.grid.itemsStorePath] = this.grid.filters;
    staticData.orderByByStorePath[this.grid.itemsStorePath] = this.grid.orderBy;
    staticData.tabularViewByStorePath[this.grid.itemsStorePath] = this.grid.options.isTabularView;
  },
};

export default GridMixin;
