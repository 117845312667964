import Panel from './panel.vue';

const routes = [
  {
    name: 'panel',
    path: '/',
    component: Panel,
    meta: { breadcrumb: [] },
  },
];

export default routes;
