/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import Vue from 'vue';
import { updateField } from 'vuex-map-fields';
import { API } from '@/app/shared/services/api';
import logger from '@/app/shared/services/logger';

logger.log('app/shared/services/store-helper');
function defaultGetters() {
  return {
    isLoading: state => !state.status.isPopulated,
  };
}

function apiDataMerge(dataStatus, obj, newValues) {
  return _.mergeWith(obj, newValues, (existingValue, newValue) => {
    if (newValue === null && dataStatus !== 'FULLY_LOADED') return existingValue;
    return undefined;
  });
}

function mergeCollection(dataStatus, collection, newValuesCollection) {
  const numberOfItems = Math.max(collection.length, newValuesCollection.length);
  const newCollection = [];
  for (let i = 0; i < numberOfItems; i += 1) {
    const obj = collection[i];
    const newValues = newValuesCollection[i];
    const updated = apiDataMerge(dataStatus, obj, newValues);
    newCollection.push(updated);
  }
  return newCollection;
}

export function defaultMutations(initialState) {
  return {
    updateField,
    set(state, data) {
      Object.assign(state, { ...data });
    },
    // Ignores values that are null where api response status code is 202
    // dataStatus = PARTIALLY_LOADED | FULLY_LOADED
    trySet(state, { dataStatus, data }) {
      if (dataStatus === 'PARTIALLY_LOADED') {
        const newState = apiDataMerge(dataStatus, state, data);
        Object.assign(state, newState);
      } else {
        Object.assign(state, { ...data });
      }
    },
    setForm(state, data) {
      Object.assign(state.form, { ...data });
    },
    setStatus(state, data) {
      Object.assign(state.status, { ...data });
    },
    setItemByIndex(state, { collection = 'items', index, item }) {
      const stateItem = { ...state[collection][index], ...item };
      if (stateItem.index === undefined) {
        stateItem.index = index;
      }
      Vue.set(state[collection], index, stateItem);
    },
    addItems(state, { collection = 'items', items }) {
      state[collection].push(...items);
    },
    mergeCollectionByIndex(state, { collection = 'items', items }) {
      const newCollection = mergeCollection('FULLY_LOADED', state[collection], items);
      Vue.set(state, collection, newCollection);
    },
    tryMergeCollectionByIndex(state, { dataStatus, collection = 'items', items }) {
      const newCollection = mergeCollection(dataStatus, state[collection], items);
      Vue.set(state, collection, newCollection);
    },
    setItemByKey(state, { collection = 'items', key, item }) {
      const index = state[collection].findIndex(x => x.key === key);
      const stateItem = apiDataMerge('FULLY_LOADED', state[collection][index], item);

      if (stateItem.value !== undefined) {
        stateItem.isLoading = false;
      }
      if (stateItem.index === undefined) {
        stateItem.index = index;
      }

      if (!_.isEqual(state[collection][index], stateItem)) {
        Vue.set(state[collection], index, stateItem);
      }

      Vue.set(state[collection], index, stateItem);
    },
    trySetItemByKey(state, {
      dataStatus, collection = 'items', key, item,
    }) {
      const index = state[collection].findIndex(x => x.key === key);

      const stateItem = apiDataMerge(dataStatus, state[collection][index], item);

      if (stateItem.value !== undefined) {
        stateItem.isLoading = false;
      }
      if (stateItem.index === undefined) {
        stateItem.index = index;
      }

      if (!_.isEqual(state[collection][index], stateItem)) {
        Vue.set(state[collection], index, stateItem);
      }
    },
    trySetDiagnosticByKey(state, {
      dataStatus, collection = 'diagnostics', key, item,
    }) {
      const index = state[collection].findIndex(x => x.key === key);
      let stateItem = state[collection][index];

      if (dataStatus === 'PARTIALLY_LOADED' && item.value === null) {
        if (stateItem.isLoading) { // if is loading we don't want to try to update the value
          delete item.value;
        } else { // if it's previously been set and now isn't we wan't to mark it as loading again
          stateItem.isLoading = true;
        }
      }

      stateItem = apiDataMerge(dataStatus, stateItem, item);

      if (stateItem.value !== undefined) {
        stateItem.isLoading = false;
      }
      if (stateItem.index === undefined) {
        stateItem.index = index;
      }

      if (dataStatus === 'PARTIALLY_LOADED' && item.value !== null) {
        Vue.set(state[collection], index, stateItem); // force rendering the value if you receive a non-null value (BUG-1358).
      } else if (!_.isEqual(state[collection][index], stateItem)) {
        Vue.set(state[collection], index, stateItem);
      }
    },
    // this function resets the store instances to their default values;
    // not called anymore, to enable the retention of data into local storage
    unload(state) {
      const stateFactory = () => ({ ...{ status: { isPopulated: false } }, ...initialState() });
      Object.assign(state, { ...stateFactory() });
    },
  };
}

function defaultActions(api) {
  return {
    requiredEndpoints() { return []; },
    async populateWrapper(context, payload) {
      await context.dispatch('populate', payload);
      context.commit('setStatus', { isPopulated: true });
    },
    populate() { },
    onPoll() { },
    unload() {
      // cancelling any polling
      if (api && api.transaction) {
        api.transaction.cancelPolling();
      }
      if (api && api.cancelPolling) {
        api.cancelPolling();
      }
    },
    reset(context) {
      // Setting each store to default values (only done before login)
      context.commit('unload');
    },
  };
}

export function storeFactory(initialState, store, api) {
  // eslint-disable-next-line no-param-reassign
  api = api || new API();

  const stateFactory = () => ({ ...{ status: { isPopulated: false } }, ...initialState() });

  const mergedStore = {
    namespaced: true,
    getters: { ...defaultGetters(), ...store.getters },
    mutations: { ...defaultMutations(initialState), ...store.mutations },
    actions: { ...defaultActions(api), ...store.actions },
    state: stateFactory,
  };

  return {
    store: mergedStore,
    api,
  };
}
