<template>
    <a-row :gutter="40" class="ant-col-xs-24">
        <a-col :span="8" :style="{textAlign: 'center'}" v-if="!cellular0.isLoading && cellular0.state !== 'NOT_FITTED'">
          <a-tooltip placement="bottom" :title="cellular0.tooltip()">
            <font-awesome-icon :icon="['fal', strengthIcon('Cellular', cellular0.level)]" size="1x"/>
            <br />
            <span>{{ $t('panel.GPRS') }}</span>
          </a-tooltip>
        </a-col>
        <a-col :span="8" :style="{textAlign: 'center'}" v-if="!cellular1.isLoading && cellular1.state !== 'NOT_FITTED'">
          <a-tooltip placement="bottom" :title="cellular1.tooltip()">
            <font-awesome-icon :icon="['fal', strengthIcon('Cellular', cellular1.level)]" size="1x"/>
            <br />
            <span>{{ $t('panel.GPRS') }}</span>
          </a-tooltip>
        </a-col>
        <a-col :span="8" :style="{textAlign: 'center'}" v-show="!ethernet.isLoading && ethernet.state !== 'NOT_FITTED'">
          <a-tooltip placement="bottom" :title="ethernet.tooltip()">
            <font-awesome-layers v-if="ethernet.state === 'LINK_DOWN'" class="fa-layers fa-fw">
              <font-awesome-icon :icon="['fal', 'network-wired']" />
              <font-awesome-icon :icon="['fal', 'slash']" />
            </font-awesome-layers>
            <font-awesome-icon v-else :icon="['fal', 'network-wired']" size="1x" />
            <br />
            <span>{{ $t('panel.LAN') }}</span>
          </a-tooltip>
        </a-col>
        <a-col :span="8" :style="{textAlign: 'center'}" v-if="!wifi0.isLoading && wifi0.state !== 'NOT_FITTED'">
          <a-tooltip placement="bottom" :title="wifi0.tooltip()">
            <font-awesome-icon :icon="['fal', strengthIcon('WiFi', wifi0.level)]" size="1x"/>
            <br />
            <span>{{ $t('panel.WIFI') }}</span>
          </a-tooltip>
        </a-col>
    </a-row>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapGetters } = createNamespacedHelpers('panelState/panelConnections');

export default {
  computed: {
    ...mapState(['ethernet', 'wifi0', 'wifi1', 'cellular0', 'cellular1']),
    ...mapGetters(['isLoading']),
  },
  methods: {
    strengthIcon(type, level) {
      if (type === 'Cellular') {
        const icon = 'signal';
        if (level >= 75) return icon;
        if (level >= 50) return `${icon}-alt-3`;
        if (level >= 25) return `${icon}-alt-2`;
        if (level >= 1) return `${icon}-alt-1`;
        return `${icon}-slash`;
      }
      if (type === 'WiFi') {
        const icon = 'wifi';
        if (level >= 75) return icon;
        if (level >= 25) return `${icon}-2`;
        if (level >= 1) return `${icon}-1`;
        return `${icon}-slash`;
      }
      return 'network-wired';
    },
  },
};

</script>
