import i18n from '@/app/shared/services/i18n';
import { KEYPAD_AND_READER_TYPE } from '@/app/keypads-and-readers/shared/enums';
import { FOLLOW_TYPE, USER_DEFINED_OUTPUT_TYPE } from '@/app/outputs/shared/enums';
import { limits } from '@/app/shared/constants';

export const DEVICE_TYPE = {
  ENDSTATION: 'ENDSTATION',
  WIRED_KEYPAD: 'WIRED_KEYPAD',
  WIRELESS_KEYPAD: 'WIRELESS_KEYPAD',
  READER: 'READER',
  WIRED_HUB: 'WIRED_HUB',
  OUTPUT_EXPANDER: 'OUTPUT_EXPANDER',
  WIRELESS_HUB: 'WIRELESS_HUB',
};

export const USER_DEFINED_OUTPUT_BEGIN = 170;
export const USER_DEFINED_OUTPUT_END = 200; // 1 after the last

export function isUserDefined(outputNumber) {
  return (outputNumber >= USER_DEFINED_OUTPUT_BEGIN) && (outputNumber < USER_DEFINED_OUTPUT_END);
}

export function udTypeName(udOutputs, outputTypeindex) {
  if (isUserDefined(outputTypeindex)) {
    const udidx = outputTypeindex - USER_DEFINED_OUTPUT_BEGIN;
    return (udOutputs[udidx].UserOutputName.trim() || i18n.t('userDefinedOutput.defaultName', { index: udidx + 1 }));
  }
  return null;
}

/**
 * Converts a (large number) outputtype to either itself (if the type is not general), or the base type/area index if the type is area-specific
 * @param {output type} outputType  The output type (after follow zone substitution)
 * @returns A record containing the base (generic) output type and area index if required
 */
export function outputTypeToAreaSpecific(outputType) {
  const record = {};
  if (outputType < 4002) { // general output types
    record.orig = outputType;
    record.outputIdx = outputType;
    record.areaIdx = undefined;
  } else if (outputType < 6963) { // area specific - area 1
    record.orig = outputType;
    record.outputIdx = ((outputType - 4002) % 30) + 202;
    record.areaIdx = Math.trunc((outputType - 4002) / 30);
  }
  return record;
}


/**
 * Converts a generic-output/area-index into an area-specific outout type
 * @param {output type} outputIdx  The generic area output type, e.g. 202
 * @param {area index} areaIdx  The area index
 * @returns The area specific output type, e.g. 4002
 */
export function areaSpecificToOutputType(outputIdx, areaIdx) {
  return (outputIdx - 202) + 4002 + (areaIdx * 30);
}


/**
 * Tests whether the output type supplied requires an area index
 * @param {[record]} outputTypes list of generic/area index output types
 * @param {output type} currOutputType Output type being tested
 * @returns true if the output type in in the range 202..230
 */
export function isAreaSpecific(outputTypes, currOutputType) {
  const outputType = outputTypes.find(o => o.value === currOutputType);
  if (outputType !== null) {
    return outputType.specific === 'AREA';
  }
  return false;
}


export function translateOutputType(outputTypeEnum) {
  let result = null;
  if (outputTypeEnum) {
    // Check whether the output type enumeration is of the form "aaa_bbb_AREA_ccc".
    const areaType = outputTypeEnum.match(/^(\w+_AREA)_([a-zA-Z0-9]+)$/);

    if (areaType == null) {
      // NOT an area dependent output type: just transform the output type enumeration into human
      // readable text.
      result = i18n.t(`enums.OUTPUT_TYPE.${outputTypeEnum}`);
    } else {
      // An area dependent output type:
      // 1. Transform the "aaa_bbb_AREA" part of the output type enumeration into human readable text.
      // 2. Replace the "{id}" marker (in the human readable text) by the area identifier "ccc".
      result = i18n.t(`enums.OUTPUT_TYPE.${areaType[1]}`, { id: areaType[2] });
    }
  }
  return result;
}

export function convertOutputType(outputType) {
  // If output type is 3000...3999 it means an output has been configured to follow an input so mark as follow = 35
  return (outputType >= 3000) && (outputType <= 3999) ? 35 : outputType;
}

export function hasFollowTime(followType, deviceType) {
  if (followType === FOLLOW_TYPE.TIMED) {
    return deviceType === 'ENDSTATION' || deviceType === 'KEYPAD' || deviceType === 'READER' || deviceType === 'WIRED_ZONE_EXPANDER' || deviceType === 'OUTPUT_EXPANDER';
  }
  return false;
}

export function isSiren(associatedWith) {
  return (associatedWith.type === 'ENDSTATION' || associatedWith.type === 'ENDSTATION_0') && (associatedWith.deviceOutputIndex === 0 || associatedWith.deviceOutputIndex === 1 || associatedWith.deviceOutputIndex === 2);
}

export function isSirenOutputType(outputType) {
  const areaRec = outputTypeToAreaSpecific(outputType);
  return [14, 16, 214, 216].includes(areaRec.outputIdx);
}

export function getOutputInfo(deviceType, deviceIndex, outputIndex, outputType) {
  if (deviceType === 'OUTPUT_EXPANDER') {
    const start = 229;
    return {
      outputIndex: start + (limits.MAX_ROX_OUTPUTS * deviceIndex) + outputIndex,
      type: isSirenOutputType(outputType) ? 'wired-siren' : 'output',
    };
  }

  if (deviceType === 'KEYPAD') {
    const start = 19;
    return {
      outputIndex: start + (1 * deviceIndex) + outputIndex,
      type: isSirenOutputType(outputType) ? 'wired-siren' : 'output',
    };
  }

  if (deviceType === 'READER') {
    const start = 49;
    return {
      outputIndex: start + (2 * deviceIndex) + outputIndex,
      type: isSirenOutputType(outputType) ? 'wired-siren' : 'output',
    };
  }

  if (deviceType === 'WIRED_ZONE_EXPANDER') {
    const start = 109;
    return {
      outputIndex: start + (4 * deviceIndex) + outputIndex,
      type: isSirenOutputType(outputType) ? 'wired-siren' : 'output',
    };
  }

  if (deviceType === 'USER_DEFINED_OUTPUT') {
    return {
      outputIndex,
      type: isSirenOutputType(outputType) ? 'wired-siren' : 'output',
    };
  }

  if (deviceType === 'ENDSTATION') {
    return {
      outputIndex,
      type: isSirenOutputType(outputType) ? 'wired-siren' : 'output',
    };
  }

  if (deviceType === 'WIRELESS_ZONE_EXPANDER') {
    return { outputIndex, type: 'wireless-siren' };
  }

  return outputIndex;
}

export function generateOutputName(type, deviceIndex, deviceOutputIndex, outputType) {
  if (type === 'ENDSTATION') {
    if (deviceOutputIndex === 0) return 'BELL';
    if (deviceOutputIndex === 1) return 'STB';
    if (deviceOutputIndex === 2) return 'SA+ / S+';
    if (deviceOutputIndex === 3) return 'OP 1';
    if (deviceOutputIndex === 4) return 'OP 2';
    if (deviceOutputIndex === 5) return 'OP 3';
    if (deviceOutputIndex === 6) return 'OP 4';
    if (deviceOutputIndex === 7) return 'OP 5';
    if (deviceOutputIndex === 8) return 'OP 6';
    if (deviceOutputIndex === 9) return 'ATE OP 1';
    if (deviceOutputIndex === 10) return 'ATE OP 2';
    if (deviceOutputIndex === 11) return 'ATE OP 3';
    if (deviceOutputIndex === 12) return 'ATE OP 4';
    if (deviceOutputIndex === 13) return 'ATE OP 5';
    if (deviceOutputIndex === 14) return 'ATE OP 6';
    if (deviceOutputIndex === 15) return 'ATE OP 7';
    if (deviceOutputIndex === 16) return 'ATE OP 8';
    if (deviceOutputIndex === 17) return 'ATE OP 9';
    if (deviceOutputIndex === 18) return 'ATE OP 10';
  }
  const associatedWithDeviceSuffix = deviceIndex;
  if (isSirenOutputType(outputType)) {
    return i18n.t(`sirens.names.${type}`, { associatedWithNumber: associatedWithDeviceSuffix, number: deviceOutputIndex + 1 });
  }

  return i18n.t(`outputs.names.${type}`, { associatedWithNumber: associatedWithDeviceSuffix, number: deviceOutputIndex + 1 });
}

export function generateEndstationOutputNumber(endstOutputs) {
  // Remap the endstation output numbers to be displayed on screen, to start at number 1 for 'OP 1', with bells at the end.
  // Matrix280 - [17,18,19,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]
  // MRP - [12,13,0,1,0,0,0,0,0,2,3,4,5,6,7,8,9,10,11]
  let startIndexOP = 0;
  let startIndexBell = 16;
  if (endstOutputs[2].Present === 0) { // check if we're on MRP, which won't have the 3rd bell/op present on board
    startIndexBell = 11;
  }

  const opIndices = endstOutputs.map((x) => {
    // Check whether the output is present on this panel type
    if (x.Present === 1) {
      // Check if the output is bell or anything else (Bells are index 0,1,2 on the panel)
      if (x.Number > 3) {
        startIndexOP += 1;
        return startIndexOP;
      }
      startIndexBell += 1;
      return startIndexBell;
    }
    return 0;
  });
  return opIndices;
}

export function generateAssociatedWithName(type, deviceIndex) {
  const suffix = deviceIndex;
  return i18n.t(`enums.ASSOCIATED_WITH.${type}`, { number: suffix });
}

export function endstationOutputType(index) {
  if (index <= 4) { // 0,1,2,3,4
    return { type: 'PGMs', index };
  }
  if (index >= 5 && index <= 8) { // 5,6,7,8
    return { type: 'ElectronicOutputs', index: index - 5 };
  }
  if (index >= 9 && index < limits.MAX_ES_OUTPUTS) { // 9,10,11,12,13,14,15,16
    return { type: 'AteOutputs', index: index - 9 };
  }
  throw Error('Not an endstation output');
}

export function getEndstationSubType(index) {
  if (index <= 4) { // 0,1,2,3,4
    return { subType: 'PGMs', subTypeIndex: index };
  }
  if (index >= 5 && index <= 8) { // 5,6,7,8
    return { subType: 'ElectronicOutputs', subTypeIndex: index - 5 };
  }
  if (index >= 9 && index < limits.MAX_ES_OUTPUTS) { // 9,10,11,12,13,14,15,16
    return { subType: 'AteOutputs', subTypeIndex: index - 9 };
  }
  throw Error('Not an endstation output');
}

// Custom validator for output time bounds
export function followTimeValidator(value) {
  // Only check the timer limits if configuring an output type Follow (35) TIMED
  if (this.$v.form.baseOutputType.$model === 35 && this.$v.form.followType.$model === FOLLOW_TYPE.TIMED) {
    return value >= 0 && value <= 63;
  }
  // Disregard the follow time value for other output types
  return true;
}

export function isTransistored(device, index) {
  if (device != null && device.type === 'ENDSTATION' && index != null) {
    return endstationOutputType(index).type === 'ElectronicOutputs';
  }
  return false;
}

export function getOutputApiPath(deviceType, deviceIndex, deviceOutputIndex) {
  if (deviceType === 'ENDSTATION') {
    const { type, index } = endstationOutputType(deviceOutputIndex);
    return `/Config/Endstation/${type}/${index}`;
  }
  if (deviceType === 'OUTPUT_EXPANDER') {
    return `/Config/BusDevices/OutputDevices/${deviceIndex}/Outputs/${deviceOutputIndex}`;
  }
  if (deviceType === 'WIRED_ZONE_EXPANDER') {
    return `/Config/BusDevices/wiredHubs/${deviceIndex}/Outputs/${deviceOutputIndex}`;
  }
  if (deviceType === 'KEYPAD') {
    return `/Config/keypadInfo/Keypads/${deviceIndex}/Outputs/${deviceOutputIndex}`;
  }
  if (deviceType === 'READER') {
    return `/Config/keypadInfo/Keypads/${deviceIndex}/Outputs/${deviceOutputIndex}`;
  }
  return null;
}

export async function saveOutput(api, field, deviceType, deviceIndex, deviceOutputIndex, value) {
  if (deviceType === 'ENDSTATION') {
    const { type, index } = endstationOutputType(deviceOutputIndex);
    await api.put(`/Config/Endstation/${type}/${index}/${field}`, value);
  } else if (deviceType === 'OUTPUT_EXPANDER') {
    await api.put(`/Config/BusDevices/OutputDevices/${deviceIndex}/Outputs/${deviceOutputIndex}/${field}`, value);
  } else if (deviceType === 'WIRED_ZONE_EXPANDER') {
    await api.put(`/Config/BusDevices/wiredHubs/${deviceIndex}/Outputs/${deviceOutputIndex}/${field}`, value);
  } else if (deviceType === 'KEYPAD') {
    await api.put(`/Config/keypadInfo/Keypads/${deviceIndex}/Outputs/${deviceOutputIndex}/${field}`, value);
  } else if (deviceType === 'READER') {
    await api.put(`/Config/keypadInfo/Keypads/${deviceIndex}/Outputs/${deviceOutputIndex}/${field}`, value);
  }
}

export async function saveOutputType(api, deviceType, deviceIndex, deviceOutputIndex, outputType) {
  await saveOutput(api, 'OutputType', deviceType, deviceIndex, deviceOutputIndex, outputType);
}

// Endpoints required: configBusDevices configEndstation configKeypadInfo
export function readRawOutputType(endpoints, deviceType, deviceIndex, deviceOutputIndex) {
  if (deviceType === 'ENDSTATION') {
    const { type, index } = endstationOutputType(deviceOutputIndex);
    return endpoints.configEndstation.data.Config.Endstation[type][index].OutputType;
  }
  if (deviceType === 'OUTPUT_EXPANDER') {
    return endpoints.configBusDevices.data.Config.BusDevices.OutputDevices[deviceIndex].Outputs[deviceOutputIndex].OutputType;
  }
  if (deviceType === 'WIRED_ZONE_EXPANDER') {
    return endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[deviceIndex].Outputs[deviceOutputIndex].OutputType;
  }
  if (deviceType === 'WIRELESS_ZONE_EXPANDER') {
    return endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[deviceIndex].OutputType[deviceOutputIndex];
  }
  if (deviceType === 'KEYPAD') {
    return endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[deviceIndex].Outputs[deviceOutputIndex].OutputType;
  }
  if (deviceType === 'READER') {
    return endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[deviceIndex].Outputs[deviceOutputIndex].OutputType;
  }
  return null;
}

// Endpoints required: configBusDevices configKeypadInfo infoBusDevices infoKeypadInfo, infoOutputInfo
export function forEachOutput(endpoints, onOutputFound, includeWireless = false) {
  let index = 0;
  let stop = false;

  const maxPanelOutputs = endpoints.infoOutputInfo.data.Info.outputInfo.EndstationOutputsMaxCount;
  for (let i = 0; i < maxPanelOutputs; i += 1) {
    stop = onOutputFound(index, { type: 'ENDSTATION', deviceIndex: 0, deviceOutputIndex: i });
    if (stop) return;
    index += 1;
  }

  const maxKeypadsAndReaders = endpoints.infoKeypadInfo.data.Info.keypadInfo.WiredKeypadsAndReadersMaxCount;
  const maxKeypadOutputs = endpoints.infoKeypadInfo.data.Info.keypadInfo.WiredKeypadOutputsMaxCount;
  const maxReaderOutputs = endpoints.infoKeypadInfo.data.Info.keypadInfo.WiredReaderOutputsMaxCount || 2;
  for (let i = 0; i < maxKeypadsAndReaders; i += 1) {
    if (endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[i].Type === KEYPAD_AND_READER_TYPE.TYPE_KEYPAD.value || endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[i].Type === KEYPAD_AND_READER_TYPE.TYPE_REMOVABLE_KEYPAD.value) {
      for (let x = 0; x < maxKeypadOutputs; x += 1) {
        stop = onOutputFound(index, { type: 'KEYPAD', deviceIndex: i, deviceOutputIndex: x });
        if (stop) return;
        index += 1;
      }
    } else {
      index += maxKeypadOutputs;
    }
  }
  for (let i = 0; i < maxKeypadsAndReaders; i += 1) {
    if (endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[i].Type === KEYPAD_AND_READER_TYPE.TYPE_READER.value) {
      for (let x = 0; x < maxReaderOutputs; x += 1) {
        stop = onOutputFound(index, { type: 'READER', deviceIndex: i, deviceOutputIndex: x });
        if (stop) return;
        index += 1;
      }
    } else {
      index += maxReaderOutputs;
    }
  }

  const maxWiredZoneExpanders = endpoints.infoBusDevices.data.Info.BusDevices.WiredHubMaxCount;
  const maxWiredZoneExpanderOutputs = endpoints.infoBusDevices.data.Info.BusDevices.WiredHubOutputsMaxCount;
  for (let i = 0; i < maxWiredZoneExpanders; i += 1) {
    if (endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[i].Installed) {
      for (let x = 0; x < maxWiredZoneExpanderOutputs; x += 1) {
        stop = onOutputFound(index, { type: 'WIRED_ZONE_EXPANDER', deviceIndex: i, deviceOutputIndex: x });
        if (stop) return;
        index += 1;
      }
    } else {
      index += maxWiredZoneExpanderOutputs;
    }
  }

  const maxOutputExpanders = endpoints.infoOutputInfo.data.Info.outputInfo.OutputExpanderMaxCount;
  const maxOutputExpanderOutputs = endpoints.infoOutputInfo.data.Info.outputInfo.OutputExpanderOutputsMaxCount;
  for (let i = 0; i < maxOutputExpanders; i += 1) {
    if (endpoints.configBusDevices.data.Config.BusDevices.OutputDevices[i].Installed) {
      for (let x = 0; x < maxOutputExpanderOutputs; x += 1) {
        stop = onOutputFound(index, { type: 'OUTPUT_EXPANDER', deviceIndex: i, deviceOutputIndex: x });
        if (stop) return;
        index += 1;
      }
    } else {
      index += maxOutputExpanderOutputs;
    }
  }

  if (includeWireless) {
    const maxWirelessZoneExpanders = 1; // endpoints.infoBusDevices.data.Info.BusDevices.WirelessHubMaxCount;
    const maxWirelessZoneExpanderOutputs = 4; // endpoints.infoBusDevices.data.Info.BusDevices.WirelessHubOutputsMaxCount;
    for (let i = 0; i < maxWirelessZoneExpanders; i += 1) {
      if (endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[i].Installed) {
        for (let x = 0; x < maxWirelessZoneExpanderOutputs; x += 1) {
          stop = onOutputFound(index, { type: 'WIRELESS_ZONE_EXPANDER', deviceIndex: i, deviceOutputIndex: x });
          if (stop) return;
          index += 1;
        }
      } else {
        index += maxWirelessZoneExpanderOutputs;
      }
    }
  }
}

// Endpoints required: configBusDevices configEndstation configKeypadInfo infoEndstation livePanelStatus
// (called functions require: configBusDevices configEndstation configKeypadInfo)
function getEndstationOutputItem(endpoints, outputIndex, associatedWith) {
  const rawOutputType = readRawOutputType(endpoints, associatedWith.type, associatedWith.deviceIndex, associatedWith.deviceOutputIndex);
  const noFollowOutputType = convertOutputType(rawOutputType);
  const outputRec = outputTypeToAreaSpecific(noFollowOutputType);
  const { subType, subTypeIndex } = getEndstationSubType(associatedWith.deviceOutputIndex);
  const panelConfig = endpoints.configEndstation.data.Config.Endstation[subType][subTypeIndex];
  const name = generateAssociatedWithName('ENDSTATION', 0);
  const endstOutputsInfo = endpoints.infoEndstation.data.Info.Endstation.Outputs;
  const opDisplayNumbers = generateEndstationOutputNumber(endstOutputsInfo);
  const item = {
    outputIndex,
    name: generateOutputName(associatedWith.type, associatedWith.deviceIndex, associatedWith.deviceOutputIndex, noFollowOutputType),
    number: opDisplayNumbers[associatedWith.deviceOutputIndex],
    present: endpoints.infoEndstation.data.Info.Endstation.Outputs[associatedWith.deviceOutputIndex].Present,
    location: name,
    state: undefined,
    rawOutputType,
    noFollowOutputType,
    outputType: outputRec.outputIdx,
    area: outputRec.areaIdx,
    associatedWith: {
      name,
      type: 'ENDSTATION',
      key: 'ENDSTATION_0',
      number: 0,
      deviceOutputIndex: associatedWith.deviceOutputIndex,
      subType,
      subTypeIndex,
    },
    followTime: panelConfig.Timer_100ms,
    followType: panelConfig.FollowState,
    followWhat: panelConfig.FollowWhat,
    followWhen: panelConfig.FollowWhen,
    followZone: panelConfig.FollowZone,
    resetZone: panelConfig.ResetZone,
  };

  if (isTransistored({ type: 'ENDSTATION' }, outputIndex)) {
    item.transistored = endpoints.configEndstation.data.Config.Endstation.ElectronicOutputs[subTypeIndex].Mode;
  }

  if (endpoints.livePanelStatus.data != null) {
    item.state = endpoints.livePanelStatus.data.Live.PanelStatus.OutputTypes[rawOutputType].state;
  }

  return item;
}

// Endpoints required: configBusDevices configEndstation configKeypadInfo livePanelStatus
// (called functions require: configBusDevices configEndstation configKeypadInfo)
function getKeypadOutputItem(endpoints, outputIndex, associatedWith) {
  const panelConfig = endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[associatedWith.deviceIndex].Outputs[associatedWith.deviceOutputIndex];
  const rawOutputType = readRawOutputType(endpoints, associatedWith.type, associatedWith.deviceIndex, associatedWith.deviceOutputIndex);
  const noFollowOutputType = convertOutputType(rawOutputType);
  const outputRec = outputTypeToAreaSpecific(noFollowOutputType);
  const item = {
    outputIndex,
    name: generateOutputName(associatedWith.type, associatedWith.deviceIndex, associatedWith.deviceOutputIndex, noFollowOutputType),
    number: associatedWith.deviceOutputIndex + 1,
    location: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[associatedWith.deviceIndex].Location,
    state: undefined,
    rawOutputType,
    noFollowOutputType,
    outputType: outputRec.outputIdx,
    area: outputRec.areaIdx,
    associatedWith: {
      name: generateAssociatedWithName('KEYPAD', associatedWith.deviceIndex),
      key: `${associatedWith.type}_${associatedWith.deviceIndex}`,
      type: 'KEYPAD',
      number: associatedWith.deviceIndex + 1,
      deviceIndex: associatedWith.deviceIndex,
      deviceOutputIndex: associatedWith.deviceOutputIndex,
    },
    followTime: panelConfig.Timer_100ms,
    followType: panelConfig.FollowState,
    followWhat: panelConfig.FollowWhat,
    followWhen: panelConfig.FollowWhen,
    followZone: panelConfig.FollowZone,
    resetZone: panelConfig.ResetZone,
  };

  if (endpoints.livePanelStatus.data != null) {
    item.state = endpoints.livePanelStatus.data.Live.PanelStatus.OutputTypes[rawOutputType].state;
  }

  return item;
}

// Endpoints required: configBusDevices configEndstation configKeypadInfo livePanelStatus
// (called functions require: configBusDevices configEndstation configKeypadInfo)
function getReaderOutputItem(endpoints, outputIndex, associatedWith) {
  const panelConfig = endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[associatedWith.deviceIndex].Outputs[associatedWith.deviceOutputIndex];
  const rawOutputType = readRawOutputType(endpoints, associatedWith.type, associatedWith.deviceIndex, associatedWith.deviceOutputIndex);
  const noFollowOutputType = convertOutputType(rawOutputType);
  const outputRec = outputTypeToAreaSpecific(noFollowOutputType);
  const item = {
    outputIndex,
    name: generateOutputName(associatedWith.type, associatedWith.deviceIndex, associatedWith.deviceOutputIndex, noFollowOutputType),
    key: `${associatedWith.type}_${associatedWith.deviceIndex}`,
    number: associatedWith.deviceOutputIndex + 1,
    location: endpoints.configKeypadInfo.data.Config.keypadInfo.Keypads[associatedWith.deviceIndex].Location,
    state: undefined,
    rawOutputType,
    noFollowOutputType,
    outputType: outputRec.outputIdx,
    area: outputRec.areaIdx,
    associatedWith: {
      name: generateAssociatedWithName('READER', associatedWith.deviceIndex),
      key: `${associatedWith.type}_${associatedWith.deviceIndex}`,
      type: 'READER',
      number: associatedWith.deviceIndex + 1,
      deviceIndex: associatedWith.deviceIndex,
      deviceOutputIndex: associatedWith.deviceOutputIndex,
    },
    followTime: panelConfig.Timer_100ms,
    followType: panelConfig.FollowState,
    followWhat: panelConfig.FollowWhat,
    followWhen: panelConfig.FollowWhen,
    followZone: panelConfig.FollowZone,
    resetZone: panelConfig.ResetZone,
  };

  if (endpoints.livePanelStatus.data != null) {
    item.state = endpoints.livePanelStatus.data.Live.PanelStatus.OutputTypes[rawOutputType].state;
  }

  return item;
}

// Endpoints required: configBusDevices configEndstation configKeypadInfo livePanelStatus
// (called functions require: configBusDevices configEndstation configKeypadInfo)
function getWiredZoneExpanderOutputItem(endpoints, outputIndex, associatedWith) {
  const panelConfig = endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[associatedWith.deviceIndex].Outputs[associatedWith.deviceOutputIndex];
  const rawOutputType = readRawOutputType(endpoints, associatedWith.type, associatedWith.deviceIndex, associatedWith.deviceOutputIndex);
  const noFollowOutputType = convertOutputType(rawOutputType);
  const outputRec = outputTypeToAreaSpecific(noFollowOutputType);
  const item = {
    outputIndex,
    name: generateOutputName(associatedWith.type, associatedWith.deviceIndex, associatedWith.deviceOutputIndex, noFollowOutputType),
    key: `${associatedWith.type}_${associatedWith.deviceIndex}`,
    number: associatedWith.deviceOutputIndex + 1,
    location: endpoints.configBusDevices.data.Config.BusDevices.wiredHubs[associatedWith.deviceIndex].Location,
    state: undefined,
    rawOutputType,
    noFollowOutputType,
    outputType: outputRec.outputIdx,
    area: outputRec.areaIdx,
    associatedWith: {
      name: generateAssociatedWithName('WIRED_ZONE_EXPANDER', associatedWith.deviceIndex),
      key: `${associatedWith.type}_${associatedWith.deviceIndex}`,
      type: 'WIRED_ZONE_EXPANDER',
      number: associatedWith.deviceIndex + 1,
      deviceIndex: associatedWith.deviceIndex,
      deviceOutputIndex: associatedWith.deviceOutputIndex,
    },
    followTime: panelConfig.Timer_100ms,
    followType: panelConfig.FollowState,
    followWhat: panelConfig.FollowWhat,
    followWhen: panelConfig.FollowWhen,
    followZone: panelConfig.FollowZone,
    resetZone: panelConfig.ResetZone,
  };

  if (endpoints.livePanelStatus.data != null) {
    item.state = endpoints.livePanelStatus.data.Live.PanelStatus.OutputTypes[rawOutputType].state;
  }

  return item;
}

// Endpoints required: configBusDevices configEndstation configKeypadInfo livePanelStatus
// (called functions require: configBusDevices configEndstation configKeypadInfo)
function getOutputExpanderOutputItem(endpoints, outputIndex, associatedWith) {
  const panelConfig = endpoints.configBusDevices.data.Config.BusDevices.OutputDevices[associatedWith.deviceIndex].Outputs[associatedWith.deviceOutputIndex];
  const rawOutputType = readRawOutputType(endpoints, associatedWith.type, associatedWith.deviceIndex, associatedWith.deviceOutputIndex);
  const noFollowOutputType = convertOutputType(rawOutputType);
  const outputRec = outputTypeToAreaSpecific(noFollowOutputType);
  const item = {
    outputIndex,
    name: generateOutputName(associatedWith.type, associatedWith.deviceIndex, associatedWith.deviceOutputIndex, noFollowOutputType),
    key: `${associatedWith.type}_${associatedWith.deviceIndex}`,
    number: associatedWith.deviceOutputIndex + 1,
    location: endpoints.configBusDevices.data.Config.BusDevices.OutputDevices[associatedWith.deviceIndex].Location,
    state: undefined,
    rawOutputType,
    noFollowOutputType,
    outputType: outputRec.outputIdx,
    area: outputRec.areaIdx,
    associatedWith: {
      name: generateAssociatedWithName('OUTPUT_EXPANDER', associatedWith.deviceIndex),
      key: `${associatedWith.type}_${associatedWith.deviceIndex}`,
      type: 'OUTPUT_EXPANDER',
      number: associatedWith.deviceIndex + 1,
      deviceIndex: associatedWith.deviceIndex,
      deviceOutputIndex: associatedWith.deviceOutputIndex,
    },
    followTime: panelConfig.Timer_100ms,
    followType: panelConfig.FollowState,
    followWhat: panelConfig.FollowWhat,
    followWhen: panelConfig.FollowWhen,
    followZone: panelConfig.FollowZone,
    resetZone: panelConfig.ResetZone,
  };

  if (endpoints.livePanelStatus.data != null) {
    item.state = endpoints.livePanelStatus.data.Live.PanelStatus.OutputTypes[rawOutputType].state;
  }

  return item;
}

// Endpoints required: configBusDevices configEndstation configKeypadInfo livePanelStatus
// (called functions require: configBusDevices configEndstation configKeypadInfo)
function getWirelessZoneExpanderOutputItem(endpoints, outputIndex, associatedWith) {
  const rawOutputType = readRawOutputType(endpoints, associatedWith.type, associatedWith.deviceIndex, associatedWith.deviceOutputIndex);
  const noFollowOutputType = convertOutputType(rawOutputType);
  const outputRec = outputTypeToAreaSpecific(noFollowOutputType);
  const item = {
    outputIndex,
    name: generateOutputName(associatedWith.type, associatedWith.deviceIndex, associatedWith.deviceOutputIndex, noFollowOutputType),
    key: `${associatedWith.type}_${associatedWith.deviceIndex}`,
    number: associatedWith.deviceOutputIndex + 1,
    location: endpoints.configBusDevices.data.Config.BusDevices.wirelessHubs[associatedWith.deviceIndex].Location,
    state: undefined,
    rawOutputType,
    noFollowOutputType,
    outputType: outputRec.outputIdx,
    area: outputRec.areaIdx,
    associatedWith: {
      name: generateAssociatedWithName('WIRELESS_ZONE_EXPANDER', associatedWith.deviceIndex),
      key: `${associatedWith.type}_${associatedWith.deviceIndex}`,
      type: 'WIRELESS_ZONE_EXPANDER',
      number: associatedWith.deviceIndex + 1,
      deviceIndex: associatedWith.deviceIndex,
      deviceOutputIndex: associatedWith.deviceOutputIndex,
    },
  };

  if (endpoints.livePanelStatus.data != null) {
    item.state = endpoints.livePanelStatus.data.Live.PanelStatus.OutputTypes[rawOutputType].state;
  }

  return item;
}

// Endpoints required: configBusDevices configEndstation configKeypadInfo infoBusDevices infoKeypadInfo infoEndstation infoOutputInfo livePanelStatus
// (called functions require: configBusDevices configKeypadInfo infoBusDevices infoKeypadInfo, infoOutputInfo)
export function getAllOutputs(endpoints, includeWireless) {
  const outputs = [];
  forEachOutput(endpoints, (outputIndex, associatedWith) => {
    let outputItem;
    if (associatedWith.type === 'ENDSTATION') {
      outputItem = getEndstationOutputItem(endpoints, outputIndex, associatedWith);
    } else if (associatedWith.type === 'KEYPAD') {
      outputItem = getKeypadOutputItem(endpoints, outputIndex, associatedWith);
    } else if (associatedWith.type === 'READER') {
      outputItem = getReaderOutputItem(endpoints, outputIndex, associatedWith);
    } else if (associatedWith.type === 'WIRED_ZONE_EXPANDER') {
      outputItem = getWiredZoneExpanderOutputItem(endpoints, outputIndex, associatedWith);
    } else if (associatedWith.type === 'OUTPUT_EXPANDER') {
      outputItem = getOutputExpanderOutputItem(endpoints, outputIndex, associatedWith);
    } else if (associatedWith.type === 'WIRELESS_ZONE_EXPANDER') {
      outputItem = getWirelessZoneExpanderOutputItem(endpoints, outputIndex, associatedWith);
    }

    outputs.push(outputItem);
  }, includeWireless);
  return outputs;
}

// Endpoints required: configBusDevices configEndstation configKeypadInfo infoBusDevices infoEndstation infoKeypadInfo infoOutputInfo livePanelStatus
// (called functions require: configBusDevices configKeypadInfo infoBusDevices infoKeypadInfo, infoOutputInfo)
export function getSingleOutputInfo(endpoints, index) {
  let outputItem = null;
  forEachOutput(endpoints, (outputIndex, associatedWith) => {
    if (outputIndex === index) {
      if (associatedWith.type === 'ENDSTATION') {
        outputItem = getEndstationOutputItem(endpoints, outputIndex, associatedWith);
      } else if (associatedWith.type === 'KEYPAD') {
        outputItem = getKeypadOutputItem(endpoints, outputIndex, associatedWith);
      } else if (associatedWith.type === 'READER') {
        outputItem = getReaderOutputItem(endpoints, outputIndex, associatedWith);
      } else if (associatedWith.type === 'WIRED_ZONE_EXPANDER') {
        outputItem = getWiredZoneExpanderOutputItem(endpoints, outputIndex, associatedWith);
      } else if (associatedWith.type === 'OUTPUT_EXPANDER') {
        outputItem = getOutputExpanderOutputItem(endpoints, outputIndex, associatedWith);
      } else if (associatedWith.type === 'WIRELESS_ZONE_EXPANDER') {
        outputItem = getWirelessZoneExpanderOutputItem(endpoints, outputIndex, associatedWith);
      }
    }
  }, true);
  return outputItem;
}

// Endpoints required: configBusDevices configEndstation configKeypadInfo infoBusDevices infoEndstation infoKeypadInfo infoOutputInfo
// (called functions require: configBusDevices configEndstation configKeypadInfo)
// (called function forEachOutput() requires: configBusDevices configKeypadInfo infoBusDevices infoKeypadInfo, infoOutputInfo)
export function getAssociatedDevices(index, endpoints) {
  const devices = [];
  forEachOutput(endpoints, (outputIndex, associatedWith) => {
    const associatedWithDeviceKey = `${associatedWith.type}_${associatedWith.deviceIndex}`;
    let device = devices.find(d => d.key === associatedWithDeviceKey);
    const endstOutputsInfo = endpoints.infoEndstation.data.Info.Endstation.Outputs;
    const opDisplayNumbers = generateEndstationOutputNumber(endstOutputsInfo);

    if (device == null) {
      device = {
        name: generateAssociatedWithName(associatedWith.type, associatedWith.deviceIndex),
        key: associatedWithDeviceKey,
        type: associatedWith.type,
        deviceIndex: associatedWith.deviceIndex,
        availableOutputs: [],
      };
      devices.push(device);
    }

    const UNUSED_TYPE = 0;
    const type = readRawOutputType(endpoints, associatedWith.type, associatedWith.deviceIndex, associatedWith.deviceOutputIndex);

    if (associatedWith.type === 'ENDSTATION') {
      const present = endpoints.infoEndstation.data.Info.Endstation.Outputs[associatedWith.deviceOutputIndex].Present;
      if (present && (type === UNUSED_TYPE || (outputIndex === index))) {
        device.availableOutputs.push({ deviceOutputIndex: associatedWith.deviceOutputIndex, deviceOutputIndexDisplay: opDisplayNumbers[associatedWith.deviceOutputIndex] - 1, outputIndex });
      }
    } else if (type === UNUSED_TYPE || (outputIndex === index)) {
      device.availableOutputs.push({ deviceOutputIndex: associatedWith.deviceOutputIndex, outputIndex });
    }
  });

  return devices;
}


const AreaOutputs = {
  4002: { field: 'HOLD_UP_AREA', genericid: 202 },
  4003: { field: 'INTRUDER_AREA', genericid: 203 },
  4004: { field: 'FINAL_ARM_AREA', genericid: 204 },
  4006: { field: 'CONFIRM_AREA', genericid: 206 },
  4007: { field: 'TAMPER_AREA', genericid: 207 },
  4008: { field: 'DURESS_AREA', genericid: 208 },
  4009: { field: 'ZONE_PA_AREA', genericid: 209 },
  4010: { field: 'FIRE_RESET_AREA', genericid: 210 },
  4013: { field: 'SEC_INT_AREA', genericid: 213 },
  4014: { field: 'SIREN_AREA', genericid: 214 },
  4016: { field: 'STROBE_AREA', genericid: 216 },
  4017: { field: 'ZONE_OMIT_REARM_AREA', genericid: 217 },
  4018: { field: 'INTR_TAMPER_AREA', genericid: 218 },
  4019: { field: 'CAN_ARM_AREA', genericid: 219 },
  4020: { field: 'EXIT_STARTS_AREA', genericid: 220 },
  4022: { field: 'LIGHTHOUSE_AREA', genericid: 502 },
};

// Endpoints required: infoOutputInfo
export function createOutputsList(infoOutputInfoEndpoint, udOutputs) {
  const outputTypesGeneral = [];
  for (let i = 0; i < infoOutputInfoEndpoint.OutputTypes.length; i += 1) {
    const outputType = infoOutputInfoEndpoint.OutputTypes[i].Name;
    if (outputType != null) {
      const areaRec = outputTypeToAreaSpecific(i);
      if (areaRec.areaIdx === undefined) {
        const record = {
          key: outputType,
          value: i,
          sortkey: i,
          name: (isUserDefined(i) ? udTypeName(udOutputs, i) : translateOutputType(outputType)),
          isSirenOutputType: isSirenOutputType(i),
          specific: undefined,
        };
        outputTypesGeneral.push(record);
      } else if (areaRec.areaIdx === 0) {
        const record = {
          key: outputType,
          value: areaRec.outputIdx,
          sortkey: AreaOutputs[i].genericid,
          name: i18n.t(`enums.OUTPUT_TYPE.${AreaOutputs[i].field}`, { id: '...' }),
          isSirenOutputType: isSirenOutputType(areaRec.outputIdx),
          specific: 'AREA',
        };
        outputTypesGeneral.push(record);
      } else {
        // other areas - ignore
      }
    }
  }
  return outputTypesGeneral.sort((l, r) => l.sortkey - r.sortkey);
}

// Endpoints required: infoOutputInfo
export function createRawOutputsList(infoOutputInfoEndpoint, udOutputs) {
  const outputTypes = {};
  for (let i = 0; i < infoOutputInfoEndpoint.OutputTypes.length; i += 1) {
    const item = infoOutputInfoEndpoint.OutputTypes[i];
    const name = (isUserDefined(i) ? udTypeName(udOutputs, i) : translateOutputType(item.Name));

    if (item.Name != null) {
      outputTypes[i] = { key: item.Name, name, isSirenOutputType: isSirenOutputType(i) };
    }
  }
  return outputTypes;
}

// Endpoints required: infoOutputInfo
export function createRawOutputsListWithValue(infoOutputInfoEndpoint, udOutputs) {
  const outputTypes = [];
  for (let i = 0; i < infoOutputInfoEndpoint.OutputTypes.length; i += 1) {
    const outputType = infoOutputInfoEndpoint.OutputTypes[i].Name;
    if (outputType != null) {
      const areaRec = outputTypeToAreaSpecific(i);
      if (areaRec.areaIdx === undefined) {
        const record = {
          key: outputType,
          value: i,
          sortKey: i,
          name: (isUserDefined(i) ? udTypeName(udOutputs, i) : translateOutputType(outputType)),
          isSirenOutputType: isSirenOutputType(i),
        };
        outputTypes.push(record);
      } else {
        // other areas - ignore
      }
    }
  }
  return outputTypes.sort((l, r) => l.sortKey - r.sortKey);
}


export function isUdPulsed(outputs, outputNumber) {
  if (isUserDefined(outputNumber)) {
    const output = outputs[outputNumber - USER_DEFINED_OUTPUT_BEGIN];
    return (output.UserOutputLatchedOrTimed === USER_DEFINED_OUTPUT_TYPE.USER_OUTPUT_PULSED.value);
  }
  return false;
}

export function udPulseTime(outputs, outputNumber) {
  if (isUdPulsed(outputs, outputNumber)) {
    const output = outputs[outputNumber - USER_DEFINED_OUTPUT_BEGIN];
    return output.UserOutputOnTime_secs;
  }
  return null;
}
