/* eslint-disable no-param-reassign */
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faExclamationTriangle,
  faExternalLinkAlt,
  faUsers,
  faColumns,
  faStreetView,
  faVolume,
  faMinusCircle,
  faWalking,
  faBells,
  faCogs,
  faChevronDoubleLeft,
  faChevronLeft,
  faChevronRight,
  faChevronDoubleRight,
  faBell,
  faBroadcastTower,
  faTachometerAltFast,
  faBatteryFull,
  faCheckCircle,
  faTimesCircle,
  faSpinner,
  faSpinnerThird,
  faThumbsDown,
  faThumbsUp,
  faTachometerAltAverage,
  faLock,
  faUnlock,
  faSlash,
  faNetworkWired,
  faSignalSlash,
  faSignal,
  faSignalAlt1,
  faSignalAlt2,
  faSignalAlt3,
  faWifi,
  faWifiSlash,
  faWifi1,
  faWifi2,
  faCopy,
  faHome,
  faSearch,
  faPlusCircle,
  faScannerKeyboard,
  faExclamationCircle,
  faTable,
  faBatterySlash,
  faCog,
  faTrash,
  faEllipsisH,
  faPlus,
  faSignOut,
  faBullhorn,
  faSirenOn,
  faWrench,
  faHistory,
  faFileExport,
  faMicrochip,
} from '@fortawesome/pro-light-svg-icons';
import {
  faGripHorizontal,
} from '@fortawesome/free-solid-svg-icons';
import {
  faBell as faBellSolid,
  faLockAlt,
  faLockOpenAlt,
} from '@fortawesome/pro-solid-svg-icons';

const FontAwesome = {
  install(Vue) {
    library.add(faBars);
    library.add(faExclamationTriangle);
    library.add(faExternalLinkAlt);
    library.add(faUsers);
    library.add(faColumns);
    library.add(faStreetView);
    library.add(faVolume);
    library.add(faMinusCircle);
    library.add(faWalking);
    library.add(faBells);
    library.add(faCogs);
    library.add(faChevronDoubleLeft);
    library.add(faChevronLeft);
    library.add(faChevronRight);
    library.add(faChevronDoubleRight);
    library.add(faBell);
    library.add(faBroadcastTower);
    library.add(faBellSolid);
    library.add(faTachometerAltFast);
    library.add(faBatteryFull);
    library.add(faCheckCircle);
    library.add(faTimesCircle);
    library.add(faSpinner);
    library.add(faSpinnerThird);
    library.add(faThumbsDown);
    library.add(faThumbsUp);
    library.add(faTachometerAltAverage);
    library.add(faLock);
    library.add(faLockAlt);
    library.add(faLockOpenAlt);
    library.add(faUnlock);
    library.add(faSlash);
    library.add(faNetworkWired);
    library.add(faSignalSlash);
    library.add(faSignal);
    library.add(faSignalAlt1);
    library.add(faSignalAlt2);
    library.add(faSignalAlt3);
    library.add(faWifi);
    library.add(faWifiSlash);
    library.add(faWifi1);
    library.add(faWifi2);
    library.add(faCopy);
    library.add(faHome);
    library.add(faSearch);
    library.add(faPlusCircle);
    library.add(faScannerKeyboard);
    library.add(faExclamationCircle);
    library.add(faTable);
    library.add(faBatterySlash);
    library.add(faCog);
    library.add(faTrash);
    library.add(faEllipsisH);
    library.add(faPlus);
    library.add(faSignOut);
    library.add(faBullhorn);
    library.add(faSirenOn);
    library.add(faWrench);
    library.add(faHistory);
    library.add(faFileExport);
    library.add(faMicrochip);

    library.add(faGripHorizontal);


    Vue.component('font-awesome-icon', FontAwesomeIcon);
    Vue.component('font-awesome-layers', FontAwesomeLayers);
  },
};

export default FontAwesome;
