import i18n from '@/app/shared/services/i18n';
import { ACCESS_TYPE, USER_TYPE } from '@/app/users/shared/enums';
import { limits, MAX_GUARD_USERS } from '@/app/shared/constants';

export const TAG_LENGTH = 12;
export const MIN_CODE_LENGTH = 4;
export const MAX_CODE_LENGTH = 6;


export function getNumber(index) {
  if (index <= 1) return null;
  if (index >= limits.MAX_USERS - MAX_GUARD_USERS + 1 && index <= limits.MAX_USERS) return index - (limits.MAX_USERS - MAX_GUARD_USERS);

  return index - 1;
}

export async function canHaveAutoDuress(api, index, accessType) {
  const isSupported = (await api.get('/Info/userInfo/IsAutoDuressSupported')).data.Info.userInfo.IsAutoDuressSupported;
  return isSupported && (index >= 2) && (index <= limits.MAX_USERS - MAX_GUARD_USERS) && (accessType === ACCESS_TYPE.USER_CODE.value);
}

export function getUserType(index, userType) {
  if (index === 0) return USER_TYPE.ENGINEER.value;
  if (index === 1) return USER_TYPE.MASTERMAN.value;
  return userType;
}

export function getApiUserType(index, userType) {
  if (index === 0) return USER_TYPE.USER.value;
  if (index === 1) return USER_TYPE.MANAGER.value;
  return userType;
}

export function getName(index, name) {
  if (!name && index === 0) return i18n.t('users.Engineer');
  if (!name && index === 1) return i18n.t('users.MasterManager');
  return name;
}

export function toFriendlyCode(codeArray) {
  const type = ACCESS_TYPE.fromCode(codeArray);
  if (type === ACCESS_TYPE.USER_CODE) {
    return codeArray
      .filter(v => v <= 9)
      .reverse()
      .join('');
  }
  if (type === ACCESS_TYPE.USER_TAG) {
    return codeArray
      .map((v, i) => ((i === 0) ? (v & 0x7f) : v))
      .map(v => (`0${v.toString(16)}`).slice(-2))
      .join('').toLowerCase();
  }
  return '';
}

export async function getDefaultEngineersCode(api) {
  const engCode = (await api.get('/Info/Features/Panel/DefaultEngCode')).data.Info.Features.Panel.DefaultEngCode;
  return engCode;
}
