<template>
    <a-drawer
    placement="right"
    :closable="false"
    @close="onClose"
    :destroyOnClose="true"
    :visible="visible">

      <template slot="title">
          {{ titleLabel }}
      </template>

      <div>
          <a-row>
              <card class="card--small" v-for="(item, index) in availableItems" v-bind:key="`item-${index}`">
                  <cardHeader>
                      <a-checkbox @change="onSelect(item, $event.target.checked)" :checked="selectedItems.filter(z => z.value === item.value).length > 0" />
                      <div>
                          <h4>{{ item.name }}</h4>
                      </div>
                  </cardHeader>
              </card>
              <a-col class="gutter-row" v-if="availableItems.length < 1">
                  <p>{{ noItemsLabel }}</p>
              </a-col>
          </a-row>
      </div>

       <div class="draw-footer">
         <a-button size="large" type="link" @click="onClose">{{ $t('common.Cancel') }}</a-button>
          <a-button size="large" type="primary" @click="addItems">{{ addLabel }}</a-button>
        </div>


    </a-drawer>
</template>
<script>
import Card from '@/app/shared/components/Card/card.vue';
import CardHeader from '@/app/shared/components/Card/Child/cardHeader.vue';

export default {
  components: {
    Card,
    CardHeader,
  },
  props: {
    visible: { default: false },
    titleLabel: {},
    noItemsLabel: {},
    addLabel: {},
    availableItems: { default: [] },
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {

  },
  methods: {
    onSelect(item, checked) {
      if (checked) {
        this.selectedItems.push({ value: item.value, name: item.name });
      } else {
        const index = this.selectedItems.map(z => z.value).indexOf(item.value);
        if (index !== -1) this.selectedItems.splice(index, 1);
      }
    },
    onClose() {
      this.$emit('close');
    },
    addItems() {
      this.$emit('onAdd', this.selectedItems);
    },
  },
};

</script>

<style lang="scss">
    .ant-drawer-title{
        .subtitle {
            float:right;
        }
    }

.ant-drawer-title {
    h3 {
        margin-top:20px;
    }
}
</style>
