import { API } from '@/app/shared/services/api';

const api = new API();
const batteryTestEndpoint = '/Live/Diagnostics/Endstation/BatteryTest';

function dropPolledEndpoint() {
  // Drop battery test specific endpoints from the current context
  const temp = window.globalThis.currentRequiredEndpoints;
  try {
    window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== batteryTestEndpoint);
  } catch (err) {
    // nothing to do
  }
}
async function doChecks() {
  const batteryLoadTest = (await api.get('/Live/ActiveFaults/Devices/Endstation/BatteryLoadTest')).data.Live.ActiveFaults.Devices.Endstation.BatteryLoadTest;
  const batteryVoltageCritical = (await api.get('/Live/ActiveFaults/Devices/Endstation/BatteryVoltageCritical')).data.Live.ActiveFaults.Devices.Endstation.BatteryVoltageCritical;
  const batteryVoltageLow = (await api.get('/Live/ActiveFaults/Devices/Endstation/BatteryVoltageLow')).data.Live.ActiveFaults.Devices.Endstation.BatteryVoltageLow;

  return {
    batteryLoadTest, batteryVoltageCritical, batteryVoltageLow,
  };
}

async function start() {
  await api.put(batteryTestEndpoint, true);

  let externalPromiseResolver;
  const promise = new Promise((resolve) => {
    externalPromiseResolver = resolve;
  });

  // Bring the ad-hoc battery test endpoint into the scope of current endpoints
  if (window.globalThis.currentRequiredEndpoints.indexOf(batteryTestEndpoint) === -1) {
    window.globalThis.currentRequiredEndpoints.push(batteryTestEndpoint);
  }

  let success = false;
  api.poll(5000, batteryTestEndpoint, (res) => {
    if (res.data.Live.Diagnostics.Endstation.BatteryTest !== true) {
      externalPromiseResolver();
      dropPolledEndpoint();
      return false;
    }
    success = true;
    return true;
  });

  await promise;

  const voltage = (await api.get('/Live/Endstation/BatteryVoltage_mV')).data.Live.Endstation.BatteryVoltage_mV / 1000;
  const current = (await api.get('/Live/Endstation/BatteryCurrent_mA')).data.Live.Endstation.BatteryCurrent_mA / 1000;

  return { success, voltage, current };
}

async function finish() {
  api.cancelPolling();
  dropPolledEndpoint();
  await api.put(batteryTestEndpoint, false);
}

export default { start, doChecks, finish };
