<template>
    <two-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
          <loading-indicator v-if="isLoading"/>
          <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
            <h1>{{ $t('history.panelLogs.navigationTitle') }}</h1>
          </a-skeleton>
        </template>
        <list-item class="list" type="error" v-for="(item, index) in items" :item="item" :key="`log-${index}`" :timeZoneIndex="timeZoneIndex" />
        <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
          @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
    </two-col-layout>
</template>

<script>

import { createNamespacedHelpers } from 'vuex';
import ListItem from '@/app/history/shared/components/list-item/list-item.vue';
import {
  ConfigWriteLock, StateLoader, DisplayFaultsGuard,
} from '@/app/shared/mixins';
import DynamicLoader from '@/app/shared/services/dynamic-loader';
import LoadingIndicator from '@/app/shared/components/loading-indicator/loading-indicator.vue';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

const storeNamespace = 'historyState/panelList';

const { mapActions, mapGetters, mapState } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, DisplayFaultsGuard],
  components: {
    ListItem,
    LoadingIndicator,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        type: DynamicLoader,
        storeNamespace,
      },
    };
  },
  computed: {
    ...mapGetters(['items', 'isLoading']),
    ...mapState(['timeZoneIndex']),
  },
  methods: {
    ...mapActions(['loadPage', 'resetPage', 'setReadFinished', 'rewindPage']),
    scroll() {
      window.onscroll = () => {
        const self = this;
        const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= (document.getElementById('app') || document.body).offsetHeight - 5;
        if (bottomOfWindow) {
          window.onscroll = undefined;
          if (!self.loadPage()) {
            document.documentElement.scrollTop -= 20;
          }
        }
      };
    },
  },
  mounted() {
    this.resetPage();
    this.scroll();
  },
  async beforeDestroy() {
    this.resetPage();
  },
  async updated() {
    this.setReadFinished();
    const store = DynamicLoader.getInstance('panel-logs').stores[storeNamespace];
    const { overlapped = false } = store;
    // it's 'overlapped' if a second request comes in while the first is going on
    // In this case, try to replay by changing a (fake) parameter.
    if (overlapped) {
      this.rewindPage();
      store.overlapped = false;
    }
    this.scroll();
  },
};
</script>

<style lang="scss">

</style>
