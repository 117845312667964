<template>
    <two-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
             <h1 id="title">{{ $t('outputs.navigationTitle.Outputs') }}</h1>
        </template>
        <template v-slot:titleCommands>
          <a-button type="settings" @click="$router.push({ name: 'settings.areasOptions' })">
            <font-awesome-icon :icon="['fal', 'cog']" />
          </a-button>
          <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" icon="plus-circle" @click="gridOpenDrawer">{{ $t('outputs.Add-output') }}</a-button>
        </template>

        <div>
             <div>
          <a-input-search :placeholder="$t('outputs.Search-for-an-output')" size="large" v-model="grid.searchQuery" />

          <a-row :gutter="23" class="header">
              <a-col :xs="{ span: 18}" :md="{ span: 18}" :lg="{ span: 18}" :xl="{ span: 18}" class="filters">
                  <a-dropdown class="grid-filter">
                      <a-menu slot="overlay">
                        <a-menu-item @click="gridDeleteSelected">
                          {{ $t('common.Delete')}}
                        </a-menu-item>
                        <a-menu-item @click="enableSelected">
                          {{ $t('common.Enable')}}
                        </a-menu-item>
                        <a-menu-item @click="disableSelected">
                          {{ $t('common.Disable')}}
                        </a-menu-item>
                      </a-menu>
                      <a-button>
                          {{ $t('common.Actions') }} <a-icon type="down" />
                      </a-button>
                  </a-dropdown>
                  <grid-filter :title="$t('common.Status')" :records="allItems" field="state" @change="gridFilterChange" resPrefix="outputs.enums.state." :initialFilters="gridSavedFilters('state')" />
                  <grid-filter :title="$t('common.Type')" :records="allItems" field="translatedOutputType" @change="gridFilterChange" :initialFilters="gridSavedFilters('translatedOutputType')" />
                  <grid-order :options="[{ field: 'name', name:  $t('common.Name')},{ field: 'number', name: $t('common.Number')}, { field: 'associatedWith', name: $t('common.Associated-with')}]" @change="gridOrderByChange" />
              </a-col>

              <a-col :xs="{ span: 6}" :md="{ span: 6}" :lg="{ span: 6}" :xl="{ span: 6}" class="align-right hide-small">
                <grid-display-toggle :isTabularview="grid.options.isTabularView" @toggle="onGridDisplayToggle" />
              </a-col>
          </a-row>
          <div>
              <ul class="inline-list">
                <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:1, width:'100%' }">
                  <li><span>{{ installed }}/{{ max }} {{ $t('common.outputs') }}</span></li>
                </a-skeleton>
              </ul>
          </div>
      </div>
      <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:10, width:'100%' }">
        <grid
          :options="grid.options"
          :records="gridItems">
            <div slot="card-title" slot-scope="scope">
                <div v-if="scope.record.name === LOADING">
                  <font-awesome-icon :icon="['fal', 'spinner-third']" spin />
                </div>
                <div v-else>
                  <h3>
                    {{ scope.record.name }}
                  </h3>
                </div>
                <p class="subtitle" v-on:click.stop>{{ $t('common.Associated-with') }}
                <router-link to="/" v-if="scope.record.associatedWith.type === 'ENDSTATION'">
                  {{ scope.record.associatedWith.name }}
                </router-link>
                <router-link :to="`/output-expanders/output-expander/${scope.record.associatedWith.deviceIndex}`" v-if="scope.record.associatedWith.type === 'OUTPUT_EXPANDER'">
                  {{ scope.record.associatedWith.name }}
                </router-link>
                <router-link :to="`/zone-expanders/wired-zone-expander/${scope.record.associatedWith.deviceIndex}`" v-if="scope.record.associatedWith.type === 'WIRED_ZONE_EXPANDER'">
                  {{ scope.record.associatedWith.name }}
                </router-link>
                <router-link :to="`/zone-expanders/wireless-zone-expander/${scope.record.associatedWith.deviceIndex}`" v-if="scope.record.associatedWith.type === 'WIRELESS_ZONE_EXPANDER'">
                  {{ scope.record.associatedWith.name }}
                </router-link>
                <router-link :to="`/keypads-and-readers/wired-keypad/${scope.record.associatedWith.deviceIndex}`" v-if="scope.record.associatedWith.type === 'KEYPAD'">
                  {{ scope.record.associatedWith.name }}
                </router-link>
                <router-link :to="`/keypads-and-readers/wired-reader/${scope.record.associatedWith.deviceIndex}`" v-if="scope.record.associatedWith.type === 'READER'">
                  {{ scope.record.associatedWith.name }}
                </router-link>
              </p>
            </div>
        </grid>
      </a-skeleton>
    </div>
    <add-output :visible="grid.drawerVisible" @onClose="grid.drawerVisible = false" :clone-payload="grid.clonePayload" />
    <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
      @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
    </two-col-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import AddOutput from '@/app/outputs/shared/components/add-output/add-output.vue';
import {
  ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard,
} from '@/app/shared/mixins';
import outputHelper from '@/app/shared/services/output-helper';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import { setDataRequiredFlag } from '@/app/outputs/pages/outputs-list/outputs-list-data';
import outputsPollHelper from '@/app/outputs/shared/services/outputs-poll-helper';
import { livePanelStatusOutputsConsts, LOADING } from '@/app/shared/constants';

const storeNamespace = 'outputsState/outputsList';
const { mapState, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard],
  components: {
    AddOutput,
    DisplayFaultsModal,
  },
  data() {
    return {
      LOADING,
      loader: {
        storeNamespace,
      },
      intervalID: [], // Stores the interval ID for clearing later
      clonePayload: undefined,
      grid: {
        itemsStorePath: `${storeNamespace}/items`,
        deleteItemsStoreAction: `${storeNamespace}/delete`,

        canToggle: true,
        searchFields: ['name', 'location', 'number'],
        orderBy: 'associatedWith',

        options: {
          columns: [
            {
              title: this.$t('common.Name'),
              dataIndex: 'name',
              isTitle: true,
            },
            {
              title: this.$t('common.Location'),
              dataIndex: 'location',
            },
            {
              title: this.$t('common.Output'),
              dataIndex: 'number',
            },
            {
              title: this.$t('common.Type'),
              dataIndex: 'noFollowOutputType',
              customRender: item => this.outputTypes[item].name,
            },
            {
              title: this.$t('common.Associated-with'),
              dataIndex: 'associatedWith',
              customRender: item => item.name,
              isTitle: true,
            },
          ],

          orderByRowStateFirst: false,
          rowState: (record) => { if (record.state != null) { return record.state ? 'active' : 'loading'; } return 'loading'; },
          canClone: () => this.configWriteLock.canWriteConfig,
          canDelete: () => this.configWriteLock.canWriteConfig,
          canToggle: () => true,
          toggleState: record => record.state,

          click: (record) => {
            this.$router.push({ name: 'output', params: { index: record.outputIndex } });
          },
          clone: (record) => {
            this.grid.clonePayload = { index: record.index };
            this.grid.drawerVisible = true;
          },
          toggle: async (index, record) => {
            await outputHelper.activateOutputType(record.rawOutputType, !record.state, this.loader.instance);
            if (record.outputTypeName === 'FOLLOW' && record.followType === 'TIMED') {
              if (!record.state === true) {
                this.startCountdown(index, record.followTime, async () => {
                  await outputHelper.activateOutputType(record.rawOutputType, !record.state, this.loader.instance);
                });
              } else {
                clearInterval(this.intervalID[index]);
              }
            } else if (record.udPulseTime !== null) {
              if (!record.state === true) {
                this.startCountdown(index, record.udPulseTime, async () => {
                  await outputHelper.activateOutputType(record.rawOutputType, !record.state, this.loader.instance);
                });
              } else {
                clearInterval(this.intervalID[index]);
              }
            }
          },
        },
      },
    };
  },
  computed: {
    ...mapState(['max', 'installed', 'outputTypes']),
    ...mapGetters(['isLoading']),
  },
  methods: {
    startCountdown(index, timer, callback) {
      let initialValue = timer;
      this.intervalID[index] = setInterval(() => {
        initialValue -= 1;
        if (initialValue <= 0) {
          clearInterval(this.intervalID[index]);
          callback();
        }
      }, 1000);
    },
    async enableSelected() {
      const selectedKeys = this.grid.options.selectedRowKeys;
      if (selectedKeys.length > 0) {
        const outputTypes = this.gridItems.filter(i => selectedKeys.includes(i.index)).map(i => i.rawOutputType);
        await outputHelper.activateOutputTypes(outputTypes, true, this.loader.instance);
        this.grid.options.command = 'clear-selection';
      }
    },
    async disableSelected() {
      const selectedKeys = this.grid.options.selectedRowKeys;
      if (selectedKeys.length > 0) {
        const outputTypes = this.gridItems.filter(i => selectedKeys.includes(i.index)).map(i => i.rawOutputType);
        await outputHelper.activateOutputTypes(outputTypes, false, this.loader.instance);
        this.grid.options.command = 'clear-selection';
      }
    },
  },
  created() {
    setDataRequiredFlag();
    // Start polling Live/PanelStatus every 5 seconds.
    outputsPollHelper.customisePollingWithIntervalAndUrl(this.loader.instance, livePanelStatusOutputsConsts.activeInterval, livePanelStatusOutputsConsts.baseUrl);
  },
  destroyed() {
    outputsPollHelper.revertCustomPollingIfPossible(this.loader.instance);
  },
};
</script>

<style lang="scss">
</style>
