<template>
  <!-- NOTE: Used a custom paging component for the grid, as the default ant design one changes the number of items, based on the current page  -->
  <!-- I.e. For the 1st 3 pages it displays 3 buttons (exc. the next & previous), then on page 4 it adds a 4th button -->
  <!-- This caused issues with the next page button being shifted to the right, & the user accidentally clicking on the last page button -->
  <!-- There wasn't any way to configure the amount of buttons to display, using this default component, -->
  <!-- apart from 'showLessItems', which only reduces the starting amount of buttons, but this still has the same issue -->

  <ul class="ant-pagination" v-if="total > 0">
    <li class="ant-pagination-prev" :class="{ 'ant-pagination-disabled': isFirstPage }" @click="toStart">
      <a-tooltip :title="$t('common.paging.toStart')">
        <a class="ant-pagination-item-link">
          <font-awesome-icon :icon="['fal', 'chevron-double-left']" />
        </a>
      </a-tooltip>
    </li>
    <li class="ant-pagination-item" :class="{ 'ant-pagination-disabled': isFirstPage }" @click="toPrevious">
      <a-tooltip :title="$t('common.paging.toPrevious')">
        <a class="ant-pagination-item-link">
          <font-awesome-icon :icon="['fal', 'chevron-left']" />
        </a>
      </a-tooltip>
    </li>

    <li v-for="page in pagesToShow" :key="page" :class="pageClassName(page)" @click="toPage(page)">
      <a>{{page}}</a>
    </li>

    <li class="ant-pagination-item" :class="{ 'ant-pagination-disabled': isLastPage }" @click="toNext">
      <a-tooltip :title="$t('common.paging.toNext')">
        <a class="ant-pagination-item-link">
          <font-awesome-icon :icon="['fal', 'chevron-right']" />
        </a>
      </a-tooltip>
    </li>
    <li class="ant-pagination-next" :class="{ 'ant-pagination-disabled': isLastPage }">
      <a-tooltip :title="$t('common.paging.toEnd')">
        <a class="ant-pagination-item-link" @click="toEnd">
          <font-awesome-icon :icon="['fal', 'chevron-double-right']" />
        </a>
      </a-tooltip>
    </li>
    <li class="ant-pagination-total-text">{{totalMessage}}</li>
  </ul>
</template>
<script>

export default {
  props: {
    total: { default: 0 },
    pageSize: { default: 10 },
    visiblePages: { default: 5 },
    showTotal: { default: '' },
  },
  data() {
    return {
      selectedPage: 1,
    };
  },
  watch: {
    selectedPage(page) {
      this.$emit('change', page);
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.pageSize);
    },
    pages() {
      const pages = [];
      for (let i = 1; i <= this.pageCount; i += 1) {
        pages.push(i);
      }
      return pages;
    },
    lastPage() {
      return this.pages[this.pages.length - 1] || 1;
    },
    isFirstPage() {
      return this.selectedPage === 1;
    },
    isLastPage() {
      return this.selectedPage === this.lastPage;
    },
    pagesToShow() {
      const count = Math.min(this.visiblePages, this.pageCount);
      const selectedPosition = Math.ceil(count / 2);
      const selectedPages = [];

      let start = this.selectedPage - (selectedPosition - 1);
      const end = start + (selectedPosition + 1);

      if (end > this.lastPage) start -= (end - this.lastPage);
      if (start < 1) start = 1;

      for (let i = start; i < (start + count); i += 1) {
        selectedPages.push(i);
      }

      return selectedPages;
    },
    totalMessage() {
      // When searching/filtering has reduced the number of pages, it's possible for the selected page
      // to be beyond the last page. If so, move the selected page to the first page.
      if (this.selectedPage > this.lastPage) {
        this.toStart();
      }
      let end = this.selectedPage * this.pageSize;
      const start = end - (this.pageSize - 1);

      if (end > this.total) {
        end = this.total;
      }
      return this.$t('common.gridItemTotals', { start, end, total: this.total });
    },
  },
  methods: {
    pageClassName(page) {
      const classes = [
        'ant-pagination-item',
        `ant-pagination-item-${page}`,
      ];

      if (page === this.selectedPage) {
        classes.push('ant-pagination-item-active');
      }

      return classes.join(' ');
    },
    toPage(page) {
      if (page > 0 && page <= this.lastPage) {
        this.selectedPage = page;
      }
    },
    toStart() {
      this.toPage(1);
    },
    toEnd() {
      this.toPage(this.lastPage);
    },
    toPrevious() {
      this.toPage(this.selectedPage - 1);
    },
    toNext() {
      this.toPage(this.selectedPage + 1);
    },
  },
};
</script>
<style lang="scss">
  ul.ant-pagination {
    border-top: solid 1px #e4e4e4;
    padding-top: 0.5em;
    margin-bottom: 0.75em;

    li.ant-pagination-total-text {
      float: right;
    }
  }
</style>
