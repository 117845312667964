import { storeFactory } from '@/app/shared/services/store-helper';

function initialState() {
  return {
    isLoading: undefined,

    panelType: undefined,
    version: undefined,
    systemID: undefined,
  };
}

const { store } = storeFactory(initialState, {
  actions: {
    requiredEndpoints: () => ['infoEndstation'],
    async populate(context, { endpoints }) {
      context.commit('set', {
        panelType: endpoints.infoEndstation.data.Info.Endstation.PanelType,
        version: endpoints.infoEndstation.data.Info.Endstation.Version,
        systemID: endpoints.infoEndstation.data.Info.Endstation.SystemID,
      });

      context.commit('set', { isLoading: false });
    },
  },
});

export default store;
