<template>
   <three-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
            <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
                <h1 id="title">{{ name }}</h1>
            </a-skeleton>
        </template>
        <template v-slot:titleCommands>
          <item-menu
            @save="saveForm" :isSaving="isSaving"
            @delete="onDelete"
            :canClone="true" @clone="addDrawerVisible = true"
            :hasToggle="true"
            :toggled="!setOmitted"
            :toggleTooltip="activeTooltipText(omittable, !setOmitted)"
            @toggle="omittZone()"
            :disabled="!configWriteLock.canWriteConfig"
            :switchDisabled="!omittable" />
        </template>

        <diagnostics :globalState="globalState" :items="diagnostics" :isLoading="false" :options="diagnosticOptions" />

        <form>
            <div class="styled-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('zones.Zone-information') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                              <p-input :label="$t('common.Name')" :model="$v.form.name" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                <p-select :label="$t('zones.Zone-type')" :model="$v.form.zoneType"
                                    :items="Object.keys(ZONE_TYPE)" :valueExpr="(i) => i" :textExpr="(i) => $t(`zones.enums.zoneType.${i}`)"
                                    :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                <p-input :label="$t('common.Location')" :model="$v.form.location" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                              <a-tooltip :title="optionsTooltipText($v.form.anyAllArea)">
                                <span><p-switch :label="$t('zones.Common-input')" :model="$v.form.anyAllArea" :disabled="!configWriteLock.canWriteConfig" /></span>
                              </a-tooltip>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                <p-level-selector :label="$t('common.Areas')" :model="$v.form.areas" :areasInfo="$v.form.areasInfo.$model" :areasConfig="$v.form.areasConfig.$model" :disabled="!configWriteLock.canWriteConfig"/>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-select :label="$t('common.Associated-with')" :model="$v.form.associatedWithDevice" @change="$v.form.index.$model = null"
                                    :items="associatedDevices" :valueExpr="(i) => i.key" :textExpr="(i) => i.name"
                                    :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-select :label="$t('common.Number')" :model="$v.form.index"
                                    :items="zoneIndexes" :valueExpr="(i) => i" :textExpr="(i) => (i + 1)"
                                    :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-readonly :label="$t('zones.Connection-type')" :value="$t(`zones.enums.connectionType.${$v.form.connectionType.$model}`)" />
                            </a-col>
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

            <div class="styled-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('zones.Zone-settings') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-select :label="$t('zones.Chime')" :model="$v.form.chime"
                                    :items="Object.keys(CHIMES)" :valueExpr="(i) => CHIMES[i]" :textExpr="(i) => $t(`enums.CHIMES.${i}`)"
                                    :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="isOmittable">
                              <a-tooltip :title="optionsTooltipText($v.form.allowByPass)">
                                <span><p-switch :label="$t('zones.Allow-bypass')" :model="$v.form.allowByPass" :disabled="!configWriteLock.canWriteConfig" /></span>
                              </a-tooltip>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <a-tooltip :title="optionsTooltipText($v.form.doubleKnock)">
                                <span><p-switch :label="$t('zones.Double-knock')" :model="$v.form.doubleKnock" :disabled="!configWriteLock.canWriteConfig" /></span>
                              </a-tooltip>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                              <a-tooltip :title="optionsTooltipText($v.form.crossZone)">
                                <span><p-switch :label="$t('zones.Cross-zone')" :model="$v.form.crossZone" :disabled="!configWriteLock.canWriteConfig" /></span>
                              </a-tooltip>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                              <a-tooltip :title="optionsTooltipText($v.form.normallyOpen)">
                                <span><p-switch :label="$t('zones.Normally-open')" :model="$v.form.normallyOpen" :disabled="!configWriteLock.canWriteConfig" /></span>
                              </a-tooltip>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <a-tooltip :title="optionsTooltipText($v.form.maskTest)">
                                <span><p-switch :label="$t('zones.Mask-test')" :model="$v.form.maskTest" :disabled="!configWriteLock.canWriteConfig" /></span>
                              </a-tooltip>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <a-tooltip :title="optionsTooltipText($v.form.nonActivityInput)">
                                <span><p-switch :label="$t('zones.Non-activity-input')" :model="$v.form.nonActivityInput" :disabled="!configWriteLock.canWriteConfig" /></span>
                              </a-tooltip>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <a-tooltip :title="optionsTooltipText($v.form.occupancy)">
                                <span><p-switch :label="$t('zones.Occupancy')" :model="$v.form.occupancy" :disabled="!configWriteLock.canWriteConfig" /></span>
                              </a-tooltip>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" :validate-status="$v.form.specialLogged.$error ? 'error': 'success'">
                              <p-select :label="$t('zones.Special-log')" :model="$v.form.specialLogged"
                                :items="Object.keys(SPECIAL_LOGGED)" :valueExpr="(i) => SPECIAL_LOGGED[i]" :textExpr="(i) => $t(`enums.SPECIAL_LOGGED.${i}`)"
                                :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-input :label="$t('zones.Swinger-limit')" :model="$v.form.swingerLimit" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.connectionType.$model === 'TYPE_WIRELESS'">
                              <span><p-select :label="$t('zones.Supervision')" :model="$v.form.supervision" :disabled="!configWriteLock.canWriteConfig"
                                :items="Object.keys(SUPERVISION)" :valueExpr="(i) => i" :textExpr="(i) => $t(`zones.enums.SUPERVISION.${i}`)" /></span>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-input :label="$t('zones.Confirm-group')" :model="$v.form.confirmGroup" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <!-- <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <a-tooltip :title="optionsTooltipText($v.form.soakTest)">
                                <span><p-switch :label="$t('zones.Soak-test')" :model="$v.form.soakTest" :disabled="!configWriteLock.canWriteConfig" /></span>
                              </a-tooltip>
                            </a-col> -->
                        </a-row>
                        <hr class="dashed-bottom" />
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <a-tooltip :title="optionsTooltipText($v.form.inertiaInput)">
                                <span><p-switch :label="$t('zones.Inertia-input')" :model="$v.form.inertiaInput" @change="inertiaInputChanged" :disabled="!configWriteLock.canWriteConfig" /></span>
                              </a-tooltip>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.inertiaInput.$model">
                              <p-input :label="$t('zones.Gross-attack')" :model="$v.form.inertiaDebounceTime" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.inertiaInput.$model">
                              <p-input :label="$t('zones.Inertia-pulse-count')" :model="$v.form.inertiaDebounceCount" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>
                        <hr class="dashed-bottom" v-if="$v.form.hasPerZoneOptions.$model === true"/>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.hasPerZoneOptions.$model === true">
                              <p-select :label="$t('zones.EOL-resistor-range')" :model="$v.form.eolResistorRange" @change="eolResistorRangeChanged"
                                :items="Object.keys(ZONE_RESISTOR_RANGE)" :valueExpr="(i) => ZONE_RESISTOR_RANGE[i]" :textExpr="(i) => $t(`enums.ZONE_RESISTOR_RANGE.${i}`)"
                                :customValidations="[{ key: 'mustBeValidEOLRange', message: $t('zones.Must-be-valid-eol-range') }]"
                                :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" v-if="$v.form.hasPerZoneOptions.$model === true">
                              <p-select :label="$t('zones.EOL-resistor-mode')" :model="$v.form.eolResistorMode" @change="eolResistorModeChanged"
                                :items="Object.keys(ZONE_RESISTOR_MODE)" :valueExpr="(i) => ZONE_RESISTOR_MODE[i]" :textExpr="(i) => $t(`enums.ZONE_RESISTOR_MODE.${i}`)"
                                :customValidations="[{ key: 'mustBeValidEOLMode', message: $t('zones.Must-be-valid-eol-mode') }]"
                                :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

            <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
            <add-zone v-bind:visible.sync="addDrawerVisible" :navigateToNewItem="true" :clone-payload="{ index: $route.params.index }" />
            <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
              @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
        </form>

   </three-col-layout>
</template>

<script>
import { required, maxLength, between } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import AddZone from '@/app/zones/shared/components/add-zone/add-zone.vue';
import ItemMenu from '@/app/shared/components/item-menu/item-menu.vue';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import {
  CHIMES, SPECIAL_LOGGED, SUPERVISION, ZONE_TYPE, ZONE_RESISTOR_MODE, ZONE_RESISTOR_RANGE,
} from '@/app/zones/shared/enums';
import zoneHelper from '@/app/shared/services/zone-helper';
import { mustBeValidEOLRange, mustBeValidEOLMode } from '@/app/zones/shared/services/validations';
import { API } from '@/app/shared/services/api';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import { liveZoneInfoConsts, liveWirelessZoneExpanderConsts } from '@/app/shared/constants';
import { canBeOmitted } from '@/app/zones/shared/services/helpers';
import zoneExpanderHelper from '@/app/shared/services/zone-expander-helper';

const api = new API();

const storeNamespace = 'zonesState/zone';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);


export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    UnsavedChangesModal,
    AddZone,
    ItemMenu,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
      CHIMES,
      SPECIAL_LOGGED,
      SUPERVISION,
      ZONE_TYPE,
      ZONE_RESISTOR_MODE,
      ZONE_RESISTOR_RANGE,
      diagnosticOptions: {
        statusType(diagnostic) {
          if (diagnostic.key === 'wiredZone.Diagnostics.Status') {
            return diagnostic.value === 'OK' ? 'active' : 'error';
          }

          if (diagnostic.key === 'wirelessZone.Diagnostics.Supervision') {
            return (diagnostic.value === true) ? 'active' : 'error';
          }

          if (diagnostic.key === 'wirelessZone.Diagnostics.BatteryStatus') {
            return (diagnostic.value === 'updating...' || diagnostic.value === 'GOOD') ? 'active' : 'error';
          }

          if (diagnostic.key === 'wirelessZone.Diagnostics.SignalStrength') {
            return (diagnostic.value === 'updating...' || (diagnostic.value !== 0 && diagnostic.format === '{0}%')) ? 'active' : 'error';
          }

          return 'none';
        },
      },
      canEdit: () => this.configWriteLock.canWriteConfig,
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(16),
      },
      zoneType: {
        required,
      },
      location: {
        maxLength: maxLength(16),
      },
      areas: {},
      areasInfo: {},
      areasConfig: {},
      anyAllArea: {},
      associatedWithDevice: {
        required,
      },
      index: {
        required,
      },
      connectionType: {},
      chime: {
        required,
      },
      allowByPass: {},
      crossZone: {},
      normallyOpen: {},
      specialLogged: {},
      nonActivityInput: {},
      occupancy: {},
      swingerLimit: {
        between: between(0, 99),
      },
      supervision: {},
      confirmGroup: {
        between: between(0, 99),
      },
      doubleKnock: {},
      maskTest: {},
      hasPerZoneOptions: {},
      eolResistorMode: {
        mustBeValidEOLMode: mustBeValidEOLMode(false),
      },
      eolResistorRange: {
        mustBeValidEOLRange: mustBeValidEOLRange(false),
      },
      inertiaInput: {},
      inertiaDebounceCount: {
        between: between(0, 20),
      },
      inertiaDebounceTime: {
        between: between(0, 99),
      },
      // soakTest: {},
    },
  },
  computed: {
    ...mapState(['form', 'globalState', 'name', 'omittable', 'forceOmitted', 'setOmitted', 'managerOmitted', 'associatedDevices']),
    ...mapGetters(['isLoading', 'diagnostics']),
    zoneIndexes() {
      const device = this.associatedDevices.find(v => v.key === this.$v.form.associatedWithDevice.$model);
      return device ? device.availableZonesIndexes : [];
    },
    isOmittable() {
      return canBeOmitted(this.$v.form.zoneType.$model);
    },
  },
  methods: {
    ...mapActions(['save', 'deleteItem']),
    onDelete() {
      return this.deleteItem().then(() => { this.$router.push({ name: 'zones-list' }); });
    },
    inertiaInputChanged() {
      this.$v.form.inertiaDebounceCount.$model = 4;
      this.$v.form.inertiaDebounceTime.$model = 4;
      if (this.$v.form.inertiaInput.$model === true) {
        this.$v.form.eolResistorMode.$model = 'DOUBLE';
        this.$v.form.eolResistorRange.$model = 'ZONE_EOL_1K_1K';
      }
    },
    eolResistorRangeChanged() {
      if (this.$v.form.eolResistorRange.$model === 'ZONE_EOL_0K_0K') {
        // Choosing EOL resistance of 0K/0K implies EOL mode of "normally closed".
        this.$v.form.eolResistorMode.$model = 'NORMALLY_CLOSED';
      }
      this.$v.form.eolResistorMode.$touch();
    },
    eolResistorModeChanged() {
      if (this.$v.form.eolResistorMode.$model === 'NORMALLY_CLOSED') {
        // Choosing EOL mode of "normally closed" implies EOL resistance of 0K/0K.
        this.$v.form.eolResistorRange.$model = 'ZONE_EOL_0K_0K';
      }
      this.$v.form.eolResistorRange.$touch();
    },
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });

      if (this.$route.params.index !== this.form.index) {
        this.$router.replace({ name: 'zone', params: { index: this.form.index } });
        this.load();
      }
    },
    async omittZone() {
      await zoneHelper.bypass(this.$route.params.index, !this.setOmitted, this.loader.instance);
    },
    activeTooltipText(isOmittable, isToggled) {
      if (isOmittable) {
        return isToggled
          ? this.$t('wiredZone.ToggleOptions.zoneEnabled')
          : this.$t('wiredZone.ToggleOptions.zoneDisabled');
      }
      return this.$t('wiredZone.ToggleOptions.zoneNotOmittable');
    },
    optionsTooltipText(field) {
      return field && field.$model
        ? this.$t('common.OptionEnabledTooltip')
        : this.$t('common.OptionDisabledTooltip');
    },
  },
  created() {
    // Begin polling the selected zone faster in order to update its resistance display quicker.
    const zoneList = this.$route.params.index.toString();
    const zoneUrl = liveZoneInfoConsts.baseUrl.concat('/Zones/').concat(zoneList);
    // Poll the currently selected zone at 450ms.
    zoneHelper.customisePollingWithIntervalAndUrl(this.loader.instance, 450, zoneUrl);
    // And start polling all wireless hubs.
    const wirelessExpanderIndexes = '0,1,2'; // Poll all three wireless hubs
    const zoneExpanderUrl = liveWirelessZoneExpanderConsts.baseUrl.concat('/').concat(wirelessExpanderIndexes);
    zoneExpanderHelper.customiseExpanderPollingWithIntervalAndUrl(liveWirelessZoneExpanderConsts.key, this.loader.instance, liveWirelessZoneExpanderConsts.activeInterval, zoneExpanderUrl);
  },
  updated() {
    // keep track of the last config lock state
    if (this.canEdit !== this.configWriteLock.canWriteConfig) {
      // if we're just tacking back the lock ensure the detectors are awake
      if (!this.canEdit && this.configWriteLock.canWriteConfig) {
        // wake up the detectors after regaining the lock
        api.put('/Live/Devices/Wireless/ActivateAllDetectors', true);
      }
      this.canEdit = this.configWriteLock.canWriteConfig;
    }
  },
  beforeDestroy() {
    if ((this.$router.history.current.name !== 'zones-list') && (this.$router.history.current.name !== 'wireless-zone-expander')) {
      api.put('/Live/Devices/Wireless/ActivateAllDetectors', false);
    }
  },
  destroyed() {
    // Custom polling of a specific zone is no longer required, so revert if no other pages using it.
    zoneHelper.revertCustomPollingIfPossible(this.loader.instance);
    // Stop polling all wireless hubs.
    zoneExpanderHelper.revertExpanderCustomPollingIfPossible(liveWirelessZoneExpanderConsts.key, this.loader.instance);
  },
};
</script>

<style lang="scss">

</style>
