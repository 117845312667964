import { API } from '@/app/shared/services/api';

const api = new API();

async function start() {
  await api.put('/Live/Diagnostics/SirenAndStrobeTest', true);
}

async function finish() {
  await api.put('/Live/Diagnostics/SirenAndStrobeTest', false);
}

export default { start, finish };
