<template>
  <div>
      <a-row>
        <config-write-lock-alert :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine"/>
        <h2 class="page-title">{{ $t('settings.zoneSettings.navigationTitle') }}</h2>
      </a-row>
      <a-form>
        <p-switch :label="$t('settings.zoneSettings.Forced-arm')" :model="$v.form.forceArm" :labelTooltip="$t('settings.zoneSettings.Forced-arm-tooltip')" :switchTooltip="toggleTooltipText($v.form.forceArm)" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-switch :label="$t('settings.zoneSettings.Bypass-on-rearm')" :model="$v.form.bypassOnRearm" :labelTooltip="$t('settings.zoneSettings.Bypass-on-rearm-tooltip')" :switchTooltip="toggleTooltipText($v.form.bypassOnRearm)" display-style="small" :disabled="!configWriteLock.canWriteConfig" />
        <p-select :label="$t('settings.zoneSettings.EOL')" :model="$v.form.eol" :labelTooltip="$t('settings.zoneSettings.EOL-tooltip')" :switchTooltip="toggleTooltipText($v.form.eol)" display-style="small"
           :items="Object.keys(get_eol_range)" :valueExpr="(i) => get_eol_range[i]" :textExpr="(i) => $t(`enums.EOL_RANGE.${i}`)"
           :disabled="!configWriteLock.canWriteConfig" />
        <a-row>
          <a-col :sm="{ offset: 12 }" :md="{ offset: 8 }" :lg="{ offset: 6 }" :xl="{ offset: 5 }">
           <a-checkbox v-model="$v.form.applyChangesToAllZones.$model">
            {{ $t('settings.zoneSettings.Apply-changes-to-all-zones') }}
          </a-checkbox>
          </a-col>
        </a-row>
        <p-select :label="$t('settings.zoneSettings.EOL-resistor-mode')" :model="$v.form.eolMode" :labelTooltip="$t('settings.zoneSettings.EOLMode-tooltip')" :switchTooltip="toggleTooltipText($v.form.PerZoneEolMode)" display-style="small"
           :items="Object.keys(get_eolMode_range)" :valueExpr="(i) => get_eolMode_range[i]" :textExpr="(i) => $t(`enums.ZONE_RESISTOR_MODE.${i}`)"
           :disabled="!configWriteLock.canWriteConfig" />
      </a-form>
      <div class="align-right">
        <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" size="large" @click="saveForm(false)" :loading="isSaving"> {{ $t('common.Save') }}</a-button>
      </div>

       <a-modal :title="$t('settings.zoneSettings.ZonesOverwriteModal.title')" v-model="confirmVisible">
        <p>{{ $t('settings.zoneSettings.ZonesOverwriteModal.description1') }}</p>
        <p>{{ $t('settings.zoneSettings.ZonesOverwriteModal.description2') }}</p>
        <template slot="footer">
            <a-row :gutter="8">
                <a-col :span="12" style="text-align: left;">
                    <a-button type="link" @click="confirmVisible = false">{{ $t('common.Cancel') }}</a-button>
                </a-col>
                <a-col :span="12">
                    <a-button type="primary" @click="saveForm(true)">{{ $t('settings.zoneSettings.ZonesOverwriteModal.buttonText') }}</a-button>
                </a-col>
            </a-row>
        </template>
    </a-modal>

      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm(false)" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { EOL_RANGE_OPTIONS, ZONE_RESISTOR_MODE } from '@/app/settings/shared/enums';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import TooltipHelper from '@/app/shared/services/tooltipHelper';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import configWriteLockAlert from '@/app/shared/components/ConfigWriteLockAlert/ConfigWriteLockAlert.vue';


const storeNamespace = 'settingsState/zoneSettings';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    configWriteLockAlert,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      addDrawerVisible: false,
      isSaving: false,
      confirmVisible: false,
    };
  },
  computed: {
    ...mapState(['form', 'riscoEolRangeSupportedEnabled']),
    get_eol_range() {
      let range = {};
      if (this.riscoEolRangeSupportedEnabled === true) {
        range = EOL_RANGE_OPTIONS;
        range.EOL_6K8_4K7 = 'EOL_6K8_4K7';
      } else if (this.riscoEolRangeSupportedEnabled === false) {
        range = EOL_RANGE_OPTIONS;
      }
      return range;
    },
    get_eolMode_range() {
      let range = {};
      range = ZONE_RESISTOR_MODE;
      return range;
    },
  },
  validations: {
    form: {
      forceArm: {},
      bypassOnRearm: {},
      eol: {},
      eolMode: {},
      applyChangesToAllZones: {},
    },
  },
  methods: {
    ...mapActions(['save']),
    async saveForm(forceSave) {
      if (forceSave) this.confirmVisible = false;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      if (!forceSave && this.$v.form.applyChangesToAllZones.$model === true) {
        this.confirmVisible = true;
      } else {
        this.isSaving = true;
        NoLeaveWhileSavingGuard.isSavingGuard = true;
        await this.save();
        NoLeaveWhileSavingGuard.isSavingGuard = false;
        this.isSaving = false;

        this.$notification.success({
          placement: 'bottomRight',
          message: this.$t('common.ItemHasBeenSaved'),
        });
      }
    },
    toggleTooltipText: TooltipHelper.toggleTooltipText,
  },
};
</script>
