<template>

    <div>
        <slot></slot>
    </div>

</template>

<script>

export default {
  components: {
  },
  provide() {
    return {
      addStep: this.addStep,
    };
  },
  props: {
    config: {},
    state: {},
    save: {},
    initialStep: {},
  },
  data() {
    return {
      steps: [],
      activeStepIndex: 0,
    };
  },
  computed: {
  },
  methods: {
    saveWizard() {
      this.$emit('save');
    },
    initializeSteps() {
      if (this.initialStep != null) {
        this.navigateTo(this.initialStep);
      } else {
        this.openStep(this.activeStepIndex);
      }
    },
    navigateToIndex(index) {
      const next = this.beforeStepChange(this.activeStepIndex);
      if (next) {
        this.changeStep(this.activeStepIndex, index);
      }
    },
    navigateTo(name) {
      const next = this.beforeStepChange(this.activeStepIndex);
      if (next) {
        const index = this.steps.findIndex(s => s.name === name);
        this.changeStep(this.activeStepIndex, index);
      }
    },
    changeStep(oldIndex, newIndex) {
      const step = this.steps[oldIndex];
      step.active = false;
      this.openStep(newIndex);
      this.activeStepIndex = newIndex;
      const current = this.steps[newIndex].currentAction;
      if (current.onShow) current.onShow();
    },
    openStep(index) {
      const step = this.steps[index];
      step.active = true;
    },
    addStep(step) {
      const index = this.$slots.default.indexOf(step.$vnode);
      this.steps.splice(index, 0, step);

      return this;
    },
    beforeStepChange(index) {
      const step = this.steps[index];
      if (step && step.beforeChange !== undefined) {
        return step.beforeChange();
      }

      return true;
    },
    done() {
      this.$emit('done');
    },
  },
  mounted() {
    this.initializeSteps();
  },
};
</script>

<style lang="scss">
</style>
