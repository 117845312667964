import { Loader } from '@/app/shared/services/generic-loader';

const StateLoader = {
  data() {
    return {
      loader: {
        _promise: new Promise((resolve) => {
          this._loaderFinishedResolve = resolve;
        }),
        type: Loader,
        instance: null,
        storeNamespace: null,
        payload: () => ({ index: parseInt(this.$route.params.index, 10) }),
      },
    };
  },
  methods: {
    load() {
      if (this.loader.storeNamespace) {
        this.loader.instance = this.loader.type.getInstance('sl', this.$store); // state-loader
        this.loader.instance.populate(this.loader.storeNamespace, this.loader.payload(), () => {
          this._loaderFinishedResolve();
          if (this.$v) this.$v.$reset();
        });
      }
    },
  },
  created() {
    this.load();
  },
  beforeDestroy() {
    if (this.loader.storeNamespace) {
      this.loader.instance.unload(this.loader.storeNamespace);
    }
  },
  watch: {
    $route() {
      this.load();
    },
  },
};

export default StateLoader;
