<template>
   <three-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
            <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
                <h1 id="title">{{ name }}</h1>
            </a-skeleton>
        </template>
        <template v-slot:titleCommands>
          <item-menu
            @save="saveForm" :isSaving="isSaving"
            @delete="onDelete"
            :canClone="true" @clone="addDrawerVisible = true"
            :disabled="!configWriteLock.canWriteConfig" />
        </template>

        <diagnostics :globalState="state" :items="diagnostics" :isLoading="false" :options="diagnosticOptions" />

        <form>
            <div class="styled-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('common.Device-information') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-input :label="$t('common.Name')" :model="$v.form.name" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-readonly :label="$t('common.Device-type')" :value="$t(`keypadsAndReaders.enums.deviceType.${$v.form.type.$model}`)" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-input :label="$t('common.Location')" :model="$v.form.location" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-select :label="$t('wiredReader.Reader-type')" :model="$v.form.readerType"
                                :items="READER_TYPE.values" :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-readonly :label="$t('common.Address')" :value="$v.form.address.$model" />
                            </a-col>
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

            <div class="styled-section" v-if="$v.form.readerType.$model === READER_TYPE.ARM_DISARM.value || $v.form.readerType.$model === READER_TYPE.DISARM_ONLY.value || $v.form.readerType.$model === READER_TYPE.ENTRY_CONTROL.value">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('keypadsAndReaders.Area-attributes') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-area-selector :label="$t('common.Sets')" :model="$v.form.setsAreas" :areasInfo="areasInfo" :areasConfig="areasConfig"
                                :disabled="!configWriteLock.canWriteConfig"/>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-area-selector :label="$t('common.Unsets')" :model="$v.form.unsetsAreas" :areasInfo="areasInfo" :areasConfig="areasConfig"
                                :disabled="!configWriteLock.canWriteConfig"/>
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-area-selector :label="$t('common.In-area')" :model="$v.form.inAreas" :areasInfo="areasInfo" :areasConfig="areasConfig"
                                @singleCheckedArea="readerInArea"
                                :disabled="!configWriteLock.canWriteConfig"/>
                            </a-col>
                        </a-row>
                        <a-row :gutter="50" v-if="readerInAreaIndex >= 0">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-select :label="$t('common.Level')"
                                :model="$v.form.readerLevel"
                                :items="levels"
                                :valueExpr="(i) => i.index"
                                :textExpr="(i) => i.label"
                                :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" />
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" />
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

            <div class="styled-section" v-if="$v.form.readerType.$model === READER_TYPE.ACCESS_CONTROL.value || $v.form.readerType.$model === READER_TYPE.ENTRY_CONTROL.value">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('wiredReader.Access-Control') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-input :label="$t('wiredReader.Lock-open-time-secs')" :model="$v.form.lockOpenTime" :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-zone-selector @selectedZone="onSelectZone" :label="$t('wiredReader.Door-contact-number')" :model="$v.form.doorContactNumber" :zones="zones" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-input :label="$t('wiredReader.Door-open-time-secs')" :model="$v.form.doorOpenTime" :disabled="internalReader || !configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>
                        <a-row :gutter="50">
                          <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                            <p-switch :label="$t('zones.doors.Operate-door')" :model="$v.form.isLockOpenState" :disabled="!configWriteLock.canWriteConfig"
                              @change="onActivateDoor"/>
                          </a-col>
                        </a-row>
                    </div>
                </a-skeleton>
            </div>
        </form>

        <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
        <add-keypad-or-reader :visible="addDrawerVisible" :navigateToNewItem="true" @onClose="addDrawerVisible = false" :clone-payload="loader.payload()" />
        <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
          @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />

   </three-col-layout>
</template>

<script>
import { required, maxLength, between } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { arrayHasTrueValue } from '@/app/shared/utils/validation';
import AddKeypadOrReader from '@/app/keypads-and-readers/shared/components/add-keypad-or-reader/add-keypad-or-reader.vue';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import ItemMenu from '@/app/shared/components/item-menu/item-menu.vue';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import { WARD_READMITS, WARD_CONTROL, READER_TYPE } from '@/app/keypads-and-readers/shared/enums';
import { limits, LEVEL_IDS } from '@/app/shared/constants';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

const storeNamespace = 'keypadsAndReadersState/wiredReader';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);
export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    AddKeypadOrReader,
    UnsavedChangesModal,
    ItemMenu,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      intervalID: null, // Stores the interval ID for clearing later
      addDrawerVisible: false,
      isSaving: false,
      readerInAreaIndex: -1,
      WARD_READMITS,
      WARD_CONTROL,
      READER_TYPE,
      diagnosticOptions: {
        statusType(diagnostic) {
          return diagnostic.value === 'FAULT' ? 'error' : 'active';
        },
      },
    };
  },
  validations: {
    areas: ['form.name', 'form.inAreas'],
    access: ['form.name', 'form.lockOpenTime', 'form.doorOpenTime', 'form.doorContactNumber'],
    form: {
      name: {
        required,
        maxLength: maxLength(16),
      },
      type: {
        required,
      },
      location: {
        maxLength: maxLength(16),
      },
      address: {
        required,
      },
      setsAreas: {},
      unsetsAreas: {},
      inAreas: {
        arrayHasTrueValue,
      },
      readerLevel: {},
      lockOpenTime: {
        required,
        between: between(1, 255),
      },
      doorOpenTime: {
        required,
        between: between(1, 255),
      },
      doorContactNumber: {
        required,
      },
      areaSetUnset: {},
      readerType: {
        required,
      },
      isLockOpenState: {}, // The access lock state!
    },
  },
  computed: {
    levels() {
      // Get the (single) area that the tag reader is configured to be in.
      const area = this.levelsConfig.Areas[this.readerInAreaIndex];

      // Return the list of levels for that area, each level in the format "A - <name>".
      const res = area.Level.map((level, i) => ({
        index: i,
        label: `${LEVEL_IDS[i]} - ${level.Name}`,
      }));
      return res;
    },
    internalReader() {
      return (this.$v.form.doorContactNumber.$model === limits.MAX_ZONES);
    },
    ...mapFields('keypadsAndReadersState/wiredReader', ['form']),
    ...mapState(['diagnostics', 'state', 'name', 'areasConfig', 'areasInfo', 'levelsConfig', 'zones']),
    ...mapGetters(['isLoading']),
  },
  methods: {
    ...mapActions(['save', 'delete', 'unlock']),
    readerInArea(areaIndex) {
      // Note the index of the area that the tag reader is configured to be in; -1 if the tag reader is in more than
      // one area.
      this.readerInAreaIndex = areaIndex;
    },
    startCountdown() {
      let initialValue = this.$v.form.lockOpenTime.$model;
      this.intervalID = setInterval(() => {
        initialValue -= 1;
        if (initialValue <= 0) {
          clearInterval(this.intervalID);
          this.$v.form.isLockOpenState.$model = false;
        }
      }, 1000);
    },
    async onActivateDoor() {
      if (this.$v.form.isLockOpenState.$model === true) {
        this.startCountdown();
        this.unlock();
      } else {
        clearInterval(this.intervalID);
      }
    },
    onDelete() {
      return this.delete().then(() => { this.$router.push({ name: 'keypads-and-readers-list' }); });
    },
    async saveForm() {
      this.$v.$touch();
      if (this.$v.form.readerType.$model === READER_TYPE.ARM_DISARM.value && this.$v.areas.$invalid) return;
      if (this.$v.form.readerType.$model === READER_TYPE.ACCESS_CONTROL.value && this.$v.access.$invalid) return;
      if (this.$v.form.readerType.$model === READER_TYPE.DISARM_ONLY.value && this.$v.areas.$invalid) return;
      if (this.$v.form.readerType.$model === READER_TYPE.ENTRY_CONTROL.value && (this.$v.areas.$invalid || this.$v.access.$invalid)) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      try {
        await this.save();
        this.$notification.success({
          placement: 'bottomRight',
          message: this.$t('common.ItemHasBeenSaved'),
        });
      } finally {
        NoLeaveWhileSavingGuard.isSavingGuard = false;
        this.isSaving = false;
      }
    },
    onSelectZone(a) {
      const newVal = (a !== null) ? a.internalKey : limits.MAX_ZONES;
      const oldVal = this.$v.form.doorContactNumber.$model;
      if (newVal !== oldVal) {
        this.$v.form.doorContactNumber.$model = newVal;
      }
    },
  },
};
</script>

<style lang="scss">

</style>
