/* eslint-disable linebreak-style */
/* eslint-disable lines-between-class-members */
/* eslint-disable no-await-in-loop */
import { API } from '@/app/shared/services/api';
import { limits, MAX_ZONES_PER_HUB } from '@/app/shared/constants';

const api = new API();

const currentPolledEndpoints = [];

const MAX_KEYFOBS = 32;
const MAX_SIRENS = 2;

function dropPolledEndpoint(polledEndpoint) {
  try {
    const temp = window.globalThis.currentRequiredEndpoints;
    window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== polledEndpoint);
  } catch (err) {
    // nothing to do
  }
}

function clearAllCurrentPolledEndpoints() {
  currentPolledEndpoints.forEach((polledEndpoint) => {
    try {
      const temp = window.globalThis.currentRequiredEndpoints;
      window.globalThis.currentRequiredEndpoints = temp.filter(endpoint => endpoint !== polledEndpoint);
    } catch (err) {
      // nothing to do
    }
  });
}

async function NotifyConfigChangesComplete() {
  let result = null;

  const url = '/Live/Devices/Wireless/ConfigChangesComplete';
  await api.put(url, true);

  let changesCompleteResolve;
  const changesCompletePromise = new Promise((resolve) => {
    changesCompleteResolve = resolve;
  });

  // Bring the ad-hoc endpoint in the current context of endpoints.
  if (window.globalThis.currentRequiredEndpoints.indexOf(url) === -1) {
    window.globalThis.currentRequiredEndpoints.push(url);
    currentPolledEndpoints.push(url);
  }

  // Wait for panel to signal that it has completed reconfiguring devices by setting the
  // 'Live/Devices/Wireless/ConfigChangesComplete' endpoint to 'false'
  const pollingCall = api.poll(1000, url, (res) => {
    if (res && res.status === 200) {
      const state = res.data.Live.Devices.Wireless.ConfigChangesComplete;
      if (state === true) {
        return true; // continue polling
      }
      changesCompleteResolve('SUCCESS');
      dropPolledEndpoint(url);
      return false;
    }
    changesCompleteResolve('FAILED');
    dropPolledEndpoint(url);
    return false;
  });

  result = await changesCompletePromise;

  pollingCall.polling.cancel();

  dropPolledEndpoint(url);

  return result;
}

class LearnWirelessDevicesService {
  continueLearning = false;
  typeBeingLearnt = null;

  learningActivatedForUrl = null;

  lastFoundLearningCompleted = false;
  lastFoundIndex = null;

  setLearningCompleted(value) {
    this.lastFoundLearningCompleted = value;
  }

  async _learnDevice(i, activateUrl, learnStateUrl, getStateCallback, onLearnFailed, onItemFound) {
    let finalState = null;

    while (finalState !== 'LEARN_COMPLETE' && finalState !== 'LEARN_FAILED') {
      finalState = await this._runLearning(i, activateUrl, learnStateUrl, getStateCallback);
    }

    if (finalState === 'LEARN_FAILED') {
      if (onLearnFailed != null) onLearnFailed({ index: i, state: finalState });
    } else if (finalState === 'LEARN_COMPLETE') {
      this.lastFoundIndex = i;
      this.lastFoundLearningCompleted = await Promise.resolve(onItemFound({ index: i, state: finalState }));
    }
  }

  async _learn(maxItems, activateUrl, learnStateUrl, getStateCallback, learntStateUrl, getLearntStateCallback, onItemFound, onLearnFailed, manuallyAssociatedZone) {
    this.continueLearning = true;
    this.lastFoundIndex = null;

    // Learn manually-specified zone first, if specified
    if (manuallyAssociatedZone != null) {
      await this._learnDevice(manuallyAssociatedZone, activateUrl, learnStateUrl, getStateCallback, onLearnFailed, onItemFound);
    }
    // When autolearning zones, it needs to be determined which hubs/zones are installed and the index should be set accordingly
    if (this.typeBeingLearnt === 'ZONE') {
      const availableWirelessHubs = (await api.get('/Config/BusDevices/wirelessHubs')).data.Config.BusDevices.wirelessHubs;
      // Determine the index the autolearn sequence should start at
      let startIndex;
      for (let hubIndex = 0; hubIndex < availableWirelessHubs.length; hubIndex += 1) {
        if (availableWirelessHubs[hubIndex].Installed[0] === true) {
          startIndex = hubIndex * MAX_ZONES_PER_HUB;
          break;
        }
      }
      // Start the autolearning sequence for zones
      for (let i = startIndex; i < maxItems; i += 1) {
        if (!this.continueLearning) return;

        const learntResponse = await api.get(learntStateUrl.replace('{{index}}', i));
        const learnt = getLearntStateCallback(learntResponse, i);

        if (!learnt) {
          await this._learnDevice(i, activateUrl, learnStateUrl, getStateCallback, onLearnFailed, onItemFound);
        }
      }
    } else {
      // Autolearning for any device type
      // TODO: introduce hub index checks; device indices might need to start from different ranges based on the wireless hub index installed on the system
      for (let i = 0; i < maxItems; i += 1) {
        if (!this.continueLearning) return;

        const learntResponse = await api.get(learntStateUrl.replace('{{index}}', i));
        const learnt = getLearntStateCallback(learntResponse, i);

        if (!learnt) {
          await this._learnDevice(i, activateUrl, learnStateUrl, getStateCallback, onLearnFailed, onItemFound);
        }
      }
    }
  }

  async _runLearning(index, activateUrl, learnStateUrl, getStateCallback) {
    let finalState = null;

    this.learningActivatedForUrl = activateUrl.replace('{{index}}', index);
    await api.put(this.learningActivatedForUrl, true);

    let learntResolve;
    const learntPromise = new Promise((resolve) => {
      learntResolve = resolve;
    });

    const learnUrl = learnStateUrl.replace('{{index}}', index);

    // Bring the ad-hoc endpoint in the current context of endpoints.
    if (window.globalThis.currentRequiredEndpoints.indexOf(learnUrl) === -1) {
      window.globalThis.currentRequiredEndpoints.push(learnUrl);
      currentPolledEndpoints.push(learnUrl);
    }

    const pollingCall = api.poll(1000, learnUrl, (res) => {
      if (res && res.status === 200) {
        const state = getStateCallback(res, index);
        if (state === 'LEARN_IN_PROGRESS') {
          return true; // continue polling
        }
        learntResolve(state);
        dropPolledEndpoint(learnUrl);
        return false;
      }
      learntResolve('LEARN_FAILED');
      dropPolledEndpoint(learnUrl);
      return false;
    });

    finalState = await learntPromise;
    pollingCall.polling.cancel();

    // turn off learning
    await api.put(activateUrl.replace('{{index}}', index), false);
    this.learningActivatedForUrl = null;

    dropPolledEndpoint(learnUrl);

    return finalState;
  }

  async stopLearning() {
    this.continueLearning = false;

    if (this.learningActivatedForUrl != null) {
      await api.put(this.learningActivatedForUrl, false);
      this.learningActivatedForUrl = null;
    }

    api.cancelPolling();
    clearAllCurrentPolledEndpoints();
  }

  async finish() {
    await this.stopLearning();

    if (this.lastFoundIndex != null) {
      if (!this.lastFoundLearningCompleted && this.typeBeingLearnt === 'FOB') {
        await api.put(`/Live/Devices/WirelessFobs/${this.lastFoundIndex}/DeleteLearnedDevice`, true);
      } else {
        await NotifyConfigChangesComplete();
      }
      this.lastFoundIndex = null;
    }
    clearAllCurrentPolledEndpoints();
  }

  async learnKeypadsAndReaders(onItemFound, onLearnFailed) {
    this.typeBeingLearnt = 'KEYPAD';
    await this._learn(limits.MAX_WE_KP,
      '/Live/Devices/WirelessKeypads/{{index}}/ActivateLearnMode',
      '/Live/Devices/WirelessKeypads/{{index}}/LearnModeState',
      (res, i) => res.data.Live.Devices.WirelessKeypads[i].LearnModeState,
      '/Config/RadioDevicesLearnt/RadioKeypadsLearnt/{{index}}',
      (res, i) => res.data.Config.RadioDevicesLearnt.RadioKeypadsLearnt[i],
      onItemFound,
      onLearnFailed);
  }

  async learnZones(onItemFound, onLearnFailed, manuallyAssociatedZone) {
    this.typeBeingLearnt = 'ZONE';
    await this._learn(limits.MAX_WE_ZONES,
      '/Live/Devices/WirelessZones/{{index}}/ActivateLearnMode',
      '/Live/Devices/WirelessZones/{{index}}/LearnModeState',
      (res, i) => res.data.Live.Devices.WirelessZones[i].LearnModeState,
      '/Config/RadioDevicesLearnt/RadioInputsLearnt/{{index}}',
      (res, i) => res.data.Config.RadioDevicesLearnt.RadioInputsLearnt[i],
      onItemFound,
      onLearnFailed,
      manuallyAssociatedZone);
  }

  async learnSirens(onItemFound, onLearnFailed) {
    this.typeBeingLearnt = 'BELL';
    await this._learn(MAX_SIRENS,
      '/Live/Devices/WirelessSirens/{{index}}/ActivateLearnMode',
      '/Live/Devices/WirelessSirens/{{index}}/LearnModeState',
      (res, i) => res.data.Live.Devices.WirelessSirens[i].LearnModeState,
      '/Config/RadioDevicesLearnt/RadioBellsLearnt/{{index}}',
      (res, i) => res.data.Config.RadioDevicesLearnt.RadioBellsLearnt[i],
      onItemFound,
      onLearnFailed);
  }

  async learnKeyfobs(onItemFound, onLearnFailed) {
    this.typeBeingLearnt = 'FOB';
    await this._learn(MAX_KEYFOBS,
      '/Live/Devices/WirelessFobs/{{index}}/ActivateLearnMode',
      '/Live/Devices/WirelessFobs/{{index}}/LearnModeState',
      (res, i) => res.data.Live.Devices.WirelessFobs[i].LearnModeState,
      '/Config/RadioDevicesLearnt/RadioFobsLearnt/{{index}}',
      (res, i) => res.data.Config.RadioDevicesLearnt.RadioFobsLearnt[i],
      onItemFound,
      onLearnFailed);
  }
}

const service = new LearnWirelessDevicesService();

export default service;
