<template>
  <a-modal
    :title="$t('importExport.Input-JSON-ZIP-password')"
    v-model="visible"
    @cancel="onCancel"
  >
    <p-password
      :label="$t('importExport.JSON-ZIP-password')"
      :labelTooltip="$t('importExport.JSON-ZIP-password-tooltip')"
      :model="$v.form.password" display-style="small" />
    <p-password
      v-if="isDownloading"
      :label="$t('importExport.Confirm-password')"
      :labelTooltip="$t('importExport.Confirm-password-tooltip')"
      :model="$v.form.confirmPassword" display-style="small"
      :customValidations="[{key: 'sameAsPassword', message: $t('importExport.Password-mismatch')}]" />
    <template slot="footer">
      <a-row :gutter="8">
        <a-col>
          <a-button type="link" @click="onCancel">{{
            $t("common.Cancel")
          }}</a-button>
          <a-button type="primary" @click="onOk">{{
            $t("common.Ok")
          }}</a-button>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
  props: {
    visible: { default: false },
    isDownloading: { default: false },
  },
  data() {
    return {
      form: {
        password: '',
        confirmPassword: '',
      },
    };
  },
  validations: {
    form: {
      password: { required },
      confirmPassword: {
        required: requiredIf(function validate() { return this.isDownloading; }),
        sameAsPassword: function validate(value) { return this.isDownloading ? (value === this.form.password) : true; },
      },
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onOk() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$emit('ok', this.form.password);
    },
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.form.password = '';
        this.form.confirmPassword = '';
        this.$v.$reset();
      }
    },
  },
};
</script>
