<template>
    <two-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
             <h1 id="title">{{ $t('zones.navigationTitle') }}</h1>
        </template>
        <template v-slot:titleCommands>
          <a-button type="settings" @click="$router.push({ name: 'settings.zoneSettings' })">
            <font-awesome-icon :icon="['fal', 'cog']" />
          </a-button>
          <a-button :disabled="!configWriteLock.canWriteConfig" type="primary" icon="plus-circle" @click="gridOpenDrawer">{{ $t('zones.Add-zone') }}</a-button>
        </template>

        <div>
             <div>
        <a-input-search :placeholder="$t('zones.Search-for-a-zone')" size="large" v-model="grid.searchQuery" />

        <a-row :gutter="23" class="header">
            <a-col :xs="{ span: 20}" :md="{ span: 20}" :lg="{ span: 20}" :xl="{ span: 20}" class="filters">
                <a-dropdown class="grid-filter">
                    <a-menu slot="overlay">
                      <a-menu-item @click="gridDeleteSelected">
                          {{ $t('common.Delete')}}
                      </a-menu-item>
                      <a-menu-item @click="bypassSelected">
                        {{ $t('common.Bypass')}}
                      </a-menu-item>
                      <a-menu-item @click="reinstateSelected">
                        {{ $t('common.Reinstate')}}
                      </a-menu-item>
                    </a-menu>
                    <a-button>
                        {{ $t('common.Actions') }} <a-icon type="down" />
                    </a-button>
                </a-dropdown>
                <grid-filter :title="$t('common.Status')" :records="allItems" resPrefix="zones.enums.state." field="globalState" @change="gridFilterChange" :initialFilters="gridSavedFilters('globalState')" />
                <grid-filter :title="$t('common.Type')" :records="allItems" resPrefix="zones.enums.zoneType." field="zoneType" @change="gridFilterChange" :initialFilters="gridSavedFilters('zoneType')" />
                <grid-order :options="[{ field: 'name', name:  $t('common.Name')},{ field: 'number', name: $t('common.Number')}]" @change="gridOrderByChange" />
                <grid-filter :title="$t('common.Associated-with')" :records="allItems" field="associatedWith" :customValue="associatedWithFilterValue" :customRender="renderAssociatedWith" @change="gridFilterChange" :initialFilters="gridSavedFilters('associatedWith')" />
                <grid-filter :title="$t('common.Areas')" :records="allItems" field="areas" :customRender="renderAreas" @change="gridFilterChange" :initialFilters="gridSavedFilters('areas')" />
            </a-col>

            <div class="align-right hide-small">
                <grid-display-toggle :isTabularview="grid.options.isTabularView" @toggle="onGridDisplayToggle" />
            </div>
        </a-row>
        <div>
            <ul class="inline-list">
              <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:1, width:'100%' }">
                <li><span>{{ installed }}/{{ max }} {{ $t('common.zones') }}</span></li>
              </a-skeleton>
            </ul>
        </div>
    </div>

    <a-skeleton :loading="isLoading" :title="false" :paragraph="{ rows:10, width:'100%' }">
      <grid @pagedItemsChange='pagedItemsChange'
        :options="grid.options"
        :records="gridItems">
          <div slot="card-title" slot-scope="scope">
              <h3>{{ scope.record.name }}</h3>
              <p class="subtitle" v-on:click.stop>{{ $t('common.Associated-with') }}
                <router-link to="/" v-if="scope.record.associatedWith.type === 'ENDSTATION'">
                  {{ scope.record.associatedWith.name }}
                </router-link>
                <router-link :to="`/zone-expanders/wired-zone-expander/${scope.record.associatedWith.number}`" v-else-if="scope.record.associatedWith.type === 'WIRED_HUB'">
                  {{ scope.record.associatedWith.name }}
                </router-link>
                <router-link :to="`/zone-expanders/wireless-zone-expander/${scope.record.associatedWith.number}`" v-else-if="scope.record.associatedWith.type === 'WIRELESS_HUB'">
                  {{ scope.record.associatedWith.name }}
                </router-link>
                <router-link :to="`/keypads-and-readers/wired-keypad/${scope.record.associatedWith.number}`" v-else-if="scope.record.associatedWith.type === 'KEYPAD'">
                  {{ scope.record.associatedWith.name }}
                </router-link>
                <router-link :to="`/keypads-and-readers/wired-reader/${scope.record.associatedWith.number}`" v-else-if="scope.record.associatedWith.type === 'READER'">
                  {{ scope.record.associatedWith.name }}
                </router-link>
                <template v-else>
                  {{ scope.record.associatedWith.name }}
                </template>
              </p>
              <p class="subtitle">
                <span>{{$t('common.Areas')}}: </span>
                <span style="color: #C02A22" v-if="scope.record.areas.length > 0">{{ formatAssociatedAreas(scope.record.areas) }}</span>
                <font-awesome-icon v-else :icon="['fal', 'spinner-third']" spin />
              </p>
          </div>
       </grid>
    </a-skeleton>
</div>
    <add-zone v-bind:visible.sync="grid.drawerVisible" :clone-payload="grid.clonePayload" />
    <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
      @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
    </two-col-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import zoneHelper from '@/app/shared/services/zone-helper';
import zoneListHelper from '@/app/shared/services/zone-list-helper';
import zoneExpanderHelper from '@/app/shared/services/zone-expander-helper';
import AddZone from '@/app/zones/shared/components/add-zone/add-zone.vue';
import areaHelper from '@/app/panel/shared/services/area-helper';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import {
  ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard,
} from '@/app/shared/mixins';
import { API } from '@/app/shared/services/api';
import { liveZonePageConsts, liveWirelessZoneExpanderConsts } from '@/app/shared/constants';

const api = new API();

let commaSeparatedIndexes = '';
let isNewCustomPollingRequired = false;

const storeNamespace = 'zonesState/list';
const { mapState, mapActions } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, StateLoader, Grid, DisplayFaultsGuard],
  components: {
    AddZone,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      grid: {
        itemsStorePath: `${storeNamespace}/items`,
        deleteItemsStoreAction: `${storeNamespace}/delete`,

        searchFields: ['name', 'location', 'number'],
        orderBy: 'number',

        options: {
          columns: [
            {
              title: this.$t('common.Name'),
              dataIndex: 'name',
              isTitle: true,
            },
            {
              title: this.$t('common.Location'),
              dataIndex: 'location',
            },
            {
              title: this.$t('common.Type'),
              dataIndex: 'zoneType',
              customRender: text => this.$t(`zones.enums.zoneType.${text}`),
            },
            {
              title: this.$t('common.State'),
              dataIndex: 'state',
              customRender: text => this.$t(`zones.enums.state.${text}`),
            },
            {
              title: this.$t('common.Number'),
              dataIndex: 'number',
            },
            {
              title: this.$t('common.Associated-with'),
              dataIndex: 'associatedWith',
              customRender: item => item.name,
              isTitle: true,
            },
          ],

          rowState: (record) => { if (record.globalState != null) { return record.globalState === 'OK' ? 'active' : 'error'; } return 'loading'; },
          canToggle: () => true,
          toggleDisabled: record => !record.omittable,
          toggleState: record => !record.setOmitted,
          canClone: record => this.configWriteLock.canWriteConfig && record.connectionType === 'TYPE_WIRED',
          canDelete: () => this.configWriteLock.canWriteConfig,

          click: (record) => {
            this.$router.push({ name: 'zone', params: { index: record.index } });
          },
          toggle: async (index, record) => { await zoneHelper.bypass(record.index, !record.setOmitted, this.loader.instance); },
        },
      },
      canEdit: () => this.configWriteLock.canWriteConfig,
    };
  },
  computed: {
    ...mapState(['installed', 'max', 'activeZones']),
    isLoading() {
      return false;
    },
  },
  methods: {
    ...mapActions(['bypass', 'reinstate']),
    formatAssociatedAreas(areas) {
      return areaHelper.selectedAreasDisplay(areas);
    },
    renderAssociatedWith(item) {
      return item.name;
    },
    renderAreas(item) {
      return item.name;
    },
    async bypassSelected() {
      // Only selected zones that are also configured to be omittable can be omitted (a.k.a. bypassed).
      const selectedKeys = this.grid.options.selectedRowKeys.filter(index => this.gridItems[index].omittable);
      if (selectedKeys.length > 0) {
        await zoneHelper.bypassMultiple(selectedKeys, true, this.loader.instance);
        this.grid.options.command = 'clear-selection';
      }
    },
    async reinstateSelected() {
      const selectedKeys = this.grid.options.selectedRowKeys;
      if (selectedKeys.length > 0) {
        await zoneHelper.bypassMultiple(selectedKeys, false, this.loader.instance);
        this.grid.options.command = 'clear-selection';
      }
    },
    associatedWithFilterValue(r) {
      return { name: r.name, type: r.type, number: r.number };
    },
    pagedItemsChange(details) {
      // Triggered by event 'pagedItemsChange' emitted by a change in the items being shown on the current page.
      // This informs the view that a new set of item indexes is available for use.
      const indexes = [];
      for (let i = 0; i < details.records.length; i += 1) {
        indexes.push(details.records[i].index);
      }
      commaSeparatedIndexes = indexes.toString(); // May be empty string if no zones shown currently.
      isNewCustomPollingRequired = true;
    },
  },

  created() {
    const subNodes = '/state,WalkTestOpened,Omittable,ManagerOmitted,ForceOmitted,WalkTestClosed,SetOmitted';
    const zoneUrl = liveZonePageConsts.baseUrl.concat('/Zones/0').concat(subNodes);
    // Basically switching the polling interval from idle to active.
    zoneListHelper.customisePollingWithIntervalAndUrl(this.loader.instance, liveZonePageConsts.activeInterval, zoneUrl);
    // And start polling all wireless hubs
    const wirelessExpanderIndexes = '0,1,2'; // Poll all three wirelessZEMs
    const zoneExpanderUrl = liveWirelessZoneExpanderConsts.baseUrl.concat('/').concat(wirelessExpanderIndexes.toString());
    zoneExpanderHelper.customiseExpanderPollingWithIntervalAndUrl(liveWirelessZoneExpanderConsts.key, this.loader.instance, liveWirelessZoneExpanderConsts.activeInterval, zoneExpanderUrl);
  },

  updated() {
    // keep track of the last config lock state
    if (this.canEdit !== this.configWriteLock.canWriteConfig) {
      // if we're just taking back the lock ensure the detectors are awake
      if (!this.canEdit && this.configWriteLock.canWriteConfig) {
        // wake up the detectors after regaining the lock
        api.put('/Live/Devices/Wireless/ActivateAllDetectors', true);
      }
      this.canEdit = this.configWriteLock.canWriteConfig;
    }

    // Determine if new customised polling is required.
    if (isNewCustomPollingRequired) {
      // Check list of zones.
      if (!commaSeparatedIndexes) {
        // No items to poll. E.g. search has zero matches. So stop polling.
        // Note that red zones do not appear in search results, so no need to poll.
        zoneListHelper.revertCustomPollingIfPossible(this.loader.instance);
      } else {
        // Sanity check activeZones before attempting to use it in a URL.
        if (!this.activeZones) {
          return;
        }
        if (this.activeZones.length === 0) {
          return;
        }
        const subNodes = '/state,WalkTestOpened,Omittable,ManagerOmitted,ForceOmitted,WalkTestClosed,SetOmitted';
        const zoneUrl = liveZonePageConsts.baseUrl.concat('/Zones/').concat(this.activeZones).concat(subNodes);
        // Update the url, which forces the endpoint to reload.
        zoneListHelper.changeUrlOnTheFlyWithLoader(this.loader.instance, zoneUrl, liveZonePageConsts.activeInterval);
      }
      // The custom polling setup is now complete.
      isNewCustomPollingRequired = false;
    }
  },

  beforeDestroy() {
    if ((this.$router.history.current.name !== 'zone') && (this.$router.history.current.name !== 'wireless-zone-expander')) {
      api.put('/Live/Devices/Wireless/ActivateAllDetectors', false);
    }
  },

  destroyed() {
    // Inform the zone helper to stop custom polling if there are no other pages using custom polling.
    zoneListHelper.revertCustomPollingIfPossible(this.loader.instance);
    // Stop polling all wireless expanders
    zoneExpanderHelper.revertExpanderCustomPollingIfPossible(liveWirelessZoneExpanderConsts.key, this.loader.instance);
  },
};
</script>

<style lang="scss">
</style>
