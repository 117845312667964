import { createArray } from '@/app/shared/services/api';
import { storeFactory } from '@/app/shared/services/store-helper';
import { limits } from '@/app/shared/constants';
import { loadArea } from '@/app/settings/pages/areas-options/areas-options-helpers';


function initialState() {
  return {
    form: {
      areas: createArray(limits.MAX_AREAS, () => ({
        areaText: undefined,
        restrictionTimer: undefined,
        appExit: undefined,
        alarm: undefined,
        fire: undefined,
        fault: undefined,
        twentyFourHour: undefined,
        chime: undefined,
        levels: createArray(limits.MAX_LEVELS, () => ({
          levelText: undefined,
          timersEntry: undefined,
          timersEntry2: undefined,
          timersExit: undefined,
          timersSiren: undefined,
          timersStrobe: undefined,
          wiredBellSetSquawk: undefined,
          wiredBellSetStrobe: undefined,
          wirelessBellSetSquawk: undefined,
          wirelessBellSetStrobe: undefined,
          wiredBellUnsetSquawk: undefined,
          wiredBellUnsetStrobe: undefined,
          wirelessBellUnsetSquawk: undefined,
          wirelessBellUnsetStrobe: undefined,
          timersSpeakerTimeMinutes: undefined,
          timersFirebellTimeMinutes: undefined,
          timersLevelbell: undefined,
          timersAppExit: undefined,
          timersExitMode: undefined,
          volumesEntry: undefined,
          volumesExit: undefined,
          responsesStartsAt: undefined,
          responsesStopsAt: undefined,
        })),
      })),
    },
  };
}

const { store, api } = storeFactory(initialState, {
  actions: {
    requiredEndpoints() {
      return [];
    },
    async populate(context) {
      const areas = [];
      for (let i = 0; i < limits.MAX_AREAS; i += 1) {
        const levels = [];
        for (let j = 0; j < limits.MAX_LEVELS; j += 1) {
          levels.push({
            levelText: null,
            timersEntry: null,
            timersEntry2: null,
            timersExit: null,
            timersSiren: null,
            timersStrobe: null,
            wiredBellSetStrobe: null,
            wiredBellUnsetStrobe: null,
            wiredBellSetSquawk: null,
            wiredBellUnsetSquawk: null,
            wirelessBellSetSquawk: null,
            wirelessBellUnsetSquawk: null,
            wirelessBellSetStrobe: null,
            wirelessBellUnsetStrobe: null,
            timersSpeakerTimeMinutes: null,
            timersFirebellTimeMinutes: null,
            timersLevelbell: null,
            timersAppExit: null,
            timersExitMode: null,
            volumesEntry: null,
            volumesExit: null,
            responsesStartsAt: null,
            responsesStopsAt: null,
          });
        }
        areas.push({
          areaText: null,
          restrictionTimer: null,
          appExit: null,
          alarm: null,
          fire: null,
          fault: null,
          twentyFourHour: null,
          chime: null,
          levels,
        });
      }
      loadArea(api, areas, 0);

      context.commit('setForm', {
        areas,
      });
    },

  },
});


export default store;
