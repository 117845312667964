/* eslint-disable no-await-in-loop */
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { ACCESS_TYPE } from '@/app/users/shared/enums';
import i18n from '@/app/shared/services/i18n';
import { generateDefaultObject } from '@/app/shared/services/schema-helper';
import {
  getName, getNumber, getUserType, canHaveAutoDuress,
} from '@/app/users/shared/services/helpers';
import { limits } from '@/app/shared/constants';


function initialState() {
  return {
    installed: undefined,
    max: undefined,
    loadingConfig: true,

    _items: [],
  };
}

const { store, api } = storeFactory(initialState, {
  getters: {
    items: state => state._items.filter(i => i.visible),
  },
  actions: {
    requiredEndpoints() {
      return ['infoUserInfo'];
    },
    async populate(context, { endpoints }) {
      const configUserInfoParts = await api.get('Config/userInfo/Users/*/Name,UserType,Code,UserSetOption,UserFlexiSet');

      const settingsTooltip = i18n.t('users.options.settingsTooltip');
      const cloneTooltip = i18n.t('users.options.cloneTooltip');
      const deleteTooltip = i18n.t('users.options.deleteTooltip');
      const users = [];
      for (let i = 0; i <= limits.MAX_USERS; i += 1) {
        const user = configUserInfoParts.data.Config.userInfo.Users[i];
        const accessType = ACCESS_TYPE.fromCode(user.Code);
        users.push({
          index: i,
          name: getName(i, user.Name),
          userType: getUserType(i, user.UserType),
          accessType: accessType != null ? accessType.value : null,
          number: getNumber(i),
          visible: accessType != null,
          settingsTooltip,
          cloneTooltip,
          deleteTooltip,
          fobIndex: accessType != null && accessType.value === ACCESS_TYPE.KEY_FOB.value ? user.Code[0] - 128 : null,
        });
      }

      context.commit('set', { loadingConfig: false });
      context.commit('set', {
        max: endpoints.infoUserInfo.data.Info.userInfo.Users.MaximumUsersAllowed,
        installed: endpoints.infoUserInfo.data.Info.userInfo.Users.RegisteredUsersCount,
        _items: users,
      });
    },
    async delete(context, { indexes }) {
      for (let i = 0; i < indexes.length; i += 1) {
        const objDefaults = await generateDefaultObject(`/Config/userInfo/Users/${indexes[i]}`);
        objDefaults.Code = [64, 64, 64, 64, 64, 64];
        objDefaults.UserAreas.fill(false, 1);
        await api.put(`/Config/userInfo/Users/${indexes[i]}`, objDefaults);

        if (context.state._items[indexes[i]].accessType === ACCESS_TYPE.KEY_FOB.value) {
          const { fobIndex } = context.state._items[indexes[i]];
          await api.put(`/Live/Devices/WirelessFobs/${fobIndex}/DeleteLearnedDevice`, true);
        }

        if (canHaveAutoDuress(api, indexes[i], context.state._items[indexes[i]].accessType)) {
          await api.put('/Live/userInfo/AutoDuressUser', indexes[i]);
        }
      }

      // Get the requiredEndpoints for the list and wizard pages
      const requiredEndpointsList = await this.dispatch('usersState/list/requiredEndpoints');
      const requiredEndpointsAddUser = (await this.dispatch('usersState/addUser/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp));
      const requiredEndpointsAddKeyfob = (await this.dispatch('usersState/addKeyFob/requiredEndpoints')).filter(endp => !requiredEndpointsList.includes(endp) && !requiredEndpointsAddUser.includes(endp));
      const endpointsToUpdate = requiredEndpointsList.concat(requiredEndpointsAddUser, requiredEndpointsAddKeyfob);
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['usersState/list'] });
    },
  },
});

export default store;
