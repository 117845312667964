import panelList from './pages/panel/panel-logs';
import voiceList from './pages/voice/voice-logs';
import accessList from './pages/access/access-logs';
import cloudList from './pages/cloud/cloud-logs';

export default {
  namespaced: true,
  modules: {
    panelList,
    voiceList,
    accessList,
    cloudList,
  },
};
