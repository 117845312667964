<template>
   <three-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
            <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
                <h1 id="title">{{ name }} {{ version }}</h1>
            </a-skeleton>
        </template>
        <template v-slot:titleCommands>
          <item-menu
            @save="saveForm" :isSaving="isSaving"
            @delete="onDelete"
            :canClone="false"
            :disabled="!configWriteLock.canWriteConfig" />
        </template>

        <diagnostics :globalState="state" :items="diagnostics" :isLoading="false" :options="diagnosticOptions" />

        <form>
          <div class="styled-section">
            <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
              <div>
                <h3 class="h4">{{ $t('common.Device-information') }}</h3>
                <a-row :gutter="50">
                  <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                    <p-readonly :label="$t('common.Device-type')" :value="$t('zoneExpanders.enums.deviceType.'+$v.form.type.$model)" />
                  </a-col>
                  <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                    <p-input :label="$t('common.Location')" :model="$v.form.location" :disabled="!configWriteLock.canWriteConfig" />
                  </a-col>
                  <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                    <p-readonly :label="$t('common.Address')" :value="$v.form.address.$model.join(', ')" />
                  </a-col>
                </a-row>
              </div>
            </a-skeleton>
          </div>

          <associated-zones :isLoading="isLoading" :items="associatedZones" :max="zonesMax" @omitt="omittZone" />

        </form>

        <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
        <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
          @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />

   </three-col-layout>
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import AssociatedZones from '@/app/shared/components/associated-zones/associated-zones.vue';
import ItemMenu from '@/app/shared/components/item-menu/item-menu.vue';
import UnsavedChangesModal from '@/app/shared/components/unsaved-changes-modal/unsaved-changes-modal.vue';
import zoneHelper from '@/app/shared/services/zone-helper';
import zoneExpanderHelper from '@/app/shared/services/zone-expander-helper';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';
import { liveWirelessZoneExpanderConsts } from '@/app/shared/constants';
import { API } from '@/app/shared/services/api';

const api = new API();

const storeNamespace = 'zoneExpandersState/wirelessZoneExpander';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);


export default {
  mixins: [ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    AssociatedZones,
    ItemMenu,
    UnsavedChangesModal,
    DisplayFaultsModal,
  },
  data() {
    return {
      index: null,
      loader: {
        storeNamespace,
      },
      isSaving: false,
      addDrawerVisible: false,
      diagnosticOptions: {
        statusType(diagnostic) {
          if (diagnostic.key === 'wirelessZoneExpander.Diagnostics.Frequency' || diagnostic.key === 'wirelessZoneExpander.Diagnostics.Channel') {
            return 'none';
          }

          return diagnostic.value === 'FAULT' ? 'error' : 'active';
        },
      },
      canEdit: () => this.configWriteLock.canWriteConfig,
    };
  },
  validations: {
    form: {
      type: {
        required,
      },
      location: {
        maxLength: maxLength(16),
      },
      address: {
        required,
      },
    },
  },
  computed: {
    clonePayload() {
      return { index: this.$route.params.index, type: 'TYPE_WIRELESS' };
    },
    ...mapState(['form', 'name', 'version', 'zonesMax', 'associatedZones', 'diagnostics', 'state']),
    ...mapGetters(['isLoading']),
  },
  methods: {
    ...mapActions(['save', 'deleteItem']),
    async omittZone(index, item, checked) {
      await zoneHelper.bypass(item.zoneIndex, checked, this.loader.instance);
    },
    onDelete() {
      return this.deleteItem().then(() => { this.$router.push({ name: 'zone-expanders-list' }); });
    },
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
  mounted() {
    this.index = this.$route.params.index;
  },
  created() {
    // Start polling the diagnostics for this wireless ZEM
    // e.g. for the second wireless ZEM build the url Live/BusDevices/wirelessHubs/1
    const expanderIndex = this.$route.params.index;
    const zoneExpanderUrl = liveWirelessZoneExpanderConsts.baseUrl.concat('/').concat(expanderIndex.toString());
    zoneExpanderHelper.customiseExpanderPollingWithIntervalAndUrl(liveWirelessZoneExpanderConsts.key, this.loader.instance, liveWirelessZoneExpanderConsts.activeInterval, zoneExpanderUrl);
  },
  updated() {
    // keep track of the last config lock state
    if (this.canEdit !== this.configWriteLock.canWriteConfig) {
      // if we're just tacking back the lock ensure the detectors are awake
      if (!this.canEdit && this.configWriteLock.canWriteConfig) {
        // wake up the detectors after regaining the lock
        api.put('/Live/Devices/Wireless/ActivateAllDetectors', true);
      }
      this.canEdit = this.configWriteLock.canWriteConfig;
    }
  },
  beforeDestroy() {
    if ((this.$router.history.current.name !== 'zones-list') && (this.$router.history.current.name !== 'zone')) {
      api.put('/Live/Devices/Wireless/ActivateAllDetectors', false);
    }
  },
  destroyed() {
    // Custom polling no longer needed, so cancel if no other users.
    zoneExpanderHelper.revertExpanderCustomPollingIfPossible(liveWirelessZoneExpanderConsts.key, this.loader.instance);
  },
};
</script>

<style lang="scss">
.card__body .diagnostic__value,
.card__body .diagnostic__extra {
  display: block;
  margin-right: 0 !important;
}

.card__body .diagnostic__extra {
  font-size: 0.6rem;
  margin-bottom: -20px;
}
</style>
