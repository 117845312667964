<template>
  <a-form-item :label="label" :validate-status="model.$error ? 'error': 'success'" :label-col="style.labelSize || labelCol" :wrapper-col="style.wrapperSize || wrapperCol">
    <a-date-picker :class="{ alternative: style.isAlternative }" type="text" v-model="model.$model" @change="onChange" :disabled="disabled"/>
    <div class="ant-form-explain" v-if="model.$params.required && model.$error && !model.required">{{ $t('common.Field-is-required') }}</div>
    <div class="ant-form-explain" v-if="model.$params.between && model.$error && !model.between">{{ $t('common.Field-must-be-between', { min: model.$params.between.min, max: model.$params.between.max }) }}</div>
    <div class="ant-form-explain" v-if="model.$params.maxLength && model.$error && !model.maxLength">{{ $t('common.Field-must-have-less-than-characters', { max: model.$params.maxLength.max }) }}</div>
  </a-form-item>
</template>

<script>
export default {
  components: {

  },
  props: ['label', 'model', 'label-col', 'wrapper-col', 'displayStyle', 'disabled'],
  data() {
    if (this.displayStyle === 'small-alternative') {
      return {
        style: {
          isAlternative: true,
          labelSize: {
            sm: { span: 12 }, md: { span: 8 }, lg: { span: 6 }, xl: { span: 5 },
          },
          wrapperSize: {
            sm: { span: 24 }, md: { span: 18 }, lg: { span: 18 }, xl: { span: 18 },
          },
        },
      };
    }
    if (this.displayStyle === 'small') {
      return {
        style: {
          isAlternative: false,
          labelSize: {
            sm: { span: 12 }, md: { span: 8 }, lg: { span: 6 }, xl: { span: 5 },
          },
          wrapperSize: {
            sm: { span: 24 }, md: { span: 18 }, lg: { span: 18 }, xl: { span: 18 },
          },
        },
      };
    }
    return {
      style: {
        isAlternative: true,
      },
    };
  },
  methods: {
    onChange(e) {
      this.$emit('input', e);
    },
  },
};
</script>

<style lang="scss">
</style>
