/* eslint-disable class-methods-use-this */
import Q from 'q';
import { API } from '@/app/shared/services/api';
import { CommandError } from '@/app/shared/errors';
import { liveZoneInfoConsts } from '@/app/shared/constants';

const api = new API();

// A count of the pages using modified polling.
let customPollPageCount = 0;

class ZoneHelper {
  async bypass(index, bypass, loader) {
    await this.bypassMultiple([index], bypass, loader);
  }

  // Private incrementor
  _incCustomPollPageCount() {
    customPollPageCount += 1;
  }

  // Private decrementor
  _decCustomPollPageCount() {
    // After the count has been reset, it is legal to attempt to decrement the count
    // (but the value should never go negative)
    if (customPollPageCount > 0) {
      customPollPageCount -= 1;
    }
    return customPollPageCount;
  }

  // Currently unused.
  resetCustomPollPageCount() {
    customPollPageCount = 0;
  }

  async bypassMultiple(indexes, bypass, loader) {
    try {
      const calls = [];
      for (let i = 0; i < indexes.length; i += 1) {
        calls.push(api.put(`/Live/zoneInfo/Zones/${indexes[i]}`, {
          SetOmitted: bypass,
        }));
      }

      await Q.all(calls);

      loader.reloadEndpoint('liveZoneInfo');
    } catch (error) {
      throw new CommandError('OmmitZoneFailed', CommandError.OMMITT_ZONE, [{ type: bypass ? 'Omitt' : 'Include' }]);
    }
  }

  cancelPolling() {
    api.cancelPolling();
  }

  // Start polling the live zoneInfo endpoint with a specific interval and URL.
  customisePollingWithIntervalAndUrl(loader, intervalMs, zoneUrl) {
    loader.setEndpointIntervalAndUrl(liveZoneInfoConsts.key, intervalMs, zoneUrl);
    this._incCustomPollPageCount();
  }

  // Revert to default polling of live zoneInfo endpoint, if there are no other pages using custom polling.
  revertCustomPollingIfPossible(loader) {
    const pageCount = this._decCustomPollPageCount();
    if (pageCount === 0) {
      // No other pages using customised polling, so revert back to default values.
      loader.setEndpointIntervalAndUrl(liveZoneInfoConsts.key, liveZoneInfoConsts.interval, liveZoneInfoConsts.baseUrl);
    }
  }
}

const helper = new ZoneHelper();

export default helper;
export { api };
