import list from './pages/output-expanders-list/output-expanders-list-data';
import outputExpander from './pages/output-expander/output-expander-data';
import addOutputExpander from './shared/components/add-output-expander/add-output-expander-data';

export default {
  namespaced: true,
  modules: {
    list,
    outputExpander,
    addOutputExpander,
  },
};
