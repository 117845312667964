<template>
   <three-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
            <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
                <h1 id="title">
                    {{ name }}
                </h1>
            </a-skeleton>
        </template>
        <template v-slot:titleCommands>
            <item-menu
              @save="saveForm" :isSaving="isSaving"
              @delete="onDelete"
              :canClone="true" @clone="addDrawerVisible = true"
              :hasToggle="true"
              :toggled="state"
              :toggleTooltip="isEnabledTooltip(state)"
              @toggle="activateOutput"
              :disabled="!configWriteLock.canWriteConfig" />
        </template>

        <diagnostics :globalState="state ? 'OK' : 'ERROR'" :items="diagnostics" :isLoading="false" :options="diagnosticOptions" />

        <form>
            <div class="styled-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('outputs.Output-information') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-readonly :label="$t('common.Name')" :value="name" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                <p-readonly :label="$t('common.Location')" :value="form.location" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" :validate-status="$v.form.associatedWithDevice.$error ? 'error': 'success'">
                                <p-select :label="$t('common.Associated-with')" :model="$v.form.associatedWithDevice" :items="associatedDevices" :valueExpr="(i) => i.key" :textExpr="(i) => i.name"
                                  @change="$v.form.associatedWithOutputIndex.$model = null"
                                  :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-select :label="$t('outputs.Output-type')" :model="$v.form.baseOutputType"
                                :items="outputTypes" :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col v-if="isAreaSpecific" class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-select :label="$t('common.Area')" :model="$v.form.areaIndex"
                                :items="areas" :valueExpr="(i) => i.value" :textExpr="(i) => i.label"
                                :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                        </a-row>
                    </div>
                </a-skeleton>
            </div>

            <div class="styled-section" v-if="isFollow || isTransistored">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('outputs.Output-settings') }}</h3>
                        <a-row :gutter="50" v-if="isTransistored">
                          <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                            <p-select :label="$t('outputs.Transistored')" :model="$v.form.transistored" :items="Object.keys(TRANSISTORED_MODE)" :valueExpr="(i) => i" :textExpr="(i) => $t(`enums.TRANSISTORED_MODE.${i}`)"
                              :disabled="!configWriteLock.canWriteConfig" />
                          </a-col>
                        </a-row>
                        <div v-if="isFollow">
                            <hr class="dashed-bottom" />
                            <a-row :gutter="50">
                              <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}">
                                <p-select :label="$t('outputs.Follow-type')" :model="$v.form.followType" @change="setDefaultFollowTime" :items="Object.keys(FOLLOW_TYPE)" :valueExpr="(i) => FOLLOW_TYPE[i]" :textExpr="(i) => $t(`enums.FOLLOW_TYPE.${i}`)"
                                  :disabled="!configWriteLock.canWriteConfig" />
                              </a-col>
                            </a-row>
                            <hr class="dashed-bottom" />
                            <a-row :gutter="50">
                              <div>
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 6}" >
                                  <p-select :label="$t('outputs.Follow-what')" :model="$v.form.followWhat" :items="Object.keys(FOLLOW_WHAT)" :valueExpr="(i) => FOLLOW_WHAT[i]" :textExpr="(i) => $t(`enums.FOLLOW_WHAT.${i}`)"
                                    :disabled="!configWriteLock.canWriteConfig" />
                                </a-col>
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 6}" v-if="$v.form.followWhat.$model === FOLLOW_WHAT.ZONE">
                                  <p-select :label="$t('outputs.Follow-input')" :model="$v.form.followZone" :items="zones" :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                    :disabled="!configWriteLock.canWriteConfig" />
                                </a-col>
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 6}" v-if="$v.form.followWhat.$model === FOLLOW_WHAT.AREA">
                                  <p-select :label="$t('outputs.Follow-area')" :model="$v.form.followArea" :items="areas" :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                    :disabled="!configWriteLock.canWriteConfig" />
                                </a-col>
                              </div>
                              <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 6}" v-if="hasFollowTime">
                                <a-form-item :validate-status="$v.form.followTime.$error ? 'error': 'success'">
                                  <p-input style="margin-bottom: 0;" :label="$t('outputs.Follow-time')" :model="$v.form.followTime" :disabled="!configWriteLock.canWriteConfig" />
                                  <div class="ant-form-explain" v-if="!$v.form.followTime.followTimeValidator">{{ $t('common.Field-must-be-between', { min: 0, max: 63 }) }}</div>
                                </a-form-item>
                              </a-col>
                              <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 6}" v-if="hasResetZone">
                                <p-select :label="$t('outputs.Reset-zone')" :model="$v.form.resetZone" :items="zones" :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                  :disabled="!configWriteLock.canWriteConfig" />
                              </a-col>
                            </a-row>
                            <div>
                              <hr class="dashed-bottom" />
                              <a-row :gutter="50">
                                <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 6}" >
                                  <p-select :label="$t('outputs.Follow-when')" :model="$v.form.followWhen" :items="Object.keys(FOLLOW_WHEN)" :valueExpr="(i) => FOLLOW_WHEN[i]" :textExpr="(i) => $t(`enums.FOLLOW_WHEN.${i}`)"
                                    :disabled="!configWriteLock.canWriteConfig" />
                                </a-col>
                              </a-row>
                            </div>
                        </div>
                    </div>
                </a-skeleton>
            </div>

        </form>

        <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
        <add-output :visible="addDrawerVisible" :navigateToNewItem="true" @onClose="addDrawerVisible = false" :clone-payload="loader.payload()" />
        <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
          @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
   </three-col-layout>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import AddOutput from '@/app/outputs/shared/components/add-output/add-output.vue';
import ItemMenu from '@/app/shared/components/item-menu/item-menu.vue';
import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import {
  FOLLOW_TYPE, FOLLOW_WHAT, FOLLOW_WHEN, TRANSISTORED_MODE,
} from '@/app/outputs/shared/enums';
import {
  followTimeValidator, isTransistored, hasFollowTime, isAreaSpecific, areaSpecificToOutputType,
} from '@/app/outputs/shared/services/helpers';
import outputHelper from '@/app/shared/services/output-helper';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

const storeNamespace = 'outputsState/output';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);


export default {
  mixins: [ConfigWriteLock, UnsavedGuard, StateLoader, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    AddOutput,
    ItemMenu,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      intervalID: null, // Stores the interval ID for clearing later
      addDrawerVisible: false,
      isSaving: false,
      FOLLOW_TYPE,
      FOLLOW_WHAT,
      FOLLOW_WHEN,
      TRANSISTORED_MODE,
      diagnosticOptions: {
        statusType(diagnostic) {
          if (diagnostic.key === 'output.Diagnostics.Status') {
            return diagnostic.value === 'OFF' ? null : 'active';
          }

          return diagnostic.value === 'FAULT' || !diagnostic.value ? 'error' : 'active';
        },
        globalState(fault) {
          return fault ? 'loading' : 'active';
        },
      },
    };
  },
  validations: {
    form: {
      baseOutputType: {
        required,
      },
      areaIndex: {
        required: requiredIf(function validate() {
          return this.isAreaSpecific;
        }),
      },
      associatedWithDevice: {
        required,
      },
      associatedWithOutputIndex: {
        required,
      },
      transistored: {
        required: requiredIf(function validate() {
          return this.isTransistored;
        }),
      },
      followType: {
        required: requiredIf(function validate() {
          return this.isFollow;
        }),
      },
      followWhat: {
        required: requiredIf(function validate() {
          return this.isFollow;
        }),
      },
      followZone: {
        required: requiredIf(function validate(m) {
          return this.isFollow && m.followWhat === FOLLOW_WHAT.ZONE;
        }),
      },
      followArea: {
        required: requiredIf(function validate(m) {
          return this.isFollow && m.followWhat === FOLLOW_WHAT.AREA;
        }),
      },
      followTime: {
        required: requiredIf(function validate() {
          return this.isFollow && this.hasFollowTime;
        }),
        followTimeValidator,
      },
      resetZone: {
        required: requiredIf(function validate() {
          return this.isFollow && this.hasResetZone;
        }),
      },
      followWhen: {
        required: requiredIf(function validate() {
          return this.isFollow;
        }),
      },
    },
  },
  computed: {
    ...mapState(['form', 'name', 'diagnostics', 'state', 'associatedDevices', 'areasInfo', 'areasConfig', 'zones']),
    ...mapGetters(['isLoading', 'outputTypes', 'isSiren']),
    areas() {
      const res = this.areasConfig.Areas.map((areaConfig, i) => ({
        value: i,
        name: this.areasInfo.Areas[i].Identifier,
        label: `${this.$t('common.Area')} ${this.areasInfo.Areas[i].Identifier} - ${areaConfig.Name}`,
      }));
      return res;
    },
    outputIndexes() {
      const device = this.associatedDevices.find(v => v.key === this.$v.form.associatedWithDevice.$model);
      return device ? device.availableOutputs.map(i => i.deviceOutputIndex) : [];
    },
    isTransistored() {
      const device = this.associatedDevices.find(v => v.key === this.$v.form.associatedWithDevice.$model);
      return isTransistored(device, this.$v.form.associatedWithOutputIndex.$model);
    },
    hasFollowTime() {
      const device = this.associatedDevices.find(v => v.key === this.$v.form.associatedWithDevice.$model);
      return hasFollowTime(this.$v.form.followType.$model, device.type);
    },
    isFollow() {
      return this.$v.form.baseOutputType.$model === 35;
    },
    hasResetZone() {
      return this.$v.form.followType.$model === FOLLOW_TYPE.LATCHED;
    },
    isAreaSpecific() {
      if (this.outputTypes !== undefined) {
        const outputType = this.outputTypes.find(o => o.value === this.$v.form.baseOutputType.$model);
        if ((outputType !== undefined) && (outputType.specific !== undefined)) {
          return outputType.specific === 'AREA';
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions(['save', 'delete']),
    onDelete() {
      return this.delete().then(() => { this.$router.push({ name: 'outputs-list' }); });
    },
    startCountdown() {
      let initialValue = this.$v.form.followTime.$model;
      this.intervalID = setInterval(() => {
        initialValue -= 1;
        if (initialValue <= 0) {
          clearInterval(this.intervalID);
          this.activateOutput();
        }
      }, 1000);
    },
    async activateOutput() {
      const generalOutputType = this.$v.form.baseOutputType.$model;
      if (this.isFollow) {
        await outputHelper.activateOutputType(this.form.rawOutputType, !this.state, this.loader.instance);
        if (this.hasFollowTime) {
          if (!this.state === true) {
            this.startCountdown();
          } else {
            clearInterval(this.intervalID);
          }
        }
      } else if (isAreaSpecific(this.outputTypes, generalOutputType)) {
        const area = this.$v.form.areaIndex.$model;
        const areaOutputType = areaSpecificToOutputType(generalOutputType, area);
        await outputHelper.activateOutputType(areaOutputType, !this.state, this.loader.instance);
      } else {
        await outputHelper.activateOutputType(generalOutputType, !this.state, this.loader.instance);
      }
    },
    // This is called when selecting a new Follow type for a Follow output
    setDefaultFollowTime() {
      // Set the default follow timer value to 0, if default was 197 (atm Panel sets all output timers to 197 by default)
      if (this.$v.form.followType.$model === FOLLOW_TYPE.TIMED && this.$v.form.followTime.$model === 197) {
        this.$v.form.followTime.$model = 0;
      }
    },
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });

      const device = this.associatedDevices.find(v => v.key === this.$v.form.associatedWithDevice.$model);
      const { outputIndex } = device.availableOutputs.find(i => i.deviceOutputIndex === this.$v.form.associatedWithOutputIndex.$model);
      if (parseInt(this.$route.params.index, 10) !== outputIndex || this.isSiren) {
        this.$router.replace({ name: this.isSiren ? 'wired-siren' : 'output', params: { index: outputIndex } });
        this.load();
      }
    },
    isEnabledTooltip(isEnabled) {
      return isEnabled
        ? this.$t('output.tooltips.outputEnabled')
        : this.$t('output.tooltips.outputDisabled');
    },
  },
};
</script>

<style lang="scss">

</style>
