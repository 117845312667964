<template>
   <three-col-layout :configWriteLock="configWriteLock" :anyAreaSet="anyAreaSet" @setMine-event="setMine">
        <template v-slot:title>
            <a-skeleton :loading="isLoading" :paragraph="{ rows:0, width:'100%' }">
                <h1 id="title">{{ name }}</h1>
            </a-skeleton>
        </template>
        <template v-slot:titleCommands>
            <item-menu
              @save="saveForm" :isSaving="isSaving"
              @delete="onDelete"
              :canClone="false"
              :hasToggle="true" :toggled="state" @toggle="activateOutput"
              :disabled="!configWriteLock.canWriteConfig" />
        </template>

        <diagnostics :globalState="globalState" :items="diagnostics" :isLoading="false" :options="diagnosticOptions" />

        <form>
            <div class="styled-section">
                <a-skeleton :loading="isLoading" :paragraph="{ rows:5, width:'100%' }">
                    <div>
                        <h3 class="h4">{{ $t('sirens.Output-information') }}</h3>
                        <a-row :gutter="50">
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-readonly :label="$t('common.Name')" :value="name" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-select :label="$t('sirens.Bell-output-type')" :model="$v.form.bellOutputType"
                                :items="outputTypes" :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                              <p-select :label="$t('sirens.Strobe-output-type')" :model="$v.form.strobeOutputType"
                                :items="outputTypes" :valueExpr="(i) => i.value" :textExpr="(i) => i.name"
                                :disabled="!configWriteLock.canWriteConfig" />
                            </a-col>
                            <a-col class="gutter-row" :xs="{ span: 24}" :lg="{ span: 8}" >
                                <p-readonly :label="$t('common.Number')" :value="number" />
                            </a-col>
                        </a-row>
                    </div>
                </a-skeleton>
            </div>
        </form>
      <unsaved-changes-modal :visible="unsavedGuard.modalVisible" :to="unsavedGuard.to" @cancel="unsavedGuard.modalVisible = false" @save="saveForm" />
      <display-faults-modal :visible="faultsGuard.modalVisible" :to="faultsGuard.to" :errors="errors" :arcDetails="arcError"
        @cancel="() => {faultsGuard.modalVisible = false; faultsGuard.anyFaults = undefined; errors = []; arcError = 'OK'}" />
   </three-col-layout>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';

import {
  ConfigWriteLock, StateLoader, UnsavedGuard, DisplayFaultsGuard, NoLeaveWhileSavingGuard,
} from '@/app/shared/mixins';
import outputHelper from '@/app/shared/services/output-helper';

import ItemMenu from '@/app/shared/components/item-menu/item-menu.vue';
import DisplayFaultsModal from '@/app/shared/components/display-faults-modal/display-faults-modal.vue';

const storeNamespace = 'sirensState/wirelessSiren';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers(storeNamespace);

export default {
  mixins: [ConfigWriteLock, UnsavedGuard, StateLoader, DisplayFaultsGuard, NoLeaveWhileSavingGuard],
  components: {
    ItemMenu,
    DisplayFaultsModal,
  },
  data() {
    return {
      loader: {
        storeNamespace,
      },
      isSaving: false,
      diagnosticOptions: {
        statusType(diagnostic) {
          if (diagnostic.key === 'sirens.Diagnostics.SignalStrength') {
            return (diagnostic.value === 'updating...' || (diagnostic.value !== 0 && diagnostic.format === '{0}%')) ? 'active' : 'error';
          }

          if (diagnostic.key === 'sirens.Diagnostics.Battery') {
            return (diagnostic.value === 'updating...' || diagnostic.value === 'GOOD') ? 'active' : 'error';
          }

          return diagnostic.value === 'FAULT' || !diagnostic.value ? 'error' : 'active';
        },
      },
    };
  },
  validations: {
    form: {
      bellOutputType: {
        required,
      },
      strobeOutputType: {
        required,
      },
    },
  },
  computed: {
    ...mapState(['form', 'name', 'number', 'diagnostics', 'state', 'globalState']),
    ...mapGetters(['isLoading', 'outputTypes']),
  },
  methods: {
    ...mapActions(['save', 'delete']),
    onDelete() {
      return this.delete().then(() => { this.$router.push({ name: 'sirens-list' }); });
    },
    async activateOutput() {
      await outputHelper.activateOutputTypes([this.form.bellOutputType, this.form.strobeOutputType], !this.state, this.loader.instance);
    },
    async saveForm() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.unsavedGuard.modalVisible = false;
      this.$v.$reset();

      this.isSaving = true;
      NoLeaveWhileSavingGuard.isSavingGuard = true;
      await this.save();
      NoLeaveWhileSavingGuard.isSavingGuard = false;
      this.isSaving = false;

      this.$notification.success({
        placement: 'bottomRight',
        message: this.$t('common.ItemHasBeenSaved'),
      });
    },
  },
};
</script>

<style lang="scss">

</style>
