import wirelessKeypad from './pages/wireless-keypad/wireless-keypad-data';
import list from './pages/keypads-and-readers-list/keypads-and-readers-list-data';
import wiredKeypad from './pages/wired-keypad/wired-keypad-data';
import wiredReader from './pages/wired-reader/wired-reader-data';
import addKeypadOrReader from './shared/components/add-keypad-or-reader/add-keypad-or-reader-data';

export default {
  namespaced: true,
  modules: {
    wirelessKeypad,
    list,
    wiredKeypad,
    wiredReader,
    addKeypadOrReader,
  },
};
