import i18n from '@/app/shared/services/i18n';
import { createArray } from '@/app/shared/services/api';
import { storeFactory } from '@/app/shared/services/store-helper';
import eventHub, { EVENTS } from '@/app/shared/utils/eventHub';
import { toDaysHoursMinutes, toMinutes } from '@/app/shared/utils/date-helper';
import {
  ARM_OPTIONS, DISARM_OPTIONS, BUGLARY_ALARM_OPTIONS, TAMPER_OPTIONS,
} from '@/app/shared/enums';
import { limits } from '@/app/shared/constants';
import { translateOutputType } from '@/app/outputs/shared/services/helpers';
import {
  undottedToDottedIPAddress, dottedToUndottedIPAddress, numericArrayToEncryptionKey, encryptionKeyToNumericArray,
} from '../../shared/helpers';


function initialState() {
  return {
    commsTypesFullyLoaded: false,
    commsTypes: [],
    signupStatus: undefined,
    whatSignup: {},
    fastFormatEnabled: false,
    outputTypes: [],
    webWayOneEnabled: false,
    areasInfo: undefined,
    areasConfig: undefined,

    form: {
      items: createArray(4, () => ({
        areaAccount: false,
        validAreas: createArray(limits.MAX_AREAS, () => (false)),

        paths: createArray(3, () => ({
          commType: undefined,
          sendVia: undefined,
          phoneNumber: undefined,
          retries: undefined,
          time: undefined,
          format: undefined,
          sendBatteryInformation: undefined,
          supervisionOption: undefined,
          arcHost: undefined,
          arcPort: undefined,
          networkProtocol: undefined,
          keySize: undefined,
          keypart1: undefined,
          keypart2: undefined,
          keypart3: undefined,
          keypart4: undefined,
        })),

        eventTypes: undefined,
        arm: undefined,
        disarm: undefined,
        burglaryAlarm: undefined,
        bypass: undefined,
        confirmedAlarm: undefined,
        commsStatus: undefined,
        technicalFault: undefined,
        abort: undefined,
        information: undefined,
        accessAlarm: undefined,
        accessEvent: undefined,
        bypassRestore: undefined,
        specialLog: undefined,
        tamper: undefined,
        invalidAccessTag: undefined,
        walkTest: undefined,
        restore: undefined,
        techFaultRestore: undefined,
        test: undefined,
        mainsFail: undefined,
        mainsFailRestore: undefined,
        armFail: undefined,
        engineerEntry: undefined,
        engineerExit: undefined,

        ffChannels: createArray(16, () => ({
          outputTrigger: undefined,
          onEnable: undefined,
          onRestore: undefined,
        })),
      })),
    },

    arcItemSignupForms: createArray(4, () => ({
      paths: createArray(3, () => ({
        hostOption: undefined,
        arcHostName: undefined,
        signupPort: undefined,
        signupSecurity: 'STANDARD',
        arcPassword: undefined,
        arcKey: undefined,
        crcKey: undefined,
        connectionHandle: undefined,
      })),
    })),

    arcSignupForms: createArray(4, () => ({
      hostOption: undefined,
      arcHostName: undefined,
      signupPort: undefined,
    })),
  };
}

export const EVENT_TYPES = {
  DEFAULT_1: 0,
  DEFAULT_2: 1,
  DEFAULT_3: 2,
  CUSTOM: 3,
};

function toCommsType(commsType, slot) {
  if (commsType === 'MODULE_CELLULAR') return `MODULE_CELLULAR$${slot}`;
  if (commsType === 'MODULE_ETHERNET') return `MODULE_ETHERNET$${slot}`;
  if (commsType === 'MODULE_WIFI') return `MODULE_WIFI$${slot}`;
  if (commsType === 'MODULE_RS232') return `MODULE_RS232$${slot}`;

  return 'MODULE_NONE$SLOT_NONE';
}

function fromComms(value) {
  const values = value.split('$');
  return { commsModuleType: values[0], commsSlot: values[1] };
}

function setCommsTypes(context, endpoint, dataStatus) {
  if (context.state.commsTypesFullyLoaded) return; // don't update if already loaded

  let commsTypesFullyLoaded = true;
  const commsTypes = [
    {
      name: i18n.t('enums.COMMS_TYPE.NONE'), type: 'NONE', slot: undefined, value: 'MODULE_NONE$SLOT_NONE',
    },
  ];

  // Note that the order of the .push() calls matches the order of the keypad menu:
  // None [0], (Unknown [1]), Serial Comms [2], Cellular [3], Ethernet [4], WiFi [5]
  for (let i = 0; i < endpoint.data.Live.commsInfo.RS232.length; i += 1) {
    if (endpoint.data.Live.commsInfo.RS232[i].state != null) {
      if (endpoint.data.Live.commsInfo.RS232[i].state !== 'NOT_FITTED') {
        commsTypes.push(i === 0
          ? { name: i18n.t('enums.COMMS_TYPE.RS232'), value: 'MODULE_RS232$BUILTIN_SLOT' }
          : { name: i18n.t(`enums.COMMS_TYPE.RS232_${i}`), value: `MODULE_RS232$COMMS_SLOT_${i}` });
      }
    } else if (dataStatus === 'PARTIALLY_LOADED') {
      commsTypesFullyLoaded = false;
    }
  }
  for (let i = 0; i < endpoint.data.Live.commsInfo.Cellular.length; i += 1) {
    if (endpoint.data.Live.commsInfo.Cellular[i].state != null) {
      if (endpoint.data.Live.commsInfo.Cellular[i].state !== 'NOT_FITTED') {
        commsTypes.push({ name: i18n.t(`enums.COMMS_TYPE.CELLULAR_${i + 1}`), value: `MODULE_CELLULAR$COMMS_SLOT_${i + 1}` });
      }
    } else if (dataStatus === 'PARTIALLY_LOADED') {
      commsTypesFullyLoaded = false;
    }
  }
  for (let i = 0; i < endpoint.data.Live.commsInfo.Ethernet.length; i += 1) {
    if (endpoint.data.Live.commsInfo.Ethernet[i].state != null) {
      if (endpoint.data.Live.commsInfo.Ethernet[i].state !== 'NOT_FITTED') {
        commsTypes.push({ name: i18n.t('enums.COMMS_TYPE.ETHERNET'), value: 'MODULE_ETHERNET$BUILTIN_SLOT' });
      }
    } else if (dataStatus === 'PARTIALLY_LOADED') {
      commsTypesFullyLoaded = false;
    }
  }
  for (let i = 0; i < endpoint.data.Live.commsInfo.WiFi.length; i += 1) {
    if (endpoint.data.Live.commsInfo.WiFi[i].state != null) {
      if (endpoint.data.Live.commsInfo.WiFi[i].state !== 'NOT_FITTED') {
        commsTypes.push({ name: i18n.t('enums.COMMS_TYPE.WIFI'), value: `MODULE_WIFI$COMMS_SLOT_${i + 1}` });
      }
    } else if (dataStatus === 'PARTIALLY_LOADED') {
      commsTypesFullyLoaded = false;
    }
  }

  context.commit('set', {
    commsTypes,
    commsTypesFullyLoaded,
  });
}

function formToDC09Host(ipaddrOrUri) {
  const addr = dottedToUndottedIPAddress(ipaddrOrUri);
  if (addr === '') {
    return ipaddrOrUri;
  }
  return '';
}

function toArcDetails(formItem) {
  const arcDetails = {};
  arcDetails.Paths = [];
  arcDetails.UseAreaAccounts = formItem.areaAccount;
  arcDetails.AccountCode = formItem.accountNumber;
  arcDetails.ValidAreaBits = formItem.validAreas;
  arcDetails.AreaAccountCodes = formItem.areaAccountCodes.map(area => area.accountNumber);
  arcDetails.EventTypesDetails = formItem.eventTypes;

  arcDetails.ChannelEnable = [];
  arcDetails.ChannelRestore = [];
  for (let ch = 0; ch < formItem.ffChannels.length; ch += 1) {
    arcDetails.ChannelEnable[ch] = formItem.ffChannels[ch].onEnable;
    arcDetails.ChannelRestore[ch] = formItem.ffChannels[ch].onRestore;
  }

  if (formItem.eventTypes === EVENT_TYPES.CUSTOM) {
    arcDetails.ArcEventTypes = {};

    arcDetails.ArcEventTypes.Arm = formItem.arm === 'ARM';
    arcDetails.ArcEventTypes.SpecialSet = formItem.arm === 'SPECIAL_ARM';
    arcDetails.ArcEventTypes.Disarm = formItem.disarm === 'DISARM';
    arcDetails.ArcEventTypes.SpecialUnset = formItem.disarm === 'SPECIAL_DISARM';
    arcDetails.ArcEventTypes.AlarmsAll = formItem.burglaryAlarm === 'ALARM_ALL';
    arcDetails.ArcEventTypes.AlarmsOnce = formItem.burglaryAlarm === 'ALARM_ONCE';
    arcDetails.ArcEventTypes.Omit = formItem.bypass;
    arcDetails.ArcEventTypes.ConfirmedAlarm = formItem.confirmedAlarm;
    arcDetails.ArcEventTypes.CommsStatus = formItem.commsStatus;
    arcDetails.ArcEventTypes.TechnicalFault = formItem.technicalFault;
    arcDetails.ArcEventTypes.Abort = formItem.abort;
    arcDetails.ArcEventTypes.Information = formItem.information;
    arcDetails.ArcEventTypes.AccessAlarm = formItem.accessAlarm;
    arcDetails.ArcEventTypes.AccessEvent = formItem.accessEvent;
    arcDetails.ArcEventTypes.OmitRestore = formItem.bypassRestore;
    arcDetails.ArcEventTypes.SpecialLog = formItem.specialLog;
    arcDetails.ArcEventTypes.TamperAlarmsAll = formItem.tamper === 'TAMPER_ALL';
    arcDetails.ArcEventTypes.TamperAlarmsOnce = formItem.tamper === 'TAMPER_ONCE';
    arcDetails.ArcEventTypes.InvalidAccesTag = formItem.invalidAccessTag;
    arcDetails.ArcEventTypes.WalkTest = formItem.walkTest;
    arcDetails.ArcEventTypes.Restore = formItem.restore;
    arcDetails.ArcEventTypes.TechFaultRestore = formItem.techFaultRestore;
    arcDetails.ArcEventTypes.TestCall = formItem.test;
    arcDetails.ArcEventTypes.MainsFail = formItem.mainsFail;
    arcDetails.ArcEventTypes.MainsFailRestore = formItem.mainsFailRestore;
    arcDetails.ArcEventTypes.SetFail = formItem.armFail;
    arcDetails.ArcEventTypes.EngineerEntry = formItem.engineerEntry;
    arcDetails.ArcEventTypes.EngineerExit = formItem.engineerExit;
  }

  arcDetails.RegistrationMode = formItem.registrationType === 'MANUAL' ? 0 : 1;
  arcDetails.TestCallType = formItem.testCalls ? 'TIMED' : 'NONE';
  arcDetails.TestMessageInterval_m = formItem.testCalls ? toMinutes(formItem.intervalDays, formItem.intervalHours, formItem.intervalMinutes) : 1440;
  arcDetails.TestMessageTime_h = formItem.testHours != null ? parseInt(formItem.testHours, 10) : 0;
  arcDetails.TestMessageTime_m = formItem.testMinutes != null ? parseInt(formItem.testMinutes, 10) : 0;

  return arcDetails;
}

function toArcPathDetails(formPath) {
  const arcPathDetails = {};

  const { commsModuleType, commsSlot } = fromComms(formPath.commType);
  arcPathDetails.CommsModuleType = commsModuleType;
  arcPathDetails.CommsSlot = commsSlot;
  arcPathDetails.Sender = formPath.sendVia;
  arcPathDetails.SendTimeout_secs = parseInt(formPath.timeout, 10);

  if (arcPathDetails.Sender === 'Tone' || arcPathDetails.Sender === 'ModemData' || arcPathDetails.Sender === 'SMS') {
    arcPathDetails.TelephoneNumber = formPath.phoneNumber;
  }

  arcPathDetails.Protocol = formPath.format;
  arcPathDetails.NumRetries = parseInt(formPath.retries, 10);
  arcPathDetails.SendBatteryInformation = formPath.sendBatteryInformation;
  arcPathDetails.SupervisionOption = formPath.supervisionOption;
  arcPathDetails.IPAddress = dottedToUndottedIPAddress(formPath.arcHost);
  arcPathDetails.URI = formToDC09Host(formPath.arcHost);
  arcPathDetails.PortNumber = parseInt(formPath.arcPort, 10);
  arcPathDetails.NetworkProtocol = formPath.networkProtocol;
  arcPathDetails.Key_Size = formPath.keySize;
  arcPathDetails.Key = encryptionKeyToNumericArray(formPath.keySize, formPath.keypart1, formPath.keypart2, formPath.keypart3, formPath.keypart4);

  return arcPathDetails;
}

const { store, api } = storeFactory(initialState, {
  getters: {
    outputTypes: state => state._outputTypes,
  },
  mutations: {
    resetARCPath(state, { endpoints, arcIndex, pathIndex }) {
      state.items[arcIndex].paths[pathIndex] = {
        showSignup: endpoints.configCommsInfo.data.Config.commsInfo.ARCDetails[arcIndex].Paths[pathIndex].Key.every(k => k === 0),
        arcSignup: {
          arcHostName: undefined,
          signupPort: undefined,
          signupSecurity: 'STANDARD',
          arcPassword: undefined,
          arcKey: undefined,
          crcKey: undefined,
          connectionHandle: undefined,
        },
      };
    },
    resetARC(state, { endpoints, arcIndex }) {
      const item = state.items[arcIndex];
      item.showSignup = endpoints.configCommsInfo.data.Config.commsInfo.ARCDetails[arcIndex].Paths.every(p => p.Key.every(k => k === 0));
    },
  },
  actions: {
    requiredEndpoints() {
      return ['configCommsInfo', 'infoAreaInfo', 'infoFeatures', 'infoOutputInfo', 'configAreaInfoNames', 'liveCommsInfo'];
    },
    async populate(context, { endpoints, payload }) {
      if (payload.e === 'ARC_UPDATE') {
        if (context.state.whatSignup.pathIndex != null) {
          context.commit('resetARCPath', { endpoints, arcIndex: context.state.whatSignup.arcIndex, pathIndex: context.state.whatSignup.pathIndex });
        }
        context.commit('resetARC', { endpoints, arcIndex: context.state.whatSignup.arcIndex });
        context.commit('set', { whatSignup: {} });

        return;
      }

      const outputTypes = [];
      for (let i = 0; i < endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes.length; i += 1) {
        const outputType = endpoints.infoOutputInfo.data.Info.outputInfo.OutputTypes[i].Name;
        if (outputType != null) {
          outputTypes.push({
            value: i, key: outputType, name: translateOutputType(outputType),
          });
        }
      }

      setCommsTypes(context, endpoints.liveCommsInfo, endpoints.liveCommsInfo.dataStatus);

      context.commit('set', {
        fastFormatEnabled: endpoints.infoFeatures.data.Info.Features.ARCProtocols.FastFormatEnabled,
        webWayOneEnabled: endpoints.infoFeatures.data.Info.Features.Panel.WebWayOneEnabled,
        _outputTypes: outputTypes,
        areasInfo: endpoints.infoAreaInfo.data.Info.areaInfo,
        areasConfig: endpoints.configAreaInfoNames.data.Config.areaInfo,
      });

      const digiChannels = endpoints.configCommsInfo.data.Config.commsInfo.DigiModem.VirtualChannels;
      context.commit('setForm', {
        items: endpoints.configCommsInfo.data.Config.commsInfo.ARCDetails.map(d => ({
          areaAccount: d.UseAreaAccounts,
          accountNumber: d.AccountCode,
          validAreas: d.ValidAreaBits,
          areaAccountCodes: d.AreaAccountCodes.map(accountNumber => ({
            accountNumber,
          })),
          paths: d.Paths.map(p => ({
            commType: toCommsType(p.CommsModuleType, p.CommsSlot),
            sendVia: p.Sender,
            phoneNumber: p.TelephoneNumber,
            retries: p.NumRetries,
            timeout: p.SendTimeout_secs,
            format: p.Protocol,
            sendBatteryInformation: p.SendBatteryInformation,
            supervisionOption: p.SupervisionOption,
            arcHost: undottedToDottedIPAddress(p.IPAddress, p.URI),
            arcPort: p.PortNumber,
            networkProtocol: p.NetworkProtocol,
            keySize: p.Key_Size,
            keypart1: numericArrayToEncryptionKey(p.Key, 0),
            keypart2: numericArrayToEncryptionKey(p.Key, 1),
            keypart3: numericArrayToEncryptionKey(p.Key, 2),
            keypart4: numericArrayToEncryptionKey(p.Key, 3),

            showSignup: p.Key.every(k => k === 0),
          })),

          eventTypes: d.EventTypesDetails,

          arm: ARM_OPTIONS.fromValues(d.ArcEventTypes.Arm, d.ArcEventTypes.SpecialSet),
          disarm: DISARM_OPTIONS.fromValues(d.ArcEventTypes.Disarm, d.ArcEventTypes.SpecialUnset),
          burglaryAlarm: BUGLARY_ALARM_OPTIONS.fromValues(d.ArcEventTypes.AlarmsAll, d.ArcEventTypes.AlarmsOnce),
          bypass: d.ArcEventTypes.Omit,
          confirmedAlarm: d.ArcEventTypes.ConfirmedAlarm,
          commsStatus: d.ArcEventTypes.CommsStatus,
          technicalFault: d.ArcEventTypes.TechnicalFault,
          abort: d.ArcEventTypes.Abort,
          information: d.ArcEventTypes.Information,
          accessAlarm: d.ArcEventTypes.AccessAlarm,
          accessEvent: d.ArcEventTypes.AccessEvent,
          bypassRestore: d.ArcEventTypes.OmitRestore,
          specialLog: d.ArcEventTypes.SpecialLog,
          tamper: TAMPER_OPTIONS.fromValues(d.ArcEventTypes.TamperAlarmsAll, d.ArcEventTypes.TamperAlarmsOnce),
          invalidAccessTag: d.ArcEventTypes.InvalidAccesTag,
          walkTest: d.ArcEventTypes.WalkTest,
          restore: d.ArcEventTypes.Restore,
          techFaultRestore: d.ArcEventTypes.TechFaultRestore,
          test: d.ArcEventTypes.TestCall,
          mainsFail: d.ArcEventTypes.MainsFail,
          mainsFailRestore: d.ArcEventTypes.MainsFailRestore,
          armFail: d.ArcEventTypes.SetFail,
          engineerEntry: d.ArcEventTypes.EngineerEntry,
          engineerExit: d.ArcEventTypes.EngineerExit,

          ffChannels: digiChannels.map((c, i) => ({
            outputTrigger: c,
            onEnable: d.ChannelEnable[i],
            onRestore: d.ChannelRestore[i],
          })),

          registrationType: d.RegistrationMode === 0 ? 'MANUAL' : 'AUTOMATIC',
          testCalls: d.TestCallType === 'TIMED',
          intervalDays: toDaysHoursMinutes(d.TestMessageInterval_m).days,
          intervalHours: toDaysHoursMinutes(d.TestMessageInterval_m).hours,
          intervalMinutes: toDaysHoursMinutes(d.TestMessageInterval_m).minutes,
          testHours: d.TestMessageTime_h,
          testMinutes: d.TestMessageTime_m,

          showSignup: d.Paths.every(p => p.Key.every(k => k === 0)),
        })),
      });
    },
    async onPoll(context, { key, dataStatus, endpoint }) {
      if (key === 'liveCommsInfo') {
        const currentState = context.state.signupStatus;
        const newState = endpoint.data.Live.commsInfo.IpArcSignup.SignupStatus;
        const arcIndex = endpoint.data.Live.commsInfo.IpArcSignup.ArcIndex;
        const pathIndex = endpoint.data.Live.commsInfo.IpArcSignup.PathIndex;

        context.commit('trySet', { dataStatus, data: { signupStatus: newState, whatSignup: { arcIndex, pathIndex } } });

        if (currentState === 'IN_PROGRESS' && newState !== 'IN_PROGRESS') {
          // Reload these endpoints only and refresh the relevant page
          eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate: ['configCommsInfo'], storesToRefresh: ['communicationsState/arcOptions'] });
        }

        setCommsTypes(context, endpoint, dataStatus);
      }
    },
    async save(context) {
      const arcDetails = [];
      const digiChannels = [];
      for (let i = 0; i < context.state.form.items.length; i += 1) {
        arcDetails[i] = toArcDetails(context.state.form.items[i]);
        for (let x = 0; x < context.state.form.items[i].paths.length; x += 1) {
          arcDetails[i].Paths[x] = toArcPathDetails(context.state.form.items[i].paths[x]);
        }

        if (i === 0) { // digichannels are global to all arcs
          for (let ch = 0; ch < context.state.form.items[0].ffChannels.length; ch += 1) {
            digiChannels[ch] = context.state.form.items[0].ffChannels[ch].outputTrigger;
          }
        }
      }
      await api.put('/Config/commsInfo/DigiModem/VirtualChannels', digiChannels);
      await api.put('/Config/commsInfo/ARCDetails', arcDetails);

      // Get the required endpoints for this page
      const endpointsToUpdate = await this.dispatch('communicationsState/arcOptions/requiredEndpoints');
      // Reload these endpoints only and refresh the relevant page
      eventHub.$emit(EVENTS.PARTIAL_CONFIG_WRITE, { endpointsToUpdate, storesToRefresh: ['communicationsState/arcOptions'] });
    },
    async signUp(context, {
      arcIndex, pathIndex, host, port, securityType, password, key, crcKey, connectionHandle,
    }) {
      context.commit('set', { whatSignup: { arcIndex, pathIndex }, signupStatus: 'IN_PROGRESS' });

      // setup arc
      if (pathIndex != null) {
        // Transform the configuration data (for the specified path on the specified ARC) from the
        // internal format to the format used by the panel.
        const arcDetails = [];
        arcDetails[arcIndex] = toArcDetails(context.state.form.items[arcIndex]);
        arcDetails[arcIndex].Paths[pathIndex] = toArcPathDetails(context.state.form.items[arcIndex].paths[pathIndex]);

        // Write the configuration data (for the specified path on the specified ARC) to the panel.
        // Some of this data (such as ARC account number, communicator type, etc.) is required for
        // the sign up process.
        await api.put('/Config/commsInfo/ARCDetails', arcDetails);

        const digiChannels = [];
        for (let ch = 0; ch < context.state.form.items[arcIndex].ffChannels.length; ch += 1) {
          digiChannels[ch] = context.state.form.items[arcIndex].ffChannels[ch].outputTrigger;
        }

        await api.put('/Config/commsInfo/DigiModem/VirtualChannels', digiChannels);

        // Write the sign up settings (transient data only used for the initial sign up, so NOT
        // part of the panel's Config data store) to the panel.
        await api.put('/Live/commsInfo/IpArcSignup/SignupMode', 'MANUAL');
        await api.put('/Live/commsInfo/IpArcSignup/ArcIndex', parseInt(arcIndex, 10));
        await api.put('/Live/commsInfo/IpArcSignup/PathIndex', pathIndex != null ? parseInt(pathIndex, 10) : null);
        if (port !== null) {
          await api.put('/Live/commsInfo/IpArcSignup/Port', parseInt(port, 10));
        }
        await api.put('/Live/commsInfo/IpArcSignup/ConnectionHandle', connectionHandle);
        await api.put('/Live/commsInfo/IpArcSignup/Host', host);
        await api.put('/Live/commsInfo/IpArcSignup/KeyCRC', crcKey || 'undefined');
        await api.put('/Live/commsInfo/IpArcSignup/KeyOrPassword', securityType === 'STANDARD' ? password : key);
        await api.put('/Live/commsInfo/IpArcSignup/SecurityType', securityType);
      } else {
        // Transform the configuration data (for all paths on the specified ARC) from the internal
        // format to the format used by the panel.
        const arcDetails = [];
        arcDetails[arcIndex] = toArcDetails(context.state.form.items[arcIndex]);
        for (let x = 0; x < context.state.form.items[arcIndex].paths.length; x += 1) {
          arcDetails[arcIndex].Paths[x] = toArcPathDetails(context.state.form.items[arcIndex].paths[x]);
        }

        // Write the configuration data (for all paths on the specified ARC) to the panel. Some of
        // this data (such as ARC account number, communicator type, etc.) is required for the sign
        // up process.
        await api.put('/Config/commsInfo/ARCDetails', arcDetails);

        const digiChannels = [];
        for (let ch = 0; ch < context.state.form.items[arcIndex].ffChannels.length; ch += 1) {
          digiChannels[ch] = context.state.form.items[arcIndex].ffChannels[ch].outputTrigger;
        }

        await api.put('/Config/commsInfo/DigiModem/VirtualChannels', digiChannels);

        // Write the sign up settings (transient data only used for the initial sign up, so NOT
        // part of the panel's Config data store) to the panel.
        await api.put('/Live/commsInfo/IpArcSignup/SignupMode', 'AUTOMATIC');
        await api.put('/Live/commsInfo/IpArcSignup/ArcIndex', parseInt(arcIndex, 10));
        await api.put('/Live/commsInfo/IpArcSignup/Host', host);
        if (port !== null) {
          await api.put('/Live/commsInfo/IpArcSignup/Port', parseInt(port, 10));
        }
      }

      // signup using arc setup from above
      await api.put('/Live/commsInfo/IpArcSignup/SignupStatus', 'IN_PROGRESS');
    },
  },
});


export default store;
